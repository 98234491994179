/* @flow */
import React, { type Node } from 'react';
import { Root, RootLink } from './styles';

export type TButtonSize = 'l' | 'm' | 's' | 'xs' | 'xxs' | 'form' | 'formS';

type TProps = {
	children: Node,
	onClick?: () => {},
	color?: 'primary' | 'secondary' | 'gray' | 'white' | 'form' | 'liraGradient',
	bordered?: boolean,
	design?: 'square' | 'round' | 'rounded',
	size?: TButtonSize,
	disabled?: boolean,
	style?: any,
	isLink?: boolean,
	to?: string,
	target?: string,
	name?: string,
	id?: string,
	as?: any,
	role?: string,
	ariaChecked?: boolean,
	ariaSelected?: boolean,
	ariaReadonly?: boolean,
	rel?: string,
};

const Button = ({
	children,
	onClick,
	color,
	bordered,
	design,
	size,
	disabled,
	style,
	isLink,
	to,
	target,
	name = undefined,
	id = undefined,
	as = undefined,
	role = undefined,
	ariaChecked = undefined,
	ariaSelected = undefined,
	ariaReadonly = undefined,
	rel = undefined,
}: TProps) =>
	isLink ? (
		<RootLink
			disabled={disabled}
			$color={color}
			$bordered={bordered}
			$design={design}
			$size={size}
			style={style}
			to={to}
			target={target}
			id={id}
			name={name}
			as={as}
			role={role}
			aria-checked={ariaChecked}
			aria-selected={ariaSelected}
			aria-readonly={ariaReadonly}
			rel={rel}
		>
			{children}
		</RootLink>
	) : (
		<Root
			type="button"
			onClick={onClick}
			disabled={disabled}
			$color={color}
			$bordered={bordered}
			$design={design}
			$size={size}
			style={style}
			id={id}
			name={name}
			as={as}
			role={role}
			aria-checked={ariaChecked}
			aria-selected={ariaSelected}
			aria-readonly={ariaReadonly}
		>
			{children}
		</Root>
	);
Button.defaultProps = {
	color: 'primary',
	bordered: false,
	design: 'square',
	size: 'm',
	disabled: false,
	style: {},
	isLink: false,
	to: '',
	target: '_self',
	name: undefined,
	id: undefined,
	as: undefined,
	onClick: () => {},
	role: undefined,
	ariaChecked: undefined,
	ariaSelected: undefined,
	ariaReadonly: undefined,
	rel: undefined,
};

export default Button;
