import styled, { css } from 'styled-components';
import {
	FacebookShareButton,
	TwitterShareButton,
	VKShareButton,
	OKShareButton,
	TelegramShareButton,
	WhatsappShareButton,
	ViberShareButton,
} from 'react-share';

const ShareButton = css`
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
`;

export const StyledVKShareButton = styled(VKShareButton)`
	${ShareButton}
`;

export const StyledFacebookShareButton = styled(FacebookShareButton)`
	${ShareButton}
`;

export const StyledTwitterShareButton = styled(TwitterShareButton)`
	${ShareButton}
`;

export const StyledOKShareButton = styled(OKShareButton)`
	${ShareButton}
`;

export const StyledTelegramShareButton = styled(TelegramShareButton)`
	${ShareButton}
`;

export const StyledWhatsAppShareButton = styled(WhatsappShareButton)`
	${ShareButton}
`;

export const StyledViberShareButton = styled(ViberShareButton)`
	${ShareButton}
`;

export const ShareButtonLabel = styled.div`
	align-self: center;
	font-size: 14px;
	font-weight: 500;
	color: #000;
`;

export const Root = styled.ul`
	display: ${(props) => (props.footer ? 'block' : 'flex')};
	flex-direction: column;
	align-items: flex-start;
	margin: 0;
	padding: 0;
`;

export const Item = styled.li`
	list-style: none;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
	margin: ${(props) => (props.colorMode === 'black' ? '0 5px' : '0')};

	&:not(:first-child) {
		margin-left: ${(props) => (props.footer ? '8px' : 0)};
	}
`;

export const ShareLink = styled.a`
	text-decoration: none;
	display: ${(props) => (props.colorMode === 'black' ? 'block' : 'initial')};
	color: ${(props) => (props.colorMode === 'black' ? '#000' : 'initial')};
	font-weight: ${(props) => (props.colorMode === 'black' ? 'bold' : 'initial')};
	font-size: ${(props) => (props.colorMode === 'black' && !props.footer ? '30px' : 'initial')};

	& svg {
		display: inline-block;
		vertical-align: middle;
		width: ${(props) => (props.footer ? '24px' : '30px')};
		height: ${(props) => (props.colorMode === 'black' ? '24px' : 'auto')};
		margin-right: ${(props) => (props.footer ? 0 : props.colorMode === 'black' ? '5px' : '10px')};
	}
`;
