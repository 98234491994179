import React from 'react';
import styled from 'styled-components';
import { MEDIA_ON_MOBILE } from '../../constants';

const COLORS = (theme) => ({
	gray: {
		main: theme.darkGrayColor,
		hover: theme.primaryBlackColor,
		active: theme.primaryBlackColor,
		color: '#fff',
		colorHover: '#fff',
		colorActive: '#fff',
	},
	transparent: {
		main: 'transparent',
		hover: 'transparent',
		active: 'transparent',
		color: '#fff',
		colorHover: theme.toxicLimeColor,
		colorActive: theme.toxicLimeColor,
	},
	transparentGray: {
		main: 'transparent',
		hover: 'transparent',
		active: 'transparent',
		color: theme.darkGrayColor,
		colorHover: theme.primaryBlackColor,
		colorActive: theme.primaryColor,
	},
	default: {
		main: '#fff',
		hover: theme.yellowColor,
		active: theme.yellowColor,
		color: theme.primaryBlackColor,
		colorHover: theme.primaryBlackColor,
		colorActive: theme.primaryBlackColor,
	},
});

const Root = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	padding: 0;
	background-color: ${({ isActive, theme, color }) =>
		isActive ? COLORS(theme)[color].active : COLORS(theme)[color].main};
	color: ${({ isActive, theme, color }) =>
		isActive ? COLORS(theme)[color].colorActive : COLORS(theme)[color].color};
	border: none;
	border-radius: 50%;
	cursor: pointer;
	transition: background-color 0.2s ease;
	${({ color }) => (color === 'default' || color === 'gray') && 'box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);'};

	svg {
		width: 100%;
		height: 100%;
		fill: currentColor;
	}

	&#buttonCompare {
		svg {
			width: 100%;
			height: 100%;
		}
	}

	&:focus {
		outline: none;
		${({ color }) => (color === 'default' || color === 'gray') && 'box-shadow: 0 2px 5px 1px rgb(179, 212, 252);'};
		${({ theme, color }) => color === 'transparentGray' && `color: ${COLORS(theme)[color].colorHover}`}
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			color: ${({ theme, color }) => COLORS(theme)[color].colorHover};
			background-color: ${({ theme, color }) => COLORS(theme)[color].hover};
		}
	}

	@media ${MEDIA_ON_MOBILE} {
		&#buttonCompare {
			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
`;

export type TColorOptions = 'default' | 'gray' | 'transparent' | 'transparentGray';

const SideButton = ({ children, color, ...props }) => (
	<Root color={color ?? 'default'} {...props}>
		{children}
	</Root>
);

export default SideButton;
