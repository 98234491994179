export const SET_WINDOW_SIZE = 'SET_WINDOW_SIZE';
export const SET_MOBILE = 'SET_MOBILE';
export const LAYOUT_AUTH_NEEDED = 'LAYOUT_AUTH_NEEDED';

export const MEDIA_ON_MOBILE = '(max-width: 600px)';
export const MEDIA_NO_MOBILE = '(min-width: 601px)';
export const MEDIA_MOBILE_LANDSCAPE = '(max-height: 480px) and (orientation: landscape)';

export const RESET_PROFILE_IS_CHANGED = 'RESET_PROFILE_IS_CHANGED';

export const SHOW_SEARCH_POPUP = 'SHOW_SEARCH_POPUP';
export const SHOW_APP_POPUP = 'SHOW_APP_POPUP';
export const APP_POPUP_WAS_SHOWN = 'APP_POPUP_WAS_SHOWN';

export const SET_IS_BOT = 'SET_IS_BOT';

export const GET_APP_STAT = 'GET_APP_STAT';
export const GET_MORTGAGE_PROGRAMS_STAT = 'GET_MORTGAGE_PROGRAMS_STAT';
export const GET_HOMEPAGE_TEXTS = 'GET_HOMEPAGE_TEXTS';
export const GET_LIRA4_TEXTS = 'GET_LIRA4_TEXTS';

export const SHOW_FOOTER = 'SHOW_FOOTER';

export const CHANGE_AUTH_TYPE = 'CHANGE_AUTH_TYPE';
