import styled, { css } from 'styled-components';
import theme from '../../theme';
import Link from '../Link';

const colorScheme = {
	primary: {
		main: theme.primaryColor,
		hover: theme.primaryHover,
		active: theme.primaryActive,
		text: '#fff',
		textBordered: theme.primaryColor,
		border: theme.primaryColor,
		borderHover: theme.primaryHover,
		borderActive: theme.primaryActive,
	},
	secondary: {
		main: theme.secondaryColor,
		hover: theme.secondaryHover,
		active: theme.secondaryActive,
		text: theme.primaryColor,
		textBordered: theme.primaryColor,
		border: theme.secondaryColor,
		borderHover: theme.secondaryHover,
		borderActive: theme.secondaryActive,
	},
	gray: {
		main: theme.lightGrayColor,
		hover: theme.lightGrayHoverColor,
		active: theme.lightGrayHoverColor,
		text: theme.primaryBlackColor,
		textBordered: theme.primaryBlackColor,
		border: theme.lightGrayColor,
		borderHover: theme.lightGrayHoverColor,
		borderActive: theme.lightGrayHoverColor,
	},
	white: {
		main: '#fff',
		hover: theme.lightGrayColor,
		active: theme.lightGrayColor,
		text: theme.primaryColor,
		textBordered: theme.primaryColor,
		border: '#fff',
		borderHover: theme.lightGrayColor,
		borderActive: theme.lightGrayColor,
	},
	form: {
		main: theme.lightGrayColor,
		hover: theme.lightGrayHoverColor,
		active: theme.lightGrayHoverColor,
		text: theme.darkGrayColor,
		textBordered: theme.darkGrayColor,
		textHover: theme.primaryBlackColor,
		border: theme.lightGrayColor,
		borderHover: theme.lightGrayHoverColor,
		borderActive: theme.lightGrayHoverColor,
	},
	liraGradient: {
		main: css`linear-gradient(90deg, #6F30F6 0%, #5862F7 100%), #303CF6`,
		hover: css`linear-gradient(90deg, #6F30F6 0%, #5862F7 100%), #303CF6`,
		active: css`linear-gradient(90deg, #6F30F6 0%, #5862F7 100%), #303CF6`,
		text: '#fff',
		textBordered: '#6F30F6',
		textHover: '#fff',
		border: 'transparent',
		borderHover: 'transparent',
		borderActive: 'transparent',
		borderBordered: '#6F30F6',
		borderBorderedHover: '#6F30F6',
		borderBorderedActive: '#6F30F6',
	},
};

const sizeScheme = {
	l: {
		...theme.typography.buttonL,
		height: 75,
	},
	m: {
		height: 64,
		...theme.typography.buttonM,
	},
	s: {
		height: 52,
		...theme.typography.textMediumL,
	},
	xs: {
		height: 40,
		...theme.typography.textMediumM,
	},
	xxs: {
		height: 32,
		...theme.typography.textMediumS,
	},
	form: {
		height: 56,
		...theme.typography.buttonForm,
	},
	formS: {
		height: 44,
		padding: '6px 24px',
		...theme.typography.buttonFormS,
	},
};

const borderType = (design, size) => {
	switch (design) {
		case 'round':
			return `${sizeScheme[size].height / 2}px`;
		case 'rounded':
			return '4px';
		default:
			return 0;
	}
};

const RootStyles = css`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	margin: 0;
	height: ${({ $size }) => `${sizeScheme[$size].height}px`};
	background: ${({ $color, $bordered }) => ($bordered ? 'transparent' : colorScheme[$color].main)};
	line-height: ${({ $size }) => sizeScheme[$size].lineHeight};
	font-weight: ${({ $size }) => sizeScheme[$size].fontWeight};
	font-size: ${({ $size }) => sizeScheme[$size].fontSize};
	font-family: ${({ $size }) => sizeScheme[$size].fontFamily};
	color: ${({ $color, $bordered }) => ($bordered ? colorScheme[$color].textBordered : colorScheme[$color].text)};
	text-decoration: none;
	border: ${({ $color, $bordered }) =>
		$bordered
			? `2px solid ${colorScheme[$color].borderBordered ?? colorScheme[$color].border}`
			: `2px solid ${colorScheme[$color].border}`};
	border-radius: ${({ $design, $size }) => borderType($design, $size)};
	transition: all 0.2s ease;
	cursor: pointer;
	box-sizing: border-box;
	box-shadow: none;
	-moz-appearance: none !important;
	-webkit-appearance: none !important;

	&:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}

	&:visited {
		background: ${({ $color, $bordered }) => ($bordered ? 'transparent' : colorScheme[$color].main)};
		color: ${({ $color, $bordered }) => ($bordered ? colorScheme[$color].textBordered : colorScheme[$color].text)};
		border: ${({ $color, $bordered }) =>
			$bordered
				? `2px solid ${colorScheme[$color].borderBordered ?? colorScheme[$color].border}`
				: `2px solid ${colorScheme[$color].border}`};
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			color: ${({ $color }) => colorScheme[$color].textHover ?? colorScheme[$color].text};
			background-color: ${({ $color }) => colorScheme[$color].hover};
			border-color: ${({ $color, $bordered }) =>
				$bordered ? colorScheme[$color]?.borderBorderedHover : colorScheme[$color].borderHover};
		}
		&:active {
			color: ${({ $color }) => colorScheme[$color].text};
			background-color: ${({ $color }) => colorScheme[$color].active};
			border-color: ${({ $color, $bordered }) =>
				$bordered ? colorScheme[$color]?.borderBorderedActive : colorScheme[$color].borderActive};
		}
	}

	@media (hover: none), (pointer: coarse) {
		&:hover,
		&:active {
			color: ${({ $color, $bordered }) => ($bordered ? colorScheme[$color].main : colorScheme[$color].text)};
			background-color: ${({ $color, $bordered }) => ($bordered ? 'transparent' : colorScheme[$color].main)};
			border-color: ${({ $color, $bordered }) =>
				$bordered ? colorScheme[$color]?.borderBordered : colorScheme[$color].main};
		}
	}

	&:focus {
		outline: none;
		border-color: ${({ $color }) => ($color === 'primary' ? theme.primaryBlackColor : theme.primaryColor)};
	}
`;

export const Root = styled.button`
	${RootStyles}
`;

export const RootLink = styled(Link)`
	${RootStyles}
`;
