/* @flow */
import React, { type Node } from 'react';
import styled from 'styled-components';
import Button from '../Button2.0';

const Root = styled.div`
	display: flex;
	width: 100%;
	border-radius: 4px;
	overflow: hidden;

	& > button {
		&:not(:last-of-type) {
			position: relative;

			&::after {
				position: absolute;
				top: -2px;
				left: 100%;
				content: '';
				width: 2px;
				height: calc(100% + 4px);
				background: ${({ theme }) => theme.secondaryHover};
			}
		}
	}

	@media (max-width: 600px) {
		flex-wrap: wrap;
		margin: -4px;

		& > button {
			margin: 4px;
			border-radius: 4px;

			&::after {
				display: none;
			}
		}
	}

	@media (min-width: 601px) {
		& > button {
			flex: 1 1 100%;
		}
	}
`;

type TButton = {
	value: string,
	label: string,
};

type TProps = {
	buttons: TButton[],
	onClick: () => {},
	activeValue: string,
	field: string,
};

const ButtonsRow = ({ buttons, onClick, activeValue, field }: TProps): Node =>
	buttons?.length && (
		<Root role="listbox">
			{buttons.map((item: TButton) => (
				<Button
					color={item.value === activeValue ? 'primary' : 'secondary'}
					onClick={() => onClick(field, item.value)}
					size="formS"
					key={item.value}
					role="option"
					ariaSelected={item.value === activeValue}
				>
					{item.label}
				</Button>
			))}
		</Root>
	);

export default ButtonsRow;
