/* @flow */
import React from 'react';
import type { Node } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Input, Label50, PhoneInput, Clearfix } from '../styles';
import { SET_ACTION_CALLBACK_POPUP } from '../../../constants/popup';
import type { TDispatch } from '../../../types/core';

const TextInputs = (): Node => {
	const dispatch: TDispatch = useDispatch();

	const name: string = useSelector((state) => state.popup.actionCallbackPopup.name);
	const phone: string = useSelector((state) => state.popup.actionCallbackPopup.phone);
	const phoneError: boolean = useSelector((state) => state.popup.actionCallbackPopup.phoneError);

	// const { name, phone, phoneError } = popup || {};

	const handleChangeName = (ev): any => {
		dispatch({ type: SET_ACTION_CALLBACK_POPUP, payload: { name: ev.target.value } });
	};

	const handleChangePhone = (ev): any => {
		dispatch({
			type: SET_ACTION_CALLBACK_POPUP,
			payload: { phone: ev.target.value.replace(/[^+\d]/g, ''), phoneError: false },
		});
	};

	return (
		<Clearfix>
			<Label50 htmlFor="name">
				Ваше имя
				<Input id="name" name="name" type="text" required value={name} onChange={handleChangeName} />
			</Label50>
			<Label50 htmlFor="phone">
				Телефон
				<PhoneInput
					hasError={phoneError}
					type="tel"
					id="phone"
					name="phone"
					mask="+7 (999) 999-99-99"
					minLength={5}
					required
					value={phone}
					onChange={handleChangePhone}
				/>
				{phoneError && <ErrorMessage>Введите номер телефона полностью</ErrorMessage>}
			</Label50>
		</Clearfix>
	);
};

export default TextInputs;
