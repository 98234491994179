/* eslint-disable import/prefer-default-export */

import {
	FAIL,
	GET_APARTMENT_SELECTION_INFO,
	RESALE,
	SET_ADVERT,
	SET_APART_CURRENT_MODAL,
	SET_APARTMENT,
	SET_APARTMENT_INFRASTRUCTURE,
	SET_APARTMENT_ON_MAP,
	SET_DISLIKE_MENU_OPEN,
	SET_LIKE_DISLIKE_STATUS,
	SET_NMARKET_URI,
	START,
	SUCCESS,
} from '../constants';
import type { TDispatch } from '../types/core';
import { addJavaHeaders } from '../utils';
import { checkStatus, parseJSON } from '../apiUtils';
import type { TCityIds } from '../types/city';

export function setApartmentStart(resale) {
	return {
		type: `${SET_APARTMENT}${resale ? RESALE : ''}${START}`,
	};
}

export function setApartmentFail(resale) {
	return {
		type: `${SET_APARTMENT}${resale ? RESALE : ''}${FAIL}`,
	};
}

export function setApartment(data, resale) {
	return {
		type: `${SET_APARTMENT}${resale ? RESALE : ''}${SUCCESS}`,
		payload: data,
	};
}

export const setNMarketUri = (data) => ({
	type: SET_NMARKET_URI,
	payload: data,
});

export const setUserStatus = (data) => ({
	type: SET_LIKE_DISLIKE_STATUS,
	payload: data,
});

export const setDislikeMenuOpen = (isOpen) => ({
	type: SET_DISLIKE_MENU_OPEN,
	payload: isOpen,
});

export const setApartAdvert = (advert) => ({
	type: SET_ADVERT,
	payload: advert,
});

export const setApartCurrentModal = (currentModal) => ({
	type: SET_APART_CURRENT_MODAL,
	payload: currentModal,
});

export const getApartInfrastructure =
	(complexId: string, cityIds: TCityIds, resale) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const {
			token: { token },
		} = getState();

		const infrastructureURL = '/lexsApi/infrastructure/complex';
		const infrastructureFetchOptions = {
			method: 'POST',
			...addJavaHeaders(token),
			body: JSON.stringify({
				id: complexId,
				cityIds,
			}),
		};

		dispatch({ type: `${SET_APARTMENT_INFRASTRUCTURE}${resale ? RESALE : ''}${START}` });

		try {
			return await fetch(infrastructureURL, infrastructureFetchOptions)
				.then(checkStatus)
				.then(parseJSON)
				.then((results) => {
					const { data: infrastructure = null } = results;
					dispatch({
						type: `${SET_APARTMENT_INFRASTRUCTURE}${resale ? RESALE : ''}${SUCCESS}`,
						payload: infrastructure,
					});
				});
		} catch (error) {
			dispatch({ type: `${SET_APARTMENT_INFRASTRUCTURE}${resale ? RESALE : ''}${FAIL}` });
			return null;
		}
	};

export const getApartOnMap =
	(apartId: string, cityIds: TCityIds, resale) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const {
			token: { token },
		} = getState();

		const onmapURL = '/lexsApi/apartment/on-map';
		const onmapFetchOptions = {
			method: 'POST',
			...addJavaHeaders(token),
			body: JSON.stringify({
				id: apartId,
				cityIds,
			}),
		};

		dispatch({ type: `${SET_APARTMENT_ON_MAP}${resale ? RESALE : ''}${START}` });

		try {
			return await fetch(onmapURL, onmapFetchOptions)
				.then(checkStatus)
				.then(parseJSON)
				.then((results) => {
					const { data: onMap = null } = results;
					dispatch({ type: `${SET_APARTMENT_ON_MAP}${resale ? RESALE : ''}${SUCCESS}`, payload: onMap });
				});
		} catch (error) {
			dispatch({ type: `${SET_APARTMENT_ON_MAP}${resale ? RESALE : ''}${FAIL}` });
			return null;
		}
	};

export const getApartAdvert =
	(apartId: string) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const {
			token: { token },
		} = getState();

		const advertURL = `/lexsApi/apartment/${apartId}/advert`;
		const advertFetchOptions = {
			method: 'GET',
			...addJavaHeaders(token),
		};

		try {
			return await fetch(advertURL, advertFetchOptions)
				.then(checkStatus)
				.then(parseJSON)
				.then((results) => {
					const { data: apartAdvert = null } = results;
					dispatch(setApartAdvert(apartAdvert));
				});
		} catch (error) {
			return null;
		}
	};

export const getApartSelectionInfo =
	(apartId: string, cityIds: TCityIds, resale) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const {
			token: { token },
		} = getState();

		const URL = '/lexsApi/apartment/selection-info';
		const fetchOptions = {
			method: 'POST',
			...addJavaHeaders(token),
			body: JSON.stringify({
				id: apartId,
				cityIds,
			}),
		};

		dispatch({ type: `${GET_APARTMENT_SELECTION_INFO}${resale ? RESALE : ''}${START}` });

		try {
			return await fetch(URL, fetchOptions)
				.then(checkStatus)
				.then(parseJSON)
				.then((results) => {
					const { data } = results;
					dispatch({
						type: `${GET_APARTMENT_SELECTION_INFO}${resale ? RESALE : ''}${SUCCESS}`,
						payload: data,
					});
				});
		} catch (error) {
			dispatch({ type: `${GET_APARTMENT_SELECTION_INFO}${resale ? RESALE : ''}${FAIL}` });
			return null;
		}
	};
