import styled from 'styled-components';

export const Root = styled.div`
	display: flex;
	flex-direction: row;
	align-self: flex-start;
	margin: 0;

	@media (max-width: 767px) {
		padding: 10px 0 10px 16px;
		border-bottom: ${({ theme }) => theme && `1px solid ${theme.middleGrayColor}`};
		overflow-x: scroll;
		column-gap: 10px;
	}
`;

export const Tab = styled.button`
	display: flex;
	box-shadow: none;
	background-color: ${({ theme, $isActive }) => ($isActive ? theme.blueColorTabs : theme.secondaryColor)};
	text-align: center;
	${({ theme }) => theme && theme.typography.textMediumM};
	padding: 4px 24px;
	color: ${({ theme, $isActive }) => ($isActive ? '#fff' : theme.baseGrayColorNew)};
	border: none;
	cursor: pointer;

	&:hover {
		${({ $isActive, theme }) => !$isActive && `background-color: ${theme.secondaryHover};`}
	}

	@media (min-width: 768px) {
		&:not(:last-child) {
			border-right: 2px solid #dadcfc;
		}

		&:last-child {
			border-radius: 0 8px 0 0;
		}

		&:first-child {
			margin: -4px 0 -6px 0;
			border-radius: 4px 4px 0 0;
		}
	}

	@media (max-width: 767px) {
		padding: 6px 14px;
		border-radius: 4px;
		margin: 0;
		align-items: center;
		white-space: nowrap;
		${({ theme }) => theme && theme.typography.textMediumS};
	}
`;

export const FormWrapper = styled.div`
	display: flex;
	padding: 32px;
	justify-content: center;
	background-color: #fff;
	border-radius: 8px;

	@media (min-width: 800px) {
		width: 760px;
	}

	@media (min-width: 768px) {
		margin: 40px 30px;
	}

	@media (max-width: 767px) {
		min-height: calc(100vh - 250px);
		margin: 0 16px;
	}
`;
