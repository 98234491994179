/* @flow */
import React, { type Node, useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
	ContextDivider,
	ContextGroup,
	DropdownCheckbox,
	DropdownIcon,
	DropdownLink,
	DropdownSubmenu,
	LiraActionButton,
} from '../../MobileHeader/styles';
import { dropdownIconSmall, settingsSmallIcon, startIcon, stopIcon } from '../../../../Icons';
import { LIRA_CHANGE_SORTING, LIRA_SELECTION_INCLUDE_SOLD, LIRA_SORTING_SELECTION } from '../../../../../constants';
import Check from '../../../../Check';
import type { TLira } from '../../../../../reducers/lira';
import { changeLiraSelectionStatus } from '../../../../../actions/lira4';
import type { TDispatch } from '../../../../../types/core';
import { AppContext } from '../../../../AppContext';
import history from '../../../../../history';
import type { TCity } from '../../../../../types/city';
import type { TRootState } from '../../../../../types/rootState';

type TProps = {
	lira: TLira,
	submenuToggle: () => {},
	openSubmenu: boolean,
};

const LiraSelection = ({ lira, submenuToggle, openSubmenu }: TProps): Node => {
	const dispatch: TDispatch = useDispatch();
	const store = useStore();
	const context = useContext(AppContext);

	const city: TCity = useSelector((state: TRootState) => state.city);

	const {
		user: { status },
		selection: { includeSold },
	} = lira;

	const isLiraActive = status === 'ACTIVE';

	const goToFilter = () => {
		history.push(`/${city.link}/lira/filter`, {
			showPreloader: true,
			prev: history.location,
			y: window.scrollY,
		});
	};

	const handleLiraChangeSorting = (payload) => {
		dispatch({ type: LIRA_CHANGE_SORTING, payload: payload.value });
	};

	const handleLiraSelectionSold = (e) => {
		dispatch({ type: LIRA_SELECTION_INCLUDE_SOLD, payload: e.target.checked });
	};

	return (
		<>
			<LiraActionButton
				$blue={!isLiraActive}
				onClick={() => changeLiraSelectionStatus(isLiraActive)(dispatch, store.getState, context)}
			>
				{isLiraActive ? (
					<>
						Остановить подбор <svg viewBox="0 0 32 32">{stopIcon}</svg>
					</>
				) : (
					<>
						Начать подбор <svg viewBox="0 0 24 32">{startIcon}</svg>
					</>
				)}
			</LiraActionButton>
			<DropdownLink as="button" onClick={goToFilter}>
				Настройки
				<DropdownIcon viewBox="0 0 24 24">{settingsSmallIcon}</DropdownIcon>
			</DropdownLink>
			{lira?.selection?.viewType !== 'map' && (
				<ContextGroup>
					<ContextDivider />
					<DropdownLink as="button" onClick={submenuToggle}>
						Сортировка
						<DropdownIcon viewBox="0 0 16 16" $isOpen={openSubmenu}>
							{dropdownIconSmall}
						</DropdownIcon>
					</DropdownLink>
					<DropdownSubmenu $isOpen={openSubmenu}>
						{LIRA_SORTING_SELECTION.map((item) => (
							<DropdownLink
								as="button"
								$isActive={false}
								key={Math.random()}
								onClick={() => handleLiraChangeSorting(item)}
							>
								{item.label}
							</DropdownLink>
						))}
					</DropdownSubmenu>
					<ContextDivider />
				</ContextGroup>
			)}
			<DropdownCheckbox>
				<Check
					name="liraSoldedFlats"
					id="liraSoldedFlats"
					label="Показывать проданные"
					checked={includeSold}
					withoutMargin
					toRight
					onChange={handleLiraSelectionSold}
					style={{ justifyContent: 'space-between' }}
				/>
			</DropdownCheckbox>
		</>
	);
};

export default LiraSelection;
