/* @flow */

import type { TResults } from '../components/SearchPopup/types';
import type { TAction } from '../types/core';
import {
	CLEAR_SEARCH,
	FAIL,
	SEARCH,
	SEARCH_FIRST_STATE,
	SEARCH_INPUT_VALUE_CHANGED,
	SEARCH_OPENED_TYPES,
	START,
	SUCCESS,
} from '../constants';

export type TSearchState = {
	value: string,
	results: TResults,
	openedTypes: Array<string>,
	firstSearch: boolean,
	isLoading: boolean,
	isLoaded: boolean,
};

export type TSearchContentType =
	| 'news'
	| 'article'
	| 'mystery_tenant'
	| 'mystery_buyer'
	| 'analytics'
	| 'knowledge'
	| 'developer'
	| 'complex'
	| 'apartment';

export type TSearchItem = {
	city: {
		id: number,
		slug: string,
		name: string,
	},
	createdAt: string,
	id: number,
	previewDescription: string,
	previewPicture: string,
	slug: string,
	title: string,
	type: TSearchContentType,
};

const initialState: TSearchState = {
	value: '',
	results: {},
	openedTypes: [],
	firstSearch: true,
	isLoading: false,
	isLoaded: false,
};

const reducer = (state: TSearchState = initialState, action: TAction): TSearchState => {
	switch (action.type) {
		case SEARCH + START:
			return { ...state, isLoading: true, isLoaded: false };
		case SEARCH + SUCCESS:
			return { ...state, results: action.payload, isLoading: false, isLoaded: true };
		case SEARCH + FAIL:
			return { ...state, results: {}, isLoading: false, isLoaded: false };
		case SEARCH_INPUT_VALUE_CHANGED:
			return { ...state, value: action.payload };
		case SEARCH_OPENED_TYPES:
			return { ...state, openedTypes: action.payload };
		case SEARCH_FIRST_STATE:
			return { ...state, firstSearch: action.payload };
		case CLEAR_SEARCH:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
