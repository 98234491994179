/* @flow */
export const CHANGE_USER_CITY = 'CHANGE_USER_CITY';

export const SET_CITY_CONFIG = 'SET_CITY_CONFIG';
export const CHANGE_CITY_CONFIG = 'CHANGE_CITY_CONFIG';
export const CLEAR_CITY_CONFIG = 'CLEAR_CITY_CONFIG';

export const GET_CITY_CONFIG = 'GET_CITY_CONFIG';

export const PREPARE_CHANGE_CITY = 'PREPARE_CHANGE_CITY';
