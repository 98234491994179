/* @flow */

import React from 'react';
import { Root } from './styles';
import {
	AppStoreIcon,
	AppStoreIconWithoutText,
	AppStoreShortIcon,
	GooglePlayIcon,
	GooglePlayIconWithoutText,
	GooglePlayShortIcon,
} from './Icons';

type TSettings = {
	store: 'ios' | 'android',
	height?: number,
	width?: number,
	padding?: string,
	withoutText?: boolean,
	squareIcon?: boolean,
	viewBox?: string,
	themeColor?: string,
};

const MobileAppBtn = ({ store, height, width, withoutText, squareIcon, viewBox, padding, themeColor }: TSettings) => {
	const MOBILE = {
		ios: {
			url: 'https://apps.apple.com/us/app/living/id1471304008',
			img: AppStoreIcon,
			imgWithoutText: AppStoreIconWithoutText,
			imgShort: AppStoreShortIcon,
		},
		android: {
			url: 'https://play.google.com/store/apps/details?id=ru.living.mobile',
			img: GooglePlayIcon,
			imgWithoutText: GooglePlayIconWithoutText,
			imgShort: GooglePlayShortIcon,
		},
	};
	const defaultLinkStyles = {
		height,
		width,
		padding,
	};

	const chooseIcon = () => {
		if (withoutText) {
			return 'imgWithoutText';
		}
		if (squareIcon) {
			return 'imgShort';
		}
		return 'img';
	};

	return (
		<Root
			to={MOBILE[store].url}
			target="_blank"
			rel="noopener noreferrer"
			style={defaultLinkStyles}
			$color={themeColor}
		>
			<svg viewBox={viewBox}>{MOBILE[store][chooseIcon()]}</svg>
		</Root>
	);
};

MobileAppBtn.defaultProps = {
	height: 30,
	width: 110,
	withoutText: false,
	squareIcon: false,
	viewBox: '0 0 110 30',
	padding: '12px 16px',
	themeColor: 'primaryBlackColor',
};

export default MobileAppBtn;
