import styled, { css } from 'styled-components';

export const Root = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Subtitle = styled.div`
	display: none;

	@media (min-width: 601px) {
		display: block;
		color: ${({ theme }) => theme.darkGrayColor};
		${({ theme }) => theme.typography.textM};
	}
`;

export const Title = styled.h2`
	margin: 10px 0 24px;
	${({ theme }) => theme.typography.textXL};

	@media (max-width: 600px) {
		margin: 0 0 10px;
		font-size: 32px;
	}
`;

export const SearchWrapper = styled.div`
	margin: 0 0 24px;

	@media (max-width: 600px) {
		margin: 0 0 10px;
	}
`;

export const Tabs = styled.div`
	display: flex;
	border: ${({ theme }) => `2px solid ${theme.primaryColor}`};
	border-radius: 4px;
	background-color: #fff;
	height: 32px;
	box-sizing: border-box;
	overflow: hidden;
`;

export const Tab = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-basis: 50%;
	background-color: ${({ theme, $isActive }) => ($isActive ? theme.primaryColor : '#fff')};
	color: ${({ theme, $isActive }) => ($isActive ? '#fff' : theme.primaryColor)};
	cursor: pointer;
	border: none;
	${({ theme }) => theme.typography.textMediumS};
`;

export const ListWrapper = styled.div`
	margin: 20px 0;

	${({ $oneColumn }) =>
		$oneColumn
			? css`
					position: relative;
					display: flex;
					flex-direction: column;
			  `
			: css`
					display: grid;
					grid-template-columns: 1fr 1fr;

					@media (max-width: 767px) {
						grid-template-columns: 100%;
					}
			  `}

	&:empty {
		&::after {
			content: 'Ничего не нашли...';
			display: block;
			margin: 40px auto;
			text-align: center;
			color: ${({ theme }) => theme.primaryBlackColor};
			${({ theme }) => theme.typography.textMediumM};
		}
	}
`;

export const BackButton = styled.button`
	margin: 0 0 20px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-self: flex-start;
	background-color: transparent;
	box-shadow: none;
	border: none;
	color: ${({ theme }) => theme.primaryColor};
	transition: color 0.3s;
	cursor: pointer;
	${({ theme }) => theme.typography.buttonM};

	svg {
		flex-shrink: 0;
		width: 24px;
		height: 14px;
		margin: auto 10px auto 0;
		fill: currentColor;
	}

	&:hover {
		color: ${({ theme }) => theme.primaryBlackColor};
	}
`;

export const BackButtonWrapper = styled.div`
	display: flex;
	position: sticky;
	top: -24px;
	left: 0;
	z-index: 11;
	padding-top: 24px;
	margin-top: -24px;
	background: #fff;

	@media (max-width: 600px) {
		top: 0;
		margin-top: 0;
	}
`;
