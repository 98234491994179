export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_CURRENT_CITY = 'SET_CURRENT_CITY';
export const SET_INDEX_FILTER = 'SET_INDEX_FILTER';
export const CHANGE_INDEX_FILTER = 'CHANGE_INDEX_FILTER';
export const SET_COMPLEX_DATA = 'SET_COMPLEX_DATA';
export const CHANGE_COMPLEX_DATA = 'CHANGE_COMPLEX_DATA';
export const SET_COMPLEX_PLANINGS = 'SET_COMPLEX_PLANINGS';
export const SET_COMPLEX_FILTER_AVAILABLE = 'SET_COMPLEX_FILTER_AVAILABLE';
export const FETCH_COMPLEX_FULL_DATA = 'FETCH_COMPLEX_FULL_DATA';
export const SET_COMPLEX_BOUNDS = 'SET_COMPLEX_BOUNDS';
