/* @flow */

import type { TAction } from '../types/core';
import {
	LIRA_GET_USER_PROFILE,
	LIRA_RESET_WISHES,
	LIRA_SET_WISHES,
	LIRA_UPDATE_WISHES,
	RESET_ALL_FILTERS,
	SUCCESS,
} from '../constants';
import type { TLiraDefaultWishes } from '../types/lira3';
import { smartConcat } from '../actions/lira';

type TLiraWishesState = TLiraDefaultWishes;

const initialState: TLiraWishesState = {};

const liraWishes = (state: TLiraWishesState = initialState, action: TAction): TLiraWishesState => {
	switch (action.type) {
		case LIRA_GET_USER_PROFILE + SUCCESS: {
			const { separated: { wishes = {} } = {} } = action.payload;
			return { ...state, ...wishes };
		}

		case LIRA_UPDATE_WISHES:
			return { ...action.payload };

		case LIRA_SET_WISHES:
			return { ...smartConcat(state, action.payload) };

		case RESET_ALL_FILTERS:
		case LIRA_RESET_WISHES:
			return initialState;

		default:
			return state;
	}
};

export default liraWishes;
export { initialState as liraWishesInitialState };
