/* @flow */
// TODO: move functions to separate files

import { addJavaHeaders } from './utils';

import {
	CLEAR_CITY_CONFIG,
	FAIL,
	GET_CITY_CONFIG,
	LOAD_CITIES,
	LOAD_FEED,
	LOAD_PROFILE,
	SET_CITY_CONFIG,
	START,
	SUCCESS,
} from './constants';
import { TTelegramAuthParams } from './types/account';

type TCookieOptions = {
	path: string,
	domain: string,
	'max-age': number | string,
	expires: number | string | Date,
	secure: boolean | string,
	samesite: string,
};

/**
 * Проверка статуса ответа
 *
 * @param {*} response - Объект ответа
 * @throws {Error}
 */
export function checkStatus(response) {
	if (response.status >= 200 && response.status < 300) {
		return response;
	}
	const error = new Error(response.statusText);
	error.response = response;
	throw error;
}

/**
 * Конвертация ответа в JSON
 *
 * @param {*} response - Объект ответа
 */
export function parseJSON(response) {
	return response.json();
}

/**
 * Получение куки
 *
 * @param {string} name - Название куки
 * @param {string} cookies
 */
export function getCookie(name, cookies = document.cookie) {
	const matches = cookies.match(
		new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`),
	);
	return matches ? decodeURIComponent(matches[1]) : undefined;
}

/**
 * Установка куки
 *
 * @param {string} name - Название куки
 * @param {string} value - Значение куки
 * @param {Object} options - Объект дополнительных опций
 */
export function setCookie(name: string, value: string, options: TCookieOptions = {}) {
	/* let { expires } = options;

	if (typeof expires === 'number' && expires) {
		const d = new Date();
		d.setTime(d.getTime() + expires * 1000);
		expires = options.expires = d;
	}
	if (expires && expires.toUTCString) {
		options.expires = expires.toUTCString();
	}

	value = encodeURIComponent(value);

	let updatedCookie = `${name}=${value}`;

	for (const propName in options) {
		updatedCookie += `; ${propName}`;
		const propValue = options[propName];
		if (propValue !== true) {
			updatedCookie += `=${propValue}`;
		}
	}

	document.cookie = updatedCookie; */

	if (document) {
		let { expires } = options;
		const opts = { ...options };

		if (typeof expires === 'number' && expires) {
			const d = new Date();
			d.setTime(d.getTime() + expires * 1000);
			expires = d;
			opts.expires = d;
		} else if (typeof expires === 'string' && expires) {
			expires = new Date(expires);
		}

		if (expires?.toUTCString) {
			opts.expires = expires.toUTCString();
		}

		const val: string = encodeURIComponent(value);

		let updatedCookie = `${name}=${val}`;
		Object.keys(opts).forEach((propName: string) => {
			const propValue = opts[propName];
			updatedCookie += `; ${propName}${propValue !== true ? `=${propValue}` : ''}`;
		});

		document.cookie = updatedCookie;
	}
}

/**
 * Удаление куки на клиенте
 *
 * @param {string} name - Название куки
 * @param {Object} options
 * @param {string} options.path
 * @param {string} options.domain
 */
export function deleteCookie(name, options = {}) {
	const { path, domain } = options;

	setCookie(name, '', {
		expires: -1,
		...(path && { path }),
		...(domain && { domain }),
	});
}

// TODO: Написать нормальный регэксп
export function getClearedDomain(domain) {
	const re = /([\w-]+\.\w+$)|([\w-]+\.\w+:)|(localhost)/g;
	return domain.match(re) ? domain.match(re)[0].replace(':', '') : domain;
}

/**
 * Удаление кук авторизации на клиенте
 */
export const deleteTokenCookies = (options = {}) => {
	const { withDomain } = options;

	const domain = getClearedDomain(document.location.hostname);

	deleteCookie('living_token', {
		path: '/',
		...(withDomain && { domain }),
	});
	deleteCookie('living_tokenExpires', {
		path: '/',
		...(withDomain && { domain }),
	});
	// deleteCookie('living_refreshToken', {
	// 	path: '/',
	// 	...(withDomain && { domain }),
	// });
	deleteCookie('living_isAnonymous', {
		path: '/',
		...(withDomain && { domain }),
	});
};

/**
 * Установка кук авторизации на клиенте
 *
 * @param {string} token - Токен авторизации
 * @param {string} tokenExpires - Дата окончания срока жизни токена
 * @param {string} refreshToken - Токен для обновления
 * @param {boolean} isAnonymous - Статус пользователя
 */
export function setTokenCookies(token, tokenExpires, refreshToken, isAnonymous) {
	const domain = getClearedDomain(document.location.hostname);

	setCookie('living_token', token, {
		path: '/',
		expires: new Date(tokenExpires),
		domain,
		samesite: 'none',
		secure: true,
	});

	setCookie('living_tokenExpires', tokenExpires, {
		path: '/',
		expires: new Date(tokenExpires),
		domain,
		samesite: 'none',
		secure: true,
	});

	// setCookie('living_refreshToken', refreshToken, {
	// 	path: '/',
	// 	expires: 2592000, // 30 дней
	// 	domain,
	// });

	setCookie('living_isAnonymous', isAnonymous, {
		path: '/',
		expires: new Date(tokenExpires),
		domain,
		samesite: 'none',
		secure: true,
	});
}

/**
 * Установка токена авторизации в куки на сервере
 *
 * @param {Object} response - Объект ответа
 * @param {Object} options
 * @param {string} options.token - Токен авторизации
 * @param {string} options.tokenExpires - Дата окончания срока жизни токена
 * @param {string} options.refreshToken - Токен для обновления
 * @param {boolean} options.isAnonymous - Статус пользователя
 */
export function setTokenCookiesOnServer(response, { token, tokenExpires, isAnonymous }) {
	const domain = getClearedDomain(response.req.headers.host);

	response.cookie('living_token', token, {
		path: '/',
		expires: new Date(tokenExpires),
		domain,
		samesite: 'none',
		secure: true,
	});

	response.cookie('living_tokenExpires', tokenExpires, {
		path: '/',
		expires: new Date(tokenExpires),
		domain,
		samesite: 'none',
		secure: true,
	});

	response.cookie('living_isAnonymous', isAnonymous, {
		path: '/',
		expires: new Date(tokenExpires),
		domain,
		samesite: 'none',
		secure: true,
	});
}

/**
 * True, если текущие дата и время больше переданной в expires
 * @param {string} expires
 * @returns {boolean}
 */
export const isExpired = (expires: string): boolean => new Date().valueOf() >= new Date(expires).valueOf();

export const getTokenStatus = async (token: string, fetch): Promise<boolean> => {
	const url = '/passApi/data/token';

	try {
		const { result, data } = await fetch(url, {
			method: 'GET',
			...addJavaHeaders(token),
		}).then((res) => res.json());
		if (result === 'ok') {
			const { status } = data;
			return status === 'valid';
		}
		return false;
	} catch (error) {
		return false;
	}
};

/**
 * Получение любого валидного токена для текущего пользователя
 *   1. В запросе отсутствует заголовок Authorization - новый анонимный токен для нового пользователя
 *   2. Анонимный токен, переданный в заголовке Authorization, просрочен - новый анонимный токен для текущего пользователя
 *   3. В заголовке анонимный токен, в параметрах присутствует телефон - новый токен для существующего авторизованного
 *      пользователя по номеру телефона (не работает в текущей версии).
 *   4. Токен авторизованного пользователя просрочен - ошибка, требуется повторная авторизация через СМС (см. Login)
 *   5. Валидный (непросроченный) токен любого пользователя - текущий токен. Не рекомендуется - это просто предохранитель.
 * @param {string} currentToken - любой текущий токен пользователя
 * @param {string} phone - телефон пользователя, если есть (deprecated)
 * @param fetch - fetch из текущего контекста (сервер, браузер)
 * @returns {Promise<null|{tokenExpires, isAnonymous, token}>}
 */
export const getValidToken = async (currentToken: string, phone: string, fetch) => {
	const url = '/passApi/data/token';

	try {
		const { result, data } = await fetch(url, {
			method: 'POST',
			...addJavaHeaders(currentToken),
			...(phone ? { body: JSON.stringify({ phone }) } : {}),
		}).then((res) => res.json());

		if (result === 'ok') {
			const { token, expires: tokenExpires, isAnonymous } = data ?? {};
			return { token, tokenExpires, isAnonymous };
		}
		return null;
	} catch (error) {
		return null;
	}
};

export const getTokenObjectFromHash = async (path, fetch) => {
	const hash = path.replace('/hash/', '');
	const hashUrl = `/lexsApi/short-url/get/${hash}`;
	try {
		const response = await fetch(hashUrl).then((res) => res.json());
		const { result = 'error', data = {}, status = 200 } = response;
		if (status !== 200) return { tokenObject: {}, url: '/' };
		if (result === 'ok') {
			if (data.token === null) {
				const { url = '/' } = data;
				return { tokenObject: {}, url: url.replace(/^http.?:\/\/[^/]+\//i, '/') };
			}
			const {
				token: { token, expired: tokenExpires, refreshToken, isAnonymous },
				url,
			} = data;
			return {
				tokenObject: { token, tokenExpires, refreshToken, isAnonymous },
				url: url.replace(/^http.?:\/\/[^/]+\//i, '/'),
			};
		}
		return { tokenObject: {}, url: '/' };
	} catch (err) {
		// throw Error(err);
		console.error(err);
		return { tokenObject: {}, url: '/' };
	}
};

/**
 * Authenticates a user via Telegram.
 *
 * @param {Object} options - The options for authentication.
 * @param {TTelegramAuthParams} options.params - The parameters for the authentication request.
 * @param {string|null} [options.token=null] - The optional token for authentication.
 * @param {Function} fetch - The fetch function to make the request.
 * @returns {Promise<TTelegramAuthToken>} The response from the authentication request.
 */
export const getAuthFromTelegram = async (options: { params: TTelegramAuthParams, token: string }, fetch) => {
	const { params, token = null } = options;

	const authUrl = `/passApi/data/login/telegram`;
	try {
		const response = await fetch(authUrl, {
			method: 'POST',
			...addJavaHeaders(token),
			body: JSON.stringify(params),
		}).then((res) => res.json());

		if (response.result === 'ok') {
			return response.data;
		}

		throw Error('Telegram authentication failed');
	} catch (err) {
		// throw Error(err);
		return null;
	}
};

/**
 * Получение профиля
 *
 * @param {string} token - Токен авторизации
 * @param {Object} options
 * @param {boolean} options.isServer - Получение профиля для сервере/клиента
 * @param {function} options.fetch
 * @param {Object} options.store - Объект глобального хранилища
 * @returns {Object}
 */
export async function getProfile(token, options) {
	const { isServer = false, fetch, store } = options;

	const profileUrl = '/lexsApi/cabinet/profile';

	await fetch(profileUrl, {
		...addJavaHeaders(token),
	})
		.then(checkStatus)
		.then(parseJSON)
		.then(({ data, result }) => {
			if (result === 'ok') {
				store.dispatch({
					type: `${LOAD_PROFILE}${SUCCESS}`,
					payload: { ...data },
				});
			}
		})
		.catch(() => {
			if (!isServer) {
				store.dispatch({
					type: `${LOAD_PROFILE}${FAIL}`,
				});
			}
		});
}

/**
 * Получение ленты новостей
 *
 * @param {string} token - Токен авторизации
 * @param {Object} options
 * @param {boolean} options.isServer - Получение ленты для сервера/клиента
 * @param {function} options.fetch
 * @param {Object} options.store - Объект глобального хранилища
 * @returns {Object}
 */
export async function getFeed(token, options) {
	const { isServer = false, fetch, store } = options;

	const feedUrl = '/lexsApi/cabinet/newsfeed';

	if (!isServer) {
		store.dispatch({
			type: LOAD_FEED + START,
		});
	}

	await fetch(feedUrl, {
		method: 'POST',
		...addJavaHeaders(token),
		body: JSON.stringify({
			page: 0,
			count: 10,
		}),
	})
		.then(checkStatus)
		.then(parseJSON)
		.then(({ data, result }) => {
			if (result === 'ok') {
				store.dispatch({
					type: LOAD_FEED + SUCCESS,
					payload: data,
				});
			}
		})
		.catch(() => {
			if (!isServer) {
				store.dispatch({
					type: LOAD_FEED + FAIL,
				});
			}
		});
}

export async function getCities(token, { fetch, store }) {
	const citiesUrl = '/lexsApi/init/city-list';

	await fetch(citiesUrl, {
		...addJavaHeaders(token),
	})
		.then(checkStatus)
		.then(parseJSON)
		.then(({ data, result }) => {
			if (result === 'ok') {
				store.dispatch({
					type: `${LOAD_CITIES}${SUCCESS}`,
					payload: data,
				});
				return data;
			}
			return data;
		});
}

export async function getCityConfig(cityIds, options) {
	let cityConfig;

	if (cityIds) {
		const { fetch, store, token } = options;

		const cityIdsUrl = '/lexsApi/init/city-config';

		store.dispatch({
			type: `${GET_CITY_CONFIG}${START}`,
		});

		await fetch(cityIdsUrl, {
			method: 'POST',
			...addJavaHeaders(token || store.getState().token.token),
			body: JSON.stringify({
				cityIds,
			}),
		})
			.then(checkStatus)
			.then(parseJSON)
			.then(({ data, result }) => {
				if (result === 'ok') {
					store.dispatch({
						type: SET_CITY_CONFIG,
						payload: data,
					});

					cityConfig = data;
				}

				if (result === 'error') {
					store.dispatch({
						type: CLEAR_CITY_CONFIG,
					});
				}
			})
			.catch((reason) => {
				console.error('---', 'reason', reason);

				store.dispatch({
					type: CLEAR_CITY_CONFIG,
				});
			});
	}

	return cityConfig;
}

function saveUtmsToCookies(options) {
	const { responseObject, utms } = options;
	const domain = getClearedDomain(responseObject.req.headers.host);

	Object.entries(utms).forEach((utm) => {
		const [utmKey, utmValue] = utm;

		responseObject.cookie(`living_utm_${utmKey}`, utmValue, {
			path: '/',
			maxAge: 2592000 * 1000, // 30 дней
			domain,
		});
	});
}

function removeUtmsFromCookies(options) {
	const { responseObject } = options;
	const utms = [
		'living_utm_source',
		'living_utm_medium',
		'living_utm_campaign',
		'living_utm_term',
		'living_utm_content',
	];
	const domain = getClearedDomain(responseObject.req.headers.host);

	utms.forEach((utm) => {
		responseObject.clearCookie(utm, {
			path: '/',
			domain,
		});
	});
}

export async function sendUtms(tokenObject, utms, options) {
	const { fetch, responseObject, fromCookies = false } = options;
	const { source, medium, campaign, term, content } = utms;
	const sendUtmsUrl = '/lexsApi/cabinet/save-utm';
	const isAuthUser = typeof tokenObject.token === 'string' && typeof tokenObject.refreshToken === 'string';
	const requiredUtmsIsExist = ['source', 'medium', 'campaign'].every((requiredUtm) => !!utms[requiredUtm]);

	if (requiredUtmsIsExist) {
		if (isAuthUser) {
			await fetch(sendUtmsUrl, {
				method: 'POST',
				...addJavaHeaders(tokenObject.token),
				body: JSON.stringify({
					...(source && { source }),
					...(medium && { medium }),
					...(campaign && { campaign }),
					...(term && { term }),
					...(content && { content }),
				}),
			})
				.then(checkStatus)
				.then(parseJSON)
				.then(({ data, result }) => {
					if (result === 'ok' && fromCookies) {
						removeUtmsFromCookies({ responseObject });
					}

					return data;
				})
				.catch((reason) => {
					console.error('---', 'reason', reason);
				});
		} else {
			saveUtmsToCookies({ responseObject, utms });
		}
	}
}

export function setCityCookiesOnServer(response, city, cityIds, tokenExpires) {
	const domain = getClearedDomain(response.req.headers.host);

	// console.log(domain);

	response.cookie('living_city', city, {
		path: '/',
		expires: new Date(tokenExpires),
		domain,
	});

	response.cookie('living_city_ids', JSON.stringify(cityIds), {
		path: '/',
		expires: new Date(tokenExpires),
		domain,
	});
}

/**
 * Deletes Telegram authentication query parameters from the current URL.
 */
export const deleteTelegramQueries = () => {
	const urlParams = new URLSearchParams(window?.location?.search);

	if (urlParams?.size > 0) {
		if (urlParams.has('id')) {
			urlParams.delete('id');
		}
		if (urlParams.has('first_name')) {
			urlParams.delete('first_name');
		}
		if (urlParams.has('last_name')) {
			urlParams.delete('last_name');
		}
		if (urlParams.has('username')) {
			urlParams.delete('username');
		}
		if (urlParams.has('photo_url')) {
			urlParams.delete('photo_url');
		}
		if (urlParams.has('auth_date')) {
			urlParams.delete('auth_date');
		}
		if (urlParams.has('hash')) {
			urlParams.delete('hash');
		}
		window.history.replaceState({}, document.title, window.location.pathname);
	}
};
