/* @flow */
import React from 'react';
import loadable from '@loadable/component';
import { Root, Tab, FormWrapper } from './styles';
import { useModal } from '../../../../utils/hooks';
import { sendYMAnalytics } from '../../../../utils';

const Modal = loadable(() => import('../../../../components/Modal'), { fallback: null, ssr: false });
const NotAvailableForm = loadable(() => import('../NotAvailableForm'), { fallback: null, ssr: false });

type TProps = {
	isMobile?: boolean,
};

const TYPE_FAKE_TABS = [
	{
		label: 'Купить',
		isActive: true,
		clickAction: '',
	},
	{
		label: 'Дома и участки',
		isActive: false,
		clickAction: 'click_houses_and_plots',
	},
	{
		label: 'Снять',
		isActive: false,
		clickAction: 'click_rent',
	},
	{
		label: 'Посуточно',
		isActive: false,
		clickAction: 'click_daily_rent',
	},
];

const FakeTabs = ({ isMobile = false }: TProps) => {
	const { isShowing, toggle } = useModal();

	const handleModalOpen = (eventName) => {
		sendYMAnalytics('reachGoal', eventName);
		toggle();
	};

	return (
		<Root>
			{TYPE_FAKE_TABS.map((tab, idx) => (
				<Tab
					key={idx.toString()}
					$isActive={tab.isActive}
					onClick={tab.isActive ? undefined : () => handleModalOpen(tab.clickAction)}
				>
					{tab.label}
				</Tab>
			))}
			<Modal transparent closeBtnPositionPx={isMobile ? '24' : '0'} isShowing={isShowing} hide={() => toggle()}>
				<FormWrapper>
					<NotAvailableForm closeModal={() => toggle()} />
				</FormWrapper>
			</Modal>
		</Root>
	);
};

FakeTabs.defaultProps = {
	isMobile: false,
};

export default FakeTabs;
