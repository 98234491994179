/* @flow */
import React, { useContext } from 'react';
import type { Node } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
	BottomLine,
	CallbackBody,
	FormWrapper,
	PhoneNote,
	Description,
	DeveloperInfo,
	DeveloperLogo,
	DeveloperText,
	Note,
	StyledBtn,
	Title,
} from '../styles';
import AgreeCheckbox from '../../AgreeCheckbox';
import type { TDeveloper } from '../../../types/apart';
import { SET_ACTION_CALLBACK_POPUP } from '../../../constants/popup';
import type { TDispatch } from '../../../types/core';
import TextInputs from './TextInputs';
import OptionsInputs from './OptionsInputs';
import { sendActionCallbackRequest } from '../../../actions/popup';
import { AppContext } from '../../AppContext';
import type { TCRMRequestContext } from '../../../types/misc';
import type { TMortgageData } from '../../../decorators/withCallbackPopup';
import { sendGTMAnalytics } from '../../../utils/tagManager';

type TProps = {
	callbackType: string | null,
	isReserved: boolean,
	apartData: {
		id: number,
		nmarketId: number,
		rooms_cnt: number,
		number: number | string,
		complex_name: string,
		developer: TDeveloper,
	} | null,
	complexId: number,
	context: TCRMRequestContext,
	mortgageData: TMortgageData,
};

export const FormBody = (props: TProps): Node => {
	const { callbackType, isReserved, apartData, complexId, context: crmContext, mortgageData } = props;
	const { developer } = apartData;

	const dispatch: TDispatch = useDispatch();
	const store = useStore();
	const context = useContext(AppContext);

	const agree: boolean = useSelector((state) => state.popup.actionCallbackPopup.agree);
	const phone: string = useSelector((state) => state.popup.actionCallbackPopup.phone);
	const isFetching: boolean = useSelector((state) => state.popup.actionCallbackPopup.isFetching);

	const callbackTitle = (): string => {
		if (callbackType === 'mortgage') return 'Заявка на ипотеку';
		if (callbackType === 'view') return 'Запись на просмотр';
		if (isReserved) return 'Уточнить наличие';
		if (!isReserved) return 'Бесплатная бронь';
		return '';
	};

	const buttonText = (): string => {
		if (isFetching) return 'Отправка...';
		if (callbackType === 'mortgage') return 'Отправить';
		if (callbackType === 'view') {
			if (developer && developer.showRefer) {
				return 'Отправить заявку застройщику';
			}
			return 'Отправить заявку';
		}
		if (isReserved) return 'Уточнить наличие';

		return 'Забронировать квартиру';
	};

	const handleChangeInput = (ev): any => {
		const value = ev.target.checked;

		dispatch({
			type: SET_ACTION_CALLBACK_POPUP,
			payload: {
				agree: value,
			},
		});
	};

	const handleClickButton = () => {
		dispatch({ type: SET_ACTION_CALLBACK_POPUP, payload: { phoneError: !/\+\d{11}/.test(phone) } });
		sendActionCallbackRequest({
			apartData,
			isReserved,
			complexId,
			context: crmContext,
			callbackType,
			mortgageData,
		})(dispatch, store.getState, context);
	};

	return (
		<CallbackBody>
			<Title>{callbackTitle()}</Title>
			{apartData.rooms_cnt !== null &&
				apartData.rooms_cnt !== undefined &&
				apartData.number &&
				apartData.complex_name && (
					<Description>
						{apartData.rooms_cnt === 0 ? 'Студия' : `${apartData.rooms_cnt}-к квартира`} №{apartData.number}{' '}
						в ЖК «{apartData.complex_name}»
					</Description>
				)}
			<FormWrapper>
				{callbackType !== 'mortgage' && (
					<DeveloperInfo>
						{developer && developer.showRefer && developer.logo && (
							<DeveloperLogo style={{ backgroundImage: `url(${developer.logo})` }} />
						)}
						<DeveloperText>
							{developer && developer.showRefer && (
								<p>
									Ваша запись обрабатывается и обслуживается застройщиком{' '}
									{developer && developer.name && `«${developer.name}»`}.
								</p>
							)}
							<p>Специалист отдела сопровождения свяжется с вами для подтверждения записи.</p>
						</DeveloperText>
					</DeveloperInfo>
				)}

				<TextInputs />

				{callbackType === 'view' && <OptionsInputs />}

				<AgreeCheckbox checked={agree} onChange={handleChangeInput} />

				<BottomLine>
					<StyledBtn rounded onClick={handleClickButton}>
						{buttonText()}
					</StyledBtn>
				</BottomLine>
			</FormWrapper>
			<PhoneNote>
				Телефон службы качества Living: <a href="tel:88007774091">8 (800) 777-40-91</a>
			</PhoneNote>
		</CallbackBody>
	);
};
