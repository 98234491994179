/* @flow */
import React, { useEffect, useRef, useState } from 'react';
import type { Node } from 'react';
import { Transition } from 'react-transition-group';
import { Root as CustomRoot, Inside as CustomInside } from './styles';

import CloseButton from '../CloseButton';

import { lockScroll, unlockScroll } from '../../DOMUtils';

const timeout = {
	enter: 10,
	exit: 150,
};
// PROPS EXPLNATION
// visible - показать\скрыть
// 	mortgage - ипотека? хз
// 	root - монтировать в root (передать компонент) или CustomRoot (всё сломает...)
// 	inside - внутри модалки или без обёртки (передать компонент) или CustomInside
// 	close = пропсы для кнопк Х
// 	timeoutMS - timeout
// 	closeBtn - нужна ли кнопка Х закрыть true/false
// 	children - внутренняя часть
// 	onEntered - хендлер открытия модалки
// 	handleClosePopup - хэндлер закрыть модалку

type TProps = {
	children: Node,
	closeBtn?: boolean,
	visible: boolean,
	mortgage?: boolean,
	root: any,
	inside: any,
	close: any,
	handleClosePopup?: () => any,
	onEntered?: () => any,
	timeoutMS?: number | { enter: number, exit: number },
};

const Popup = ({
	visible,
	mortgage,
	root,
	inside,
	close,
	timeoutMS,
	closeBtn,
	children,
	onEntered,
	handleClosePopup,
}: TProps): Node => {
	const Root = root || CustomRoot;
	const Inside = inside || CustomInside;
	const rootRef = useRef(null);

	const [clickedLayer, setClickedLayer] = useState('');

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (visible) {
				rootRef.current.focus();
				lockScroll();
			} else {
				unlockScroll();
			}
		}
	}, [visible]);

	const handleClickOutside = (event) => {
		const { layer = '' } = event.target.dataset;
		if (layer === 'outside' && layer === clickedLayer) {
			setClickedLayer('');
			handleClosePopup();
		}
	};

	const handleCloseClick = () => {
		handleClosePopup();
	};

	const handleMouseDown = (event) => {
		const { layer = '' } = event.target.dataset;
		setClickedLayer(layer);
	};

	const handleClick = (e) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
	};

	return (
		<Transition in={visible} timeout={timeoutMS || timeout} unmountOnExit onEntered={onEntered}>
			{(status) => (
				// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
				<Root
					mortgage={mortgage}
					status={status}
					onClick={handleClickOutside}
					onMouseDown={handleMouseDown}
					role="button"
					tabIndex="0"
					data-layer="outside"
					{...root}
				>
					{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
					<Inside
						status={status}
						mortgage={mortgage}
						onClick={handleClick}
						onMouseDown={handleClick}
						onMouseUp={handleClick}
						tabIndex={visible ? 0 : -1}
						role="alertdialog"
						aria-modal={visible}
						ref={rootRef}
					>
						{closeBtn && <CloseButton autofocus {...close} onClick={handleCloseClick} />}
						{children}
					</Inside>
				</Root>
			)}
		</Transition>
	);
};

Popup.defaultProps = {
	closeBtn: false,
	mortgage: false,
	handleClosePopup: () => {},
	onEntered: () => {},
	timeoutMS: null,
};

export default Popup;
