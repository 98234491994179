export const notificationsStyle = {
	Containers: {
		DefaultStyle: {
			width: '310px',
			padding: '16px',
			paddingBottom: '0px',
		},
	},
	NotificationItem: {
		DefaultStyle: {
			margin: '0',
			marginBottom: '8px',
			padding: '16px 24px',
			opacity: '0.9',
			border: 'none',
			borderRadius: '8px',
			boxShadow: 'none',
		},
		success: {
			backgroundColor: '#7cb342',
		},
		warning: {
			backgroundColor: '#e28413',
		},
		info: {
			backgroundColor: '#3498db',
		},
		error: {
			backgroundColor: '#cc3b1f',
		},
	},
	Title: {
		DefaultStyle: {
			margin: '0',
			color: '#fff',
			lineHeight: '1.4',
		},
	},
	Action: {
		DefaultStyle: {
			margin: '0px',
			marginTop: '8px',
			padding: '0px',
			fontSize: '12px',
			fontWeight: '500',
			textDecoration: 'underline',
			backgroundColor: 'transparent',
			cursor: 'pointer',
		},
	},
	Dismiss: {
		DefaultStyle: {
			display: 'none',
			// backgroundColor: 'transparent',
			// fontSize: '24px',
			// fontWeight: '100',
		},
	},
};

const AUTO_DISSMISS_TIME = 3;
const ACTION_LABEL = 'Закрыть';
const POSITION = 'tr';

export const notificationOpts = title => ({
	title,
	position: POSITION,
	autoDismiss: AUTO_DISSMISS_TIME,
	action: {
		label: ACTION_LABEL,
	},
});

// Профиль
export const successUpdateProfileNotificationOpts = notificationOpts('Отлично, ваши изменения успешно сохранены');
export const failUpdateProfileNotificationOpts = notificationOpts('Не удалось сохранить данные профиля');
export const succedPhoneVerify = notificationOpts('Номер телефона успешно подтвержден');
export const failSendPhoneVerifyCode = notificationOpts('Не удалось отправить SMS для подтверждения номера');

const renderTextByFavTypeId = id => {
	switch (id) {
		case 1:
		default:
			return 'Квартира добавлена в избранное';
		case 2:
			return 'ЖК добавлен в избранное';
		case 100:
			return 'Квартира добавлена в сравнение';
	}
};

const getActionLabel = id => {
	switch (id) {
		case 100:
			return 'Перейти к сравнению';
		default:
			return 'Войти в систему';
	}
};

// Избранное
export const addToFavWarningOpts = notificationOpts('Для добавления в избранное необходимо авторизоваться');
export const compareLengthErrorOpts = notificationOpts('Вы добавили максимальное количество вариантов в сравнение');
export const anonWarningOpts = (action = () => {}, favTypeID) => ({
	...notificationOpts(renderTextByFavTypeId(favTypeID)),
	autoDismiss: 5,
	action: { label: getActionLabel(favTypeID), callback: action },
});
export const ratingUpdateErrorOpts = notificationOpts(
	'Не удалось сохранить оценку. Перезагрузите страницу и попробуйте еще раз',
);

// Авторизация
export const successLoginOpts = notificationOpts('Вы успешно авторизованы');

// Бронирование
export const failNmarketBooking = notificationOpts('Не удалось отправить заявку. Попробуйте обновить страницу');

// LIRA
export const failLiraNextVariant = notificationOpts(
	'Не удалось загрузить следующий вариант. Попробуйте повторить попытку.',
);

// Статистика цены
export const failPriceStatsLoad = notificationOpts(
	'Не удалось загрузить статистику по цене. Перезагрузите страницу и попробуйте еще раз',
);
