import { BUYER_LIST_FETCH_ARTICLES_DATA } from '../constants/buyerList';
import { FAIL, START, SUCCESS } from '../constants';

const initialState = {
	articlesData: null,
	loading: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case BUYER_LIST_FETCH_ARTICLES_DATA + START:
			return { ...state, loading: true };

		case BUYER_LIST_FETCH_ARTICLES_DATA + SUCCESS:
			return { ...state, articlesData: action.payload, loading: false };

		case BUYER_LIST_FETCH_ARTICLES_DATA + FAIL:
			return { ...state, articlesData: null, loading: false };

		default:
			return state;
	}
};

export default reducer;
