import styled from 'styled-components';
import { MEDIA_ON_MOBILE } from '../../constants';

export const Root = styled.div`
	display: flex;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.18);
	border-radius: ${({ rounded }) => (rounded ? '5px' : '0')};
	overflow: hidden;

	@media ${MEDIA_ON_MOBILE} {
		width: ${({ noSlider }) => (noSlider ? '100%' : '170px')};
		flex-direction: column;
	}
`;

export const ArticleImage = styled.figure`
	position: relative;
	margin: 0;
	padding: 0;
	width: 275px;
	flex-shrink: 0;
	background-color: ${({ theme }) => theme.lightGrayColor};

	@media (max-width: 1024px) {
		width: 210px;
	}

	@media ${MEDIA_ON_MOBILE} {
		width: 100%;
		height: 160px;
	}

	img {
		width: 100%;
		min-height: 100%;
		object-fit: cover;
		object-position: center;
	}
`;

export const ArticleRating = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 90px;
	height: 90px;

	@media ${MEDIA_ON_MOBILE} {
		display: none;
	}
`;

export const ArticleTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex: 1 1 100%;
	padding: 14px 35px;

	@media ${MEDIA_ON_MOBILE} {
		padding: 14px;
	}
`;

export const ArticleTitle = styled.h3`
	font-weight: bold;
	font-size: 18px;
	line-height: 1.33;
	color: #333;

	a {
		color: #333;
		text-decoration: none;

		&:hover {
			color: ${({ theme }) => theme.primaryColor};
		}
	}

	@media ${MEDIA_ON_MOBILE} {
		font-size: 14px;
		line-height: 1.43;
		hyphens: auto;
	}
`;

export const ArticleText = styled.div`
	font-size: 14px;
	line-height: 1.43;
	color: #333;
	margin-bottom: 25px;

	p {
		margin-top: 0;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	@media ${MEDIA_ON_MOBILE} {
		display: none;
	}
`;

export const ArticleFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	justify-self: flex-end;
	margin-top: auto;
`;

export const ArticleDate = styled.div`
	margin-left: 20px;
	font-size: 12px;
	line-height: 1.33;
	color: #999;

	@media ${MEDIA_ON_MOBILE} {
		display: none;
	}
`;
