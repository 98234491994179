/* @flow */
import React, { type Node, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { TDispatch } from '../../types/core';
import type { TLiraLocationItem, TRegionItem } from '../../types/lira3';
import type { TLiraPopupsState } from '../../reducers/liraPopups';
import type { TLiraTemplatesState } from '../../reducers/liraTemplates';
import { ListWrapper, Root, SearchWrapper, Subtitle, Title } from './styles';
import {
	CHANGE_LIRA_USER_FILTER,
	LIRA_SET_SIDEBAR_SCREEN,
	LIRA_SET_TEMP_PARAMS,
	SET_BASE_FILTER,
} from '../../constants';
import Search from '../Search';
import { arrayUniqueAdd, arrayUniqueSubtract, uniqueArray } from '../../utils/arraysHelpers';
import { Grouped } from './Components/Grouped';
import type { TCity } from '../../types/city';
import { List } from './Components/List';
import Loader from '../Loader';
import Button from '../Button2.0';
import { ButtonsWrapper } from '../CommonFilters/styles';
import type { TRootState } from '../../types/rootState';
import type { TFilterBase } from '../../types/filters';
import type { TLira } from '../../types/lira4';

type TProps = {
	mode: 'area' | 'metro',
	oneColumn?: boolean,
	isHome?: boolean,
	isPopup?: boolean,
	isLira?: boolean,
	modalToggle?: () => {},
};

const FilterArea = ({ mode = 'area', oneColumn, isHome, isPopup, isLira, modalToggle }: TProps): Node => {
	const dispatch: TDispatch = useDispatch();
	const city: TCity = useSelector((state: TRootState) => state.city);
	const filterBase: TFilterBase = useSelector((state: TRootState) => state.filterBase);
	const lira: TLira = useSelector((state: TRootState) => state.lira);
	const liraPopups: TLiraPopupsState = useSelector((state: TRootState) => state.liraPopups);
	const liraTemplates: TLiraTemplatesState = useSelector((state: TRootState) => state.liraTemplates);

	const { filter } = lira;
	const { tempParams } = liraPopups;
	const { cities, metro } = liraTemplates;

	const [locationData, setLocationData] = useState([]);
	const [totalLocations, setTotalLocations] = useState([]);
	const [activeTab, setActiveTab] = useState(mode === 'area' ? 'abc' : 'local');
	const [searchValue, setSearchValue] = useState('');

	const handleBack = () => {
		dispatch({ type: LIRA_SET_SIDEBAR_SCREEN, payload: 'root' });

		if ((isHome || isPopup) && modalToggle) {
			modalToggle();
		}
	};

	const getCityLocations = (): TRegionItem[] => [
		{
			name: 'Город',
			code: cities?.city?.none ? 'none' : 'city',
			params: cities?.city?.none ?? [
				{
					name: 'Центр',
					code: 'center',
					params: cities?.city?.center ?? [],
				},
				{
					name: 'Север',
					code: 'north',
					params: cities?.city?.north ?? [],
				},
				{
					name: 'Запад',
					code: 'west',
					params: cities?.city?.west ?? [],
				},
				{
					name: 'Юг',
					code: 'south',
					params: cities?.city?.south ?? [],
				},
				{
					name: 'Восток',
					code: 'east',
					params: cities?.city?.east ?? [],
				},
			],
		},
	];

	const getMetroLocations = (): TRegionItem[] =>
		metro.reduce((acc: TRegionItem[], curr: TLiraLocationItem) => {
			const idx: number = acc.findIndex((item: TRegionItem) => item.name === curr.lineName);
			const { lineColor, lineName } = curr;
			if (idx === -1) {
				return [...acc, { name: lineName, lineColor, params: [{ ...curr }] }];
			}
			acc[idx].params.push({ ...curr });
			return acc;
		}, []);

	const changeSearchValue = (value) => {
		setSearchValue(value);
	};

	const targetField = mode === 'metro' ? 'metroIds' : 'areaIds';
	const source = tempParams;

	const handleChangeArea = (areas: number[], isActive: boolean) => () => {
		const targetType = LIRA_SET_TEMP_PARAMS;
		const payloadData = isActive
			? arrayUniqueSubtract(source[targetField], areas)
			: arrayUniqueAdd(source[targetField], areas);

		dispatch({
			type: targetType,
			payload: { [targetField]: payloadData },
		});
	};

	const handleSave = () => {
		dispatch({
			type: isLira ? CHANGE_LIRA_USER_FILTER : SET_BASE_FILTER,
			payload: isHome
				? { areaIds: source.areaIds, metroIds: source.metroIds }
				: { [targetField]: source[targetField] },
		});

		handleBack();
	};

	const handleReset = () => {
		dispatch({
			type: LIRA_SET_TEMP_PARAMS,
			payload: { [targetField]: [] },
		});
	};

	useEffect(() => {
		const cityLocations: TRegionItem[] = mode === 'metro' ? getMetroLocations() : getCityLocations();
		const regionLocations =
			mode === 'metro'
				? []
				: [
						{
							name: 'Область',
							code: 'region',
							params: cities?.region?.none ?? [],
						},
				  ];
		const rightCityArray =
			mode === 'area' && cityLocations[0].code === 'city' ? cityLocations[0].params : cityLocations;
		const cityIds = rightCityArray.reduce(
			(acc: number[], curr: TRegionItem) => [...acc, ...curr.params.map((item: TLiraLocationItem) => item.id)],
			[],
		);
		const regionIds = regionLocations.reduce(
			(acc: number[], curr: TRegionItem) => [...acc, ...curr.params.map((item: TLiraLocationItem) => item.id)],
			[],
		);
		const locationsIds = uniqueArray([...cityIds, ...regionIds]);

		setLocationData([...cityLocations, ...regionLocations]);
		setTotalLocations(locationsIds);
	}, [liraTemplates, mode]);

	useEffect(() => {
		if (searchValue.length > 0) {
			setActiveTab('abc');
		}
	}, [searchValue]);

	useEffect(() => {
		if (typeof window !== 'undefined' && window?.scrollY > 0) {
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		}
		dispatch({
			type: LIRA_SET_TEMP_PARAMS,
			payload: { [targetField]: isLira ? filter[targetField] : filterBase[targetField] },
		});
	}, []);

	if (totalLocations?.length > 0) {
		return (
			<>
				<Root>
					{!isHome && (
						<>
							<Subtitle>Параметры подбора квартиры</Subtitle>
							<Title>{mode === 'metro' ? 'Метро' : 'Район'}</Title>
						</>
					)}
					<SearchWrapper>
						<Search
							data={{ type: 'text', placeholder: 'Поиск' }}
							value={searchValue}
							handleChangeSearch={changeSearchValue}
						/>
					</SearchWrapper>
					<ListWrapper $oneColumn={oneColumn}>
						{/* !searchValue && (
						<CheckboxButton
							onClick={handleChangeArea(
								totalLocations,
								arraysCompare(totalLocations, source[targetField]),
							)}
							label={mode === 'metro' ? 'Все станции' : 'Все районы'}
							isActive={arraysCompare(totalLocations, source[targetField])}
							oneColumn
						/>
					) */}
						{(activeTab === 'local' || mode === 'metro') && searchValue.length <= 0 ? (
							<Grouped
								mode={mode}
								data={locationData}
								handleChangeArea={handleChangeArea}
								targetField={targetField}
								source={source}
								city={city}
							/>
						) : (
							<List
								mode={mode}
								data={mode === 'metro' ? metro : cities}
								handleChangeArea={handleChangeArea}
								targetField={targetField}
								source={source}
								searchValue={searchValue.toLowerCase()}
							/>
						)}
					</ListWrapper>
				</Root>
				<ButtonsWrapper $isHome={isHome} $isPopup={isPopup}>
					<Button
						color="gray"
						size="s"
						design="rounded"
						bordered
						onClick={isHome ? handleReset : handleBack}
						disabled={
							isHome &&
							((mode === 'area' && tempParams?.areaIds?.length === 0) ||
								(mode === 'metro' && tempParams?.metroIds?.length === 0))
						}
					>
						{isHome ? 'Сбросить' : 'Назад'}
					</Button>
					<Button color="primary" size="s" design="rounded" onClick={handleSave}>
						{isHome ? 'Применить' : 'Сохранить'}
					</Button>
				</ButtonsWrapper>
			</>
		);
	}
	return (
		<Root>
			<Loader />
		</Root>
	);
};

FilterArea.defaultProps = {
	oneColumn: true,
	isHome: false,
	isPopup: false,
	isLira: false,
	modalToggle: () => {},
};

export default FilterArea;
