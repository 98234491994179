/* @flow */
import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';
import { Inside } from '../Popup/styles';
import Button from '../Button';

export const PopupInside = styled(Inside)`
	max-width: 583px;
	padding-bottom: 30px;
`;

export const FetchingInside = styled(Inside)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 1200px;
	height: 100%;
	max-height: 675px;
	box-sizing: border-box;
	background-color: #fff;
`;

export const Title = styled.h2`
	margin: 0 0 24px;
	font-size: 36px;
	font-weight: bold;
	line-height: 1.15;
	text-align: center;
`;

export const Description = styled.p`
	margin: -16px 0 32px;
	font-size: 14px;
	font-weight: 500;
	text-align: center;

	@media (max-width: 768px) {
		margin-bottom: 24px;
	}
`;

export const FetchingDescription = styled(Description)`
	width: 100%;
	max-width: 420px;
	margin: 0 0 24px;
`;

export const Label = styled.label`
	position: relative;
	display: block;
	margin-bottom: 32px;
	font-size: 14px;
	font-weight: 500;

	@media (max-width: 768px) {
		margin-bottom: 24px;
	}
`;

export const Label50 = styled(Label)`
	display: inline-block;
	width: 50%;
	padding-right: 20px;
	box-sizing: border-box;

	&:last-of-type {
		padding-right: 0;
	}

	@media (max-width: 767px) {
		display: block;
		width: 100%;
		padding-right: 0;
	}
`;

const InputStyle = css`
	display: block;
	width: 100%;
	height: 48px;
	margin-top: 5px;
	padding: 15px;
	box-sizing: border-box;
	font-size: 14px;
	font-weight: 500;
	background-color: #fff;
	border: solid 1px #d8d8d8;

	@media (max-width: 768px) {
		height: 40px;
	}
`;

export const Input = styled.input`
	${InputStyle}
`;

export const PhoneInput = styled(InputMask)`
	${InputStyle}

	${({ hasError, theme: { primaryColor } }) =>
		hasError &&
		`
	border: 1px solid ${primaryColor};

	&:focus {
		outline: none;
	}
	`}
`;

export const FetchingBody = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	min-height: 435px;
	box-sizing: border-box;
	width: 100%;
	max-width: 585px;
`;

export const LoaderWrapper = styled.div`
	margin-bottom: 32px;
`;

export const ErrorMessage = styled.span`
	position: absolute;
	bottom: -20px;
	left: 0;
	font-size: 12px;
	font-weight: 500;
	color: ${(props) => props.theme.primaryColor};
`;

export const BottomLine = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 16px;
`;

export const Note = styled.div`
	margin-top: 16px;
	font-size: 12px;
	font-weight: 500;
	color: ${(props) => props.theme.darkGrayColor};
	text-align: center;
`;

export const PhoneNote = styled.div`
	margin-top: 24px;
	font-size: 12px;
	font-weight: 500;
	text-align: center;

	a {
		color: #000;
		text-decoration: none;
		white-space: nowrap;
	}
`;

export const CallbackBody = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: flex-start;
`;

export const Response = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 435px;
	box-sizing: border-box;

	${Title} {
		margin-bottom: 24px;
	}

	${Description} {
		margin: 0 0 32px;
	}
`;

export const Clearfix = styled.div`
	width: 100%;
	clear: both;
`;

export const SelectWrapper = styled.div`
	position: relative;
	width: 100%;

	&:after {
		content: '';
		position: absolute;
		top: calc(50% + 2.5px);
		right: 12px;
		transform: translateY(-50%);
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid #9b9b9b;
		pointer-events: none;
	}
`;

export const Select = styled.select`
	width: 100%;
	height: 48px;
	margin-top: 5px;
	padding: 0 32px 0 16px;
	box-sizing: border-box;
	font-size: 14px;
	font-weight: 500;
	background-color: transparent;
	border: solid 1px #d8d8d8;
	border-radius: 0;
	appearance: none;

	& > option {
		text-transform: capitalize;
	}
`;

export const DeveloperInfo = styled.div`
	width: 100%;
	margin: 0 0 30px 0;
	padding: 20px;
	border-radius: 16px;
	border: solid 8px #fefce8;
	display: flex;
	box-sizing: border-box;

	@media (max-width: 520px) {
		flex-direction: column;
		align-items: center;
	}
`;

export const DeveloperLogo = styled.div`
	width: 130px;
	min-height: 100px;
	flex-shrink: 0;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	margin-right: 20px;

	@media (max-width: 520px) {
		margin-right: 0;
		margin-bottom: 20px;
	}
`;

export const DeveloperText = styled.div`
	p {
		font-size: 14px;
		font-weight: 500;
		margin-top: 0;
		margin-bottom: 14px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;

export const StyledBtn = styled(Button)`
	@media (max-width: 440px) {
		font-size: 14px;
	}
`;

export const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;
