/* @flow */

import React from 'react';
import styled, { keyframes } from 'styled-components';

import type { Node } from 'react';

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 125px;
	height: 125px;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 0 23px 18px -6px rgba(0, 0, 0, 0.1);
`;

const Icon = styled.svg`
	width: 120px;
	height: 120px;
`;

const IconBg = styled.circle`
	stroke-width: 5px;
	stroke: #f5f5f5;
	fill: none;
`;

const countdown = keyframes`
	from {
		stroke-dashoffset: 360px;
	}

	to {
		stroke-dashoffset: 0;
	}
`;

const IconFill = styled.circle`
	stroke-dasharray: 360px;
	stroke-dashoffset: 360px;
	stroke-linecap: round;
	stroke-width: 5px;
	stroke: #cc3b1f;
	fill: none;
	animation: ${countdown} 5s linear infinite forwards;
`;

type Props = {
	progress?: boolean,
	shape: Node,
};

const CircleLoaderWithIcon = ({ shape, progress }: Props): Node => {
	return (
		<IconWrapper>
			<Icon>
				{shape}
				<IconBg r="50" cx="50%" cy="50%" />
				{progress && <IconFill r="50" cx="50%" cy="50%" transform={`rotate(-90,${120 / 2},${120 / 2})`} />}
			</Icon>
		</IconWrapper>
	);
};

CircleLoaderWithIcon.defaultProps = {
	progress: false,
};

export default CircleLoaderWithIcon;
