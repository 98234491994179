/* @flow */

import { TLiraLocationItem } from '../types/lira3';

export const months = [
	'января',
	'февраля',
	'марта',
	'апреля',
	'мая',
	'июня',
	'июля',
	'августа',
	'сентября',
	'октября',
	'ноября',
	'декабря',
];

export const weekDays = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

export const weekDaysFull = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];

export function truncateString(str: string, maxlength: number): string {
	if (str === null || str === undefined) return '';

	if (typeof str !== 'string') return str;

	if (maxlength <= 3) return `${str.slice(0, maxlength).trim()}...`;

	if (str.length > maxlength) return `${str.slice(0, maxlength - 3).trim()}...`;

	return str;
}

export function stripHtmlTag(string: string) {
	return string.replace(/(<([^>]+)>)|(&([^;]+);)/g, '');
}

export function formatPlainPhone(phoneString: string): string {
	// TODO: remove non digit at first

	if (phoneString.length === 10) {
		return phoneString.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, '+7 ($1) $2-$3-$4');
	}

	if (phoneString.length === 12) {
		return phoneString.replace(/(\+7)(\d{3})(\d{3})(\d{2})(\d{2})/g, '+7 ($2) $3-$4-$5');
	}

	return phoneString;
}

// TODO: Перенести из этого файла в более подходящий по смыслу?
export function getApartRoomsAndNumberString(
	roomsCnt: number,
	apartNumber: string | number,
	declension: boolean = false,
) {
	const flat: string = declension ? 'квартире' : 'квартира';
	const studio: string = declension ? 'студии' : 'Студия';
	const rooms: string = roomsCnt ? `${roomsCnt}-к ${flat}` : studio;
	const number: string = apartNumber ? ` №${apartNumber}` : '';

	return `${rooms}${number}`;
}

export function getApartRoomsAndSquareString(roomsCnt: number, square: number, declension: boolean = false) {
	const flat: string = declension ? 'квартире' : 'квартира';
	const studio: string = declension ? 'студии' : 'Студия';
	const rooms: string = roomsCnt ? `${roomsCnt}-к ${flat}` : studio;
	const squareText: string = square ? ` ${square}\u00A0м\u00B2` : '';

	return `${rooms}${squareText}`;
}

export function getApartRoomsAndSquareAndFloorString(
	roomsCnt: number,
	square: number,
	floor: number = null,
	floorTotal: number = null,
	declension: boolean = false,
) {
	const flat: string = declension ? 'квартире' : 'квартира';
	const studio: string = declension ? 'студии' : 'Студия';
	const rooms: string = roomsCnt ? `${roomsCnt}-к ${flat}` : studio;
	const squareText: string = square ? ` ${square}\u00A0м\u00B2` : '';
	const floorText: string = floor ? `, этаж ${floor}${floorTotal ? `/${floorTotal}` : ''}` : '';

	return `${rooms}${squareText}${floorText}`;
}

/**
 * Форматирование строки срока передачи ключей
 * @param {string} dateString - Срок передачи ключей
 * @param {string} mode - Формат строки на выходе
 * @returns {string} - Форматированный срок передачи ключей
 *
 * @example
 * // returns '30.06.2017'
 * formatKeyTransferDate('2017-06-30')
 * // returns '30 июня 2017'
 * formatKeyTransferDate('2017-06-30', 'long')
 */
export function formatKeyTransferDate(dateString: string, mode?: 'long'): string {
	const [year, month, day] = dateString.split('-');

	if (mode === 'long') {
		const monthName = months[Number(month) - 1];
		return `${day} ${monthName} ${year}`;
	}

	return `${day}.${month}.${year}`;
}

export function formatTime(totalMinutes: number) {
	if (totalMinutes < 60) {
		return `${totalMinutes} мин`;
	}

	const hours = Math.trunc(totalMinutes / 60);
	const minutes = totalMinutes - hours * 60;

	if (minutes === 0) {
		return `${hours} ч.`;
	}

	return `${hours} ч. ${minutes} мин.`;
}

/**
 * Форматирование чисел и добавление метров, рублей
 * @param {number} value - число
 * @param {'rub' | 'square' | 'rubSquare' | 'rubMonth' | 'custom'} mode - режим
 * @param {string} custom - "свой" формат после числа
 * @returns {string|null} - Форматированное число *
 * @example
 * // returns '120 000 ₽/м²'
 * formatValuesNumber(120000, 'rubSquare')
 * // returns '12 000 $'
 * formatValuesNumber(12000, 'custom', '$')
 */
export const formatValuesNumber = (
	value: number,
	mode?: 'rub' | 'square' | 'rubSquare' | 'rubMonth' | 'custom',
	custom?: string,
) => {
	if (value) {
		const NUM = new Intl.NumberFormat('ru-RU').format(value);
		let AFTER = '';

		switch (mode) {
			case 'rub':
				AFTER = `\u00A0\u20BD`;
				break;
			case 'square':
				AFTER = `\u00A0м\u00B2`;
				break;
			case 'rubSquare':
				AFTER = `\u00A0\u20BD/м\u00B2`;
				break;
			case 'rubMonth':
				AFTER = `\u00A0\u20BD/мес`;
				break;
			case 'custom':
				AFTER = `\u00A0${custom}`;
				break;
			default:
				return null;
		}

		return NUM + AFTER;
	}
	return null;
};

/**
 * Поиск названия станции/района из массива liraTemplates
 * @param typeArray - liraTemplates.cities | liraTemplates.metro
 * @param id - number
 */
export const getMetroAreaName = (typeArray: TLiraLocationItem[], id: number) =>
	typeArray.find((item: TLiraLocationItem) => item.id === id)?.name;

/**
 * Вывод строки "Название метро/района + N"
 * @param prefix - например, "Район: "
 * @param array - массив ID
 * @param template - liraTemplates.cities | liraTemplates.metro
 * @returns {string}
 */
export const metroAreaNaming = (prefix: string, array: number[], template: TLiraLocationItem[]) => {
	let tempString: string = prefix;
	const metroAreaName = getMetroAreaName(template, array[0]);
	if (metroAreaName) {
		tempString += ` ${metroAreaName}`;
	}
	if (array?.length > 1) {
		tempString += ` +${metroAreaName ? array.length - 1 : array.length}`;
	}
	return tempString;
};

export const stringToBoolean = (stringValue) => {
	if (typeof stringValue === 'boolean') return stringValue;

	if (stringValue === 'true') return true;
	if (stringValue === 'false') return false;

	return null;
};

/**
 * Removes duplicate underscores and dashes from a given text.
 *
 * @param {string} text - The text from which to remove duplicate underscores and dashes.
 * @returns {string} - The text with duplicate underscores and dashes removed.
 */
export const removeDuplicateUnderscoresAndDashes = (text) => text.replace(/[_-]{2,}/g, (match) => match[0]);
