// @flow

import { logout } from '../actions/profile';

export const interceptStatus401 =
	(context) =>
	async (response: Response): Response => {
		const { status } = response;
		if (status === 401 && window.App.state?.token.token) {
			// Call logout action on invalid token
			logout()(context.store.dispatch, context.store, context);
		}
		return response;
	};
