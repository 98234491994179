import { ARTICLE_FETCH_ARTICLE_DATA, ARTICLE_CLEAR } from '../constants/article';
import { FAIL, START, SUCCESS } from '../constants';
import type { TArticleKnowledge } from '../types/articles';

export type TArticle = {
	articleData: TArticleKnowledge,
	loading: boolean,
};

const initialState = {
	articleData: null,
	loading: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ARTICLE_FETCH_ARTICLE_DATA + START:
			return { ...state, loading: true };

		case ARTICLE_FETCH_ARTICLE_DATA + SUCCESS:
			return { ...state, articleData: action.payload, loading: false };

		case ARTICLE_FETCH_ARTICLE_DATA + FAIL:
			return { ...state, loading: false };

		case ARTICLE_CLEAR:
			return {
				...initialState,
			};

		default:
			return state;
	}
};

export default reducer;
