/* @flow */
export const SET_LEXS_FILTER = 'SET_LEXS_FILTER';
export const SET_LEXS_QUESTIONS = 'SET_LEXS_QUESTIONS';
export const CHANGE_LEXS_FILTER = 'CHANGE_LEXS_FILTER';
export const GET_LEXS_FILTER_STATS = 'GET_LEXS_FILTER_STATS';
export const CLEAR_RELEVANCE_FILTER = 'CLEAR_RELEVANCE_FILTER';
export const CALCULATE_CHOICE_ACCURACY = 'CALCULATE_CHOICE_ACCURACY';
export const SET_SELECTION_HISTORY_ID = 'SET_SELECTION_HISTORY_ID';
export const SET_LEXS_TEMPLATES = 'SET_LEXS_TEMPLATES';

export const CHANGE_LEXS_COMPLEXES = 'CHANGE_LEXS_COMPLEXES';
export const LOAD_LEXS_SUGGESTION = 'LOAD_LEXS_SUGGESTION';
export const LOAD_NEXT_LEXS_SUGGESTION = 'LOAD_NEXT_LEXS_SUGGESTION';

export const CHANGE_LEXS_CROSS = 'CHANGE_LEXS_CROSS';

export const INCREMENT_LEXS_COMPLEXES_PAGE = 'INCREMENT_LEXS_COMPLEXES_PAGE';

export const BACKUP_LEXS_FILTER = 'BACKUP_LEXS_FILTER';
export const RESTORE_LEXS_FILTER = 'RESTORE_LEXS_FILTER';

export const SET_LEXS_ANSWERS = 'SET_LEXS_ANSWERS';
export const SET_ACTIVE_QUESTION = 'SET_ACTIVE_QUESTION';
export const SET_PREV_QUESTION = 'SET_PREV_QUESTION';
export const SET_NEXT_QUESTION = 'SET_NEXT_QUESTION';
export const SET_QUESTIONS_FINISH = 'SET_QUESTIONS_FINISH';

export const GET_LEXS_PDF = 'GET_LEXS_PDF';
export const CLEAR_LEXS_PDF_STATUS = 'CLEAR_LEXS_PDF_STATUS';
