/* @flow */
import React, { type Node, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Check from '../../Check';
import type { TDispatch } from '../../../types/core';
import type { TProfile } from '../../../types/account';
import type { TRootState } from '../../../types/rootState';
import { changeProfile } from '../../../actions/profile';

const TelegramStage = (): Node => {
	const dispatch: TDispatch = useDispatch();
	const profile: TProfile = useSelector((state: TRootState) => state.profile);
	const { isAgree } = profile;

	const onPersonalChange = useCallback((event: Event): any => {
		dispatch(changeProfile({ isAgree: !!event.target.checked }));
	}, []);

	return <Check id="agree" name="agree" checked={isAgree} personal onChange={onPersonalChange} />;
};

export default TelegramStage;
