/* @flow */
import type { TCityAreasList, TLiraInitialData, TLiraLocationItem, TLiraTemplate, TLiraWishes } from '../types/lira3';
import type { TAction } from '../types/core';
import {
	FAIL,
	LIRA_GET_CITY_AREAS,
	LIRA_GET_CITY_METRO,
	LIRA_GET_INITIAL_DATA,
	LIRA_GET_TEMPLATES,
	LIRA_GET_WISHES,
	START,
	SUCCESS,
} from '../constants';

export type TLiraTemplatesState = {
	portraits: TLiraTemplate[] | null,
	cities: TCityAreasList | null,
	metro: TLiraLocationItem[] | null,
	wishes: TLiraWishes[] | null,
	initials: TLiraInitialData | null,
	isLoading: boolean,
	isLoaded: boolean,
};

const initialState: TLiraTemplatesState = {
	portraits: null,
	cities: null,
	metro: null,
	wishes: null,
	initials: null,
	isLoading: false,
	isLoaded: false,
};

const liraTemplates = (state: TLiraTemplatesState = initialState, action: TAction): TLiraTemplatesState => {
	switch (action.type) {
		case LIRA_GET_INITIAL_DATA + START:
			return { ...state, isLoading: true, isLoaded: false };

		case LIRA_GET_INITIAL_DATA + SUCCESS:
			return { ...state, initials: action.payload, isLoading: false, isLoaded: true };

		case LIRA_GET_INITIAL_DATA + FAIL:
			return { ...state, isLoading: false, isLoaded: false };

		case LIRA_GET_TEMPLATES + START:
			return { ...state, isLoading: true, isLoaded: false };

		case LIRA_GET_TEMPLATES + SUCCESS:
			return { ...state, portraits: action.payload, isLoading: false, isLoaded: true };

		case LIRA_GET_TEMPLATES + FAIL:
			return { ...state, isLoading: false, isLoaded: false };

		case LIRA_GET_CITY_AREAS + START:
			return { ...state, isLoading: true };

		case LIRA_GET_CITY_AREAS + SUCCESS:
			return { ...state, cities: action.payload, isLoading: false };

		case LIRA_GET_CITY_AREAS + FAIL:
			return { ...state, isLoading: false };

		case LIRA_GET_CITY_METRO + START:
			return { ...state, isLoading: true };

		case LIRA_GET_CITY_METRO + SUCCESS:
			return { ...state, metro: action.payload, isLoading: false };

		case LIRA_GET_CITY_METRO + FAIL:
			return { ...state, isLoading: false };

		case LIRA_GET_WISHES + START:
			return { ...state, isLoading: true };

		case LIRA_GET_WISHES + SUCCESS:
			return { ...state, wishes: action.payload, isLoading: false };

		case LIRA_GET_WISHES + FAIL:
			return { ...state, isLoading: false };

		default:
			return state;
	}
};

export default liraTemplates;
export { initialState as liraTemplateInitialState };
