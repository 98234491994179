/* @flow */
import { SET_TOKEN, DELETE_TOKEN, CLEAR_STORE, SET_IS_BOT } from '../constants';

export function setToken({ token, tokenExpires, refreshToken, isAnonymous }) {
	return {
		type: SET_TOKEN,
		payload: {
			token,
			tokenExpires,
			refreshToken,
			isAnonymous,
		},
	};
}

export function deleteToken() {
	return {
		type: DELETE_TOKEN,
	};
}

export function clearStore() {
	return {
		type: CLEAR_STORE,
	};
}

export function setIsBot(isBot) {
	return {
		type: SET_IS_BOT,
		payload: isBot,
	};
}
