import styled from 'styled-components';

export const Root = styled.div`
	position: relative;

	::-webkit-input-placeholder {
		color: ${({ theme }) => theme.borderColor};
	}

	::-moz-placeholder {
		color: ${({ theme }) => theme.borderColor};
	}

	:-ms-input-placeholder {
		color: ${({ theme }) => theme.borderColor};
	}

	:-moz-placeholder {
		color: ${({ theme }) => theme.borderColor};
	}

	/* IE10+ */

	::-ms-clear {
		display: none;
	}

	/* Chrome */

	::-webkit-search-decoration,
	::-webkit-search-cancel-button,
	::-webkit-search-results-button,
	::-webkit-search-results-decoration {
		display: none;
	}
`;

export const Input = styled.input`
	width: 100%;
	height: 45px;
	padding-left: 20px;
	padding-right: 100px;
	border: 1px solid ${({ theme }) => theme.middleGrayColor};
	border-radius: 4px;
	box-sizing: border-box;
	outline: none;
	transition: border-color 0.3s;
	${({ theme }) => theme.typography.textL};

	&:focus {
		border-bottom-color: ${({ theme }) => theme.darkGrayColor};
	}
`;

export const ButtonClear = styled.button`
	width: 30px;
	height: 100%;
	position: absolute;
	right: 50px;
	top: 0;
	cursor: pointer;
	text-align: center;
	background-color: transparent;
	border: none;
	padding: 0;
	visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
	opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
	transition: visibility 0s 0.3s ease, opacity 0.3s ease;

	&:before,
	&::after {
		content: '';
		position: absolute;
		top: 10px;
		height: 2px;
		width: 80%;
		background-color: ${({ theme }) => theme.accentColor};
	}

	&::before {
		left: 7px;
		transform: rotate(45deg);
		transform-origin: 0 0 0;
	}

	&::after {
		right: 7px;
		transform: rotate(-45deg);
		transform-origin: 100% 0 0;
	}
`;

export const ButtonSearch = styled.button`
	background-color: transparent;
	border: none;
	padding: 0;
	position: absolute;
	width: 50px;
	height: 100%;
	top: 0;
	right: 0;
	cursor: pointer;
	text-align: center;
`;

export const Icon = styled.svg`
	width: 20px;
	height: 21px;
	fill: ${({ theme }) => theme.darkGrayColor};
	stroke: none;
	transition: fill 0.3s;

	&:hover {
		fill: ${({ theme }) => theme.primaryBlackColor};
	}
`;
