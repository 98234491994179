import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';
import loadable from '@loadable/component';

import SocialList from '../SocialList/SocialList';

import { dropdownIcon } from '../Icons';
import type { TRootState } from '../../types/rootState';
import type { TAppState } from '../../reducers/app';
import { SHOW_SEARCH_POPUP } from '../../constants';
import type { TDispatch } from '../../types/core';
import {
	About,
	AboutLink,
	ANIMATION_TIME,
	Bottom,
	BottomWrapper,
	Container,
	Copy,
	DropdownIcon,
	LinkWrapper,
	Navigation,
	NavigationColumn,
	NavLink,
	Root,
	ToggleButton,
	Top,
	TopWrapper,
} from './styles';

const SearchPopup = loadable(() => import('../SearchPopup'), { fallback: null, ssr: false });

const delayed = (cb) => setTimeout(cb, ANIMATION_TIME);

const FoldingFooter = ({ data, route }) => {
	const dispatch: TDispatch = useDispatch();
	const app: TAppState = useSelector((state: TRootState) => state.app);
	const {
		isMobile,
		appPopup: { show },
		showSearch,
		showFooter,
	} = app;

	const [isOpen, setIsOpen] = useState(false);
	const [isRunnig, setIsRunnig] = useState(false);

	const toogleOpen = () => {
		setIsOpen(!isOpen);
		setIsRunnig(true);

		delayed(() => setIsRunnig(false));
	};

	const handleMouseLeave = () => {
		if (isRunnig || !isOpen) {
			return;
		}

		delayed(() => toogleOpen());
	};

	const showSearchPopup = (action) => () => {
		dispatch({ type: SHOW_SEARCH_POPUP, payload: action });
	};

	return (
		<Root onMouseLeave={handleMouseLeave} $popupShow={show} $isVisible={showFooter}>
			<BottomWrapper>
				<Container>
					<Bottom>
						<ToggleButton
							onClick={toogleOpen}
							type="button"
							id="footerButtonToggle"
							data-qa="footer_expand"
							aria-haspopup="true"
							aria-expanded={isOpen}
						>
							{isOpen ? 'Свернуть' : 'Развернуть'}

							<DropdownIcon viewBox="0 0 10 5" isOpen={isOpen}>
								{dropdownIcon}
							</DropdownIcon>
						</ToggleButton>
						<Copy>
							{`© 2014—${new Date().getFullYear()} Living. Все права на материалы сайта защищены законом РФ.`}
						</Copy>
						<SocialList data={data.social} links mode="black" footer />
					</Bottom>
				</Container>
			</BottomWrapper>
			{!isMobile && (
				<TopWrapper isOpen={isOpen}>
					<Container>
						<Top>
							<Navigation aria-hidden={!isOpen} aria-label="Нижняя навигация">
								{data &&
									data.navigation &&
									data.navigation.map((navigationGroup, index) => (
										<NavigationColumn key={Math.random()}>
											{index === 0 && (
												<LinkWrapper>
													<NavLink
														onClick={showSearchPopup(true)}
														id="footerNavLink_search}"
														data-qa="footer_search"
														tabIndex={isOpen ? undefined : -1}
													>
														Поиск
													</NavLink>
												</LinkWrapper>
											)}
											{navigationGroup.map((navigationLink) => (
												<LinkWrapper key={navigationLink.id.toString()}>
													<NavLink
														to={navigationLink.link}
														id={`footerNavLink_${navigationLink.id.toString()}`}
														data-qa={`footer_${navigationLink.dataQa}`}
														tabIndex={isOpen ? undefined : -1}
													>
														{navigationLink.title}
													</NavLink>
												</LinkWrapper>
											))}
										</NavigationColumn>
									))}
							</Navigation>

							<About>
								{/* Сетевое издание LIVING зарегистрировано Роскомнадзором 30.11.2017&nbsp;г.
								за&nbsp;номером{' '}
								<AboutLink
									href="https://rkn.gov.ru/mass-communications/reestr/media/?id=646959"
									target="_blank"
									rel="noopener noreferrer"
								>
									ЭЛ&nbsp;№&nbsp;ФС&nbsp;77&nbsp;–&nbsp;71723
								</AboutLink>
								. Учредитель:&nbsp;ООО&nbsp;«Умный&nbsp;поиск».
								<br />
								Главный редактор:&nbsp;Бабенко Наталья Владимировна
								<br />
								Адрес редакции:&nbsp;191186, г.&nbsp;Санкт-Петербург, наб.&nbsp;реки&nbsp;Мойки, д.37,
								оф.&nbsp;10Н,
								<br />
								тел.&nbsp;+7&nbsp;(812)&nbsp;337&nbsp;2766,&nbsp;
								<a href="mailto:editor@living.ru">editor@living.ru</a>
								<br /> */}
								Любое использование материалов допускается только при&nbsp;наличии гиперссылки на&nbsp;
								<AboutLink tabIndex={-1} href="https://living.ru">
									living.ru
								</AboutLink>
								.<br />
								Информационная продукция предназначена для&nbsp;взрослых и&nbsp;детей старше
								16&nbsp;лет.
							</About>
						</Top>
					</Container>
				</TopWrapper>
			)}
			<Portal>
				<SearchPopup isVisible={showSearch} closeSearch={showSearchPopup(false)} route={route} />
			</Portal>
		</Root>
	);
};

FoldingFooter.propTypes = {
	data: PropTypes.shape({
		navigation: PropTypes.arrayOf(
			PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number.isRequired,
					link: PropTypes.string.isRequired,
					title: PropTypes.string.isRequired,
				}),
			),
		).isRequired,
		social: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				link: PropTypes.string.isRequired,
				type: PropTypes.string.isRequired,
			}),
		).isRequired,
	}).isRequired,
	route: PropTypes.string.isRequired,
};

export default FoldingFooter;
