import styled, { css } from 'styled-components';
import { FEED_WIDTH } from '../Feed/constants';

const Feed = css`
	display: flex;
	justify-content: center;
	position: fixed;
	right: ${FEED_WIDTH}px;
	border-radius: 0;
	transform: translateX(200%);

	width: 100%;
	max-width: ${FEED_WIDTH}px;
	height: 100%;
	box-sizing: border-box;
	overflow: hidden;
	background-color: #fff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	transition: transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);

	${(props) => props.transition === 'entering' && 'transform: translateX(1%)'};
	${(props) => props.transition === 'entered' && 'transform: translateX(100%)'};

	@media (max-width: 768px) {
		right: 0;
		max-width: 100%;
		transform: none;
	}
`;

export const ModalContent = styled.div`
	border-radius: 4px;
	background-color: ${({ $transparent }) => ($transparent ? 'transparent' : '#fff')};
	display: inline-block;
	position: relative;
	width: ${({ $withContainer, $fullWidth }) => ($withContainer || $fullWidth ? '100%' : 'unset')};
	max-width: ${({ $withContainer }) => ($withContainer ? '1360px' : 'unset')};
	max-height: 100vh;
	max-height: ${({ theme }) => theme.fullHeight};
	overflow: ${({ $overflowEnabled }) => ($overflowEnabled ? 'visible' : 'auto')};
	outline: none;
	z-index: 1300;

	@media (max-width: 768px) {
		transition: none;
		box-sizing: border-box;
	}

	@media (min-width: 769px) {
		max-height: ${({ $fullHeight }) => ($fullHeight ? '100vh' : 'calc(100vh - 64px)')};
	}

	${({ $isFeed }) => $isFeed && Feed};

	&::-webkit-scrollbar {
		width: 6px;
		background-color: #f5f5f5;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #999;
		border-radius: 4px;
	}
`;

export const ModalRoot = styled.div`
	position: ${({ noPortal }) => (noPortal ? 'absolute' : 'fixed')};
	width: 100%;
	height: ${({ noPortal }) => (noPortal ? '100%' : '100vh')};
	top: 0;
	left: 0;
	z-index: 9000;
	background-color: rgba(86, 86, 86, 0.75);
	transition: opacity 0.3s ease;
	display: flex;
	justify-content: center;
	align-items: center;

	&:focus {
		outline: none;
	}

	@media (max-width: 768px) {
		-webkit-overflow-scrolling: touch;
		transition: none;
	}

	@media (max-width: 600px) {
		height: 100vh;
		height: ${({ theme }) => theme.fullHeight};

		${ModalContent} {
			${({ noPortal }) =>
				noPortal &&
				`
				width: auto;
				height: auto;
			`}
		}
	}
`;

export const BtnClose = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	position: ${({ $fixed }) => ($fixed ? 'fixed' : 'absolute')};
	top: 10px;
	right: ${({ $positionPx }) => `${$positionPx}px`};
	outline: none;
	width: 30px;
	height: 30px;
	background-color: #fff;
	cursor: pointer;
	border: none;
	border-radius: 50%;
	padding: 0;
	font-size: 24px;
	line-height: 1;
	z-index: 9001;

	&:focus {
		border-radius: 50%;
		outline: ${({ theme }) => `1px solid ${theme.primaryColor}`};
	}

	span {
		margin-top: -3px;
	}
`;

export const NavPanel = styled.div`
	display: flex;
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 1202;
`;

export const NavButton = styled.button`
	width: 30px;
	height: 30px;
	color: ${(props) => (props.$disabled ? '#e0e0e0' : '#333')};
	background-color: #fff;
	cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
	border: none;
	border-radius: 50%;
	padding: 0;
	font-size: 24px;
	line-height: 1;
	outline: none;

	& svg {
		fill: currentColor;
	}
`;
