import { SET_CITY_CONFIG, CHANGE_CITY_CONFIG, CLEAR_CITY_CONFIG, CLEAR_STORE } from '../constants';

const initialState = null;

export default function cityConfig(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_CITY_CONFIG:
			return {
				...payload,
			};

		case CHANGE_CITY_CONFIG:
			return {
				...state,
				...payload,
			};

		// case CLEAR_STORE:
		case CLEAR_CITY_CONFIG:
			return initialState;

		default:
			return state;
	}
}
