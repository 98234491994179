/* @flow */
import React, { type Node } from 'react';
import { navigateBack, navigateNext } from '../Icons';
import { NavButton } from './styles';

export type TNavigationButtonProps = {
	mode?: 'back' | 'forward',
	icon?: Node,
	disabled?: boolean,
	visible?: boolean,
	onClick: () => any,
};

const selectIcon = (mode: string): Node => {
	switch (mode) {
		case 'back':
			return navigateBack;
		case 'forward':
			return navigateNext;
		default:
			return navigateBack;
	}
};

const NavigationButton = (props: TNavigationButtonProps): Node => {
	const { mode, icon = null, disabled = false, visible = true, onClick = () => {} } = props;

	const ButtonIcon = mode ? selectIcon(mode) : icon;

	if (!ButtonIcon || !visible) return null;

	return (
		<NavButton $disabled={disabled} onClick={onClick}>
			<svg viewBox="0 0 24 24" width={28} height={28}>
				{ButtonIcon}
			</svg>
		</NavButton>
	);
};

NavigationButton.defaultProps = {
	mode: undefined,
	icon: null,
	disabled: false,
	visible: true,
};

export default NavigationButton;
