import styled, { css } from 'styled-components';
import Link from '../Link';

export const ANIMATION_TIME = 500;

export const Root = styled.footer`
	position: relative;
	display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
	width: 100%;
	z-index: ${({ $popupShow }) => ($popupShow ? '10' : '100')};
`;

export const TopWrapper = styled.div`
	position: absolute;
	z-index: -1;
	top: 1px;
	left: 0;
	width: 100%;
	padding: 32px 0 24px;
	box-sizing: border-box;
	transform: scaleY(0) translateY(0);
	background-color: ${({ theme }) => theme.lightGrayColor};
	box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.05);
	transition: transform ${ANIMATION_TIME}ms ease;
	visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};

	${({ isOpen }) => isOpen && 'transform: scaleY(1) translateY(-100%);'}
`;

export const Top = styled.div`
	display: flex;

	@media (max-width: 992px) {
		flex-direction: column;
	}
`;

export const Navigation = styled.nav`
	display: flex;
	flex-basis: 55%;

	@media (max-width: 992px) {
		margin-bottom: 30px;
	}
`;

export const NavigationColumn = styled.ul`
	flex-grow: 1;
	margin: 0;
	padding: 0 16px 0 0;
	box-sizing: border-box;
	list-style: none;

	@media (max-width: 992px) {
		flex-grow: 0;
		padding-right: 0;

		&:not(:last-child) {
			padding-right: 64px;
		}
	}
`;

export const LinkWrapper = styled.li`
	display: block;
	margin: 0;

	&:not(:last-child) {
		margin-bottom: 5px;
	}
`;

const linkStyle = css`
	text-decoration: none;

	&:hover {
		color: ${({ theme }) => theme.primaryColor};
		transition: color 0.2s ease;
	}
`;

export const NavLink = styled(Link)`
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	color: ${({ theme }) => theme.primaryBlackColor};
	cursor: pointer;
	${linkStyle}

	&:focus {
		outline: none;
		color: ${({ theme }) => theme.primaryColor};
	}
`;

export const About = styled.div`
	flex-basis: 45%;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.6;
`;

export const AboutLink = styled.a`
	white-space: nowrap;
	color: inherit;
	${linkStyle}
`;

export const BottomWrapper = styled.div`
	position: relative;
	background-color: ${({ theme }) => theme.lightGrayColor};
	z-index: 2;
`;

export const Bottom = styled.div`
	margin: 0 -15px;
	padding: 16px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
	font-weight: 500;
	color: #9b9b9b;

	@media (max-width: 992px) {
		flex-direction: column;
		text-align: center;
		align-items: center;
	}
`;

export const ToggleButton = styled.button`
	min-width: 78px;
	padding: 5px 0;
	box-sizing: border-box;
	text-align: left;
	border: none;
	color: inherit;
	background-color: transparent;
	cursor: pointer;

	@media (max-width: 992px) {
		display: none;
	}

	&:focus {
		outline: none;
		color: ${({ theme }) => theme.primaryBlackColor};
	}
`;

export const DropdownIcon = styled.svg`
	width: 8px;
	height: 5px;
	margin-left: 5px;
	fill: none;
	stroke: currentColor;
	transform: rotate(-180deg);
	transition: transform ${ANIMATION_TIME}ms ease;

	${({ isOpen }) => isOpen && 'transform: rotate(0deg);'}
`;

export const Copy = styled.p`
	margin: 0;
	@media (max-width: 992px) {
		margin-bottom: 8px;
	}
`;

export const Container = styled.div`
	width: 100%;
	max-width: 1430px;
	margin: 0 auto;
	padding: 0 15px;
	box-sizing: border-box;
`;
