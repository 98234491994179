/* @flow */
import React, { type Node, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContextOverflow, Dropdown, Kebab, SubTitle, Title, TitleWrapper } from '../MobileHeader/styles';
import { kebabIcon } from '../../../Icons';
import type { TRootState } from '../../../../types/rootState';
import { getApartRoomsAndSquareString } from '../../../../utils/formatting';
import type { TApartment } from '../../../../reducers/apartment';
import type { TDispatch } from '../../../../types/core';
import { handleChangeLikesSorting } from '../../../../actions/lira';
import type { TLiraSelectionsState } from '../../../../reducers/liraSelections';
import type { TLira } from '../../../../reducers/lira';
import Apart from './screens/Apart';
import LiraDislikes from './screens/LiraDislikes';
import LiraLikes from './screens/LiraLikes';
import LiraSelection from './screens/LiraSelection';

type TProps = {
	route: string,
	isOpen: boolean,
	toggleModal: () => {},
	setModal: () => {},
	isApartResale?: boolean,
};

type TData = {
	title: string,
	subtitle?: Node,
	menu?: Node,
};

const ContextMenu = ({ route, isOpen, toggleModal, setModal, isApartResale }: TProps): Node => {
	const dispatch: TDispatch = useDispatch();
	const apartment: TApartment = useSelector((state: TRootState) => state.apartment);
	const liraLikes: TLiraSelectionsState = useSelector((state: TRootState) => state.liraSelections);
	const lira: TLira = useSelector((state: TRootState) => state.lira);

	const {
		user: { status },
	} = lira;

	const [openSubmenu, setOpenSubmenu] = useState(false);

	const currentApartData = route === 'apart' && isApartResale ? apartment.apartDataResale : apartment.apartData;
	const apartTitle =
		route === 'apart' ? getApartRoomsAndSquareString(currentApartData?.roomsCnt, currentApartData?.areaTotal) : '';
	const apartSubtitle =
		route === 'apart'
			? isApartResale
				? currentApartData?.house?.addressText
				: currentApartData?.complex?.addressText
			: '';

	const submenuToggle = (e) => {
		e.stopPropagation();
		setOpenSubmenu(!openSubmenu);
	};

	const changeLikesSorting = (page, value) => (e) => {
		e.stopPropagation();
		dispatch(handleChangeLikesSorting(page, value));
	};

	const isLiked = apartment?.userStatus === 'LIKE';
	const isDisliked = apartment?.userStatus === 'DISLIKE';

	const getContent = (): TData => {
		switch (route) {
			case 'tinderoid-filter':
				return {
					title: 'Поиск квартиры',
					subtitle: <SubTitle>Настройки</SubTitle>,
				};
			case 'lira-filter':
				return {
					title: 'Персональный подбор',
					subtitle: <SubTitle>Настройки</SubTitle>,
					/* menu: <LiraFilter />, */
				};
			case 'lira-selection':
				return {
					title: 'Персональный подбор',
					subtitle: (
						<SubTitle $color={status === 'ACTIVE' ? 'primaryColor' : 'darkGrayColor'}>
							{status === 'ACTIVE' ? 'Запущен' : 'Остановлен'}
						</SubTitle>
					),
					menu: <LiraSelection lira={lira} submenuToggle={submenuToggle} openSubmenu={openSubmenu} />,
				};
			case 'likes':
				return {
					title: 'Избранное',
					menu: (
						<LiraLikes
							liraLikes={liraLikes}
							changeLikesSorting={changeLikesSorting}
							submenuToggle={submenuToggle}
							openSubmenu={openSubmenu}
						/>
					),
				};
			case 'dislikes':
				return {
					title: 'Не понравилось',
					menu: (
						<LiraDislikes
							liraLikes={liraLikes}
							changeLikesSorting={changeLikesSorting}
							openSubmenu={openSubmenu}
							submenuToggle={submenuToggle}
						/>
					),
				};
			case 'apart':
				return {
					title: apartTitle,
					subtitle: <SubTitle>{apartSubtitle}</SubTitle>,
					menu: (
						<Apart
							isApartResale={isApartResale}
							apartTitle={apartTitle}
							currentApartData={currentApartData}
							isLiked={isLiked}
							isDisliked={isDisliked}
						/>
					),
				};
			default:
				return {
					title: '',
					menu: '',
				};
		}
	};

	const content: TData = getContent();

	useEffect(() => {
		if (!isOpen && openSubmenu) {
			setOpenSubmenu(false);
		}
	}, [isOpen]);

	return (
		<>
			<TitleWrapper>
				<Title>{content.title}</Title>
				{content?.subtitle}
			</TitleWrapper>
			{content?.menu && (
				<>
					<Kebab onClick={() => toggleModal('context')} $isOpen={isOpen}>
						<svg viewBox="0 0 32 32">{kebabIcon}</svg>
					</Kebab>
					<ContextOverflow $isVisible={isOpen} onClick={() => setModal()}>
						<Dropdown $isOpen={isOpen}>{content.menu}</Dropdown>
					</ContextOverflow>
				</>
			)}
		</>
	);
};

ContextMenu.defaultProps = {
	isApartResale: false,
};

export default ContextMenu;
