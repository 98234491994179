import { TFilterComplex } from '../types/filters';
import { INITIAL_FEE_DEFAULT, LOAN_PERIOD_DEFAULT, PAYMENT_DEFAULT } from '../data/filter/price';
import type { TAction } from '../types/core';
import { RESET_COMPLEX_FILTER, SET_COMPLEX_FILTER, RESET_ALL_COMPLEX_FILTERS } from '../constants';

const initialState: TFilterComplex = {
	deadlineLte: 300001,
	priceTotalMinGte: 0,
	priceTotalMinLte: 1000000000,
	roomsCntForFilter: [],
	initialFee: INITIAL_FEE_DEFAULT, // первоначальный взнос, обязательный параметр, целое число
	payment: PAYMENT_DEFAULT, // ежемесячный платеж, обязательный параметр, целое число
	loanPeriod: LOAN_PERIOD_DEFAULT, // срок кредитования в годах (от 1 до 30), обязательный параметр
};

const filterComplex = (state: TFilterComplex = initialState, action: TAction): TFilterComplex => {
	switch (action.type) {
		case SET_COMPLEX_FILTER:
			return { ...state, ...action.payload };

		case RESET_ALL_COMPLEX_FILTERS:
		case RESET_COMPLEX_FILTER:
			return { ...initialState };
		default:
			return state;
	}
};
export default filterComplex;
