import { RESEARCH_LIST_FETCH_ARTICLES_DATA } from '../constants/researchList';
import { FAIL, START, SUCCESS } from '../constants';

const initialState = {
	articles: null,
	count: 0,
	loading: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case RESEARCH_LIST_FETCH_ARTICLES_DATA + START:
			return { ...state, loading: true };

		case RESEARCH_LIST_FETCH_ARTICLES_DATA + SUCCESS: {
			const {
				items = null,
				meta: { pageCount: count = 0 },
			} = action.payload;
			return { ...state, articles: items ? [...items] : items, count, loading: false };
		}

		case RESEARCH_LIST_FETCH_ARTICLES_DATA + FAIL:
			return { ...state, articles: null, count: 0, loading: false };

		default:
			return state;
	}
};

export default reducer;
