/* @flow */

/**
 * Function for compare arrays Symmetrical Difference
 * @param containArray
 * @param compareArray
 * @returns {null|*}
 */
export const getDiffArray = (containArray, compareArray) => {
	if (containArray.length && compareArray.length) {
		return containArray
			.filter((x) => !compareArray.includes(x))
			.concat(compareArray.filter((x) => !containArray.includes(x)));
	}
	console.error(['Empty arrays', containArray, compareArray]);
	return null;
};

export const uniqueArray = (source: number[]): number[] =>
	source.reduce((acc: number[], curr: number): number[] => (acc.includes(curr) ? acc : [...acc, curr]), []);

/**
 * Добавляет уникальные элементы из массива term в массив source
 * @param {number[]} source
 * @param {number[]} term
 * @return {number[]}
 */
export const arrayUniqueAdd = (source: number[], term: number[]): number[] => [
	...source,
	...term.reduce((acc: number[], elem: number) => (source.includes(elem) ? [...acc] : [...acc, elem]), []),
];

/**
 * Удаляет элементы массива subtrahend из массива source
 * @param {number[]} source
 * @param {number[]} subtrahend
 * @return {number[]|*}
 */
export const arrayUniqueSubtract = (source: number[], subtrahend: number[]): number[] =>
	source.reduce((acc: number[], elem: number) => (subtrahend.includes(elem) ? [...acc] : [...acc, elem]), []);

/**
 * Пересечение массивов - возвращает уникальный массив элементов содержащихся в обоих массивах
 * @param {number[]} first
 * @param {number[]} second
 * @return {number[]|*}
 */
export const arrayUniqueIntersection = (first: number[], second: number[]): number[] =>
	first.reduce((acc: number[], curr: number) => (second.includes(curr) ? [...acc, curr] : acc), []) |> uniqueArray;

/**
 * Возвращает true, если хотя бы один элемент массива target содержится в массиве source
 * @param {number[]} source
 * @param {number[]} target
 * @return {boolean|*}
 */
export const arrayIncludes = (source: number[], target: number[]): boolean =>
	source.some((elem: number) => target.includes(elem));

/**
 * Сравнивает на идентичность 2 объекта. ВНИМАНИЕ! Корректно только для простых объектов без вложенности.
 * @param object1
 * @param object2
 * @returns {boolean}
 */
export const isEqual = (object1, object2) => {
	const props1 = Object.getOwnPropertyNames(object1);
	const props2 = Object.getOwnPropertyNames(object2);

	if (props1.length !== props2.length) {
		return false;
	}

	for (let i = 0; i < props1.length; i += 1) {
		const prop = props1[i];

		if (object1[prop] !== object2[prop]) {
			return false;
		}
	}

	return true;
};

/**
 * Сравнивает 2 массива (например, ID районов/метро)
 * @param firstArray
 * @param secondArray
 * @returns {*} true/false
 */
export const arraysCompare = (firstArray, secondArray) =>
	firstArray?.length > 0 && secondArray?.length > 0 && firstArray.every((elem) => secondArray.includes(elem));
