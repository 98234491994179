import styled, { css } from 'styled-components';
import { Brand, NavButton, NavLink } from '../../styles';
import { textFontSizeM, textFontSizeXS } from '../../../../theme';
import Link from '../../../Link';

export const Container = styled.div`
	width: 100%;
	display: grid;
	background-color: #fff;
	grid-template-areas: ${({ $withContext }) =>
		$withContext ? `'burger title kebab'` : `'burger logo . fav notice profile'`};
	grid-template-columns: ${({ $withContext }) => ($withContext ? '32px 1fr 32px' : '32px 89px 1fr repeat(3, 32px)')};
	grid-column-gap: 6px;
	align-items: center;
	padding: 9px;
`;

export const BurgerBox = styled.span`
	width: 20px;
	height: 12px;
	display: inline-block;
	position: relative;
`;

const BurgerLine = css`
	width: 20px;
	height: 2px;
	background-color: currentColor;
	border-radius: 2px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
`;

export const BurgerInner = styled.span`
	display: block;
	top: 50%;
	margin-top: -1px;
	transition-duration: 0.075s;
	transition-timing-function: ${({ $isActive }) =>
		$isActive ? 'cubic-bezier(0.215, 0.61, 0.355, 1)' : 'cubic-bezier(0.55, 0.055, 0.675, 0.19)'};
	${BurgerLine};
	${({ $isActive }) =>
		$isActive &&
		`
			transform: rotate(45deg);
			transition-delay: 0.12s;
	`}

	&::before {
		content: '';
		display: block;
		top: ${({ $isActive }) => ($isActive ? 0 : '-6px')};
		transition: ${({ $isActive }) =>
			$isActive ? 'top 0.075s ease, opacity 0.075s 0.12s ease' : 'top 0.075s 0.12s ease, opacity 0.075s ease'};
		${BurgerLine};
		${({ $isActive }) => $isActive && 'opacity: 0;'}
	}

	&::after {
		content: '';
		display: block;
		bottom: ${({ $isActive }) => ($isActive ? 0 : '-6px')};
		transition: ${({ $isActive }) =>
			$isActive
				? 'bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)'
				: 'bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19)'};
		${BurgerLine};
		${({ $isActive }) => $isActive && 'transform: rotate(-90deg);'}
	}
`;

export const Burger = styled.button`
	position: relative;
	z-index: 10002;
	grid-area: burger;
	padding: 6px;
	display: inline-block;
	cursor: pointer;
	color: ${({ theme }) => theme.primaryBlackColor};
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	text-transform: none;
	background-color: #fff;
	border: 0;
	margin: 0;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			color: ${({ theme }) => theme.primaryColor};
		}
		&:active {
			color: ${({ theme }) => theme.primaryBlackColor};
		}
	}

	@media (hover: none), (pointer: coarse) {
		&:hover,
		&:active {
			color: ${({ theme }) => theme.primaryBlackColor};
		}
	}
`;

export const MobileBrand = styled(Brand)`
	margin-left: 6px;

	svg {
		width: 76.5px;
		height: 18px;
	}
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	grid-area: title;
	justify-content: center;
	max-width: 100%;
	overflow: hidden;
`;

export const Title = styled.p`
	${({ theme }) => theme.typography.textMediumL};
	margin: 0;
	padding: 0;
	line-height: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const SubTitle = styled.p`
	${({ theme }) => theme.typography.textS};
	margin: 0;
	padding: 0;
	color: ${({ $color, theme }) => ($color ? theme[$color] : theme.darkBlueColor)};
	line-height: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const Profile = styled.button`
	grid-area: profile;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	padding: 0;
	margin: 0;
	border-radius: 4px;
	box-shadow: none;
	border: none;
	background-color: ${({ theme }) => theme.backgroundGrayColor};
`;

export const Kebab = styled.button`
	grid-area: kebab;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	padding: 0;
	margin: 0;
	border-radius: 4px;
	box-shadow: none;
	border: none;
	background-color: ${({ $isOpen, theme }) => ($isOpen ? theme.lightGrayColor : '#fff')};

	svg {
		width: 32px;
		height: 32px;
		fill: ${({ theme }) => theme.primaryBlackColor};
	}
`;

export const ProfileNavLink = styled(Link)`
	grid-area: profile;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	width: 32px;
	height: 32px;
	border-radius: 4px;
	background-color: ${({ theme, $isActive, $isProfile }) =>
		$isActive && !$isProfile ? theme.primaryColor : theme.lightGrayColor};
`;

export const ProfileIcon = styled.svg`
	width: 21px;
	height: 22px;
	fill: ${({ theme, $isAuth, $isActive }) => (!$isAuth || !$isActive ? theme.darkGrayColor : '#fff')};
`;

export const MenuOverflow = styled.div`
	position: fixed;
	top: 0;
	right: ${({ $isVisible }) => ($isVisible ? 0 : '100%')};
	bottom: 0;
	left: ${({ $isVisible }) => ($isVisible ? 0 : '-100%')};
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0;
	margin: 0;
	z-index: 10000;
	box-shadow: none;
	border: none;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
	transition: opacity 0.2s;
	appearance: none !important;
	overflow-y: auto;
`;

export const ProfileOverflow = styled.div`
	position: fixed;
	top: 57px;
	right: ${({ $isVisible }) => ($isVisible ? 0 : '-100%')};
	bottom: 0;
	left: ${({ $isVisible }) => ($isVisible ? 0 : '100%')};
	display: flex;
	width: 100%;
	padding: 0;
	margin: 0;
	z-index: 1299;
	box-shadow: none;
	border: none;
	background-color: rgba(255, 255, 255, 0);
	transition: all 0.2s;
	appearance: none !important;
`;

export const MenuWrapper = styled.div`
	position: relative;
	top: 0;
	left: ${({ $isVisible }) => ($isVisible ? 0 : '-100%')};
	z-index: 10001;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 356px;
	flex: 1 1 100%;
	box-sizing: border-box;
	transition: left 0.5s ease-in-out 0.1s;

	@media (max-width: 360px) {
		max-width: 95vw;
	}
`;

export const ProfileWrapper = styled.div`
	position: relative;
	top: 0;
	left: ${({ $isVisible }) => ($isVisible ? 0 : '100%')};
	width: 100%;
	max-width: 320px;
	margin-left: auto;
	background-color: ${({ theme }) => theme.lightGrayColor};
	padding: 15px;
	box-sizing: border-box;
	transition: left 0.5s ease-in-out 0.1s;
	align-self: flex-start;

	@media (max-width: 480px) {
		max-width: 100%;
	}
`;

export const NavMobileLink = styled(Link)`
	display: grid;
	grid-template-columns: ${({ $isReverse }) => ($isReverse ? '20px 1fr' : '1fr auto')};
	grid-template-areas: ${({ $isReverse }) => ($isReverse ? '"icon title"' : '"title icon"')};
	grid-gap: 8px;
	align-items: center;
	justify-items: start;
	min-height: 40px;
	padding: 0 10px;
	box-shadow: none;
	border: none;
	background: transparent;
	color: ${({ theme, $isGray }) => ($isGray ? theme.darkGrayColor : theme.primaryBlackColor)};
	text-decoration: none;
	cursor: pointer;
	${({ theme }) => theme.typography.textMediumM};

	b {
		${({ theme }) => theme.typography.textBoldL};
	}

	@media (hover: hover) {
		&:hover {
			color: ${({ theme, $isGray }) => ($isGray ? theme.primaryBlackColor : theme.primaryColor)};
		}
	}
`;

export const LinkFlex = styled.span`
	display: flex;
`;

export const LinkIcon = styled.svg`
	width: 20px;
	height: 20px;
	fill: ${({ theme }) => theme.darkGrayColor};
	grid-area: icon;
`;

export const LinkLoginIcon = styled(LinkIcon)`
	fill: none;
	stroke: currentColor;
`;

export const NavSection = styled.div`
	display: flex;
	flex-direction: column;
	background: #fff;
	border-radius: 4px;
	padding: 0;
	margin-bottom: 12px;

	${NavMobileLink} + ${NavMobileLink} {
		border-top: ${({ theme }) => `1px solid ${theme.lightGrayColor}`};
	}
`;

export const NavSectionTitle = styled.p`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0;
	padding: 0 0 12px;
	color: ${({ theme }) => theme.primaryBlackColor};
	${({ theme }) => theme.typography.textMediumM};
`;

export const CountInline = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	min-width: 16px;
	height: 16px;
	padding: 2px;
	margin: 2px 5px;
	color: #fff;
	background-color: ${({ theme }) => theme.primaryColor};
	border-radius: 8px;
	font-size: 10px;
`;

export const ProfileBlock = styled(Link)`
	display: grid;
	margin: 12px 0;
	grid-template-areas: 'icon name' 'icon link';
	grid-template-columns: 44px 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 0;
	text-decoration: none;
`;

export const ProfileNameIcon = styled.span`
	grid-area: icon;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	color: #fff;
	font-size: ${textFontSizeM};
	background-color: ${({ theme }) => theme.primaryColor};
`;

export const ProfileName = styled.span`
	grid-area: name;
	${({ theme }) => theme.typography.textMediumM};
	color: ${({ theme }) => theme.primaryBlackColor};
`;

export const ProfileLink = styled.span`
	grid-area: link;
	font-size: ${textFontSizeXS};
	color: ${({ theme }) => theme.darkGrayColor};
`;

export const LoginSection = styled.div`
	margin: 12px 0;
	display: flex;
	flex-direction: column;
`;

export const LoginIcon = styled.img`
	width: 80px;
	height: 80px;
	align-self: center;
`;

export const LoginTitle = styled.p`
	margin: 20px 0 0 0;
	text-align: center;
	${({ theme }) => theme.typography.headlineXS};
`;

export const LoginText = styled.p`
	margin: 4px 0 30px;
	text-align: center;
	${({ theme }) => theme.typography.textM};
`;

export const AppButtons = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: flex-start;
	margin: 0;
`;

export const LogoContainer = styled.div`
	position: sticky;
	top: 0;
	display: grid;
	background-color: #fff;
	grid-template-areas: '. logo . ';
	grid-template-columns: 32px 89px 1fr;
	grid-column-gap: 6px;
	width: 100%;
	height: 56px;
	padding: 12px 9px;
	flex-shrink: 0;
`;

export const Menu = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.lightGrayColor};
	padding: 15px;
	flex: 1 1 100%;
`;

export const NavButtonMobile = styled(NavButton)`
	width: 32px;
	height: 32px;
	padding: 0;
	justify-content: center;
`;

export const NavLinkMobile = styled(NavLink)`
	width: 32px;
	height: 32px;
	padding: 0;
	justify-content: center;
`;

export const ContextOverflow = styled(MenuOverflow)`
	top: 57px;
	right: 0;
	bottom: ${({ $isVisible }) => ($isVisible ? 0 : 'calc(100% - 57px)')};
	left: 0;
	overflow: hidden;
	align-items: flex-end;
`;

export const Dropdown = styled.div`
	position: absolute;
	top: ${({ $isOpen }) => ($isOpen ? 0 : '-100%')};
	right: 0;
	display: flex;
	flex-direction: column;
	width: max-content;
	min-width: 220px;
	padding: 15px 12px;
	gap: 12px;
	background: #fff;
	box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	transition: top ease-in-out 0.1s;
`;

export const DropdownLink = styled(NavMobileLink)`
	padding: 0 18px;
	background-color: ${({ theme, $isActive }) => ($isActive ? theme.middleGrayColor : 'transparent')};
	${({ theme }) => theme.typography.textMediumS};
	width: 100%;

	&:disabled {
		opacity: 0.3;
	}
`;

export const DropdownCheckbox = styled.div`
	padding: 0 18px;
	display: flex;
	min-height: 40px;
	align-items: center;
`;

const getColor = ({ theme, isActive, $stroke }) => {
	if (isActive) {
		return theme.primaryColor;
	}
	if ($stroke) {
		return 'none';
	}
	return theme.darkGrayColor;
};

export const DropdownIcon = styled(LinkIcon)`
	width: 24px;
	height: 24px;
	fill: ${({ theme, $stroke }) => ($stroke ? 'none' : theme.darkGrayColor)};
	stroke: ${({ theme, $stroke }) => ($stroke ? theme.darkGrayColor : 'none')};
	transition: transform 0.2s;
	${({ $isOpen }) => $isOpen && 'transform: rotate(180deg)'};

	@media (max-width: 600px) {
		fill: ${(theme, props) => getColor(theme, props)};
	}
`;

export const DropdownSubmenu = styled.div`
	display: flex;
	flex-direction: column;
	height: auto;
	max-height: ${({ $isOpen }) => ($isOpen ? '9999px' : 0)};
	overflow: hidden;
	background-color: ${({ theme }) => theme.backgroundGrayColor};
`;

export const LiraActionButton = styled.button`
	display: grid;
	grid-template-columns: 1fr 32px;
	grid-gap: 4px;
	align-items: center;
	justify-items: start;
	width: 100%;
	height: 40px;
	padding: 4px 18px;
	box-sizing: border-box;
	box-shadow: none;
	border: none;
	background: ${({ theme, $blue }) =>
		$blue ? `linear-gradient(90deg, #6F30F6 0%, #5862F7 100%)` : theme.secondaryColor};
	color: ${({ theme, $blue }) => ($blue ? '#fff' : theme.primaryBlackColor)};
	border-radius: 4px;
	${({ theme }) => theme.typography.textMediumM};

	svg {
		width: 32px;
		height: 32px;
		stroke: currentColor;
		fill: none;
		justify-self: center;
		grid-column: 2;
	}
`;

export const ContextDivider = styled.hr`
	width: 100%;
	height: 0;
	margin: 0;
	border-top: ${({ theme }) => `1px solid ${theme.lightGrayColor}`};
	border-right: none;
	border-bottom: none;
	border-left: none;
	box-shadow: none;
`;

export const ContextGroup = styled.div`
	display: flex;
	flex-direction: column;
`;
