/* @flow */
import {
	BLOCK_SCREEN_MAP,
	CHANGE_LIRA_USER_FILTER,
	FAIL,
	FETCH_LIRA_SELECTION_DATA,
	FETCH_LIRA_SELECTION_MAP_DATA,
	LIRA_CHANGE_SORTING,
	LIRA_CHANGE_STATISTICS,
	LIRA_CHANGE_USER_STATUS,
	LIRA_CHANGE_USER_SUBSCRIBE,
	LIRA_FILTER_RESET,
	LIRA_GET_PORTRAITS,
	LIRA_GET_WISH_GROUP,
	LIRA_SELECTION_CHANGE_PAGE,
	LIRA_SELECTION_INCLUDE_SOLD,
	LIRA_SELECTION_RELOAD_PAGE,
	LIRA_SET_USER_PORTRAIT,
	LIRA_SET_USER_WISHES,
	LIRA_SHOW_MESSAGE,
	LOGOUT,
	NEW_LIRA_CHANGE_USER_PROFILE,
	NEW_LIRA_CREATE_USER_PROFILE,
	NEW_LIRA_GET_USER_PROFILE,
	SET_BOUNDS,
	SET_LIRA_FIRST_ENTRY_STATUS,
	SET_LIRA_STEP,
	SET_LIRA_VIEW,
	SET_SOFT_UPDATE,
	SET_UPDATE_MAP_SELECTION,
	START,
	SUCCESS,
} from '../constants';
import type { TAction } from '../types/core';
import { PRICE_TOTAL_MIN_GTE_DEFAULT, PRICE_TOTAL_MIN_LTE_DEFAULT } from '../data/filter/price';
import { handleStatisticsChange } from '../actions/lira4';
import type { TLira } from '../types/lira4';

let liraViewDesktop = 'list';
let liraViewMobile = 'map';

if (typeof localStorage !== 'undefined') {
	if (localStorage.getItem('liraViewDesktop')) {
		liraViewDesktop = localStorage.getItem('liraViewDesktop');
	}
	if (localStorage.getItem('liraViewMobile')) {
		liraViewMobile = localStorage.getItem('liraViewMobile');
	}
}

const initialState: TLira = {
	step: 'subscribe',
	filter: {
		deadlineLte: 300001,
		priceTotalMinGte: PRICE_TOTAL_MIN_GTE_DEFAULT,
		priceTotalMinLte: PRICE_TOTAL_MIN_LTE_DEFAULT,
		roomsCntForFilter: [1, 2],
		areaIds: [],
		metroIds: [],
		realtyType: 'ALL',
		isApartHotel: null,
	},
	user: {
		portraitId: null,
		userWishes: [],
		firstEntry: true,
		status: 'ACTIVE',
	},
	subscribe: {
		telegram: false,
		email: false,
	},
	selection: {
		viewType: {
			desktop: liraViewDesktop,
			mobile: liraViewMobile,
		},
		items: [],
		stats: {
			countNewSuggested: 0,
			countNoFeedback: 0,
			countLiked: 0,
			countDisliked: 0,
		},
		meta: {},
		sort: 'SUGGESTION_DATE_DESC',
		page: 0,
		isLoading: false,
		isLoaded: false,
		needUpdate: true,
		reloadPage: true,
		includeSold: true,
	},
	selectionMap: {
		data: [],
		isLoading: false,
		isLoaded: false,
		blockScreen: false,
		needUpdate: true,
		softUpdate: false,
		bounds: [],
	},
	portraits: {
		data: null,
		isLoading: false,
		isLoaded: false,
	},
	wishesList: {
		data: null,
		isLoading: false,
		isLoaded: false,
	},
	profileWasReset: false,
	showInfoMessage: false,
};

export default function lira(state: TLira = initialState, action: TAction) {
	const { type, payload } = action;

	switch (type) {
		case SET_LIRA_STEP:
			return { ...state, step: payload };

		case CHANGE_LIRA_USER_FILTER:
			return { ...state, filter: { ...state.filter, ...payload } };

		case LIRA_SET_USER_PORTRAIT:
			return { ...state, user: { ...state.user, portraitId: payload } };

		case LIRA_SET_USER_WISHES:
			return { ...state, user: { ...state.user, userWishes: payload } };

		case LIRA_CHANGE_USER_STATUS + SUCCESS:
			return { ...state, user: { ...state.user, status: payload } };

		case SET_LIRA_FIRST_ENTRY_STATUS:
			return { ...state, user: { ...state.user, firstEntry: payload } };

		case LIRA_CHANGE_STATISTICS:
			return {
				...state,
				selection: { ...state.selection, stats: handleStatisticsChange(state.selection.stats, payload) },
			};

		case LIRA_CHANGE_USER_SUBSCRIBE:
			return { ...state, subscribe: { ...state.subscribe, ...payload } };

		case SET_LIRA_VIEW:
			return {
				...state,
				selection: {
					...state.selection,
					items: state.selection.items.slice(0, 12),
					page: state.selection.page > 0 ? 0 : state.selection.page,
					viewType: {
						...state.selection.viewType,
						...payload,
					},
				},
			};

		case LIRA_CHANGE_SORTING:
			return {
				...state,
				selection: {
					...state.selection,
					sort: payload,
					needUpdate: true,
					reloadPage: true,
					page: 0,
				},
			};

		case LIRA_GET_PORTRAITS + START:
			return { ...state, portraits: { ...state.portraits, isLoading: true, isLoaded: false } };

		case LIRA_GET_PORTRAITS + SUCCESS:
			return { ...state, portraits: { data: action.payload, isLoading: false, isLoaded: true } };

		case LIRA_GET_PORTRAITS + FAIL:
			return { ...state, portraits: { ...state.portraits, isLoading: false, isLoaded: false } };

		case LIRA_GET_WISH_GROUP + START:
			return { ...state, wishesList: { ...state.wishesList, isLoading: true, isLoaded: false } };

		case LIRA_GET_WISH_GROUP + SUCCESS:
			return { ...state, wishesList: { data: payload, isLoading: false, isLoaded: true } };

		case LIRA_GET_WISH_GROUP + FAIL:
			return { ...state, wishesList: { ...state.wishesList, isLoading: false, isLoaded: false } };

		case NEW_LIRA_GET_USER_PROFILE + SUCCESS:
		case NEW_LIRA_CREATE_USER_PROFILE + SUCCESS:
		case NEW_LIRA_CHANGE_USER_PROFILE + SUCCESS:
			return {
				...state,
				...action.payload,
				filter: {
					...state.filter,
					...action.payload.filter,
				},
				user: {
					...state.user,
					...action.payload.user,
				},
				subscribe: {
					...state.subscribe,
					...action.payload.subscribe,
				},
				settingsWereUpdated: false,
			};

		case FETCH_LIRA_SELECTION_DATA + START:
			return { ...state, selection: { ...state.selection, isLoading: true, isLoaded: false } };

		case FETCH_LIRA_SELECTION_DATA + SUCCESS: {
			const { items, meta, stats } = payload;
			return {
				...state,
				selection: {
					...state.selection,
					items: state.selection.reloadPage ? items : [...state.selection.items, ...items],
					meta,
					stats,
					isLoading: false,
					isLoaded: true,
					needUpdate: false,
					reloadPage: false,
				},
			};
		}

		case FETCH_LIRA_SELECTION_DATA + FAIL:
			return { ...state, selection: { ...state.selection, isLoading: false, isLoaded: false } };

		case FETCH_LIRA_SELECTION_MAP_DATA + START:
			return { ...state, selectionMap: { ...state.selectionMap, isLoading: true, isLoaded: false } };

		case FETCH_LIRA_SELECTION_MAP_DATA + SUCCESS:
			return {
				...state,
				selectionMap: {
					...state.selectionMap,
					...payload,
					isLoading: false,
					isLoaded: true,
					needUpdate: false,
				},
			};

		case FETCH_LIRA_SELECTION_MAP_DATA + FAIL:
			return { ...state, selectionMap: { ...state.selectionMap, isLoading: false, isLoaded: false } };

		case NEW_LIRA_GET_USER_PROFILE + START:
		case NEW_LIRA_CREATE_USER_PROFILE + START:
			return { ...state, isLoading: true, isLoaded: false };

		case LIRA_SELECTION_RELOAD_PAGE:
			return { ...state, selection: { ...state.selection, reloadPage: true } };

		case LIRA_SELECTION_CHANGE_PAGE:
			return {
				...state,
				selection: {
					...state.selection,
					page: payload,
					needUpdate: true,
				},
			};

		case LIRA_SELECTION_INCLUDE_SOLD: {
			return {
				...state,
				selection: {
					...state.selection,
					items: initialState.selection.items,
					meta: initialState.selection.meta,
					page: initialState.selection.page,
					includeSold: action.payload,
					needUpdate: true,
					reloadPage: true,
				},
				selectionMap: {
					...state.selectionMap,
					needUpdate: true,
				},
			};
		}

		case BLOCK_SCREEN_MAP:
			return { ...state, selectionMap: { ...state.selectionMap, blockScreen: payload } };

		case SET_SOFT_UPDATE:
			return { ...state, selectionMap: { ...state.selectionMap, softUpdate: payload } };

		case SET_BOUNDS:
			return { ...state, selectionMap: { ...state.selectionMap, bounds: payload, blockScreen: false } };

		case SET_UPDATE_MAP_SELECTION:
			return { ...state, selectionMap: { ...state.selectionMap, needUpdate: true } };

		case LIRA_FILTER_RESET:
			return {
				...state,
				step: initialState.step,
				filter: initialState.filter,
				user: initialState.user,
				selection: initialState.selection,
				selectionMap: initialState.selectionMap,
				profileWasReset: true,
			};

		case LIRA_SHOW_MESSAGE:
			return { ...state, showInfoMessage: payload };

		case LOGOUT + SUCCESS:
			return { ...state, ...initialState, portraits: state.portraits, wishesList: state.wishesList };

		default:
			return state;
	}
}
