import styled from 'styled-components';

export const Root = styled.div`
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 9000;
	background-color: rgba(245, 245, 245, 0.9);
	transition: opacity 0.3s ease;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: ${({ mortgage, status }) => (mortgage || status === 'entered' ? 1 : 0)};

	&:focus {
		outline: none;
	}

	@media (max-width: 768px) {
		-webkit-overflow-scrolling: touch;
		transition: none;
	}
`;

export const Inside = styled.div`
	padding: 45px;
	background-color: #fff;
	display: inline-block;
	position: relative;
	max-height: 100vh;
	overflow: auto;
	outline: none;

	right: ${({ mortgage, status }) => {
		if (mortgage) {
			return status === 'entered' ? 0 : '-630px';
		}
		return 0;
	}};

	@media (max-width: 768px) {
		transition: none;
		box-sizing: border-box;
	}
`;
