import type { TComplexBuilding } from '../types/complex';
import type { TComplex } from '../types/lira';
import type { TBounds } from '../types/selectionMap';

export type TMinMax = {
	lat: { min: number, max: number },
	lon: { min: number, max: number },
};

const LATITUDE_DIFF = 0.430465;
const LONGITUDE_DIFF = 0.609515;

const INITIAL_BOUNDS = { lat: { min: 85.05112878, max: -85.05112878 }, lon: { min: 180, max: -180 } };
const INITIAL_COORDS = [59.938093, 30.313902];

export const calculateMinMax = (
	items: TComplex[] | TComplexBuilding[],
	coords: number[] = INITIAL_COORDS,
	latDiff: number,
	lonDiff: number,
): TMinMax =>
	items.length
		? items.reduce(
				(acc: TMinMax, curr: TComplexBuilding): TMinMax =>
					curr.latitude && curr.longitude
						? {
								lat: {
									min: Math.min(acc.lat.min, curr.latitude),
									max: Math.max(acc.lat.max, curr.latitude),
								},
								lon: {
									min: Math.min(acc.lon.min, curr.longitude),
									max: Math.max(acc.lon.max, curr.longitude),
								},
						  }
						: acc,
				INITIAL_BOUNDS,
		  )
		: {
				lat: { min: coords[0] - latDiff, max: coords[0] + latDiff },
				lon: { min: coords[1] - lonDiff, max: coords[1] + lonDiff },
		  };

/**
 * Вычисляет минимальные и максимальные значения координат объектов в списке
 * @param {TComplexBuilding[]} items - список GEO объектов
 * @param {number[]} coords - географические координаты центра региона
 * @returns {TMinMax}
 */
export const calcMinMax = (items: TComplexBuilding[] = [], coords: number[]): TMinMax =>
	calculateMinMax(items, coords, LATITUDE_DIFF, LONGITUDE_DIFF);

/**
 * Рассчитывает границы видимой области карты по минимальным и максимальным значениям координат набора гео-объектов
 * @param {TMinMax} mx - объект минимальных и максимальных значений координат
 * @param {number} outer - коэффициент отступа до границы. Чем меньше, тем ближе крайние объекты к границам
 * видимой области карты
 * @returns {TBounds}
 */
export const calcBounds = (
	{ lat: { min: latMin, max: latMax }, lon: { min: lonMin, max: lonMax } }: TMinMax,
	outer: number = 0,
): TBounds => [
	[latMin - Math.max((latMax - latMin) * outer, 0.00175), lonMin - Math.max((lonMax - lonMin) * outer, 0.00175)],
	[latMax + Math.max((latMax - latMin) * outer, 0.00175), lonMax + Math.max((lonMax - lonMin) * outer, 0.00175)],
];

/**
 * Множественный выбор в зависимости от точек условий
 * @param val - текущее значение
 * @param def - значение по-умолчанию. Возвращается в том случае, если текущее значение меньше
 * значения первой точки выбора
 * @param cases - массив точек выбора. Если текущее значение больше или равно точке выбора возвращается
 * соответствующее значение из массива results
 * @param results - массив возвращаемых значений. Значения массива соответствуют диапазонам точек выбора.
 * Если текущее значение больше или равно значению первой тчки выбора и меньше значения второй точки,
 * возвращается первое значение из массива
 * @returns {null|T|*}
 */
export const ifCase = (val: number, def: any = false, cases: number[] = [], results: any[] = []): any => {
	if (!cases.length) return def;
	const outs = [...results];
	if (outs.length < cases.length) {
		outs.length = cases.length;
	}
	return cases.reduce((acc: any, curr: number, idx: number) => {
		if (val >= curr) return outs[idx];
		return acc;
	}, def);
};
