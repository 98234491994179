import React from 'react';

export const VKColor = (
	<g fill="none" fillRule="evenodd">
		<circle cx="14.893" cy="14.893" r="14.893" fill="#4D76A1" fillRule="nonzero" />
		<path
			fill="#FFF"
			d="M14.33 21.425h1.17s.353-.038.533-.233c.166-.178.16-.513.16-.513s-.022-1.569.706-1.8c.717-.227 1.639 1.516 2.616 2.187.738.507 1.3.396 1.3.396l2.611-.037s1.366-.084.718-1.158c-.053-.088-.377-.795-1.941-2.247-1.638-1.52-1.419-1.274.554-3.903 1.201-1.601 1.682-2.579 1.532-2.997-.144-.4-1.027-.294-1.027-.294l-2.94.019s-.219-.03-.38.067c-.158.094-.26.315-.26.315s-.465 1.24-1.086 2.293c-1.31 2.223-1.833 2.34-2.047 2.203-.498-.322-.373-1.293-.373-1.983 0-2.154.326-3.053-.637-3.285-.32-.078-.555-.129-1.372-.137-1.05-.01-1.937.003-2.44.25-.335.164-.593.529-.436.55.195.026.635.118.868.436.302.41.291 1.33.291 1.33s.173 2.537-.404 2.852c-.397.216-.94-.225-2.108-2.242a18.752 18.752 0 0 1-1.05-2.176s-.087-.213-.242-.327c-.188-.138-.452-.183-.452-.183l-2.794.019s-.42.011-.573.194c-.137.162-.011.498-.011.498s2.187 5.118 4.664 7.697c2.272 2.365 4.85 2.21 4.85 2.21z"
		/>
	</g>
);

export const FacebookColor = (
	<path
		fill="#3B5998"
		fillRule="nonzero"
		d="M15 .006C6.719.006.006 6.719.006 15c0 8.281 6.713 14.994 14.994 14.994.107 0 .214-.002.32-.004v-9.885H12v-4.151h3.32v-2.49c0-3.42 1.931-5.812 4.982-5.812h3.32v4.15h-1.66c-1.658 0-2.49.83-2.49 2.491v1.66h4.15l-.83 4.152h-3.32v9.21C25.569 27.414 29.994 21.725 29.994 15 29.994 6.719 23.281.006 15 .006z"
	/>
);

export const OKColor = (
	<g fill="none" fillRule="evenodd">
		<circle cx="15" cy="15" r="15" fill="#F2720C" />
		<g fill="#FFF" fillRule="nonzero">
			<path d="M19.375 15C18.75 15 17.5 16.25 15 16.25S11.25 15 10.625 15c-.688 0-1.25.563-1.25 1.25 0 .625.375.938.625 1.063.75.437 3.125 1.437 3.125 1.437l-2.688 3.375s-.5.563-.5 1c0 .688.563 1.25 1.25 1.25.626 0 .938-.438.938-.438L15 20.625l2.813 3.313s.312.437.937.437c.688 0 1.25-.563 1.25-1.25 0-.375-.5-1-.5-1l-2.625-3.375s2.375-1 3.125-1.438c.25-.187.625-.437.625-1.062 0-.688-.563-1.25-1.25-1.25zM15 5.625A4.348 4.348 0 0 0 10.625 10 4.348 4.348 0 0 0 15 14.375 4.348 4.348 0 0 0 19.375 10 4.348 4.348 0 0 0 15 5.625zm0 6.563c-1.188 0-2.188-1-2.188-2.188 0-1.188 1-2.188 2.188-2.188 1.188 0 2.188 1 2.188 2.188 0 1.188-1 2.188-2.188 2.188z" />
		</g>
	</g>
);

export const TwitterColor = (
	<g id="svg-twitter-color" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		<g id="Log-in" transform="translate(-776.000000, -423.000000)" fillRule="nonzero">
			<g id="if_twitter_circle_294709" transform="translate(776.000000, 423.000000)">
				<circle id="Oval" fill="#1CB7EB" cx="24" cy="24" r="24" />
				<path
					d="M36.8,15.4 C35.9,15.9 34.8,16.2 33.8,16.3 C34.9,15.6 35.7,14.5 36.1,13.2 C35.1,13.8 34,14.3 32.7,14.6 C31.7,13.5 30.4,12.8 28.9,12.8 C26,12.8 23.6,15.3 23.6,18.5 C23.6,18.9 23.6,19.4 23.7,19.8 C19.3,19.6 15.4,17.3 12.8,13.9 C12.3,14.7 12.1,15.7 12.1,16.8 C12.1,18.8 13,20.5 14.4,21.5 C13.5,21.5 12.7,21.2 12,20.8 C12,20.8 12,20.9 12,20.9 C12,23.6 13.8,25.9 16.2,26.5 C15.8,26.6 15.3,26.7 14.8,26.7 C14.5,26.7 14.1,26.7 13.8,26.6 C14.5,28.9 16.4,30.5 18.7,30.5 C16.9,32 14.6,32.9 12.2,32.9 C11.8,32.9 11.4,32.9 10.9,32.8 C13.2,34.4 16,35.4 19,35.4 C28.7,35.4 34,26.8 34,19.3 C34,19.1 34,18.8 34,18.6 C35.2,17.6 36.1,16.6 36.8,15.4 Z"
					id="Shape"
					fill="#FFFFFF"
				/>
			</g>
		</g>
	</g>
);

export const YoutubeSquareIcon = (
	<g fill="none" fillRule="evenodd">
		<rect width="24" height="24" fill="#CC181E" rx=".6" />
		<path
			fill="#FFF"
			d="M10.73 13.844V9.85l3.903 2.005-3.902 1.99zm8.57-4.687s-.141-.98-.574-1.411c-.55-.567-1.165-.57-1.447-.602C15.257 7 12.225 7 12.225 7h-.006s-3.032 0-5.053.144c-.283.033-.898.035-1.448.602-.433.431-.574 1.411-.574 1.411S5 10.307 5 11.458v1.079c0 1.15.144 2.301.144 2.301s.141.98.574 1.411c.55.567 1.272.549 1.593.608 1.156.11 4.911.143 4.911.143s3.035-.004 5.057-.148c.282-.034.897-.036 1.447-.603.433-.431.574-1.411.574-1.411s.144-1.15.144-2.301v-1.079c0-1.15-.144-2.3-.144-2.3z"
		/>
	</g>
);

export const VKSquareIcon = (
	<g fill="none" fillRule="evenodd">
		<rect width="24" height="24" fill="#507299" rx=".6" />
		<path
			fill="#FFF"
			d="M11.85 15.966h.837s.252-.027.381-.167c.12-.128.116-.368.116-.368s-.017-1.124.504-1.29c.514-.164 1.174 1.087 1.873 1.567.529.364.93.285.93.285l1.87-.027s.978-.06.514-.83c-.038-.063-.27-.57-1.39-1.611-1.172-1.09-1.015-.914.397-2.8.86-1.147 1.204-1.848 1.096-2.148-.102-.286-.735-.21-.735-.21l-2.105.012s-.156-.021-.272.048a.59.59 0 0 0-.185.226s-.334.889-.778 1.644c-.937 1.595-1.312 1.679-1.465 1.58-.357-.23-.268-.927-.268-1.421 0-1.546.234-2.19-.455-2.357-.229-.055-.398-.091-.983-.097-.75-.008-1.386.002-1.746.179-.24.117-.425.379-.312.394.139.019.454.085.621.313.216.294.208.954.208.954s.124 1.819-.29 2.045c-.283.154-.672-.162-1.508-1.608-.428-.741-.751-1.56-.751-1.56s-.063-.153-.174-.235c-.135-.1-.323-.131-.323-.131l-2 .013s-.3.009-.411.14c-.099.116-.008.356-.008.356s1.566 3.67 3.34 5.52c1.625 1.696 3.472 1.584 3.472 1.584"
		/>
	</g>
);

export const FacebookSquareIcon = (
	<g fill="none" fillRule="evenodd">
		<rect width="24" height="24" fill="#365899" rx=".6" />
		<path
			fill="#FFF"
			d="M13.525 8.892h1.441V7.007L12.981 7c-2.205 0-2.706 1.407-2.706 2.305v1.257H9v1.942h1.275V18h2.68v-5.496h1.81L15 10.562h-2.044v-1.14c0-.43.334-.53.569-.53"
		/>
	</g>
);
