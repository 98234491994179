import {
	ADD_SERVICE_ORDER,
	ADD_SERVICE_ORDER_PAYMENT,
	CHANGE_SERVICE_ORDER,
	CHANGE_SERVICE_ORDER_PAYMENT,
	CLEAR_SERVICE_ORDER,
	FAIL,
	GET_SERVICE_ORDER_PAYMENT_STATUS,
	SET_SERVICE_ORDER_DETAILS,
	SET_SERVICE_ORDER_FROM_HISTORY,
	SUCCESS,
} from '../constants';
import type { TServiceOrder } from '../types/serviceOrder';

const initialState: TServiceOrder = {
	id: null,
	mode: 'new',
	serviceType: '',
	title: '',
	cityIds: '78,47',
	paymentStatusWasViewed: false,
	paymentType: 'post-payment',
	paymentBase: 0,
	paymentFact: 0,
	amountPaid: 0,
	amountLeft: 0,
	paymentUrl: null,
	readyToPay: false,
	status: null,
	comment: '',
	createdAt: '',
	agreement: false,
	oferta: false,
	showMessage: false,
	error: {},
};

export default function serviceOrder(state: TServiceOrder = initialState, action) {
	const { type, payload, error, status, timestamp } = action;

	switch (type) {
		case SET_SERVICE_ORDER_DETAILS:
			return { ...state, ...payload };
		case ADD_SERVICE_ORDER + SUCCESS:
		case CHANGE_SERVICE_ORDER + SUCCESS:
			return {
				...state,
				...payload,
				cityIds: payload.cityIds.toString(),
				error: {},
			};
		case GET_SERVICE_ORDER_PAYMENT_STATUS + SUCCESS:
			return {
				...state,
				...payload,
				cityIds: payload.cityIds.toString(),
				showMessage: true,
				error: {},
			};
		case SET_SERVICE_ORDER_FROM_HISTORY + SUCCESS:
			return {
				...state,
				...payload,
				mode: 'history',
				cityIds: payload.cityIds.toString(),
				error: {},
			};
		case ADD_SERVICE_ORDER + FAIL:
		case CHANGE_SERVICE_ORDER + FAIL:
		case SET_SERVICE_ORDER_FROM_HISTORY + FAIL:
			return { ...state, error: { ...state.error, message: error, status, timestamp } };
		case ADD_SERVICE_ORDER_PAYMENT + FAIL:
		case CHANGE_SERVICE_ORDER_PAYMENT + FAIL:
		case GET_SERVICE_ORDER_PAYMENT_STATUS + FAIL:
			return { ...state, showMessage: true, error: { ...state.error, message: error, status, timestamp } };
		case ADD_SERVICE_ORDER_PAYMENT + SUCCESS:
		case CHANGE_SERVICE_ORDER_PAYMENT + SUCCESS:
			return {
				...state,
				...payload,
				error: {},
			};
		case CLEAR_SERVICE_ORDER:
			return initialState;
		default:
			return state;
	}
}
