/* @flow */

import type { TPlanningsData } from '../types/complex';
import type { TAction } from '../types/core';
import {
	FAIL,
	FETCH_COMPLEX_PLANNINGS,
	RESET_PLANNINGS_FILTER,
	SET_COMPLEX_FILTER,
	SET_PLANNINGS_FILTER,
	START,
	SUCCESS,
} from '../constants';

export type TPlanningsState = {
	data: TPlanningsData,
	isLoading: boolean,
	isLoaded: boolean,
	needUpdate: boolean,
	lastError: any,
};

const initialState: TPlanningsState = {
	data: null,
	isLoading: false,
	isLoaded: false,
	needUpdate: false,
};

const plannings = (state: TPlanningsState = initialState, action: TAction): TPlanningsState => {
	switch (action.type) {
		case FETCH_COMPLEX_PLANNINGS + START:
			return { ...state, isLoading: true, isLoaded: false };

		case FETCH_COMPLEX_PLANNINGS + SUCCESS:
			return { ...state, data: action.payload, isLoading: false, isLoaded: true, needUpdate: false };

		case FETCH_COMPLEX_PLANNINGS + FAIL:
			return { ...state, isLoading: false, isLoaded: false, lastError: action.error };

		case SET_COMPLEX_FILTER:
		case SET_PLANNINGS_FILTER:
		case RESET_PLANNINGS_FILTER:
			return { ...state, needUpdate: true };

		default:
			return state;
	}
};

export default plannings;
