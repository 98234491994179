/* @flow */

import React, { memo } from 'react';

import homeImage from '../../../../../public/images/home/cards/home.png';
import homeImage2x from '../../../../../public/images/home/cards/home@2x.png';
import { Card, CardImage, CardList, CardListItem, CardTitle } from '../../styles';
import type { TCity } from '../../../../types/city';
import { formatValuesNumber } from '../../../../utils/formatting';

type TProps = {
	index: number,
	city: TCity,
	allApartmentCount: number,
	newApartmentCount: number,
	resaleApartmentCount: number,
};

export const PinkCard = memo(({ index, city, allApartmentCount, newApartmentCount, resaleApartmentCount }: TProps) => (
	<Card index={index} $color="pink">
		<CardTitle>Объявлений в {city.prepositional}</CardTitle>
		<CardList>
			{!!allApartmentCount && (
				<CardListItem>
					Всего&ensp;<b>{formatValuesNumber(allApartmentCount, 'custom', '')}</b>
				</CardListItem>
			)}
			{!!newApartmentCount && (
				<CardListItem>
					Новостройки&ensp;<b>{formatValuesNumber(newApartmentCount, 'custom', '')}</b>
				</CardListItem>
			)}
			{!!resaleApartmentCount && (
				<CardListItem>
					Вторичка&ensp;<b>{formatValuesNumber(resaleApartmentCount, 'custom', '')}</b>
				</CardListItem>
			)}
		</CardList>
		<CardImage>
			<img src={homeImage} srcSet={`${homeImage}, ${homeImage2x} 2x`} alt="" />
		</CardImage>
	</Card>
));
