/* @flow */
import { LODGER_FETCH_ARTICLE_DATA } from '../constants/lodger';
import { FAIL, START, SUCCESS } from '../constants';
import type { TArticleLodger } from '../types/articles';

export type TLodger = {
	articleData: TArticleLodger,
	loading: boolean,
};

const initialState = {
	articleData: null,
	loading: false,
};

const reducer = (state: TLodger = initialState, action) => {
	switch (action.type) {
		case LODGER_FETCH_ARTICLE_DATA + START:
			return { ...state, loading: true };

		case LODGER_FETCH_ARTICLE_DATA + SUCCESS:
			return { ...state, articleData: action.payload, loading: false };

		case LODGER_FETCH_ARTICLE_DATA + FAIL:
			return { ...state, articleData: null, loading: false };

		default:
			return state;
	}
};

export default reducer;
