/* @flow */
import { addJavaHeaders } from '../utils';

import {
	checkStatus,
	deleteTokenCookies,
	getCityConfig,
	getProfile,
	getValidToken,
	parseJSON,
	setTokenCookies,
} from '../apiUtils';

import {
	AUTH_BLOCKED,
	AUTH_DECREMENT_CHANCE,
	CHANGE_PROFILE,
	FAIL,
	LOGIN_FIRST_STAGE,
	LOGIN_SECOND_STAGE,
	LOGOUT,
	SAVE_PROFILE,
	SET_SELECTION_VIEW,
	SET_TOKEN,
	START,
	SUCCESS,
} from '../constants';
import type { TAction, TDispatch, TTokenData } from '../types/core';
import { clearStore } from './token';
import { logger } from '../utils/misc';
import { getUserProfileAnyway } from './lira';
import { getFeed } from './feed';
import type { TRootState } from '../types/rootState';
import { getLiraUserProfile } from './lira4';

export const RECAPTCHA_LIFE_TIME = 120000;

export function changeProfile(profileData) {
	return {
		type: CHANGE_PROFILE,
		payload: {
			...profileData,
		},
	};
}

export const changePhone = (value: string): TAction => {
	const phone = `+${value.replace(/[^\d]/gi, '')}`;
	const phoneVerified = /^\+7\s?\(?\d{3}\)?\s?\d{3}\s?\d{4}$/.test(value);
	return changeProfile({ phone, phoneVerified });
};

function getAnalyticsIds() {
	let googleClientId = null;
	let yandexClientId = null;

	try {
		if (typeof window !== 'undefined') {
			if (window.ga && window.ga.getAll) {
				googleClientId = window.ga.getAll()[0].get('clientId');
			}

			if (window.yaCounter31760356) {
				yandexClientId = window.yaCounter31760356.getClientID();
			}
		}
	} catch (reason) {
		console.error('---', 'reason', reason);
	}

	return {
		googleClientId,
		yandexClientId,
	};
}

export function checkClientIds() {
	return async (dispatch, getState, { fetch }) => {
		const { token: tokenObject, profile } = getState();
		const { googleClientId, yandexClientId } = getAnalyticsIds();
		const { isBot } = tokenObject;

		const someIdIsExist = !!googleClientId || !!yandexClientId;
		const idsIsNotSet =
			(!profile.googleClientId && !!googleClientId) || (!profile.yandexClientId && !!yandexClientId);
		const idsAreDifferent =
			(profile.googleClientId && profile.googleClientId !== googleClientId) ||
			(profile.yandexClientId && profile.yandexClientId !== yandexClientId);

		if (!isBot && someIdIsExist && (idsIsNotSet || idsAreDifferent)) {
			await fetch('/lexsApi/cabinet/update-client-ids', {
				method: 'POST',
				...addJavaHeaders(tokenObject.token),
				body: JSON.stringify({
					...(googleClientId && { googleClientId }),
					...(yandexClientId && { yandexClientId }),
				}),
			})
				.then(checkStatus)
				.then(parseJSON)
				.then((response) => {
					if (response.result === 'ok') {
						dispatch(
							changeProfile({
								...(googleClientId && { googleClientId }),
								...(yandexClientId && { yandexClientId }),
							}),
						);
					}
				})
				.catch((reason) => {
					console.error(reason);
				});
		}
	};
}

export const logout =
	() =>
	async (dispatch: TDispatch, store, { fetch }): any => {
		const { city, token: { token = '' } = {} } = store.getState();

		dispatch({ type: LOGOUT + START });

		try {
			deleteTokenCookies({ withDomain: true });
			const anonTokenObject = await getValidToken('', '', fetch);
			setTokenCookies(anonTokenObject?.token, anonTokenObject?.tokenExpires, null, anonTokenObject?.isAnonymous);
			dispatch({ type: SET_TOKEN, payload: anonTokenObject });

			await getCityConfig(city.cityIds, {
				store,
				fetch,
				token,
			});

			await getProfile(anonTokenObject.token, {
				store,
				fetch,
			});

			await getUserProfileAnyway(anonTokenObject.token)(dispatch, store.getState, { fetch });

			await dispatch(checkClientIds());

			dispatch(clearStore());

			dispatch({ type: LOGOUT + SUCCESS });
		} catch (error) {
			console.error('---', 'status', error.status);
			dispatch({ type: LOGOUT + FAIL });
		}
	};

/**
 * Запрос авторизации пользователя
 * @param {string} phoneNumber - номер телефона в любом формате
 * @returns {function(TDispatch, *, {fetch: *}): Promise<*|null>}
 */
export const loginStageOne =
	(phoneNumber: string) =>
	async (dispatch: TDispatch, getState, { fetch }) => {
		const {
			profile = {},
			token: { reCaptchaToken = '' } = {},
			user: { geoLocation = {} },
		} = getState();

		const { hostname } = window?.location ?? {};
		const url: string = '/passApi/data/login';
		const phone = phoneNumber ?? profile?.phone;
		const ip = geoLocation?.ip ?? null;

		if (phone) {
			dispatch({ type: LOGIN_FIRST_STAGE + START });

			try {
				const { status, data, message } = await fetch(url, {
					method: 'POST',
					credentials: 'same-origin',
					...addJavaHeaders(reCaptchaToken, '', true),
					body: JSON.stringify({ phone, ip, hostname }),
				}).then((res) => res.json());
				if (status === 'ok') {
					dispatch({ type: LOGIN_FIRST_STAGE + SUCCESS, payload: data?.base });
					return data?.base;
				}
				dispatch({ type: LOGIN_FIRST_STAGE + FAIL, error: message ?? 'WTF' });
				return null;
			} catch (error) {
				logger(error);
				dispatch({ type: LOGIN_FIRST_STAGE + FAIL, error });
				return null;
			}
		}
		return null;
	};

/**
 * Подтверждение авторизации пользователя по коду из SMS
 * @param {() => {}} callback - функция-коллбэк
 * @returns {function(TDispatch, *, {fetch: *}): TTokenData}
 */
export const loginStageTwo =
	(callback: () => {}) =>
	async (dispatch: TDispatch, store, { fetch }): TTokenData => {
		const { token: { baseToken = '', token = '', code, isAnonymous } = {}, profile: { referralId = '' } = {} } =
			store.getState();

		if (!baseToken || !code) {
			console.error(!baseToken ? 'Empty token' : 'Empty code');
			return null;
		}

		dispatch({ type: LOGIN_SECOND_STAGE + START });

		const url: string = `/passApi/data/login/${code}`;
		try {
			const fetchData = await fetch(url, {
				method: 'POST',
				...addJavaHeaders('', baseToken, true),
				...(!!token && isAnonymous
					? { body: JSON.stringify({ bearer: token, ...(referralId ? { referralId } : {}) }) }
					: {}),
			}).then((res) => res.json());
			const { result, data, message = '' } = fetchData;

			if (result === 'ok') {
				dispatch({ type: LOGIN_SECOND_STAGE + SUCCESS, payload: data });
				const { token: newToken, expires, isAnonymous: newIsAnonymous }: TTokenData = data;
				setTokenCookies(newToken, expires, null, newIsAnonymous);

				dispatch({
					type: SET_TOKEN,
					payload: { token: newToken, tokenExpires: expires, isAnonymous: newIsAnonymous },
				});

				await Promise.all([
					getProfile(newToken, { store, fetch }),
					getUserProfileAnyway(newToken)(dispatch, store.getState, { fetch }),
					getLiraUserProfile(newToken)(dispatch, store.getState, { fetch }),
					getFeed()(dispatch, store.getState),
				]);

				if (callback) {
					callback();
				}
				return data;
			}
			dispatch({ type: LOGIN_SECOND_STAGE + FAIL, error: message });

			return null;
		} catch (error) {
			dispatch({ type: LOGIN_SECOND_STAGE + FAIL, error });

			return null;
		}
	};

export const setAuthRepeatCode = () => async (dispatch: TDispatch, getState: () => TRootState) => {
	const {
		auth: { repeatChance, repeatBlocked },
	} = getState();

	if (repeatBlocked) {
		const now = Date.now();

		if (now - repeatBlocked > 3600000) {
			dispatch({ type: AUTH_BLOCKED, payload: null });
			localStorage.setItem('authRepeatBlocked', null);
		}
	}

	if (!repeatBlocked) {
		const newValue = repeatChance - 1;

		if (newValue > 0) {
			dispatch({ type: AUTH_DECREMENT_CHANCE, payload: newValue });
			localStorage.setItem('authRepeatChance', newValue);
		} else {
			const date = Date.now();
			dispatch({ type: AUTH_BLOCKED, payload: date });
			localStorage.setItem('authRepeatBlocked', date);
		}
	}
};

export const saveProfile =
	() =>
	async (dispatch: TDispatch, getState, { fetch }): boolean => {
		const {
			city: { cityIds = [] } = {},
			profile: { name = '', phone = '' } = {},
			token: { token = '' } = {},
		} = getState();
		const cityId = cityIds[0] ?? null;
		const url: string = '/lexsApi/cabinet/profile-update';

		if (cityId && name && phone) {
			dispatch({ type: SAVE_PROFILE + START });
			const body: string = JSON.stringify({ cityId, name, phone });
			try {
				const { result, data, errors } = await fetch(url, {
					method: 'POST',
					...addJavaHeaders(token),
					body,
				}).then((res) => res.json());
				if (result === 'ok') {
					dispatch({ type: SAVE_PROFILE + SUCCESS, payload: data?.result });
					return data?.result;
				}
				dispatch({ type: SAVE_PROFILE + FAIL, error: errors, payload: errors });
				return errors;
			} catch (error) {
				dispatch({ type: SAVE_PROFILE + FAIL, error, payload: 'Что-то пошло не так...' });
				return false;
			}
		} else {
			dispatch({ type: SAVE_PROFILE + FAIL, error: 'Not enough data' });
			return false;
		}
	};

export const setSelectionView =
	(desktop: 'grid' | 'list' | 'map', mobile: 'grid' | 'map') => async (dispatch: TDispatch) => {
		const payload = {};

		if (desktop) {
			localStorage.setItem('selectionViewDesktop', desktop);
			payload.desktop = desktop;
		}
		if (mobile) {
			localStorage.setItem('selectionViewMobile', mobile);
			payload.mobile = mobile;
		}

		dispatch({
			type: SET_SELECTION_VIEW,
			payload,
		});
	};
