// FIXME: Синхронизировать очистку сторов, редирект на главную и установку анонимного токена.
/* @flow */
import React, { type Node, useContext } from 'react';
import { useDispatch, useStore } from 'react-redux';
import history from '../history';
import { logout } from '../actions/profile';
import { AppContext } from '../components/AppContext';
import type { TDispatch } from '../types/core';

/**
 * Logout HOC on Hooks
 * @param {Node} Component
 * @returns {function(*): Node}
 */
const auth =
	(Component: Node) =>
	(props): Node => {
		const dispatch: TDispatch = useDispatch();
		const store = useStore();

		const context = useContext(AppContext);

		const loggingOut = () => {
			document.getElementById('preloader').classList.add('isVisible');
			logout()(dispatch, store, context).then(() => {
				history.push('/', {
					showPreloader: true,
					prev: history.location,
					y: window.scrollY,
				});
			});
		};

		return <Component {...props} logout={loggingOut} />;
	};

export default auth;
