/* @flow */

import type { TFilterExt } from '../types/filters';
import type { TAction } from '../types/core';
import {
	LIRA_GET_USER_PROFILE, RESET_ALL_COMPLEX_FILTERS,
	RESET_ALL_FILTERS,
	RESET_EXT_FILTER,
	SET_EXT_FILTER,
	SET_UNIVERSAL_LEXS_FILTER,
	SUCCESS,
} from '../constants';
import { separateAdditionalParams } from '../utils/filters';

const initialState: TFilterExt = {
	finishingType: 0,
	floorGte: null,
	floorLte: null,
	isNotFirstFloor: 0,
	isNotLastFloor: 0,
	areaTotalGte: null,
	areaTotalLte: null,
	areaKitchenGte: null,
	areaKitchenLte: null,
	hasAnyBalcony: null,
	hasNoBalcony: null,
	isApartHotel: null,
};

const filterExt = (state: TFilterExt = initialState, action: TAction): TFilterExt => {
	switch (action.type) {
		case SET_EXT_FILTER:
			return { ...state, ...action.payload };

		case RESET_ALL_FILTERS:
		case RESET_ALL_COMPLEX_FILTERS:
		case RESET_EXT_FILTER:
			return { ...initialState };

		case LIRA_GET_USER_PROFILE + SUCCESS: {
			const { separated: { ext = {} } = {} } = action.payload;
			return { ...state, ...ext };
		}

		case SET_UNIVERSAL_LEXS_FILTER: {
			const { filterGroup, data } = action.payload;
			if (filterGroup === 'additionalParams') {
				const { ext } = separateAdditionalParams(data);
				return { ...state, ...ext };
			}
			return state;
		}

		default:
			return state;
	}
};

export default filterExt;
