import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const withBackgroundSize = 50;
const defaultSize = 18;
const defaultPosition = 30;

const Close = styled.button`
	position: absolute;
	top: ${({ top = defaultPosition }) => `${top}px`};
	right: ${({ right = defaultPosition }) => `${right}px`};
	outline: none;
	width: ${({ withBackground, size = defaultSize }) => (withBackground ? `${withBackgroundSize}px` : `${size}px`)};
	height: ${({ withBackground, size = defaultSize }) => (withBackground ? `${withBackgroundSize}px` : `${size}px`)};
	background-color: ${({ theme, withBackground }) => (withBackground ? theme.primaryColor : 'transparent')};
	cursor: pointer;
	border: none;
	border-radius: ${({ withBackground }) => withBackground && '50%'};
	padding: 0;
	z-index: 1;

	/* &:hover {
		span {
			transform: rotate(180deg);
		}
	} */

	span {
		position: absolute;
		width: ${({ withBackground, size = defaultSize }) => (withBackground ? `${size}px` : '100%')};
		height: ${({ withBackground, size = defaultSize }) => (withBackground ? `${size}px` : '100%')};
		top: ${({ withBackground, size = defaultSize }) => (withBackground ? `calc(50% - ${size / 2}px)` : '0')};
		left: ${({ withBackground, size = defaultSize }) => (withBackground ? `calc(50% - ${size / 2}px)` : '0')};
		transition: transform 0.5s;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			width: ${({ size = defaultSize }) => `${Math.ceil(Math.sqrt(size ** 2 * 2))}px`};
			height: 1px;
			background-color: ${({ withBackground }) => (withBackground ? '#fff' : '#515151')};
		}

		&::before {
			left: 0;
			transform: rotate(45deg);
			transform-origin: 0 0 0;
		}

		&::after {
			right: 0;
			transform: rotate(-45deg);
			transform-origin: 100% 0 0;
		}
	}

	&:focus {
		background-color: ${({ theme, withBackground }) => (withBackground ? theme.primaryHover : 'transparent')};

		span {
			&::before,
			&::after {
				background-color: ${({ withBackground, theme }) => (withBackground ? '#fff' : theme.primaryColor)};
			}
		}
	}
`;

const CloseButton = ({ onClick, top, right, withBackground, size }) => (
	<Close onClick={onClick} type="button" top={top} right={right} size={size} withBackground={withBackground}>
		<span />
	</Close>
);

CloseButton.propTypes = {
	withBackground: PropTypes.bool,
	onClick: PropTypes.func,
	top: PropTypes.number,
	right: PropTypes.number,
	size: PropTypes.number,
};

CloseButton.defaultProps = {
	withBackground: false,
	onClick: () => {},
	top: defaultPosition,
	right: defaultPosition,
	size: defaultSize,
};

export { Close as CloseStyle };
export default CloseButton;
