/* @flow */
import React, { type Node, useState } from 'react';
import type { TFilterBase } from '../../../types/filters';
import CheckboxButton from '../../CheckboxButton';
import { arraysCompare } from '../../../utils/arraysHelpers';
import { plural } from '../../../utils';

type TProps = {
	mode: 'metro' | 'area',
	data: any,
	handleChangeArea: () => {},
	targetField: 'metroIds' | 'areaIds',
	source: TFilterBase,
	searchValue: string,
};

export const List = ({ mode, data, handleChangeArea, targetField, source, searchValue }: TProps): Node => {
	const [collapseGroup, setCollapseGroup] = useState([]);

	const prepareArray = (areaObject) => {
		const tempArray = [];
		const flatArray = Object.values(areaObject).flat();
		flatArray.forEach((item) => !tempArray.find((i) => i.id === item.id) && tempArray.push(item));

		return tempArray.sort((a, b) => (a.name < b.name ? -1 : 1));
	};
	const getIds = (array) => {
		const tempArray = [];
		array.forEach((item) => !tempArray.includes(item.id) && tempArray.push(item.id));

		return tempArray;
	};

	const handleCollapseGroup = (code) => () => {
		if (collapseGroup.includes(code)) {
			setCollapseGroup(collapseGroup.filter((item) => item !== code));
		} else {
			setCollapseGroup([...collapseGroup, code]);
		}
	};

	const abcMetro = mode === 'metro' && data.sort((a, b) => (a.name < b.name ? -1 : 1));
	const abcAreaCity = mode === 'area' && data?.city && prepareArray(data.city);
	const abcAreaRegion = mode === 'area' && data?.region && prepareArray(data.region);
	const cityIds = mode === 'area' && getIds(abcAreaCity);
	const regionIds = mode === 'area' && getIds(abcAreaRegion);

	if (mode === 'metro') {
		return (
			<>
				{abcMetro.map((metroItem) =>
					!!searchValue && !metroItem.name.toLowerCase().includes(searchValue) ? null : (
						<CheckboxButton
							mode={mode}
							key={metroItem.name}
							onClick={handleChangeArea([metroItem.id], source[targetField].includes(metroItem.id))}
							label={metroItem.name}
							isActive={source[targetField].includes(metroItem.id)}
							color={metroItem.lineColor}
							groupText={searchValue.length > 0 && metroItem?.lineName}
							withColorPoint
						/>
					),
				)}
			</>
		);
	}
	if (mode === 'area') {
		return (
			<>
				{abcAreaCity.length > 0 && (
					<>
						<CheckboxButton
							isGroup
							isTopLevel
							isOpen={!collapseGroup.includes('city')}
							isShow={!searchValue}
							onLabelClick={handleCollapseGroup('city')}
							onClick={handleChangeArea(cityIds, arraysCompare(cityIds, source[targetField]))}
							label="Город"
							groupText={`${abcAreaCity.length} ${plural(abcAreaCity.length, [
								'район',
								'района',
								'районов',
							])}`}
							isActive={arraysCompare(cityIds, source[targetField])}
							mode={mode}
							oneColumn
						/>
						{abcAreaCity.map((cityItem) =>
							!!searchValue && !cityItem.name.toLowerCase().includes(searchValue) ? null : (
								<CheckboxButton
									key={cityItem.id}
									isShow={!collapseGroup.includes('city')}
									onClick={handleChangeArea([cityItem.id], source[targetField].includes(cityItem.id))}
									label={cityItem.name}
									isActive={source[targetField].includes(cityItem.id)}
									groupText={searchValue.length > 0 && 'Город'}
								/>
							),
						)}
					</>
				)}
				{abcAreaRegion.length > 0 && (
					<>
						<CheckboxButton
							isGroup
							isTopLevel
							isOpen={!collapseGroup.includes('region')}
							isShow={!searchValue}
							onLabelClick={handleCollapseGroup('region')}
							onClick={handleChangeArea(regionIds, arraysCompare(regionIds, source[targetField]))}
							label="Область"
							groupText={`${abcAreaRegion.length} ${plural(abcAreaRegion.length, [
								'район',
								'района',
								'районов',
							])}`}
							isActive={arraysCompare(regionIds, source[targetField])}
							mode={mode}
							oneColumn
						/>
						{abcAreaRegion.map((regionItem) =>
							!!searchValue && !regionItem.name.toLowerCase().includes(searchValue) ? null : (
								<CheckboxButton
									key={regionItem.id}
									isShow={!collapseGroup.includes('region')}
									onClick={handleChangeArea(
										[regionItem.id],
										source[targetField].includes(regionItem.id),
									)}
									label={regionItem.name}
									isActive={source[targetField].includes(regionItem.id)}
									groupText={searchValue.length > 0 && 'Область'}
								/>
							),
						)}
					</>
				)}
			</>
		);
	}
	return null;
};
