/* @flow */

import type { TProfileServiceOrder } from './serviceOrder';
import { TProfileLoyaltyProgram } from './loyaltyProgram';

export type TToken = {
	token: string,
	tokenExpires: string,
	refreshToken: string,
	isAnonymous: boolean,
	isBot: boolean,
	baseToken: string,
	code: string,
	reCaptchaToken: string,
	reCaptchaTime: Date,
};

export type TProfileSplashScreens = {
	mainShown: boolean,
	apartShown: boolean,
};

export type TSelectionView = {
	desktop: 'list' | 'grid' | 'map',
	mobile: 'grid' | 'map',
};

export type TProfile = {
	id: number,
	cityId: number,
	city: {
		id: number,
		slug: string,
		name: string,
	},
	email: string,
	phone: string,
	phoneVerified: boolean,
	status: number,
	name: string,
	registrationType: string,
	googleClientId: string,
	yandexClientId: string,
	hash: string,
	referralId: string,
	isAgree: boolean,
	isLoading: boolean,
	errors: string | [],
	firstVisit: boolean,
	splashScreens: TProfileSplashScreens,
	forceSteps: boolean,
	selectionView: TSelectionView,
	selectionChanged: boolean,
	orders: {
		data: TProfileServiceOrder[],
		isLoaded: boolean,
		isLoading: boolean,
		needUpdate: boolean,
	},
	loyaltyProgram: {
		data: TProfileLoyaltyProgram,
		isLoaded: boolean,
		isLoading: boolean,
	},
	templateId: number,
	connectedToTelegram: boolean,
};

export type TTelegramAuth = {
	status: 'ok' | 'error' | 'processing',
	token?: string,
	message?: string,
};

export type TTelegramAuthEvent = {
	type: 'message' | 'error',
	data: string | TTelegramAuth,
};

export type TTelegramAuthParams = {
	id: string,
	first_name: string,
	username: string,
	photo_url: string,
	auth_date: string,
};

export type TTelegramAuthToken = {
	token: string,
	expires: string,
	isAnonymous: boolean,
};
