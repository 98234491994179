/* @flow */

import React from 'react';
import type { Node } from 'react';
import { StyledElement } from './styles';

type Props = {
	children: Node,
	forID?: string,
};

export const FieldLabel = ({ children, forID, styles, error }: Props) => (
	<StyledElement error={error} styles={styles} htmlFor={forID}>
		{children}
	</StyledElement>
);

FieldLabel.defaultProps = {
	forID: undefined,
};
