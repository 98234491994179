import styled, { createGlobalStyle, css } from 'styled-components';
import { MEDIA_ON_MOBILE } from '../../constants';

export const Root = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 100%;
`;

export const SlideStyle = createGlobalStyle`
	.apartMediaSlider {
		position: relative;
		width: 100%;
		display: block;
		margin-bottom: 10px;

		@media (max-width: 600px) {
				margin-bottom: 6px;

	}

		.slick-list {
			overflow: hidden;
			height: 100%;
		}

		.slick-track {
			display: flex;
			align-items: center;
			height: 100%;
			line-height: 0;
			font-size: 0;
		}

		.slick-slide {
			display: block;
		}

		.slick-arrow {
			position: absolute;
			top: 50%;
			left: 24px;
			z-index: 1;
			width: 50px;
			height: 50px;
			border: none;
			border-radius: 50%;
			background: rgba(255, 255, 255, 0.20);
			box-shadow: none;
			font-size: 0;
			transform: translateY(-50%);
			cursor: pointer;

			@media (max-width: 640px) {
				left: 7px;
				width: 36px;
				height: 36px;
			}

			&::after {
				content: '';
				position: absolute;
				width: 14px;
				height: 14px;
				top: 50%;
				left: 50%;
				border: 2px solid #999;
				transform: translate(-50%, -50%) rotate(45deg);

				@media (max-width: 680px) {
					width: 8px;
					height: 8px;
				}
			}

			&:hover {
				&::after {
					border-color: #333;
				}
			}

			&.slick-disabled {
				opacity: 0;
				pointer-events: none;
			}
		}

		.slick-prev {
			&::after {
				border-top: none;
				border-right: none;
				margin-left: 3px;
			}
		}

		.slick-next {
			left: auto;
			right: 24px;

			&::after {
				border-left: none;
				border-bottom: none;
				margin-left: -3px;
			}

			@media (max-width: 640px) {
				right: 7px;
			}
		}
	}

	.apartThumbsSlider {
		padding: 0;
		min-width: 100%;
		width: 0;

		.slick-list {
			overflow: hidden;
			height: 100%;
		}

		.slick-track {
			display: flex;
			height: 100%;
			line-height: 0;
			font-size: 0;
		}

		.slick-slide {
			display: inline-block;
			padding: 0 2.5px;
			box-sizing: border-box;
		}
	}
`;

export const SlideOverflow = styled.div`
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	font-weight: bold;
	line-height: 1.25;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.5);
	transition: background-color 0.3s ease;
	transform: translateY(-50%);
	z-index: 2;

	svg {
		width: 50px;
		height: 50px;
		fill: #fff;
		margin-bottom: 24px;
	}
`;

export const SlideImg = styled.figure`
	position: relative;
	width: 100%;
	height: 0;
	margin: 0;
	padding: 0 0 70%;
	overflow: hidden;

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		min-height: 100%;
		object-fit: cover;
		transform: translate3d(-50%, -50%, 0);
	}

	@media (min-width: 1280px) {
		padding: 0 0 52%;
	}
`;

export const ThumbImg = styled(SlideImg)`
	width: 100%;
	height: 100%;
	border-radius: 4px;

	&& {
		padding: 0;
	}
`;

export const Slide = styled.button`
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
	background-color: ${({ isLayout, showVertical }) => (isLayout || showVertical ? '#fff' : '#d8d8d8')};
	background-image: ${({ isLayout, showVertical }) =>
		isLayout || showVertical ? 'unset' : 'linear-gradient(62deg, #d8d8d8 0%, #eaeaea 50%, #e0e0e0 100%)'};
	border: none;
	box-shadow: none;
	cursor: pointer;
	box-sizing: border-box;
	border-radius: 5px;
	overflow: hidden;

	&:hover {
		background-color: ${({ isLayout, showVertical }) => (isLayout || showVertical ? '#fff' : '#d8d8d8')};
		background-image: ${({ isLayout, showVertical }) =>
			isLayout || showVertical ? 'unset' : 'linear-gradient(62deg, #d8d8d8 0%, #eaeaea 50%, #e0e0e0 100%)'};

		${SlideOverflow} {
			background-color: rgba(0, 0, 0, 0.25);
			color: #fff;

			svg {
				fill: #fff;
			}
		}
	}

	&:focus {
		position: relative;
		outline: none;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
			box-shadow: ${({ theme }) => `0px 0px 0px 2px ${theme.primaryColor} inset;`};
			border-radius: 5px;
		}
	}

	${SlideImg} {
		${({ isLayout }) =>
			isLayout &&
			`
			img {
				object-fit: contain;
				max-width: 97%;
				min-height: 0;
				max-height: 97%;
			}
		`}
		${({ showVertical }) =>
			showVertical &&
			`
			width: 100%;
			height: 100%;

			img {
				object-fit: contain;
				min-height: 0;
				max-height: 100%;
			}
		`}
	}
`;

export const ThumbsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 5px;
	align-items: start;
	margin-bottom: 6px;

	@media ${MEDIA_ON_MOBILE} {
		grid-template-columns: repeat(3, 1fr);
	}
`;

const getColumns = (length, withPanorama, mobile) => {
	if (mobile) {
		if (withPanorama) {
			if (length < 3) {
				return `1 / span ${length}`;
			}
			return '1 / span 2';
		}
		if (length < 3) {
			return `1 / span ${length}`;
		}
	}
	if (withPanorama) {
		if (length < 5) {
			return `1 / span ${length}`;
		}
		return '1 / span 5';
	}
	if (length < 5) {
		return `1 / span ${length}`;
	}
	return '1 / -1';
};

export const Thumbs = styled.div`
	grid-column: ${({ length, withPanorama }) => length && getColumns(length, withPanorama, false)};
	width: calc(100% - 48px);
	margin: 0 24px;

	@media ${MEDIA_ON_MOBILE} {
		grid-column: ${({ length, withPanorama }) => length && getColumns(length, withPanorama, true)};
		width: 100%;
		margin: 0;
	}
`;

export const Thumb = styled.button`
	display: block;
	position: relative;
	width: 100%;
	height: 75px;
	margin: 0;
	padding: ${({ isLayout }) => (isLayout ? '6px' : 0)};
	cursor: pointer;
	box-sizing: border-box;
	background-color: transparent;
	box-shadow: none;
	border: none;
	transition: opacity 0.2s;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		background-color: ${(props) => props.isVideo && 'rgba(0, 0, 0, 0.5)'};
		box-shadow: ${({ isActive, theme }) => (isActive ? `0 -2px 0px ${theme.primaryColor} inset` : `none`)};
	}

	&::after {
		content: '';
		position: absolute;
		z-index: 3;
		top: 50%;
		left: 50%;
		width: 0;
		height: 0;
		transform: translate(-50%, -50%);
		border-style: ${({ isVideo }) => isVideo && 'solid'};
		border-width: ${({ isVideo }) => isVideo && '10px 0 10px 17.3px'};
		border-color: ${({ isVideo, isActive, theme }) =>
			isVideo &&
			(isActive
				? `transparent transparent transparent ${theme.primaryColor}`
				: 'transparent transparent transparent #ffffff')};
	}

	&:hover {
		opacity: 0.8;
	}

	&:focus {
		outline: none;

		&::before {
			box-shadow: ${({ theme }) => `0 0 0 2px ${theme.primaryColor} inset`};
		}
	}

	${ThumbImg} {
		${({ isLayout }) =>
			isLayout &&
			`
			img {
				object-fit: contain;
				min-height: 0;
				max-height: 100%;
			}
		`}
	}

	@media (min-width: 1430px) {
		height: 105px;
	}
`;

export const SliderIcons = styled.div`
	position: absolute;
	top: ${({ $withTabs }) => ($withTabs ? '76px' : '20px')};
	right: 24px;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 18px;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	transition: opacity 0.3s;

	@media (max-width: 1025px) {
		top: 8px;
		right: 8px;
		grid-gap: 14px;
	}
`;

export const SliderIcon = styled.svg`
	width: ${({ width }) => `${width}px`};
	height: ${({ height }) => `${height}px`};
	fill: ${({ theme }) => theme.darkGrayColor};

	@media (max-width: 1025px) {
		width: ${({ width }) => `${width * 0.72}px`};
		height: ${({ height }) => `${height * 0.72}px`};
	}
`;

const SliderIconStyle = css`
	background: #fff;
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: color 0.2s;
	z-index: 2;
	cursor: pointer;
	border: 1px solid ${({ theme }) => theme.lightGrayColor};
	border-radius: 4px;
	padding: 0;

	&:hover,
	&:active {
		color: ${({ theme }) => theme.primaryColor};
	}

	@media (max-width: 1025px) {
		width: 36px;
		height: 36px;
	}
`;

export const SliderIconButton = styled.button`
	${SliderIconStyle};
`;

export const SliderIconLink = styled.a`
	${SliderIconStyle};
`;

export const TopButtonsWrapper = styled.div`
	display: flex;
	margin-bottom: 10px;

	@media (max-width: 640px) {
		overflow-x: scroll;
		scrollbar-width: none;
		margin: 0 -15px 10px;
		padding: 0 15px;

		&::-webkit-scrollbar {
			display: none;
		}
	}
`;
export const CounterWrapper = styled.div`
	border-radius: 4px;
	flex-grow: 0;
	margin: 0px 12px;
	padding: 5px 12px;
	${({ theme }) => theme.typography.textMediumS};

	@media (max-width: 1020px) {
		margin: 0px 6px;
	}

	@media (max-width: 770px) {
		margin: 0px 12px;
	}
`;

export const TopButton = styled.button`
	display: flex;
	flex: 1 1 100%;
	align-items: center;
	justify-content: center;
	padding: ${({ isActive }) => (isActive ? `11px 14px 8px` : `11px 14px`)};
	${({ theme }) => theme.typography.headlineXS};

	background-color: transparent;
	border: 0;
	border-bottom: ${({ isActive, theme }) =>
		isActive ? `4px solid ${theme.primaryColor}` : `1px solid ${theme.darkGrayColor}`};
	color: ${({ theme }) => theme.primaryBlackColor};
	text-decoration: none;
	box-shadow: none;
	white-space: nowrap;
	cursor: pointer;

	&:active,
	&:hover {
		color: ${({ theme }) => theme.primaryColor};
	}

	&:disabled {
		color: ${({ theme }) => theme.darkGrayColor};
		cursor: default;
	}

	${CounterWrapper} {
		background: ${({ isActive, theme }) => (isActive ? theme.primaryColor : theme.secondaryColor)};
		color: ${({ isActive, theme }) => (isActive ? '#fff' : theme.primaryColor)};
	}
	@media (max-width: 1026px) {
		font-size: 12px;
		padding: ${({ isActive }) => (isActive ? `11px 4px 8px` : `11px 4px`)};
	}

	@media (max-width: 640px) {
		font-size: 12px;
		padding: ${({ isActive }) => (isActive ? `0 11px 7px` : `0 11px 10px`)};
	}
`;

export const LayoutButton = styled.button`
	position: absolute;
	left: 50%;
	bottom: 100px;
	transform: translateX(-50%);

	display: flex;
	align-items: center;
	border: none;
	box-shadow: none;
	gap: 8px;
	padding: 8px 12px;
	border-radius: 4px;
	background-color: ${({ theme }) => theme.primaryBlackColor};
	transition: background-color 0.3s;
	color: #fff;
	cursor: pointer;

	${({ theme }) => theme.typography.textMediumS};

	svg {
		width: 16px;
		height: 16px;
	}

	&:hover {
		background-color: #000;
	}

	@media (min-width: 1430px) {
		bottom: 130px;
	}
`;
