import React from 'react';

import Home from './Home';
import Layout from '../../components/Layout';
import NotFound from '../not-found/NotFound';

import getSeoData from '../../utils';

import { checkIsCity, setCity } from '../../actions/city';
import { getCityConfig } from '../../apiUtils';
import { getLiraInitialData, getTextsFromDatabase } from '../../actions/lira';
import type { TLiraTemplatesState } from '../../reducers/liraTemplates';
import { getAppStats, getMortgageProgramsStats } from '../../actions/app';
import { GET_HOMEPAGE_TEXTS } from '../../constants';

async function action({ fetch, store, params }) {
	const {
		app: { stats, mortgagePrograms, homepageTexts },
		cities,
		cityConfig,
		liraTemplates,
		user,
	} = store.getState();

	const isCity = checkIsCity(params.city, cities) || !params.city;

	const spbUser = user.citySlug === 'spb';
	const notSpbUser = user.citySlug && user.citySlug !== 'spb';
	const spbUrl = !params.city || params.city === 'spb';
	const notSpbCityUrl = params.city;
	const userInHisCity = (spbUser && spbUrl) || user.citySlug === params.city;

	const city = setCity({
		store,
		params,
		fetch,
	});

	const { portraits, cities: liraCities, initials, wishes }: TLiraTemplatesState = liraTemplates;

	if (!portraits || !liraCities || !initials || !wishes) {
		await getLiraInitialData()(store.dispatch, store.getState, { fetch });
	}

	if (!stats.isLoaded) {
		await getAppStats()(store.dispatch, store.getState, { fetch });
	}

	if (!mortgagePrograms.isLoaded) {
		await getMortgageProgramsStats()(store.dispatch, store.getState, { fetch });
	}

	if (!homepageTexts) {
		await getTextsFromDatabase('site_main', ['header_text'], GET_HOMEPAGE_TEXTS)(store.dispatch, store.getState, {
			fetch,
		});
	}

	if (!isCity) {
		return {
			chunks: ['not-found'],
			title: 'Страница не найдена',
			component: (
				<Layout isNotFound>
					<NotFound title={this.title} />
				</Layout>
			),
			status: 404,
		};
	}

	if (!userInHisCity) {
		if (spbUser && notSpbCityUrl) {
			return {
				redirect: '/',
			};
		}
		if ((notSpbUser && spbUrl) || (notSpbUser && notSpbCityUrl)) {
			return {
				redirect: `/${user.citySlug}/`,
			};
		}
	}

	if (!cityConfig) {
		await getCityConfig(city.cityIds, {
			store,
			fetch,
		});
	}

	const { title, description, keywords } = await getSeoData(fetch, city.seoTitle, null, city.cityIds);

	return {
		title,
		description,
		keywords,
		component: (
			<Layout route="main">
				<Home />
			</Layout>
		),
	};
}

export default action;
