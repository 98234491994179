/* @flow */
import React, { useEffect } from 'react';
import type { Node } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { genDates, times } from './helpers';
import type { TDispatch } from '../../types/core';
import type { TProfile } from '../../types/account';
import type { TDeveloper } from '../../types/apart';
import { SET_ACTION_CALLBACK_POPUP } from '../../constants/popup';
import { FetchingInside, PopupInside } from './styles';
import Popup from '../Popup';
import Form from './components';
import type { TCRMRequestContext } from '../../types/misc';
import type { TMortgageData } from '../../decorators/withCallbackPopup';

type TProps = {
	callbackType: string | null,
	visible: boolean,
	popupApart: boolean,
	isReserved: boolean,
	apartData: {
		id: number,
		nmarketId: number,
		rooms_cnt: number,
		number: number | string,
		complex_name: string,
		developer: TDeveloper,
	} | null,
	complexId: number | null,
	context: TCRMRequestContext,
	mortgageData: TMortgageData,
	handleClosePopup: () => any,
};

const dates = [...genDates(7)];

const NOW = new Date();
const disableToday = NOW.getHours() >= 18;
const disableTime = NOW.getHours() > 9 && NOW.getHours() < 20;

const CallbackPopup = (props: TProps): Node => {
	const {
		callbackType = null,
		visible = false,
		popupApart = false,
		apartData,
		complexId,
		context,
		isReserved,
		mortgageData,
		handleClosePopup = () => {},
	} = props;

	const dispatch: TDispatch = useDispatch();

	const isFetching: boolean = useSelector((state) => state.popup.actionCallbackPopup.isFetching);
	const response: boolean = useSelector((state) => state.popup.actionCallbackPopup.response);
	const profile: TProfile = useSelector((state) => state.profile);

	useEffect(() => {
		const { name: profileName, email: profileEmail, phone: profilePhone } = profile;

		dispatch({
			type: SET_ACTION_CALLBACK_POPUP,
			payload: {
				name: profileName || '',
				email: profileEmail || '',
				phone: profilePhone || '',
				day: disableToday ? dates[1].value : dates[0].value,
				time: disableTime ? times[1].value : times[0].value,
			},
		});
	}, []);

	const clearState = (): any => {
		const { name: profileName, email: profileEmail, phone: profilePhone } = profile;

		dispatch({
			type: SET_ACTION_CALLBACK_POPUP,
			payload: {
				response: false,
				responseIsExist: false,
				isFetching: false,
				name: profileName || '',
				email: profileEmail || '',
				phone: profilePhone || '',
				agree: true,
				phoneError: false,
				queue: null,
			},
		});
	};

	const closeCallback = (): any => {
		handleClosePopup();
		clearState();
	};

	if (popupApart) {
		return (
			<Form
				apartData={apartData}
				isReserved={isReserved}
				callbackType={callbackType}
				complexId={complexId}
				context={context}
				mortgageData={mortgageData}
				closeCallback={closeCallback}
			/>
		);
	}

	return (
		<Popup
			visible={visible}
			inside={isFetching || response ? FetchingInside : PopupInside}
			closeBtn
			handleClosePopup={closeCallback}
		>
			<Form
				apartData={apartData}
				isReserved={isReserved}
				callbackType={callbackType}
				complexId={complexId}
				context={context}
				mortgageData={mortgageData}
				closeCallback={closeCallback}
			/>
		</Popup>
	);
};

export default CallbackPopup;
