import styled from 'styled-components';
import Button from '../Button/Button';

export const CustomBtn = styled(Button)`
	position: relative;
	${(props) => props.withText === false && 'padding-left: 8px; padding-right: 8px;'}
`;

export const Popup = styled.div`
	width: ${({ $isBig }) => ($isBig ? 'calc(100vw - 50px)' : '185px')};
	max-width: 350px;
	padding: 20px 20px 10px 20px;
	background-color: #ffffff;
	box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
`;

export const Icon = styled.svg`
	width: ${(props) => props.iconSize && `${props.iconSize}px`};
	height: ${(props) => props.iconSize && `${props.iconSize}px`};
	transition: fill 0.3s, stroke 0.3s;
	fill: ${(props) => props.theme.darkGrayColor};
	stroke: transparent;
	stroke-width: 1.5px;
`;

export const CopyButton = styled.button`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-bottom: 10px;
	padding: 0;
	background-color: transparent;
	border: none;
	cursor: pointer;
`;

export const CopyIcon = styled.svg`
	width: 30px;
	height: auto;
	margin-right: 10px;
`;

export const Label = styled.div`
	align-self: center;
	color: #000;
	font-weight: 500;
	font-size: 14px;
`;
