/* @flow */

import { FAIL, START, SUCCESS, DOSIER_DETAIL_FETCH_DOSIER_DATA } from '../constants';
import type { TDosierDetailState } from '../types/dosierDetail';
import type { TAction } from '../types/core';

const initialState: TDosierDetailState = {
	dosierData: null,
	loading: false,
};

const reducer = (state: TDosierDetailState = initialState, action: TAction): TDosierDetailState => {
	switch (action.type) {
		case DOSIER_DETAIL_FETCH_DOSIER_DATA + START:
			return { ...state, loading: true };

		case DOSIER_DETAIL_FETCH_DOSIER_DATA + SUCCESS:
			return { ...state, loading: false, ...action.payload };

		case DOSIER_DETAIL_FETCH_DOSIER_DATA + FAIL:
			return { ...state, dosierData: null, loading: false };

		default:
			return state;
	}
};

export default reducer;
