/* @flow */

import React from 'react';

import type { Node } from 'react';

import CircleLoaderWithIcon from './CircleLoaderWithIcon';

import theme from '../../theme';

const ArrowLoader = ({ progress = false, fill = theme.primaryColor }: { progress?: boolean, fill?: string }): Node => {
	return (
		<CircleLoaderWithIcon
			shape={
				<path
					transform="translate(46 36)"
					fill={fill}
					fillRule="nonzero"
					d="M12.417 2.121L1.895 15.076C1.241 15.868.5 16.475.5 17.671.5 19.29 1.833 20 3.083 20H11v20.4c0 1.427.996 2.6 2.417 2.6h3.166C18.004 43 19 41.827 19 40.4V20h7.917c1.25 0 2.583-.708 2.583-2.33 0-1.195-.741-1.802-1.395-2.594L17.583 2.121C16.777 1.128 16.15.5 15 .5c-1.15 0-1.775.628-2.583 1.621z"
				/>
			}
			progress={progress}
		/>
	);
};

const SearchLoader = ({ progress = false, fill = theme.primaryColor }: { progress?: boolean, fill?: string }): Node => {
	return (
		<CircleLoaderWithIcon
			shape={
				<path
					transform="translate(42 40)"
					fill={fill}
					fillRule="nonzero"
					d="M27.583 4.732A16.051 16.051 0 0 0 16.158 0C11.842 0 7.784 1.68 4.732 4.732A16.052 16.052 0 0 0 0 16.158c0 4.316 1.68 8.374 4.732 11.425a16.052 16.052 0 0 0 11.426 4.733c3.3 0 6.45-.984 9.114-2.812l6.62 6.62a2.993 2.993 0 0 0 4.232-4.233l-6.62-6.62a16.043 16.043 0 0 0 2.812-9.113c0-4.316-1.681-8.374-4.733-11.426zm-3.856 18.995c-4.174 4.173-10.965 4.173-15.138 0-4.173-4.174-4.173-10.964 0-15.138 4.173-4.173 10.964-4.173 15.138 0 4.173 4.174 4.173 10.964 0 15.138z"
				/>
			}
			progress={progress}
		/>
	);
};

const CheckLoader = ({ progress = false, fill = theme.primaryColor }: { progress?: boolean, fill?: string }): Node => {
	return (
		<CircleLoaderWithIcon
			shape={
				<path
					transform="translate(40 45)"
					fill={fill}
					fillRule="nonzero"
					d="M36.994.381a1.268 1.268 0 0 0-1.813 0L16.078 19.556c-.501.512-1.318.512-1.815 0l-8.37-8.596a1.265 1.265 0 0 0-1.814 0l-3.7 3.353A1.3 1.3 0 0 0 0 15.22c0 .334.133.684.381.937l8.44 8.932c.5.51 1.318 1.337 1.815 1.843l3.629 3.69a1.276 1.276 0 0 0 1.814 0L40.625 5.913c.5-.507.5-1.339 0-1.846L36.994.381z"
				/>
			}
			progress={progress}
		/>
	);
};

const CrossLoader = ({ progress = false, fill = theme.primaryColor }: { progress?: boolean, fill?: string }): Node => {
	return (
		<CircleLoaderWithIcon
			shape={
				<path
					transform="translate(42 44)"
					fill={fill}
					fillRule="nonzero"
					d="M17.49 12.015L29.57.29a1.055 1.055 0 0 1 1.45 0l3.692 3.592c.396.385.396.962-.066 1.411L22.613 17l12.032 11.708a.988.988 0 0 1 0 1.41l-3.692 3.593a1.055 1.055 0 0 1-1.45 0l-12.019-11.72L5.44 33.71a1.055 1.055 0 0 1-1.45 0L.297 30.12a.988.988 0 0 1 0-1.411L12.36 16.994.297 5.228a.913.913 0 0 1 0-1.347L3.989.29a1.055 1.055 0 0 1 1.45 0L17.49 12.015z"
				/>
			}
			progress={progress}
		/>
	);
};

export default CircleLoaderWithIcon;
export { ArrowLoader, SearchLoader, CheckLoader, CrossLoader };
