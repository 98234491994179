/* @flow */

import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import Slider from 'react-slick';
import loadable from '@loadable/component';
import {
	ButtonIcon,
	ButtonsWrapper,
	Container,
	Content,
	Footer,
	Hero,
	LottieWrapper,
	Root,
	SlideStyle,
	Subtitle,
	Title,
} from './styles';
import type { TCity } from '../../types/city';
import { TDispatch } from '../../types/core';
import type { TRootState } from '../../types/rootState';
import type { TFilterBase, TFilterGlobal } from '../../types/filters';
import { SET_BASE_FILTER } from '../../constants';
import Modal from '../../components/Modal';
import CityModal from './components/CityModal';
import FakeTabs from './components/FakeTabs';
import { useComponentDidMount, useModal } from '../../utils/hooks';
import Button from '../../components/Button2.0';
import { mapSelectionIcon, search2021Icon } from '../../components/Icons';
import type { TAppState } from '../../reducers/app';

import { getAppStats, getMortgageProgramsStats } from '../../actions/app';
import { AppContext } from '../../components/AppContext';
import { PinkCard } from './components/Cards/PinkCard';
import { GreenCard } from './components/Cards/GreenCard';
import { getLiraInitialData } from '../../actions/lira';
import { PRICE_TOTAL_MIN_GTE_DEFAULT, PRICE_TOTAL_MIN_LTE_DEFAULT } from '../../data/filter/price';
import { MortgageCard } from './components/Cards/MortgageCard';
import { SLIDER_SETTINGS } from './contstants';

import lottieGrey1 from './.lottie/grey1.json';
import lottieBlue1 from './.lottie/blue1.json';
import lottieGrey2 from './.lottie/grey2.json';
import lottieBlue2 from './.lottie/blue2.json';
import Fields from './components/Fields/Fields';

const Lottie = loadable(() => import('lottie-react'), { fallback: null, ssr: false });

const Home = () => {
	const store = useStore();
	const context = useContext(AppContext);
	const dispatch: TDispatch = useDispatch();
	const app: TAppState = useSelector((state: TRootState) => state.app);
	const city: TCity = useSelector((state: TRootState) => state.city);
	const filterBase: TFilterBase = useSelector((state: TRootState) => state.filterBase);
	const filterGlobal: TFilterGlobal = useSelector((state: TRootState) => state.filterGlobal);

	const {
		isMobile,
		stats: { allApartmentCount, newApartmentCount, resaleApartmentCount, needUpdate },
		mortgagePrograms,
		windowSize,
		homepageTexts: { headerText },
	} = app;
	const {
		realtyType,
		roomsCntForFilter,
		priceTotalMinGte,
		priceTotalMinLte,
		areaIds,
		metroIds,
		mortgageInfo: { initialFee, monthlyPayment },
	} = filterBase;
	const { selectionMode } = filterGlobal;

	const { isShowing, toggle } = useModal();
	const isMounted = useComponentDidMount();

	const isMortgage = selectionMode === 'mortgage';

	useEffect(() => {
		if (priceTotalMinLte === null && priceTotalMinGte === null) {
			dispatch({
				type: SET_BASE_FILTER,
				payload: {
					priceTotalMinGte: PRICE_TOTAL_MIN_GTE_DEFAULT,
					priceTotalMinLte: PRICE_TOTAL_MIN_LTE_DEFAULT,
				},
			});
		}

		if (!mortgagePrograms.isLoaded) {
			getMortgageProgramsStats()(dispatch, store.getState, context);
		}
	});

	useEffect(() => {
		if (needUpdate) {
			getAppStats()(dispatch, store.getState, context);
			getLiraInitialData()(dispatch, store.getState, context).then();
		}
	}, [needUpdate]);

	const linkParams = `type=${realtyType}${
		isMortgage
			? `&initialFee=${initialFee}&monthlyPayment=${monthlyPayment}`
			: `&priceFrom=${priceTotalMinGte}&priceTo=${priceTotalMinLte}`
	}&rooms=${roomsCntForFilter.toString()}&area=${areaIds.toString()}&metro=${metroIds.toString()}&selection=${selectionMode}`;

	return (
		<Root id="Root">
			{windowSize <= 0 && <FakeTabs isMobile />}
			<Hero>
				<Container>
					<Content>
						<Title>{headerText?.title ?? 'Агрегатор недвижимости'}</Title>
						<Subtitle>{headerText?.text ?? 'Собираем предложения со всех площадок вашего города'}</Subtitle>
						{windowSize > 0 && <FakeTabs />}
						<Fields toggle={toggle} />
						<ButtonsWrapper>
							<Button
								size="formS"
								color="white"
								design="rounded"
								style={{ paddingLeft: '20px', paddingRight: '20px' }}
								isLink
								to={`/${city.link}/selection/search-map?${linkParams}`}
							>
								<ButtonIcon>{mapSelectionIcon}</ButtonIcon>
								{isMobile ? 'На карте' : 'Показать на карте'}
							</Button>
							<Button
								size="formS"
								color="primary"
								design="rounded"
								style={{ paddingLeft: '44px', paddingRight: '44px' }}
								isLink
								to={`/${city.link}/selection/search?${linkParams}`}
							>
								<ButtonIcon viewBox="-2 -2 24 24">{search2021Icon}</ButtonIcon>
								Найти
							</Button>
						</ButtonsWrapper>
					</Content>
				</Container>
				{windowSize > 2 && isMounted && (
					<>
						<LottieWrapper
							style={{ width: '135px', bottom: '60%', left: '50%', transform: 'translateX(-650px)' }}
						>
							<Lottie animationData={lottieGrey1} loop />
							<Lottie animationData={lottieBlue1} loop />
						</LottieWrapper>

						<LottieWrapper
							style={{ width: '135px', top: '45%', left: '50%', transform: 'translateX(520px)' }}
						>
							<Lottie animationData={lottieGrey2} loop />
							<Lottie animationData={lottieBlue2} loop />
						</LottieWrapper>
					</>
				)}
			</Hero>
			<Footer>
				<Slider {...SLIDER_SETTINGS}>
					<PinkCard
						city={city}
						resaleApartmentCount={resaleApartmentCount}
						allApartmentCount={allApartmentCount}
						newApartmentCount={newApartmentCount}
						index={1}
					/>
					<GreenCard index={2} />
					<MortgageCard index={3} data={mortgagePrograms.data} />
				</Slider>
			</Footer>
			{isMounted && (
				<Modal isShowing={isShowing} hide={() => toggle()}>
					<CityModal modalToggle={toggle} />
				</Modal>
			)}
			<SlideStyle />
		</Root>
	);
};

export default Home;
