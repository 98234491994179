export const OPEN_AUTH_POPUP = 'OPEN_AUTH_POPUP';
export const CLOSE_AUTH_POPUP = 'CLOSE_AUTH_POPUP';

export const OPEN_PAYMENT_POPUP = 'OPEN_PAYMENT_POPUP';
export const CLOSE_PAYMENT_POPUP = 'CLOSE_PAYMENT_POPUP';
export const CHANGE_PAYMENT_POPUP_STEP = 'CHANGE_PAYMENT_POPUP_STEP';

export const OPEN_CONFIRM_PHONE_POPUP = 'OPEN_CONFIRM_PHONE_POPUP';
export const CLOSE_CONFIRM_PHONE_POPUP = 'CLOSE_CONFIRM_PHONE_POPUP';

export const TOGGLE_CALLBACK_POPUP = 'TOGGLE_CALLBACK_POPUP';

export const TOGGLE_ACTION_CALLBACK_POPUP = 'TOGGLE_ACTION_CALLBACK_POPUP';
export const SET_ACTION_CALLBACK_POPUP = 'SET_ACTION_CALLBACK_POPUP';
export const CLEAR_ACTION_CALLBACK_POPUP = 'CLEAR_ACTION_CALLBACK_POPUP';
export const FETCH_ACTION_CALLBACK_POPUP = 'FETCH_ACTION_CALLBACK_POPUP';

export const POPUP_IS_OPEN = 'POPUP_IS_OPEN';
