import { CLEAR_VIDEO_STATE, VIDEO_FETCH_VIDEO_DATA, VIDEO_SEARCH_VIDEO_DATA } from '../constants/video';
import { FAIL, START, SUCCESS } from '../constants';
import type { TVideoState } from '../types/video';

const initialState: TVideoState = {
	videos: [],
	categories: [],
	categorySlug: null,
	pageNumber: 0,
	pageCount: 0,
	isLoading: false,
	isLoaded: false,
};

const reducer = (state: TVideoState = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case CLEAR_VIDEO_STATE:
			return initialState;

		case VIDEO_FETCH_VIDEO_DATA + START:
		case VIDEO_SEARCH_VIDEO_DATA + START:
			return { ...state, isLoading: true, isLoaded: false };

		case VIDEO_SEARCH_VIDEO_DATA + SUCCESS:
			return {
				...state,
				videos: payload,
				isLoading: false,
				isLoaded: true,
			};

		case VIDEO_FETCH_VIDEO_DATA + SUCCESS:
			return {
				...state,
				videos: [...state.videos, ...payload.videoData.items],
				categories: payload.videoData.categoryList,
				categorySlug: payload.slug,
				pageNumber: payload.videoData.meta.pageNumber,
				pageCount: payload.videoData.meta.pageCount,
				isLoading: false,
				isLoaded: true,
			};

		case VIDEO_FETCH_VIDEO_DATA + FAIL:
		case VIDEO_SEARCH_VIDEO_DATA + FAIL:
			return {
				...state,
				isLoading: false,
				isLoaded: false,
			};

		default:
			return state;
	}
};

export default reducer;
