import React from 'react';

export const AuthDialogImg = (
	<>
		<g clipPath="url(#authModal)">
			<path
				d="M74.739 71.327a6 6 0 01-6 6H6a6 6 0 01-6-6V40.084a6 6 0 016-6h62.739a6 6 0 016 6v31.243zM.456 70.87a6 6 0 006 6h61.827a6 6 0 006-6V40.54a6 6 0 00-6-6H6.456a6 6 0 00-6 6V70.87z"
				fill="#D2DFFA"
			/>
			<path
				d="M58.551 57.14H10.715a.729.729 0 100 1.459h47.836a.73.73 0 000-1.458zM61.903 62.518h-51.12a.797.797 0 100 1.594h51.12a.797.797 0 100-1.594zM61.903 67.986h-51.12a.797.797 0 100 1.594h51.12a.798.798 0 000-1.594zM25.355 49.303h-13.5a1.868 1.868 0 000 3.737h13.5a1.868 1.868 0 100-3.737z"
				fill="#C2D1F4"
			/>
			<path
				d="M71.07 34.312H3.668a3.44 3.44 0 100 6.88H71.07a3.44 3.44 0 100-6.88zM165 87H86a3 3 0 00-3 3v70a6 6 0 006 6h73a6 6 0 006-6V90a3 3 0 00-3-3z"
				fill="#D2DFFA"
			/>
			<path d="M77.657 62.518H8.983v67.312a4 4 0 004 4h64.674V62.518z" fill="#C2D1F4" />
			<path d="M77.657 62.518H8.983v7.791h68.674v-7.791z" fill="#D2DFFA" />
			<path
				d="M13.224 67.803c.806 0 1.46-.673 1.46-1.503 0-.83-.654-1.504-1.46-1.504-.806 0-1.46.673-1.46 1.504 0 .83.654 1.503 1.46 1.503zM19.06 67.803c.807 0 1.46-.673 1.46-1.503 0-.83-.653-1.504-1.46-1.504-.805 0-1.458.673-1.458 1.504 0 .83.653 1.503 1.459 1.503zM24.897 67.803c.806 0 1.46-.673 1.46-1.503 0-.83-.654-1.504-1.46-1.504-.806 0-1.459.673-1.459 1.504 0 .83.653 1.503 1.46 1.503z"
				fill="#fff"
			/>
			<path
				d="M181 13.488h-79.689a4 4 0 00-4 4v79.351a4 4 0 004 4H179a6 6 0 006-6V17.488a4 4 0 00-4-4z"
				fill="#C2D1F4"
			/>
			<path d="M181 13.488h-79.689a4 4 0 00-4 4v2.207a4 4 0 004 4H185v-6.207a4 4 0 00-4-4z" fill="#D2DFFA" />
			<path
				d="M102.874 20.505c1.058 0 1.915-.898 1.915-2.005 0-1.107-.857-2.005-1.915-2.005s-1.915.898-1.915 2.005c0 1.107.857 2.005 1.915 2.005zM112.003 19.912c.748-.747.719-1.988-.064-2.77-.784-.784-2.025-.812-2.773-.065-.748.747-.719 1.988.064 2.77.784.784 2.025.813 2.773.065zM118.287 20.505c1.058 0 1.915-.898 1.915-2.005 0-1.107-.857-2.005-1.915-2.005-1.057 0-1.915.898-1.915 2.005 0 1.107.858 2.005 1.915 2.005z"
				fill="#fff"
			/>
			<path
				d="M136.81 129.87c-8.368-5.864-10.324-16.723-9.943-26.496.054-1.629-.109-3.366.652-4.778.76-1.412 2.608-2.389 4.075-1.737 1.195.597 1.738 1.954 2.445 3.149.923 1.574 2.228 2.823 3.803 3.637 1.033.543 2.337.869 3.369.326 1.413-.76 1.576-2.715 1.576-4.344 0-3.04-.055-6.026-.055-9.067 0-1.629 0-3.257.489-4.832.489-1.52 1.63-2.986 3.152-3.42 1.521-.435 3.477.434 3.803 2.063.163.651 0 1.357.054 2.009.055.651.381 1.411.978 1.629.652.217 1.304-.272 1.793-.76 1.685-1.63 2.826-3.747 3.858-5.864 1.032-2.118 2.119-4.235 3.641-6.027 1.521-1.792 3.64-3.203 5.976-3.312 2.337-.109 4.782 1.303 5.379 3.583.598 2.28-.76 4.615-2.173 6.461-2.173 2.77-4.727 5.158-7.552 7.167-.489.38-1.087.76-1.25 1.358-.326 1.086.652 2.171 1.739 2.497 1.195.38 2.499.272 3.803.435 1.25.163 2.662.705 3.097 1.9.652 1.683-.706 3.42-2.065 4.561-2.716 2.28-5.868 4.072-9.182 5.32-1.196.435-2.445.815-3.423 1.684-.978.814-1.522 2.28-.978 3.42.597 1.14 2.064 1.52 3.314 1.303 1.25-.217 2.391-.923 3.586-1.357 2.228-.869 5.108-.706 6.52 1.249.924 1.248 1.033 3.04.544 4.56-.489 1.521-1.413 2.824-2.445 4.018-3.586 4.181-8.151 7.547-13.312 9.393-5.325 1.683-10.106 2.172-15.268.272z"
				fill="#7A9BDF"
			/>
			<path
				d="M169.411 73.404l-.163.163c-.109.108-.272.271-.489.434-.435.38-1.033.923-1.848 1.683a62.389 62.389 0 00-6.194 6.678c-1.195 1.52-2.445 3.204-3.803 5.05-1.304 1.846-2.717 3.854-4.075 5.972-1.359 2.172-2.771 4.452-4.021 6.95-.652 1.248-1.195 2.552-1.739 3.855-.543 1.303-1.141 2.66-1.738 4.017-2.282 5.43-4.347 10.696-5.814 15.637-1.467 4.887-2.336 9.447-2.717 13.302-.38 3.855-.326 7.004-.163 9.176.109 1.086.163 1.9.218 2.498.054.271.054.488.054.651v.217s0-.054-.054-.217c0-.163-.055-.38-.109-.651-.109-.543-.217-1.412-.326-2.498-.217-2.172-.326-5.321.054-9.176.381-3.855 1.196-8.47 2.663-13.356 1.467-4.941 3.477-10.207 5.759-15.637.598-1.357 1.141-2.715 1.739-4.018a83.466 83.466 0 011.738-3.855c1.25-2.497 2.663-4.832 4.021-6.95a125.396 125.396 0 014.129-5.972c1.304-1.846 2.609-3.529 3.804-5.049 2.445-2.986 4.673-5.212 6.303-6.624.815-.706 1.467-1.249 1.901-1.629a4.33 4.33 0 00.489-.434c.326-.217.381-.272.381-.217z"
				fill="#263238"
			/>
			<path
				d="M148.709 100.171s-.054-.218-.163-.706c-.109-.434-.163-1.086-.326-1.9a140.472 140.472 0 01-.706-6.353 236.23 236.23 0 00-.761-6.298c-.109-.814-.217-1.466-.326-1.9a10.387 10.387 0 00-.109-.706s.109.217.218.706c.108.434.271 1.086.38 1.9.326 1.629.598 3.855.869 6.298.272 2.443.489 4.67.652 6.352.109.76.163 1.412.218 1.9.054.435.054.707.054.707z"
				fill="#263238"
			/>
			<path
				d="M169.573 96.261s-.271.055-.815.109c-.543.054-1.304.108-2.282.217a107.63 107.63 0 00-7.443 1.14c-2.88.543-5.488 1.14-7.335 1.629-.924.217-1.685.434-2.228.597-.544.163-.815.217-.815.217s.271-.108.76-.325c.489-.163 1.25-.435 2.174-.706 1.847-.543 4.455-1.195 7.335-1.738a76.951 76.951 0 017.444-1.031c.978-.109 1.738-.109 2.282-.109h.923zM139.58 123.354s-.054-.108-.163-.271l-.38-.76c-.326-.652-.706-1.629-1.25-2.824-1.032-2.389-2.282-5.755-3.64-9.447-1.358-3.692-2.608-7.058-3.586-9.501-.489-1.195-.869-2.118-1.141-2.824a5.286 5.286 0 00-.326-.76c-.054-.163-.109-.271-.109-.271s.055.108.163.271l.381.76c.326.652.706 1.63 1.249 2.824 1.033 2.389 2.282 5.755 3.641 9.447 1.358 3.692 2.608 7.058 3.586 9.447.489 1.195.869 2.118 1.141 2.823.108.326.217.543.326.761.108.217.108.325.108.325zM167.345 114.45s-.108.054-.271.108c-.218.055-.489.163-.815.272-.761.217-1.739.543-2.989.977a693.716 693.716 0 00-9.78 3.258c-3.803 1.303-7.281 2.443-9.834 3.203-1.25.38-2.282.706-2.989.869-.326.109-.597.163-.815.217-.163.054-.271.054-.271.054s.108-.054.271-.108c.218-.054.489-.163.815-.272.761-.217 1.739-.543 2.989-.977a668.606 668.606 0 009.78-3.258c3.803-1.303 7.281-2.443 9.78-3.203 1.25-.38 2.282-.706 2.988-.869.326-.108.598-.163.815-.217.218-.054.326-.108.326-.054z"
				fill="#263238"
			/>
			<path
				d="M157.948 11.928l-2.956 1.023-1.25-3.806c-2.331-7.043-9.663-10.849-16.427-8.406-6.764 2.385-10.345 10.11-8.072 17.153l1.251 3.806-2.956 1.022c-2.274.795-3.524 3.465-2.728 5.85l8.185 24.879c.795 2.442 3.296 3.692 5.627 2.897l30.353-10.85c2.331-.851 3.581-3.464 2.785-5.906l-8.185-24.822c-.796-2.385-3.353-3.692-5.627-2.84zm-23.134 3.976c-1.251-3.862.682-8.065 4.433-9.428 3.695-1.307 7.731.738 8.981 4.6l1.251 3.806-13.415 4.771-1.25-3.749zM151.866 38l2.103 6.362a.454.454 0 01-.341.625l-2.899 1.022c-.227.057-.511-.057-.568-.284l-2.103-6.362c-2.274-.113-4.32-1.59-5.116-3.976-1.023-3.067.568-6.418 3.524-7.497 2.956-1.08 6.139.568 7.162 3.692a6.2 6.2 0 01-1.762 6.418z"
				fill="#7A9BDF"
			/>
			<path
				d="M142.726 17.482c6.761-2.442 12.216-4.493 12.184-4.582-.032-.088-5.539 1.82-12.3 4.261-6.761 2.442-12.216 4.493-12.184 4.581.032.089 5.539-1.819 12.3-4.26zM161.301 13.916s0 .057.057.17c.057.114.114.285.17.455.171.398.342.966.626 1.704.511 1.534 1.25 3.692 2.16 6.362.909 2.67 1.989 5.907 3.183 9.428.568 1.761 1.25 3.636 1.876 5.567.284.965.511 2.045.454 3.124-.057.511-.17 1.079-.454 1.59a3.845 3.845 0 01-1.08 1.25c-.853.681-1.876 1.079-2.899 1.476-.967.341-1.99.682-2.956 1.08-1.933.681-3.752 1.363-5.514 1.988-3.524 1.25-6.707 2.385-9.378 3.35-2.672.91-4.832 1.705-6.31 2.216-.739.227-1.307.454-1.705.568a3.913 3.913 0 00-.455.17c-.113.057-.17.057-.17.057s.057 0 .17-.056c.114-.057.284-.114.455-.17.398-.171.966-.398 1.705-.626 1.535-.568 3.638-1.363 6.31-2.328 2.671-.966 5.854-2.102 9.378-3.408a775.253 775.253 0 005.514-1.989c.966-.34 1.933-.681 2.899-1.079.966-.34 1.989-.738 2.785-1.42.398-.34.739-.738 1.023-1.192.228-.455.398-.966.455-1.477.114-1.023-.114-2.045-.398-3.01-.625-1.932-1.25-3.806-1.819-5.567-1.194-3.578-2.217-6.76-3.126-9.429-.853-2.67-1.592-4.828-2.103-6.361-.228-.739-.398-1.307-.569-1.761-.057-.17-.113-.34-.113-.454-.171-.17-.171-.228-.171-.228z"
				fill="#263238"
			/>
			<path
				d="M145.33 6.135s-.285-.17-.853-.398c-.568-.227-1.421-.454-2.501-.51a7.922 7.922 0 00-3.638.68 8.015 8.015 0 00-3.467 2.897 7.799 7.799 0 00-1.307 4.317c0 1.42.284 2.67.568 3.692.284 1.023.625 1.875.796 2.443.227.568.341.908.284.908 0 0-.171-.284-.398-.852-.227-.568-.568-1.363-.909-2.385a12.093 12.093 0 01-.626-3.806 7.96 7.96 0 011.364-4.487c.967-1.42 2.274-2.442 3.638-3.01 1.364-.568 2.672-.739 3.752-.625 1.08.057 1.932.34 2.501.625.284.113.511.227.625.34.114.057.171.114.171.17zM145.557 28.344s.114-.057.284-.17c.171-.114.512-.228.853-.341a5.386 5.386 0 011.478-.228c.568-.056 1.307.057 1.989.284 1.478.512 2.785 1.988 3.467 3.976.341.966.341 2.159 0 3.238-.341 1.136-.966 2.158-1.875 3.067l.056-.17c.739 2.044 1.592 4.316 2.445 6.645l.056.17-.17.057a18.08 18.08 0 00-1.307.455c-.967.34-1.876.625-2.843.965l-.113.057-.057-.113c-.796-2.33-1.648-4.601-2.274-6.703l.114.114c-1.137-.057-2.217-.568-3.069-1.193-.853-.625-1.478-1.42-1.933-2.272-.398-.852-.625-1.76-.625-2.556 0-.795.227-1.59.454-2.215a5.788 5.788 0 011.933-2.5c.739-.454 1.137-.567 1.137-.567s-.341.17-.91.625c-.568.454-1.307 1.25-1.819 2.499-.511 1.192-.682 2.953.171 4.6.398.796 1.023 1.59 1.876 2.159.796.625 1.819 1.08 2.899 1.136h.113v.114c.626 2.101 1.478 4.316 2.274 6.702l-.171-.114c.91-.284 1.876-.625 2.843-.965.454-.17.909-.284 1.307-.455l-.114.17c-.852-2.328-1.648-4.6-2.444-6.645l-.057-.057.057-.056a7.328 7.328 0 001.819-2.954c.284-1.08.284-2.159 0-3.124-.625-1.931-1.876-3.351-3.297-3.862-.682-.284-1.364-.341-1.932-.341-.569 0-1.08.113-1.478.227-.739.17-1.137.34-1.137.34z"
				fill="#263238"
			/>
			<path
				d="M127.316 154.289l-45.555-.228c-5.472-.045-9.895-4.465-9.895-9.933l.501-114.1a9.96 9.96 0 019.987-9.888l45.554.228c5.472.046 9.896 4.466 9.896 9.934l-.502 114.145c-.046 5.468-4.514 9.888-9.986 9.842z"
				fill="#455A64"
			/>
			<path
				d="M127.817 24.287h-8.801c-1.185 0-2.143 1.003-2.143 2.233v1.595c0 1.23-.957 2.233-2.143 2.233l-17.191-.092c-1.186 0-2.144-1.002-2.144-2.232v-1.595c0-1.23-.957-2.233-2.143-2.233h-3.1l-7.707-.046c-3.83 0-6.977 3.099-6.977 6.927l-.456 111.365c0 3.828 3.101 6.972 6.931 6.972l45.464.228c3.83 0 6.977-3.099 6.977-6.927l.456-111.365c-.092-3.919-3.192-7.017-7.023-7.063z"
				fill="#fff"
			/>
			<path
				d="M121.707 134.696l-33.79-.137c-.365 0-.638-.365-.638-.866l.045-8.84c0-.456.274-.866.639-.866l33.79.137c.364 0 .638.365.638.866l-.046 8.84c0 .501-.273.866-.638.866zM94.626 57.032l-11.72-.054-.01 2.141 11.72.054.01-2.142z"
				fill="#7A9BDF"
			/>
			<path opacity=".1" d="M94.626 57.032l-11.72-.054-.01 2.141 11.72.054.01-2.142z" fill="#000" />
			<path
				d="M129.049 70.218s.091 0 .273-.045c.183-.046.411-.274.456-.593v-3.144-2.278-.638c0-.228 0-.41-.137-.592a.649.649 0 00-.547-.274h-7.296c-9.758-.045-23.256-.09-38.122-.136-.182 0-.41.09-.501.273-.137.182-.137.365-.137.592v6.106c.046.183.182.32.365.41a.578.578 0 00.273.046h2.326c3.511 0 6.886.046 10.123.046 6.43.045 12.267.09 17.146.09 4.879.046 8.801.092 11.537.092 1.368 0 2.417.045 3.146.045h.821-15.55c-4.879 0-10.716-.045-17.145-.045-3.238 0-6.612-.046-10.124-.046H83.63c-.136 0-.273 0-.364-.045-.228-.091-.41-.32-.502-.547-.045-.137-.045-.273-.045-.365v-.319-1.367-2.734-1.367-.364c0-.137.091-.274.137-.365.136-.228.41-.364.684-.364 14.91.09 28.363.136 38.121.228 2.417 0 4.652.045 6.567.045h.729c.274 0 .502.137.639.32.137.181.137.455.137.683v2.916c0 1.367-.046 2.415-.046 3.144-.046.365-.319.592-.502.638-.045 0-.136-.046-.136-.046z"
				fill="#7A9BDF"
			/>
			<path d="M107.485 65.347l-21.432-.099-.01 2.005 21.433.099.009-2.005z" fill="#7A9BDF" />
			<path opacity=".2" d="M107.485 65.347l-21.432-.099-.01 2.005 21.433.099.009-2.005z" fill="#fff" />
			<path d="M94.535 76.67l-11.72-.053-.01 2.142 11.72.053.01-2.141z" fill="#7A9BDF" />
			<path opacity=".1" d="M94.535 76.67l-11.72-.053-.01 2.142 11.72.053.01-2.141z" fill="#000" />
			<path
				d="M128.729 89.858s.091 0 .274-.046c.182-.045.41-.182.593-.501.091-.182.091-.365.091-.592v-.73-1.822-2.279-.637c0-.228 0-.41-.137-.593a.706.706 0 00-.502-.364c-.182-.046-.456-.046-.684-.046h-6.566c-9.713-.046-23.165-.091-37.985-.137a.835.835 0 00-.82.73v5.468c0 .227-.046.455.045.637a.835.835 0 00.319.456c.182.091.365.137.547.137h2.007c3.51 0 6.885.045 10.077.045 6.43.046 12.221.091 17.1.091 4.88.046 8.801.092 11.537.092 1.368 0 2.417.045 3.147.045h.82c.046.046.137.046.137.046h-15.777c-4.88 0-10.671-.046-17.1-.046-3.238 0-6.612-.045-10.078-.045h-2.007c-.228 0-.501-.046-.684-.183a.895.895 0 01-.41-.592c-.046-.273-.046-.456-.046-.684v-1.367-2.734-1.412c.046-.547.548-.957 1.05-.957 14.82.091 28.271.137 37.984.228 2.417 0 4.651.045 6.567.045.228 0 .501-.045.729.046.228.091.456.228.547.456.137.182.137.455.137.683V88.765c0 .227 0 .41-.091.591a1.108 1.108 0 01-.593.502h-.228zM94.49 97.13l-11.72-.053-.01 2.142 11.72.053.01-2.141z"
				fill="#7A9BDF"
			/>
			<path opacity=".1" d="M94.49 97.13l-11.72-.053-.01 2.142 11.72.053.01-2.141z" fill="#000" />
			<path
				d="M128.638 110.317s.091 0 .274-.045c.182-.046.41-.183.592-.502.092-.182.092-.364.092-.592v-.729-1.823-2.278-.638c0-.228 0-.41-.137-.592a.706.706 0 00-.502-.365c-.182-.046-.456-.046-.684-.046h-6.566c-9.713-.045-23.165-.091-37.985-.136a.835.835 0 00-.821.729v5.468c0 .228-.046.455.046.638a.834.834 0 00.319.455c.182.092.364.137.547.137H85.819c3.512 0 6.886.046 10.078.046 6.43.045 12.221.091 17.1.091 4.879.045 8.801.091 11.537.091 1.368 0 2.417.046 3.146.046h.821c.046.045.137.045.137.045H112.86c-4.879 0-10.67-.045-17.1-.045-3.237 0-6.612-.046-10.078-.046H83.677c-.228 0-.502-.046-.684-.182a.896.896 0 01-.41-.593c-.046-.273-.046-.455-.046-.683v-1.367-2.734-1.413c.046-.547.547-.957 1.049-.957 14.82.091 28.272.137 37.985.228 2.417 0 4.651.046 6.566.046.228 0 .502-.046.73.045.228.091.456.228.547.456.137.182.137.456.137.683v5.468c0 .228 0 .456-.091.593a1.108 1.108 0 01-.593.501h-.228zM107.394 84.987l-21.432-.1-.01 2.006 21.433.098.009-2.004z"
				fill="#7A9BDF"
			/>
			<path opacity=".2" d="M107.394 84.987l-21.432-.1-.01 2.006 21.433.098.009-2.004z" fill="#fff" />
			<path
				d="M87.94 106.584c.003-.554-.424-1.004-.953-1.007-.529-.002-.96.444-.962.998-.003.554.424 1.004.953 1.007.529.002.96-.444.962-.998zM90.879 106.631c.002-.554-.425-1.005-.953-1.007-.53-.003-.96.444-.963.998-.002.554.424 1.004.953 1.007.529.002.96-.444.963-.998zM93.771 106.631c.003-.553-.424-1.004-.953-1.007-.529-.002-.96.445-.962.998-.003.554.424 1.005.953 1.007.529.003.96-.444.962-.998zM96.664 106.632c.002-.553-.424-1.004-.953-1.007-.53-.002-.96.445-.963.998-.002.554.424 1.005.953 1.007.53.003.96-.444.963-.998zM99.556 106.633c.003-.553-.424-1.004-.953-1.006-.528-.003-.96.444-.962.998-.002.553.424 1.004.953 1.007.53.002.96-.445.962-.999zM102.494 106.68c.002-.554-.424-1.004-.953-1.007-.529-.002-.96.445-.962.998-.003.554.424 1.005.953 1.007.529.003.959-.444.962-.998zM105.387 106.681c.002-.554-.425-1.004-.954-1.007-.528-.002-.959.445-.962.998-.002.554.425 1.005.953 1.007.529.003.96-.444.963-.998zM108.28 106.682c.002-.554-.425-1.004-.954-1.007-.528-.002-.959.445-.962.999-.002.553.425 1.004.953 1.006.529.003.96-.444.963-.998zM111.218 106.683c.002-.553-.424-1.004-.953-1.007-.529-.002-.96.445-.962.998-.003.554.424 1.005.952 1.007.529.003.96-.444.963-.998z"
				fill="#7A9BDF"
			/>
			<g opacity=".2">
				<path
					opacity=".2"
					d="M87.94 106.584c.003-.554-.424-1.004-.953-1.007-.529-.002-.96.444-.962.998-.003.554.424 1.004.953 1.007.529.002.96-.444.962-.998zM90.879 106.631c.002-.554-.425-1.005-.953-1.007-.53-.003-.96.444-.963.998-.002.554.424 1.004.953 1.007.529.002.96-.444.963-.998zM93.771 106.631c.003-.553-.424-1.004-.953-1.007-.529-.002-.96.445-.962.998-.003.554.424 1.005.953 1.007.529.003.96-.444.962-.998zM96.664 106.632c.002-.553-.424-1.004-.953-1.007-.53-.002-.96.445-.963.998-.002.554.424 1.005.953 1.007.53.003.96-.444.963-.998zM99.556 106.633c.003-.553-.424-1.004-.953-1.006-.528-.003-.96.444-.962.998-.002.553.424 1.004.953 1.007.53.002.96-.445.962-.999zM102.494 106.68c.002-.554-.424-1.004-.953-1.007-.529-.002-.96.445-.962.998-.003.554.424 1.005.953 1.007.529.003.959-.444.962-.998zM105.387 106.681c.002-.554-.425-1.004-.954-1.007-.528-.002-.959.445-.962.998-.002.554.425 1.005.953 1.007.529.003.96-.444.963-.998zM108.28 106.682c.002-.554-.425-1.004-.954-1.007-.528-.002-.959.445-.962.999-.002.553.425 1.004.953 1.006.529.003.96-.444.963-.998zM111.218 106.683c.002-.553-.424-1.004-.953-1.007-.529-.002-.96.445-.962.998-.003.554.424 1.005.952 1.007.529.003.96-.444.963-.998z"
					fill="#fff"
				/>
			</g>
			<path
				d="M107.069 40.919a1.835 1.835 0 001.049-1.64c0-.957-.73-1.778-1.642-1.778-.912 0-1.641.775-1.687 1.778 0 .774.456 1.412 1.049 1.64-2.554.364-2.417 3.144-2.417 3.144l6.019.046s.137-2.826-2.371-3.19z"
				fill="#7A9BDF"
			/>
			<path
				opacity=".1"
				d="M107.069 40.919a1.835 1.835 0 001.049-1.64c0-.957-.73-1.778-1.642-1.778-.912 0-1.641.775-1.687 1.778 0 .774.456 1.412 1.049 1.64-2.554.364-2.417 3.144-2.417 3.144l6.019.046s.137-2.826-2.371-3.19z"
				fill="#000"
			/>
			<path d="M116.88 45.936l-21.523-.1-.012 2.552 21.523.1.012-2.552z" fill="#7A9BDF" />
			<path opacity=".2" d="M116.88 45.936l-21.523-.1-.012 2.552 21.523.1.012-2.552z" fill="#fff" />
			<path
				d="M50.799 163.75v-4.205l-7.486-.049-.049 6.307.49.049c2.054.147 10.519.342 11.938-.098 1.468-.489-4.893-2.004-4.893-2.004z"
				fill="#7A9BDF"
			/>
			<path
				d="M49.527 165.509c3.459 0 6.263-.065 6.263-.146 0-.081-2.804-.147-6.263-.147s-6.263.066-6.263.147c0 .081 2.804.146 6.263.146zM50.946 164.58c-.049-.048 0-.244.147-.488.147-.196.342-.343.391-.294.05.049-.049.245-.195.44-.147.245-.294.391-.343.342zM51.435 164.874c-.049 0-.049-.293.098-.538.147-.244.342-.44.391-.391.05.049-.049.245-.195.489-.098.293-.245.44-.294.44z"
				fill="#263238"
			/>
			<path
				d="M45.515 165.363c-.049 0-.147-.098-.293-.293a3.9 3.9 0 00-.636-.636c-.294-.196-.588-.342-.832-.342-.245-.049-.392 0-.392-.049s.147-.147.392-.147c.244 0 .636.098.978.342.294.245.538.538.636.734.147.195.196.342.147.391zM50.946 163.798c.049.049-.098.196-.294.293-.195.098-.391.049-.44 0 0-.049.147-.146.342-.244.196-.049.392-.098.392-.049zM50.848 163.407c0 .049-.196.147-.44.147-.245 0-.44-.049-.44-.098s.195-.147.44-.147c.244-.049.44 0 .44.098z"
				fill="#263238"
			/>
			<path
				d="M69.294 163.75v-4.205l-7.486-.049-.05 6.258.49.049c2.055.147 10.52.391 11.938-.049 1.468-.489-4.892-2.004-4.892-2.004z"
				fill="#7A9BDF"
			/>
			<path
				d="M68.022 165.509c3.458 0 6.262-.065 6.262-.146 0-.081-2.804-.147-6.263-.147-3.458 0-6.262.066-6.262.147 0 .081 2.804.146 6.263.146zM69.392 164.58c-.049-.048 0-.244.147-.488.147-.196.342-.343.391-.294.049.049-.049.245-.196.44-.097.245-.244.391-.342.342zM69.881 164.874c-.049 0-.049-.293.098-.538.147-.244.343-.44.392-.391.049.049-.05.245-.196.489-.098.293-.196.44-.294.44z"
				fill="#263238"
			/>
			<path
				d="M64.01 165.363c-.05 0-.147-.098-.294-.293a3.896 3.896 0 00-.636-.636c-.293-.196-.587-.342-.832-.342-.244-.049-.391 0-.391-.049s.147-.147.391-.147c.245 0 .636.098.979.342.294.245.538.538.636.734.098.195.147.342.147.391zM69.44 163.798c.05.049-.097.196-.293.293-.196.098-.392.049-.44 0 0-.049.146-.146.342-.244.196-.049.342-.098.391-.049zM69.343 163.407c0 .049-.196.147-.44.147-.245 0-.441-.049-.441-.098s.196-.147.44-.147c.196-.049.392 0 .44.098z"
				fill="#263238"
			/>
			<path
				d="M96.938 39.613c-.196-.196-.685-.098-.685-.098s.098-.587 0-.831c-.098-.293-.685-.245-.685-.245s-.049-.244 0-.684a.269.269 0 00-.147-.245c-.293-.195-.93-.146-.93-.146-.39-.294 1.175-2.396 1.713-3.08.538-.685.49-1.223-.049-1.223-.342 0-2.153 2.054-2.69 2.738-.539.685-2.007 2.592-2.056 1.076-.049-1.516-.685-2.69-1.027-2.885-.392-.195-.783.245-.587.685.195.342.293 1.027.342 1.515.05.392.05.832-.049 1.223-.244 1.124-.538 1.907-.636 2.2h-.049S78.737 49.83 77.954 50.565c-.685.635-14.58-.783-17.37-1.076-.782-.098-1.907-.244-2.837-.196-1.223.05-1.419.245-2.642.245-3.719.049-6.752.391-9.052.782a7.574 7.574 0 00-5.92 5.134c-2.789 8.752-9.15 29.482-10.275 41.118-.88 1.662-1.908 3.618-2.3 4.742-.782 2.152-.587 2.201-.342 2.249.734.147 1.517-3.715 1.81-2.786.294.929-1.565 4.595-.782 4.693.978.147 1.761-3.911 1.859-4.253.098-.343.587-.196.49.097-.148.44-1.518 4.987-.54 5.134.54.098.588-.88.588-.88s.979-4.351 1.419-4.302c.44.097-.049 2.689-.245 3.52-.244.831.098 1.32.538 1.075.294-.146 1.077-2.738 1.273-3.618.195-.831.733-3.227 1.419-1.857.684 1.369 1.712 2.151 2.152 2.151.44 0 .636-.587.245-.88-.294-.245-.685-.783-.93-1.223-.195-.342-.391-.733-.489-1.124-.342-1.565-.342-2.494-.342-2.494l-.098-.489c1.076-5.427 4.256-21.316 8.807-31.486l1.859 3.863.832 2.835-4.99 12.272 21.234-.293-3.18-12.174 3.865-12.712c4.159.098 14.874.244 16.635-.538 2.25-.978 13.847-15.597 13.847-15.597.783-.635 1.565-1.27 2.152-1.858 0-.048.685-.586.294-1.026z"
				fill="#FFBE9D"
			/>
			<path
				d="M41.16 74.766c.637-2.102 3.768-12.516 3.768-12.516s1.566 8.458 1.125 9.485c-.489 1.173-1.32 2.542-1.32 2.542-3.572 5.476-3.132 8.214-3.132 8.214l21.675-.684c.049-3.032-1.761-10.072-2.104-11.197-.391-1.124.88-4.791.88-4.791s2.447-2.494 2.35-3.765c-.196-2.591-.441-3.422-.441-3.422l13.21.489-.391-8.947-18.984-1.907c-.636 1.858-6.654.929-6.654.929-6.459.195-6.85 1.418-9.541 3.471-2.691 2.102-7.143 19.948-7.143 19.948l6.703 2.151z"
				fill="#7A9BDF"
			/>
			<path
				d="M53.98 72.664c0 .049-.44.049-1.126.049-.685 0-1.663-.05-2.74-.147a24.373 24.373 0 01-2.74-.391c-.685-.147-1.125-.245-1.125-.293 0-.05.44 0 1.125.097.685.098 1.664.196 2.74.343 1.077.097 2.055.195 2.74.244.685.049 1.126.049 1.126.098zM55.447 70.806c0 .05-.44.196-1.174.391-.734.196-1.81.44-2.985.587-1.174.147-2.25.147-3.033.147-.783 0-1.223-.098-1.223-.098 0-.049.489-.049 1.223-.049.783 0 1.81-.098 2.984-.196a40.39 40.39 0 002.936-.488c.832-.196 1.272-.294 1.272-.294zM62.738 62.592c.048 0 0 .44-.147 1.125-.147.684-.343 1.613-.587 2.64a44.927 44.927 0 01-.685 2.591c-.196.636-.343 1.076-.392 1.027-.049 0 .05-.44.196-1.076.196-.782.391-1.662.636-2.59.245-.979.44-1.859.636-2.592.196-.733.343-1.125.343-1.125zM41.35 73.515c.022-.078-1.44-.545-3.265-1.043-1.825-.497-3.321-.837-3.343-.759-.021.078 1.44.545 3.266 1.043 1.824.497 3.321.837 3.342.759zM64.206 58.534s-.196.049-.539 0c-.342-.049-.88-.098-1.468-.342-.587-.245-1.32-.587-1.957-1.174a6.688 6.688 0 01-1.614-2.298c-.392-.928-.49-1.906-.44-2.737.048-.88.293-1.614.684-2.2.392-.538.881-.832 1.224-.93.195-.049.342-.049.44-.049h.147s-.196 0-.538.147c-.343.098-.783.391-1.126.93-.685 1.026-.832 2.933-.147 4.742.343.88.881 1.662 1.517 2.2a6.134 6.134 0 001.86 1.173c1.174.49 2.005.49 1.956.538zM75.775 54.431c.005-2.484-.057-4.498-.138-4.498-.081 0-.151 2.014-.156 4.498-.005 2.484.057 4.498.138 4.498.081 0 .15-2.014.156-4.498z"
				fill="#263238"
			/>
			<path
				d="M57.697 48.804c.05-1.075.147-2.346.147-2.346s2.691-.294 2.887-3.08c.147-2.788 0-9.192 0-9.192l-4.942-2.494-5.43 4.254.831 13.934 4.99.293c.784.098 1.469-.538 1.517-1.369z"
				fill="#FFBE9D"
			/>
			<path
				d="M58.187 41.226s0 .05-.049.098c-.048.049-.146.147-.293.147-.294 0-.636-.342-.734-.782a1.395 1.395 0 010-.636c.049-.196.147-.391.294-.44.048-.049.146 0 .195 0 .05.049.098.049.098.098.049.098 0 .146 0 .146s0-.048-.049-.097c-.049-.05-.147-.098-.245-.05a.412.412 0 00-.195.343.998.998 0 000 .538c.098.39.342.684.587.733.098 0 .196-.049.245-.049.097-.049.097-.049.146-.049z"
				fill="#729BEE"
			/>
			<path
				d="M62.395 32.719c-.392-1.271-1.762-1.907-2.985-2.249-1.908-.538-3.963-.782-5.773-.098-1.86.685-4.061 1.858-4.061 3.912-1.077-.098-1.713.88-2.153 2.053-.245.684-.44 1.467-.44 2.25-.147 2.688-.881 6.355-2.643 8.31-.978 1.076-2.348 1.859-2.886 3.228-.49 1.173-.245 2.59-.685 3.764-.392 1.076-1.321 1.81-1.957 2.738-1.42 2.005-1.517 4.792-.49 7.04 1.028 2.25 3.083 3.863 5.333 4.596 2.251.734 4.746.685 7.046.05 1.712-.44 3.376-1.174 4.697-2.347 1.37-1.174 2.397-2.787 2.691-4.547.343-2.005-.196-4.01-.636-6.014-1.321-6.258-.93-6.6.734-12.419.049-.097.049-.195.098-.293 0-.098.049-.147.049-.244.049-.245.097-.44.097-.685-.489.196-1.027.049-1.418-.293-.343-.391-.49-.978-.343-1.467.196-.587.734-.978 1.272-1.173.538-.245 1.126-.343 1.664-.587 1.125-.538 1.908-1.663 2.446-2.836.294-.929.587-1.858.343-2.689z"
				fill="#263238"
			/>
			<path
				d="M58.383 41.324l.244.489c.147.293.343.733.538 1.222l.05.098h-.098c-.147 0-.343.049-.49.049-.342 0-.636.049-.978.049-.05 0-.098-.05-.147-.098v-.147c0-.098.049-.147.049-.195l.098-.343c.098-.44.195-.684.195-.684s0 .293-.097.684l-.05.343c-.048.097-.048.293 0 .293.245 0 .588-.049.93-.098.147 0 .343-.049.49-.049l-.05.098c-.195-.489-.39-.929-.489-1.271-.146-.245-.244-.44-.195-.44z"
				fill="#7A9BDF"
			/>
			<path
				d="M41.6 82.491l21.676-.684 9.443 36.815-1.762 42.243H59.46l1.42-41.363-8.416-25.62 1.272 68.645-11.596.147-1.223-64.489c-.05-1.809-.636-5.818-.44-7.627l1.125-8.067z"
				fill="#263238"
			/>
			<path
				d="M55.447 94.47c0 .048-.391 0-1.076-.05-.636-.097-1.566-.195-2.544-.39-.979-.196-1.86-.44-2.496-.587-.636-.196-1.027-.343-1.027-.343 0-.048 1.614.294 3.571.685 1.958.342 3.572.587 3.572.684zM65.086 158.762c3.216 0 5.823-.065 5.823-.146 0-.081-2.607-.147-5.823-.147s-5.822.066-5.822.147c0 .081 2.606.146 5.822.146zM48.108 160.914c3.108 0 5.627-.066 5.627-.147 0-.081-2.52-.147-5.627-.147-3.107 0-5.627.066-5.627.147 0 .081 2.52.147 5.627.147z"
				fill="#455A64"
			/>
			<path
				d="M52.267 160.62c-.05 0-.392-15.01-.685-33.491-.294-18.481-.49-33.49-.44-33.49.048 0 .39 15.009.684 33.49.294 18.481.49 33.491.44 33.491zM69.343 158.616v-.196-.586c0-.538.049-1.272.049-2.249.049-1.956.146-4.792.293-8.263.245-6.992.636-16.672 1.028-27.282v-.293.049c-2.593-10.268-4.893-19.606-6.557-26.353-.831-3.374-1.516-6.063-1.957-7.97-.244-.928-.391-1.662-.538-2.15a5.072 5.072 0 00-.147-.587c-.049-.147-.049-.196-.049-.196s.05.049.05.196c.048.146.097.342.146.537.147.49.343 1.223.587 2.152.49 1.906 1.174 4.596 2.055 7.969 1.713 6.747 4.061 16.037 6.654 26.304v.293c-.44 10.659-.831 20.29-1.125 27.282-.147 3.471-.294 6.307-.391 8.263-.05.978-.098 1.711-.098 2.249 0 .244-.05.44-.05.586.099.147.05.245.05.245z"
				fill="#455A64"
			/>
			<path
				d="M65.624 118.818c0 .049-1.125.342-2.544.586-1.076.196-1.957.294-2.348.343v-.098c.146.049.195.049.195.098h-.489l.294-.098a41.865 41.865 0 012.348-.489c1.37-.244 2.544-.391 2.544-.342zM65.38 120.236c0 .048-1.028 0-2.3-.098-1.272-.098-2.25-.196-2.25-.245 0-.049 1.027-.098 2.3 0 1.271.098 2.299.294 2.25.343z"
				fill="#455A64"
			/>
			<path
				opacity=".2"
				d="M41.503 83.273c.538.734 1.272.978 2.153 1.125.88.147 1.761.049 2.642-.049 5.578-.636 11.547-1.174 17.027-2.542-7.095.39-14.58.244-21.675.684 0 0-.05.489-.098.587M48.842 93.296l4.012 15.499-.293-14.864-3.719-.635z"
				fill="#000"
			/>
		</g>
		<defs>
			<clipPath id="authModal">
				<path fill="#fff" d="M0 0h185v166H0z" />
			</clipPath>
		</defs>
	</>
);
