import { CHANGE_USER_CITY, FAIL, GET_USER_LOCATION, START, SUCCESS } from '../constants';
import type { TUser } from '../types/core';

const initialState = {
	citySlug: null,
	cityIds: null,
	geoLocation: {
		isLoaded: false,
		isLoading: false,
	},
};

export default function user(state: TUser = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CHANGE_USER_CITY:
			return {
				...state,
				citySlug: payload.citySlug,
				cityIds: payload.cityIds,
			};

		case GET_USER_LOCATION + START:
			return {
				...state,
				geoLocation: {
					...state.geoLocation,
					isLoading: true,
					isLoaded: false,
				},
			};

		case GET_USER_LOCATION + SUCCESS:
			return {
				...state,
				geoLocation: {
					...payload,
					isLoading: false,
					isLoaded: true,
				},
			};

		case GET_USER_LOCATION + FAIL:
			return {
				...state,
				geoLocation: {
					isLoading: false,
					isLoaded: false,
				},
			};

		default:
			return state;
	}
}

export { initialState as userInitialState };
