/* @flow */
import {
	ADD_TO_COMPARE,
	CHANGE_COMPARE_ACTIVE_ITEM,
	CHANGE_COMPARE_OPTION,
	CLEAR_STORE,
	DELETE_FROM_COMPARE,
	FAIL,
	LOAD_COMPARE,
	LOAD_PROFILE,
	LOGOUT,
	PREPARE_CHANGE_CITY,
	SET_COMPARE_ITEMS,
	SET_DIFFERENCES,
	START,
	SUCCESS,
} from '../constants';
import type { TComparisonState } from '../types/compare';

const initialState: TComparisonState = {
	items: [],
	meta: null,
	option: 'flat',
	isLoading: false,
	isLoaded: false,
	needUpdate: true,
	comparison: {
		complex: {},
		apartment: {},
	},
	differences: false,
};

export default function compare(state: TComparisonState = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case LOAD_COMPARE + START:
			return {
				...state,
				isLoading: true,
			};

		case LOAD_COMPARE + SUCCESS:
			return {
				...state,
				...payload,
				isLoading: false,
				isLoaded: true,
				needUpdate: false,
			};

		case LOAD_COMPARE + FAIL:
			return {
				...state,
				isLoading: false,
				isLoaded: false,
			};

		case CHANGE_COMPARE_OPTION:
			return { ...state, option: payload };

		case CHANGE_COMPARE_ACTIVE_ITEM:
			return { ...state, activeItem: payload };

		case SET_COMPARE_ITEMS:
			return { ...state, ...payload };

		case CLEAR_STORE:
			return initialState;

		case ADD_TO_COMPARE:
		case DELETE_FROM_COMPARE:
		case PREPARE_CHANGE_CITY:
		case LOAD_PROFILE + SUCCESS:
		case LOGOUT + SUCCESS:
			return {
				...state,
				needUpdate: true,
			};

		case SET_DIFFERENCES:
			return {
				...state,
				differences: payload,
			};

		default:
			return state;
	}
}

export { initialState as compareInitialState };
