import styled from 'styled-components';
import Link from '../Link';

export const Root = styled.header`
	flex-shrink: 0;
	display: flex;
	width: 100%;
	max-width: 100vw;
	height: 56px;
	background-color: #fff;
	border-bottom: ${({ theme }) => `1px solid ${theme.middleGrayColor}`};

	@media (max-width: 768px) {
		position: fixed;
		top: 0;
		z-index: 1300;
	}

	& * {
		box-sizing: border-box;
	}
`;

export const ContainerWrapper = styled.div`
	display: flex;
	flex: 1 1 100%;
	justify-content: space-between;
	background-color: #fff;

	@media (max-width: 880px) {
		display: none;
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	background-color: #fff;

	@media (max-width: 880px) {
		display: none;
	}
`;

export const CommonInfoWrapper = styled.div`
	display: flex;
	gap: 20px;
	background-color: #fff;
	margin-right: 40px;

	@media (max-width: 1025px) {
		margin-right: 15px;
	}

	@media (max-width: 880px) {
		display: none;
	}
`;

export const AppBtnsWrapper = styled.div`
	display: flex;
	align-self: center;
	gap: 6px;
	max-width: 100%;
`;

export const BrandSection = styled.div`
	display: grid;
	grid-template-areas: 'logo city phone';
	grid-template-columns: repeat(3, auto);
	grid-column-gap: 16px;
	grid-row-gap: 2px;
	margin: 10px 20px 10px 40px;

	@media (max-width: 1200px) {
		grid-template-areas: 'logo city' 'logo phone';
		grid-template-columns: 70px auto;
		margin-left: 15px;
	}
`;

export const Brand = styled(Link)`
	grid-area: logo;
	display: flex;
	align-items: center;
	padding: 0;

	svg {
		width: 70px;
		height: 16px;
		fill: #000;
	}

	&:focus {
		outline: none;

		svg {
			fill: ${({ theme }) => theme.primaryColor};
		}
	}
`;

export const NavSection = styled.nav`
	display: flex;
	margin: 0 -12px;

	& > div {
		display: flex;
	}
`;

export const NavIcon = styled.figure`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: 0;
	background-color: transparent;

	svg {
		width: 24px;
		height: 24px;
		fill: ${({ theme }) => theme.darkGrayColor};
		transition: fill 0.2s ease;
	}
`;

export const Count = styled.span`
	position: absolute;
	right: 3px;
	top: 3px;
	transform: translate3d(50%, -40%, 0);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	min-width: 18px;
	height: 18px;
	padding: 2px;
	color: #fff;
	background-color: ${({ theme }) => theme.primaryColor};
	border-radius: 50%;
	font-size: 10px;
	border: 2px solid #fff;
`;

export const Notice = styled.span`
	position: absolute;
	right: 8px;
	top: 0;
	transform: translate3d(50%, -40%, 0);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: 10px;
	height: 10px;
	background-color: ${({ theme }) => theme.primaryColor};
	border-radius: 50%;
	border: 2px solid #fff;
`;

export const Arrow = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	transform: ${({ $isRotate }) => ($isRotate ? 'rotate(180deg)' : 'rotate(0)')};
	transition: transform 0.2s ease;

	svg {
		width: 8px;
		height: 5px;
		fill: ${({ theme }) => theme.darkGrayColor};
		transition: fill 0.2s ease;
	}
`;

export const ProfileIcon = styled.span`
	margin: 0 12px 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	background-color: ${({ theme }) => theme.primaryColor};
	border-radius: 50%;
	color: #fff;
	text-transform: uppercase;
	transition: background-color 0.2s ease;
	${({ theme }) => theme.typography.textSemiBoldS};
`;

export const ProfileSubmenu = styled.div`
	display: flex;
	flex-direction: column;
	width: max-content;
	min-width: 220px;
	padding: 0;
	background: #fff;
	box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
`;

export const Divider = styled.hr`
	margin: 0;
	border-width: 1px 0 0 0;
	border-style: solid;
	border-color: ${({ theme }) => theme.lightGrayColor};
`;

export const NavButton = styled.button`
	position: relative;
	display: flex;
	gap: 4px;
	align-items: center;
	padding: 0 12px;
	margin: 0;
	box-shadow: none;
	border: 0;
	background-color: transparent;
	cursor: pointer;
	color: ${({ theme, $isActive, $isGray }) =>
		$isActive ? theme.primaryColor : $isGray ? theme.darkGrayColor : theme.primaryBlackColor};
	transition: color 0.2s ease;
	${({ theme }) => theme.typography.textMediumS};

	@media (hover: hover) and (pointer: fine) {
		&:hover,
		&:active,
		&:focus {
			color: ${({ theme, $isGray }) => ($isGray ? theme.primaryBlackColor : theme.primaryColor)};

			${Arrow}, ${NavIcon} {
				svg {
					fill: ${({ theme }) => theme.primaryColor};
				}
			}
		}

		&:focus {
			outline: none;
		}
	}

	@media (hover: none), (pointer: coarse) {
		&:hover,
		&:active {
			color: ${({ theme, $isGray }) => ($isGray ? theme.darkGrayColor : theme.primaryBlackColor)};

			${Arrow}, ${NavIcon} {
				svg {
					fill: ${({ theme }) => theme.darkGrayColor};
				}
			}
		}
	}
`;

export const DropDownButton = styled(NavButton)`
	background: ${({ $isOpen, theme }) => ($isOpen ? theme.secondaryColor : 'transparent')};
	box-shadow: ${({ theme, $isActive, $isProfile }) =>
		$isActive && !$isProfile ? `${theme.primaryColor} 0px -2px 0px inset;` : 'none'};
`;

export const NavLink = styled(Link)`
	display: flex;
	flex-shrink: 0;
	padding: 0 12px;
	gap: 4px;
	align-items: center;
	color: ${({ theme, $isActive, $isGray }) =>
		$isActive ? theme.primaryColor : $isGray ? theme.darkGrayColor : theme.primaryBlackColor};
	text-decoration: none;
	box-shadow: ${({ theme, $isActive, $isProfile }) =>
		$isActive && !$isProfile ? `${theme.primaryColor} 0px -2px 0px inset;` : 'none'};
	${({ theme }) => theme.typography.textMediumS};

	${NavIcon} {
		svg {
			fill: ${({ theme, $isActive }) => ($isActive ? theme.primaryColor : theme.darkGrayColor)};
		}
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover,
		&:active,
		&:focus {
			color: ${({ theme, $isGray }) => ($isGray ? theme.primaryBlackColor : theme.primaryColor)};

			${NavIcon} {
				svg {
					fill: ${({ theme }) => theme.primaryColor};
				}
			}
		}

		&:focus {
			outline: none;
		}
	}

	@media (hover: none), (pointer: coarse) {
		&:hover,
		&:active {
			color: ${({ theme, $isGray }) => ($isGray ? theme.darkGrayColor : theme.primaryBlackColor)};
		}
	}
`;

export const ProfileSection = styled.div`
	position: relative;
	display: flex;

	& > * {
		display: flex;
	}
`;

export const City = styled(NavButton)``;

export const Phone = styled(NavButton)`
	padding: 0;
	align-self: center;
`;

export const ProfileText = styled.span`
	@media (max-width: 1200px) {
		display: none;
	}
`;

export const PopupContentWrapper = styled.div`
	max-width: 480px;
	display: flex;
	flex-direction: column;
`;

export const LikePopover = styled.div`
	width: 288px;
	display: flex;
	flex-direction: column;
	${({ theme }) => theme.typography.textMediumS};
	color: ${({ theme }) => theme.darkGrayColor};
`;

export const TooltipTitle = styled.div`
	color: ${({ theme }) => theme.primaryBlackColor};
	${({ theme }) => theme.typography.textBoldM};
	max-width: 90%;
`;

export const TooltipDescription = styled.div`
	color: ${({ theme }) => theme.darkGrayColor};
	${({ theme }) => theme.typography.textMediumS};
	margin: 8px 0 14px;
`;

export const ProfileButtonWrapper = styled.div`
	display: flex;
	border-left: ${({ theme }) => `1px solid ${theme.middleGrayColor}`};
	border-right: ${({ theme }) => `1px solid ${theme.middleGrayColor}`};
`;
