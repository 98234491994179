/* @flow */

import React, { useCallback, useContext, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { useDispatch, useSelector, useStore } from 'react-redux';
import history from '../../../../history';
import { selectStyles2022 } from '../../../../theme/select';
import { pinMap2023Icon } from '../../../../components/Icons';
import { SkeletonFilterField } from '../../../../components/Skeleton';
import type { TRootState } from '../../../../types/rootState';
import type { TCities, TCity } from '../../../../types/city';
import { CityWrapper, Content, Header, Root, SelectImage, TabsWrapper } from './styles';
import type { TOption } from '../../../../components/Filter/Filter';
import ButtonsRow from '../../../../components/ButtonsRow';
import FilterArea from '../../../../components/FilterArea';
import type { TAppState } from '../../../../reducers/app';
import { afterCityChanged, cityChange } from '../../../../actions/city';
import type { TDispatch } from '../../../../types/core';
import { AppContext } from '../../../../components/AppContext';

// eslint-disable-next-line react/prop-types
const Control = ({ children, ...props }) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<components.Control {...props}>
		<SelectImage>{pinMap2023Icon}</SelectImage>
		{children}
	</components.Control>
);

const AREA_OPTIONS: TOption[] = [
	{
		value: 'area',
		label: 'Районы',
	},
	{
		value: 'metro',
		label: 'Метро',
	},
];

type TProps = {
	modalToggle: () => {},
};

const CityModal = ({ modalToggle }: TProps) => {
	const dispatch: TDispatch = useDispatch();
	const store = useStore();
	const context = useContext(AppContext);
	const app: TAppState = useSelector((state: TRootState) => state.app);
	const city: TCity = useSelector((state: TRootState) => state.city);
	const cities: TCities = useSelector((state: TRootState) => state.cities);

	const { windowSize } = app;
	const { hasMetro } = city;

	const [citiesOptions, setCitiesOptions] = useState([]);
	const [areaTab, setAreaTab] = useState('area');

	const cityDefault = { value: city.link, label: city.title };

	const { location: { pathname = '/' } = {} } = history;

	useEffect(() => {
		setCitiesOptions(
			cities.map((cityItem: TCity) => ({
				value: cityItem.link,
				label: cityItem.title,
				data: cityItem,
			})),
		);
	}, [cities]);

	const handleChangeCity = useCallback((payload) => {
		if (payload.value !== city.link) {
			Promise.all([
				cityChange(payload.data)(dispatch, store, context),
				afterCityChanged(payload.data.cityIds)(dispatch, context),
			]).then(() => {
				const newPathname =
					pathname === '/' ? `/${payload.value}/` : pathname?.replace(/^\/\w+\//i, `/${payload.value}/`);

				history.push(newPathname, {
					showPreloader: false,
					prev: history.location,
					y: window.scrollY,
				});
			});
		}
	});

	const toggleAreaTab = (field, value) => {
		setAreaTab(value);
	};

	return (
		<Root>
			<Header>Выберите ваш город</Header>
			<Content>
				<CityWrapper>
					{cityDefault ? (
						<Select
							id="city"
							isSearchable={false}
							defaultValue={cityDefault}
							options={citiesOptions}
							styles={selectStyles2022}
							placeholder="Например, Москва"
							onChange={handleChangeCity}
							components={{ Control }}
						/>
					) : (
						<SkeletonFilterField />
					)}
				</CityWrapper>
				{hasMetro && (
					<TabsWrapper>
						<ButtonsRow buttons={AREA_OPTIONS} onClick={toggleAreaTab} activeValue={areaTab} field="area" />
					</TabsWrapper>
				)}
				<FilterArea oneColumn={windowSize === 0} mode={areaTab} modalToggle={modalToggle} isHome />
			</Content>
		</Root>
	);
};

export default CityModal;
