import {
	FAIL,
	START,
	SUCCESS,
	DOSIER_FETCH_DOSIERS_DATA,
	DOSIER_SORTING,
	CHANGE_USER_CITY,
	TYPE,
	DIRECTION,
	DOSIER_NEED_UPDATE,
	DOSIER_PAGE,
} from '../constants';
import type { TDosier } from '../types/dosier';

const initialState = {
	dosiersData: {
		items: [],
		meta: {
			pageNumber: 0,
			itemsOnPage: 0,
			itemsTotal: 0,
			pageCount: 0,
		},
	},
	sortType: null,
	sortDirection: 'up',
	isLoading: false,
	isLoaded: false,
	needUpdate: false,
};

const reducer = (state: TDosier = initialState, action) => {
	switch (action.type) {
		case CHANGE_USER_CITY: {
			return initialState;
		}

		case DOSIER_FETCH_DOSIERS_DATA + START:
			return { ...state, isLoading: true, isLoaded: false };

		case DOSIER_FETCH_DOSIERS_DATA + SUCCESS:
			return { ...state, dosiersData: action.payload, isLoading: false, isLoaded: true, needUpdate: false };

		case DOSIER_FETCH_DOSIERS_DATA + FAIL:
			return { ...state, dosiersData: initialState.dosiersData, isLoading: false, isLoaded: false };

		case DOSIER_SORTING + TYPE:
			return { ...state, sortType: action.payload, needUpdate: true };

		case DOSIER_SORTING + DIRECTION:
			return { ...state, sortDirection: action.payload, needUpdate: true };

		case DOSIER_PAGE:
			return { ...state, dosiersData: { meta: { pageNumber: action.payload } } };

		case DOSIER_NEED_UPDATE:
			return { ...state, needUpdate: true };

		default:
			return state;
	}
};

export default reducer;
