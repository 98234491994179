/* @flow */
import React, { type Node } from 'react';
import { Desktop, LastElement, Mobile, Root, StyledLink, BackButton, ArrowIcon } from './styles';
import history from '../../history';
import { backArrow2 } from '../Icons';

type TProps = {
	data: [
		{
			index: number,
			title: string,
			link: string,
		},
	],
	mobileTitle?: string | null,
	handleBack?: () => {} | null,
	color?: string,
	colorLast?: string,
	style?: Node,
};

const Breadcrumbs = ({ data, mobileTitle, handleBack, color, colorLast, style }: TProps) => {
	const link = data && data[data.length - 2]?.link;
	const title = data && data[data.length - 2]?.title;

	const handleBackDefault = () => {
		if (history.location) {
			history.push(link, {
				showPreloader: false,
				prev: history.location,
				y: window.scrollY,
			});
		}
	};

	return (
		<Root $color={color} style={style}>
			<Desktop>
				{data?.map((item, index) => {
					if (index === data.length - 1) {
						return (
							<LastElement key={item.index} $colorLast={colorLast}>
								{item.title}
							</LastElement>
						);
					}
					return (
						<StyledLink key={item.index} to={item.link} id={`breadcrumbsLink_${item.index}`} $color={color} $colorLast={colorLast}>
							{item.title}
						</StyledLink>
					);
				})}
			</Desktop>
			<Mobile>
				<BackButton onClick={handleBack || handleBackDefault} $color={color} type="button" title="Назад">
					<ArrowIcon viewBox="0 0 20 20">{backArrow2}</ArrowIcon>
					{mobileTitle || title}
				</BackButton>
			</Mobile>
		</Root>
	);
};

Breadcrumbs.defaultProps = {
	mobileTitle: null,
	handleBack: null,
	color: null,
	colorLast: null,
	style: undefined,
};

export default Breadcrumbs;
