import styled from 'styled-components';
import Link from '../Link/Link';
import { MEDIA_NO_MOBILE, MEDIA_ON_MOBILE } from '../../constants';

export const Root = styled.div`
	font-size: 14px;
	line-height: 1.43;
	font-weight: 500;
	color: ${({ $color }) => $color || '#000'};
`;

export const StyledLink = styled(Link)`
	color: ${({ $color }) => $color || '#000'};
	text-decoration: none;
	padding-right: 20px;
	position: relative;

	&:hover {
		color: ${({ theme, $colorLast }) => $colorLast || theme.primaryColor};
	}

	&::after {
		content: '/';
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
	}

	&:focus {
		outline: none;
		color: ${({ theme }) => theme.primaryColor};
	}
`;

export const LastElement = styled.span`
	color: ${({ theme, $colorLast }) => $colorLast || theme.darkGrayColor};
`;

export const Desktop = styled.div`
	@media ${MEDIA_ON_MOBILE} {
		display: none;
	}
`;

export const Mobile = styled.div`
	@media ${MEDIA_NO_MOBILE} {
		display: none;
	}
`;

export const BackButton = styled.button`
	display: flex;
	align-items: center;
	padding: 5px 0;
	font-size: 14px;
	line-height: 1.43;
	color: ${({ $color }) => $color || '#333'};
	background-color: transparent;
	border: none;
	box-shadow: none;
`;

export const ArrowIcon = styled.svg`
	margin-right: 6px;
	width: 20px;
	height: 20px;
	fill: currentColor;
`;
