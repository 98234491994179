/* @flow */
import React, { type Node, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { lockScroll, unlockScroll } from '../../DOMUtils';
import ModalDialog from './ModalDialog';
import type { TNavigationButtonProps } from './NavigationButton';
import type { TRootState } from '../../types/rootState';

type TProps = {
	isShowing: boolean,
	timeoutMS?: number,
	children: Node,
	hasNavigation?: boolean,
	navButtons?: TNavigationButtonProps[],
	noCloseButton?: boolean,
	hide: () => any,
	onEntered?: () => any,
	noPortal?: boolean,
	isFeed?: boolean,
	scrollEnabled?: boolean,
	overflowEnabled?: boolean,
	transparent?: boolean,
	fullHeight?: boolean,
	fullWidth?: boolean,
	fixedClose?: boolean,
	withContainer?: boolean,
	closeBtnPositionPx?: number,
};

const Modal = ({
	isShowing,
	hide,
	onEntered,
	timeoutMS,
	children,
	hasNavigation,
	navButtons,
	noPortal,
	isFeed,
	noCloseButton = false,
	scrollEnabled = false,
	overflowEnabled = false,
	transparent = false,
	fullHeight = false,
	fullWidth = false,
	fixedClose = false,
	withContainer = false,
	closeBtnPositionPx,
}: TProps) => {
	const popupOpen: boolean = useSelector((state: TRootState) => state.popup.popupOpen);

	const handleKeyPress = (e) => {
		if (e.key === 'Escape') {
			if (!noCloseButton) hide();
		}
	};

	useEffect(() => {
		if (!scrollEnabled) {
			if (isShowing && popupOpen) {
				lockScroll();
				window.addEventListener('keydown', handleKeyPress);
			} else {
				unlockScroll();
			}
		}
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
			unlockScroll();
		};
	}, [isShowing]);

	const mount = document?.getElementById('modal-portal');

	const Popup = (
		<ModalDialog
			isShowing={isShowing}
			timeoutMS={timeoutMS}
			hasNavigation={hasNavigation}
			navButtons={navButtons}
			hide={hide}
			onEntered={onEntered}
			noPortal={noPortal}
			isFeed={isFeed}
			noCloseButton={noCloseButton}
			overflowEnabled={overflowEnabled}
			transparent={transparent}
			fullHeight={fullHeight}
			fullWidth={fullWidth}
			fixedClose={fixedClose}
			withContainer={withContainer}
			closeBtnPositionPx={closeBtnPositionPx}
		>
			{children}
		</ModalDialog>
	);

	if (isShowing) {
		return noPortal ? Popup : ReactDOM.createPortal(Popup, mount);
	}

	return null;
};

Modal.defaultProps = {
	hasNavigation: false,
	navButtons: [],
	isFeed: false,
	noPortal: false,
	noCloseButton: false,
	scrollEnabled: false,
	overflowEnabled: false,
	transparent: false,
	fullHeight: false,
	fullWidth: false,
	fixedClose: false,
	withContainer: false,
	timeoutMS: 0,
	onEntered: () => {},
	closeBtnPositionPx: 10,
};

export default Modal;
