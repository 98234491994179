/* @flow */
import React, { type Node, useEffect, useState } from 'react';
import {
	AppBtnsWrapper,
	Arrow,
	Brand,
	BrandSection,
	City,
	CommonInfoWrapper,
	Container,
	ContainerWrapper,
	Count,
	DropDownButton,
	LikePopover,
	NavButton,
	NavIcon,
	NavLink,
	NavSection,
	Notice,
	Phone,
	ProfileButtonWrapper,
	ProfileSection,
	ProfileSubmenu,
	TooltipDescription,
	TooltipTitle,
} from '../../styles';
import { arrow2021Icon, bell2021Icon, heart2023Icon, heart2023IconFill, Logo, profileUser } from '../../../Icons';
import { Popover } from '../../../Tooltip/Tooltip';
import { NavMobileLink } from '../MobileHeader/styles';
import Button from '../../../Button2.0';
import CloseButton from '../../../CloseButton';
import { plural } from '../../../../utils';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import MobileAppBtn from '../../../MobileAppBtn';
import { TLink } from '../../Header';
import type { TCity } from '../../../../types/city';
import type { TAppState } from '../../../../reducers/app';
import { usePrevState } from '../../../../utils/hooks';

type TProps = {
	appState: TAppState,
	city: TCity,
	route: string,
	stageRoute: string,
	links: TLink[],
	onCallbackClick: () => {},
	onCityClick: () => {},
	favCount: number,
	unreadEventsCount: number,
	compareCount: number,
	isAuthUser: boolean,
	isAnonUser: boolean,
	setShowNotice: () => {},
	openAuthPopup: () => {},
	goToLikes: () => {},
	logout: () => {},
};

const DesktopHeader = ({
	appState,
	city,
	links,
	favCount,
	compareCount,
	isAnonUser,
	isAuthUser,
	openAuthPopup,
	route,
	stageRoute,
	setShowNotice,
	onCityClick,
	onCallbackClick,
	unreadEventsCount,
	goToLikes,
	logout,
}: TProps): Node => {
	const [showService, setShowService] = useState(false);
	const [showFav, setShowFav] = useState(false);
	const [showProfile, setShowProfile] = useState(false);

	const prevFavCount = usePrevState(favCount);

	const handleService = () => {
		if (showProfile) {
			setShowProfile(false);
		}
		if (showFav) {
			setShowFav(false);
		}

		setShowService(true);
	};

	const handleFav = () => {
		if (showService) {
			setShowService(false);
		}
		if (showProfile) {
			setShowProfile(false);
		}

		setShowFav(true);
	};

	const handleProfile = () => {
		if (showService) {
			setShowService(false);
		}
		if (showFav) {
			setShowFav(false);
		}

		setShowProfile(true);
	};

	const SERVICE_LINKS = [
		{
			link: `/${city.link}/service`,
			title: 'Все услуги',
		},
		{
			link: `/${city.link}/service/podbor-i-pokupka/`,
			title: 'Подбор и покупка',
		},
		{
			link: `/${city.link}/service/bezopasnaya-sdelka/`,
			title: 'Безопасная сделка',
		},
		{
			link: `/${city.link}/service/prodazha-i-pokupka/`,
			title: 'Продажа и покупка',
		},
		{
			link: `/${city.link}/service/prodazha/`,
			title: 'Продажа',
		},
	];

	useEffect(() => {
		if ((prevFavCount <= 0 && favCount === 1) || (route === 'apart' && prevFavCount < favCount)) {
			handleFav(true);
		}
	}, [favCount]);

	useEffect(() => {
		if (showService) {
			setShowService(false);
		}
		if (showProfile) {
			setShowProfile(false);
		}
		if (showFav) {
			setShowFav(false);
		}
	}, [route, stageRoute]);

	return (
		<ContainerWrapper>
			<Container role="navigation">
				<BrandSection>
					<Brand
						to={!city || city.link === 'spb' ? '/' : `/${city.link}/`}
						id="headerLinkLogo"
						data-qa="header_logo"
						aria-label="Переход на главную"
					>
						<svg viewBox="0 0 315 68">{Logo}</svg>
					</Brand>
				</BrandSection>
				<NavSection>
					{links.map((link: TLink) => (
						<NavLink
							key={Array.isArray(link.route) ? link.route[0] : link.route}
							to={link.link}
							$isActive={Array.isArray(link.route) ? link.route.includes(route) : link.route === route}
							id={`headerLink_${Array.isArray(link.route) ? link.route[0] : link.route}`}
							data-qa={`header_${Array.isArray(link.route) ? link.route[0] : link.route}`}
							aria-label={`Перейти в ${link.title}`}
						>
							{link.title}
						</NavLink>
					))}
					<div id="service-menu">
						<Popover
							theme="clean"
							placement="bottom-start"
							visible={showService}
							onClickOutside={() => setShowService(false)}
							onHidden={() => setShowService(false)}
							offset={[0, 1]}
							content={
								<ProfileSubmenu>
									{SERVICE_LINKS.map((item) => (
										<NavMobileLink to={item.link} key={item.link}>
											{item.title}
										</NavMobileLink>
									))}
								</ProfileSubmenu>
							}
						>
							<DropDownButton
								title="Услуги"
								$isActive={route === 'service' || route === 'services-detail'}
								$isOpen={showService}
								onClick={() => handleService(!showService)}
							>
								Услуги
								<Arrow $isRotate={showService}>
									<svg viewBox="0 0 11 7">{arrow2021Icon}</svg>
								</Arrow>
							</DropDownButton>
						</Popover>
					</div>
				</NavSection>
			</Container>
			<CommonInfoWrapper>
				<Phone onClick={onCallbackClick} aria-label="Заказать обратный звонок">
					8&nbsp;(800)&nbsp;777-40-91
				</Phone>
				{appState.windowSize > 2 && (
					<ProfileButtonWrapper>
						<City
							onClick={onCityClick}
							title="Выбор города"
							id="headerButtonCity"
							data-qa="header_city"
							aria-label="Выбор города"
						>
							{city.title}
						</City>
					</ProfileButtonWrapper>
				)}
				<ProfileSection>
					<Popover
						theme="white"
						placement="bottom-start"
						visible={showFav}
						onClickOutside={() => setShowFav(false)}
						onHidden={() => setShowFav(false)}
						content={
							<LikePopover>
								<TooltipTitle>Вам понравилась квартира</TooltipTitle>
								<TooltipDescription>
									Все понравившиеся квартиры находятся во вкладке «Избранное»
								</TooltipDescription>
								<Button
									onClick={goToLikes}
									color="secondary"
									size="xs"
									style={{ width: '50%', height: '52px' }}
								>
									Перейти
								</Button>
								<CloseButton size={10} right={12} top={12} onClick={() => setShowFav(false)} />
							</LikePopover>
						}
						arrow
					>
						<div id="likesLink">
							<NavLink
								to={`/${city.link}/selection/likes/`}
								$isActive={route === 'likes'}
								title="Избранное"
								id="headerLinkFav"
								data-qa="header_favs"
								aria-label="Перейти в Избранное"
								style={{ padding: '0 6px' }}
								$isProfile
							>
								<NavIcon>
									<svg viewBox="0 1 24 20">
										{route === 'likes' ? heart2023IconFill : heart2023Icon}
									</svg>
									{!!favCount && <Count>{favCount > 99 ? '99+' : favCount}</Count>}
								</NavIcon>
							</NavLink>
						</div>
					</Popover>
					<NavButton
						onClick={() => setShowNotice(true)}
						title={
							unreadEventsCount
								? `${unreadEventsCount} ${plural(unreadEventsCount, [
										'непрочитанное уведомление',
										'непрочитанных уведомления',
										'непрочитанных уведомлений',
								  ])}`
								: 'У вас нет непрочитанных уведомлений'
						}
						id="headerLinkFeed"
						data-qa="header_notify"
						aria-label="Открыть уведомления"
						style={{ padding: '0 6px' }}
					>
						<NavIcon>
							<svg>{bell2021Icon}</svg>
							{!!unreadEventsCount && <Notice />}
						</NavIcon>
					</NavButton>
				</ProfileSection>
				<ProfileButtonWrapper id="profile-menu">
					<Popover
						theme="clean"
						placement="bottom-start"
						visible={showProfile}
						onClickOutside={() => setShowProfile(false)}
						onHidden={() => setShowProfile(false)}
						offset={[0, 1]}
						content={
							<ProfileSubmenu>
								<ProfileMenu
									city={city}
									compareCount={compareCount}
									favCount={favCount}
									isAnonUser={isAnonUser}
									isAuthUser={isAuthUser}
									onCityClick={onCityClick}
									openAuthPopup={openAuthPopup}
									isReverse
									withDivider
									logout={logout}
								/>
							</ProfileSubmenu>
						}
					>
						<DropDownButton
							title="Профиль"
							$isActive={route === 'profile-main'}
							$isOpen={showProfile}
							$isProfile
							onClick={() => handleProfile(!showProfile)}
						>
							<NavIcon>
								<svg viewBox="0 0 19 22">{profileUser}</svg>
							</NavIcon>
							Профиль
							<Arrow $isRotate={showProfile}>
								<svg viewBox="0 0 11 7">{arrow2021Icon}</svg>
							</Arrow>
						</DropDownButton>
					</Popover>
				</ProfileButtonWrapper>
				<AppBtnsWrapper>
					<MobileAppBtn
						store="android"
						squareIcon
						width={32}
						height={32}
						viewBox="-8 -6 32 32"
						padding="0"
						key={Math.random()}
						themeColor="darkBlueColor"
					/>
					<MobileAppBtn
						store="ios"
						squareIcon
						width={32}
						height={32}
						viewBox="0 0 32 32"
						padding="0"
						key={Math.random()}
						themeColor="darkBlueColor"
					/>
				</AppBtnsWrapper>
			</CommonInfoWrapper>
		</ContainerWrapper>
	);
};

export default DesktopHeader;
