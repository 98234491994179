/* @flow */
import React from 'react';
import type { Node } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Clearfix, Label50, Select, SelectWrapper } from '../styles';
import { genDates, times } from '../helpers';
import { SET_ACTION_CALLBACK_POPUP } from '../../../constants/popup';
import type { TDispatch } from '../../../types/core';
import type { TDayItem } from '../../../types/popup';

const dates = [...genDates(7)];

export const Options = ({ date, disabled }: { date: TDayItem, disabled: boolean }): Node => (
	<option key={date.value} value={date.value} disabled={disabled}>
		{date.label}
	</option>
);

const NOW = new Date();
const disableToday = NOW.getHours() >= 18;
// const disableTime = NOW.getHours() > 9 && NOW.getHours() < 20;

const OptionsInputs = (): Node => {
	const dispatch: TDispatch = useDispatch();

	const day: string = useSelector((state) => state.popup.actionCallbackPopup.day);
	const time: string = useSelector((state) => state.popup.actionCallbackPopup.time);

	/**
	 * Проверка параметра day - выбран ли день просмотра  "Сегодня"  или нет
	 * @param someDay
	 * @returns {boolean}
	 */
	const checkNowViewingDay = (someDay: string): boolean => {
		return new Date(someDay).getUTCDate() === NOW.getUTCDate();
	};

	const handleOptionChange = (title) => (e) => {
		dispatch({
			type: SET_ACTION_CALLBACK_POPUP,
			payload: {
				[title]: e.target.value,
				...(title === 'day' && new Date(day).getUTCDate() > NOW.getUTCDate() ? { time: times[0].value } : {}),
			},
		});
	};

	return (
		<Clearfix>
			<Label50 htmlFor="date">
				Выберите день просмотра
				<SelectWrapper>
					<Select id="date" name="message[date]" value={day} onChange={handleOptionChange('day')}>
						{dates.map((date, index) => (
							<Options key={index.toString()} date={date} disabled={index === 0 && disableToday} />
						))}
					</Select>
				</SelectWrapper>
			</Label50>
			<Label50 htmlFor="time">
				Удобное время
				<SelectWrapper>
					<Select id="time" name="message[time]" value={time} onChange={handleOptionChange('time')}>
						{times.map((item, index) => (
							<Options
								key={index.toString()}
								date={item}
								disabled={NOW.getHours() >= item.endTime && checkNowViewingDay(day)}
							/>
						))}
					</Select>
				</SelectWrapper>
			</Label50>
		</Clearfix>
	);
};

export default OptionsInputs;
