/* @flow */
import { TwitterIcon, TelegramIcon, WhatsappIcon, ViberIcon, OKIcon, VKIcon } from 'react-share';
import React from 'react';
import { FacebookSquareIcon, YoutubeSquareIcon } from './icons';

// import s from './SocialList.scss';
import { Facebook } from '../Icons';
import {
	Item,
	StyledVKShareButton,
	StyledFacebookShareButton,
	ShareButtonLabel,
	StyledOKShareButton,
	StyledTwitterShareButton,
	Root,
	ShareLink,
	StyledTelegramShareButton,
	StyledWhatsAppShareButton,
	StyledViberShareButton,
} from './styles';

type TSocialItem = {
	id: number,
	type: string,
	link: string,
};

type TProps = {
	data: TSocialItem[],
	url?: string,
	links?: boolean,
	mode: string,
	footer?: boolean,
	seoShareEvent?: () => any,
};

const SocialList = ({ data, url, links = false, mode = 'black', footer = false, seoShareEvent }: TProps) => {
	const seoEvent = (type) => () => seoShareEvent(type);

	const twitterIconBgStyle = { fill: '#fff' };

	const fbIcon = footer ? (
		<svg viewBox="0 0 24 24">{FacebookSquareIcon}</svg>
	) : (
		<svg viewBox="0 0 1792 1792">{Facebook}</svg>
	);

	const buttons = {
		vk: links ? (
			<VKIcon />
		) : (
			<StyledVKShareButton url={url} beforeOnClick={seoEvent('vKontakte')}>
				<VKIcon round />
				{mode === 'color' && <ShareButtonLabel>Вконтакте</ShareButtonLabel>}
			</StyledVKShareButton>
		),
		ok: links ? (
			<OKIcon />
		) : (
			<StyledOKShareButton url={url} beforeOnClick={seoEvent('Одноклассники')}>
				<OKIcon round />
				{mode === 'color' && <ShareButtonLabel>Одноклассники</ShareButtonLabel>}
			</StyledOKShareButton>
		),
		telegram: links ? (
			<TelegramIcon />
		) : (
			<StyledTelegramShareButton url={url} beforeOnClick={seoEvent('Telegram')}>
				<TelegramIcon round />
				{mode === 'color' && <ShareButtonLabel>Telegram</ShareButtonLabel>}
			</StyledTelegramShareButton>
		),
		whatsapp: (
			<StyledWhatsAppShareButton url={url} beforeOnClick={seoEvent('WhatsApp')}>
				<WhatsappIcon round />
				{mode === 'color' && <ShareButtonLabel>WhatsApp</ShareButtonLabel>}
			</StyledWhatsAppShareButton>
		),
		viber: (
			<StyledViberShareButton url={url} beforeOnClick={seoEvent('Viber')}>
				<ViberIcon round />
				{mode === 'color' && <ShareButtonLabel>Viber</ShareButtonLabel>}
			</StyledViberShareButton>
		),
		/* facebook: links ? (
			fbIcon
		) : (
			<StyledFacebookShareButton url={url} beforeOnClick={seoEvent('Facebook')}>
				<svg viewBox={mode === 'black' ? '0 0 1792 1792' : '0 0 30 30'}>
					{mode === 'black' ? Facebook : FacebookColor}
				</svg>
				{mode === 'color' && <ShareButtonLabel>Facebook</ShareButtonLabel>}
			</StyledFacebookShareButton>
		),
		twitter: (
			<StyledTwitterShareButton url={url} beforeOnClick={seoEvent('Twitter')}>
				{mode === 'black' ? (
					<TwitterIcon size={40} logoFillColor="#000" iconBgStyle={twitterIconBgStyle} />
				) : (
					<svg viewBox="0 0 48 48">{TwitterColor}</svg>
				)}
				{mode === 'color' && <ShareButtonLabel>Twitter</ShareButtonLabel>}
			</StyledTwitterShareButton>
		), */
	};

	return (
		<Root footer={footer} colorMode={mode}>
			{footer && (
				<Item colorMode={mode} footer={footer}>
					<ShareLink
						href="https://www.youtube.com/channel/UCySFzEFo1x3JK3HgACtu3VQ"
						target="_blank"
						rel="noopener noreferrer"
						id="footerLinkYouTube"
						colorMode={mode}
						footer={footer}
					>
						<svg>{YoutubeSquareIcon}</svg>
					</ShareLink>
				</Item>
			)}
			{data &&
				data.map((item) => (
					<Item key={item.id} colorMode={mode} footer={footer}>
						{links ? (
							<ShareLink
								href={item.link}
								target={footer ? '_blank' : '_self'}
								rel="noopener noreferrer"
								id={`footerLink_${item.type}`}
								colorMode={mode}
								footer={footer}
							>
								{buttons[item.type]}
							</ShareLink>
						) : (
							<ShareLink as="div" colorMode={mode} footer={footer}>
								{buttons[item.type]}
							</ShareLink>
						)}
					</Item>
				))}
			{footer && (
				<Item colorMode={mode} footer={footer} style={{ display: 'none' }}>
					<a href="//www.liveinternet.ru/click" target="_blank" rel="noopener noreferrer">
						<img
							src="//counter.yadro.ru/logo?26.6"
							title="LiveInternet: показано число посетителей за сегодня"
							alt=""
							border="0"
							width="88"
							height="15"
						/>
					</a>
				</Item>
			)}
		</Root>
	);
};

SocialList.defaultProps = {
	url: undefined,
	links: false,
	footer: false,
	seoShareEvent: () => {},
};

export default SocialList;
