/* @flow */
import type { Node } from 'react';
import React from 'react';
import { FetchingBody, FetchingDescription, Title } from '../styles';

type TProps = {
	title: string,
	description: string,
	loader: Node,
};

export const Reserving = ({ title, description, loader }: TProps): Node => (
	<FetchingBody>
		{loader && loader}
		{title && <Title>{title}</Title>}
		{description && <FetchingDescription>{description}</FetchingDescription>}
	</FetchingBody>
);
