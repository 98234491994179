import { SET_CURRENT_CITY } from '../constants';

const INITIAL_STATE = null;

export default function city(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_CURRENT_CITY:
			return action.payload;

		default:
			return state;
	}
}
