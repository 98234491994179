import styled from 'styled-components';

export const Root = styled.div`
	display: flex;
	width: 100%;
	position: relative;
	z-index: ${({ $isOpen }) => ($isOpen ? 1000 : 100)};
`;

export const Button = styled.button`
	position: relative;
	z-index: 100;
	display: grid;
	grid-template-columns: 1fr auto;
	padding: 0 16px;
	margin: 0;
	width: 100%;
	background: transparent;
	box-shadow: none;
	border: ${({ $isOpen, theme }) => ($isOpen ? `2px solid ${theme.primaryColor}` : '2px solid transparent')};
	text-align: left;
	align-items: center;
	grid-gap: 12px;
	cursor: pointer;
	transition: border-color 0.3s;
	color: ${({ theme }) => theme.primaryBlackColor};
	${({ theme }) => theme.typography.textBoldM};

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	svg {
		width: 16px;
		height: 16px;
		fill: ${({ theme }) => theme.darkGrayColor};
		transform: ${({ $isOpen }) => ($isOpen ? 'rotate(90deg)' : 'rotate(-90deg)')};
		transition: all 0.3s;
	}

	@media (min-width: 768px) {
		white-space: normal;
	}

	@media (max-width: 480px) {
		padding: 0 10px;
	}
`;

export const List = styled.div`
	position: absolute;
	z-index: 1003;
	display: flex;
	flex-direction: column;
	top: calc(100% + 6px);
	right: 0;
	min-width: 100%;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0 3px 20.7px 0 rgba(50, 50, 50, 0.2);
	overflow: hidden;
	max-height: ${({ $isOpen }) => ($isOpen ? '9999px' : 0)};
	box-sizing: border-box;
	transition: all 0.3s;

	@media (max-width: 767px) {
		position: fixed;
		top: unset;
		bottom: ${({ $isOpen }) => ($isOpen ? 0 : '-100%')};
		left: 0;
		width: 100%;
		max-width: 480px;
		margin: 0 auto;
		padding: 0 16px 32px;
		border-radius: 0;
	}
`;

export const ListHeader = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 10px;
	align-items: center;
	padding: 0 0 0 32px;
	margin: 0 -16px;
	height: 50px;
	border-bottom: ${({ theme }) => `1px solid ${theme.middleGrayColor}`};
	color: ${({ theme }) => theme.darkGrayColor};
	box-sizing: border-box;
	${({ theme }) => theme.typography.textM};

	@media (min-width: 768px) {
		display: none;
	}
`;

export const ListItem = styled.button`
	display: grid;
	grid-template-columns: 1fr 20px;
	grid-gap: 10px;
	align-items: center;
	padding: 0 12px;
	height: 32px;
	background: transparent;
	border: none;
	box-shadow: none;
	text-align: left;
	overflow: hidden;
	transition: all 0.3s;
	white-space: nowrap;
	color: ${({ theme }) => theme.primaryBlackColor};
	${({ theme }) => theme.typography.textM};

	@media (hover: hover) {
		&:hover {
			background: ${({ theme }) => theme.secondaryHover};
		}
	}

	@media (max-width: 767px) {
		padding: 0 16px;
		height: 44px;

		& + & {
			border-top: ${({ theme }) => `1px solid ${theme.middleGrayColor}`};
		}
	}

	svg {
		width: 20px;
		height: 20px;
		fill: ${({ theme }) => theme.primaryColor};
	}
`;

export const Overflow = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1002;

	@media (max-width: 767px) {
		opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
		background: rgba(0, 0, 0, 0.5);
		transition: all 0.3s;
	}
`;

export const CloseButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 16px;
	height: 100%;
	background: transparent;
	box-shadow: none;
	border: none;

	svg {
		flex-shrink: 0;
		width: 16px;
		height: 16px;
		fill: ${({ theme }) => theme.darkGrayColor};
	}
`;
