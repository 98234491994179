/* @flow */
// Lira
export const CHANGE_LIRA_NOTICE_TYPE = 'CHANGE_LIRA_NOTICE_TYPE';
export const CHANGE_LIRA_NOTICES = 'CHANGE_LIRA_NOTICES';
export const SET_LIRA_STEP = 'SET_LIRA_STEP';
export const CHANGE_LIRA_STEP = 'CHANGE_LIRA_STEP';
export const CHANGE_LIRA_USER_FILTER = 'CHANGE_LIRA_USER_FILTER';
export const CHANGE_LIRA_VARIANT_COMPLEX_TAB = 'CHANGE_LIRA_VARIANT_COMPLEX_TAB';
export const CHANGE_LIRA_VARIANT_TAB = 'CHANGE_LIRA_VARIANT_TAB';
export const CLEAR_FINALIZATION = 'CLEAR_FINALIZATION';
export const CLEAR_LIRA = 'CLEAR_LIRA';
export const EXIT_LIRA_NOTE_MODE = 'EXIT_LIRA_NOTE_MODE';
export const LOAD_LIRA_APART_DATA = 'LOAD_LIRA_APART_DATA';
export const LOAD_LIRA_AREA_LIST = 'LOAD_LIRA_AREA_LIST';
export const LOAD_LIRA_COMPLEX_DATA = 'LOAD_LIRA_COMPLEX_DATA';
export const LOAD_LIRA_DEFAULT_FILTER_STATS = 'LOAD_LIRA_DEFAULT_FILTER_STATS';
export const LOAD_LIRA_FILTER_STATS = 'LOAD_LIRA_FILTER_STATS';
export const LOAD_LIRA_MAP_STATS = 'LOAD_LIRA_MAP_STATS';
export const LOAD_LIRA_FINALIZATION = 'LOAD_LIRA_FINALIZATION';
export const LOAD_LIRA_NEXT_VARIANT = 'LOAD_LIRA_NEXT_VARIANT';
export const SET_LIRA_NOTE_MODE = 'SET_LIRA_NOTE_MODE';
export const ADD_LIRA_USER_POLYGON = 'ADD_LIRA_USER_POLYGON';
export const SET_LIRA_NOTICE_MODE = 'SET_LIRA_NOTICE_MODE';
export const SET_LIRA_ANIMATION_PROGRESS = 'SET_LIRA_ANIMATION_PROGRESS';
export const SET_IGNORE_USER_FILTER = 'SET_IGNORE_USER_FILTER';
export const SET_LIRA_FIRST_ENTRY_STATUS = 'SET_LIRA_FIRST_ENTRY_STATUS';
export const LIRA_CHANGE_STATISTICS = 'LIRA_CHANGE_STATISTICS';
export const LIRA_CHANGE_USER_SUBSCRIBE = 'LIRA_CHANGE_USER_SUBSCRIBE';

export const ADD_LIRA_USER_POLYGONS = 'ADD_LIRA_USER_POLYGONS';
export const SET_LIRA_MAP_EDITOR = 'SET_LIRA_MAP_EDITOR';

// Context Lira
export const ADD_LIRA_NOTICES = 'ADD_LIRA_NOTICES';
export const CHANGE_CONTEXT_LIRA_NOTICES = 'CHANGE_CONTEXT_LIRA_NOTICES';
export const GET_APARTS_BY_NOTICES = 'GET_APARTS_BY_NOTICES';
export const SET_SHOW_LIRA = 'SET_SHOW_LIRA';
export const SET_GLOBAL_SHOW_LIRA = 'SET_GLOBAL_SHOW_LIRA';
export const SET_LIRA_SCREEN = 'SET_LIRA_SCREEN';
export const SET_LIRA_LEXS_VARIANT = 'SET_LIRA_LEXS_VARIANT';
export const SET_LIRA_MAIN_FILTER_VISIBILITY = 'SET_LIRA_MAIN_FILTER_VISIBILITY';
export const SET_LIRA_SHOW_MOBILE = 'SET_LIRA_SHOW_MOBILE';
// Apart
export const GET_LIRA_APART_STORIES = 'GET_LIRA_APART_STORIES';
export const GET_LIRA_APART_CONCLUSION = 'GET_LIRA_APART_CONCLUSION';
// Complex
export const GET_LIRA_COMPLEX_STORIES = 'GET_LIRA_COMPLEX_STORIES';
export const GET_LIRA_COMPLEX_CONCLUSION = 'GET_LIRA_COMPLEX_CONCLUSION';
export const SET_LIRA_COMPLEX_ACTIVE_STORY = 'SET_LIRA_COMPLEX_ACTIVE_STORY';
export const SET_LIRA_COMPLEX_BOOKING_FORM = 'SET_LIRA_COMPLEX_BOOKING_FORM';
export const CLEAR_LIRA_COMPLEX_BOOKING_FORM = 'CLEAR_LIRA_COMPLEX_BOOKING_FORM';
export const POST_LIRA_COMPLEX_BOOKING_PREVIEW = 'POST_LIRA_COMPLEX_BOOKING_PREVIEW';
//
export const GET_LIRA_APART_ALTERNATIVES = 'GET_LIRA_APART_ALTERNATIVES';
export const SET_LIRA_INFO_TYPE = 'SET_LIRA_INFO_TYPE';
export const SET_LIRA_FROM_PAGE = 'SET_LIRA_FROM_PAGE';
export const SET_LIRA_ACTIVE_STORY = 'SET_LIRA_ACTIVE_STORY';
export const SET_LIRA_ACTIVE_MESSAGE = 'SET_LIRA_ACTIVE_MESSAGE';
export const SET_LIRA_ALTERNATIVES_SCREEN = 'SET_LIRA_ALTERNATIVES_SCREEN';
// export const SET_LIRA_PROACTIVE_BLOCK = 'SET_LIRA_PROACTIVE_BLOCK';
export const SET_LIRA_NOTICES_STATUS = 'SET_LIRA_NOTICES_STATUS';
export const SET_LIRA_PREVIEW_STEP = 'SET_LIRA_PREVIEW_STEP';
export const SET_LIRA_PREVIEW_STATUS = 'SET_LIRA_PREVIEW_STATUS';
export const SET_LIRA_PREVIEW_FORM = 'SET_LIRA_PREVIEW_FORM';
export const POST_LIRA_PREVIEW_DATA = 'POST_LIRA_PREVIEW_DATA';
export const SET_LIRA_ONLINE_PREVIEW_FORM = 'SET_LIRA_ONLINE_PREVIEW_FORM';
export const POST_LIRA_ONLINE_PREVIEW_DATA = 'POST_LIRA_ONLINE_PREVIEW_DATA';
export const SET_LIRA_BOOKING_FORM = 'SET_LIRA_BOOKING_FORM';
export const CLEAR_LIRA_BOOKING_FORM = 'CLEAR_LIRA_BOOKING_FORM';
export const POST_LIRA_BOOKING_DATA = 'POST_LIRA_BOOKING_DATA';
export const SET_LIRA_CALLBACK_PREVIEW_FORM = 'SET_LIRA_CALLBACK_PREVIEW_FORM';
export const POST_LIRA_CALLBACK_FORM = 'POST_LIRA_CALLBACK_FORM';
export const SET_LIRA_TITLE = 'SET_LIRA_TITLE';
export const SET_LIRA_HISTORY = 'SET_LIRA_HISTORY';
export const ADD_LIRA_HISTORY = 'ADD_LIRA_HISTORY';
export const REMOVE_LIRA_HISTORY = 'REMOVE_LIRA_HISTORY';
export const SET_LIRA_APART_CHANGE_DECISION = 'SET_LIRA_APART_CHANGE_DECISION';
export const SET_LIRA_CALLBACK_FORM = 'SET_LIRA_CALLBACK_FORM';
export const FETCH_LIRA_CALLBACK_REQUEST = 'FETCH_LIRA_CALLBACK_REQUEST';
export const SET_LIRA_LOYALTY_POPUP = 'SET_LIRA_LOYALTY_POPUP';
export const FETCH_LIRA_LOYALTY_REQUEST = 'FETCH_LIRA_LOYALTY_REQUEST';
export const HIDE_LIRA_MENU = 'HIDE_LIRA_MENU';

// Filter Constants
export const LIRA_APART_FILTER_TITLE = 'Параметры';
export const LIRA_APART_FILTER_LABEL_APART_PRICE = 'Цена квартиры';
export const LIRA_APART_FILTER_LABEL_ROOMS_COUNT = 'Комнатность';
export const LIRA_APART_FILTER_LABEL_DEADLINE = 'Срок сдачи, до';
export const LIRA_APART_FILTER_LABEL_PLACE = 'Расположение';
export const LIRA_APART_FILTER_BUTTON_PICKUP = 'Подобрать';

/*
 * LIRA 3.0
 */

export const LIRA_GET_INITIAL_DATA = 'LIRA_GET_INITIAL_DATA';
export const LIRA_GET_CITY_AREAS = 'LIRA_GET_CITY_AREAS';
export const LIRA_GET_CITY_METRO = 'LIRA_GET_CITY_METRO';
export const LIRA_GET_NEXT_APART = 'LIRA_GET_NEXT_APART';
export const LIRA_GET_APARTS_LIST = 'LIRA_GET_APARTS_LIST';
export const LIRA_GET_LIKED_APARTS_LIST_FULL = 'LIRA_GET_LIKED_APARTS_LIST_FULL';
export const LIRA_GET_APART_INFRASTRUCTURE = 'LIRA_GET_APART_INFRASTRUCTURE';
export const LIRA_GET_APART_MAP_DATA = 'LIRA_GET_APART_MAP_DATA';
export const LIRA_UPDATE_APARTMENT_OPINION = 'LIRA_UPDATE_APARTMENT_OPINION';
export const LIRA_UPDATE_APARTMENT_RATING = 'LIRA_UPDATE_APARTMENT_RATING';
export const LIRA_SET_SELECTED_APART = 'LIRA_SET_SELECTED_APART';

export const LIRA_CREATE_USER_PROFILE = 'LIRA_CREATE_USER_PROFILE';
export const LIRA_GET_USER_PROFILE = 'LIRA_GET_USER_PROFILE';
export const LIRA_CHANGE_USER_PROFILE = 'LIRA_CHANGE_USER_PROFILE';
export const LIRA_DELETE_USER_PROFILE = 'LIRA_DELETE_USER_PROFILE';
export const LIRA_CHANGE_FILTERS = 'LIRA_CHANGE_FILTERS';
export const LIRA_CHANGE_FILTERS_FIELD = 'LIRA_CHANGE_FILTERS_FIELD';

export const LIRA_GET_TEMPLATES = 'LIRA_GET_TEMPLATES';
export const LIRA_GET_WISHES = 'LIRA_GET_WISHES';
export const LIRA_SET_STAGE = 'LIRA_SET_STAGE';
export const LIRA_RESET_WISHES = 'LIRA_RESET_WISHES';
export const LIRA_SET_WISHES = 'LIRA_SET_WISHES';
export const LIRA_UPDATE_WISHES = 'LIRA_UPDATE_WISHES';
export const LIRA_GET_STORIES = 'LIRA_GET_STORIES';
export const LIRA_SET_STORY_WATCHED = 'LIRA_SET_STORY_WATCHED';
export const LIRA_STORIES_UPDATE = 'LIRA_STORIES_UPDATE';
export const LIRA_SET_SELECTION_TYPE = 'LIRA_SET_SELECTION_TYPE';
export const LIRA_SET_SELECTION_PARAMS = 'LIRA_SET_SELECTION_PARAMS';
export const LIRA_NEED_NEXT_VARIANT = 'LIRA_NEED_NEXT_VARIANT';

export const LIRA_SET_POPUP = 'LIRA_SET_POPUP';
export const LIRA_SET_POPUP_TYPE = 'LIRA_SET_POPUP_TYPE';

export const LIRA_LIKES_SORTING = 'LIRA_LIKES_SORTING';
export const LIRA_DISLIKES_SORTING = 'LIRA_DISLIKES_SORTING';

export const LIRA_LIKES_VIEW = 'LIRA_LIKES_VIEW';
export const LIRA_DISLIKES_VIEW = 'LIRA_DISLIKES_VIEW';

export const LIRA_SET_INCLUDE_SOLD = 'LIRA_SET_INCLUDE_SOLD';

export const LIRA_SET_SAVE_SIDEBAR = 'LIRA_SET_SAVE_SIDEBAR';
export const LIRA_SET_CANCEL_SIDEBAR = 'LIRA_SET_CANCEL_SIDEBAR';
export const LIRA_SET_SIDEBAR_SCREEN = 'LIRA_SET_SIDEBAR_SCREEN';
export const LIRA_SET_POPUP_TEXTS = 'LIRA_SET_POPUP_TEXTS';
export const LIRA_SET_CALLBACK_POPUP_TEXTS = 'LIRA_SET_CALLBACK_POPUP_TEXTS';
export const LIRA_SET_CALLBACK_POPUP_ADVERT_TEXTS = 'LIRA_SET_CALLBACK_POPUP_ADVERT_TEXTS';
export const LIRA_SET_LIVING_BANNER_TEXTS = 'LIRA_SET_LIVING_BANNER_TEXTS';
export const LIRA_SET_PARAMS = 'LIRA_SET_PARAMS';
export const LIRA_SET_MORTGAGE_PARAMS = 'LIRA_SET_MORTGAGE_PARAMS';
export const LIRA_SET_TEMP_PARAMS = 'LIRA_SET_TEMP_PARAMS';
export const LIRA_SET_TEMP_MORTGAGE_PARAMS = 'LIRA_SET_TEMP_MORTGAGE_PARAMS';

export const LIRA_UPDATE_SELECTION_AFTER_VOTE = 'LIRA_UPDATE_SELECTION_AFTER_VOTE';

export const LIRA_LIKES_NEED_UPDATE = 'LIRA_LIKES_NEED_UPDATE';

export const LIRA_SORTING_LIKES = [
	{
		id: 0,
		title: 'По дате добавления',
		value: null,
	},
	{
		id: 1,
		title: 'Дешевле',
		value: 'PRICE_ASC',
	},
	{
		id: 2,
		title: 'Дороже',
		value: 'PRICE_DESC',
	},
	{
		id: 3,
		title: 'Больше подходят',
		value: 'RELEVANCE_DESC',
	},
	{
		id: 4,
		title: 'Меньше подходят',
		value: 'RELEVANCE_ASC',
	},
];

export const LIRA_SORTING_DISLIKES = [
	{
		id: 0,
		title: 'Без сортировки',
		value: null,
	},
	{
		id: 1,
		title: 'Дешевле',
		value: 'PRICE_ASC',
	},
	{
		id: 2,
		title: 'Дороже',
		value: 'PRICE_DESC',
	},
	{
		id: 3,
		title: 'Больше подходят',
		value: 'RELEVANCE_DESC',
	},
	{
		id: 4,
		title: 'Меньше подходят',
		value: 'RELEVANCE_ASC',
	},
];

// Messages

export const LIRA_TITLE = 'Виртуальный помощник';
export const LIRA_TAGLINE = 'На основе искусственного интеллекта.';
export const LIRA_VISITED_START = 'Вы уже смотрели эту квартиру';
export const LIRA_VISITED_NOT_MARKED = ', но ещё не оценили её.';
export const LIRA_VISITED_NOT_MARKED_FAV = ' и даже добавили ее в избранное, но ещё не оценили её.';
export const LIRA_VISITED_LIKE = ', и она вам понравилась.';
export const LIRA_VISITED_DISLIKE = ', и она вам не понравилась.';
export const LIRA_VISITED_NOTICES = ' и оставили замечания:';
export const LIRA_VISITED_MARKED = 'Вы уже смотрели и оценили эту квартиру.';
export const LIRA_APART_TITLE_QUESTION = 'Как вам эта квартира?';
export const LIRA_SHOW_MORE = 'Я подберу ещё варианты в соответствии с вашей оценкой.';
export const LIRA_CALLBACK_TITLE =
	'Самое время посмотреть вживую или, если остались вопросы, обратитесь к специалисту Living.';
export const LIRA_SEARCH_ALL_COMPLEXES = 'Я могу подобрать подходящий вариант среди всех Жилых комплексов.';
export const LIRA_SUCCESS_TITLE = 'Отлично!';
export const LIRA_ADD_TO_FAV = 'Я добавила квартиру в избранное, чтобы она всегда была под рукой';
export const LIRA_SAVE_MARK = 'Я запомнила Вашу оценку по этой квартире и учту это при дальнейших подборах.';
export const LIRA_NOTICES_TITLE = 'Какие у вас замечания?';
export const LIRA_GET_MORE = 'Я подберу ещё варианты в соответствии с ними.';
export const LIRA_GET_ALTERNATIVE = 'Я могу подобрать альтернативные варианты с учетом Ваших замечаний';
export const LIRA_USER_NOTICES_TITLE = 'Ваши замечания:';
export const LIRA_USER_NOTICES_MESSAGE = 'Вы оставили замечания:';
export const LIRA_TOOK_NOTICES_TITLE = 'Я учла ваши замечания';
export const LIRA_LOOK_ALTERNATIVE_MESSAGE = 'Посмотрите квартиры неподалеку, которые вам подходят.';
export const LIRA_LOOK_ALTERNATIVE_NOT_FOUND = 'Подходящих квартир не найдено.';
export const LIRA_LETS_COMPARE = 'Давайте сравним их.';
export const LIRA_APART_MINICARD_LABEL_CLOSEST = 'Рядом с этим ЖК';
export const LIRA_APART_MINICARD_LABEL_SAME = 'В этом ЖК';
export const LIRA_COMPLEX_MAIN_PARAMS_TITLE = 'Давайте определимся с основными параметрами.';
export const LIRA_FOUND_APARTS_TITLE = 'Я нашла для Вас лучшие варианты в этом ЖК.';
export const LIRA_LETS_FAMILIAR = 'Ознакомьтесь с ними поближе.';
export const LIRA_LEXS_FOUND_APARTS = 'Я подобрала вам квартиры';
export const LIRA_LEXS_PARAMS =
	'Отметьте, что вам наиболее важно и я расставлю все квартиры в порядке ваших приоритетов';
export const LIRA_HAS_OPINION = 'Я не поменяла своего мнения.';
export const LIRA_CONCLUSION_TITLE = 'Моё заключение';

// Buttons

export const LIRA_BUTTON_SIGNUP = 'Записаться на просмотр';
export const LIRA_BUTTON_CALLBACK = 'Консультация эксперта Living';
export const LIRA_BUTTON_SEARCH = 'Перейти в поиск';
export const LIRA_BUTTON_LIKE = 'Нравится';
export const LIRA_BUTTON_DISLIKE = 'Совершенно не нравится';
export const LIRA_BUTTON_NOTICES = 'Неплохо, но есть замечания';
export const LIRA_BUTTON_APPLY = 'Подтвердить';
export const LIRA_BUTTON_NO_THANKS = 'Нет, спасибо.';
export const LIRA_BUTTON_LETS_CONTINUE = 'Продолжим';
export const LIRA_BUTTON_CONTINUE = 'Продолжить';
export const LIRA_BUTTON_CHANGED = 'Передумали?';
export const LIRA_BUTTON_WANT_CHANGED = 'Хочу изменить';
export const LIRA_BUTTON_DO_IT = 'Да, подбери';
export const LIRA_BUTTON_LOADING = 'Загрузка...';
export const LIRA_BUTTON_COMPARE = 'Сравнить';
export const LIRA_BUTTON_BACK = 'Назад';
export const LIRA_BUTTON_CLOSE = 'Закрыть';
export const LIRA_BUTTON_SHOW_VARIANT = 'Смотреть предложение';
export const LIRA_BUTTON_RESET = 'Сбросить';
export const LIRA_BUTTON_ALTERNATIVES = 'Посмотреть альтернативы';

// request type constants from CallbackPopup
export const TYPE_COMPLEX_VIEW = 1;
export const TYPE_COMPLEX_MORTGAGE = 2;
export const TYPE_APARTMENT_VIEW = 3;
export const TYPE_APARTMENT_QUEUE = 4;
export const TYPE_APARTMENT_RESERVE = 5;
export const TYPE_APARTMENT_MORTGAGE = 6;
export const TYPE_APARTMENT_RESERVE_TRY = 7;

// booking response statuses
export const BOOKING_STATUS_UNDEFINED = '';
export const BOOKING_STATUS_AVAILABLE = 'Available';
export const BOOKING_STATUS_RESERVED = 'Reserved';
export const BOOKING_STATUS_ERROR = 'Error';

// Remarks Menu List
export const LIRA_REMARKS_MENU_APARTMENT = 'Квартира';
export const LIRA_REMARKS_MENU_HOUSE = 'Дом';
export const LIRA_REMARKS_MENU_INFRA = 'Инфаструктура';

// TODO: Refactor this APART and COMPLEX has same booking statuses?
// booking COMPLEX response statuses
export const BOOKING_COMPLEX_STATUS_UNDEFINED = '';
export const BOOKING_COMPLEX_STATUS_AVAILABLE = 'Available';
export const BOOKING_COMPLEX_STATUS_RESERVED = 'Reserved';
export const BOOKING_COMPLEX_STATUS_ERROR = 'Error';

// TODO: Refactor this APART and COMPLEX has same types reserve?
// request type constants from CallbackPopup
export const TYPE_COMPLEX_RESERVE = 1;

// CALLBACK_FORM_STATUSES
export const CALLBACK_FORM_RESPONSE_STATUS_UNDEFINED = '';
export const CALLBACK_FORM_RESPONSE_STATUS_SUCCESS = 'Success';
export const CALLBACK_FORM_RESPONSE_STATUS_ERROR = 'Error';

// Apartment
export const GET_APARTMENT_SELECTION_INFO = 'GET_APARTMENT_SELECTION_INFO';

// LIRA 4.0
export const NEW_LIRA_CREATE_USER_PROFILE = 'NEW_LIRA_CREATE_USER_PROFILE';
export const NEW_LIRA_GET_USER_PROFILE = 'NEW_LIRA_GET_USER_PROFILE';
export const NEW_LIRA_CHANGE_USER_PROFILE = 'NEW_LIRA_CHANGE_USER_PROFILE';

export const LIRA_SET_USER_PORTRAIT = 'LIRA_SET_USER_PORTRAIT';
export const LIRA_SET_USER_SUBSCRIBE = 'LIRA_SET_USER_SUBSCRIBE';
export const LIRA_CHANGE_USER_STATUS = 'LIRA_CHANGE_USER_STATUS';
export const LIRA_CHANGE_SETTINGS = 'LIRA_CHANGE_SETTINGS';

export const SET_LIRA_VIEW = 'SET_LIRA_VIEW';
export const LIRA_CHANGE_SORTING = 'LIRA_CHANGE_SORTING';
export const LIRA_GET_PORTRAITS = 'LIRA_GET_PORTRAITS';
export const LIRA_GET_WISH_GROUP = 'LIRA_GET_WISH_GROUP';
export const FETCH_LIRA_SELECTION_DATA = 'FETCH_LIRA_SELECTION_DATA';
export const FETCH_LIRA_SELECTION_MAP_DATA = 'FETCH_LIRA_SELECTION_MAP_DATA';
export const LIRA_SET_USER_WISHES = 'LIRA_SET_USER_WISHES';
export const LIRA_SELECTION_RELOAD_PAGE = 'LIRA_SELECTION_RELOAD_PAGE';
export const LIRA_SELECTION_CHANGE_PAGE = 'LIRA_SELECTION_CHANGE_PAGE';
export const LIRA_SELECTION_INCLUDE_SOLD = 'LIRA_SELECTION_INCLUDE_SOLD';
export const LIRA_FILTER_RESET = 'LIRA_FILTER_RESET';
export const LIRA_SHOW_MESSAGE = 'LIRA_SHOW_MESSAGE';

export const LIRA_SORTING_SELECTION = [
	{
		id: 0,
		label: 'По дате добавления',
		value: 'SUGGESTION_DATE_DESC',
	},
	{
		id: 1,
		label: 'Дешевле',
		value: 'PRICE_ASC',
	},
	{
		id: 2,
		label: 'Дороже',
		value: 'PRICE_DESC',
	},
	{
		id: 3,
		label: 'Больше подходят',
		value: 'RELEVANCE_DESC',
	},
	{
		id: 4,
		label: 'Меньше подходят',
		value: 'RELEVANCE_ASC',
	},
];

export const LIRA_SELECTION_LINK = `lira/selection`;

export const SUGGESTION_TYPE = {
	PRICE: 'Немного дороже',
	LOCATION: 'Другой район',
	DEADLINE: 'Сдаётся позже',
	ROOMS_COUNT: 'Больше комнат',
};
