import React from 'react';
import { useDispatch } from 'react-redux';

import { Container } from '../../components/Layout/styles';
import { Description, Picture, Root, SearchButton, SearchTitle } from './styles';
import image404 from '../../../public/images/errors/404.png';
import image404a2x from '../../../public/images/errors/404@2x.png';
import image404a3x from '../../../public/images/errors/404@3x.png';
import image404Mobile from '../../../public/images/errors/404Mobile.png';
import image404Mobile2x from '../../../public/images/errors/404Mobile@2x.png';
import image404Mobile3x from '../../../public/images/errors/404Mobile@3x.png';
import { search2021Icon } from '../../components/Icons';
import { Icon } from '../../components/Search/styles';
import { SHOW_SEARCH_POPUP } from '../../constants';
import type { TDispatch } from '../../types/core';

const NotFound = () => {
	const dispatch: TDispatch = useDispatch();

	const showSearchPopup = (action) => () => {
		dispatch({ type: SHOW_SEARCH_POPUP, payload: action });
	};

	return (
		<Root>
			<Container>
				<Picture>
					<source
						srcSet={`${image404Mobile}, ${image404Mobile2x} 2x, ${image404Mobile3x} 3x`}
						media="(max-width: 600px)"
					/>
					<source srcSet={`${image404}, ${image404a2x} 2x, ${image404a3x} 3x`} media="(min-width: 601px)" />
					<img src={image404} alt="404 ошибка" />
				</Picture>
				<Description>Такой страницы у нас нет</Description>
				<SearchTitle>Но есть другие, попробуйте поискать</SearchTitle>
				<SearchButton onClick={showSearchPopup(true)} role="button">
					Введите свой запрос
					<Icon viewBox="0 0 20 21">{search2021Icon}</Icon>
				</SearchButton>
			</Container>
		</Root>
	);
};

export default NotFound;
