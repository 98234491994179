import React from 'react';
import { StyledTippy } from './styles';

export const Tooltip = (props) => (
	<StyledTippy role="tooltip" arrow offset={[0, 16]} delay={[50, 0]} theme="white" zIndex={9998} {...props} />
);

export const Popover = (props) => (
	<StyledTippy interactive arrow={false} offset={[0, 16]} delay={[50, 0]} maxWidth="420px" {...props} />
);
