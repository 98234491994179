export default [
	{
		type: 'vk',
		link: 'https://vk.com/livingru',
		id: 1,
	},
	/*{
		type: 'ok',
		link: 'https://ok.ru/group/61617301487694',
		id: 2,
	},*/
	{
		type: 'telegram',
		link: 'https://t.me/livingApp',
		id: 3,
	},
	/*{
		type: 'facebook',
		link: 'https://www.facebook.com/livingspb/',
		id: 2,
	},*/
];
