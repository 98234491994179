export const FETCH_SELECTION_DATA = 'FETCH_SELECTION_DATA';
export const SELECTION_SET_HOVERED_COMPLEX = 'SELECTION_SET_HOVERED_COMPLEX';
export const SELECTION_SET_ACTIVE_SECTION = 'SELECTION_SET_ACTIVE_SECTION';
export const SELECTION_CHANGE_SAMPLING = 'SELECTION_CHANGE_SAMPLING';
export const SELECTION_CHANGE_PAGE = 'SELECTION_CHANGE_PAGE';
export const SELECTION_RELOAD_PAGE = 'SELECTION_RELOAD_PAGE';
export const SET_LIKE_DISLIKE_STATUS = 'SET_LIKE_DISLIKE_STATUS';
export const SET_DISLIKE_MENU_OPEN = 'SET_DISLIKE_MENU_OPEN';
export const SET_SELECTION_VIEW = 'SET_SELECTION_VIEW';
export const SET_SHOW_SORTING_MODAL = 'SET_SHOW_SORTING_MODAL';

export const selectionConstsTitle = {
	MAIN: 'По вашим параметрам',
	LOCATION: 'В других районах',
	ROOMS_COUNT: 'С иным количеством комнат',
	MORTGAGE_PRICE: 'Более дорогие варианты',
	DEADLINE: 'Более поздний срок сдачи',
	PRICE: 'Более дорогие варианты',
};

export const SET_START_SCREEN_PASSED = 'SET_START_SCREEN_PASSED';
export const SET_FILTER_SCREEN_PASSED = 'SET_FILTER_SCREEN_PASSED';

export const SOURCE_ADVERT = {
	AVITO: 'Авито',
	CIAN: 'Циан',
	NMARKET: 'Застройщик',
};
