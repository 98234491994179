/* eslint-disable import/prefer-default-export */

import { LOAD_CITIES, PREPARE_CHANGE_CITY, SET_CURRENT_CITY, SUCCESS } from '../constants';
import type { TDispatch } from '../types/core';
import type { TCities, TCity } from '../types/city';
import { TCityIds } from '../types/city';
import { setUserCity } from './user';
import { getCityConfig, getClearedDomain, setCookie } from '../apiUtils';
import { changeUserProfile, getLiraInitialData } from './lira';
import { getCity } from '../utils';

export const checkIsCity = (citySlug, cities) => !!cities.find((city) => city.link === citySlug);

export function setCurrentCity(city) {
	return {
		type: SET_CURRENT_CITY,
		payload: city,
	};
}

export function setCities(cities) {
	return {
		type: `${LOAD_CITIES}${SUCCESS}`,
		payload: cities,
	};
}

export function setCityCookie(citySlug: string, cityIds: Array<number>, force: boolean): void {
	if (force || typeof window !== 'undefined') {
		const domain = getClearedDomain(window.location.hostname);

		setCookie('living_city', citySlug, {
			path: '/',
			expires: 604800, // 1 неделя
			domain,
		});

		setCookie('living_city_ids', JSON.stringify(cityIds), {
			path: '/',
			expires: 604800, // 1 неделя
			domain,
		});
	}
}

export function setCity({ params, store, fetch }: { params: { city?: string } }): TCity {
	const { city, cities, user } = store.getState();

	const checkTheCity = () => {
		if (params?.city && checkIsCity(params.city, cities)) {
			return params.city;
		}
		if (user?.citySlug) {
			return user.citySlug;
		}
		if (city?.link) {
			return city.link;
		}
		return 'spb';
	};

	const targetCity: ?string = checkTheCity();
	const currentCity: ?TCity = getCity(targetCity, cities);

	if (city?.link !== targetCity) {
		store.dispatch(setCurrentCity(currentCity));
		setCityCookie(currentCity.link, currentCity.cityIds);
	}

	return currentCity;
}

export const cityChange =
	(city: TCity) =>
	async (dispatch: TDispatch, store, { fetch }): any => {
		dispatch(setCurrentCity(city));
		dispatch(
			setUserCity({
				citySlug: city.link,
				cityIds: city.cityIds,
			}),
		);
		setCityCookie(city.link, city.cityIds);
		getCityConfig(city.cityIds, { store, fetch }).then();
	};

export const afterCityChanged =
	(cityIds: TCityIds = null) =>
	async (dispatch: TDispatch, context): any => {
		await Promise.all([
			dispatch({ type: PREPARE_CHANGE_CITY }),
			changeUserProfile({ areaIds: [], metroIds: [], polygonList: [], cityIds })(
				dispatch,
				context.store.getState,
				context,
			),
		]).then();
		await getLiraInitialData()(dispatch, context.store.getState, context);
	};

/*
Вывод заголовка города/региона по КЛАДР
 */
export const getCityRegionTitleById = (
	kladr: number,
	cities: TCities,
	mode: 'genitive' | 'prepositional' | 'normal' = 'normal',
) => {
	if (cities?.length > 0) {
		const currentRegion: TCity = cities.find((city) => city.cityIds.includes(kladr));

		if (currentRegion.kladr === kladr) {
			switch (mode) {
				case 'genitive':
					return currentRegion.genitive;
				case 'prepositional':
					return currentRegion.prepositional;
				default:
					return currentRegion.title;
			}
		}

		if (currentRegion?.region?.kladr === kladr) {
			switch (mode) {
				case 'genitive':
					return currentRegion.region.genitive;
				case 'prepositional':
					return currentRegion.region.prepositional;
				default:
					return currentRegion.region.title;
			}
		}
	}

	return null;
};
