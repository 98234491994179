/* @flow */
import React, { type Node, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { BtnClose, ModalContent, ModalRoot, NavPanel } from './styles';
import type { TNavigationButtonProps } from './NavigationButton';
import NavigationButton from './NavigationButton';

const timeout = {
	enter: 100,
	exit: 150,
};

type TProps = {
	isShowing: boolean,
	timeoutMS: number,
	children: Node,
	hasNavigation?: boolean,
	navButtons?: TNavigationButtonProps[],
	hide: () => any,
	onEntered: () => {},
	isFeed?: boolean,
	noCloseButton?: boolean,
	noPortal?: boolean,
	overflowEnabled?: boolean,
	transparent?: boolean,
	fullHeight?: boolean,
	fullWidth?: boolean,
	fixedClose?: boolean,
	withContainer?: boolean,
	closeBtnPositionPx?: number,
};

const ModalDialog = ({
	isShowing,
	hide,
	onEntered,
	timeoutMS,
	children,
	hasNavigation = false,
	navButtons = [],
	isFeed,
	noCloseButton = false,
	noPortal = false,
	overflowEnabled = false,
	transparent = false,
	fullHeight = false,
	fullWidth = false,
	fixedClose = false,
	withContainer = false,
	closeBtnPositionPx,
}: TProps): Node => {
	const modalRef = useRef(null);
	const [clickedLayer, setClickedLayer] = useState('');

	const handleClickOutside = (event) => {
		if (!noCloseButton) {
			const layer = event.target.getAttribute('data-layer');
			if (layer === 'outside') {
				setClickedLayer('');
				hide();
			}
		}
	};

	useEffect(() => {
		if (modalRef?.current) {
			modalRef.current.focus();
		}
	}, []);

	return (
		<Transition in={Boolean(isShowing)} timeout={timeoutMS || timeout} unmountOnExit onEntered={onEntered}>
			{(status) => (
				<ModalRoot
					noPortal={noPortal}
					transition={status}
					onClick={handleClickOutside}
					data-layer="outside"
					ref={modalRef}
					tabIndex={0}
				>
					<ModalContent
						transition={status}
						$isFeed={isFeed}
						$overflowEnabled={overflowEnabled}
						$transparent={transparent}
						$fullHeight={isFeed || fullHeight}
						$fullWidth={fullWidth}
						$withContainer={withContainer}
					>
						{hasNavigation && !!navButtons.length && (
							<NavPanel>
								{navButtons.map((button: TNavigationButtonProps) => (
									<NavigationButton key={Math.random()} {...button} />
								))}
							</NavPanel>
						)}
						{!noCloseButton && (
							<BtnClose type="button" onClick={hide} $fixed={fixedClose} $positionPx={closeBtnPositionPx}>
								<span aria-hidden="true">&times;</span>
							</BtnClose>
						)}
						{children}
					</ModalContent>
				</ModalRoot>
			)}
		</Transition>
	);
};

ModalDialog.defaultProps = {
	hasNavigation: false,
	navButtons: [],
	isFeed: false,
	noCloseButton: false,
	noPortal: false,
	overflowEnabled: false,
	transparent: false,
	fullHeight: false,
	fullWidth: false,
	fixedClose: false,
	withContainer: false,
	closeBtnPositionPx: 10,
};

export default ModalDialog;
