/* @flow */
import type { TAction } from '../types/core';
import {
	GET_SELECTION_MAP_DATA,
	BLOCK_SCREEN_MAP,
	CHANGE_FILTER_MAP,
	SET_BOUNDS,
	START,
	SUCCESS,
	FAIL,
	LIRA_CHANGE_USER_PROFILE,
	SELECTION_CHANGE_SAMPLING,
	PREPARE_CHANGE_CITY,
	SET_UPDATE_MAP_SELECTION,
	SET_SOFT_UPDATE,
	LIRA_UPDATE_WISHES,
} from '../constants';

import type { TBounds, TMapData } from '../types/selectionMap';

export type TSelectionMapState = {
	data: TMapData[],
	isLoading: boolean,
	isLoaded: boolean,
	blockScreen: boolean,
	filterChanged: boolean,
	needUpdate: boolean,
	softUpdate: boolean,
	bounds: TBounds,
};

const initialState: TSelectionMapState = {
	data: [],
	isLoading: false,
	isLoaded: false,
	blockScreen: false,
	filterChanged: false, // подумать куда перенести, тк в profile есть такое поле, но это вызывает лишние запросы и тп
	needUpdate: true,
	softUpdate: false,
	bounds: [],
};

const selectionMap = (state: TSelectionMapState = initialState, action: TAction): TSelectionMapState => {
	const { type, payload } = action;

	switch (type) {
		case GET_SELECTION_MAP_DATA + START:
			return { ...state, ...payload };

		case GET_SELECTION_MAP_DATA + SUCCESS:
			return {
				...state,
				...payload,
				isLoading: false,
				isLoaded: true,
				needUpdate: false,
				blockScreen: false,
			};

		case GET_SELECTION_MAP_DATA + FAIL:
			return { ...state, isLoading: false, isLoaded: false };

		case BLOCK_SCREEN_MAP:
			return { ...state, blockScreen: payload };

		case CHANGE_FILTER_MAP:
			return { ...state, filterChanged: payload };

		case PREPARE_CHANGE_CITY:
			return { ...state, data: initialState.data, needUpdate: true };

		case LIRA_CHANGE_USER_PROFILE + SUCCESS:
		case SELECTION_CHANGE_SAMPLING:
		case SET_UPDATE_MAP_SELECTION:
			return { ...state, needUpdate: true };

		case LIRA_UPDATE_WISHES:
			return { ...state, softUpdate: true };

		case SET_SOFT_UPDATE:
			return { ...state, softUpdate: payload };

		case SET_BOUNDS:
			return { ...state, bounds: payload, blockScreen: false };

		default:
			return state;
	}
};

export { initialState as selectionMapInitialState };

export default selectionMap;
