/* @flow */

import { getTokenStatus, getValidToken } from './apiUtils';
import { SET_TOKEN } from './constants';

export const checkCurrentToken =
	(token, tokenExpires, isAnonymous) =>
	async ({ fetch, store }) => {
		const { dispatch } = store;

		try {
			const isValid = token ? await getTokenStatus(token, fetch) : false;

			if (!isValid) {
				const { result, data } = await getValidToken(token, null, fetch);
				if (result === 'ok') {
					dispatch({ type: SET_TOKEN, payload: { ...data } });
					return { ...data };
				}
				return {};
			}
			dispatch({ type: SET_TOKEN, payload: { token, tokenExpires, isAnonymous } });
			return { token, tokenExpires, isAnonymous };
		} catch (error) {
			return {};
		}
	};
