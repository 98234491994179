/* @flow */

import type { Node } from 'react';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AreaChip, AreaWrapper, CityButton, Fields, FieldWrapper, InputLabel, SwitcherLabel } from '../../styles';
import FakeSelect from '../FakeSelect';
import { TYPE_OPTIONS } from '../../../../components/Filter/constants';
import { ROOMS_OPTIONS } from '../../contstants';
import PriceSelect from '../PriceSelect';
import SwitcherCheckbox from '../../../../components/SwitcherCheckbox';
import InputSlider from '../../../../components/UniversalFilter/InputSlider';
import type { TFilterBase, TFilterGlobal } from '../../../../types/filters';
import { TRealityType } from '../../../../types/filters';
import { LIRA_SET_MORTGAGE_PARAMS, SET_BASE_FILTER, SET_GLOBAL_SELECTION_MODE } from '../../../../constants';
import type { TInputProps } from '../../../../components/UniversalFilter/InputSlider/InputSlider';
import type { TOption } from '../../../../components/Filter/Filter';
import { TDispatch } from '../../../../types/core';
import type { TAppState } from '../../../../reducers/app';
import type { TRootState } from '../../../../types/rootState';
import type { TCity } from '../../../../types/city';

type TProps = {
	toggle: () => {},
};

const FieldsComponent = ({ toggle }: TProps): Node => {
	const dispatch: TDispatch = useDispatch();
	const app: TAppState = useSelector((state: TRootState) => state.app);
	const city: TCity = useSelector((state: TRootState) => state.city);
	const filterBase: TFilterBase = useSelector((state: TRootState) => state.filterBase);
	const filterGlobal: TFilterGlobal = useSelector((state: TRootState) => state.filterGlobal);

	const { isMobile, windowSize } = app;
	const {
		realtyType,
		roomsCntForFilter,
		priceTotalMinGte,
		priceTotalMinLte,
		areaIds,
		metroIds,
		mortgageInfo: { initialFee, monthlyPayment },
	} = filterBase;
	const { selectionMode } = filterGlobal;
	const isMortgage = selectionMode === 'mortgage';

	const [currentSelect, setCurrentSelect] = useState(null);

	const handleSelect = useCallback((mode: 'type' | 'rooms' | 'price' | null) => () => {
		if (currentSelect === mode) {
			setCurrentSelect(null);
		}
		setCurrentSelect(mode);
	});

	const handleTypeClick = useCallback((type: TRealityType) => () => {
		dispatch({ type: SET_BASE_FILTER, payload: { realtyType: type } });
	});

	const handleRoomsClick = useCallback((value: number) => () => {
		const data =
			roomsCntForFilter?.length > 0 && roomsCntForFilter.includes(value)
				? roomsCntForFilter.filter((item) => item !== value)
				: [...roomsCntForFilter, value];
		dispatch({
			type: SET_BASE_FILTER,
			payload: { roomsCntForFilter: data },
		});
	});

	const roomsNaming = () => {
		if (roomsCntForFilter.length === 0 || roomsCntForFilter.length === 5) {
			return 'Любая комн.';
		}

		let tempString = '';
		roomsCntForFilter
			.sort((a, b) => a - b)
			.map((item, idx) => {
				if (item === 0) {
					tempString = `Студия`;
				} else {
					tempString = `${tempString}${item}`;
				}
				if (roomsCntForFilter[idx + 1]) {
					tempString = `${tempString}, `;
				}
				return null;
			});
		if (roomsCntForFilter[0] !== 0) {
			return `${tempString} комн.`;
		}

		return tempString;
	};

	const handleChange = useCallback((payload: TInputProps) => {
		const { value, field } = payload;

		if (value !== undefined) {
			dispatch({
				type: field === 'initialFee' || field === 'monthlyPayment' ? LIRA_SET_MORTGAGE_PARAMS : SET_BASE_FILTER,
				payload: { [field]: value },
			});
		}
	});

	const handleSelectionModeToggle = (e) => {
		const isChecked = e?.target?.checked;
		dispatch({ type: SET_GLOBAL_SELECTION_MODE, payload: isChecked ? 'mortgage' : 'normal' });
	};

	const typeName = () => {
		if (realtyType === 'ALL') {
			return 'Новостройки и вторичка';
		}

		return TYPE_OPTIONS.find((item: TOption) => item.value === realtyType).label;
	};

	const priceTitle =
		priceTotalMinLte && priceTotalMinGte
			? `${Number((priceTotalMinGte / 1000000).toFixed(1)).toString()} — ${Number(
					(priceTotalMinLte / 1000000).toFixed(1),
			  ).toString()} млн. ₽`
			: 'Цена';

	const mortgageTitle =
		initialFee && monthlyPayment
			? `${Number((monthlyPayment / 1000).toFixed(1)).toString()} тыс., ${Number(
					(initialFee / 1000000).toFixed(1),
			  )} млн. ₽`
			: 'Платеж и взнос';

	return (
		<Fields>
			<FieldWrapper style={{ gridArea: 'city' }}>
				<CityButton onClick={() => toggle()}>
					<span>{city.title}</span>
					<AreaWrapper>
						<AreaChip>{areaIds.length > 0 ? `Районы: ${areaIds.length}` : 'Районы'}</AreaChip>
						{city.hasMetro && (
							<AreaChip>{metroIds.length > 0 ? `Метро: ${metroIds.length}` : 'Метро'}</AreaChip>
						)}
					</AreaWrapper>
				</CityButton>
			</FieldWrapper>
			<FieldWrapper style={{ gridArea: 'type' }}>
				<FakeSelect
					options={TYPE_OPTIONS}
					currentValue={realtyType}
					title={typeName()}
					mobileTitle="Тип жилья"
					onOptionClick={handleTypeClick}
					mode="type"
					isOpen={currentSelect === 'type'}
					setOpen={handleSelect}
					noWrap={isMobile}
				/>
			</FieldWrapper>
			<FieldWrapper style={{ gridArea: 'rooms' }}>
				<FakeSelect
					options={ROOMS_OPTIONS}
					currentValue={roomsCntForFilter}
					multiselect
					title={roomsNaming()}
					mobileTitle="Количество комнат"
					onOptionClick={handleRoomsClick}
					mode="rooms"
					isOpen={currentSelect === 'rooms'}
					setOpen={handleSelect}
					noWrap={isMobile}
				/>
			</FieldWrapper>
			{windowSize > 0 ? (
				<FieldWrapper style={{ gridArea: 'price' }}>
					<PriceSelect
						title={isMortgage ? mortgageTitle : priceTitle}
						valueMin={priceTotalMinGte}
						valueMax={priceTotalMinLte}
						initialFee={initialFee}
						monthlyPayment={monthlyPayment}
						onChange={handleChange}
						isOpen={currentSelect === 'price'}
						setOpen={handleSelect}
						handleSelectionModeToggle={handleSelectionModeToggle}
						isMortgage={isMortgage}
					/>
				</FieldWrapper>
			) : (
				<>
					<FieldWrapper style={{ gridArea: 'checkbox', padding: '0 10px' }}>
						<SwitcherLabel>
							По ежемесячному платежу
							<SwitcherCheckbox checked={isMortgage} onChange={handleSelectionModeToggle} />
						</SwitcherLabel>
					</FieldWrapper>
					{isMortgage ? (
						<>
							<InputLabel style={{ gridArea: 'priceFrom' }}>
								Ежемесячный платеж
								<FieldWrapper>
									<InputSlider
										value={monthlyPayment}
										name="monthlyPayment"
										prefix="до"
										suffix={`\u20BD`}
										handleInputChange={handleChange}
										inputStyle={{ border: 'none', height: '42px' }}
									/>
								</FieldWrapper>
							</InputLabel>
							<InputLabel style={{ gridArea: 'priceTo' }}>
								Первоначальный взнос
								<FieldWrapper>
									<InputSlider
										value={initialFee}
										name="initialFee"
										suffix={`\u20BD`}
										handleInputChange={handleChange}
										inputStyle={{ border: 'none', height: '42px' }}
									/>
								</FieldWrapper>
							</InputLabel>
						</>
					) : (
						<>
							<FieldWrapper style={{ gridArea: 'priceFrom' }}>
								<InputSlider
									value={priceTotalMinGte}
									name="priceTotalMinGte"
									prefix="от"
									suffix={`\u20BD`}
									handleInputChange={handleChange}
									inputStyle={{ border: 'none', height: '42px' }}
								/>
							</FieldWrapper>
							<FieldWrapper style={{ gridArea: 'priceTo' }}>
								<InputSlider
									value={priceTotalMinLte}
									name="priceTotalMinLte"
									prefix="до"
									suffix={`\u20BD`}
									handleInputChange={handleChange}
									inputStyle={{ border: 'none', height: '42px' }}
								/>
							</FieldWrapper>
						</>
					)}
				</>
			)}
		</Fields>
	);
};

export default FieldsComponent;
