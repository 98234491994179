/* @flow */

import type { TFilterBase } from '../types/filters';
import type { TAction } from '../types/core';
import {
	LIRA_CHANGE_USER_PROFILE,
	LIRA_GET_USER_PROFILE,
	LIRA_SET_MORTGAGE_PARAMS,
	PREPARE_CHANGE_CITY,
	RESET_ALL_FILTERS,
	RESET_BASE_FILTER,
	SET_BASE_FILTER,
	SET_CURRENT_CITY,
	SET_UNIVERSAL_LEXS_FILTER,
	SUCCESS,
} from '../constants';
import {
	INITIAL_FEE_DEFAULT,
	PAYMENT_DEFAULT,
	PRICE_TOTAL_MIN_GTE_DEFAULT,
	PRICE_TOTAL_MIN_LTE_DEFAULT,
} from '../data/filter/price';

const initialState: TFilterBase = {
	deadlineLte: 300001,
	priceTotalMinGte: PRICE_TOTAL_MIN_GTE_DEFAULT,
	priceTotalMinLte: PRICE_TOTAL_MIN_LTE_DEFAULT,
	roomsCntForFilter: [1, 2],
	polygonList: [],
	page: 0,
	areaIds: [],
	metroIds: [],
	areaForFilter: 0,
	realtyType: 'ALL', // NEW | RESALE | ALL - Первичная, вторичная недвижимость или вся. По умолчанию ALL
	isApartHotel: null,
	mortgageInfo: {
		initialFee: INITIAL_FEE_DEFAULT, // первоначальный взнос, обязательный параметр, целое число
		monthlyPayment: PAYMENT_DEFAULT, // ежемесячный платеж, обязательный параметр, целое число
		// loanPeriod: LOAN_PERIOD_DEFAULT, // срок кредитования в годах (от 1 до 30), обязательный параметр
		loanerInfo: {
			age: 35,
			sex: 'MALE',
			hasChildren: false,
			worksInIT: false,
		},
	},
};

const filterBase = (state: TFilterBase = initialState, action: TAction): TFilterBase => {
	const { type, payload } = action;

	switch (type) {
		case SET_BASE_FILTER:
			return { ...state, ...payload };

		case LIRA_SET_MORTGAGE_PARAMS:
			return { ...state, mortgageInfo: { ...state.mortgageInfo, ...payload } };

		case LIRA_CHANGE_USER_PROFILE + SUCCESS:
		case LIRA_GET_USER_PROFILE + SUCCESS: {
			const { separated: { base = {} } = {} } = payload;
			const mortgageInfo = base.mortgageInfo === null ? initialState.mortgageInfo : base.mortgageInfo;
			return { ...state, ...base, mortgageInfo };
		}

		case RESET_ALL_FILTERS:
		case RESET_BASE_FILTER:
			return initialState;

		case PREPARE_CHANGE_CITY:
			return {
				...state,
				priceTotalMinGte: initialState.priceTotalMinGte,
				priceTotalMinLte: initialState.priceTotalMinLte,
				areaIds: initialState.areaIds,
				metroIds: initialState.metroIds,
				polygonList: initialState.polygonList,
				mortgageInfo: {
					...state.mortgageInfo,
					initialFee: initialState.mortgageInfo.initialFee,
					monthlyPayment: initialState.mortgageInfo.monthlyPayment,
				},
			};

		case SET_CURRENT_CITY:
			return {
				...state,
				areaIds: initialState.areaIds,
				metroIds: initialState.metroIds,
			};

		case SET_UNIVERSAL_LEXS_FILTER: {
			const { filterGroup, data } = payload;
			if (filterGroup === 'baseParams') {
				return { ...state, ...data };
			}
			return state;
		}

		default:
			return state;
	}
};

export { initialState as baseFilterInitialState };
export default filterBase;
