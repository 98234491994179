import { SET_COMPLEX_FILTER_AVAILABLE, SUCCESS, FETCH_COMPLEX_FULL_DATA, SET_COMPLEX_BOUNDS } from '../constants';
import { TComplex } from '../types/complex';

const initialState = null;

export default function complex(state: TComplex = initialState, action) {
	switch (action.type) {
		case SET_COMPLEX_FILTER_AVAILABLE + SUCCESS:
			return {
				...state,
				availableFilters: {
					...action.payload,
				},
			};

		case FETCH_COMPLEX_FULL_DATA + SUCCESS:
			return { ...state, ...action.payload };

		case SET_COMPLEX_BOUNDS:
			return { ...state, bounds: action.payload };

		default:
			return state;
	}
}
