import isEmpty from 'lodash/isEmpty';
import { error as failNotification } from 'react-notification-system-redux';
import { ADD_TO_COMPARE, DELETE_FROM_COMPARE, FAIL, LOAD_COMPARE, START, SUCCESS } from '../constants';
import { addJavaHeaders } from '../utils';
import { checkStatus, parseJSON } from '../apiUtils';
import { notificationOpts } from '../data/general/notificationsConfig';

export const getCompare =
	(callback = null) =>
	async (dispatch, getState, { fetch }) => {
		const { token } = getState().token;

		dispatch({
			type: LOAD_COMPARE + START,
		});

		const compareUrl = '/lexsApi/cabinet/comparison/list';

		try {
			const { result, data } = await fetch(compareUrl, {
				method: 'GET',
				...addJavaHeaders(token),
			}).then((res) => res.json());
			if (result === 'ok') {
				dispatch({
					type: `${LOAD_COMPARE}${SUCCESS}`,
					payload: data,
				});
				if (callback) {
					callback();
				}
				return data;
			}
			dispatch({
				type: `${LOAD_COMPARE}${FAIL}`,
			});
			return null;
		} catch (error) {
			console.error('---', 'fetch error reason', error);

			dispatch({
				type: `${LOAD_COMPARE}${FAIL}`,
			});
			return null;
		}
	};

type TCompareAction = {
	apartmentId: string,
	isDelete?: boolean,
	callback?: () => {},
};

/**
 * Добавление/удаление в сравнение
 * @param apartmentId - id квартиры
 * @param isDelete - флаг на удаление
 * @param callback - коллбэк-функция
 */
export const compareToggleAction =
	(apartmentId, isDelete = false, callback = () => {}): TCompareAction =>
	(dispatch, getState) => {
		const { token } = getState().token;
		const compareUrl = `${window.App.lexsUrl}/cabinet/comparison`;

		fetch(compareUrl, {
			method: isDelete ? 'DELETE' : 'POST',
			...addJavaHeaders(token),
			body: JSON.stringify({ apartmentId }),
		})
			.then(checkStatus)
			.then(parseJSON)
			.then(({ result, data, errors }) => {
				if (result === 'ok') {
					dispatch({
						type: isDelete ? DELETE_FROM_COMPARE : ADD_TO_COMPARE,
						payload: {
							...data,
						},
					});
					if (callback) {
						callback();
					}
				}

				if (result === 'error' && !isEmpty(errors)) {
					Object.values(errors).forEach((error) => {
						dispatch(failNotification(notificationOpts(error.message)));
					});
				}
			})
			.catch((reason) => {
				console.error('---', 'reason', reason);
			});
	};
