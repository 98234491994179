export default [
	// Базовые значения
	{
		value: 'keyTransferDateLte',
		get: 'date',
		type: 'value',
	},
	{
		value: 'keysAvailable',
		get: 'ka',
	},
	{
		value: 'deadlineLte',
		get: 'dlte',
		type: 'value',
	},
	{
		value: 'deadlineLte',
		get: 'deadlineLte',
		type: 'value',
	},
	{
		value: 'priceTotalMinGte',
		get: 'pgte',
		type: 'price',
		requireForSaveSelection: true,
	},
	{
		value: 'priceTotalMinGte',
		get: 'priceTotalMinGte',
		type: 'price',
		requireForSaveSelection: true,
	},
	{
		value: 'priceTotalMinLte',
		get: 'plte',
		type: 'price',
		requireForSaveSelection: true,
	},
	{
		value: 'priceTotalMinLte',
		get: 'priceTotalMinLte',
		type: 'price',
		requireForSaveSelection: true,
	},
	{
		value: 'activeArea',
		get: 'area',
		type: 'value',
	},
	{
		value: 'roomsCntForFilter',
		get: 'rms',
		type: 'array',
		requireForSaveSelection: true,
	},
	{
		value: 'roomsCntForFilter',
		get: 'roomsCntForFilter',
		type: 'array',
		requireForSaveSelection: true,
	},
	{
		value: 'areaIds',
		get: 'dst',
		type: 'array',
	},
	{
		value: 'metroIds',
		get: 'sbw',
		type: 'array',
	},
	{
		value: 'calculationType',
		get: 'ctype',
		type: 'string',
	},
	{
		value: 'finish',
		get: 'finish',
		type: 'string',
	},
	{
		value: 'sort',
		get: 'sort',
		type: 'string',
	},
	{
		value: 'page',
		get: 'page',
		type: 'page',
	},
];
