import { KNOWLEDGE_DETAIL_FETCH_ARTICLE_DATA, KNOWLEDGE_DETAIL_CLEAR } from '../constants/knowledgeDetail';
import { FAIL, START, SUCCESS } from '../constants';
import type { TArticleKnowledge } from '../types/articles';

export type TKnowledgeDetail = {
	articleData: TArticleKnowledge,
	loading: boolean,
};

const initialState = {
	articleData: null,
	loading: false,
};

const reducer = (state: TKnowledgeDetail = initialState, action) => {
	switch (action.type) {
		case KNOWLEDGE_DETAIL_FETCH_ARTICLE_DATA + START:
			return { ...state, loading: true };

		case KNOWLEDGE_DETAIL_FETCH_ARTICLE_DATA + SUCCESS:
			return { ...state, articleData: action.payload, loading: false };

		case KNOWLEDGE_DETAIL_FETCH_ARTICLE_DATA + FAIL:
			return { ...state, articleData: null, loading: false };

		case KNOWLEDGE_DETAIL_CLEAR:
			return {
				...initialState,
			};

		default:
			return state;
	}
};

export default reducer;
