import { LIRA_SET_POPUP } from '../constants';
import { changeLiraApartmentOpinion } from '../actions/lira';
import { setUserStatus } from '../actions/apartment';

/**
 * Статус оценки пользователем квартиры
 * @param {string} opinion - оценка (LIKE, DISLIKE, null)
 * @param {string} apartId - id квартиры, которую оценивают
 */
export const changeApartOpinionCommon = (opinion, apartId) => async (store, context, dispatch) => {
	if (opinion === 'LIKE' || 'RESET_REACTION') {
		dispatch({
			type: LIRA_SET_POPUP,
			payload: { notices: [] },
		});
	}

	await changeLiraApartmentOpinion(apartId, opinion)(dispatch, store.getState, context);
	dispatch(setUserStatus(opinion === 'RESET_REACTION' ? null : opinion));
};
