import React from 'react';
import { Header as ComplexHeader, ShareWrapper } from '../../routes/complex/styles';
import {
	AddressWrapper,
	ArticleText,
	ArticleTitle,
	BigSlide,
	BreadcrumbsWrapper,
	ComplexCard,
	ComplexCardContent,
	ComplexCardImage,
	ComplexCardPrice,
	ComplexCards,
	ComplexCardText,
	ComplexCardTitle,
	ComplexFilter,
	ComplexFilterPlanning,
	ComplexFilterPlannings,
	ComplexFilterSelect,
	ComplexFilterSelectLeft,
	ComplexFilterSelectRight,
	ComplexFilterTable,
	ComplexFilterTitle,
	ComplexRating,
	ComplexRatingPie,
	ComplexRatingPieItem,
	ComplexRatingProp,
	ComplexRatingProps,
	ComplexRatingTitle,
	DiscountIcon,
	DiscountItem,
	DiscountText,
	DiscountWrapper,
	Map,
	ShareIcon,
	Sidebar,
	SmallSlide,
	Spacer,
	TinderFilterField,
	TinderLiraApartCard,
	TinderLiraApartCardList,
	TitleWrapper,
	LiraApartCardList,
} from './styles';
import { Root as ImageSlider, ThumbsWrapper } from '../ImageSlider/styles';
import { ArticleImage, ArticleTextWrapper, Root as ComplexArticle } from '../ArticleItem/styles';
import { Wrapper } from '../Stories/styles';

export const SkeletonComplexHeader = () => (
	<ComplexHeader>
		<BreadcrumbsWrapper>
			<Spacer dark />
		</BreadcrumbsWrapper>
		<TitleWrapper>
			<Spacer dark />
		</TitleWrapper>
		<AddressWrapper>
			<Spacer dark />
		</AddressWrapper>
		<ShareWrapper>
			<ShareIcon>
				<Spacer />
			</ShareIcon>
			<ShareIcon>
				<Spacer />
			</ShareIcon>
		</ShareWrapper>
	</ComplexHeader>
);

export const SkeletonComplexImageSlider = () => (
	<ImageSlider>
		<BigSlide>
			<Spacer />
		</BigSlide>
		<ThumbsWrapper>
			<SmallSlide>
				<Spacer />
			</SmallSlide>
			<SmallSlide>
				<Spacer />
			</SmallSlide>
			<SmallSlide>
				<Spacer />
			</SmallSlide>
			<SmallSlide>
				<Spacer />
			</SmallSlide>
			<SmallSlide>
				<Spacer />
			</SmallSlide>
			<SmallSlide>
				<Spacer />
			</SmallSlide>
		</ThumbsWrapper>
	</ImageSlider>
);

export const SkeletonComplexSidebar = () => (
	<Sidebar>
		<Spacer />
	</Sidebar>
);

export const SkeletonDiscounts = () => (
	<DiscountWrapper>
		<DiscountItem>
			<DiscountIcon>
				<Spacer />
			</DiscountIcon>
			<DiscountText>
				<Spacer dark />
			</DiscountText>
		</DiscountItem>
		<DiscountItem>
			<DiscountIcon>
				<Spacer />
			</DiscountIcon>
			<DiscountText>
				<Spacer dark />
			</DiscountText>
		</DiscountItem>
		<DiscountItem>
			<DiscountIcon>
				<Spacer />
			</DiscountIcon>
			<DiscountText>
				<Spacer dark />
			</DiscountText>
		</DiscountItem>
	</DiscountWrapper>
);

export const SkeletonMap = ({ fullHeight = false }) => (
	<Map $fullHeight={fullHeight}>
		<Spacer />
	</Map>
);

export const SkeletonComplexArticles = () => (
	<ComplexArticle round>
		<ArticleImage>
			<Spacer />
		</ArticleImage>
		<ArticleTextWrapper>
			<ArticleTitle>
				<Spacer dark />
			</ArticleTitle>
			<ArticleText>
				<Spacer />
			</ArticleText>
		</ArticleTextWrapper>
	</ComplexArticle>
);

export const SkeletonNav = () => <Spacer />;

export const SkeletonComplexCards = () => (
	<ComplexCards>
		<ComplexCard>
			<ComplexCardImage>
				<Spacer />
			</ComplexCardImage>
			<ComplexCardContent>
				<ComplexCardTitle>
					<Spacer dark />
				</ComplexCardTitle>
				<ComplexCardText>
					<Spacer />
				</ComplexCardText>
				<ComplexCardText>
					<Spacer />
				</ComplexCardText>
				<ComplexCardPrice>
					<Spacer dark />
				</ComplexCardPrice>
			</ComplexCardContent>
		</ComplexCard>
		<ComplexCard>
			<ComplexCardImage>
				<Spacer />
			</ComplexCardImage>
			<ComplexCardContent>
				<ComplexCardTitle>
					<Spacer dark />
				</ComplexCardTitle>
				<ComplexCardText>
					<Spacer />
				</ComplexCardText>
				<ComplexCardText>
					<Spacer />
				</ComplexCardText>
				<ComplexCardPrice>
					<Spacer dark />
				</ComplexCardPrice>
			</ComplexCardContent>
		</ComplexCard>
	</ComplexCards>
);

export const SkeletonComplexPlanningsTable = () => {
	const tableArray = [];

	for (let i = 0; i < 16; i++) {
		tableArray.push(<Spacer key={i.toString()} white style={{ height: '28px' }} />);
	}

	return (
		<Spacer>
			<ComplexFilterTable>{tableArray}</ComplexFilterTable>
		</Spacer>
	);
};

export const SkeletonComplexFilter = () => {
	const planningArray = [];

	for (let i = 0; i < 5; i++) {
		planningArray.push(
			<ComplexFilterPlanning key={i.toString()}>
				<Spacer />
			</ComplexFilterPlanning>,
		);
	}

	return (
		<ComplexFilter>
			<ComplexFilterTitle>
				<Spacer dark />
			</ComplexFilterTitle>
			<ComplexFilterPlannings>{planningArray}</ComplexFilterPlannings>
			<ComplexFilterSelect>
				<ComplexFilterSelectLeft>
					<Spacer dark />
				</ComplexFilterSelectLeft>
				<ComplexFilterSelectRight>
					<Spacer dark />
				</ComplexFilterSelectRight>
			</ComplexFilterSelect>
			<SkeletonComplexPlanningsTable />
		</ComplexFilter>
	);
};

export const SkeletonRating = () => (
	<ComplexRating>
		<ComplexRatingPie>
			<ComplexRatingPieItem />
		</ComplexRatingPie>
		<ComplexRatingProps>
			<ComplexRatingTitle>
				<Spacer dark />
			</ComplexRatingTitle>
			<ComplexRatingProp>
				<Spacer />
			</ComplexRatingProp>
			<ComplexRatingProp>
				<Spacer />
			</ComplexRatingProp>
		</ComplexRatingProps>
	</ComplexRating>
);

export const SkeletonTinderLiraApartCard = () => {
	const blockArray = [];

	for (let i = 0; i < 6; i++) {
		blockArray.push(
			<React.Fragment key={i.toString()}>
				<Spacer dark={i === 1} />
				<div />
			</React.Fragment>,
		);
	}

	return <TinderLiraApartCard>{blockArray}</TinderLiraApartCard>;
};

export const SkeletonTinderLiraApartCardList = () => (
	<TinderLiraApartCardList>
		<Spacer dark />
		<Spacer />
	</TinderLiraApartCardList>
);

export const SkeletonLiraApartCardList = () => (
	<LiraApartCardList>
		<Spacer dark />
		<Spacer />
	</LiraApartCardList>
);

export const SkeletonStories = () => (
	<Wrapper $countInRow={4}>
		<Spacer />
		<Spacer />
		<Spacer />
	</Wrapper>
);

export const SkeletonFilterField = () => (
	<TinderFilterField>
		<Spacer />
	</TinderFilterField>
);
