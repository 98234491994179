import styled, { keyframes } from 'styled-components';
import { MEDIA_ON_MOBILE } from '../../constants';
import theme from '../../theme';

const lightGray = theme.lightGrayColor;
const darkGray = theme.middleGrayColor;
const transparent = 'rgba(255, 255, 255, 0)'; /* Safari fix */

export const Loading = keyframes`
	from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
`;

export const Spacer = styled.div`
	position: relative;
	z-index: 0;
	width: 100%;
	height: 100%;
	background-color: ${({ dark, white }) => (dark ? darkGray : white ? '#fff' : lightGray)};
	border-radius: 5px;
	background-repeat: no-repeat;
	overflow: hidden;

	&::after {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: -150px;
		z-index: 1;
		background-image: linear-gradient(to right, ${transparent} 0%, #fefefe 50%, ${transparent} 100%);
		animation: ${Loading} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
		content: '';
	}
`;

export const BreadcrumbsWrapper = styled.div`
	grid-area: breadcrumbs;
	display: flex;
	width: 30%;
	height: 12px;
`;

export const TitleWrapper = styled.div`
	grid-area: title;
	display: flex;
	width: 50%;
	height: 35px;
`;

export const AddressWrapper = styled.div`
	grid-area: address;
	width: 50%;
	height: 12px;
`;

export const ShareIcon = styled.div`
	width: 42px;
	height: 42px;
	border-radius: 50%;
	overflow: hidden;

	@media ${MEDIA_ON_MOBILE} {
		width: 32px;
		height: 32px;
	{
`;

export const BigSlide = styled.div`
	width: 100%;
	height: 400px;
`;

export const SmallSlide = styled.div`
	width: 100%;
	height: 100px;
`;

export const Sidebar = styled.div`
	width: 100%;
	height: 505px;
	grid-area: sidebar;
`;

export const DiscountWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const DiscountItem = styled.div`
	display: grid;
	grid-template-areas:
		'DiscountIcon DiscountText DiscountBtn'
		'. DiscountDescription . ';
	grid-template-columns: 50px 1fr 28px;
	align-items: center;
	margin-bottom: 20px;
	padding: 20px;
	border-radius: 5px;
	box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.18);
`;

export const DiscountIcon = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
	grid-area: DiscountIcon;
`;

export const DiscountText = styled.div`
	grid-area: DiscountText;
	width: 50%;
	height: 24px;
	margin-left: 40px;
`;

export const Map = styled.div`
	width: 100%;
	height: ${({ $fullHeight }) => ($fullHeight ? '100%' : '500px')};
`;

export const ArticleTitle = styled.div`
	margin: 1em 0;
	width: 70%;
	height: 23px;
`;

export const ArticleText = styled.div`
	width: 100%;
	height: 90px;
	margin-bottom: 40px;
`;

export const ComplexCards = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
	grid-gap: 20px;
	width: 100%;
`;

export const ComplexCard = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 250px;
	max-width: 320px;
	box-sizing: border-box;
	background: #fff;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.18);
	border-radius: 5px;
	overflow: hidden;
`;

export const ComplexCardImage = styled.div`
	width: 100%;
	height: 160px;
`;

export const ComplexCardContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 24px 24px;
`;

export const ComplexCardTitle = styled.div`
	width: 90%;
	height: 28px;
	margin: 1em 0;
`;

export const ComplexCardText = styled.div`
	width: 75%;
	height: 14px;
	margin: 5px 0;

	&:last-of-type {
		width: 60%;
	}
`;

export const ComplexCardPrice = styled.div`
	width: 40%;
	height: 18px;
	margin-top: 40px;
`;

export const ComplexFilter = styled.div`
	display: grid;
	grid-row-gap: 20px;
	grid-template-columns: 100%;
`;

export const ComplexFilterTitle = styled.div`
	width: 30%;
	height: 28px;
	margin: 40px 0 20px;
`;

export const ComplexFilterPlannings = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-column-gap: 15px;
	width: 100%;

	@media ${MEDIA_ON_MOBILE} {
		width: calc(100% + 30px);
		margin: 0 -15px;
		padding: 0 15px;
		grid-template-columns: repeat(5, 180px);
		overflow: hidden;
	}
`;

export const ComplexFilterPlanning = styled.div`
	width: 100%;
	height: 210px;
`;

export const ComplexFilterSelect = styled.div`
	display: flex;
	justify-content: space-between;
	height: 32px;
`;

export const ComplexFilterSelectLeft = styled.div`
	width: 25%;
`;

export const ComplexFilterSelectRight = styled.div`
	width: 40%;
`;

export const ComplexFilterTable = styled.div`
	margin: 70px 15px 55px;
	display: grid;
	grid-gap: 40px 10px;
	grid-template-columns: 28px 1fr;
`;

export const ComplexRating = styled.div`
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 260px 1fr;
	align-items: center;
	margin: 50px 0;

	@media ${MEDIA_ON_MOBILE} {
		grid-template-columns: 100%;
	}
`;

export const ComplexRatingProps = styled.div`
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(3, 1fr);

	@media ${MEDIA_ON_MOBILE} {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const ComplexRatingTitle = styled.div`
	grid-column: 1 / span 2;
	width: 60%;
	height: 28px;
	margin: 0 0 30px;
`;

export const ComplexRatingProp = styled.div`
	grid-row: 2;
	width: 100%;
	height: 180px;
`;

export const Rotate = keyframes`
	0% {
		transform: rotate(0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	50% {
		transform: rotate(900deg);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	100% {
		transform: rotate(1800deg);
	}
`;

export const ComplexRatingPie = styled.div`
	display: flex;
	width: 100%;
	height: 250px;
	align-items: center;
	justify-content: center;
`;

export const ComplexRatingPieItem = styled.div`
	display: block;
	position: relative;
	width: 80px;
	height: 80px;

	&::after {
		content: ' ';
		display: block;
		border-radius: 50%;
		width: 0;
		height: 0;
		margin: 8px;
		box-sizing: border-box;
		border: 32px solid #fff;
		border-color: ${darkGray} ${transparent} ${lightGray} ${transparent};
		animation: ${Rotate} 1.2s infinite;
	}
`;

export const TinderLiraApartCard = styled.div`
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 190px 6px 4px 10px 70px 20px 70px 20px 40px 20px 20px 10px;
	padding: 16px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
	border-radius: 4px;
	background-color: #fff;
`;

export const TinderLiraApartCardList = styled.div`
	display: grid;
	grid-template-columns: 1fr 360px;
	padding: 16px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
	border-radius: 4px;
	background-color: #fff;
	gap: 18px;
	height: 320px;
`;

export const TinderFilterField = styled.div`
	display: flex;
	width: 100%;
	height: 56px;
`;

export const LiraApartCardList = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	padding: 16px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
	border-radius: 4px;
	background-color: #fff;
	gap: 12px;
	height: 320px;
`;
