import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getUrl } from '../../utils';
import SocialList from '../SocialList';
import { Popover, Tooltip } from '../Tooltip/Tooltip';
import SideButton from '../SideButton';
import { CustomBtn, Popup, Icon, CopyButton, CopyIcon, Label } from './styles';
import { share2023Icon } from '../Icons';

const SOCIAL_SHARE = [
	{
		type: 'vk',
		title: 'ВКонтакте',
		link: '/',
		id: 1,
	},
	{
		type: 'ok',
		title: 'Одноклассники',
		link: '/',
		id: 2,
	},
	{
		type: 'telegram',
		title: 'Telegram',
		link: '/',
		id: 3,
	},
	{
		type: 'whatsapp',
		title: 'WhatsApp',
		link: '/',
		id: 4,
	},
	{
		type: 'viber',
		title: 'Viber',
		link: '/',
		id: 5,
	},
	/* {
		type: 'facebook',
		title: 'Facebook',
		link: '/',
		id: 3,
	},
	{
		type: 'twitter',
		title: 'Twitter',
		link: '/',
		id: 6,
	}, */
];

const shareIcon = (
	<path
		fillRule="nonzero"
		d="M15.2 11.4a3.789 3.789 0 0 0-2.863 1.306l-4.822-2.41a3.819 3.819 0 0 0 0-1.591l4.822-2.411c.697.799 1.72 1.306 2.863 1.306a3.8 3.8 0 1 0-3.715-3.005L6.663 7.006A3.787 3.787 0 0 0 3.8 5.7a3.8 3.8 0 0 0 0 7.6 3.789 3.789 0 0 0 2.863-1.306l4.822 2.41A3.8 3.8 0 1 0 15.2 11.4zm0-9.5a1.9 1.9 0 1 1 0 3.8 1.9 1.9 0 0 1 0-3.8zM3.8 11.4a1.9 1.9 0 1 1 0-3.8 1.9 1.9 0 0 1 0 3.8zm11.4 5.7a1.9 1.9 0 1 1 0-3.8 1.9 1.9 0 0 1 0 3.8z"
	/>
);

const chainIcon = (
	<g fill="none" fillRule="evenodd">
		<circle cx="13" cy="13" r="13" fill="#CC3B1F" />
		<path
			fill="#FFF"
			fillRule="nonzero"
			d="M11.127 16.107l-.708.704a1.585 1.585 0 0 1-2.227 0 1.532 1.532 0 0 1-.457-1.095c0-.413.162-.802.457-1.095l2.608-2.587c.54-.536 1.557-1.325 2.297-.59a.867.867 0 1 0 1.223-1.231c-1.26-1.25-3.121-1.02-4.742.59L6.97 13.39A3.256 3.256 0 0 0 6 15.716c0 .88.344 1.705.97 2.326.644.639 1.49.958 2.335.958.846 0 1.692-.32 2.336-.958l.709-.704a.867.867 0 1 0-1.223-1.23zm7.902-10.05c-1.352-1.342-3.244-1.414-4.496-.172l-.883.877a.867.867 0 1 0 1.223 1.23l.882-.875c.649-.645 1.498-.377 2.052.172.296.293.458.682.458 1.095 0 .414-.162.803-.457 1.096l-2.782 2.76c-1.272 1.261-1.869.67-2.124.416a.868.868 0 0 0-1.222 1.231c.584.58 1.25.867 1.95.867.855 0 1.759-.43 2.618-1.284l2.782-2.76c.625-.62.97-1.447.97-2.326s-.345-1.705-.97-2.326z"
		/>
	</g>
);

const ShareBtn = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const toggleCopyButton = () => {
		const { seoShareEvent } = props;
		setIsCopied(true);
		seoShareEvent('Скопировать ссылку');
	};
	const {
		mode,
		margin,
		iconSize,
		withText,
		link,
		seoShareEvent,
		dataQa,
		color,
		shareData,
		mobileContextMenuType,
		renderComponent,
	} = props;

	const SharePopup = (
		<Popup $isBig={mobileContextMenuType}>
			<CopyToClipboard text={link || getUrl()} onCopy={toggleCopyButton}>
				<CopyButton>
					<CopyIcon viewBox="0 0 26 26">{chainIcon}</CopyIcon>
					<Label>{isCopied ? 'Ссылка скопирована' : 'Скопировать ссылку'}</Label>
				</CopyButton>
			</CopyToClipboard>
			<SocialList data={SOCIAL_SHARE} url={link || getUrl()} mode="color" seoShareEvent={seoShareEvent} />
		</Popup>
	);

	const handleSharing = async () => {
		if (navigator.share && navigator.canShare(shareData)) {
			await navigator.share(shareData);
		} else {
			toggle();
		}
	};

	if (mode === 'Side') {
		return (
			<Tooltip content="Поделиться" placement="top" touch={false}>
				<div>
					<Popover
						theme="clean"
						placement="right"
						visible={isOpen}
						onClickOutside={() => setIsOpen(false)}
						onHidden={() => setIsOpen(false)}
						content={SharePopup}
					>
						<div>
							<SideButton
								data-qa={dataQa}
								id="buttonShare"
								onClick={toggle}
								isActive={isOpen}
								color={color}
							>
								<Icon iconSize={iconSize} viewBox="0 0 24 24">
									{share2023Icon}
								</Icon>
							</SideButton>
						</div>
					</Popover>
				</div>
			</Tooltip>
		);
	}

	return (
		<Popover
			theme="clean"
			visible={isOpen}
			appendTo={() => mobileContextMenuType && document.body}
			onClickOutside={() => setIsOpen(false)}
			onHidden={() => setIsOpen(false)}
			content={SharePopup}
		>
			<div>
				{renderComponent ? (
					renderComponent(handleSharing)
				) : (
					<CustomBtn
						margin={margin}
						isActive={isOpen}
						onClick={toggle}
						title="Поделиться"
						withText={withText}
						id="buttonShare"
						{...props}
					>
						<Icon iconSize={iconSize} viewBox="0 0 19 19">
							{shareIcon}
						</Icon>
						{withText && <span>Поделиться</span>}
					</CustomBtn>
				)}
			</div>
		</Popover>
	);
};

ShareBtn.propTypes = {
	seoShareEvent: PropTypes.func,
	mode: PropTypes.string,
	margin: PropTypes.string,
	link: PropTypes.string,
	withText: PropTypes.bool,
	iconSize: PropTypes.number,
	dataQa: PropTypes.string,
	color: PropTypes.string,
	mobileContextMenuType: PropTypes.bool,
	shareData: PropTypes.object,
	renderComponent: PropTypes.func,
};

ShareBtn.defaultProps = {
	seoShareEvent: () => {},
	mode: null,
	withText: false,
	iconSize: 16,
	dataQa: null,
	color: null,
	mobileContextMenuType: false,
	renderComponent: () => {},
};

export default ShareBtn;
