import styled, { css } from 'styled-components';

export const Label = styled.button`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	column-gap: 10px;
	row-gap: normal;
	flex: 1 1 100%;
	margin: 0 10px 0 0;
	padding: 0;
	border: none;
	box-shadow: none;
	background: transparent;
	cursor: pointer;
	${({ $isMetro, $isGroup }) =>
		$isMetro &&
		!$isGroup &&
		css`
			flex-direction: column;
			align-items: flex-start;
		`}

	@media (max-width: 460px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 0;
	}
`;

export const LabelText = styled.div`
	display: flex;
	align-items: center;
	text-align: left;
	color: ${({ $isTopLevel, theme }) => ($isTopLevel ? theme.primaryColor : theme.primaryBlackColor)};
	${({ $isGroup, theme }) => ($isGroup ? theme.typography.textMediumL : theme.typography.textL)};

	svg {
		margin-right: 10px;
	}

	${({ $isGroup, $color }) =>
		!$isGroup &&
		$color &&
		`
		&::before {
			content: '';
			display: block;
			width: 4px;
			height: 4px;
			margin-right: 8px;
			border-radius: 50%;
			background-color: ${$color};
		}`}
	}
`;

export const LabelCounter = styled.div`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.darkGrayColor};
	${({ theme }) => theme.typography.textM};

	svg {
		flex-shrink: 0;
		width: 14px;
		height: 8px;
		stroke: currentColor;
		fill: none;
		margin-left: 10px;
		transform: ${({ $isOpen }) => ($isOpen ? 'rotate(0)' : 'rotate(-180deg)')};
		transition: all 0.3s;
	}
`;

export const Checkbox = styled.button`
	position: relative;
	flex-shrink: 0;
	width: 32px;
	height: 32px;
	display: block;
	background-color: ${({ theme }) => theme.secondaryColor};
	color: ${({ $isActive, theme }) => ($isActive ? theme.primaryColor : theme.secondaryColor)};
	box-shadow: none;
	border: none;
	border-radius: 50%;
	transition: all 0.3s;
	cursor: pointer;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 15px;
		height: 8px;
		border-top: none;
		border-right: none;
		border-bottom: 2px solid currentColor;
		border-left: 2px solid currentColor;
		box-sizing: border-box;
		transform: translate(-50%, -60%) rotate(-45deg);
		transform-origin: center;
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background-color: ${({ theme }) => theme.primaryColor};
			color: ${({ $isActive, theme }) => ($isActive ? theme.primaryColor : theme.secondaryColor)};
		}
	}
`;

export const Root = styled.div`
	display: ${({ $isShow }) => ($isShow ? 'flex' : 'none')};
	align-items: center;
	justify-content: space-between;
	padding: ${({ $isGroup }) => ($isGroup ? '6px 12px 6px 0' : '6px 12px')};
	margin: 0;
	box-sizing: border-box;
	transition: background-color 0.3s;
	border-radius: 4px;

	@media (min-width: 600px) {
		${({ $oneColumn }) => $oneColumn && 'grid-column: span 2;'};
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover,
		&:focus {
			background-color: ${({ theme, $isGroup }) => ($isGroup ? 'unset' : theme.grayColor)};
		}
	}
`;
