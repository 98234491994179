import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';

import analytics from './analytics';
import apartment from './apartment';
import app from './app';
import article from './article';
import auth from './auth';
import buyer from './buyer';
import buyerList from './buyerList';
import cities from './cities';
import city from './city';
import cityConfig from './cityConfig';
import complex from './complex';
import plannings from './plannings';
import expertise from './expertise';
import dosier from './dosier';
import dosierDetail from './dosierDetail';
import expertiseReport from './expertiseReport';
import filterBase from './filterBase';
import filterExt from './filterExt';
import filterGlobal from './filterGlobal';
import filterWishes from './filterWishes';
import filterPlannings from './filterPlannings';
import filterComplex from './filterComplex';
import filterTemp from './filterTemp';
import compare from './compare';
import feed from './feed';
import helpful from './helpful';
import journal from './journal';
import journalDetail from './journalDetail';
import knowledge from './knowledge';
import knowledgeDetail from './knowledgeDetail';
import liraPopups from './liraPopups';
import liraSelections from './liraSelections';
import liraTemplates from './liraTemplates';
import liraWishes from './liraWishes';
import lodger from './lodger';
import lodgerList from './lodgerList';
import news from './news';
import popup from './popup';
import profile from './profile';
import reports from './reports';
import runtime from './runtime';
import selection from './selection';
import token from './token';
import user from './user';
import video from './video';
import mortgage from './mortgage';
import services from './services';
import serviceOrder from './serviceOrder';
import search from './search';
import researchList from './researchList';
import selectionMap from './selectionMap';
import sidebarAparts from './sidebarAparts';
import lira from './lira';

const rootReducer = combineReducers({
	analytics,
	apartment,
	app,
	article,
	auth,
	buyer,
	buyerList,
	cities,
	city,
	cityConfig,
	compare,
	complex,
	plannings,
	filterBase,
	filterExt,
	filterGlobal,
	filterWishes,
	filterPlannings,
	filterComplex,
	filterTemp,
	expertise,
	dosier,
	dosierDetail,
	expertiseReport,
	feed,
	form: formReducer,
	helpful,
	journal,
	journalDetail,
	knowledge,
	knowledgeDetail,
	news,
	notifications,
	lira,
	liraPopups,
	liraSelections,
	liraTemplates,
	liraWishes,
	lodger,
	lodgerList,
	popup,
	profile,
	reports,
	researchList,
	runtime,
	selection,
	selectionMap,
	sidebarAparts,
	token,
	user,
	video,
	mortgage,
	search,
	services,
	serviceOrder,
});

export default rootReducer;
