/* @flow */
import type { Node } from 'react';
import React, { useCallback, useEffect, useRef } from 'react';
import loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import { Normalize } from 'styled-normalize';
import { ThemeProvider } from 'styled-components';
import TagManager from 'react-gtm-module';

import { SnackbarProvider } from 'notistack';
import FoldingFooter from '../FoldingFooter';
import Header from '../Header';

import { notificationsStyle } from '../../data/general/notificationsConfig';
import social from '../../data/general/social';

import theme from '../../theme';
import { getActiveSelectionPath, getFooterUrls, getSelectionLinkItem } from '../../utils';
import { AUTH_SET_STAGE, LAYOUT_AUTH_NEEDED } from '../../constants';
import type { TDispatch } from '../../types/core';
import type { TCity, TCityConfig } from '../../types/city';
import { Content, GlobalStyles, Main, PopupContentWrapper, Root, Skip, Wrapper } from './styles';
import {
	useAuthLocalStorage,
	useAuthTimer,
	useComponentDidMount,
	useLayoutSizes,
	useLocation,
	useModal,
	useValidToken,
} from '../../utils/hooks';
import ModalAuthDialog from '../ModalAuthDialog';
import type { TAuthState } from '../../reducers/auth';
import type { TProfile } from '../../types/account';
import type { TNavigationButtonProps } from '../Modal/NavigationButton';
import type { TAppState } from '../../reducers/app';
import type { TRootState } from '../../types/rootState';
import { setShowFooter } from '../../actions/app';

type TProps = {
	children: Node,
	route?: string | null,
	stageRoute?: string | null,
	withoutHeader?: boolean,
	withoutFooter?: boolean,
	menuWithContext?: boolean,
	isApartResale?: boolean,
	bg?: string,
};

const Modal = loadable(() => import('../Modal/Modal'), { fallback: null, ssr: false });

const Layout = ({
	route,
	stageRoute,
	children,
	withoutHeader,
	withoutFooter,
	menuWithContext,
	isApartResale,
	bg,
}: TProps): Node => {
	useEffect(() => {
		const tagManagerArgs = {
			gtmId: 'GTM-W77CQJT',
		};

		TagManager.initialize(tagManagerArgs);
	}, []);

	const dispatch: TDispatch = useDispatch();

	const authState: TAuthState = useSelector((state: TRootState) => state.auth);
	const city: TCity = useSelector((state: TRootState) => state.city);
	const cityConfig: TCityConfig = useSelector((state: TRootState) => state.cityConfig);
	const notifications = useSelector((state: TRootState) => state.notifications);
	const profile: TProfile = useSelector((state: TRootState) => state.profile);
	const app: TAppState = useSelector((state: TRootState) => state.app);

	const { authNeeded } = app;
	const { splashScreens, forceSteps } = profile;
	const { timerInProgress } = authState;

	const rootRef = useRef(null);

	useValidToken();
	useLayoutSizes();
	useAuthTimer(60);
	useAuthLocalStorage();

	const { isShowing, toggle, openModal, closeModal } = useModal();
	const isMounted = useComponentDidMount();

	const onAuthClose = () => {
		closeModal();
	};

	const onAuthBack = (): any => {
		dispatch({ type: AUTH_SET_STAGE, payload: 'phone', act: true });
	};

	const openAuthDialog = useCallback(() => {
		dispatch({ type: LAYOUT_AUTH_NEEDED, payload: false });
		openModal();
	}, []);

	/* const showAppPopupAction = () => {
		if (!sessionStorage.getItem('startTime')) {
			sessionStorage.setItem('startTime', Date.now());
		}

		const enterTime = sessionStorage.getItem('startTime');

		const timer = setInterval(() => {
			const currentTime = Date.now();
			const spentTime = (currentTime - enterTime) / 1000;
			if (spentTime >= 5 && !appPopup.show && !appPopup.wasShown) {
				clearInterval(timer);
				dispatch({ type: SHOW_APP_POPUP, payload: true });
			}
		}, 10000);
	}; */

	useEffect(() => {
		rootRef?.current.focus();
	}, []);

	useEffect(() => {
		dispatch(setShowFooter(!withoutFooter));
	}, [withoutFooter]);

	useEffect(() => {
		if (isMounted) {
			if (authNeeded) {
				openAuthDialog();
			}
		}
	}, [authNeeded]);

	const citySlug = city.link;
	const selectionLinkItem = getSelectionLinkItem(citySlug, getActiveSelectionPath(splashScreens, forceSteps));
	const footerNavigation = getFooterUrls(citySlug, cityConfig, selectionLinkItem);

	const footer = {
		social,
		navigation: footerNavigation,
	};

	const authNavButtons: TNavigationButtonProps = [
		{
			mode: 'back',
			visible: authState.stage === 'code' && !authState.repeatBlocked,
			disabled: timerInProgress,
			onClick: onAuthBack,
		},
	];

	const location = useLocation();

	useEffect(() => {
		const { hash } = location;

		if (hash === '') {
			window.scrollTo(0, 0);
		} else {
			setTimeout(() => {
				const id = hash.replace('#', '');
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView();
				}
			}, 0);
		}
	}, [location]);

	let backURL;

	if (location?.search?.length > 0) {
		const urlParams = new URLSearchParams(location.search);
		backURL = urlParams.get('backUrl');
	}

	return (
		<>
			<Normalize />
			<SnackbarProvider
				autoHideDuration={5000}
				maxSnack={3}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			/>
			<ThemeProvider theme={theme}>
				<GlobalStyles />
				<Root ref={rootRef} isVisible={isMounted && !authState.isLogout} className={route}>
					<Skip tabIndex="0" href="#main">
						Перейти к основному контенту
					</Skip>
					<Content flex bg={bg}>
						<Wrapper>
							{!withoutHeader && (
								<Header
									route={route}
									stageRoute={stageRoute}
									openAuthPopup={toggle}
									withContext={menuWithContext}
									isApartResale={isApartResale}
								/>
							)}
							<Main id="main">{children}</Main>
						</Wrapper>
					</Content>
					<FoldingFooter data={footer} route={route} />
				</Root>

				<Modal isShowing={isShowing} hasNavigation navButtons={authNavButtons} hide={onAuthClose}>
					<PopupContentWrapper>
						<ModalAuthDialog onClose={onAuthClose} backUrl={backURL} />
					</PopupContentWrapper>
				</Modal>

				<Notifications notifications={notifications} style={notificationsStyle} />
			</ThemeProvider>
		</>
	);
};

Layout.defaultProps = {
	route: null,
	stageRoute: null,
	withoutHeader: false,
	withoutFooter: false,
	menuWithContext: false,
	isApartResale: false,
	bg: 'transparent',
};

export default Layout;
