export const GET_SELECTION_MAP_DATA = 'GET_SELECTION_MAP_DATA';
export const GET_SELECTION_MAP_DATA_FULL = 'GET_SELECTION_MAP_DATA_FULL';
export const GET_SIDE_BAR_DATA = 'GET_SIDE_BAR_DATA';
export const SET_SIDE_BAR_SORT = 'SET_SIDE_BAR_SORT';
export const SET_SIDEBAR_SELECTED_APART = 'SET_SIDEBAR_SELECTED_APART';
export const SET_SIDEBAR_TAB_VIEW = 'SET_SIDEBAR_TAB_VIEW';
export const SET_SIDEBAR_SELECTED_CLUSTER = 'SET_SIDEBAR_SELECTED_CLUSTER';
export const BLOCK_SCREEN_MAP = 'BLOCK_SCREEN_MAP';
export const SET_BOUNDS = 'SET_BOUNDS';
export const CHANGE_FILTER_MAP = 'CHANGE_FILTER_MAP';
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';
export const SET_SIDEBAR_CLOSE = 'SET_SIDEBAR_CLOSE';
export const SET_UPDATE_MAP_SELECTION = 'SET_UPDATE_MAP_SELECTION';
export const SET_SOFT_UPDATE = 'SET_SOFT_UPDATE';
export const SET_SELECTED_CLUSTER = 'SET_SELECTED_CLUSTER';
export const SET_SIDEBAR_NEED_UPDATE = 'SET_SIDEBAR_NEED_UPDATE';

export const MAP_TILES = 'http://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}';
export const MAP_COPYRIGHT =
	'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://2gis.ru/">2Gis</a>';
