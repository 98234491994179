/* @flow */

import type { TFilterWishes } from '../types/filters';
import type { TAction } from '../types/core';
import {
	LIRA_CHANGE_USER_PROFILE,
	LIRA_GET_USER_PROFILE,
	RESET_ALL_COMPLEX_FILTERS,
	RESET_ALL_FILTERS,
	RESET_WISHES_FILTER,
	SET_UNIVERSAL_LEXS_FILTER,
	SET_WISHES_FILTER,
	SUCCESS,
} from '../constants';
import { separateAdditionalParams } from '../utils/filters';

const initialState: TFilterWishes = {};

const filterWishes = (state: TFilterWishes = initialState, action: TAction): TFilterWishes => {
	switch (action.type) {
		case SET_WISHES_FILTER:
			return { ...state, ...action.payload };

		case RESET_ALL_FILTERS:
		case RESET_ALL_COMPLEX_FILTERS:
		case RESET_WISHES_FILTER:
			return { ...initialState };

		case LIRA_CHANGE_USER_PROFILE + SUCCESS:
		case LIRA_GET_USER_PROFILE + SUCCESS: {
			const { separated: { wishes = {} } = {} } = action.payload;
			return { ...state, ...wishes };
		}

		case SET_UNIVERSAL_LEXS_FILTER: {
			const { filterGroup, data } = action.payload;
			if (filterGroup === 'additionalParams') {
				const { wishes } = separateAdditionalParams(data);
				return { ...state, ...wishes };
			}
			return state;
		}

		default:
			return state;
	}
};

export default filterWishes;
