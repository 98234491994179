import { createGlobalStyle } from 'styled-components';
import noui from 'nouislider/distribute/nouislider.css';

// This is hack to get style from file, cause we are using isomorphic-style-loader now
// We can replace it after migrating to SC
const NoUI = createGlobalStyle`
	${noui._getCss()}

	.noUi-horizontal {
		height: 2px;
		margin-top: 45px;
		border: none;
		background: #d8d8d8;
	}

	.noUi-horizontal .noUi-handle {
		margin-top: -7px;
		border-radius: 50%;
		box-shadow: none;
		box-sizing: border-box;
		padding: 0;
		cursor: pointer;
		width: 15px;
		height: 15px;
		border: none;
		background: #cc3b1f;
		top: 0;
		left: -5px;

		&::before,
		&::after {
			content: none;
		}
	}

	.noUi-tooltip {
		background-color: transparent;
		border: none;
		font-size: 14px;
		font-weight: 500;
		color: #cc3b1f;
	}

	.noUi-connect {
		background: #cc3b1f;
	}

	.noUi-handle-lower {
		.noUi-tooltip {
			top: 100%;
		}
	}
`;

export default NoUI;
