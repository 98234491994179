import styled from 'styled-components';

const linesArray = {
	msk: {
		width: 14,
		height: 10,
		l1: '#8e479b',
		l2: '#4cb85e',
		l3: '#acbfe3',
		l4: '#00bef0',
		l5: '#0078bf',
		l6: '#ffdf80',
		l7: '#ffcb35',
		l8: '#b4d445',
		l9: '#d8ac9d',
		l10: '#79cdcd',
		l11: '#a0a2a3',
		l12: '#f58232',
		l13: '#ed3326',
		l14: '#7a655f',
	},
	spb: {
		width: 12,
		height: 10,
		line1: '#cc2727',
		line2: '#087dcc',
		line3: '#079757',
		line4: '#fbaa32',
		line5: '#6c037a',
	},
	nn: {
		width: 16,
		height: 12,
		line1: '#d92028',
		line2: '#087dcc',
	},
	nsk: {
		width: 11,
		height: 10,
		line1: '#48b75e',
		line2: '#d92028',
	},
	ekb: {
		width: 11,
		height: 10,
		ekb: '#64c299',
	},
	smr: {
		width: 12,
		height: 10,
		smr: '#d92028',
	},
	kzn: {
		width: 16,
		height: 12,
		kzn: '#d92028',
	},
};

const metroExist = (city) => Object.keys(linesArray).some((item) => item === city);

export const MetroIconSvg = styled.svg.attrs((props) => ({
	viewBox:
		props.viewBox ||
		`0 0 ${linesArray[metroExist(props.city) ? props.city : 'msk'].width} ${
			linesArray[metroExist(props.city) ? props.city : 'msk'].height
		}`,
}))`
	flex-shrink: 0;
	vertical-align: baseline;
	width: ${({ city }) => `${linesArray[metroExist(city) ? city : 'msk'].width}px`};
	height: ${({ city }) => `${linesArray[metroExist(city) ? city : 'msk'].height}px`};
	fill: ${({ city, line, theme }) => (metroExist(city) && line ? linesArray[city][line] : theme.primaryColor)};
`;
