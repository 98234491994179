import { HELPFUL_FETCH_CONTENT_DATA, HELPFUL_SCRIPT_LOADED } from '../constants/helpful';
import { CHANGE_USER_CITY, FAIL, START, SUCCESS } from '../constants';
import type { THelpful } from '../types/helpful';

export type THelpfulState = {
	contentData: THelpful,
	loading: boolean,
	lpScriptLoaded: boolean,
};

const initialState = {
	contentData: null,
	loading: false,
	lpScriptLoaded: false,
};

const reducer = (state: THelpfulState = initialState, action) => {
	switch (action.type) {
		case HELPFUL_FETCH_CONTENT_DATA + START:
			return { ...state, loading: true };

		case HELPFUL_FETCH_CONTENT_DATA + SUCCESS:
			return { ...state, contentData: action.payload, loading: false };

		case HELPFUL_FETCH_CONTENT_DATA + FAIL:
			return { ...state, contentData: null, loading: false };

		case CHANGE_USER_CITY:
			return { ...state, contentData: null };

		/* case SET_WINDOW_SIZE:
			return { ...state, windowSize: action.payload }; */

		case HELPFUL_SCRIPT_LOADED:
			return { ...state, lpScriptLoaded: action.payload };

		default:
			return state;
	}
};

export default reducer;
