import styled, { css } from 'styled-components';

export const StyledElement = styled.label`
	display: block;
	position: ${({ error }) => (error ? 'absolute' : 'relative')};
	${({ theme }) => theme.typography.textXS};
	color: ${({ error, theme }) => (error ? '#cc3b1f' : theme.darkGrayColor)};
	${(props) => props.styles}

	&:not(:empty) {
		margin-bottom: ${({ theme }) => theme.fieldLabelMarginBottom || '5px'};
	}
`;
