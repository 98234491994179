import styled, { createGlobalStyle } from 'styled-components';

export const Root = styled.div`
	display: flex;
	flex-direction: column;

	.fslightbox-slide-btn-container,
	.fslightbox-slide-number-container {
		display: none;
	}

	.fslightbox {
		&-toolbar {
			&-button {
				&:first-of-type {
					display: none;
				}
			}
		}

		@media (max-width: 600px) {
			&-source {
				width: 100%;
				height: 100%;

				&-inner {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	@media (max-width: 767px) {
		width: calc(100% + 30px);
		margin: 0 -15px;
		overflow-x: auto;
	}
`;

export const SliderWrapper = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	min-height: 142px;
	position: relative;
	margin-bottom: 40px;
`;

export const SliderWrapperInner = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	.slick {
		&-slider {
			position: relative;
			display: flex;
			flex-direction: column;
		}

		&-list {
			width: calc(100% + 24px);
			margin: 0 -12px;
			overflow: hidden;

			@media (max-width: 1023px) {
				width: calc(100% + 12px);
				margin: 0 -6px;
			}
		}

		&-track {
			display: flex;
		}

		&-slide {
			padding: 0 12px;

			@media (max-width: 1023px) {
				padding: 0 6px;
			}
		}

		&-arrow {
			position: absolute;
			top: 55px;
			z-index: 99;
			width: 32px;
			height: 32px;
			font-size: 0;
			border-radius: 50%;
			box-shadow: none;
			border: none;
			background-color: #fff;
			color: ${({ theme }) => theme.primaryColor};
			transition: color 0.2s, opacity 0.2s;
			cursor: pointer;

			&:hover {
				color: ${({ theme }) => theme.primaryBlackColor};
			}

			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 8px;
				height: 8px;
				border: 2px solid currentColor;
				transform: translate3d(-50%, -50%, 0) rotate(45deg);
			}

			@media (max-width: 1023px) {
				width: 24px;
				height: 24px;

				&::after {
					width: 6px;
					height: 6px;
				}
			}

			&.slick-prev {
				left: 0;
				transform: translateX(-50%);

				&::after {
					margin-left: 2px;
					border-top: none;
					border-right: none;
				}
			}

			&.slick-next {
				right: 0;
				transform: translateX(50%);

				&::after {
					margin-left: -2px;
					border-bottom: none;
					border-left: none;
				}
			}

			&.slick-disabled {
				opacity: 0;
			}
		}

		&-dots {
			display: flex !important;
			justify-content: center;
			list-style: none;
			margin: 16px 0;
			padding: 0;

			li {
				display: block;
				margin: 0 4px;
				line-height: 0;

				button {
					font-size: 0;
					width: 8px;
					height: 8px;
					border-radius: 4px;
					padding: 0;
					box-shadow: none;
					border: none;
					background-color: ${({ theme }) => theme.darkGrayColor};
					transition: background-color 0.2s, width 0.2s;

					&:hover {
						background-color: ${({ theme }) => theme.primaryBlackColor};
					}
				}

				&.slick-active {
					button {
						width: 12px;
						background-color: ${({ theme }) => theme.primaryColor};
					}
				}
			}
		}
	}
`;

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: ${({ $countInRow }) => `repeat(${$countInRow}, 1fr)`};
	grid-gap: 24px;
	height: 142px;

	@media (max-width: 767px) {
		grid-auto-flow: column;
		grid-template-columns: auto;
		justify-content: start;
		margin: 0 15px;
		height: 130px;
		grid-gap: 8px;
	}
`;

export const Story = styled.button`
	position: relative;
	display: flex !important;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	min-height: 142px;
	padding: 16px;
	border-radius: 4px;
	border: none;
	background-color: ${({ $bgColor, theme }) => ($bgColor ? `#${$bgColor}` : theme.darkColor)};
	background-image: ${({ $bgImage }) => ($bgImage ? `url(${$bgImage})` : 'none')};
	background-repeat: no-repeat;
	background-position: left top;
	background-size: cover;
	text-align: left;
	color: #fff;
	cursor: pointer;
	box-sizing: border-box;
	hyphens: auto;
	${({ theme }) => theme.typography.textS};

	@media (max-width: 767px) {
		width: 120px;
		min-height: auto;
	}
`;

export const StoryPoint = styled.span`
	position: absolute;
	top: 16px;
	right: 16px;
	display: block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.toxicLimeColor};
`;

export const StoryContent = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	flex: 1 1 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	padding: 32px 24px;
	box-sizing: border-box;
	background-color: ${({ $bgColor, theme }) => ($bgColor ? `#${$bgColor}` : theme.darkColor)};
	background-image: ${({ $bgImage }) => ($bgImage ? `url(${$bgImage})` : 'none')};
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: #fff;
`;

export const StoryTitle = styled.h3`
	${({ theme }) => theme.typography.headlineS};
`;

export const StoryText = styled.p`
	${({ theme }) => theme.typography.textM};
`;

export const ModalStories = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: ${({ $isShow }) => ($isShow ? 'flex' : 'none')};
	align-items: center;
	justify-content: center;
	z-index: 9000;
`;

export const ModalStoriesOverflow = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(86, 86, 86, 0.75);
	z-index: 1;
`;

export const ModalStoriesContent = styled.div`
	position: relative;
	z-index: 2;
	width: ${({ $width }) => $width ?? '360px'};
	height: ${({ $height }) => $height ?? '640px'};
	overflow: hidden;

	@media (max-width: 600px) {
		width: 100vw;
		height: 100vh;
		height: ${({ theme }) => theme.fullHeight};
	}
`;

export const ModalStoriesWrapper = styled.div`
	display: flex !important;
	width: 100%;
	height: 100%;
`;

export const ModalStoriesClose = styled.button`
	position: absolute;
	z-index: 9001;
	top: 15px;
	right: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	padding: 0;
	margin: 0;
	background: transparent;
	border: none;
	box-shadow: none;
	color: #fff;
	font-size: 36px;
	cursor: pointer;
`;

export const ModalStoriesStyle = createGlobalStyle`
	.modalStories {
		&.slick-slider {
			position: relative;
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
		}
		.slick-track {
			display: flex;
			flex-shrink: 0;
		}
		.slick-slide {
			/*width: 360px;
			height: 640px;

			@media (max-width: 600px) {
				width: 100vw;
				height: 100vh;
				height: ${({ theme }) => theme.fullHeight};
			}*/

			& > div {
				display: flex;
				width: 100%;
				height: 100%;
			}
		}

		@media (max-width: 600px) {
			&.slick-slider {
				height: 100vh;
				max-width: 100%;
			}
			.slick-slide {
				height: 100%;
				max-width: 100%;
			}
		}
	}
`;
