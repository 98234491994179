/* @flow */
import type { TAction } from '../types/core';
import {
	APP_POPUP_WAS_SHOWN,
	FAIL,
	GET_APP_STAT,
	GET_HOMEPAGE_TEXTS,
	GET_LIRA4_TEXTS,
	GET_MORTGAGE_PROGRAMS_STAT,
	LAYOUT_AUTH_NEEDED,
	LIRA_CHANGE_USER_PROFILE,
	PREPARE_CHANGE_CITY,
	RESET_PROFILE_IS_CHANGED,
	SET_CURRENT_CITY,
	SET_MOBILE,
	SET_WINDOW_SIZE,
	SHOW_APP_POPUP,
	SHOW_FOOTER,
	SHOW_SEARCH_POPUP,
	START,
	SUCCESS,
} from '../constants';
import type { TLiraTexts } from '../types/lira4';

export type TMortgageProgramStat = {
	id: number,
	title: string,
	bankProgramCount: number,
	minRate: number,
	maxAge: number,
	maxPeriod: number,
};

export type TAppState = {
	windowSize: number,
	isMobile: boolean,
	authNeeded: boolean,
	liraProfileChanged: boolean,
	showSearch: boolean,
	showFooter: boolean,
	appPopup: {
		show: boolean,
		wasShown: boolean,
	},
	stats: {
		newApartmentCount: number,
		resaleApartmentCount: number,
		allApartmentCount: number,
		lastUpdatedAt: number,
		isLoaded: boolean,
		isLoading: boolean,
		needUpdate: boolean,
	},
	mortgagePrograms: {
		data: TMortgageProgramStat[],
		isLoaded: boolean,
		isLoading: boolean,
	},
	homepageTexts: {
		headerText: {
			text: string,
			title: string,
		},
	},
	liraTexts: TLiraTexts,
};

const initialState: TAppState = {
	windowSize: 0,
	isMobile: false,
	authNeeded: false,
	liraProfileChanged: false,
	showSearch: false,
	showFooter: true,
	appPopup: {
		show: false,
		wasShown: false,
	},
	stats: {
		isLoaded: false,
		isLoading: false,
		needUpdate: true,
	},
	mortgagePrograms: {
		data: [],
		isLoaded: false,
		isLoading: false,
	},
	homepageTexts: null,
	liraTexts: null,
};

const reducer = (state: TAppState = initialState, action: TAction): TAppState => {
	switch (action.type) {
		case SET_WINDOW_SIZE: {
			const clientSize = action.payload;
			let windowSize;
			if (clientSize) {
				if (clientSize < 768) {
					windowSize = 0;
				}
				if (clientSize >= 768 && clientSize < 1024) {
					windowSize = 1;
				}
				if (clientSize >= 1024 && clientSize < 1340) {
					windowSize = 2;
				}
				if (clientSize >= 1340 && clientSize < 1600) {
					windowSize = 3;
				}
				if (clientSize >= 1600 && clientSize < 1800) {
					windowSize = 4;
				}
				if (clientSize >= 1800) {
					windowSize = 5;
				}
			}

			if (windowSize !== state.windowSize) {
				return { ...state, windowSize };
			}
			return state;
		}

		case SET_MOBILE: {
			const clientSize = action.payload;
			let isMobile;
			if (clientSize) {
				if (clientSize <= 600) {
					isMobile = true;
				}
				if (clientSize > 600) {
					isMobile = false;
				}
			}

			if (isMobile !== state.isMobile) {
				return { ...state, isMobile };
			}
			return state;
		}

		case LAYOUT_AUTH_NEEDED:
			return { ...state, authNeeded: action.payload };

		case LIRA_CHANGE_USER_PROFILE + SUCCESS:
			return { ...state, liraProfileChanged: true };

		case RESET_PROFILE_IS_CHANGED:
			return { ...state, liraProfileChanged: false };

		case SHOW_SEARCH_POPUP:
			return { ...state, showSearch: action.payload };

		case SHOW_APP_POPUP:
			return { ...state, appPopup: { ...state.appPopup, show: action.payload } };

		case APP_POPUP_WAS_SHOWN:
			return { ...state, appPopup: { show: false, wasShown: true } };

		case GET_APP_STAT + START:
			return { ...state, stats: { ...state.stats, isLoading: true } };

		case GET_APP_STAT + FAIL:
			return { ...state, stats: { isLoaded: false, isLoading: false, needUpdate: true } };

		case GET_APP_STAT + SUCCESS:
			return { ...state, stats: { ...action.payload, isLoaded: true, isLoading: false, needUpdate: false } };

		case GET_MORTGAGE_PROGRAMS_STAT + START:
			return { ...state, mortgagePrograms: { ...state.mortgagePrograms, isLoading: true } };

		case GET_MORTGAGE_PROGRAMS_STAT + FAIL:
			return { ...state, mortgagePrograms: { isLoaded: false, isLoading: false } };

		case GET_MORTGAGE_PROGRAMS_STAT + SUCCESS:
			return { ...state, mortgagePrograms: { data: action.payload, isLoaded: true, isLoading: false } };

		case SET_CURRENT_CITY:
		case PREPARE_CHANGE_CITY:
			return { ...state, stats: { ...state.stats, needUpdate: true } };

		case GET_HOMEPAGE_TEXTS:
			return { ...state, homepageTexts: action.payload };

		case GET_LIRA4_TEXTS:
			return { ...state, liraTexts: action.payload };

		case SHOW_FOOTER:
			return { ...state, showFooter: action.payload };

		default:
			return state;
	}
};

export { initialState as appInitialState };
export default reducer;
