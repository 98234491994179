/* @flow */
import {
	SET_TOKEN,
	DELETE_TOKEN,
	LOGIN_FIRST_STAGE,
	START,
	SUCCESS,
	FAIL,
	LOGIN_SECOND_STAGE,
	CHANGE_TOKEN,
	SET_IS_BOT,
} from '../constants';
import type { TToken } from '../types/account';
import type { TTokenData } from '../types/core';
import { stringToBoolean } from '../utils/formatting';

const initialState: TToken = {
	token: null,
	tokenExpires: null,
	refreshToken: null,
	isAnonymous: true,
	isBot: false,
	baseToken: null,
	code: '',
	reCaptchaToken: '',
	reCaptchaTime: null,
};

const getAnonymous = (val): boolean => {
	if (typeof val === 'undefined') return undefined;
	if (typeof val === 'string') return stringToBoolean(val);
	return val;
};

const token = (state: TToken = initialState, action): TToken => {
	const { type, payload } = action;

	switch (type) {
		case SET_TOKEN: {
			const isAnonymous = getAnonymous(payload.isAnonymous);
			return {
				...state,
				...(payload?.token ? { token: payload.token } : {}),
				...(payload?.tokenExpires ? { tokenExpires: payload.tokenExpires } : {}),
				...(payload?.refreshToken ? { refreshToken: payload?.refreshToken } : {}),
				...(typeof isAnonymous !== 'undefined' ? { isAnonymous } : {}),
			};
		}

		case CHANGE_TOKEN:
			return { ...state, ...payload };

		case LOGIN_FIRST_STAGE + START:
			return { ...state, baseToken: null };

		case LOGIN_FIRST_STAGE + SUCCESS:
			return { ...state, baseToken: action.payload };

		case LOGIN_FIRST_STAGE + FAIL:
			return { ...state, baseToken: null };

		case LOGIN_SECOND_STAGE + START:
			return state;

		case LOGIN_SECOND_STAGE + SUCCESS: {
			const { token: newToken, expires: tokenExpires, isAnonymous }: TTokenData = action.payload;
			return {
				...state,
				token: newToken,
				tokenExpires,
				isAnonymous,
				baseToken: null,
				code: '',
			};
		}

		case LOGIN_SECOND_STAGE + FAIL:
			return { ...state, /* baseToken: null, */ code: '' };

		case SET_IS_BOT:
			return { ...state, isBot: payload };

		case DELETE_TOKEN:
			return initialState;

		default:
			return state;
	}
};

export default token;
export { initialState as tokenInitialState };
