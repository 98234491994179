/* @flow */
import React from 'react';
import type { Node } from 'react';
import { useReferralId, useUserLocation } from '../utils/hooks';

const App = ({ children }: Node): Node => {
	useReferralId();
	useUserLocation();

	return React.Children.only(children);
};

export default App;
