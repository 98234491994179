export const SECTIONS = [
	{ name: 'territory', title: 'На территории ЖК' },
	{ name: 'infrastructure', title: 'Инфраструктура' },
	{ name: 'purchaseMethod', title: 'Способ оплаты' },
	{ name: 'location', title: 'Расположение' },
	{ name: 'goodForPets', title: 'Для питомцев' },
	{ name: 'goodForCarOwners', title: 'Для автовладельцев' },
	{ name: 'security', title: 'Безопасность' },
	{ name: 'house', title: 'Дом' },
	{ name: 'floor', title: 'Этаж' },
	{ name: 'layout', title: 'Планировка' },
	{ name: 'apartAdditionals', title: 'Особенности квартиры' },
	{ name: 'finishing', title: 'Отделка' },
	{ name: 'developer', title: 'Застройщик' },
	{ name: 'windowAngle', title: 'Направление окон' },
];

export default {
	// На территории ЖК
	territory: [
		{ title: 'Детская площадка', value: 'hasChildrenPlayground', get: 'chpgn' },
		{ title: 'Уличные тренажеры и спортивные площадки', value: 'hasOutdoorFitness', get: 'outfts' },
		{ title: 'Стадион', value: 'hasStadium', get: 'stm' },
		{ title: 'Велопарковка', value: 'hasBikeParking', get: 'bike' },
		{
			title: 'Особые объекты благоустройства (пруды, фонтаны, перголы, БАФы)',
			value: 'hasSpecialImprovementObjects',
			get: 'ivt',
		},
		{ title: 'Ландшафтный дизайн', value: 'hasLandscapeDesign', get: 'lnddsn' },
		{ title: 'Беседки, места для барбекю', value: 'hasPlacesForAdults', get: 'pladl' },
		{ title: 'Парковая зона / аллея для прогулок / сквер', value: 'isParkArea', get: 'parkar' },
		{ title: 'Доступный вход для маломобильной группы населения', value: 'hasAccessibleEntrance', get: 'asent' },
		{ title: 'Кладовые', value: 'hasPantry', get: 'pntr' },
	],
	// Инфраструктура
	infrastructure: [
		{ title: 'Близко к парку', value: 'isCloseToPark', get: 'clpark' },
		{ title: 'Близко к спортивному комплексу', value: 'isCloseToSportFacility', get: 'clsrt' },
		{ title: 'Близко к поликлинике', value: 'isCloseToPolyclinic', get: 'clpol' },
		{ title: 'Близко к аптеке', value: 'isCloseToPharmacy', get: 'clphm' },
		{ title: 'Близко к супермаркету', value: 'isCloseToSupermarket', get: 'clmkt' },
		{ title: 'Близко к детскому саду', value: 'isCloseToKindergarten', get: 'clkrg' },
		{ title: 'Близко к школе', value: 'isCloseToSchool', get: 'clshl' },
		{ title: 'Близко к детской поликлинике', value: 'isCloseToChildrenPolyclinic', get: 'clchpol' },
		{ title: 'Близко к торговому центру', value: 'isCloseToShoppingMall', get: 'clmall' },
	],
	// Для прогулок с питомцами
	goodForPets: [
		{ title: 'Комната для мытья лап домашних питомцев', value: 'hasPawsWashingRoom', get: 'pwswsg' },
		{ title: 'Площадки для выгула собак', value: 'hasGroundsForWalkingDogs', get: 'gndwalk' },
	],
	// Для автовладельцев
	goodForCarOwners: [
		{ title: 'Обеспеченность машиноместами', value: 'hasEnoughParkingPlaces', get: 'prnpls' },
		{ title: 'Крытый паркинг', value: 'hasCoveredParking', get: 'cvdpkg' },
		{ title: 'Подземный паркинг', value: 'hasUndergroundParking', get: 'undpkg' },
		{ title: 'Платная автостоянка', value: 'hasPaidParking', get: 'paidpkg' },
	],
	// Безопасность
	security: [
		{ title: 'Система видеонаблюдения', value: 'hasVideoSurveillanceSystem', get: 'video' },
		{ title: 'Закрытый от машин двор', value: 'hasClosedYard', get: 'cldyard' },
		{ title: 'Круглосуточный пост', value: 'hasAllDayProtectionPost', get: 'prcn' },
		{ title: 'Ограждение территории', value: 'hasTerritoryEnclosure', get: 'trtecl' },
	],
	// Способ оплаты
	purchaseMethod: [
		{ title: '100% оплата', value: 'isFullPaymentAvailable', get: 'fullpmt' },
		{ title: 'Ипотека', value: 'hasMortgage', get: 'mtg' },
		{ title: 'Материнский капитал', value: 'hasMaternityCapital', get: 'matc' },
		{ title: 'Военная ипотека', value: 'hasMilitaryMortgage', get: 'milm' },
	],
	// Расположение
	location: [
		{ title: 'Близко к метро', value: 'isCloseToMetro', get: 'clmtr' },
		{ title: 'Близко к центру', value: 'isCloseToCenter', get: 'clcnr' },
	],
	// Дом
	house: [
		{ title: 'Кирпичный', value: 'isTechnologyBrick', get: 'tchbrk' },
		{ title: 'Кирпично-монолитный', value: 'isTechnologyBrickMonolith', get: 'tchbrkmth' },
		{ title: 'Панельный', value: 'isTechnologyPanel', get: 'tchpnl' },
		{ title: 'Монолитный', value: 'isTechnologyMonolith', get: 'tchmth' },
		{ title: 'Малоэтажный', value: 'isLowRise', get: 'lrise' },
		{ title: 'Многоэтажный', value: 'isHighRise', get: 'hrise' },
		{ title: 'Есть колясочные', value: 'hasStrollers', get: 'strls' },
		{ title: 'Есть консьерж', value: 'hasConcierge', get: 'conc' },
		{ title: 'Умный дом', value: 'hasSmartHouse', get: 'smart' },
		{
			title: 'Вертикальная внутриквартирная разводка труб',
			value: 'isVerticalPipeRouting',
			get: '',
		},
		{
			title: 'Горизонтальная внутриквартирная разводка труб',
			value: 'isHorizontalPipeRouting',
			get: '',
		},
	],
	// Этаж
	floor: [
		{ title: 'Верхние этажи', value: 'isHighFloor', get: 'hiflr' },
		{ title: 'Нижние этажи', value: 'isLowFloor', get: 'lowflr' },
		{ title: 'Не первый этаж', value: 'isNotFirstFloor', get: 'notfst' },
		// { title: 'Не последний этаж', value: 'isNotLastFloor' },
	],
	// Планировка
	layout: [
		{ title: 'Европланировка', value: 'isEuroPlanning', get: 'euro' },
		{ title: 'Классическая', value: 'isClassicPlanning', get: 'cls' },
		{ title: 'Свободная планировка', value: 'isFreePlanning', get: 'free' },
		{ title: 'Окна на одну сторону', value: 'hasWindowsOnSingleDirection', get: 'wndsngl' },
		{ title: 'Окна на несколько сторон', value: 'hasWindowsOnMultipleDirections', get: 'wndmlt' },
		{ title: 'Окна на противоположные стороны', value: 'hasWindowsOnOppositeDirections', get: 'wndopp' },
		{ title: 'С изолированными комнатами', value: 'hasIsolatedRooms', get: 'iso', order: 7 },
		{ title: 'Со смежными комнатами', value: 'hasAdjacentRooms', get: 'adj', order: 7 },
		{ title: 'Совмещенный санузел', value: 'hasAdjacentBathroom', get: 'adjbtm', order: 6 },
		{ title: 'Раздельный санузел', value: 'hasSeparateBathroom', get: 'sepbtm', order: 6 },
		{ title: 'Несколько санузлов', value: 'hasMultipleBathroom', get: 'multbtm', order: 6 },
		{ title: 'Есть балкон', value: 'hasBalcony', get: 'balc' },
		{ title: 'Есть лоджия', value: 'hasLoggia', get: 'logg' },
		{ title: 'Есть терраса', value: 'hasTerrace', get: 'terr' },
		{ title: 'Есть эркер', value: 'hasBayWindow', get: 'baywnd' },
		{ title: 'Балкон из кухни', value: 'hasLoggiaKitchen', get: '' },
		{ title: 'Балкон из комнаты', value: 'hasLoggiaRoom', get: '' },
		{ title: 'Вентшахты - нет ', value: 'hasNoAirShaft', get: '' },
	],
	// Особенности квартиры
	apartAdditionals: [
		{ title: 'Сауна', value: 'hasSauna', get: 'sauna', order: 15 },
		{ title: 'Прачечная/постирочная', value: 'hasLaundry', get: 'laund', order: 12 },
		{ title: 'Спальня с ванной комнатой', value: 'hasRoomWithBathroom', get: 'rmbath', order: 11 },
		{ title: 'Несколько окон на кухне', value: 'hasMultipleWindowKitchen', get: 'mlpwnwkchn', order: 13 },
		{ title: 'Несколько окон в комнате', value: 'hasMultipleWindowRoom', get: 'mlpwnwrm', order: 14 },
		{ title: 'Есть гардеробные в коридоре', value: 'hasPantryCorridor', get: 'pntcor', order: 9 },
		{ title: 'Есть гардеробная в спальне', value: 'hasPantryRoom', get: 'pntroom', order: 10 },
		{ title: 'Большая кухня', value: 'isBigKitchen', get: 'bigktn', order: 4 },
		{ title: 'Большая квартира', value: 'isBigFlat', get: 'areaTotal', order: 16 },
		{ title: 'Маленькая квартира', value: 'isSmallFlat', get: 'areaTotal', order: 17 },
		{ title: 'Высокие потолки', value: 'hasHighCeiling', get: 'hceil', order: 5 },
	],
	// Отделка
	finishing: [
		{ title: 'Без отделки', value: 'hasNoFinishing', get: 'nofnh' },
		{ title: 'Предчистовая', value: 'hasPreFinishing', get: 'prefnh' },
		{ title: 'Чистовая', value: 'hasFineFinishing', get: 'finefnh' },
	],
	// Застройщик
	developer: [{ title: 'Надёжный застройщик', value: 'isReliableBuilder', get: 'isReliableBuilder' }],
	// Направление окон
	windowAngle: [
		{ title: 'Восток', value: 'hasEastWindow', get: 'windowAngle' },
		{ title: 'Запад', value: 'hasWestWindow', get: 'windowAngle' },
		{ title: 'Север', value: 'hasNorthWindow', get: 'windowAngle' },
		{ title: 'Северо-Восток', value: 'hasNorthEastWindow', get: 'windowAngle' },
		{ title: 'Северо-Запад', value: 'hasNorthWestWindow', get: 'windowAngle' },
		{ title: 'Юг', value: 'hasSouthWindow', get: 'windowAngle' },
		{ title: 'Юго-Восток', value: 'hasSouthEastWindow', get: 'windowAngle' },
		{ title: 'Юго-Запад', value: 'hasSouthWestWindow', get: 'windowAngle' },
		{ title: 'Большое кол-во сторон света', value: 'hasWindowsOnMultipleDirections', get: 'windowAngle' },
	],
};
