/* @flow */

import React, { memo } from 'react';
import layoutImage from '../../../../../public/images/home/cards/layout.png';
import layoutImage2x from '../../../../../public/images/home/cards/layout@2x.png';

import theme from '../../../../theme/index';
import { Card, CardImage, CardList, CardListItem, CardTitle, MicroCardIcon } from '../../styles';
import { microCardIcon } from '../../../../components/Icons';
import { formatValuesNumber } from '../../../../utils/formatting';

const fakeStats = [
	{
		cian: 1276,
		avito: 1115,
		devs: 68,
	},
	{
		cian: 1558,
		avito: 1920,
		devs: 161,
	},
	{
		cian: 2502,
		avito: 2210,
		devs: 1782,
	},
	{
		cian: 2825,
		avito: 2622,
		devs: 1271,
	},
	{
		cian: 4433,
		avito: 2702,
		devs: 1336,
	},
	{
		cian: 4694,
		avito: 3131,
		devs: 1453,
	},
	{
		cian: 578,
		avito: 982,
		devs: 806,
	},
];

type TProps = {
	index: number,
};

export const GreenCard = memo(({ index }: TProps) => {
	const date = new Date();
	const dayOfWeek = date.getDay();
	const day = date.getDate();

	return (
		<Card $color="green" index={index}>
			<CardTitle>Загружено сегодня по России</CardTitle>
			<CardList>
				<CardListItem $color={theme.primaryColor}>
					<span>
						Циан<MicroCardIcon>{microCardIcon}</MicroCardIcon>
						<b>{formatValuesNumber(fakeStats[dayOfWeek].cian * 10 + day + 9, 'custom', '')}</b>
					</span>
				</CardListItem>
				<CardListItem $color={theme.baseGrayColor}>
					<span>
						Авито<MicroCardIcon>{microCardIcon}</MicroCardIcon>
						<b>{formatValuesNumber(fakeStats[dayOfWeek].avito * 10 + day + 5, 'custom', '')}</b>
					</span>
				</CardListItem>
				<CardListItem $color={theme.greenColor}>
					<span>
						Застройщики<MicroCardIcon>{microCardIcon}</MicroCardIcon>
						<b>{formatValuesNumber(fakeStats[dayOfWeek].devs * 10 + day + 1, 'custom', '')}</b>
					</span>
				</CardListItem>
			</CardList>
			<CardImage>
				<img src={layoutImage} srcSet={`${layoutImage}, ${layoutImage2x} 2x`} alt="" />
			</CardImage>
		</Card>
	);
});
