export default {
	spb: {
		line2: [
			{
				circle: { cx: '227.5', cy: '11.5' },
				text: { x: '239', y: '14.5', title: 'Парнас' },
				id: 284,
			},
			{
				circle: { cx: '227.5', cy: '32' },
				text: { x: '239', y: '35', title: 'Пр-т Просвещения' },
				id: 302,
			},
			{
				circle: { cx: '227.5', cy: '52' },
				text: { x: '239', y: '55', title: 'Озерки' },
				id: 278,
			},
			{
				circle: { cx: '227.5', cy: '72' },
				text: { x: '239', y: '75', title: 'Удельная' },
				id: 312,
			},
			{
				circle: { cx: '227.5', cy: '92' },
				text: { x: '239', y: '95', title: 'Пионерская' },
				id: 289,
			},
			{
				circle: { cx: '227.5', cy: '111' },
				text: { x: '239', y: '115', title: 'Чёрная речка' },
				id: 316,
			},
			{
				circle: { cx: '227.5', cy: '132' },
				text: { x: '239', y: '136', title: 'Петроградская' },
				id: 287,
			},
			{
				circle: { cx: '227.5', cy: '153' },
				text: { x: '239', y: '157', title: 'Горьковская' },
				id: 227,
			},
			{
				circle: { cx: '227.5', cy: '183' },
				text: { x: '144', y: '181', title: 'Невский пр-т' },
				id: 9,
				transferId: 3,
			},
			{
				circle: { cx: '227.5', cy: '260' },
				text: { x: '126', y: '266', title: 'Сенная площадь' },
				id: 10,
				transferId: 1,
			},
			{
				circle: { cx: '227.5', cy: '329' },
				text: { x: '72', y: '315', title: 'Технологический институт - I' },
				id: 845,
				transferId: 7,
			},
			{
				circle: { cx: '227.5', cy: '376' },
				text: { x: '239', y: '379', title: 'Фрунзенская' },
				id: 314,
			},
			{
				circle: { cx: '227.5', cy: '396' },
				text: { x: '239', y: '399', title: 'Московские ворота' },
				id: 325,
			},
			{
				circle: { cx: '227.5', cy: '416' },
				text: { x: '239', y: '419', title: 'Электросила' },
				id: 323,
			},
			{
				circle: { cx: '227.5', cy: '436' },
				text: { x: '239', y: '439', title: 'Парк Победы' },
				id: 282,
			},
			{
				circle: { cx: '227.5', cy: '456.5' },
				text: { x: '239', y: '459.5', title: 'Московская' },
				id: 269,
			},
			{
				circle: { cx: '227.5', cy: '476.5' },
				text: { x: '239', y: '479.5', title: 'Звёздная' },
				id: 240,
			},
			{
				circle: { cx: '227.5', cy: '497' },
				text: { x: '239', y: '500', title: 'Купчино' },
				id: 249,
			},
		],
		line5: [
			{
				circle: { cx: '86.5', cy: '11.5' },
				text: { x: '98', y: '14.5', title: 'Комендантский пр-т' },
				id: 244,
			},
			{
				circle: { cx: '86.5', cy: '32' },
				text: { x: '98', y: '35', title: 'Старая деревня' },
				id: 309,
			},
			{
				circle: { cx: '86.5', cy: '72.5' },
				text: { x: '98', y: '75.5', title: 'Крестовский остров' },
				id: 247,
			},
			{
				circle: { cx: '86.5', cy: '111' },
				text: { x: '98', y: '115', title: 'Чкаловская' },
				id: 320,
			},
			{
				circle: { cx: '86.5', cy: '132.5' },
				text: { x: '98', y: '135.5', title: 'Спортивная' },
				id: 327,
			},
			{
				circle: { cx: '144', cy: '221' },
				text: { x: '45', y: '231', title: 'Адмиралтейская' },
				id: 216,
			},
			{
				circle: { cx: '221', cy: '251' },
				text: { x: '173', y: '241', title: 'Садовая' },
				id: 328,
				transferId: '1',
			},
			{
				circle: { cx: '307.5', cy: '331.5' },
				text: { x: '325', y: '333', title: 'Звенигородская' },
				id: 326,
				transferId: '4',
			},
			{
				circle: { cx: '354.5', cy: '378' },
				text: { x: '366', y: '380', title: 'Обводный канал' },
				id: 276,
			},
			{
				circle: { cx: '385.5', cy: '433' },
				text: { x: '396', y: '436', title: 'Волковская' },
				id: 836,
			},
			{
				circle: { cx: '385.5', cy: '454' },
				text: { x: '396', y: '457', title: 'Бухарестская' },
				id: 835,
			},
			{
				circle: { cx: '385.5', cy: '495' },
				text: { x: '396', y: '498', title: 'Международная' },
				id: 268,
			},
		],
		line3: [
			{
				circle: { cx: '23', cy: '109' },
				text: { x: '-27', y: '111', title: 'Беговая' },
				id: 912,
			},
			{
				circle: { cx: '23', cy: '129' },
				text: { x: '-73', y: '131', title: 'Новокрестовская' },
				id: 913,
			},
			{
				circle: { cx: '23', cy: '149' },
				text: { x: '-50', y: '151', title: 'Приморская' },
				id: 295,
			},
			{
				circle: { cx: '64', cy: '189.5' },
				text: { x: '4', y: '208', title: 'Василеостровская' },
				id: 225,
			},
			{
				circle: { cx: '227.5', cy: '194' },
				text: { x: '236', y: '207', title: 'Гостиный двор' },
				id: 229,
				transferId: 3,
			},
			{
				circle: { cx: '362', cy: '191' },
				text: { x: '299', y: '181', title: 'Маяковская' },
				id: 267,
				transferId: 2,
			},
			{
				circle: { cx: '442.5', cy: '259.5' },
				text: { x: '436', y: '226', title: 'Площадь Александра Невского - I' },
				id: 219,
				transferId: 219,
			},

			{
				circle: { cx: '476.5', cy: '393' },
				text: { x: '488', y: '396', title: 'Елизаровская' },
				id: 239,
			},
			{
				circle: { cx: '476.5', cy: '413' },
				text: { x: '488', y: '416', title: 'Ломоносовская' },
				id: 264,
			},
			{
				circle: { cx: '476.5', cy: '434' },
				text: { x: '488', y: '437', title: 'Пролетарская' },
				id: 297,
			},
			{
				circle: { cx: '476.5', cy: '454' },
				text: { x: '488', y: '457', title: 'Обухово' },
				id: 795,
			},
			{
				circle: { cx: '476.5', cy: '475' },
				text: { x: '488', y: '478', title: 'Рыбацкое' },
				id: 306,
			},
		],
		line1: [
			{
				circle: { cx: '374', cy: '11.5' },
				text: { x: '385', y: '14.5', title: 'Девяткино' },
				id: 233,
			},
			{
				circle: { cx: '374', cy: '31' },
				text: { x: '385', y: '34', title: 'Гражданский пр-т' },
				id: 230,
			},
			{
				circle: { cx: '374', cy: '50' },
				text: { x: '385', y: '53', title: 'Академическая' },
				id: 218,
			},
			{
				circle: { cx: '374', cy: '69' },
				text: { x: '385', y: '71', title: 'Политехническая' },
				id: 838,
			},
			{
				circle: { cx: '374', cy: '87' },
				text: { x: '385', y: '90', title: 'Площадь Мужества' },
				id: 293,
			},
			{
				circle: { cx: '374', cy: '106' },
				text: { x: '385', y: '109', title: 'Лесная' },
				id: 260,
			},
			{
				circle: { cx: '374', cy: '126' },
				text: { x: '385', y: '132', title: 'Выборгская' },
				id: 324,
			},
			{
				circle: { cx: '374', cy: '144' },
				text: { x: '385', y: '150', title: 'Площадь Ленина' },
				id: 292,
			},
			{
				circle: { cx: '374', cy: '167' },
				text: { x: '385', y: '170', title: 'Чернышевская' },
				id: 318,
			},
			{
				circle: { cx: '374', cy: '191' },
				text: { x: '385', y: '194', title: 'Площадь Восстания' },
				id: 291,
				transferId: 2,
			},
			{
				circle: { cx: '323', cy: '241' },
				text: { x: '336', y: '242', title: 'Владимирская' },
				id: 839,
				transferId: 5,
			},
			{
				circle: { cx: '316', cy: '340' },
				text: { x: '248', y: '355', title: 'Пушкинская' },
				id: 840,
				transferId: 4,
			},
			{
				circle: { cx: '227.5', cy: '341' },
				text: { x: '72', y: '332', title: 'Технологический институт - II' },
				id: 846,
				transferId: 7,
			},
			{
				circle: { cx: '161', cy: '368' },
				text: { x: '83', y: '369', title: 'Балтийская' },
				id: 221,
			},
			{
				circle: { cx: '143', cy: '386' },
				text: { x: '79', y: '386', title: 'Нарвская' },
				id: 273,
			},
			{
				circle: { cx: '106', cy: '424' },
				text: { x: '3', y: '427', title: 'Кировский завод' },
				id: 841,
			},
			{
				circle: { cx: '106', cy: '444' },
				text: { x: '51', y: '447', title: 'Автово' },
				id: 213,
			},
			{
				circle: { cx: '106', cy: '464' },
				text: { x: '10', y: '467', title: 'Ленинский пр-т' },
				id: 258,
			},
			{
				circle: { cx: '106', cy: '484' },
				text: { x: '11', y: '487', title: 'Пр-т Ветеранов' },
				id: 300,
			},
		],
		line4: [
			{
				circle: { cx: '513', cy: '359' },
				text: { x: '526', y: '362', title: 'Ул. Дыбенко' },
				id: 236,
			},
			{
				circle: { cx: '513', cy: '338' },
				text: { x: '526', y: '341', title: 'Пр-т Большевиков' },
				id: 222,
			},
			{
				circle: { cx: '513', cy: '318' },
				text: { x: '526', y: '321', title: 'Ладожская' },
				id: 252,
			},
			{
				circle: { cx: '513', cy: '298' },
				text: { x: '526', y: '298', title: 'Новочеркасская' },
				id: 274,
			},
			{
				circle: { cx: '434', cy: '251' },
				text: { x: '436', y: '243', title: 'Площадь Александра Невского - II' },
				id: 844,
				transferId: 219,
			},
			{
				circle: { cx: '379', cy: '251' },
				text: { x: '339', y: '266', title: 'Лиговский пр-т' },
				id: 263,
			},
			{
				circle: { cx: '316', cy: '251' },
				text: { x: '248', y: '265', title: 'Достоевская' },
				id: 842,
				transferId: 5,
			},
			{
				circle: { cx: '233', cy: '251' },
				text: { x: '238', y: '241', title: 'Спасская' },
				id: 843,
				transferId: 1,
			},
		],
	},
	msk: {
		l1: [
			{
				circle: { cx: '259.7', cy: '98.6' },
				text: [{ transform: 'matrix(1 0 0 1 276.175 104.1076)', title: 'Планерная' }],
				id: 691,
			},
			{
				circle: { cx: '259.7', cy: '137.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 276.1748 146.5441)',
						title: 'Сходненская',
					},
				],
				id: 735,
			},
			{
				circle: { cx: '259.7', cy: '187.7' },
				text: [{ transform: 'matrix(1 0 0 1 276.1748 191.5656)', title: 'Тушинская' }],
				id: 755,
			},
			{
				circle: { cx: '259.7', cy: '231' },
				text: [{ transform: 'matrix(1 0 0 1 276.1747 234.6193)', title: 'Спартак' }],
				id: 731,
			},
			{
				circle: { cx: '259.7', cy: '273.9' },
				text: [{ transform: 'matrix(1 0 0 1 276.1747 278.0519)', title: 'Щукинская' }],
				id: 778,
			},
			{
				circle: { cx: '259.7', cy: '462.9' },
				text: [
					{ transform: 'matrix(1 0 0 1 269.174 435.5802)', title: 'Октябрьское' },
					{ transform: 'matrix(1 0 0 1 269.174 453.5802)', title: 'Поле' },
				],
				id: 676,
			},
			{
				circle: { cx: '330.3', cy: '583.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 303.7668 565.7773)',
						title: 'Полежаевская',
					},
				],
				id: 695,
			},
			{
				circle: { cx: '396', cy: '648.6' },
				text: [{ transform: 'matrix(1 0 0 1 316.0727 666.7081)', title: 'Беговая' }],
				id: 575,
			},
			{
				circle: { cx: '434.6', cy: '686.6' },
				text: [
					{ transform: 'matrix(1 0 0 1 373.2399 705.415)', title: 'Улица' },
					{ transform: 'matrix(1 0 0 1 337.0203 723.415)', title: '1905 года' },
				],
				id: 759,
			},
			{
				circle: { cx: '525', cy: '703.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 521.3383 688.3956)',
						title: 'Баррикадная',
					},
				],
				id: 573,
			},
			{
				circle: { cx: '739', cy: '700.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 684.4379 685.3917)',
						title: 'Пушкинская',
					},
				],
				id: 704,
			},
			{
				circle: { cx: '920.7', cy: '703.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 820.6027 688.0841)',
						title: 'Кузнецкий Мост',
					},
				],
				id: 831,
			},
			{
				circle: { cx: '1050.1', cy: '783.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 948.6375 809.1466)',
						title: 'Китай-город',
					},
				],
				id: 906,
			},
			{
				circle: { cx: '1186.2', cy: '920.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1080.2871 919.1007)',
						title: 'Таганская',
					},
				],
				id: 907,
			},
			{
				circle: { cx: '1299.4', cy: '1032' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1315.1716 1034.8458)',
						title: 'Пролетарская',
					},
				],
				id: 791,
			},
			{
				circle: { cx: '1354', cy: '1086.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1362.7771 1073.3615)',
						title: 'Волгоградский проспект',
					},
				],
				id: 598,
			},
			{
				circle: { cx: '1424', cy: '1155.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1437.7842 1143.2062)',
						title: 'Текстильщики',
					},
				],
				id: 740,
			},
			{
				circle: { cx: '1448', cy: '1209.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1463.7871 1213.7296)',
						title: 'Кузьминки',
					},
				],
				id: 638,
			},
			{
				circle: { cx: '1448', cy: '1279.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1463.7871 1284.1202)',
						title: 'Рязанский',
					},
					{ transform: 'matrix(1 0 0 1 1463.7871 1302.1202)', title: 'проспект' },
				],
				id: 717,
			},
			{
				circle: { cx: '1448', cy: '1368.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1463.7871 1372.382)',
						title: 'Выхино',
					},
				],
				id: 603,
			},
			{
				circle: { cx: '1448', cy: '1438.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1463.7871 1442.4728)',
						title: 'Лермонтовский',
					},
					{ transform: 'matrix(1 0 0 1 1463.7871 1460.4728)', title: 'проспект' },
				],
				id: 643,
			},
			{
				circle: { cx: '1448', cy: '1526.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1463.7871 1530.4767)',
						title: 'Жулебино',
					},
				],
				id: 613,
			},
			{
				circle: { cx: '1448', cy: '1597.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1463.7871 1601.9034)',
						title: 'Котельники',
					},
				],
				id: 628,
			},
		],
		l2: [
			{
				circle: { cx: '466.2', cy: '99.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 482.4517 104.3849)',
						title: 'Речной вокзал',
					},
				],
				id: 709,
			},
			{
				circle: { cx: '466.8', cy: '149.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 483.0045 152.9191)',
						title: 'Водный стадион',
					},
				],
				id: 596,
			},
			{
				circle: { cx: '466.8', cy: '278.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 481.0897 286.708)',
						title: 'Войковская',
					},
				],
				id: 597,
			},
			{
				circle: { cx: '466.8', cy: '344.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 483.5825 348.92)',
						title: 'Сокол',
					},
				],
				id: 729,
			},
			{
				circle: { cx: '466.8', cy: '385.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 482.5823 388.6573)',
						title: 'Аэропорт',
					},
				],
				id: 569,
			},
			{
				circle: { cx: '466.2', cy: '435.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 481.5367 429.7149)',
						title: 'Динамо',
					},
				],
				id: 606,
			},
			{
				circle: { cx: '552.4', cy: '559.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 431.056 581.7804)',
						title: 'Белорусская',
					},
				],
				id: 576,
			},
			{
				circle: { cx: '605.6', cy: '612.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 615.254 605.2765)',
						title: 'Маяковская',
					},
				],
				id: 789,
			},
			{
				circle: { cx: '721.6', cy: '731.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 638.1973 755.6671)',
						title: 'Тверская',
					},
				],
				id: 834,
			},
			{
				circle: { cx: '859.7', cy: '841.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 868.6934 834.8058)',
						title: 'Театральная',
					},
				],
				id: 739,
			},
			{
				circle: { cx: '983', cy: '973.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 997.355 973.7472)',
						title: 'Новокузнецкая',
					},
				],
				id: 669,
			},
			{
				circle: { cx: '981.9', cy: '1135.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 997.3616 1139.2882)',
						title: 'Павелецкая',
					},
				],
				id: 679,
			},
			{
				circle: { cx: '981.7', cy: '1322.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 998.2009 1326.3195)',
						title: 'Автозаводская',
					},
				],
				id: 559,
			},
			{
				circle: { cx: '981.7', cy: '1428.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 998.8286 1432.6603)',
						title: 'Технопарк',
					},
				],
				id: 746,
			},
			{
				circle: { cx: '981.7', cy: '1482.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 998.8286 1486.4249)',
						title: 'Коломенская',
					},
				],
				id: 625,
			},
			{
				circle: { cx: '981.9', cy: '1540.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 998.8711 1543.2433)',
						title: 'Каширская',
					},
				],
				id: 812,
			},
			{
				circle: { cx: '981.7', cy: '1605.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 997.5642 1608.4894)',
						title: 'Кантемировская',
					},
				],
				id: 620,
			},
			{
				circle: { cx: '981.7', cy: '1650.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 998.2009 1654.6729)',
						title: 'Царицыно',
					},
				],
				id: 767,
			},
			{
				circle: { cx: '981.7', cy: '1707.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 997.8655 1711.9316)',
						title: 'Орехово',
					},
				],
				id: 677,
			},
			{
				circle: { cx: '981.7', cy: '1762.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 999.0198 1767.9561)',
						title: 'Домодедовская',
					},
				],
				id: 609,
			},
			{
				circle: { cx: '1193.1', cy: '1827.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1044.5129 1854.5488)',
						title: 'Красногвардейская',
					},
				],
				id: 631,
			},
			{
				circle: { cx: '1341.8', cy: '1825.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1323.6401 1852.7725)',
						title: 'Алма-Атинская',
					},
				],
				id: 803,
			},
		],
		l3: [
			{
				circle: { cx: '540.1', cy: '1770.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 401.965 1797.167)',
						title: 'Битцевский парк',
					},
				],
				id: 814,
			},
			{
				circle: { cx: '638.3', cy: '1770.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 577.09 1798.0146)',
						title: 'Лесопарковая',
					},
				],
				id: 807,
			},
			{
				circle: { cx: '721.5', cy: '1837.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 491.0234 1842.2344)',
						title: 'Улица Старокачаловская',
					},
				],
				id: 805,
			},
			{
				circle: { cx: '648.9', cy: '1880.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 635.6417 1910.3184)',
						title: 'Улица',
					},
					{
						transform: 'matrix(1 0 0 1 635.6417 1928.3184)',
						title: 'Скобелевская',
					},
				],
				id: 762,
			},
			{
				circle: { cx: '539', cy: '1880.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 524.8749 1910.3174)',
						title: 'Бульвар',
					},
					{
						transform: 'matrix(1 0 0 1 524.8749 1928.3174)',
						title: 'Адмирала',
					},
					{
						transform: 'matrix(1 0 0 1 524.8749 1946.5674)',
						title: 'Ушакова',
					},
				],
				id: 587,
			},
			{
				circle: { cx: '422.3', cy: '1880.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 406.0297 1910.4424)',
						title: 'Улица',
					},
					{
						transform: 'matrix(1 0 0 1 406.0292 1928.4424)',
						title: 'Горчакова',
					},
				],
				id: 806,
			},
			{
				circle: { cx: '302.3', cy: '1880.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 286.365 1910.3184)',
						title: 'Бунинская',
					},
					{
						transform: 'matrix(1 0 0 1 286.365 1928.3184)',
						title: 'аллея',
					},
				],
				id: 591,
			},
		],
		l4: [
			{
				circle: { cx: '214.6', cy: '816.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 103.8954 800.0753)',
						title: 'Международная',
					},
				],
				id: 790,
			},
			{
				circle: { cx: '315.1', cy: '816.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 285.8781 800.2843)',
						title: 'Выставочная',
					},
				],
				id: 602,
			},
			{
				circle: { cx: '450.5', cy: '816.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 397.7927 845.8898)',
						title: 'Киевская',
					},
				],
				id: 909,
			},
			{
				circle: { cx: '577.3', cy: '815.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 524.8215 798.4376)',
						title: 'Смоленская',
					},
				],
				id: 910,
			},
			{
				circle: { cx: '647.6', cy: '830.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 657.8416 819.797)',
						title: 'Арбатская',
					},
				],
				id: 568,
			},
			{
				circle: { cx: '714.8', cy: '899.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 729.7686 906.6505)',
						title: 'Александровский сад',
					},
				],
				id: 800,
			},
			{
				circle: { cx: '64', cy: '667.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 59.2742 649.0509)',
						title: 'Кунцевская',
					},
				],
				id: 908,
			},
			{
				circle: { cx: '64', cy: '699.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 80.0887 703.7267)',
						title: 'Пионерская',
					},
				],
				id: 823,
			},
			{
				circle: { cx: '64', cy: '733' },
				text: [
					{
						transform: 'matrix(1 0 0 1 80.0887 736.0284)',
						title: 'Филёвский',
					},
					{
						transform: 'matrix(1 0 0 1 80.0887 754.3615)',
						title: 'парк',
					},
				],
				id: 764,
			},
			{
				circle: { cx: '64', cy: '908.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 80.0887 911.8429)',
						title: 'Багратионовская',
					},
				],
				id: 571,
			},
			{
				circle: { cx: '64', cy: '944.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 80.0887 948.0284)',
						title: 'Фили',
					},
				],
				id: 765,
			},
			{
				circle: { cx: '255.6', cy: '982.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 136.054 971.6036)',
						title: 'Кутузовская',
					},
				],
				id: 885,
			},
			{
				circle: { cx: '299.6', cy: '938.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 308.0794 964.7784)',
						title: 'Студенческая',
					},
				],
				id: 826,
			},
		],
		l5: [
			{
				circle: { cx: '45', cy: '270.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 61.7935 275.041)',
						title: 'Пятницкое',
					},
					{
						transform: 'matrix(1 0 0 1 61.7935 293.041)',
						title: 'шоссе',
					},
				],
				id: 706,
			},
			{
				circle: { cx: '44.6', cy: '335.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 61.9218 338.9229)',
						title: 'Митино',
					},
				],
				id: 655,
			},
			{
				circle: { cx: '44.6', cy: '381.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 61.082 384.4633)',
						title: 'Волоколамская',
					},
				],
				id: 600,
			},
			{
				circle: { cx: '44.6', cy: '426.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 61.082 430.4024)',
						title: 'Мякинино',
					},
				],
				id: 657,
			},
			{
				circle: { cx: '44.6', cy: '473.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 61.082 477.2452)',
						title: 'Строгино',
					},
				],
				id: 733,
			},
			{
				circle: { cx: '44.6', cy: '519.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 61.082 523.5255)',
						title: 'Крылатское',
					},
				],
				id: 637,
			},
			{
				circle: { cx: '44.6', cy: '565.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 61.082 569.5812)',
						title: 'Молодёжная',
					},
				],
				id: 656,
			},
			{
				circle: { cx: '44.8', cy: '645.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 59.2742 649.0509)',
						title: 'Кунцевская',
					},
				],
				id: 640,
			},
			{
				circle: { cx: '97.6', cy: '1048.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 -6.103516e-05 1080.1407)',
						title: 'Славянский',
					},
					{
						transform: 'matrix(1 0 0 1 31.9189 1098.1407)',
						title: 'бульвар',
					},
				],
				id: 726,
			},
			{
				circle: { cx: '158.9', cy: '1048.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 154.2383 1080.7697)',
						title: 'Парк ',
					},
					{
						transform: 'matrix(1 0 0 1 154.2391 1098.7697)',
						title: 'Победы',
					},
				],
				id: 681,
			},
			{
				circle: { cx: '450', cy: '866.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 397.7927 845.8898)',
						title: 'Киевская',
					},
				],
				id: 622,
			},
			{
				circle: { cx: '584.8', cy: '865.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 524.8359 849.4435)',
						title: 'Смоленская',
					},
				],
				id: 728,
			},
			{
				circle: { cx: '733', cy: '867.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 689.376 851.2423)',
						title: 'Арбатская',
					},
				],
				id: 78,
			},
			{
				circle: { cx: '870.9', cy: '868.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 880.6489 889.964)',
						title: 'Пл. Революции',
					},
				],
				id: 830,
			},
			{
				circle: { cx: '1185.6', cy: '722.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1200.5425 714.6152)',
						title: 'Курская',
					},
				],
				id: 641,
			},
			{
				circle: { cx: '1250.3', cy: '657.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1260.2622 682.6515)',
						title: 'Бауманская',
					},
				],
				id: 574,
			},
			{
				circle: { cx: '1282', cy: '625' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1291.2878 651.4189)',
						title: 'Электрозаводская',
					},
				],
				id: 779,
			},
			{
				circle: { cx: '1313.6', cy: '592.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1316.124 622.5656)',
						title: 'Семёновская',
					},
				],
				id: 724,
			},
			{
				circle: { cx: '1409.8', cy: '497.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1424.8169 509.2267)',
						title: 'Партизанская',
					},
				],
				id: 683,
			},
			{
				circle: { cx: '1446.8', cy: '424.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1463.6367 428.8702)',
						title: 'Измайловская',
					},
				],
				id: 617,
			},
			{
				circle: { cx: '1446.8', cy: '349.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1463.6367 354.5127)',
						title: 'Первомайская',
					},
				],
				id: 816,
			},
			{
				circle: { cx: '1446.8', cy: '271.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1463.6367 276.1875)',
						title: 'Щёлковская',
					},
				],
				id: 776,
			},
		],
		l6: [
			{
				circle: { cx: '328.8', cy: '848.9' },
				text: [
					{ transform: 'matrix(1 0 0 1 252.9369 874.2316)', title: 'Деловой ' },
					{ transform: 'matrix(1 0 0 1 275.5804 891.1945)', title: 'центр' },
				],
				id: 801,
			},
			{
				circle: { cx: '138.4', cy: '1069.6' },
				text: [
					{ transform: 'matrix(1 0 0 1 154.2383 1080.7697)', title: 'Парк ' },
					{ transform: 'matrix(1 0 0 1 154.2391 1098.7697)', title: 'Победы' },
				],
				id: 904,
			},
			{
				circle: { cx: '139.3', cy: '1139.3' },
				text: [{ transform: 'matrix(1 0 0 1 155.5948 1143.6447)', title: 'Минская' }],
				id: 90,
			},
			{
				circle: { cx: '139.3', cy: '1195.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 155.5946 1199.2965)',
						title: 'Ломоносовский',
					},
					{ transform: 'matrix(1 0 0 1 155.5946 1217.2965)', title: 'проспект' },
				],
				id: 91,
			},
			{
				circle: { cx: '139.3', cy: '1264.2' },
				text: [{ transform: 'matrix(1 0 0 1 156.5949 1267.6183)', title: 'Раменки' }],
				id: 92,
			},
		],
		l7: [
			{
				circle: { cx: '968.8', cy: '1008' },
				text: [
					{
						transform: 'matrix(1 0 0 1 824.9036 1016.422)',
						title: 'Третьяковская',
					},
				],
				id: 748,
			},
			{
				circle: { cx: '1200.4', cy: '955.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1166.5425 983.6544)',
						title: 'Марксистская',
					},
				],
				id: 824,
			},
			{
				circle: { cx: '1312.4', cy: '838.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1327.0942 847.6066)',
						title: 'Площадь',
					},
					{
						transform: 'matrix(1 0 0 1 1327.0942 866.4152)',
						title: 'Ильича',
					},
				],
				id: 693,
			},
			{
				circle: { cx: '1363.9', cy: '787.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1371.0952 813.1056)',
						title: 'Авиамоторная',
					},
				],
				id: 558,
			},
			{
				circle: { cx: '1396.5', cy: '755.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1386.5581 737.8175)',
						title: 'Шоссе Энтузиастов',
					},
				],
				id: 775,
			},
			{
				circle: { cx: '1480.1', cy: '670.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1490.9375 694.9913)',
						title: 'Перово',
					},
				],
				id: 686,
			},
			{
				circle: { cx: '1513.3', cy: '638.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1523.4404 664.0538)',
						title: 'Новогиреево',
					},
				],
				id: 663,
			},
			{
				circle: { cx: '1543.3', cy: '607' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1554.2937 632.5411)',
						title: 'Новокосино',
					},
				],
				id: 666,
			},
		],
		l8: [
			{
				circle: { cx: '1215.2', cy: '1805.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1227.1462 1798.1279)',
						title: 'Зябликово',
					},
				],
				id: 804,
			},
			{
				circle: { cx: '1214.6', cy: '1736' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1230.3943 1740.1846)',
						title: 'Шипиловская',
					},
				],
				id: 102,
			},
			{
				circle: { cx: '1214.6', cy: '1679.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1230.3943 1683.8018)',
						title: 'Борисово',
					},
				],
				id: 582,
			},
			{
				circle: { cx: '1214.6', cy: '1622.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1230.3943 1626.3829)',
						title: 'Марьино',
					},
				],
				id: 647,
			},
			{
				circle: { cx: '1214.6', cy: '1564.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1230.3943 1568.0382)',
						title: 'Братиславская',
					},
				],
				id: 809,
			},
			{
				circle: { cx: '1214.6', cy: '1506.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1230.3943 1510.9005)',
						title: 'Люблино',
					},
				],
				id: 645,
			},
			{
				circle: { cx: '1214.6', cy: '1449.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1230.3943 1453.1163)',
						title: 'Волжская',
					},
				],
				id: 599,
			},
			{
				circle: { cx: '1214.6', cy: '1392.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1230.3943 1395.6857)',
						title: 'Печатники',
					},
				],
				id: 811,
			},
			{
				circle: { cx: '1214.6', cy: '1333.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1230.3948 1338.0031)',
						title: 'Кожуховская',
					},
				],
				id: 820,
			},
			{
				circle: { cx: '1214.9', cy: '1236' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1114.8103 1239.1593)',
						title: 'Дубровка',
					},
				],
				id: 612,
			},
			{
				circle: { cx: '1266.2', cy: '1031.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1160.2722 1057.007)',
						title: 'Крестьянская',
					},
					{
						transform: 'matrix(1 0 0 1 1210.5918 1075.007)',
						title: 'Застава',
					},
				],
				id: 633,
			},
			{
				circle: { cx: '1282.1', cy: '838.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1216.7361 823.4816)',
						title: 'Римская',
					},
				],
				id: 714,
			},
			{
				circle: { cx: '1154.3', cy: '709.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1036.7163 715.9181)',
						title: 'Чкаловская',
					},
				],
				id: 794,
			},
			{
				circle: { cx: '1005.7', cy: '610.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 951.5896 576.8224)',
						title: 'Сретенский ',
					},
					{
						transform: 'matrix(1 0 0 1 970.1694 594.8224)',
						title: 'бульвар',
					},
				],
				id: 829,
			},
			{
				circle: { cx: '847.6', cy: '574.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 853.7327 562.2052)',
						title: 'Трубная',
					},
				],
				id: 752,
			},
			{
				circle: { cx: '845.6', cy: '411.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 862.2703 415.712)',
						title: 'Достоевская',
					},
				],
				id: 611,
			},
			{
				circle: { cx: '845.6', cy: '378.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 862.2703 383.1368)',
						title: 'Марьина Роща',
					},
				],
				id: 646,
			},
			{
				circle: { cx: '845.6', cy: '343' },
				text: [
					{
						transform: 'matrix(1 0 0 1 862.2703 346.8496)',
						title: 'Бутырская',
					},
				],
				id: 819,
			},
			{
				circle: { cx: '810', cy: '270.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 826.3516 273.7814)',
						title: 'Фонвизинская',
					},
				],
				id: 818,
			},
			{
				circle: { cx: '741.2', cy: '248.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 754.7396 238.7666)',
						title: 'Петровско-Разумовская',
					},
				],
				id: 689,
			},
		],
		l9: [
			{
				circle: { cx: '497.1', cy: '726.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 510.8068 733.7882)',
						title: 'Краснопресненская',
					},
				],
				id: 825,
			},
			{
				circle: { cx: '552.4', cy: '589.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 431.056 581.7804)',
						title: 'Белорусская',
					},
				],
				id: 893,
			},
			{
				circle: { cx: '727.9', cy: '459' },
				text: [
					{
						transform: 'matrix(1 0 0 1 743.309 474.9835)',
						title: 'Новослободская',
					},
				],
				id: 671,
			},
			{
				circle: { cx: '984.5', cy: '468.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 996.8457 459.8321)',
						title: 'Проспект Мира',
					},
				],
				id: 892,
			},
			{
				circle: { cx: '1123', cy: '572' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1136.2861 563.1515)',
						title: 'Комсомольская',
					},
				],
				id: 891,
			},
			{
				circle: { cx: '1185.5', cy: '694.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1200.5425 714.6152)',
						title: 'Курская',
					},
				],
				id: 933,
			},
			{
				circle: { cx: '1166.5', cy: '940.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1080.2871 919.1007)',
						title: 'Таганская',
					},
				],
				id: 737,
			},
			{
				circle: { cx: '998.3', cy: '1110.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 997.3616 1139.2882)',
						title: 'Павелецкая',
					},
				],
				id: 890,
			},
			{
				circle: { cx: '770.6', cy: '1139.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 782.0223 1124.9933)',
						title: 'Добрынинская',
					},
				],
				id: 608,
			},
			{
				circle: { cx: '635.9', cy: '1080.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 513.7142 1101.2648)',
						title: 'Октябрьская',
					},
				],
				id: 889,
			},
			{
				circle: { cx: '552.6', cy: '992.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 493.2133 1000.4962)',
						title: 'Парк',
					},
					{
						transform: 'matrix(1 0 0 1 458.1945 1018.6661)',
						title: 'культуры',
					},
				],
				id: 888,
			},
			{
				circle: { cx: '494.2', cy: '841.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 397.7927 845.8898)',
						title: 'Киевская',
					},
				],
				id: 894,
			},
		],
		l10: [
			{
				circle: { cx: '729.8', cy: '1588.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 624.327 1593.4093)',
						title: 'Каховская',
					},
				],
				id: 621,
			},
			{
				circle: { cx: '881', cy: '1587.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 828.2999 1571.0509)',
						title: 'Варшавская',
					},
				],
				id: 592,
			},
			{
				circle: { cx: '1003.8', cy: '1561.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 998.8711 1543.2433)',
						title: 'Каширская',
					},
				],
				id: 887,
			},
		],
		l11: [
			{
				circle: { cx: '767.2', cy: '7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 784.5168 18.1328)',
						title: 'Алтуфьево',
					},
				],
				id: 564,
			},
			{
				circle: { cx: '767.2', cy: '60.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 784.5168 64.0851)',
						title: 'Бибирево',
					},
				],
				id: 580,
			},
			{
				circle: { cx: '767.2', cy: '105' },
				text: [
					{
						transform: 'matrix(1 0 0 1 784.5168 110.0586)',
						title: 'Отрадное',
					},
				],
				id: 815,
			},
			{
				circle: { cx: '767.2', cy: '166.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 784.5168 171.0841)',
						title: 'Владыкино',
					},
				],
				id: 882,
			},
			{
				circle: { cx: '741.2', cy: '218' },
				text: [
					{
						transform: 'matrix(1 0 0 1 754.7396 238.7666)',
						title: 'Петровско-Разумовская',
					},
				],
				id: 903,
			},
			{
				circle: { cx: '668.4', cy: '314.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 685.6149 319.6485)',
						title: 'Тимирязевская',
					},
				],
				id: 747,
			},
			{
				circle: { cx: '668.2', cy: '366.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 685.5071 370.4474)',
						title: 'Дмитровская',
					},
				],
				id: 607,
			},
			{
				circle: { cx: '668.2', cy: '403.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 685.5071 406.961)',
						title: 'Савёловская',
					},
				],
				id: 719,
			},
			{
				circle: { cx: '727.9', cy: '489.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 698.9561 518.2939)',
						title: 'Менделеевская',
					},
				],
				id: 654,
			},
			{
				circle: { cx: '819.6', cy: '585.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 734.5352 605.6476)',
						title: 'Цветной',
					},
					{
						transform: 'matrix(1 0 0 1 734.5352 624.0753)',
						title: 'бульвар',
					},
				],
				id: 769,
			},
			{
				circle: { cx: '756.7', cy: '731.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 770.608 740.3263)',
						title: 'Чеховская',
					},
				],
				id: 798,
			},
			{
				circle: { cx: '678.8', cy: '899.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 559.7585 906.6505)',
						title: 'Боровицкая',
					},
				],
				id: 583,
			},
			{
				circle: { cx: '751.3', cy: '1082.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 766.4385 1086.4366)',
						title: 'Полянка',
					},
				],
				id: 696,
			},
			{
				circle: { cx: '751.3', cy: '1162.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 767.4749 1181.715)',
						title: 'Серпуховская',
					},
				],
				id: 725,
			},
			{
				circle: { cx: '751.3', cy: '1258.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 766.4385 1262.255)',
						title: 'Тульская',
					},
				],
				id: 753,
			},
			{
				circle: { cx: '751.3', cy: '1433.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 766.4385 1437.3693)',
						title: 'Нагатинская',
					},
				],
				id: 660,
			},
			{
				circle: { cx: '751.3', cy: '1472.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 766.4385 1475.5929)',
						title: 'Нагорная',
					},
				],
				id: 661,
			},
			{
				circle: { cx: '751.3', cy: '1510.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 766.4385 1513.5021)',
						title: 'Нахимовский',
					},
					{
						transform: 'matrix(1 0 0 1 766.4385 1531.5021)',
						title: 'проспект',
					},
				],
				id: 662,
			},
			{
				circle: { cx: '751.3', cy: '1611.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 766.4385 1622.3712)',
						title: 'Севастопольская',
					},
				],
				id: 817,
			},
			{
				circle: { cx: '751.3', cy: '1656.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 766.4385 1660.2568)',
						title: 'Чертановская',
					},
				],
				id: 770,
			},
			{
				circle: { cx: '751.3', cy: '1692.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 766.4385 1696.5449)',
						title: 'Южная',
					},
				],
				id: 785,
			},
			{
				circle: { cx: '751.3', cy: '1729' },
				text: [
					{
						transform: 'matrix(1 0 0 1 766.4385 1732.9463)',
						title: 'Пражская',
					},
				],
				id: 698,
			},
			{
				circle: { cx: '751.3', cy: '1766.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 766.4385 1769.5938)',
						title: 'Ул. Академика Янгеля',
					},
				],
				id: 760,
			},
			{
				circle: { cx: '751.3', cy: '1802' },
				text: [
					{
						transform: 'matrix(1 0 0 1 766.4385 1805.8877)',
						title: 'Аннино',
					},
				],
				id: 566,
			},
			{
				circle: { cx: '751', cy: '1837.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 766.1578 1842.2334)',
						title: 'Бульвар Дмитрия',
					},
					{
						transform: 'matrix(1 0 0 1 766.1578 1860.4033)',
						title: 'Донского',
					},
				],
				id: 588,
			},
		],
		l12: [
			{
				circle: { cx: '1052.6', cy: '14.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1070.4453 19.214)',
						title: 'Медведково',
					},
				],
				id: 651,
			},
			{
				circle: { cx: '1052.6', cy: '65.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1070.4453 69.8478)',
						title: 'Бабушкинская',
					},
				],
				id: 570,
			},
			{
				circle: { cx: '1052.6', cy: '116.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1070.4451 120.8409)',
						title: 'Свиблово',
					},
				],
				id: 723,
			},
			{
				circle: { cx: '1053.1', cy: '165.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 905.948 157.666)',
						title: 'Ботанический',
					},
					{
						transform: 'matrix(1 0 0 1 997.3271 175.9015)',
						title: 'сад',
					},
				],
				id: 584,
			},
			{
				circle: { cx: '1052.6', cy: '315.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1070.4453 323.3849)',
						title: 'ВДНХ',
					},
				],
				id: 593,
			},
			{
				circle: { cx: '1052.6', cy: '353.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1070.4453 357.5996)',
						title: 'Алексеевская',
					},
				],
				id: 562,
			},
			{
				circle: { cx: '1037.5', cy: '386.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1052.3328 404.1524)',
						title: 'Рижская',
					},
				],
				id: 712,
			},
			{
				circle: { cx: '984.4', cy: '437.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 996.8457 459.8321)',
						title: 'Проспект Мира',
					},
				],
				id: 702,
			},
			{
				circle: { cx: '941', cy: '537.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 957.9287 540.8116)',
						title: 'Сухаревская',
					},
				],
				id: 734,
			},
			{
				circle: { cx: '988.2', cy: '640.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 855.8096 647.5197)',
						title: 'Тургеневская',
					},
				],
				id: 828,
			},
			{
				circle: { cx: '1019.9', cy: '783.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 948.6375 809.1466)',
						title: 'Китай-город',
					},
				],
				id: 832,
			},
			{
				circle: { cx: '948.5', cy: '987.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 824.9036 1016.422)',
						title: 'Третьяковская',
					},
				],
				id: 886,
			},
			{
				circle: { cx: '635.8', cy: '1110.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 513.7142 1101.2648)',
						title: 'Октябрьская',
					},
				],
				id: 675,
			},
			{
				circle: { cx: '583.4', cy: '1164.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 593.1746 1189.7843)',
						title: 'Шаболовская',
					},
				],
				id: 774,
			},
			{
				circle: { cx: '517.7', cy: '1290.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 528.2855 1262.3947)',
						title: 'Ленинский',
					},
					{
						transform: 'matrix(1 0 0 1 528.2855 1279.2335)',
						title: 'проспект',
					},
				],
				id: 788,
			},
			{
				circle: { cx: '517.7', cy: '1412.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 536.1689 1417.1847)',
						title: 'Академическая',
					},
				],
				id: 787,
			},
			{
				circle: { cx: '517.7', cy: '1453.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 536.1689 1457.6388)',
						title: 'Профсоюзная',
					},
				],
				id: 703,
			},
			{
				circle: { cx: '517.7', cy: '1494.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 536.1689 1498.6222)',
						title: 'Новые Черёмушки',
					},
				],
				id: 672,
			},
			{
				circle: { cx: '517.7', cy: '1535.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 536.1689 1539.7355)',
						title: 'Калужская',
					},
				],
				id: 618,
			},
			{
				circle: { cx: '517.7', cy: '1577.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 536.1689 1581.2491)',
						title: 'Беляево',
					},
				],
				id: 577,
			},
			{
				circle: { cx: '517.7', cy: '1619.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 536.1689 1623.6066)',
						title: 'Коньково',
					},
				],
				id: 822,
			},
			{
				circle: { cx: '517.7', cy: '1660.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 536.1689 1664.2842)',
						title: 'Тёплый стан',
					},
				],
				id: 742,
			},
			{
				circle: { cx: '517.7', cy: '1700' },
				text: [
					{
						transform: 'matrix(1 0 0 1 536.1689 1703.8213)',
						title: 'Ясенево',
					},
				],
				id: 786,
			},
			{
				circle: { cx: '539.9', cy: '1740.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 554.3875 1744.9268)',
						title: 'Новоясеневская',
					},
				],
				id: 813,
			},
		],
		l13: [
			{
				circle: { cx: '1299.4', cy: '327.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1231.1125 308.0519)',
						title: 'Бульвар Рокоссовского',
					},
				],
				id: 590,
			},
			{
				circle: { cx: '1275.8', cy: '388.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1139.0674 393.1476)',
						title: 'Черкизовская',
					},
				],
				id: 797,
			},
			{
				circle: { cx: '1241.6', cy: '421.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1252.127 445.1212)',
						title: 'Преображенская пл.',
					},
				],
				id: 699,
			},
			{
				circle: { cx: '1206.5', cy: '458.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1216.4675 481.4249)',
						title: 'Сокольники',
					},
				],
				id: 730,
			},
			{
				circle: { cx: '1170.9', cy: '493.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1181.3867 519.1329)',
						title: 'Красносельская',
					},
				],
				id: 632,
			},
			{
				circle: { cx: '1123.5', cy: '541.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1136.2861 563.1515)',
						title: 'Комсомольская',
					},
				],
				id: 627,
			},
			{
				circle: { cx: '1072.2', cy: '591.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1082.2061 616.9775)',
						title: 'Красные Ворота',
					},
				],
				id: 799,
			},
			{
				circle: { cx: '1023.6', cy: '640.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1039.0374 658.9298)',
						title: 'Чистые пруды',
					},
				],
				id: 827,
			},
			{
				circle: { cx: '931.9', cy: '731.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 928.6511 761.8341)',
						title: 'Лубянка',
					},
				],
				id: 644,
			},
			{
				circle: { cx: '848.5', cy: '814.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 754.7393 802.9913)',
						title: 'Охотный',
					},
					{
						transform: 'matrix(1 0 0 1 799.8594 820.9913)',
						title: 'Ряд',
					},
				],
				id: 678,
			},
			{
				circle: { cx: '733.1', cy: '930.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 738.4958 958.0236)',
						title: 'Библиотека им. Ленина',
					},
				],
				id: 833,
			},
			{
				circle: { cx: '643.1', cy: '971.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 580.1022 1000.4083)',
						title: 'Кропоткинская',
					},
				],
				id: 634,
			},
			{
				circle: { cx: '515', cy: '972.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 493.2133 1000.4962)',
						title: 'Парк',
					},
					{
						transform: 'matrix(1 0 0 1 458.1945 1018.6661)',
						title: 'культуры',
					},
				],
				id: 680,
			},
			{
				circle: { cx: '425.4', cy: '1025.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 435.5079 1048.462)',
						title: 'Фрунзенская',
					},
				],
				id: 793,
			},
			{
				circle: { cx: '354.9', cy: '1096.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 370.9135 1106.0109)',
						title: 'Спортивная',
					},
				],
				id: 792,
			},
			{
				circle: { cx: '311.5', cy: '1209.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 327.5089 1213.9972)',
						title: 'Воробьёвы горы',
					},
				],
				id: 601,
			},
			{
				circle: { cx: '311.5', cy: '1271.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 327.795 1276.1036)',
						title: 'Университет',
					},
				],
				id: 763,
			},
			{
				circle: { cx: '311.5', cy: '1362.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 327.7947 1366.0841)',
						title: 'Проспект',
					},
					{
						transform: 'matrix(1 0 0 1 327.7947 1384.0841)',
						title: 'Вернадского',
					},
				],
				id: 701,
			},
			{
				circle: { cx: '311.5', cy: '1413.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 327.7947 1418.256)',
						title: 'Юго-Западная',
					},
				],
				id: 780,
			},
			{
				circle: { cx: '311.5', cy: '1473.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 328.795 1477.3331)',
						title: 'Тропарёво',
					},
				],
				id: 749,
			},
			{
				circle: { cx: '311.5', cy: '1535.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 327.7947 1539.923)',
						title: 'Румянцево',
					},
				],
				id: 715,
			},
			{
				circle: { cx: '311.5', cy: '1595.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 328.795 1598.9991)',
						title: 'Саларьево',
					},
				],
				id: 720,
			},
		],
		l14: [
			{
				circle: { cx: '1310.9', cy: '400.9' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1327.5098 404.6642)',
						title: 'Локомотив',
					},
				],
				id: 207,
			},
			{
				circle: { cx: '1409.4', cy: '556.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1425.9822 560.2394)',
						title: 'Измайлово',
					},
				],
				id: 208,
			},
			{
				circle: { cx: '1421.2', cy: '591' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1439.4263 590.2208)',
						title: 'Соколиная',
					},
					{
						transform: 'matrix(1 0 0 1 1443.0176 608.6661)',
						title: 'Гора',
					},
				],
				id: 209,
			},
			{
				circle: { cx: '1456.1', cy: '755.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1386.5581 737.8175)',
						title: 'Шоссе Энтузиастов',
					},
				],
				id: 898,
			},
			{
				circle: { cx: '1451', cy: '868.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1469.5396 876.7579)',
						title: 'Андроновка',
					},
				],
				id: 211,
			},
			{
				circle: { cx: '1443.5', cy: '913.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1459.0413 928.839)',
						title: 'Нижегородская',
					},
				],
				id: 212,
			},
			{
				circle: { cx: '1430.8', cy: '969.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1446.5452 986.2394)',
						title: 'Новохохловская',
					},
				],
				id: 213,
			},
			{
				circle: { cx: '1282.9', cy: '1215.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1294.4326 1241.005)',
						title: 'Угрешская',
					},
				],
				id: 214,
			},
			{
				circle: { cx: '1191.9', cy: '1291.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1091.1292 1290.0138)',
						title: 'Дубровка',
					},
				],
				id: 899,
			},
			{
				circle: { cx: '1005.4', cy: '1377.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1018.1152 1395.6847)',
						title: 'Автозаводская',
					},
				],
				id: 900,
			},
			{
				circle: { cx: '947.7', cy: '1390.5' },
				text: [
					{
						transform: 'matrix(1 0 0 1 913.0906 1373.9943)',
						title: 'ЗИЛ',
					},
				],
				id: 217,
			},
			{
				circle: { cx: '838.8', cy: '1398.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 801.6099 1362.8624)',
						title: 'Верхние',
					},
					{
						transform: 'matrix(1 0 0 1 812.7695 1381.8624)',
						title: 'Котлы',
					},
				],
				id: 218,
			},
			{
				circle: { cx: '683.2', cy: '1376.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 684.4377 1360.1241)',
						title: 'Крымская',
					},
				],
				id: 219,
			},
			{
				circle: { cx: '543.6', cy: '1320.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 562.7673 1314.7443)',
						title: 'Пл. Гагарина',
					},
				],
				id: 220,
			},
			{
				circle: { cx: '354.9', cy: '1155.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 374.0522 1160.4572)',
						title: 'Лужники',
					},
				],
				id: 221,
			},
			{
				circle: { cx: '254.1', cy: '952.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 136.054 971.6036)',
						title: 'Кутузовская',
					},
				],
				id: 802,
			},
			{
				circle: { cx: '235.2', cy: '841.8' },
				text: [
					{
						transform: 'matrix(1 0 0 1 143.445 858.4962)',
						title: 'Деловой',
					},
					{
						transform: 'matrix(1 0 0 1 114.4451 876.7579)',
						title: 'центр (МЦК)',
					},
				],
				id: 223,
			},
			{
				circle: { cx: '235.9', cy: '724.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 243.4148 754.0138)',
						title: 'Шелепиха',
					},
				],
				id: 224,
			},
			{
				circle: { cx: '265', cy: '596.2' },
				text: [
					{
						transform: 'matrix(1 0 0 1 158.9532 599.1808)',
						title: 'Хорошёво',
					},
				],
				id: 225,
			},
			{
				circle: { cx: '278.8', cy: '562.6' },
				text: [
					{
						transform: 'matrix(1 0 0 1 215.1493 551.8302)',
						title: 'Зорге',
					},
				],
				id: 226,
			},
			{
				circle: { cx: '315.7', cy: '487.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 282.1753 515.9132)',
						title: 'Панфиловская',
					},
				],
				id: 227,
			},
			{
				circle: { cx: '391.8', cy: '386.3' },
				text: [
					{
						transform: 'matrix(1 0 0 1 283.2203 372.921)',
						title: 'Панфиловская',
					},
				],
				id: 228,
			},
			{
				circle: { cx: '434.4', cy: '342.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 320.4322 337.4326)',
						title: 'Балтийская',
					},
				],
				id: 229,
			},
			{
				circle: { cx: '562', cy: '255.4' },
				text: [
					{
						transform: 'matrix(1 0 0 1 482.6105 237.167)',
						title: 'Коптево',
					},
				],
				id: 230,
			},
			{
				circle: { cx: '628.1', cy: '226' },
				text: [
					{
						transform: 'matrix(1 0 0 1 534.7678 209.2599)',
						title: 'Лихоборы',
					},
				],
				id: 231,
			},
			{
				circle: { cx: '697.6', cy: '204.1' },
				text: [
					{
						transform: 'matrix(1 0 0 1 634.6177 182.3566)',
						title: 'Окружная',
					},
				],
				id: 232,
			},
			{
				circle: { cx: '788.2', cy: '187.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 784.5168 171.0841)',
						title: 'Владыкино',
					},
				],
				id: 595,
			},
			{
				circle: { cx: '1011.2', cy: '208.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 905.948 157.666)',
						title: 'Ботанический',
					},
					{
						transform: 'matrix(1 0 0 1 997.3271 175.9015)',
						title: 'сад',
					},
				],
				id: 896,
			},
			{
				circle: { cx: '1117.8', cy: '250' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1129.1614 235.5265)',
						title: 'Ростокино',
					},
				],
				id: 235,
			},
			{
				circle: { cx: '1182.5', cy: '286.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1192.7598 273.666)',
						title: 'Белокаменная',
					},
				],
				id: 236,
			},
			{
				circle: { cx: '1236.6', cy: '327.7' },
				text: [
					{
						transform: 'matrix(1 0 0 1 1231.1125 308.0519)',
						title: 'Бульвар Рокоссовского',
					},
				],
				id: 897,
			},
		],
	},
	ekb: {
		ekb: [
			{
				circle: { cx: '330', cy: '15' },
				text: { x: '205', y: '18', title: 'Проспект Космонавтов' },
				id: 922,
			},
			{
				circle: { cx: '330', cy: '85' },
				text: { x: '275', y: '88', title: 'Уралмаш' },
				id: 921,
			},
			{
				circle: { cx: '330', cy: '150' },
				text: { x: '225', y: '153', title: 'Машиностроителей' },
				id: 920,
			},
			{
				circle: { cx: '290', cy: '190' },
				text: { x: '230', y: '193', title: 'Уральская' },
				id: 919,
			},
			{
				circle: { cx: '290', cy: '230' },
				text: { x: '240', y: '233', title: 'Динамо' },
				id: 918,
			},
			{
				circle: { cx: '240', cy: '280' },
				text: { x: '250', y: '283', title: 'Площадь 1905 года' },
				id: 917,
			},
			{
				circle: { cx: '240', cy: '330' },
				text: { x: '250', y: '333', title: 'Геологическая' },
				id: 916,
			},
			{
				circle: { cx: '285', cy: '375' },
				text: { x: '295', y: '378', title: 'Чкаловская' },
				id: 915,
			},
			{
				circle: { cx: '330', cy: '420' },
				text: { x: '250', y: '423', title: 'Ботаническая' },
				id: 914,
			},
		],
	},
	nn: {
		line1: [
			{
				circle: { cx: '330', cy: '520' },
				text: { x: '250', y: '522.5', title: 'Парк культуры' },
				id: 935,
			},
			{
				circle: { cx: '330', cy: '480' },
				text: { x: '270', y: '482', title: 'Кировская' },
				id: 936,
			},
			{
				circle: { cx: '330', cy: '440' },
				text: { x: '245', y: '442', title: 'Комсомольская' },
				id: 937,
			},
			{
				circle: { cx: '330', cy: '400' },
				text: { x: '250', y: '402', title: 'Автозаводская' },
				id: 938,
			},
			{
				circle: { cx: '330', cy: '360' },
				text: { x: '252', y: '362', title: 'Пролетарская' },
				id: 939,
			},
			{
				circle: { cx: '330', cy: '320' },
				text: { x: '215', y: '322', title: 'Двигатель Революции' },
				id: 940,
			},
			{
				circle: { cx: '330', cy: '280' },
				text: { x: '275', y: '282', title: 'Заречная' },
				id: 941,
			},
			{
				circle: { cx: '330', cy: '240' },
				text: { x: '270', y: '242', title: 'Ленинская' },
				id: 942,
			},
			{
				circle: { cx: '330', cy: '200' },
				text: { x: '265', y: '202', title: 'Чкаловская' },
				id: 943,
			},
			{
				circle: { cx: '330', cy: '160' },
				text: { x: '340', y: '163', title: 'Московская' },
				id: 944,
			},
			{
				circle: { cx: '360', cy: '120' },
				text: { x: '370', y: '122', title: 'Горьковская' },
				id: 945,
			},
		],
		line2: [
			{
				circle: { cx: '315', cy: '160' },
				text: { x: '260', y: '175', title: 'Московская II' },
				id: 950,
			},
			{
				circle: { cx: '315', cy: '100' },
				text: { x: '300', y: '90', title: 'Стрелка' },
				id: 949,
			},
			{
				circle: { cx: '280', cy: '160' },
				text: { x: '245', y: '150', title: 'Канавинская' },
				id: 948,
			},
			{
				circle: { cx: '200', cy: '160' },
				text: { x: '160', y: '175', title: 'Бурнаковская' },
				id: 947,
			},
			{
				circle: { cx: '120', cy: '160' },
				text: { x: '90', y: '150', title: 'Буревестник' },
				id: 946,
			},
		],
	},
	nsk: {
		line1: [
			{
				circle: { cx: '260', cy: '200' },
				text: { x: '150', y: '218', title: 'Площадь Гарина-Михайловского' },
				id: 959,
			},
			{
				circle: { cx: '355', cy: '200' },
				text: { x: '340', y: '188', title: 'Красный проспект' },
				id: 960,
			},
			{
				circle: { cx: '450', cy: '200' },
				text: { x: '400', y: '215', title: 'Маршала Покрышкина' },
				id: 961,
			},
			{
				circle: { cx: '550', cy: '240' },
				text: { x: '560', y: '243', title: 'Березовая роща' },
				id: 962,
			},
			{
				circle: { cx: '600', cy: '300' },
				text: { x: '525', y: '303', title: 'Золотая Нива' },
				id: 963,
			},
		],
		line2: [
			{
				circle: { cx: '180', cy: '420' },
				text: { x: '150', y: '440', title: 'Площадь Маркса' },
				id: 951,
			},
			{
				circle: { cx: '252', cy: '370' },
				text: { x: '265', y: '375', title: 'Студенческая' },
				id: 952,
			},
			{
				circle: { cx: '302', cy: '340' },
				text: { x: '315', y: '345', title: 'Речной вокзал' },
				id: 953,
			},
			{
				circle: { cx: '330', cy: '300' },
				text: { x: '340', y: '303', title: 'Октябрьская' },
				id: 954,
			},
			{
				circle: { cx: '330', cy: '260' },
				text: { x: '340', y: '263', title: 'Площадь Ленина' },
				id: 955,
			},
			{
				circle: { cx: '330', cy: '200' },
				text: { x: '340', y: '223', title: 'Сибирская' },
				id: 956,
			},
			{
				circle: { cx: '330', cy: '160' },
				text: { x: '340', y: '163', title: 'Гагаринская' },
				id: 957,
			},
			{
				circle: { cx: '330', cy: '100' },
				text: { x: '340', y: '103', title: 'Заельцовская' },
				id: 958,
			},
		],
	},
	kzn: {
		kzn: [
			{
				circle: { cx: '500', cy: '510' },
				text: { x: '510', y: '513', title: 'Дубравная' },
				id: 923,
			},
			{
				circle: { cx: '460', cy: '480' },
				text: { x: '475', y: '482', title: 'Проспект Победы' },
				id: 924,
			},
			{
				circle: { cx: '420', cy: '450' },
				text: { x: '435', y: '452', title: 'Горки' },
				id: 925,
			},
			{
				circle: { cx: '380', cy: '418' },
				text: { x: '395', y: '420', title: 'Аметьево' },
				id: 926,
			},
			{
				circle: { cx: '340', cy: '387' },
				text: { x: '355', y: '390', title: 'Суконная Слобода' },
				id: 927,
			},
			{
				circle: { cx: '300', cy: '356' },
				text: { x: '315', y: '359', title: 'Площадь Габдуллы Тукая' },
				id: 928,
			},
			{
				circle: { cx: '260', cy: '325' },
				text: { x: '275', y: '327', title: 'Кремлёвская' },
				id: 929,
			},
			{
				circle: { cx: '230', cy: '285' },
				text: { x: '240', y: '288', title: 'Козья Слобода' },
				id: 930,
			},
			{
				circle: { cx: '230', cy: '245' },
				text: { x: '240', y: '248', title: 'Яшьлек' },
				id: 931,
			},
			{
				circle: { cx: '230', cy: '205' },
				text: { x: '240', y: '208', title: 'Северный вокзал' },
				id: 966,
			},
			{
				circle: { cx: '230', cy: '165' },
				text: { x: '240', y: '168', title: 'Авиастроительная' },
				id: 932,
			},
		],
	},
	smr: {
		smr: [
			{
				circle: { cx: '230', cy: '500' },
				text: { x: '240', y: '503', title: 'Российская' },
				id: 968,
			},
			{
				circle: { cx: '230', cy: '460' },
				text: { x: '240', y: '463', title: 'Московская' },
				id: 969,
			},
			{
				circle: { cx: '230', cy: '420' },
				text: { x: '240', y: '423', title: 'Гагаринская' },
				id: 970,
			},
			{
				circle: { cx: '230', cy: '380' },
				text: { x: '240', y: '383', title: 'Спортивная' },
				id: 971,
			},
			{
				circle: { cx: '230', cy: '340' },
				text: { x: '240', y: '343', title: 'Советская' },
				id: 972,
			},
			{
				circle: { cx: '230', cy: '300' },
				text: { x: '240', y: '303', title: 'Победа' },
				id: 973,
			},
			{
				circle: { cx: '230', cy: '260' },
				text: { x: '240', y: '263', title: 'Безымянка' },
				id: 974,
			},
			{
				circle: { cx: '350', cy: '150' },
				text: { x: '360', y: '153', title: 'Юнгородок' },
				id: 976,
			},
			{
				circle: { cx: '305', cy: '180' },
				text: { x: '318', y: '184', title: 'Кировская' },
				id: 975,
			},
		],
	},
};
