/* @flow */
import React from 'react';
import type { Node } from 'react';
import { useSelector } from 'react-redux';
import { Fetching } from './Fetching';
import { FormBody } from './FormBody';
import { Description, Response, Title } from '../styles';
import { checkLoader } from '../helpers';
import Button from '../../Button';
import type { TDeveloper } from '../../../types/apart';
import type { TActionCallbackPopup } from '../../../types/popup';
import type { TCRMRequestContext } from '../../../types/misc';
import type { TMortgageData } from '../../../decorators/withCallbackPopup';
import {sendGTMAnalytics} from "../../../utils/tagManager";

type TProps = {
	callbackType: string | null,
	isReserved: boolean,
	apartData: {
		id: number,
		nmarketId: number,
		rooms_cnt: number,
		number: number | string,
		complex_name: string,
		developer: TDeveloper,
	} | null,
	complexId: number,
	context: TCRMRequestContext,
	mortgageData: TMortgageData,
	closeCallback: () => any,
};

const Form = (props: TProps): Node => {
	const { callbackType, isReserved, apartData, closeCallback, context, complexId, mortgageData } = props;

	const popup: TActionCallbackPopup = useSelector((state) => state.popup.actionCallbackPopup);

	const { queue, response, responseIsSuccess, responseIsExist, isFetching } = popup || {};

	const responseText = (): string => {
		if (responseIsSuccess) return 'Ваша заявка отправлена';
		if (responseIsExist) return 'Ваша заявка уже находится в обработке';
		return 'Что-то пошло не так :(';
	};

	if (isFetching) {
		return (
			<Fetching
				apartData={apartData}
				callbackType={callbackType}
				context={context}
				complexId={complexId}
				isReserved={isReserved}
				mortgageData={mortgageData}
				closeCallback={closeCallback}
			/>
		);
	}
	if (queue === 0) {
		return (
			<FormBody
				callbackType={callbackType}
				apartData={apartData}
				isReserved={isReserved}
				context={context}
				complexId={complexId}
				mortgageData={mortgageData}
			/>
		);
	}
	if (response) {
		return (
			<Response>
				{(responseIsSuccess || responseIsExist) && checkLoader}
				<Title>{responseText()}</Title>

				{responseIsSuccess && (
					<Description>Специалист отдела сопровождения свяжется с вами для подтверждения записи</Description>
				)}

				<Button rounded onClick={closeCallback}>
					Закрыть окно
				</Button>
			</Response>
		);
	}

	return (
		<FormBody
			callbackType={callbackType}
			isReserved={isReserved}
			apartData={apartData}
			complexId={complexId}
			context={context}
			mortgageData={mortgageData}
		/>
	);
};

export default Form;
