/* @flow */
import React, { type Node, useState } from 'react';
import { plural } from '../../../utils';
import CheckboxButton from '../../CheckboxButton';
import type { TFilterBase } from '../../../types/filters';
import type { TCity } from '../../../types/city';
import { arraysCompare } from '../../../utils/arraysHelpers';

type TProps = {
	mode: 'metro' | 'area',
	data: any,
	handleChangeArea: () => {},
	targetField: 'metroIds' | 'areaIds',
	source: TFilterBase,
	city: TCity,
};

export const Grouped = ({ mode, data, handleChangeArea, targetField, source, city }: TProps): Node => {
	const [collapseGroup, setCollapseGroup] = useState([]);

	const getGroupText = (count) =>
		`${count} ${
			mode === 'metro'
				? plural(count, ['станция', 'станции', 'станций'])
				: plural(count, ['район', 'района', 'районов'])
		}`;

	const handleCollapseGroup = (code) => () => {
		if (collapseGroup.includes(code)) {
			setCollapseGroup(collapseGroup.filter((item) => item !== code));
		} else {
			setCollapseGroup([...collapseGroup, code]);
		}
	};

	const groupIDs = (group) => {
		const array = [];
		group?.forEach((item) =>
			item?.id ? array.push(item.id) : item.params.map((i) => i.id && !array.includes(i.id) && array.push(i.id)),
		);
		return array;
	};

	return data.map(
		(group) =>
			group?.params?.length > 0 && (
				<React.Fragment key={group.name}>
					<CheckboxButton
						isGroup
						onClick={handleChangeArea(
							groupIDs(group.params),
							arraysCompare(groupIDs(group.params), source[targetField]),
						)}
						onLabelClick={handleCollapseGroup(group.name)}
						groupText={getGroupText(groupIDs(group.params).length)}
						isOpen={!collapseGroup.includes(group.name)}
						label={group.name}
						isActive={arraysCompare(groupIDs(group.params), source[targetField])}
						isTopLevel={group?.code === 'city' || group?.code === 'region'}
						city={city}
						color={group?.lineColor}
						mode={mode}
						oneColumn
					/>
					{group?.params?.length > 0 &&
						group.params.map((child) =>
							group?.code === 'city' ? (
								child?.params?.length > 0 && (
									<React.Fragment key={child.name}>
										<CheckboxButton
											isGroup
											onClick={handleChangeArea(
												groupIDs(child.params),
												arraysCompare(groupIDs(child.params), source[targetField]),
											)}
											onLabelClick={handleCollapseGroup(child.name)}
											groupText={getGroupText(child.params.length)}
											label={child.name}
											isOpen={!collapseGroup.includes(child.name)}
											isShow={!collapseGroup.includes(group.name)}
											isActive={arraysCompare(groupIDs(child.params), source[targetField])}
											city={city}
											color={child?.lineColor}
											mode={mode}
											oneColumn
										/>
										{child.params.map((item) => (
											<CheckboxButton
												key={item.name}
												onClick={handleChangeArea(
													[item.id],
													arraysCompare([item.id], source[targetField]),
												)}
												isShow={
													!collapseGroup.includes(group.name) &&
													!collapseGroup.includes(child.name)
												}
												label={item.name}
												isActive={arraysCompare([item.id], source[targetField])}
												city={city}
												color={item?.lineColor}
												mode={mode}
												withColorPoint={mode === 'metro'}
											/>
										))}
									</React.Fragment>
								)
							) : (
								<CheckboxButton
									key={child.name}
									onClick={handleChangeArea(
										[child.id],
										arraysCompare([child.id], source[targetField]),
									)}
									label={child.name}
									isShow={!collapseGroup.includes(group.name)}
									isActive={arraysCompare([child.id], source[targetField])}
									city={city}
									color={child?.lineColor}
									mode={mode}
									withColorPoint={mode === 'metro'}
								/>
							),
						)}
				</React.Fragment>
			),
	);
};
