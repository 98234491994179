/* @flow */
import React, { useContext, useEffect } from 'react';
import type { Node } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Reserving } from './Reserving';
import {
	arrowLoader,
	CHECK_APART_AVAILABILITY,
	FORM_RESERVATION_AND_SEND,
	REQUEST_APART_AVAILABILITY,
	searchLoader,
	SEND_MORTGAGE,
	SENDING_MORTGAGE,
	SENDING_RESERVATION,
	SENDING_RESERVATION_DEVELOPER,
	TAKE_BOOKING_QUEUE,
} from '../helpers';
import { FetchingBody, FetchingDescription, Title } from '../styles';
import Button from '../../Button';
import type { TDeveloper } from '../../../types/apart';
import { SET_ACTION_CALLBACK_POPUP } from '../../../constants/popup';
import type { TDispatch } from '../../../types/core';
import { sendActionCallbackRequest } from '../../../actions/popup';
import { AppContext } from '../../AppContext';
import type { TCRMRequestContext } from '../../../types/misc';
import type { TMortgageData } from '../../../decorators/withCallbackPopup';

type TProps = {
	callbackType: string | null,
	apartData: {
		id: number,
		nmarketId: number,
		rooms_cnt: number,
		number: number | string,
		complex_name: string,
		developer: TDeveloper,
	} | null,
	isReserved: boolean,
	complexId: number,
	context: TCRMRequestContext,
	mortgageData: TMortgageData,
	// closeCallback: () => any,
};

export const Fetching = (props: TProps): Node => {
	const {
		callbackType,
		apartData,
		isReserved,
		context: crmContext,
		complexId,
		mortgageData,
		// closeCallback,
	} = props;
	const { developer } = apartData;

	const dispatch: TDispatch = useDispatch();
	const store = useStore();
	const context = useContext(AppContext);

	const status: string = useSelector((state) => state.popup.actionCallbackPopup.status);
	const queue: number | null = useSelector((state) => state.popup.actionCallbackPopup.queue);

	useEffect(() => {
		if (queue === 1) {
			sendActionCallbackRequest({
				callbackType,
				apartData,
				context: crmContext,
				complexId,
				isReserved,
				mortgageData,
			})(dispatch, store.getState, context);
		}
	}, [queue]);

	const queueCase = (val: number = 1) => (): any => {
		dispatch({ type: SET_ACTION_CALLBACK_POPUP, payload: { queue: val } });
	};

	if (callbackType === 'mortgage') {
		return <Reserving title={SENDING_MORTGAGE} description={SEND_MORTGAGE} loader={arrowLoader} />;
	}

	if (queue === 1) {
		return <Reserving title={TAKE_BOOKING_QUEUE} description={FORM_RESERVATION_AND_SEND} loader={arrowLoader} />;
	}
	if (status === 'Reserved') {
		return (
			<FetchingBody>
				<Title>Квартира забронирована другим покупателем</Title>
				<FetchingDescription>
					Вы можете встать в очередь и если бронирование будет снято, то квартира автоматически забронируется
					за Вами
				</FetchingDescription>
				<Button rounded onClick={queueCase(1)}>
					Встать в очередь
				</Button>
			</FetchingBody>
		);
	}
	if (status === 'Available') {
		return (
			<Reserving
				title={developer && developer.showRefer ? SENDING_RESERVATION_DEVELOPER : SENDING_RESERVATION}
				description={FORM_RESERVATION_AND_SEND}
				loader={arrowLoader}
			/>
		);
	}
	if (apartData.nmarketId) {
		return (
			<Reserving
				title={CHECK_APART_AVAILABILITY}
				description={REQUEST_APART_AVAILABILITY}
				loader={searchLoader}
			/>
		);
	}

	return <Reserving title={SENDING_RESERVATION} description={FORM_RESERVATION_AND_SEND} loader={arrowLoader} />;
};
