/* @flow */

import type { TFilterGlobal } from '../types/filters';
import type { TAction } from '../types/core';
import {
	LIRA_CHANGE_USER_PROFILE,
	LIRA_GET_USER_PROFILE,
	LIRA_SET_SELECTION_PARAMS,
	LIRA_SET_SELECTION_TYPE,
	RESET_ALL_FILTERS,
	RESET_GLOBAL_FILTER,
	SET_GLOBAL_COMPLEX_VIEW,
	SET_GLOBAL_FILTER,
	SET_GLOBAL_SEARCH_MODE,
	SET_GLOBAL_SELECTION_MODE,
	SET_UNI_FILTER_COMPLEX_VIEW,
	SET_UNIVERSAL_LEXS_FILTER,
	SUCCESS,
} from '../constants';

const initialState: TFilterGlobal = {
	calculationType: 'all',
	navigation: 'suggestion',
	sort: 'relevance',
	searchMode: 'normal',
	dataMode: 'normal',
	selectionMode: 'normal',
	selectionType: 'MAIN',
	selectionParams: null,
};

const filterGlobal = (state: TFilterGlobal = initialState, action: TAction): TFilterGlobal => {
	switch (action.type) {
		case SET_GLOBAL_FILTER:
			return { ...state, ...action.payload };

		case SET_UNI_FILTER_COMPLEX_VIEW:
		case SET_GLOBAL_COMPLEX_VIEW:
			return { ...state, isComplexView: action.payload };

		case SET_GLOBAL_SEARCH_MODE:
			return { ...state, searchMode: action.payload };

		case SET_GLOBAL_SELECTION_MODE:
			return { ...state, selectionMode: action.payload };

		case RESET_ALL_FILTERS:
		case RESET_GLOBAL_FILTER:
			return { ...initialState, isComplexView: state?.isComplexView };

		case LIRA_CHANGE_USER_PROFILE + SUCCESS:
		case LIRA_GET_USER_PROFILE + SUCCESS: {
			const { separated: { global = {} } = {} } = action.payload;
			return { ...state, ...global };
		}

		case SET_UNIVERSAL_LEXS_FILTER: {
			const { filterGroup, data } = action.payload;
			if (filterGroup === 'globalParams') {
				return { ...state, ...data };
			}
			return state;
		}

		case LIRA_SET_SELECTION_TYPE: {
			return { ...state, selectionType: action.payload };
		}

		case LIRA_SET_SELECTION_PARAMS: {
			return { ...state, selectionParams: action.payload };
		}

		/* case CHANGE_USER_CITY: {
			return initialState;
		} */

		default:
			return state;
	}
};

export { initialState as globalFilterInitialState };
export default filterGlobal;
