/* @flow */
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';

import { useSelector } from 'react-redux';
import SORT_BAR from './data/choise/sort';
import stations from './data/filter/metro';
import lexsBasicParams from './data/general/lexsBasicParams';
import lexsParams from './data/general/lexsFilterParams';
import type { TRGBColor } from './types/core';
import type { TProfileSplashScreens } from './types/account';
import type { TCities, TCity, TCityConfig } from './types/city';
import type { TSearchItem } from './reducers/search';
import { LIRA_SELECTION_LINK } from './constants';
import type { TRootState } from './types/rootState';

export const RATING_START_COLOR: TRGBColor = {
	r: 210,
	g: 45,
	b: 45,
};

export const RATING_STOP_COLOR = {
	r: 45,
	g: 210,
	b: 45,
};

export const flatten = (arr1) =>
	arr1.reduce((acc, val) => (Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val)), []);

export const getCityIdsGetArray = (city_ids) => `city_ids=${city_ids.join('&city_ids=')}`;

export default async function getSeoData(fetch, page, slug, city_ids = [78, 47], tab) {
	try {
		const seoRes = await fetch(
			slug
				? `/lexsApi/seo?page=${page}&slug=${slug}&${getCityIdsGetArray(city_ids)}${tab ? `&tab=${tab}` : ``}`
				: `/lexsApi/seo?page=${page.slice(-1) === '/' ? page : `${page}/`}&${getCityIdsGetArray(city_ids)}${
						tab ? `&tab=${tab}` : ``
				  }`,
		);
		const response = await seoRes.json();
		return {
			title: response.metaTitle,
			description: response.metaDescription,
			keywords: response.metaKeywords,
		};
	} catch (e) {
		return {};
	}
}

export const handleClick = (func, param) => () => {
	func(param);
};

export const getMysteryLink = (city, category) => {
	let citySlug;
	let categorySlug;

	switch (city) {
		case 77:
			citySlug = 'msk';
			break;
		case 78:
			citySlug = 'spb';
			break;
		default:
			citySlug = 'spb';
	}

	switch (category) {
		case 1:
			categorySlug = 'tainyi-pokupatel';
			break;
		case 2:
			categorySlug = 'tainyi-zhilec';
			break;
		case 3:
		default:
			categorySlug = 'research';
			break;
	}

	return `/${citySlug}/expert/${categorySlug}/`;
};

export const getSearchTitleByType = (type) => {
	switch (type) {
		case 'news':
			return 'НОВОСТИ';
		case 'article':
			return 'ЖУРНАЛ';
		case 'mystery':
		case 'mystery_buyer':
		case 'mystery_tenant':
		case 'analytics':
			return 'ЭКСПЕРТИЗА';
		case 'knowledge':
			return 'ЗНАНИЕ';
		case 'developer':
			return 'ЗАСТРОЙЩИК';
		case 'complex':
			return 'ЖК';
		case 'apartment':
			return 'КВАРТИРА';
		default:
			return '';
	}
};

// TODO: set uppercase in css for getSearchTitleByType and remove this
export const getSearchTitleByTypeNormalCase = (type) => {
	switch (type) {
		case 'news':
			return 'Новости';
		case 'article':
			return 'Журнал';
		case 'mystery_tenant':
			return 'Тайный жилец';
		case 'mystery_buyer':
			return 'Тайный покупатель';
		case 'analytics':
			return 'Исследования';
		case 'knowledge':
			return 'Знание';
		case 'developer':
			return 'Застройщик';
		case 'complex':
			return 'Жилые комплексы';
		case 'apartment':
			return 'Квартиры';
		default:
			return '';
	}
};

export const getCityLinkById = (cityId, citiesArray) =>
	citiesArray.find((city) => city.cityIds.includes(cityId))
		? citiesArray.find((city) => city.cityIds.includes(cityId)).link
		: '';

export const getCityByCityId = (cityId: number, citiesArray: TCities) =>
	citiesArray.find((city: TCity) => city.cityIds.includes(cityId));

export const getOnlySearchLink = (item: TSearchItem, city: TCity): string => {
	switch (item.type) {
		case 'news':
			return `/news/${item.slug}/`;
		case 'article':
			return `/journal/${item.slug}/`;
		case 'mystery_tenant':
			return `/${item?.city?.slug ?? city.link}/expert/tainyi-zhilec/${item.slug}/`;
		case 'mystery_buyer':
			return `/${item?.city?.slug ?? city.link}/expert/tainyi-pokupatel/${item.slug}/`;
		case 'analytics':
			return `/${item?.city?.slug ?? city.link}/expert/research/${item.slug}/`;
		case 'knowledge':
			return `/znanie/${item.slug}/`;
		case 'developer':
			return `/zastroischiki/${item.slug}/`;
		case 'complex':
			return `/${item?.city?.slug ?? city.link}/novostroyki/${item.slug}/`;
		case 'apartment':
			return `/${item?.city?.slug ?? city.link}/apart/${item.id}/`;
		default:
			return null;
	}
};

export const toggleArrayValue = (array, value) => {
	const result = [...array];

	const index = array.indexOf(value);

	if (index === -1) {
		result.push(value);
	} else {
		result.splice(index, 1);
	}

	return result;
};

export async function getStorageData(fetch, url, ids, params = {}) {
	let res;
	try {
		const compareRes = await fetch(url, {
			method: 'POST',
			body: JSON.stringify({ ...params, ids }),
		});
		const { data } = await compareRes.json();
		res = data;
	} catch (e) {
		res = null;
	}
	return res;
}

export async function getPhones(fetch, id) {
	const URL = '/lexsApi/search/developer-phone';
	const res = await fetch(URL, {
		method: 'POST',
		body: JSON.stringify({ id }),
	});

	const { data } = await res.json();
	return !!data.length && data[0].phone ? data[0].phone : null;
}

/**
 * Сохранение метрики
 * @param {Object} eventObject - Объект - событие
 */
export const addDataLayer = (eventObject) => {
	if (typeof window !== 'undefined') {
		const dataLayer = window.dataLayer || [];
		dataLayer.push(eventObject);
	}
};

/**
 * Сохранение метрики Yandex
 */
const YA_COUNTER_ID = 31760356;
export const sendYMAnalytics = (...eventParams) => {
	if (typeof window !== 'undefined' && window.yaCounter31760356) {
		window.ym(YA_COUNTER_ID, ...eventParams);
	}
};

/**
 * Получение listing_pagetype для СЕО по урлу
 * @param {string} path
 * @param {string} links
 * @returns {string} - pagetype из набора
 */
// https://livingru.atlassian.net/browse/DEV-1163
export const getPagetype = (path, links) => {
	if (path === '/' || links.includes(path)) return 'home';
	if (path.includes('/apart/')) return 'product';
	if (path.match(/novostroyki\/(.*?)\//)) return 'category';
	if (path.includes('/lexs/results/')) return 'searchresults';
	return 'other';
};

// https://livingru.atlassian.net/browse/DEV-1925
export const getCRTO = (pageType, email) => {
	if (pageType === 'home') {
		return {
			event: 'crto_homepage',
			crto: {
				email: email || '', // может быть пустой строкой
			},
		};
	}
	return {};
};

export const getUrl = () => (typeof window !== 'undefined' ? window.location.href : '');

export const getOriginUrl = () => (typeof window !== 'undefined' ? window.location.origin : '');

export const getActiveSelectionPath = (splashScreens: TProfileSplashScreens, forceSteps: boolean = false): string => {
	const { mainShown, apartShown } = splashScreens;
	// if (!mainShown) return 'start';
	if (!apartShown) return 'filter';
	return '';
};

export const getSelectionFullLink = (cityObject: TCity = null) => {
	const city: TCity = useSelector((state) => state.city);
	const splashScreens: TProfileSplashScreens = useSelector((state) => state.profile.splashScreens);
	const forceSteps: boolean = useSelector((state) => state.profile.forceSteps);

	return `/${cityObject ? cityObject.link : city.link}/selection/${getActiveSelectionPath(
		splashScreens,
		forceSteps,
	)}`;
};

export const getLiraFullLink = (cityObject: TCity = null) => {
	const city: TCity = useSelector((state: TRootState) => state.city);
	const liraFirstEntry: boolean = useSelector((state: TRootState) => state.lira.user.firstEntry);

	if (liraFirstEntry) return `/${cityObject ? cityObject.link : city.link}/lira/`;

	return `/${cityObject ? cityObject.link : city.link}/${LIRA_SELECTION_LINK}`;
};

export const getSelectionLinkItem = (citySlug: string, activeSelection: string = '') => ({
	id: 0,
	link: `/${citySlug}/selection/${activeSelection}`,
	route: 'tinderoid',
	title: 'Подбор',
	dataQa: 'selection',
});

export const getHeaderNavigationUrls = (citySlug: string, selectionLinkItem = {}) => [
	selectionLinkItem,
	{
		id: 1,
		route: 'cabinet-finance',
		link: `/cabinet/finance/`,
		title: 'Ипотека', // Мои финансы
		external: true, // To reload state in the lpro app
		dataQa: 'mortgage',
	},
	{
		id: 2,
		route: 'cabinet',
		link: `/cabinet/`,
		title: 'К покупке',
		external: true, // To reload state in the lpro app
		dataQa: 'buy',
	},
	{
		id: 3,
		link: `/${citySlug}/articles/`,
		route: 'articles',
		title: 'Журнал',
		dataQa: 'articles',
	},
	{
		id: 4,
		link: `//about.living.ru`,
		title: 'О проекте', // Мои финансы
		external: true,
		isBlank: true,
		dataQa: 'about',
	},
	{
		id: 5,
		link: `/compare/`,
		route: 'compare',
		title: 'Сравнение',
		dataQa: 'compare',
	},
];

export const getFooterUrls = (citySlug: string, cityConfig: TCityConfig, selectionLinkItem = {}) => {
	const column1 = [selectionLinkItem];

	if (!cityConfig || cityConfig.availableContent.hasDevelopers) {
		column1.push({
			title: 'Застройщики',
			link: `/${citySlug}/zastroischiki/`,
			id: 4,
			dataQa: 'developers',
		});
	}

	const column2 = [
		{
			title: 'Публичная оферта',
			link: '/publicnaa-oferta/',
			id: 8,
			dataQa: 'offer',
		},
		{
			title: 'Политика конфиденциальности',
			link: '/soglasie-na-obrabotku-personalnyh-dannyh/',
			id: 14,
			dataQa: 'privacy',
		},
	];

	return [column1, column2];
};

/**
 * Склонение слова от количества
 * @param {number} number - Количество
 * @param {[string, string, string]} words - Массив склоненных слов
 * @returns {string} - Нужное слово из набора
 * @example
 * // 'квартира'
 * plural(1, ['квартира', 'квартиры', 'квартир']);
 * // 'квартиры'
 * plural(2, ['квартира', 'квартиры', 'квартир']);
 */
export const plural = (number, words) => {
	const cases = [2, 0, 1, 1, 1, 2];
	return words[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};

/**
 * Расчет ежемесячного платежа по ипотеке
 * @param {number} sum - Сумма ипотеки
 * @param {number} period - Срок в годах
 * @param {number} rate - Годовая процентная ставка
 * @returns {number}
 * @example
 * // returns 87451
 * getMortgagePayment(1000000, 1, 9)
 */
export function getMortgagePayment(sum, period, rate) {
	// ставка в месяц
	const i = parseInt(rate, 10) / 12 / 100;
	const p = parseInt(period, 10);
	// коэффициент аннуитета
	const k = (i * (1 + i) ** (p * 12)) / ((1 + i) ** (p * 12) - 1);
	const payment = parseInt(sum, 10) * k;

	return parseInt(payment, 10);
}

export const getMortgagePrice = (downPayment, period, payment, rate) => {
	// ставка в месяц
	const i = parseInt(rate, 10) / 12 / 100;
	const p = parseInt(period, 10);
	// коэффициент аннуитета
	const k = (i * (1 + i) ** (p * 12)) / ((1 + i) ** (p * 12) - 1);
	const price = parseInt(payment, 10) / k + downPayment;

	return parseInt(price, 10);
};

/**
 * Трансформация массива в мап
 * @param {Array} arr
 * @param {string} byField
 * @returns {Object}
 *
 * @example
 * // returns {111: {id: 111, foo: 'bar'}, 222: {id: 222, foo: 'baz'}}
 * arrayToMap([{id: 111, foo: 'bar'}, {id: 222, foo: 'baz'}])
 *
 * // returns {111: {contentId: 111, foo: 'bar'}, 222: {contentId: 222, foo: 'baz'}}
 * arrayToMap([{contentId: 111, foo: 'bar'}, {contentId: 222, foo: 'baz'}], 'contentId');
 */
export function arrayToMap(arr, byField = 'id') {
	return arr.reduce((acc, entity) => ({ ...acc, [entity[byField]]: entity }), {});
}

/**
 * Трансформация массива id в объект с ключами-id
 * @param {Array} arr
 * @returns {Object}
 * @example
 * // returns { 1: { id: 1 }, 2: { id: 2 }}
 * idsArrayToObject([1, 2])
 */
export function idsArrayToObject(arr) {
	const result = {};
	arr.forEach((el) => {
		result[el] = { id: el };
	});
	return result;
}

/**
 * Трансформация массива id в массив объектов с id
 * @param {Array} arr
 * @returns {Array}
 * @example
 * // returns [{ id: 1 }, { id: 2 }]
 * idsArrayToArrayOfObject([1, 2])
 */
export function idsArrayToArrayOfObject(arr) {
	const result = [];
	arr.forEach((el) => {
		result.push({ id: el });
	});
	return result;
}

/**
 * Перевод строки "2,3" в массив [2, 3]
 * Используется для перевода get параметра в массив квартир с отбрасыванием всех значений, кроме 0 - 4, если apart=true.
 * @param string
 * @param apart bool
 * @return {Array} arr
 */
export const transformApartString = (string, apart = false) =>
	apart
		? String(string)
				.split('_')
				.filter((item) => Number(item) >= 0 && Number(item) <= 4)
				.map(Number)
		: string.split('_').map(Number);

export const validQuad = (deadline) => {
	if (!(deadline % 100)) {
		return deadline + 4;
	}
	if (deadline % 100 > 4) {
		// eslint-disable-next-line no-bitwise
		return ~~(deadline / 100) * 100 + 4;
	}
	return deadline;
};

export const getLexsFilterFromQuery = (query) => {
	const filter = {};

	// Main params
	lexsBasicParams.forEach((item) => {
		switch (item.type) {
			case 'array':
				if (query[item.get]) {
					filter[item.value] = transformApartString(query[item.get], item.get === 'rms');
				}
				break;
			case 'price':
				if (query[item.get]) {
					if (item.value === item.get) {
						filter[item.value] = Number(Number(query[item.get]).toFixed(2));
					} else {
						filter[item.value] = Number((Number(query[item.get]) * 1000000).toFixed(2));
					}
				}
				break;
			case 'value':
				if (query[item.get]) {
					if (item.get === 'area' || item.get === 'dlte' || item.get === 'deadlineLte') {
						if (item.get === 'deadlineLte') {
							filter[item.value] =
								Number(query[item.get]) < 300000 ? validQuad(Number(query[item.get])) : 300001;
						} else {
							filter[item.value] = Number(query[item.get]);
						}
					} else {
						filter.keyTransferDateLte = query.date;
					}
				}
				break;
			case 'page':
				if (query[item.get]) {
					filter.page = Number(query[item.get]);
				} else {
					filter.page = 0;
				}
				break;
			case 'string':
				if (query[item.get]) {
					filter[item.value] = query[item.get];
				}
				break;
			default:
				if (query[item.get] && query[item.get] === '1') {
					filter[item.value] = 1;
				}
		}
	});

	// Additional params
	flatten(Object.values(lexsParams)).forEach((item) => {
		if (query[item.get] && query[item.get] === '1') {
			filter[item.value] = 1;
		}
	});

	return filter;
};

export const getAdditionalsParams = (query, choise) => {
	const additionals = {};

	if (query.districts) {
		additionals.areaIds = transformApartString(query.districts);
	}
	if (query.subways) {
		additionals.metroIds = transformApartString(query.subways);
	}

	if (choise) {
		additionals.page = query.page ? Number(query.page) - 1 : 0;

		if (query.sort && SORT_BAR.find((sort) => sort.id === Number(query.sort))) {
			additionals.sort = SORT_BAR.find((sort) => sort.id === Number(query.sort)).sort_field;
		} else {
			additionals.sort = SORT_BAR[1].sort_field;
		}

		if (query.stage_is_put === '1') {
			additionals.keysAvailable = 1;
		}

		if (query.deadline) {
			additionals.deadlineLte = Number(query.deadline);
		}

		if (query.has_report) {
			additionals.hasReport = true;
		}

		additionals.roomsCntForFilter = query.aparts ? transformApartString(query.aparts, true) : [];
	} else {
		if (query.stage_is_put === '1') {
			additionals.stage_is_put = 1;
		}

		if (query.deadline) {
			additionals.deadlineLte = Number(query.deadline);
		}

		additionals.roomsCntArr = query.aparts ? transformApartString(query.aparts, true) : [];
	}

	if (query.price_from) {
		additionals.price_total_min_gte = Number(query.price_from);
	}

	if (query.price_to) {
		additionals.price_total_min_lte = Number(query.price_to);
	}

	if (query.area_from) {
		additionals.area_total_gte = Number(query.area_from);
	}

	if (query.area_to) {
		additionals.area_total_lte = Number(query.area_to);
	}
	// For Housing page
	if (query.building_id) {
		additionals.building_id = query.building_id;
	}

	if (query.area_kitchen_from) {
		additionals.area_kitchen_gte = query.area_kitchen_from;
	}

	if (query.area_kitchen_to) {
		additionals.area_kitchen_lte = query.area_kitchen_to;
	}

	if (query.floor_from) {
		additionals.floorNumberGte = query.floor_from;
	}

	if (query.floor_to) {
		additionals.floorNumberLte = query.floor_to;
	}

	if (query.balcony) {
		additionals.balconyTypes = [query.balcony];
	}

	if (query.bathroom) {
		additionals.bathroomTypes = [query.bathroom];
	}

	return additionals;
};

export const getBody = (filter, city) => {
	const body = {};

	if (filter.search) {
		body.searchString = filter.search;
	}

	body.cityIds = city.cityIds;

	if (filter.inputCost[0]) {
		body.price_total_min_gte = filter.inputCost[0];
	}

	if (filter.inputCost[1]) {
		body.price_total_min_lte = filter.inputCost[1];
	}

	if (filter.inputArea[0]) {
		body.area_total_gte = filter.inputArea[0];
	}

	if (filter.inputArea[1]) {
		body.area_total_lte = filter.inputArea[1];
	}

	if (filter.activeRooms && filter.activeRooms.length) {
		body.roomsCntForFilter = filter.activeRooms;
	}

	if (filter.inputDistricts && filter.inputDistricts.length) {
		body.areaIds = filter.inputDistricts;
	}

	if (filter.inputSubways && filter.inputSubways.length) {
		body.metroIds = filter.inputSubways;
	}

	if (filter.deadline && filter.deadline !== 'empty') {
		body.deadlineLte = filter.deadline;
	}

	if (filter.page) {
		body.page = filter.page - 1;
	}

	body.sort = SORT_BAR.find((sort) => sort.id === filter.sortId)
		? SORT_BAR.find((sort) => sort.id === filter.sortId).sort_field
		: SORT_BAR[1].sort_field;

	return body;
};

export const getColor = (type = '', value, alpha = 1) => {
	const max = type === 'text' ? 100 : 10;
	return `hsla(${(120 * value) / max}, 51%, 52%, ${alpha})`;
};

export const getRatingBackgroundColor = (type = '', value, alpha = 1) => {
	const max = type === 'text' ? 100 : 10;
	return `hsla(${(120 * value) / max}, 58%, 94%, ${alpha})`;
};

export const getColorPoint = (
	val: number,
	alpha: number,
	start: TRGBColor = RATING_START_COLOR,
	stop: TRGBColor = RATING_STOP_COLOR,
): TRGBColor => ({
	r: Math.floor(start.r + val * (stop.r - start.r)),
	g: Math.floor(start.g + val * (stop.g - start.g)),
	b: Math.floor(start.b + val * (stop.b - start.b)),
	a: alpha,
});

export const colorPointToString = (color: TRGBColor): string => `rgb(${color.r}, ${color.g}, ${color.b})`;

export const colorPointToRgbaString = (color: TRGBColor): string =>
	`rgba(${color.r}, ${color.g}, ${color.b}, ${color.a ?? 1})`;

export const getDeadlineString = (deadline) =>
	deadline ? `${deadline.toString().slice(-1)} кв. ${deadline.toString().slice(0, 4)} г.` : 'н/д.';

export const checkQuery = (query, state, choise) => {
	const result = {};

	if (choise) {
		if (SORT_BAR.find((sort) => sort.id === Number(query.sort)) && Number(query.sort) !== state.sortId) {
			result.sortId = Number(query.sort);
		}

		if (!query.sort && state.sortId !== 2) {
			result.sortId = 2;
		}

		result.hasReport = !!query.has_report;

		if (query.page && state.page !== Number(query.page)) {
			result.page = Number(query.page);
		}

		if (!query.page && state.page !== 1) {
			result.page = 1;
		}

		if (!query.section && state.activeSection !== 'complexes') {
			result.activeSection = 'complexes';
		}

		if (query.section && query.section === 'coords' && state.activeSection !== 'coords') {
			result.activeSection = 'coords';
		}

		if (!query.stage_is_put && !query.deadline && state.deadline !== 'empty') {
			result.deadline = 'empty';
		}

		if (query.stage_is_put === '1' && state.deadline !== 1) {
			result.deadline = 1;
		}

		if (query.deadline && state.deadline !== query.deadline) {
			result.deadline = query.deadline;
		}

		const arrayValues = [
			{ state: 'inputDistricts', query: 'districts' },
			{ state: 'inputSubways', query: 'subways' },
		];

		arrayValues.forEach((item) => {
			if (query[item.query]) {
				result[item.state] = transformApartString(query[item.query]);
			} else {
				result[item.state] = [];
			}
		});
	} else {
		['planning_id', 'building_id', 'apartment_id'].forEach((item) => {
			if (state[item] !== query[item]) {
				result[item] = query[item];
			}
		});

		if (!query.stage_is_put && !query.deadline && state.currentDeadline !== 'empty') {
			result.currentDeadline = 'empty';
		}

		if (query.deadline && state.currentDeadline !== Number(query.deadline)) {
			result.currentDeadline = Number(query.deadline);
		}

		if (query.stage_is_put === '1' && state.currentDeadline !== 0) {
			result.currentDeadline = 0;
		}

		['balcony', 'bathroom'].forEach((item) => {
			if (query[item]) {
				if (query[item] !== state[item]) {
					result[item] = query[item];
				}
			} else if (state[item] !== 'empty') result[item] = 'empty';
		});
	}

	if (query.aparts) {
		const queryAparts = transformApartString(query.aparts, true);

		if (!isEqual(queryAparts, state.activeRooms)) {
			result.activeRooms = queryAparts;
		}
	} else if (!isEqual(state.activeRooms, [])) {
		result.activeRooms = [];
	}

	const arrayValues = choise
		? [
				{ state: 'inputCost', query: 'price' },
				{ state: 'inputArea', query: 'area' },
		  ]
		: [
				{ state: 'inputCost', query: 'price' },
				{ state: 'inputArea', query: 'area' },
				{ state: 'inputAreaKitchen', query: 'area_kitchen' },
				{ state: 'inputFloor', query: 'floor' },
		  ];

	arrayValues.forEach((item) => {
		if (
			!isEqual(
				[
					query[`${item.query}_from`] ? Number(query[`${item.query}_from`]) : null,
					query[`${item.query}_to`] ? Number(query[`${item.query}_to`]) : null,
				],
				state[item.state],
			)
		) {
			result[item.state] = [
				query[`${item.query}_from`] ? Number(query[`${item.query}_from`]) : null,
				query[`${item.query}_to`] ? Number(query[`${item.query}_to`]) : null,
			];
		}
	});

	return result;
};

export const getSubwayStationsByLink = (cityLink, stationsIds) => {
	if (!stations[cityLink]) return [];
	const result = flatten(
		Object.keys(stations[cityLink]).map((line) =>
			stations[cityLink][line].map((station) => ({ ...station, line })),
		),
	);
	return result.filter((station) => stationsIds.includes(station.id));
};

/**
 * Форматирование строки района/метро
 * @param {Array} area - Массив ID районов
 * @param {Array} metro - Массив ID станций метро
 * @returns {string}
 */
export function formatArea(area = [], metro = []) {
	if (!area.length) {
		return `${metro.length} метро`;
	}

	if (!metro.length) {
		return `${area.length} ${plural(area.length, ['район', 'района', 'районов'])}`;
	}

	return `${area.length} ${plural(area.length, ['район', 'района', 'районов'])}/${metro.length} метро`;
}

/**
 * Форматирование строки даты передачи ключей
 * @param {string} date - Дата передачи ключей
 * @param {string} delimiter - Разделитель даты
 * @returns {string}
 * @example
 * // returns '07.2018'
 * formatKeyTransferDate('2018-07-31')
 */
export const formatKeyTransferDate = (date, delimiter = '.') => {
	const d = new Date(date);

	return `${d.getMonth() + 1}${delimiter}${d.getFullYear()}`;
};

/**
 * Форматирования строки квартир
 * @param {Array} rooms - Массив комнатности
 * @returns {string}
 * // returns 'Ст, 4к+'
 * formatRooms([4, 0]])
 */
export function formatRooms(rooms, options = {}) {
	const { post = 'к', separator = ',' } = options;

	const ROOMS = {
		0: 'Ст',
		1: `1${post}`,
		2: `2${post}`,
		3: `3${post}`,
		4: `4${post}+`,
	};

	return [...rooms]
		.sort()
		.map((room) => `${ROOMS[room]}`)
		.join(separator);
}

// Функция для фильтрации районов в зависимости от id переданного пресета
export const getDistrictsById = (array, id) => {
	let result = [];
	if (id === 1) {
		result = array;
	} else if (id === 2) {
		result = array.filter((district) => district.filter !== 7);
	} else if (id === 6) {
		result = array.filter((district) => district.filter !== 5 && district.filter !== 7);
	} else {
		result = array.filter((district) => district.filter === id);
	}
	return result.map((item) => item.id);
};

export const getCity = (citySlug, cities) => cities.find((city) => city.link === citySlug);

export const getFinishingPrice = (finishing, totalArea) => {
	let price;

	if (finishing === 'Предчистовая') {
		price = totalArea * 6900;
	}

	if (finishing === 'Без отделки') {
		price = totalArea * 9900;
	}

	return price;
};

export const getHistoryLink = (values, removePage = false, link) => {
	// ToDo  Remove URL constructor
	const url = link ? new URL(link, window.location.href) : new URL(document.location);

	values.forEach((item) => {
		switch (item.type) {
			// choise-new route
			case 'page':
				if (item.value === 1) {
					url.searchParams.delete(item.get);
				} else {
					url.searchParams.set(item.get, item.value);
				}
				break;
			case 'bool':
				if (item.value) {
					url.searchParams.set(item.get, '1');
				} else {
					url.searchParams.delete(item.get);
				}
				break;
			case 'section':
				if (item.value === 'coords') {
					url.searchParams.delete(item.get);
				} else {
					url.searchParams.set(item.get, item.value);
				}
				break;
			case 'sort':
				if (item.value === 2) {
					url.searchParams.delete(item.get);
				} else {
					url.searchParams.set(item.get, item.value);
				}
				break;
			case 'complex_item_deadline':
				if (item.value && item.value === 1) {
					url.searchParams.set('stage_is_put', '1');
				}
				break;
			case 'array':
				if (item?.value?.length) {
					url.searchParams.set(item.get, item.value.join('_'));
				} else {
					url.searchParams.delete(item.get);
				}
				break;
			case 'deadline':
				if (item.value === 'empty') {
					url.searchParams.delete('deadline');
				} else {
					url.searchParams.set('deadline', item.value);
				}
				break;
			case 'slider':
				if (item.min === (item.price ? item.input[0] / 1000000 : item.input[0])) {
					url.searchParams.delete(item.get_min);
				} else {
					url.searchParams.set(item.get_min, item.input[0].toFixed(item.toFixed ? item.toFixed : 0));
				}

				if (item.max === (item.price ? item.input[1] / 1000000 : item.input[1])) {
					url.searchParams.delete(item.get_max);
				} else {
					url.searchParams.set(item.get_max, item.input[1].toFixed(item.toFixed ? item.toFixed : 0));
				}
				break;
			default:
				if (item.value && item.value !== 'empty') {
					url.searchParams.set(
						item.get,
						item.price ? (Math.trunc((item.value / 1000000) * 10) / 10).toString() : item.value,
					);
				} else {
					url.searchParams.delete(item.get);
				}
		}
	});

	if (removePage) {
		url.searchParams.delete('page');
	}

	return `${url.pathname}${url.search}`;
};

/**
 * Форматирование цены
 * @param {number} price - Цена в миллионах
 * @returns {string}
 */
export const formatPrice = (price) => Math.trunc((price / 1000000) * 10) / 10;

export const serializeObjectToUrl = (obj) =>
	Object.entries(obj)
		.map(([key, val]) => `${key}=${val}`)
		.join('&');

export const serializeObjctToQueryString = (obj) => {
	const string = serializeObjectToUrl(obj);
	return string ? `?${string}` : '';
};

export const getHistoryLexsLink = (filter, link, extraParams = {}): string => {
	const { selectionHistoryId, finish } = extraParams;

	const result = {};

	[...flatten(Object.values(lexsParams)), ...lexsBasicParams].forEach((item) => {
		switch (item.type) {
			case 'array':
				if (filter[item.value] && filter[item.value].length) {
					result[item.get] = filter[item.value].join('_');
				}
				break;
			case 'price':
				if (filter[item.value]) result[item.get] = Math.trunc(filter[item.value] * 10) / 10;
				break;
			case 'value':
				if ((item.value === 'deadlineLte' && typeof filter[item.value] === 'number') || filter[item.value]) {
					result[item.get] = filter[item.value];
				}
				break;
			case 'string':
			case 'page':
				if (filter[item.value]) {
					// if (item.value === 'navigation' && filter[item.value] === 'suggestion') {
					// 	break;
					// }
					result[item.get] = filter[item.value];
				}
				break;
			default:
				if (filter[item.value]) {
					result[item.get] = '1';
				}
		}
	});

	if (filter.keysAvailable) {
		delete result.date;
	}

	if (selectionHistoryId) {
		result.shid = selectionHistoryId;
	}

	if (finish) {
		result.finish = finish;
	}

	return `${link}?${serializeObjectToUrl(result)}`;
};

export const renderLexsExtraText = (extra) => {
	let text;
	switch (extra) {
		case 'moreExpensive':
			text = 'Чуть дороже';
			break;
		case 'later':
			text = 'Дольше ждать';
			break;
		default:
			text = 'Другая локация';
	}
	return text;
};

export const getQuestionsDataFromXMLParsedObject = (obj) => {
	const getObject = (array) => {
		let result = {};

		// Первый элемент массива - описание вопроса
		result = { ...array[0] };

		array.slice(1).forEach((item) => {
			Object.keys(item).forEach((key) => {
				result = { ...result, [key]: item[key].map((val) => val._attributes) };
			});
		});

		return result;
	};
	const { settings, question_tree } = obj.body;
	const { question } = question_tree;
	return question.map((item) => getObject(Object.values(item)));
};

export const camelCase = (obj) => {
	const changeBoolToNum = (bool) => (bool ? 1 : 0);

	// Skip booleans values
	const BOOLEANS = ['isIndividual'];

	const newObj = {};
	Object.keys(obj).forEach((key) => {
		newObj[key.replace(/(_\w)/g, (k) => k[1].toUpperCase())] =
			typeof obj[key] === 'boolean' && !BOOLEANS.includes(key) ? changeBoolToNum(obj[key]) : obj[key];
	});
	return newObj;
};

export const realCamesCase = (obj) => {
	const newObj = {};
	Object.keys(obj).forEach((key) => {
		newObj[key.replace(/(_\w)/g, (k) => k[1].toUpperCase())] = obj[key];
	});
	return newObj;
};

/**
 * Конвертация имени параметра из snake_case в camelCase
 * @param {string} name
 * @returns {string}
 */
export const snakeCaseToCamelCase = (name: string = ''): string => {
	if (name) {
		return name.replace(/(_\w)/g, (m) => m[1].toUpperCase());
	}
	return null;
};

export const getComplexesData = (activeCrossSort, complexes) => {
	if (activeCrossSort) {
		return activeCrossSort === 'all'
			? // uniqBy - костыль, если приходят несколько одинаковых ЖК оставляем только один из них
			  uniqBy(
					flatten(
						Object.keys(complexes)
							.filter((key) => !!complexes[key])
							.map((item) => complexes[item].complexList.map((complex) => ({ ...complex, extra: item }))),
					),
					'id',
			  )
			: complexes[activeCrossSort].complexList;
	}
	return complexes && complexes.length && complexes;
};

/**
 * Получение случайного целого числа из промежутка (включая границы)
 * @param {number} min - Минимальное число промежутка
 * @param {number} max - Максимальное число промежутка
 * @returns {number} - Случайно целое число
 *
 * @example
 * // returns 15
 * getRandomInt(10, 20);
 */
export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

// Возвращает объект header, который используется для авторизованных запросов на сервер
export const addJavaHeaders = (token: string = '', base: string = '', noPlatformType: boolean = false) => {
	const javaHeaders = {
		headers: {
			'Content-Type': 'application/json',
			...(!noPlatformType && { 'X-Platform-Type': 'SITE' }),
		},
	};

	if (base) {
		javaHeaders.headers.Authorization = `Base ${base}`;
	} else if (token) {
		javaHeaders.headers.Authorization = `Bearer ${token}`;
	}
	return javaHeaders;
};

export const wait = (amount = 0) => new Promise((resolve) => setTimeout(resolve, amount));

// TODO: add docs
export const groupPriceStatsByMonth = (priceStats) =>
	priceStats.reduce((acc, cur) => {
		const date = new Date(cur.date);
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const curMonth = `${year}-${month < 10 ? `0${month}` : month}`;

		return {
			...acc,
			[curMonth]: acc[curMonth] ? [...acc[curMonth], cur] : [cur],
		};
	}, {});

const getPriceStatsByMonth = (groupedPriceByMonth, { byField, sort = 'avg' }) => {
	const average = (arr) => arr.reduce((acc, cur) => acc + cur[byField], 0) / arr.length;
	const max = (arr) => Math.max(...arr.map((i) => i[byField]));

	const data = [];

	Object.entries(groupedPriceByMonth)
		// Фильтруем полностью пустые месяцы
		.filter((month) => {
			const emptyMonth = month[1].every((day) => !day[byField]);
			return !emptyMonth;
		})
		.forEach((month) => {
			// Отбрасываем пустые дни месяца
			const filteredMonth = month[1].filter((day) => !!day[byField]);
			const date = `${month[0]}-01`;
			if (sort === 'last') {
				data.push([
					new Date(filteredMonth[filteredMonth.length - 1].date),
					Math.round(filteredMonth[filteredMonth.length - 1][byField]),
				]);
			} else if (sort === 'max') {
				data.push([new Date(date), Math.round(max(filteredMonth))]);
			} else {
				data.push([new Date(date), Math.round(average(filteredMonth))]);
			}
		});
	return data;
};

// TODO: fix docs
/**
 * Форматирование массива цен для графика динамики цены квартиры
 * @param {*} priceStats
 * @param {*} byField
 * @param {String} sort
 */
export const formatPriceStatsForChart = (priceStats, byField, sort = 'avg') =>
	getPriceStatsByMonth(groupPriceStatsByMonth(priceStats), { byField, sort });

export const fillEmptyMonths = (priceStats) => {
	if (Array.isArray(priceStats)) {
		const startDate = new Date(priceStats[0][0]);
		const finishDate = new Date(priceStats[priceStats.length - 1][0]);
		const newArray = [];

		let i = 0;
		let tempDate = new Date(startDate);

		while (new Date(tempDate) <= finishDate) {
			let tempElem = null;

			priceStats.forEach((elem) => {
				if (new Date(elem[0]).valueOf() === new Date(tempDate).valueOf()) {
					tempElem = elem[1];
				}
			});

			newArray.push([new Date(tempDate), tempElem]);
			tempDate = startDate.setMonth(startDate.getMonth() + 1);
			i++;
		}

		return newArray;
	}
	return null;
};

/**
 * Округление до заданной точности
 * @param {number} number
 * @param {number} accuracy
 */
export const roundToAccuracy = (number, accuracy = 1) => {
	const grade = 10 ** accuracy;
	return Math.round(number * grade) / grade;
};

export const getQueryObject = (queryString) => {
	const queryObject = {};

	queryString
		.substr(1)
		.split('&')
		.forEach((item) => {
			queryObject[item.split('=')[0]] = decodeURIComponent(item.split('=')[1]);
		});

	return queryObject;
};

export function moveCaretToEnd(ev) {
	const temp = ev.target.value;
	ev.target.value = '';
	ev.target.value = temp;
}

/**
 * Заменяет плейсхолдер в тексте на элемент React
 * @param text
 * @param placeholder
 * @param element
 * @returns {*}
 */
export const changePlaceholderInText = (text: string, placeholder: string, element) => {
	const textArray = text.split(' ');
	const regexObj = new RegExp(placeholder);
	for (let i = 0; i < textArray.length; i++) {
		if (regexObj.test(textArray[i])) {
			textArray[i] = element;
			if (textArray[i + 1]) {
				const buffer = ` ${textArray[i + 1]}`.slice();
				textArray[i + 1] = `${buffer} `;
			}
		} else {
			textArray[i] += ' ';
		}
	}
	return textArray;
};

export const isEmpty = (inp: any): boolean => {
	if (typeof inp === 'object') {
		if (Array.isArray(inp)) return inp.length === 0;
		if (inp === null) return true;
		return Object.keys(inp).length === 0;
	}
	return !inp;
};

export const between = (value: number, min: number, max: number): number => Math.max(min, Math.min(value, max));

export const removeKeyFromObject = (key: string, obj: Object) =>
	Object.keys(obj).reduce((acc, curr) => (curr === key ? acc : { ...acc, [curr]: obj[curr] }), {});

/**
 * Проверка id на принадлежность к "вторичке"
 * @param id - строка вида "n:123456" или "r:123456"
 * @returns {boolean} - вернёт true, если это "вторичка"
 */
export const checkIsResaleById = (id: string) => id[0] === 'r';
