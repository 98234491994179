import styled from 'styled-components';

export const Root = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100%;
	max-height: 100vh;
	max-height: ${({ theme }) => theme.fullHeight};

	@media (min-width: 768px) {
		max-height: calc(100vh - 64px);
		width: 760px;
	}
`;

export const Header = styled.header`
	width: 100%;
	height: 68px;
	flex-shrink: 0;
	padding: 0 16px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: ${({ theme }) => `1px solid ${theme.middleGrayColor}`};
	${({ theme }) => theme.typography.textBoldL};

	@media (min-width: 768px) {
		padding: 0 32px;
	}
`;

export const Content = styled.div`
	overflow-y: auto;
	display: flex;
	flex: 1 1 100%;
	flex-direction: column;
	gap: 22px;
	padding: 16px 16px 0;
	scrollbar-width: thin;

	@media (min-width: 768px) {
		padding: 24px 32px 0;
	}

	&::-webkit-scrollbar {
		max-width: 5px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: ${({ theme }) => theme.lightGrayColor};
		-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}
`;

export const SelectImage = styled.svg`
	width: 20px;
	height: 22px;
	margin: 16px 0 16px 18px;
	fill: ${({ theme }) => theme.darkGrayColor};
`;

export const CityWrapper = styled.div`
	display: grid;
	grid-template-columns: 100%;
	grid-gap: 10px;
	align-items: center;
`;

export const TabsWrapper = styled.div`
	flex-shrink: 0;
	max-width: 260px;
`;
