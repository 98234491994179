/* @flow */
import {
	FAIL,
	GET_APP_STAT,
	GET_MORTGAGE_PROGRAMS_STAT,
	SET_MOBILE,
	SET_WINDOW_SIZE,
	SHOW_FOOTER,
	START,
	SUCCESS,
} from '../constants';
import type { TDispatch } from '../types/core';
import { addJavaHeaders } from '../utils';
import { checkStatus, parseJSON } from '../apiUtils';

/**
 * Получение размера экрана
 * @param size - number
 * @returns {{payload: *, type: string}}
 */
export const setWindowSize = (size: number) => ({
	type: SET_WINDOW_SIZE,
	payload: size,
});

export const setMobile = (isMobile: boolean) => ({
	type: SET_MOBILE,
	payload: isMobile,
});

export const setShowFooter = (value: boolean) => ({
	type: SHOW_FOOTER,
	payload: value,
});

export const getAppStats =
	() =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const { city, token: { token = '' } = {} } = getState();

		dispatch({ type: GET_APP_STAT + START });

		const fetchOptions = {
			method: 'GET',
			...addJavaHeaders(token),
		};

		try {
			await fetch(`/lexsApi/init/stats/active-apartment?cityIds=${city.cityIds.toString()}`, fetchOptions)
				.then(checkStatus)
				.then(parseJSON)
				.then((results) => {
					const { data } = results;
					dispatch({ type: GET_APP_STAT + SUCCESS, payload: data });
				});
		} catch (error) {
			dispatch({ type: GET_APP_STAT + FAIL });
			throw error;
		}
	};

export const getMortgageProgramsStats =
	() =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const { token: { token = '' } = {} } = getState();

		dispatch({ type: GET_MORTGAGE_PROGRAMS_STAT + START });

		const fetchOptions = {
			method: 'GET',
			...addJavaHeaders(token),
		};

		try {
			await fetch(`/lexsApi/mortgage/bank-program-group`, fetchOptions)
				.then(checkStatus)
				.then(parseJSON)
				.then((results) => {
					const { data } = results;
					dispatch({ type: GET_MORTGAGE_PROGRAMS_STAT + SUCCESS, payload: data });
				});
		} catch (error) {
			dispatch({ type: GET_MORTGAGE_PROGRAMS_STAT + FAIL });
			throw error;
		}
	};
