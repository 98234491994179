import { SET_REPORTS, CLEAR_STORE } from '../constants';

const initialState = {
	allReports: {
		entities: [],
		meta: {},
	},
};

export default function reports(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_REPORTS:
			return {
				...state,
				allReports: {
					...state.allReports,
					entities: payload.items,
					meta: payload.meta,
				},
			};

		case CLEAR_STORE:
			return initialState;

		default:
			return state;
	}
}
