import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
	position: sticky;
	bottom: 0;
	z-index: 2;
	margin: 0 -15px;
	padding: 15px;
	width: ${({ $isHome }) => ($isHome ? 'auto' : 'calc(100% + 30px)')};
	box-sizing: border-box;
	background-color: #fff;
	display: flex;
	gap: 16px;
	border-top: ${({ theme }) => `1px solid ${theme.middleGrayColor}`};
	overflow: hidden;
	flex-shrink: 0;

	@media (min-width: 601px) {
		display: flex;
		justify-content: flex-end;
		width: ${({ $isHome }) => ($isHome ? 'auto' : 'calc(100% + 64px)')};
		gap: 24px;
		margin: auto -32px 0;
		padding: 20px 32px;
		border-radius: 0 0 8px 8px;

		& > button {
			padding-left: 48px;
			padding-right: 48px;
		}
	}

	@media (max-width: 601px) {
		display: flex;
		flex-direction: column;
	}
`;

export const Form = styled.div`
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 15px 15px 0;
	box-sizing: border-box;
	background: #fff;

	@media (min-width: 601px) {
		padding: 32px 32px 0;
		border-radius: 8px;
	}
`;

export const ArrowIcon = styled.svg`
	width: 24px;
	height: 24px;
	margin: 0 0 0 24px;
	stroke: #fff;
	stroke-width: 2px;
	fill: transparent;
	transform: rotate(180deg);
`;
