/* @flow */
import type { TAction } from '../types/core';
import { CHANGE_USER_CITY, LIRA_GET_USER_PROFILE, LIRA_SET_STAGE, RESET_ALL_FILTERS, SUCCESS } from '../constants';

export type TLiraStageState = {
	templateId: number,
	state: string,
	title: string,
	relativeLink: string,
	prevLink: string,
	nextLink: string,
	screensCount: number,
	screen: number,
	buttons: boolean,
	isLoaded: boolean,
};

export type TStage = {
	id: number,
	title: string,
	relativeLink: string,
	state: string,
	prevLink: string | null,
	nextLink: string | null,
	buttons: boolean,
};

export const stages: TStage[] = [
	/* {
		id: 0,
		title: 'Выбор подбора',
		state: 'mortgage',
		relativeLink: '/',
		prevLink: null,
		nextLink: '/city',
		buttons: false,
	}, */
	{
		id: 1,
		title: 'Город',
		state: 'city',
		relativeLink: '/city',
		prevLink: '/',
		nextLink: '/filter',
		buttons: false,
	},
	{
		id: 2,
		title: 'Основные параметры',
		state: 'filter',
		relativeLink: '/filter',
		prevLink: '/city',
		nextLink: '/location',
		buttons: true,
	},
	{
		id: 3,
		title: 'Локация',
		state: 'location',
		relativeLink: '/location',
		prevLink: '/filter',
		nextLink: '/portrait',
		buttons: true,
	},
	{
		id: 4,
		title: 'Ваш портрет',
		state: 'portrait',
		relativeLink: '/portrait',
		prevLink: '/location',
		nextLink: '/wishes',
		buttons: true,
	},
	{
		id: 5,
		title: 'Пожелания',
		state: 'wishes',
		relativeLink: '/wishes',
		prevLink: '/portrait',
		nextLink: null,
		buttons: true,
	},
];

const initialState: TLiraStageState = {
	templateId: 1,
	state: 'city',
	title: 'Город',
	relativeLink: '/city',
	prevLink: '/',
	nextLink: '/filter',
	screensCount: stages.length,
	screen: 1,
	buttons: false,
	isLoaded: false,
};

const liraStage = (state: TLiraStageState = initialState, action: TAction): TLiraStageState => {
	switch (action.type) {
		case LIRA_SET_STAGE:
			return {
				...state,
				...action.payload,
				...(action.payload?.id ? { screen: action.payload.id } : {}),
			};

		case LIRA_GET_USER_PROFILE + SUCCESS:
			return { ...state, templateId: action.payload?.data?.templateId ?? null, isLoaded: true };

		case CHANGE_USER_CITY:
		case RESET_ALL_FILTERS:
			return initialState;

		default:
			return state;
	}
};

export default liraStage;
export { initialState as liraStageInitialState };
