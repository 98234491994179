import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.div`
	position: relative;
	margin-bottom: 32px;
`;

const CustomCheckbox = styled.span`
	position: absolute;
	top: 2px;
	left: 0;
	width: 15px;
	height: 15px;
	display: inline-block;
	margin-top: 0;
	margin-right: 10px;
	border: 1px solid #d8d8d8;
	border-radius: 2px;
	transition: background-color 0.1s ease;
`;

const Checkbox = styled.input`
	position: absolute;
	z-index: 100;
	top: 0;
	width: 18px;
	height: 18px;
	opacity: 0;
	cursor: pointer;

	&:checked + ${CustomCheckbox} {
	background-color: ${props => props.theme.primaryColor};
	border-color: ${props => props.theme.primaryColor};

	&::before {
		position: absolute;
		top: 3px;
		left: 2px;
		content: '';
		width: 8px;
		height: 4px;
		border-left: 2px solid #fff;
		border-bottom: 2px solid #fff;
		transform: rotate(-45deg);
	}
`;

const Label = styled.label`
	margin-left: 24px;
	font-size: 14px;
	font-weight: 500;
`;

const AgreeCheckbox = ({ checked = false, onChange }) => (
	<Root>
		<Checkbox id="rules" name="rules" type="checkbox" checked={checked} required onChange={onChange} /> <CustomCheckbox />
		<Label htmlFor="rules">
			Я ознакомлен с <a href="/soglasie-na-obrabotku-personalnyh-dannyh/">правилами</a> обработки персональных
			данных
		</Label>
	</Root>
);

AgreeCheckbox.propTypes = {
	checked: PropTypes.bool,
	onChange: PropTypes.func,
};

AgreeCheckbox.defaultProps = {
	checked: false,
	onChange: () => {},
};

export default AgreeCheckbox;
