import {
	KNOWLEDGE_FETCH_ARTICLES_DATA,
	KNOWLEDGE_FETCH_MORE_ARTICLES_DATA,
	KNOWLEDGE_FETCH_SEARCH_ARTICLES_DATA,
	KNOWLEDGE_SET_ROUTE_TYPE,
	KNOWLEDGE_CLEAR,
} from '../constants/knowledge';
import { FAIL, START, SUCCESS } from '../constants';
import type { TArticle, TCategory } from '../types/articles';

export type TKnowledgeState = {
	articles: TArticle[],
	categoryList: TCategory[],
	currentPage: number,
	pageCount: number,
	type: string,
	isLoading: boolean,
	isLoaded: boolean,
};

const initialState: TKnowledgeState = {
	articles: [],
	categoryList: [],
	currentPage: 0,
	pageCount: 0,
	type: null,
	isLoading: false,
	isLoaded: false,
};

const reducer = (state: TKnowledgeState = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case KNOWLEDGE_FETCH_ARTICLES_DATA + START:
		case KNOWLEDGE_FETCH_MORE_ARTICLES_DATA + START:
		case KNOWLEDGE_FETCH_SEARCH_ARTICLES_DATA + START:
			return { ...state, isLoading: true, isLoaded: false };

		case KNOWLEDGE_FETCH_ARTICLES_DATA + SUCCESS:
		case KNOWLEDGE_FETCH_MORE_ARTICLES_DATA + SUCCESS: {
			const {
				items,
				categoryList,
				meta: { pageCount, pageNumber },
				type: category,
			} = payload;

			return {
				...state,
				articles: [...state.articles, ...items],
				categoryList: [...categoryList],
				pageCount,
				currentPage: pageNumber,
				type: category,
				isLoading: false,
				isLoaded: true,
			};
		}
		case KNOWLEDGE_FETCH_ARTICLES_DATA + FAIL:
		case KNOWLEDGE_FETCH_MORE_ARTICLES_DATA + FAIL:
		case KNOWLEDGE_FETCH_SEARCH_ARTICLES_DATA + FAIL:
			return { ...state, isLoading: false, isLoaded: false };

		case KNOWLEDGE_FETCH_SEARCH_ARTICLES_DATA + SUCCESS: {
			const {
				items,
				meta: { pageCount, pageNumber },
			} = payload;
			return {
				...state,
				articles: items,
				currentPage: pageNumber,
				pageCount,
				isLoading: false,
				isLoaded: true,
			};
		}

		case KNOWLEDGE_SET_ROUTE_TYPE:
			return { ...state, type: action.payload };

		case KNOWLEDGE_CLEAR:
			return initialState;

		default:
			return state;
	}
};

export default reducer;
