import styled from 'styled-components';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { MEDIA_ON_MOBILE } from '../../constants';

export const Root = styled.article`
	display: grid;
	grid-template-columns: 70% 1fr;
	grid-template-areas:
		'header header'
		'nav nav'
		'slider sidebar'
		'aparts aparts'
		'discounts discounts'
		'map map'
		'rating rating'
		'addition addition';
	grid-gap: 15px 20px;
	width: 100%;
	margin: 20px 0;
	padding: 0 15px;
	box-sizing: border-box;

	@media (min-width: 1430px) {
		margin-left: auto;
		margin-right: auto;
		padding: 0;
		max-width: 1360px;
		grid-template-areas:
			'header header'
			'nav nav'
			'slider sidebar'
			'aparts sidebar'
			'discounts sidebar'
			'map sidebar'
			'rating sidebar'
			'addition sidebar';
	}

	@media (min-width: 1024px) and (max-width: 1280px) {
		grid-template-columns: 63% 1fr;
	}

	@media (max-width: 1023px) {
		grid-template-columns: 100%;
		grid-template-areas:
			'header'
			'nav'
			'slider'
			'sidebar'
			'aparts'
			'discounts'
			'map'
			'rating'
			'addition';
	}

	@media (max-width: 767px) {
		grid-template-areas:
			'header'
			'slider'
			'sidebar'
			'aparts'
			'discounts'
			'map'
			'rating'
			'addition';
	}
`;

export const Header = styled.header`
	grid-area: header;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-areas:
		'breadcrumbs breadcrumbs'
		'title title'
		'address share';
	grid-gap: 15px 20px;

	@media (max-width: 1024px) {
		grid-template-columns: auto 1fr;
		grid-template-areas:
			'breadcrumbs breadcrumbs'
			'title title'
			'address address'
			'share .';
	}

	@media ${MEDIA_ON_MOBILE} {
		grid-template-columns: 1fr auto;
		grid-template-areas:
			'breadcrumbs share'
			'title title'
			'address address';
	}
`;

export const Sidebar = styled.div`
	grid-area: sidebar;
	display: flex;
	flex-direction: column;
`;

export const ComplexBreadcrumbs = styled(Breadcrumbs)`
	grid-area: breadcrumbs;
`;

export const NavWrapper = styled.div`
	grid-area: nav;
	min-height: 48px;

	@media (max-width: 767px) {
		display: none;
	}
`;

export const Title = styled.h1`
	grid-area: title;
	margin: 0;
	hyphens: auto;
	hyphenate-limit-chars: 6 3 2;
	word-break: break-all;
	${({ theme }) => theme.typography.headlineXXL};

	@media (max-width: 768px) {
		${({ theme }) => theme.typography.headlineXXLmobile};
	}
`;

export const Title2 = styled.h2`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 40px 0 30px;
	${({ theme }) => theme.typography.headlineXS};

	a:hover {
		text-decoration: none;
	}

	@media ${MEDIA_ON_MOBILE} {
		margin: 35px 0 15px;
	}
`;

export const Address = styled.div`
	grid-area: address;
	display: flex;
	width: 100%;
	margin: 0;
	flex-direction: column;

	a {
		text-decoration: none;
		transition: color 0.2s;
	}
`;

export const AddressLine = styled.p`
	margin: 0;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 14px;
	line-height: 1.43;

	&:not(:last-child) {
		margin-bottom: 5px;
	}

	@media (max-width: 480px) {
		font-size: 12px;
	}

	a {
		text-decoration: underline;
	}

	& > * {
		flex-shrink: 0;
	}
`;

export const ShareWrapper = styled.div`
	grid-area: share;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 10px;
	justify-self: start;
`;

export const SliderWrapper = styled.div`
	grid-area: slider;
`;

export const ApartsWrapper = styled.div`
	grid-area: aparts;
`;

export const DiscountWrapper = styled.div`
	grid-area: discounts;
`;

export const MapWrapper = styled.div`
	grid-area: map;
`;

export const MapRoot = styled.section`
	position: relative;
	z-index: 1;
	width: 100%;
	height: 500px;

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column-reverse;
		height: 30vh;
	}
`;

export const RatingWrapper = styled.div`
	grid-area: rating;
`;

export const AdditionWrapper = styled.div`
	grid-area: addition;
	display: grid;
	grid-gap: 20px;
	grid-auto-flow: row;
`;

export const ArticlesWrapper = styled.div`
	display: grid;
	grid-gap: 10px;
	grid-auto-flow: row;

	@media ${MEDIA_ON_MOBILE} {
		grid-auto-flow: column;
	}
`;

export const ScrolledWrapper = styled.div`
	@media ${MEDIA_ON_MOBILE} {
		width: 100vw;
		margin: 0 -15px;
		padding: 0 15px 2px;
		overflow-x: auto;
		box-sizing: border-box;
	}
`;

export const SpacedTitle = styled(Title2)`
	justify-content: space-between;
	align-items: center;
`;

export const Title2Link = styled.a`
	padding: 0;
	text-decoration: underline;
	white-space: nowrap;
	vertical-align: text-top;
	color: ${({ theme }) => theme.darkGrayColor};
	border: none;
	background-color: transparent;
	cursor: pointer;
	text-transform: none;
	${({ theme }) => theme.typography.textMediumS};

	&:hover {
		text-decoration: none;
	}
`;

export const ButtonAsLink = styled.button`
	display: inline-block;
	padding: 0;
	margin: 0;
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
	text-decoration: underline;
	cursor: pointer;
	border: none;
	background: transparent;
	box-shadow: none;
	color: ${({ $colored, theme }) => ($colored ? theme.primaryColor : 'inherit')};

	&:hover {
		text-decoration: none;
	}

	&:focus {
		outline: none;
		color: ${({ $colored, theme }) => ($colored ? theme.primaryBlackColor : theme.primaryColor)};
	}
`;

export const PopupContentWrapper = styled.div`
	max-width: 480px;
	display: flex;
	flex-direction: column;
`;

export const BenefitsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 20px;
	margin-top: 40px;
`;
