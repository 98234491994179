/* @flow */

import { TRealityType } from './filters';
import type { TBounds } from './selectionMap';
import { TApartInfo } from './selectionMap';
import type { TLiraTemplate } from './lira3';

export type TLiraStep = 'filter' | 'portraits' | 'wishes' | 'subscribe' | 'edit';

export type TLiraFilterValues = {
	priceTotalMinGte?: number | null,
	priceTotalMinLte?: number | null,
	deadlineLte: number | null,
	roomsCntForFilter: number[],
	areaIds?: number[] | null,
	metroIds?: number[] | null,
	realtyType: TRealityType,
	isApartHotel: boolean | null,
};

export type TLiraWishInfo = {
	id: number,
	name: string,
	priority: number,
};

export type TLiraUserWish = {
	[key: string]: 'NOT_IMPORTANT' | 'IMPORTANT' | 'VERY_IMPORTANT',
};

export type TLiraUserSubscribe = {
	telegram: boolean,
	email: boolean,
};

export type TLiraSelectionStats = {
	countNewSuggested: number,
	countNoFeedback: number,
	countLiked: number,
	countDisliked: number,
};

export type TLiraSelectionMeta = {
	page: number,
	size: number,
	totalPages: number,
	totalItems: number,
};

export type TLiraUser = {
	portraitId: number | null,
	userWishes: TLiraUserWish[] | null,
	firstEntry: boolean,
	status: 'ACTIVE' | 'STOP',
};

export type TLiraSelectionMapDataItem = {
	apartmentIDs: string[],
	types: string[],
	latitude: number,
	longitude: number,
	priceMin: number,
};

export type TLiraSelectionMap = {
	data: TLiraSelectionMapDataItem[],
	isLoading: boolean,
	isLoaded: boolean,
	blockScreen: boolean,
	needUpdate: boolean,
	softUpdate: boolean,
	bounds: TBounds,
};

export type TLira = {
	step: TLiraStep,
	filter: TLiraFilterValues,
	user: TLiraUser,
	subscribe: TLiraUserSubscribe,
	selection: {
		viewType: {
			desktop: 'list' | 'map',
			mobile: 'grid' | 'map',
		},
		items: TApartInfo[],
		meta: TLiraSelectionMeta,
		stats: TLiraSelectionStats,
		sort: 'SUGGESTION_DATE_ASC' | 'PRICE_DESC' | 'PRICE_ASC' | 'RELEVANCE_DESC' | 'RELEVANCE_ASC',
		page: number,
		isLoading: boolean,
		isLoaded: boolean,
		needUpdate: boolean,
		reloadPage: boolean,
		includeSold: boolean,
	},
	selectionMap: TLiraSelectionMap,
	portraits: {
		data: TLiraTemplate[] | null,
		isLoading: boolean,
		isLoaded: boolean,
	},
	wishesList: {
		data: TLiraWishInfo[] | null,
		isLoading: boolean,
		isLoaded: boolean,
	},
	profileWasReset: boolean,
	showInfoMessage: boolean,
};

export type TLiraTextsItem = {
	text: string,
	title: string,
};

export type TLiraTexts = {
	home: TLiraTexts,
	startFilter: TLiraTextsItem,
	startPortrait: TLiraTextsItem,
	startWishes: TLiraTextsItem,
	startSubscribe: TLiraTextsItem,
	startSubscribeMessage: TLiraTextsItem,
	resultsFirstVisit: TLiraTextsItem,
	resultsTelegramMessage: TLiraTextsItem,
	filter: TLiraTextsItem,
	filterMessageAfterUpdate: TLiraTextsItem,
};
