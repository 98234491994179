/* @flow */
import type { Node } from 'react';
import React from 'react';
import styled from 'styled-components';

type TProps = {
	onChange: () => {},
	checked?: boolean,
};

const Root = styled.input`
	position: relative;
	width: 38px;
	height: 22px;
	visibility: hidden;
	-moz-appearance: initial;

	&::before {
		visibility: visible;
		z-index: 1;
		content: '';
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 11px;
		background-color: ${({ theme }) => theme.secondaryHover};
		transition: 0.1s background-color 0.1s cubic-bezier(0, 0.4, 1, 0.4);
	}

	&::after {
		visibility: visible;
		z-index: 2;
		content: '';
		position: absolute;
		top: 50%;
		left: 4px;
		display: block;
		background-color: #fff;
		width: 14px;
		height: 14px;
		border-radius: 50%;
		transform: translateY(-50%);
		transition: left 0.2s cubic-bezier(0, 0.4, 1, 0.4);
	}

	&:checked {
		&::before {
			background-color: ${({ theme }) => theme.primaryColor};
		}

		&::after {
			left: 19px;
		}
	}
`;

const SwitcherCheckbox = ({ checked = false, onChange }: TProps): Node => (
	<Root type="checkbox" checked={checked} onChange={onChange} />
);

SwitcherCheckbox.defaultProps = {
	checked: false,
};

export default SwitcherCheckbox;
