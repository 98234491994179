/* @flow */
export type TProgressiveDeadlineView = {
	value: number,
	label: string,
};

export type TProgressiveDeadlineOptions = {
	symbol: string,
	word: string,
};

type TWord = {
	short: string,
	full: string,
	genitive: string,
};

const QUARTER_SYMBOL = {
	1: {
		roman: 'I',
		arabic: '1',
	},
	2: {
		roman: 'II',
		arabic: '2',
	},
	3: {
		roman: 'III',
		arabic: '3',
	},
	4: {
		roman: 'IV',
		arabic: '4',
	},
};

const QUARTER_WORD: TWord = {
	short: 'кв.',
	full: 'квартал',
	genitive: 'квартала',
};

const HALF_YEAR_WORD: TWord = {
	short: 'пол.',
	full: 'полугодие',
	genitive: 'полугодия',
};

const YEAR_WORD: TWord = {
	short: 'конец',
	full: 'до конца',
	genitive: 'конца',
};

/**
 * Получение смещения года и квартал текущей точки по смещению квартала относительно стартовой точки
 * @param num
 * @returns {*[]}
 */
const yearAndQuad = (num: number): number[] =>
	num % 4 ? [Math.floor(num / 4), num % 4] : [Math.floor(num / 4) - 1, 4];

/**
 * Генерация пунктов меню сроков сдачи ЖК
 * @param curDate
 * @param options
 * @returns {Generator<{label: string, value: number,}, boolean>}
 */
export function* genProgressiveDeadlines(
	curDate: ?Date = null,
	options: ?TProgressiveDeadlineOptions = {},
): Generator<TProgressiveDeadlineView, boolean> {
	const date: Date = curDate === null ? new Date() : curDate;
	const { symbol = 'roman', word = 'short' }: TProgressiveDeadlineOptions = options;

	// Стартовый год
	const year: number = date.getFullYear();

	// Стартовый квартал
	const quad: number = Math.ceil((date.getMonth() + 1) / 3);

	// Количество точек поквартальной генерации, выводить кварталы текущего и следующего годов
	// Максимальное колличество кварталов 8шт (по 4 на каждый из двух лет), минимальное 5шт (1шт квартал этого года и 4шт следующего года)
	// Если стартовый(текущий) квартал 1, то тогда получаем 9-1=8 кварталов, если стартовый квартал 4, то получаем 9-4=5 кварталов и т.д
	const quadsIdx: number = 9 - quad;

	// Индекс точки генерации
	let idx: number = 0;

	// Смещение квартала точки генерации относительно стартового квартала
	let offset: number = 0;

	// Генерация первой точки
	yield { value: year * 100 + quad, label: `${QUARTER_SYMBOL[quad][symbol]} ${QUARTER_WORD[word]} ${year}` };
	idx += 1;

	// Общее колличество точек генерации. Выводим кварталы текущего и следующего годов (quadsIdx)  и 3 года после них
	const points = quadsIdx + 3;
	// Цикл генерации последующих точек, ограниченный заданным количеством (points)
	while (idx < points) {
		// Генерация квартальных точек
		if (idx < quadsIdx) {
			offset += 1;
			const [y, q]: number[] = yearAndQuad(quad + offset);
			yield {
				value: (year + y) * 100 + q,
				label: `${QUARTER_SYMBOL[q][symbol]} ${QUARTER_WORD[word]} ${year + y}`,
			};
		}

		// Генерация годовых точек
		else {
			// Инкремент смещения и его нормализация до года
			offset += 4 + ((quad + offset) % 4);
			const [y]: number[] = yearAndQuad(quad + offset);
			yield { value: (year + y) * 100 + 4, label: `${YEAR_WORD[word]} ${year + y}` };
		}
		idx += 1;
	}
	return false;
}

export const currentQuarter = () => {
	const date: Date = new Date();
	const year: number = date.getFullYear();
	const quad: number = Math.ceil((date.getMonth() + 1) / 3);
	return year * 100 + quad;
};

/* export const formatDeadline = (deadline: TProgressiveDeadlineView): string =>
	deadline.value < currentQuarter() ? 'Сдано' : deadline.label; */

export const formatDeadline = (deadline: TProgressiveDeadlineView): string => deadline.label;
