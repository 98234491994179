/* @flow */
import React, { type Node, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldMarker, Label, MessageText, StyledInputMaskFull } from '../styles';
import Check from '../../Check';
import { changePhone, changeProfile } from '../../../actions/profile';
import type { TDispatch } from '../../../types/core';
import type { TProfile } from '../../../types/account';
import type { TRootState } from '../../../types/rootState';

type TProps = {
	hiddenText?: string,
	handleKeyPress?: () => any,
	changeHidden?: (string) => any,
	tokenCaptcha?: string,
};

const PhoneStage = ({ hiddenText = '', handleKeyPress, changeHidden, tokenCaptcha }: TProps): Node => {
	const dispatch: TDispatch = useDispatch();
	const profile: TProfile = useSelector((state: TRootState) => state.profile);
	const { phone, phoneVerified, isAgree } = profile;

	const [statePhone, setStatePhone] = useState(phone ?? '');

	useEffect(() => {
		if (statePhone.length === 17) {
			dispatch(changePhone(statePhone));
		}
		if (statePhone.length === 16) {
			dispatch(changePhone(statePhone));
		}
	}, [statePhone]);

	const onHiddenChange = useCallback((event: Event) => {
		if (changeHidden) {
			changeHidden(event.target.value);
		}
	}, []);

	const onChange = useCallback((event: Event): any => {
		setStatePhone(event.target.value);
	}, []);

	const onPersonalChange = useCallback((event: Event): any => {
		dispatch(changeProfile({ isAgree: !!event.target.checked }));
	}, []);

	const handleKeyPressSubmit = useCallback((e) => {
		if (e.key === 'Enter' && phoneVerified && isAgree && tokenCaptcha) {
			handleKeyPress();
		}
	}, []);

	return (
		<>
			<MessageText>Мы отправим SMS с одноразовым паролем на Ваш номер</MessageText>
			<Label htmlFor="name">
				Номер телефона<FieldMarker>&bull;</FieldMarker>
			</Label>
			<input id="user-phone" type="hidden" value={hiddenText} onChange={onHiddenChange} />
			<StyledInputMaskFull
				id="name"
				mask="+7 (999) 999 9999"
				maskChar={null}
				placeholder="+7 (900) 987 1234"
				value={statePhone}
				onChange={onChange}
				type="tel"
				onKeyPress={handleKeyPressSubmit}
				autoFocus
			/>
			<Check id="agree" name="agree" checked={isAgree} personal onChange={onPersonalChange} />
		</>
	);
};

PhoneStage.defaultProps = {
	hiddenText: '',
	handleKeyPress: undefined,
	changeHidden: undefined,
	tokenCaptcha: '',
};

export default PhoneStage;
