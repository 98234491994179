/* @flow */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import loadable from '@loadable/component';
import { Portal } from 'react-portal';
import { PopupContentWrapper, Root } from './styles';
import type { TCity } from '../../types/city';
import type { TFeed } from '../../types/feed';
import { ALLOWED_EVENT_TYPES } from '../Feed/constants';
import type { TToken } from '../../types/account';
import { getUserStatus } from '../../utils/auth';
import { getLiraFullLink, getSelectionFullLink } from '../../utils';
import type { TComparisonState } from '../../types/compare';
import { AppContext } from '../AppContext';
import type { TDispatch } from '../../types/core';
import { afterCityChanged, cityChange } from '../../actions/city';
import auth from '../../decorators/auth';
import MobileHeader from './components/MobileHeader';
import { getLiraApartmentListFull } from '../../actions/lira';
import { TLiraSelectionsState } from '../../reducers/liraSelections';
import type { TRootState } from '../../types/rootState';
import { getCompare } from '../../actions/compare';
import history from '../../history';
import { CLEAR_ACTION_CALLBACK_POPUP } from '../../constants/popup';
import type { TAppState } from '../../reducers/app';
import DesktopHeader from './components/DesktopHeader';

const Modal = loadable(() => import('../Modal/Modal'), { fallback: null, ssr: false });
const PopupSelectCity = loadable(() => import('../PopupSelectCity'), { fallback: null });
const FeedPopup = loadable(() => import('../FeedPopUp'), {
	fallback: null,
	ssr: false,
});
const CallbackPopup = loadable(() => import('../CallbackModalForm'), {
	fallback: null,
	ssr: false,
});

type TProps = {
	route: string,
	stageRoute?: string | null,
	openAuthPopup: () => {},
	withContext?: boolean,
	isApartResale?: boolean,
	logout: () => {},
};

export type TLink = {
	link: string,
	route: string | string[],
	title: string,
};

export type TProfileLink = {
	link: string,
	title: string,
};

const Header = ({ route, stageRoute, openAuthPopup, withContext, isApartResale, logout }: TProps) => {
	const dispatch: TDispatch = useDispatch();
	const store = useStore();
	const context = useContext(AppContext);
	const [showCityChoise, setShowCityChoise] = useState(false);
	const [showNotice, setShowNotice] = useState(false);
	const [showCallback, setShowCallback] = useState(false);

	const app: TAppState = useSelector((state: TRootState) => state.app);
	const city: TCity = useSelector((state: TRootState) => state.city);
	const feed: TFeed = useSelector((state: TRootState) => state.feed);
	const compare: TComparisonState = useSelector((state: TRootState) => state.compare);
	const token: TToken = useSelector((state: TRootState) => state.token);
	const liraSelections: TLiraSelectionsState = useSelector((state: TRootState) => state.liraSelections);
	const liraSelectionsData = liraSelections.data;

	const favCount = liraSelectionsData?.liked?.length;

	const unreadEventsCount =
		!feed.isLoading &&
		feed.events
			.filter((event) => ALLOWED_EVENT_TYPES.includes(event.type))
			.reduce((unreadEventSum, currentEvent) => (!currentEvent.isRead ? unreadEventSum + 1 : unreadEventSum), 0);
	const compareCount = !compare.isLoading && compare?.items?.length;
	const isAnonUser = getUserStatus('anon', token);
	const isAuthUser = getUserStatus('auth', token);

	const handleChangeCity = (cityItem: TCity) => () => {
		if (cityItem.link !== city.link) {
			Promise.all([
				cityChange(cityItem)(dispatch, store, context),
				afterCityChanged(cityItem.cityIds)(dispatch, context),
			]).then();
		}
	};

	useEffect(() => {
		if (!liraSelections.isLoading && liraSelections.needUpdate) {
			getLiraApartmentListFull()(dispatch, store.getState, context);
		}
	}, [liraSelections.needUpdate]);

	useEffect(() => {
		if (!compare.isLoading && compare.needUpdate) {
			getCompare()(dispatch, store.getState, context);
		}
	}, [compare.needUpdate]);

	const LINKS = [
		{
			link: getSelectionFullLink(),
			route: ['tinderoid', 'tinderoid-filter'],
			title: 'Поиск квартиры',
		},
		{
			link: getLiraFullLink(),
			route: 'lira',
			title: 'Персональный подбор',
		},
	];

	const goToLikes = () => {
		history.push(`/${city.link}/selection/likes/`, {
			showPreloader: true,
			prev: history.location,
			y: window.scrollY,
		});
	};

	const closeCallback = () => {
		setShowCallback(false);
		dispatch({ type: CLEAR_ACTION_CALLBACK_POPUP });
	};

	return (
		<Root>
			{app.windowSize <= 1 ? (
				<MobileHeader
					city={city}
					favCount={favCount}
					compareCount={compareCount}
					isAuthUser={isAuthUser}
					isAnonUser={isAnonUser}
					onNoticeClick={setShowNotice}
					unreadEventsCount={unreadEventsCount}
					onCityClick={() => setShowCityChoise(true)}
					onCallbackClick={() => setShowCallback(true)}
					links={LINKS}
					route={route}
					openAuthPopup={openAuthPopup}
					withContext={withContext}
					isApartResale={isApartResale}
					logout={logout}
				/>
			) : (
				<DesktopHeader
					appState={app}
					city={city}
					route={route}
					stageRoute={stageRoute}
					links={LINKS}
					onCityClick={() => setShowCityChoise(true)}
					onCallbackClick={() => setShowCallback(true)}
					favCount={favCount}
					unreadEventsCount={unreadEventsCount}
					compareCount={compareCount}
					isAuthUser={isAuthUser}
					isAnonUser={isAnonUser}
					setShowNotice={setShowNotice}
					openAuthPopup={openAuthPopup}
					goToLikes={goToLikes}
					logout={logout}
				/>
			)}
			<Portal>
				<PopupSelectCity
					visible={showCityChoise}
					handleClosePopup={() => setShowCityChoise(false)}
					handleChangeCity={handleChangeCity}
				/>
				<Modal isFeed isShowing={showNotice} hide={() => setShowNotice(false)}>
					<FeedPopup feed={feed} onClose={() => setShowNotice(false)} />
				</Modal>
				<Modal isShowing={showCallback} hide={closeCallback}>
					<PopupContentWrapper>
						<CallbackPopup callbackType="callback" />
					</PopupContentWrapper>
				</Modal>
			</Portal>
		</Root>
	);
};

Header.defaultProps = {
	stageRoute: null,
	withContext: false,
	isApartResale: false,
};

export default auth(Header);
