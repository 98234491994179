import { ALLOWED_EVENT_TYPES } from '../components/Feed/constants';
import { SET_FEED_READ, SUCCESS, FAIL, LOAD_FEED, START } from '../constants';

import { addJavaHeaders } from '../utils';
import { checkStatus, parseJSON } from '../apiUtils';

export function getFeed() {
	return async (dispatch, getState) => {
		const feedUrl = '/cabinet/newsfeed';
		const { token } = getState().token;

		dispatch({ type: LOAD_FEED + START });

		return fetch(`${window.App.lexsUrl}${feedUrl}`, {
			method: 'POST',
			...addJavaHeaders(token),
			body: JSON.stringify({
				page: 0,
				count: 10,
			}),
		})
			.then(checkStatus)
			.then(parseJSON)
			.then(({ data, result }) => {
				if (result === 'ok') {
					dispatch({
						type: LOAD_FEED + SUCCESS,
						payload: data,
					});
				}
			})
			.catch(() => {
				dispatch({
					type: LOAD_FEED + FAIL,
				});
			});
	};
}

export function setEventsRead(eventsIds) {
	return (dispatch, getState) => {
		const feedSetReadUrl = '/cabinet/newsfeed-set-read';
		const { token } = getState().token;

		fetch(`${window.App.lexsUrl}${feedSetReadUrl}`, {
			method: 'POST',
			...addJavaHeaders(token),
			body: JSON.stringify({
				ids: eventsIds,
			}),
		})
			.then(checkStatus)
			.then(parseJSON)
			.then(({ result }) => {
				if (result === 'ok') {
					dispatch({
						type: SET_FEED_READ + SUCCESS,
						payload: {
							eventsIds,
						},
					});
				} else {
					dispatch({
						type: SET_FEED_READ + FAIL,
					});
				}
			})
			.catch((reason) => {
				console.error('---', 'reason', reason);

				dispatch({
					type: SET_FEED_READ + FAIL,
				});
			});
	};
}

export function setFeedRead() {
	return (dispatch, getState) => {
		const feedEvents = getState().feed.events;
		const eventsIds = feedEvents
			.filter((event) => ALLOWED_EVENT_TYPES.includes(event.type))
			.map((event) => event.id);

		dispatch(setEventsRead(eventsIds));
	};
}
