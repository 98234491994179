import { FAIL, GET_SERVICE_DATA, START, SUCCESS } from '../constants';
import type { TServices } from '../types/services';

const initialState = {
	data: {},
	isLoading: false,
	isLoaded: false,
};

export default function services(state: TServices = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_SERVICE_DATA + START:
			return { ...state, isLoading: true, isLoaded: false };
		case GET_SERVICE_DATA + SUCCESS:
			return { ...state, data: payload, isLoading: false, isLoaded: true };
		case GET_SERVICE_DATA + FAIL:
			return { ...state, isLoading: false, isLoaded: false };
		default:
			return state;
	}
}

export { initialState as servicesInitialState };
