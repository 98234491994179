/* @flow */

import type { TAction } from '../types/core';
import {
	GET_SELECTION_MAP_DATA,
	GET_SIDE_BAR_DATA,
	LIRA_UPDATE_APARTMENT_OPINION,
	SET_SIDE_BAR_SORT,
	SET_SIDEBAR_CLOSE,
	SET_SIDEBAR_NEED_UPDATE,
	SET_SIDEBAR_OPEN,
	SET_SIDEBAR_TAB_VIEW,
	START,
	SUCCESS,
} from '../constants';

import { TApartCardType, TApartInfo } from '../types/selectionMap';

export type TSidebarToggleData = {
	apartIds: string[],
	selectedCluster: number,
	isOpen: boolean,
};

export type TSelectionMapSidebarState = {
	state: TSidebarToggleData,
	sidebarSort: 'PRICE_ASC' | 'RELEVANCE_DESC',
	data: TApartInfo[],
	isLoading: boolean,
	isLoaded: boolean,
	tabView: TApartCardType,
	needUpdate: boolean,
	updateMapAfterClose: boolean,
};

const initialState = {
	state: { apartIds: [], selectedCluster: null, isOpen: false },
	sidebarSort: 'RELEVANCE_DESC',
	data: [],
	isLoading: false,
	isLoaded: false,
	tabView: null,
	needUpdate: false,
	updateMapAfterClose: false,
};
const sidebarAparts = (state: TSelectionMapSidebarState = initialState, action: TAction): TSelectionMapSidebarState => {
	switch (action.type) {
		case GET_SIDE_BAR_DATA + START:
			return { ...state, isLoading: true, isLoaded: false };
		case GET_SIDE_BAR_DATA + SUCCESS:
			return { ...state, ...action.payload, isLoading: false, isLoaded: true, needUpdate: false };
		case SET_SIDE_BAR_SORT:
			return { ...state, sidebarSort: action.payload, needUpdate: true };
		case SET_SIDEBAR_TAB_VIEW:
			return { ...state, tabView: action.payload };
		case LIRA_UPDATE_APARTMENT_OPINION + SUCCESS:
			return { ...state, updateMapAfterClose: true };
		case SET_SIDEBAR_NEED_UPDATE:
			return { ...state, needUpdate: true };
		case GET_SELECTION_MAP_DATA + SUCCESS:
			return { ...state, updateMapAfterClose: false };
		case SET_SIDEBAR_OPEN:
			return { ...state, state: action.payload };
		case SET_SIDEBAR_CLOSE:
			return { ...state, state: initialState.state };
		default:
			return state;
	}
};

export default sidebarAparts;
