import styled, { createGlobalStyle, keyframes } from 'styled-components';

import DrukTextWideBold from '../../../public/fonts/DrukTextWide-Bold.ttf';
import DrukTextWideBoldItalic from '../../../public/fonts/DrukTextWide-BoldItalic.ttf';
import DrukTextWide from '../../../public/fonts/DrukTextWide-Medium.ttf';
import DrukTextWideItalic from '../../../public/fonts/DrukTextWide-MediumItalic.ttf';
import DrukTextWideBoldWoff from '../../../public/fonts/DrukTextWide-Bold.woff';
import DrukTextWideBoldItalicWoff from '../../../public/fonts/DrukTextWide-BoldItalic.woff';
import DrukTextWideWoff from '../../../public/fonts/DrukTextWide-Medium.woff';
import DrukTextWideItalicWoff from '../../../public/fonts/DrukTextWide-MediumItalic.woff';
import DrukTextWideBoldWoff2 from '../../../public/fonts/DrukTextWide-Bold.woff2';
import DrukTextWideBoldItalicWoff2 from '../../../public/fonts/DrukTextWide-BoldItalic.woff2';
import DrukTextWideWoff2 from '../../../public/fonts/DrukTextWide-Medium.woff2';
import DrukTextWideItalicWoff2 from '../../../public/fonts/DrukTextWide-MediumItalic.woff2';

import { basicFont, textFontSizeM, textLineHeightXL } from '../../theme';

const LiraSize = {
	xlarge: '476px',
	large: '360px',
	medium: '320px',
	small: '290px',
};

export const GlobalStyles = createGlobalStyle`
	@font-face {
		font-family: "Druk Text Wide";
		src: local("Druk Text Wide"), url(${DrukTextWideBoldWoff2}) format('woff2'), url(${DrukTextWideBoldWoff}) format('woff'), url(${DrukTextWideBold}) format('truetype');
		font-weight: 700;
		font-display: swap;
	}

	@font-face {
		font-family: "Druk Text Wide";
		src: local("Druk Text Wide"), url(${DrukTextWideBoldItalicWoff2}) format('woff2'), url(${DrukTextWideBoldItalicWoff}) format('woff'), url(${DrukTextWideBoldItalic}) format('truetype');
		font-style: italic;
		font-weight: 700;
		font-display: swap;
	}

	@font-face {
		font-family: "Druk Text Wide";
		src: local("Druk Text Wide"), url(${DrukTextWideWoff2}) format('woff2'), url(${DrukTextWideWoff}) format('woff'), url(${DrukTextWide}) format('truetype');
		font-weight: 500;
		font-display: swap;
	}

	@font-face {
		font-family: "Druk Text Wide";
		src: local("Druk Text Wide"), url(${DrukTextWideItalicWoff2}) format('woff2'), url(${DrukTextWideItalicWoff}) format('woff'), url(${DrukTextWideItalic}) format('truetype');
		font-style: italic;
		font-weight: 500;
		font-display: swap;
	}

	html {
		color: ${({ theme }) => theme.primaryBlackColor};
		font-weight: 400;
		font-size: ${textFontSizeM};
		font-family: ${basicFont};
		line-height: ${textLineHeightXL};
	}

	body {
		margin: 0;
		min-width: 320px;
		max-width: 100vw;
	}

	a {
		color: ${({ theme }) => theme.primaryColor};

		&:hover {
		text-decoration: none;
		}
	}

	.fslightbox {
		&-open {
			overflow: visible !important;
			height: auto !important;
		}
	}

	.grecaptcha-badge {
		position: absolute !important;
		z-index: -1 !important;
		bottom: 0 !important;
		right: 0 !important;
		visibility: hidden;
	}

	.button-cta {
		position: relative;
		display: inline-block;
		padding: 12px 30px;
		margin: 0 auto;
		height: 52px;
		text-decoration: none;
		transition: all 0.2s ease;
		cursor: pointer;
		box-sizing: border-box;
		background-color: #fff;
		color: ${({ theme }) => theme.primaryColor};
		border: ${({ theme }) => `2px solid ${theme.primaryColor}`};
		${({ theme }) => theme.typography.buttonS};

		&.center {
			left: 50%;
			transform: translateX(-50%);
		}

		&.right {
			left: 100%;
			transform: translateX(-100%);
		}

		&.primary {
			background-color: ${({ theme }) => theme.primaryColor};
			color: #fff;
			border: ${({ theme }) => `2px solid ${theme.primaryColor}`};
		}

		&.secondary {
			background-color: ${({ theme }) => theme.secondaryColor};
			color: ${({ theme }) => theme.primaryColor};
			border: ${({ theme }) => `2px solid ${theme.secondaryColor}`};
		}

		&:visited {
			background-color: #fff;
			color: ${({ theme }) => theme.primaryColor};
			border: ${({ theme }) => `2px solid ${theme.primaryColor}`};

			&.primary {
              background-color: ${({ theme }) => theme.primaryColor};
              color: #fff;
              border: ${({ theme }) => `2px solid ${theme.primaryColor}`};
            }

			&.secondary {
				background-color: ${({ theme }) => theme.secondaryColor};
				color: ${({ theme }) => theme.primaryColor};
				border: ${({ theme }) => `2px solid ${theme.secondaryColor}`};
			}
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover {
					background-color: ${({ theme }) => theme.primaryHover};
					color: #fff;
					border: ${({ theme }) => `2px solid ${theme.primaryHover}`};

				&.secondary {
					background-color: ${({ theme }) => theme.secondaryHover};
					color: ${({ theme }) => theme.primaryColor};
					border: ${({ theme }) => `2px solid ${theme.secondaryHover}`};
				}
			}
			&:active {
					background-color: ${({ theme }) => theme.primaryActive};
					color: #fff;
					border: ${`2px solid ${({ theme }) => theme.primaryActive}`};

				&.secondary {
					background-color: ${({ theme }) => theme.secondaryActive};
					color: ${({ theme }) => theme.primaryColor};
					border: ${({ theme }) => `2px solid ${theme.secondaryActive}`};
				}
			}
		}

		@media (hover: none), (pointer: coarse) {
			&:hover,
			&:active {
				background-color: #fff;
				color: ${({ theme }) => theme.primaryColor};
				border: ${({ theme }) => `2px solid ${theme.primaryColor}`};

				&.primary {
					background-color: ${({ theme }) => theme.primaryColor};
					color: #fff;
					border: ${({ theme }) => `2px solid ${theme.primaryColor}`};
				}

				&.secondary {
					background-color: ${({ theme }) => theme.secondaryColor};
					color: ${({ theme }) => theme.primaryColor};
					border: ${({ theme }) => `2px solid ${theme.secondaryColor}`};
				}
			}
		}
	}

	#app {
		position: relative;
	}

	#preloader {
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100vh;
		height: ${({ theme }) => theme.fullHeight};
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		opacity: 0;
		transition: opacity 0.5s;
		overflow: hidden;

		svg {
			width: 130px;
			height: 28px;
			fill: #000;
			animation: preloader 2s ease-in-out infinite;
		}

		&.isVisible {
			opacity: 1 !important;
			z-index: 9001 !important;
		}
	}

	@keyframes preloader {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0.2;
		}
		100% {
			opacity: 1;
		}
	}
`;

export const Root = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	min-height: ${({ theme }) => theme.fullHeight};
	background-color: #fff;
	opacity: ${(props) => (props.isVisible ? 1 : 0)};
	overflow: ${(props) => (props.isVisible ? 'unset' : 'hidden')};
	transition: opacity 0.5s;
	// fix stacking context break
	z-index: 10;
`;

export const Content = styled.div`
	position: relative;
	flex-grow: 1;
	background: ${(props) => (props.bg ? props.bg : 'transparent')};
	${(props) => props.flex && 'display: flex'};
`;

export const Main = styled.main`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 1 100%;
`;

export const Skip = styled.a`
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 16px 12px;
	box-sizing: border-box;
	background: ${({ theme }) => theme.primaryColor};
	color: #fff;
	transform: translateY(-100%);
	text-decoration: none;
	text-align: center;
	z-index: 9999;
	border-radius: 0;
	visibility: hidden;

	&:focus {
		visibility: visible;
		transform: translateY(0);
	}
`;

export const AppBannerWrapper = styled.div`
	position: sticky;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 99;
`;

export const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;

	@media (max-width: 768px) {
		padding-top: 56px;
	}
`;

export const Sidebar = styled.aside`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 90;
	width: ${LiraSize.small};

	@media (max-width: 767px) {
		left: ${({ showLiraMobile }) => (showLiraMobile ? `calc(100% - ${LiraSize.small})` : '100%')};
		right: auto;
		z-index: 1001;
		transition: left 0.5s;
	}

	@media (min-width: 1340px) {
		width: ${LiraSize.medium};
	}

	@media (min-width: 1600px) {
		width: ${LiraSize.large};
	}

	@media (min-width: 1800px) {
		width: ${LiraSize.xlarge};
	}
`;

const PreloaderKeyframes = keyframes`
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
`;

export const Preloader = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	height: ${({ theme }) => theme.fullHeight};
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	z-index: ${({ isVisible }) => (isVisible ? 9000 : '-1')};
	transition: opacity 0.5s;
	overflow: hidden;

	svg {
		width: 130px;
		height: 28px;
		fill: #000;
		animation: ${PreloaderKeyframes} 2s ease-in-out infinite;
	}
`;

export const HideLiraButton = styled.button`
	position: absolute;
	z-index: 1000;
	width: 100%;
	height: 100%;
	display: block;
	background-color: rgba(255, 255, 255, 0.5);
	border: 0;
	box-shadow: none;
	opacity: ${({ show }) => (show ? 1 : 0)};
	transition: opacity 0.3s;
`;

export const PopupContentWrapper = styled.div`
	width: 480px;
	display: flex;
	flex-direction: column;

	@media (max-width: 600px) {
		width: 100%;
	}
`;

export const Container = styled.div`
	width: 100%;
	padding: 0 15px;
	box-sizing: border-box;

	@media (min-width: 1440px) {
		padding: 0;
		margin: 0 auto;
		max-width: 1360px;
	}

	&.liraSelection {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&.liraSelectionHeader {
		@media (max-width: 800px) {
			padding: 10px 15px;
		}
	}

	&.liraSelectionCards {
		@media (max-width: 800px) {
			padding: 12px 0 0;
		}
	}
`;
