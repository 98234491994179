import {
	ADD_LIRA_NOTICES,
	CHANGE_CONTEXT_LIRA_NOTICES,
	FAIL,
	GET_APARTMENT_SELECTION_INFO,
	LIRA_SET_LIVING_BANNER_TEXTS,
	RESALE,
	SET_ADVERT,
	SET_APART_CURRENT_MODAL,
	SET_APARTMENT,
	SET_APARTMENT_INFRASTRUCTURE,
	SET_APARTMENT_ON_MAP,
	SET_DISLIKE_MENU_OPEN,
	SET_LIKE_DISLIKE_STATUS,
	SET_SIMILAR_APART_SORT,
	START,
	SUCCESS,
} from '../constants';
import type { TApartAdverts, TApartData } from '../types/apart';
import { TFilterPopupText } from '../types/filters';

export type TApartment = {
	apartData: TApartData,
	apartDataResale: TApartData,
	userStatus: 'LIKE' | 'DISLIKE' | null,
	dislikeMenuOpen: boolean,
	apartAdvert: TApartAdverts,
	livingBanner: {
		blockText: TFilterPopupText,
	},
	isLoading: boolean,
	isLoaded: boolean,
	similarSort: string,
	currentModal: string,
};

const initialState = {
	apartData: null,
	apartDataResale: null,
	userStatus: null,
	dislikeMenuOpen: false,
	apartAdvert: null,
	livingBanner: null,
	isLoading: false,
	isLoaded: false,
	similarSort: 'PRICE_ASC',
	currentModal: null,
};

export default function apartment(state: TApartment = initialState, action) {
	switch (action.type) {
		case SET_APARTMENT + START:
		case SET_APARTMENT + RESALE + START:
			return { ...state, isLoading: true };

		case SET_APARTMENT + SUCCESS:
			return { ...state, apartData: action.payload, isLoaded: true, isLoading: false };

		case SET_APARTMENT + FAIL:
		case SET_APARTMENT + RESALE + FAIL:
			return { ...state, isLoading: false, isLoaded: false };

		case SET_APARTMENT + RESALE + SUCCESS:
			return { ...state, apartDataResale: action.payload, isLoaded: true, isLoading: false };

		case SET_APARTMENT_ON_MAP + SUCCESS:
			return { ...state, apartData: { ...state.apartData, onMap: action.payload } };

		case SET_APARTMENT_ON_MAP + RESALE + SUCCESS:
			return { ...state, apartDataResale: { ...state.apartDataResale, onMap: action.payload } };

		case SET_APARTMENT_INFRASTRUCTURE + SUCCESS:
			return { ...state, apartData: { ...state.apartData, infrastructure: action.payload } };

		case SET_APARTMENT_INFRASTRUCTURE + RESALE + SUCCESS:
			return { ...state, apartDataResale: { ...state.apartDataResale, infrastructure: action.payload } };

		case GET_APARTMENT_SELECTION_INFO + SUCCESS:
			return { ...state, apartData: { ...state.apartData, selectionInfo: action.payload } };

		case GET_APARTMENT_SELECTION_INFO + RESALE + SUCCESS:
			return { ...state, apartDataResale: { ...state.apartDataResale, selectionInfo: action.payload } };

		case CHANGE_CONTEXT_LIRA_NOTICES:
			return {
				...state,
				apartData: {
					...state.apartData,
					notices: {
						...state.apartData.notices,
						noticeList: action.payload,
					},
				},
			};

		case ADD_LIRA_NOTICES + SUCCESS: {
			const { apartData } = state;
			const { notices } = apartData;
			return { ...state, apartData: { ...apartData, notices: { ...notices, ...action.payload } } };
		}

		case SET_LIKE_DISLIKE_STATUS: {
			return { ...state, userStatus: action.payload };
		}

		case SET_DISLIKE_MENU_OPEN: {
			return { ...state, dislikeMenuOpen: action.payload };
		}

		case SET_ADVERT: {
			return { ...state, apartAdvert: action.payload };
		}

		case LIRA_SET_LIVING_BANNER_TEXTS: {
			return { ...state, livingBanner: action.payload };
		}

		case SET_SIMILAR_APART_SORT: {
			return { ...state, similarSort: action.payload };
		}
		case SET_APART_CURRENT_MODAL: {
			return { ...state, currentModal: action.payload };
		}

		default:
			return state;
	}
}
