/* @flow */
import {
	INITIAL_FEE_DEFAULT,
	PAYMENT_DEFAULT,
	PRICE_TOTAL_MIN_GTE_DEFAULT,
	PRICE_TOTAL_MIN_LTE_DEFAULT,
} from '../data/filter/price';

import {
	CHANGE_FILTER_MAP,
	CHANGE_LIRA_USER_FILTER,
	CHANGE_PROFILE,
	FAIL,
	LIRA_CHANGE_USER_PROFILE,
	LIRA_CREATE_USER_PROFILE,
	LIRA_DELETE_USER_PROFILE,
	LIRA_DISLIKES_SORTING,
	LIRA_GET_APART_INFRASTRUCTURE,
	LIRA_GET_APART_MAP_DATA,
	LIRA_GET_APARTS_LIST,
	LIRA_GET_CITY_AREAS,
	LIRA_GET_CITY_METRO,
	LIRA_GET_INITIAL_DATA,
	LIRA_GET_LIKED_APARTS_LIST_FULL,
	LIRA_GET_STORIES,
	LIRA_GET_USER_PROFILE,
	LIRA_GET_WISHES,
	LIRA_LIKES_SORTING,
	LIRA_SET_INCLUDE_SOLD,
	LIRA_SET_MORTGAGE_PARAMS,
	LIRA_SET_STAGE,
	LIRA_SET_STORY_WATCHED,
	LIRA_STORIES_UPDATE,
	LIRA_UPDATE_APARTMENT_OPINION,
	LIRA_UPDATE_SELECTION_AFTER_VOTE,
	LIRA_UPDATE_WISHES,
	RESET_ALL_FILTERS,
	SET_BASE_FILTER,
	SET_GLOBAL_SELECTION_MODE,
	START,
	SUCCESS,
} from '../constants';

import { addJavaHeaders, snakeCaseToCamelCase } from '../utils';
import type { TDispatch } from '../types/core';
import { combineFilters, separateFilters } from '../utils/filters';
import type { TLiraFavorite, TLiraLocationItem, TLiraSelections } from '../types/lira3';
import { arrayUniqueIntersection } from '../utils/arraysHelpers';
import { TFilterPopupText } from '../types/filters';
import type { TStage } from '../types/apart';
import { stages } from '../reducers/liraStage';
import { getLiraSelectionWishes } from './lira4';

/**
 * Изменение пользовательского фильтра
 * @param {Object} params - Измененные параметры пользовательского фильтра
 */
export function changeLiraUserFilter(params) {
	return {
		type: CHANGE_LIRA_USER_FILTER,
		payload: { ...params },
	};
}

/*
 * LIRA 3.0
 */

export const getLiraInitialFiltersData =
	() =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const {
			city: { cityIds = [] } = {},
			filterBase: { polygonList = [] } = {},
			token: { token = '' } = {},
		} = getState();
		const url: string = `/lexsApi/init/selection/filter`;

		dispatch({ type: LIRA_GET_INITIAL_DATA + START });
		if (cityIds?.length) {
			try {
				const { result, data, error } = await fetch(url, {
					method: 'POST',
					...addJavaHeaders(token),
					body: JSON.stringify({ cityIds, polygonList }),
				}).then((res) => res.json());
				if (result === 'ok') {
					dispatch({
						type: LIRA_GET_INITIAL_DATA + SUCCESS,
						payload: {
							...data,
							priceTotalMinGte: data?.priceTotalMinGte < 0 ? 100000 : data.priceTotalMinGte,
							priceTotalMinLte: data?.priceTotalMinLte < 0 ? 999999999999 : data.priceTotalMinLte,
						},
					});
					return data;
				}
				dispatch({ type: LIRA_GET_INITIAL_DATA + FAIL, error });
				return null;
			} catch (error) {
				dispatch({ type: LIRA_GET_INITIAL_DATA + FAIL, error });
				return null;
			}
		}

		dispatch({ type: LIRA_GET_CITY_AREAS + FAIL, error: 'City configuration error' });
		return null;
	};

export const getCityAreas =
	() =>
	async (dispatch: TDispatch, getState: () => any, { fetch }) => {
		const { city: { cityIds = [] } = {}, token: { token = '' } = {} } = getState();

		dispatch({ type: LIRA_GET_CITY_AREAS + START });
		if (cityIds.length) {
			const cityKladrs = cityIds.join(',');
			const url = `/lexsApi/init/area-list/${cityKladrs}`;

			try {
				const { result, data } = await fetch(url, {
					method: 'GET',
					...addJavaHeaders(token),
				}).then((res) => res.json());

				if (result === 'ok') {
					dispatch({ type: LIRA_GET_CITY_AREAS + SUCCESS, payload: data });
					return data;
				}
				dispatch({ type: LIRA_GET_CITY_AREAS + FAIL, error: 'WTF' });
				return null;
			} catch (error) {
				dispatch({ type: LIRA_GET_CITY_AREAS + FAIL, error });
				return null;
			}
		}

		dispatch({ type: LIRA_GET_CITY_AREAS + FAIL, error: 'City configuration error' });
		return null;
	};

export const getCityMetro =
	() =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): TLiraLocationItem[] => {
		const { city: { cityIds = [] } = {}, token: { token = '' } = {} } = getState();

		dispatch({ type: LIRA_GET_CITY_METRO + START });
		if (cityIds.length) {
			const url = '/lexsApi/init/metro-list';

			try {
				const { result, data, error } = await fetch(url, {
					method: 'POST',
					...addJavaHeaders(token),
					body: JSON.stringify({ cityIds }),
				}).then((res) => res.json());

				if (result === 'ok') {
					dispatch({ type: LIRA_GET_CITY_METRO + SUCCESS, payload: data });
					return data;
				}
				dispatch({ type: LIRA_GET_CITY_METRO + FAIL, error });
				return null;
			} catch (error) {
				dispatch({ type: LIRA_GET_CITY_METRO + FAIL, error });
				return null;
			}
		}

		dispatch({ type: LIRA_GET_CITY_METRO + FAIL, error: 'City configuration error' });
		return null;
	};

export const getLiraWishesKeys = (wishes) =>
	Object.keys(
		Object.keys(wishes)
			.map((key) => wishes[key].map((elem) => elem.params))
			.reduce((acc, curr) => ({ ...acc, ...curr.reduce((a, c) => ({ ...a, ...c })) }), {}),
	);

export const createUserProfile =
	(userToken: string | null = null) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const {
			token: { token = '' } = {},
			city,
			filterBase = {},
			filterExt = {},
			filterGlobal = {},
			liraWishes = {},
			liraTemplates,
			profile,
		} = getState();
		const { cityIds = [78, 47] } = city ?? {};
		const { wishes = {} } = liraTemplates ?? {};
		const mode = filterGlobal.selectionMode ?? 'normal';
		const url = '/lexsApi/selection/swipe/profile';

		dispatch({ type: LIRA_CREATE_USER_PROFILE + START });

		try {
			const body = JSON.stringify({
				cityIds,
				state: 'main',
				...combineFilters(
					filterBase,
					filterExt,
					filterGlobal,
					liraWishes,
					getLiraWishesKeys(wishes ?? {}),
					mode,
					true,
				),
			});
			const { result, data, error } = await fetch(url, {
				method: 'PUT',
				...addJavaHeaders(userToken ?? token),
				body,
			}).then((res) => res.json());

			if (result === 'ok') {
				dispatch({ type: LIRA_CREATE_USER_PROFILE + SUCCESS, payload: data });
				return data;
			}
			dispatch({ type: LIRA_CREATE_USER_PROFILE + FAIL, error });
			return null;
		} catch (error) {
			dispatch({ type: LIRA_CREATE_USER_PROFILE + FAIL, error });
			return null;
		}
	};

/**
 * Изменение профиля подбора
 * @param {object} force - объект содержащий параметры, состояние которых должно отличаться от их состояния в хранилище
 * @returns {(function(TDispatch, function(): *, {fetch: *}): *)|*}
 */
export const changeUserProfile =
	(force: any = {}) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const {
			token: { token = '' } = {},
			city: { cityIds = [] } = {},
			filterBase = {},
			filterExt = {},
			filterGlobal = {},
			filterTemp = {},
			liraWishes = {},
			liraTemplates: { wishes = {}, initials = {} } = {},
			profile: { splashScreens = { mainShown: false, apartShown: false }, templateId } = {},
		} = getState();
		const url = '/lexsApi/selection/swipe/profile';

		const { roomsCnts = [] } = initials ?? {};
		const mode = filterGlobal.selectionMode ?? 'normal';

		dispatch({ type: LIRA_CHANGE_USER_PROFILE + START });

		try {
			const body = JSON.stringify({
				cityIds,
				splashScreens,
				...(templateId ? { templateId } : {}),
				...combineFilters(
					filterBase,
					filterExt,
					filterGlobal,
					liraWishes,
					getLiraWishesKeys(wishes),
					mode,
					true,
				),
				roomsCntForFilter: arrayUniqueIntersection(roomsCnts, filterBase.roomsCntForFilter),
				...(mode === 'mortgage' ? { priceTotalMinLte: null, priceTotalMinGte: null } : {}),
				...(mode === 'normal' && filterBase?.priceTotalMinLte === null
					? { priceTotalMinLte: filterTemp.priceTotalMinLte ?? PRICE_TOTAL_MIN_LTE_DEFAULT }
					: {}),
				...(mode === 'normal' && (filterBase?.priceTotalMinGte === null || filterBase?.priceTotalMinGte === 0)
					? { priceTotalMinGte: filterTemp.priceTotalMinGte ?? PRICE_TOTAL_MIN_GTE_DEFAULT }
					: {}),
				...(filterBase?.mortgageInfo?.initialFee === null
					? { mortgageInfo: { initialFee: INITIAL_FEE_DEFAULT } }
					: {}),
				...(filterBase?.mortgageInfo?.monthlyPayment === null
					? { mortgageInfo: { monthlyPayment: PAYMENT_DEFAULT } }
					: {}),
				...force,
			});
			const { result, data, error } = await fetch(url, {
				method: 'POST',
				...addJavaHeaders(token),
				body,
			}).then((res) => res.json());

			if (result === 'ok') {
				dispatch({
					type: LIRA_CHANGE_USER_PROFILE + SUCCESS,
					payload: { data, separated: separateFilters(data) },
				});
				return data;
			}
			dispatch({ type: LIRA_CHANGE_USER_PROFILE + FAIL, error });
			return null;
		} catch (error) {
			dispatch({ type: LIRA_CHANGE_USER_PROFILE + FAIL, error });
			return null;
		}
	};

export const getUserProfile =
	(userToken: string | null = null) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const { token: { token = '' } = {} } = getState();
		const url = '/lexsApi/selection/swipe/profile';

		dispatch({ type: LIRA_GET_USER_PROFILE + START });

		try {
			const { result, data, error } = await fetch(url, {
				method: 'GET',
				...addJavaHeaders(userToken ?? token),
			}).then((res) => res.json());
			if (result === 'ok') {
				dispatch({
					type: LIRA_GET_USER_PROFILE + SUCCESS,
					payload: { data, separated: separateFilters(data) },
				});
				return data;
			}
			dispatch({ type: LIRA_GET_USER_PROFILE + FAIL, error });
			return null;
		} catch (error) {
			dispatch({ type: LIRA_GET_USER_PROFILE + FAIL, error });
			return null;
		}
	};

export const getUserProfileAnyway =
	(userToken: string | null = null) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		try {
			const profile = await getUserProfile(userToken)(dispatch, getState, { fetch });
			if (!profile) {
				dispatch({
					type: CHANGE_PROFILE,
					payload: { firstVisit: true },
				});
				return await createUserProfile(userToken)(dispatch, getState, { fetch });
			}
			return profile;
		} catch (error) {
			return null;
		}
	};

export const deleteUserProfile =
	() =>
	async (dispatch: TDispatch, getState: () => any, { fetch }) => {
		const { token: { token = '' } = {} } = getState();
		const url = '/lexsApi/selection/swipe/profile';

		dispatch({ type: LIRA_DELETE_USER_PROFILE + START });

		try {
			const { result, error } = await fetch(url, {
				method: 'DELETE',
				...addJavaHeaders(token),
			}).then((res) => res.json());
			if (result === 'ok') {
				dispatch({ type: LIRA_DELETE_USER_PROFILE + SUCCESS, payload: true });
				return true;
			}
			dispatch({ type: LIRA_DELETE_USER_PROFILE + FAIL, error });
			return false;
		} catch (error) {
			dispatch({ type: LIRA_DELETE_USER_PROFILE + FAIL, error });
			return false;
		}
	};

export const getLiraPortraits =
	(selectionType = 'LEXS') =>
	async (dispatch: TDispatch, getState: () => any, { fetch }) => {
		const { token: { token = '' } = {} } = getState();
		const url = `/lexsApi/selection/templates?type=${selectionType}`;

		const actionType = selectionType === 'LEXS' ? 'LIRA_GET_TEMPLATES' : 'LIRA_GET_PORTRAITS';
		dispatch({ type: actionType + START });

		try {
			const { result, data } = await fetch(url, {
				method: 'GET',
				...addJavaHeaders(token),
			}).then((res) => res.json());

			if (result === 'ok') {
				dispatch({ type: actionType + SUCCESS, payload: data });
				return data;
			}
			dispatch({ type: actionType + FAIL, error: 'WTF' });
			return null;
		} catch (error) {
			dispatch({ type: actionType + FAIL, error });
			return null;
		}
	};

export const getLiraWishes =
	() =>
	async (dispatch: TDispatch, getState: () => any, { fetch }) => {
		const { token: { token = '' } = {} } = getState();
		const url = '/lexsApi/selection/swipe/wishes';

		dispatch({ type: LIRA_GET_WISHES + START });

		try {
			const { result, data, error } = await fetch(url, {
				method: 'GET',
				...addJavaHeaders(token),
			}).then((res) => res.json());
			if (result === 'ok') {
				dispatch({ type: LIRA_GET_WISHES + SUCCESS, payload: data });
				return data;
			}
			dispatch({ type: LIRA_GET_WISHES + FAIL, error });
			return null;
		} catch (error) {
			dispatch({ type: LIRA_GET_WISHES + FAIL, error });
			return null;
		}
	};

/**
 *	Получение текстов из БД
 * @param {string} category - категория
 * @param {string[]} keys - массив ключей
 * @param {string} type - константа типа для редюсера
 * * */

export const getTextsFromDatabase =
	(category: string, keys: string[], type: string) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }) => {
		const { token: { token = '' } = {} } = getState();
		let keysString = '';

		keys.forEach((key) => {
			keysString += `&keys=${key}`;
			return null;
		});

		const query = `/lexsApi/init/texts?category=${category}${keysString}`;

		try {
			const { result, data } = await fetch(query, {
				method: 'GET',
				...addJavaHeaders(token),
			}).then((res) => res.json());
			if (result === 'ok') {
				const payload = {};

				if (data?.length > 0) {
					data.forEach((item: TFilterPopupText) => {
						const { key, text, title, url } = item;

						let customText;

						try {
							customText = JSON.parse(text);
						} catch (e) {
							customText = text;
						}

						payload[snakeCaseToCamelCase(key)] = {
							...(customText ? { text: customText } : null),
							...(title ? { title } : null),
							...(url ? { url } : null),
						};
					});
				}
				dispatch({ type, payload });

				return payload;
			}
			return null;
		} catch (error) {
			return null;
		}
	};

export const getLiraNextApartmentInfrastructure =
	(id: string) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const { token: { token = '' } = {}, city: { cityIds = [] } = {} } = getState();
		const url = '/lexsApi/infrastructure/complex';

		dispatch({ type: LIRA_GET_APART_INFRASTRUCTURE + START });

		try {
			const { result, data } = await fetch(url, {
				method: 'POST',
				...addJavaHeaders(token),
				body: JSON.stringify({
					cityIds,
					id,
				}),
			}).then((res) => res.json());
			if (result === 'ok') {
				dispatch({ type: LIRA_GET_APART_INFRASTRUCTURE + SUCCESS, payload: data });
				return data;
			}
			dispatch({ type: LIRA_GET_APART_INFRASTRUCTURE + FAIL, error: 'WTF' });
			return null;
		} catch (error) {
			dispatch({ type: LIRA_GET_APART_INFRASTRUCTURE + FAIL, error });
			return null;
		}
	};

export const getLiraNextApartmentMapData =
	(id: string, complexSlug: string) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const { token: { token = '' } = {}, city: { cityIds = [] } = {} } = getState();
		const url = '/lexsApi/apartment/on-map';

		dispatch({ type: LIRA_GET_APART_MAP_DATA + START });

		try {
			const { result, data } = await fetch(url, {
				method: 'POST',
				...addJavaHeaders(token),
				body: JSON.stringify({
					id,
					cityIds,
					complexSlug,
				}),
			}).then((res) => res.json());
			if (result === 'ok') {
				dispatch({ type: LIRA_GET_APART_MAP_DATA + SUCCESS, payload: data });
				return data;
			}
			dispatch({ type: LIRA_GET_APART_MAP_DATA + FAIL, error: 'WTF' });
			return null;
		} catch (error) {
			dispatch({ type: LIRA_GET_APART_MAP_DATA + FAIL, error });
			return null;
		}
	};

export const updateLiraApartmentOpinion =
	(
		apartmentId: string | null = null,
		action: 'LIKE' | 'DISLIKE' | 'RESET_REACTION' | null,
		apartNotices: string | null = null,
		isLira = false,
	) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): boolean => {
		const {
			token: { token = '' } = {},
			liraPopups: { notices = [] },
		} = getState();
		const apartSelectedNotices = apartNotices ?? notices;
		const body = JSON.stringify({ apartmentId, action, notices: apartSelectedNotices });
		const url = isLira ? '/lexsApi/selection/lira/feedback' : '/lexsApi/selection/swipe/evaluate-variant';

		dispatch({ type: LIRA_UPDATE_APARTMENT_OPINION + START });

		try {
			const { result, error } = await fetch(url, {
				method: 'POST',
				...addJavaHeaders(token),
				body,
			}).then((res) => res.json());
			if (result === 'ok') {
				if (apartSelectedNotices.length > 0) {
					dispatch({ type: LIRA_UPDATE_SELECTION_AFTER_VOTE });
				}
				dispatch({ type: LIRA_UPDATE_APARTMENT_OPINION + SUCCESS, payload: apartmentId });
				return true;
			}
			dispatch({ type: LIRA_UPDATE_APARTMENT_OPINION + FAIL, error });
			return false;
		} catch (error) {
			dispatch({ type: LIRA_UPDATE_APARTMENT_OPINION + FAIL, error });
			return false;
		}
	};

export const getLiraApartmentList =
	(type: 'liked' | 'disliked' = 'liked') =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): TLiraFavorite[] => {
		const {
			token: { token = '' } = {},
			liraSelections: { includeSold = false, likedSort = null, dislikedSort = null } = {},
			city: { cityIds = [] },
		} = getState();

		const paramsArray = [];
		const sort = type === 'disliked' ? dislikedSort : likedSort;
		if (cityIds?.length > 0) paramsArray.push(`cityIds=${cityIds.toString()}`);
		if (sort) paramsArray.push(`sort=${sort}`);
		if (includeSold) paramsArray.push('includeSold=true');

		const params = paramsArray.join('&');
		const url = `/lexsApi/selection/swipe/profile/${type}${paramsArray.length ? `?${params}` : ''}`;

		dispatch({ type: LIRA_GET_APARTS_LIST + START });

		try {
			const { result, data, error }: { result: string, data: TLiraFavorite[], error: any } = await fetch(url, {
				method: 'GET',
				...addJavaHeaders(token),
			}).then((res) => res.json());
			if (result === 'ok') {
				dispatch({ type: LIRA_GET_APARTS_LIST + SUCCESS, payload: { [type]: data } });
				return data;
			}
			dispatch({ type: LIRA_GET_APARTS_LIST + FAIL, error });
			return null;
		} catch (error) {
			dispatch({ type: LIRA_GET_APARTS_LIST + FAIL, error });
			return null;
		}
	};

export const getLiraApartmentListFull =
	() =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): TLiraSelections => {
		dispatch({ type: LIRA_GET_LIKED_APARTS_LIST_FULL + START });
		try {
			const [liked, disliked] = await Promise.all([
				getLiraApartmentList('liked')(dispatch, getState, { fetch }),
				getLiraApartmentList('disliked')(dispatch, getState, { fetch }),
			]);
			dispatch({ type: LIRA_GET_LIKED_APARTS_LIST_FULL + SUCCESS });
			return { liked, disliked };
		} catch {
			dispatch({ type: LIRA_GET_LIKED_APARTS_LIST_FULL + FAIL });
			return null;
		}
	};

export const clearUserProfile =
	() =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		try {
			const deleted: boolean = await deleteUserProfile()(dispatch, getState, { fetch });
			if (deleted) {
				dispatch({ type: RESET_ALL_FILTERS });
				const userProfile = await createUserProfile()(dispatch, getState, { fetch });
				if (userProfile) {
					await getLiraApartmentListFull()(dispatch, getState, { fetch });
				}
				return userProfile;
			}
			return null;
		} catch (error) {
			return null;
		}
	};

export const changeLiraApartmentOpinion =
	(apartmentId: string | null = null, action: 'LIKE' | 'DISLIKE' | 'RESET_REACTION' | null, isLira = false) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const {
			liraPopups: { notices = [], apartNotices },
		} = getState();

		try {
			updateLiraApartmentOpinion(
				apartmentId,
				action,
				apartNotices[apartmentId] ?? notices,
				isLira,
			)(dispatch, getState, {
				fetch,
			});
			return true;
		} catch (error) {
			return null;
		}
	};

export const getLiraInitialData =
	() =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const { city: { hasMetro = false } = {} } = getState();

		// eslint-disable-next-line no-return-await
		return await Promise.all([
			getLiraInitialFiltersData()(dispatch, getState, { fetch }),
			getCityAreas()(dispatch, getState, { fetch }),
			hasMetro ? getCityMetro()(dispatch, getState, { fetch }) : null,
			getLiraPortraits()(dispatch, getState, { fetch }),
			getLiraPortraits('LIRA_4')(dispatch, getState, { fetch }),
			getLiraWishes()(dispatch, getState, { fetch }),
			getLiraSelectionWishes()(dispatch, getState, { fetch }),
		]);
	};

export const getLiraStage = (state: string): TStage => stages?.find((item: TStage) => item.state === state) ?? {};

export const getRelativeLiraStage = (state: string, direction: 'state' | 'forward' | 'back'): TStage => {
	const currStage: TStage = getLiraStage(state);
	const { id = -1 } = currStage;
	if (id < 0) return {};
	if (direction === 'state') {
		return stages.find((item: TStage) => item.state === state);
	}
	if (direction === 'forward' && id < stages.length) {
		return stages.find((item: TStage) => item.id === id + 1);
	}
	if (direction === 'back' && id > 0) {
		return stages.find((item: TStage) => item.id === id - 1);
	}
	return {};
};

export const goToRelativeStage =
	(type: string, state: string) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const stage: TStage = getRelativeLiraStage(state, type);
		dispatch({ type: LIRA_SET_STAGE, payload: { ...stage } });

		try {
			return await changeUserProfile()(dispatch, getState, { fetch });
		} catch (error) {
			return null;
		}
	};

/**
 * Объединяет два объекта содержащих параметры типа Number: если в исходном и объединяемом объектах
 * есть один и тот же параметр, они складываются. В противном случае - помещается в результирующий объект без изменений
 * @param source
 * @param target
 * @returns {*&T}
 */
export const smartConcat = (source, target) => {
	const intermediate = Object.keys(target).reduce(
		(acc, key) => ({
			...acc,
			...(source[key] ? { [key]: source[key] + target[key] } : { [key]: target[key] }),
		}),
		{},
	);
	return { ...source, ...intermediate };
};

/**
 * Получение Stories для Дашборда
 * @param count - количество слайдов, по умолчанию - 12
 * @param page - страница, по умолчанию - 0
 */

export const getSelectionStories =
	(count: number = 12, page: number = 0) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const { token: { token = '' } = {}, liraStories: { needUpdate = false } = {} } = getState();
		const url = `/lexsApi/content/story?count=${count}&page=${page}`;

		dispatch({ type: LIRA_GET_STORIES + START });

		try {
			const { result, data, error } = await fetch(url, {
				method: 'GET',
				...addJavaHeaders(token),
			}).then((res) => res.json());
			if (result === 'ok') {
				dispatch({ type: LIRA_GET_STORIES + SUCCESS, payload: data });
				if (needUpdate) {
					dispatch({ type: LIRA_STORIES_UPDATE, payload: false });
				}
				return data;
			}
			dispatch({ type: LIRA_GET_STORIES + FAIL, error });
			return null;
		} catch (error) {
			dispatch({ type: LIRA_GET_STORIES + FAIL, error });
			return null;
		}
	};

/**
 * Отметить Stories просмотренной
 * @param storyId - ID коллекции Stories
 * @param storyStepId - ID конкретного слайда Stories
 */

export const setSelectionStoriesSeen =
	(storyId: number, storyStepId: number) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const { token: { token = '' } = {} } = getState();
		if (storyId && storyStepId) {
			dispatch({ type: LIRA_SET_STORY_WATCHED + START });
			const url = `/lexsApi/content/story/${storyId}/step`;
			const body = JSON.stringify({ storyStepId });

			try {
				const { result, error } = await fetch(url, {
					method: 'POST',
					...addJavaHeaders(token),
					body,
				}).then((res) => res.json());
				if (result === 'ok') {
					dispatch({ type: LIRA_SET_STORY_WATCHED + SUCCESS });
					return true;
				}
				dispatch({ type: LIRA_SET_STORY_WATCHED + FAIL, error });
				return false;
			} catch (error) {
				dispatch({ type: LIRA_SET_STORY_WATCHED + FAIL, error });
				return false;
			}
		}
		return false;
	};

/**
 * Подстановка параметров подбора из URL
 */

export const getSelectionParamsFromUrl =
	() =>
	async (dispatch: TDispatch, getState: () => any): any => {
		try {
			if (typeof window !== 'undefined') {
				const params = new URLSearchParams(window.location.search);
				const {
					type,
					deadline,
					rooms,
					price,
					priceFrom,
					priceTo,
					initialFee,
					monthlyPayment,
					area,
					metro,
					wishes,
					selection,
				} = Object.fromEntries(params.entries());

				if (
					type ||
					deadline ||
					rooms ||
					price ||
					priceFrom ||
					priceTo ||
					initialFee ||
					monthlyPayment ||
					area ||
					metro ||
					wishes
				) {
					const { profile: { splashScreens = '' } = {} } = getState();
					const realtyType: string = type ? type.toUpperCase() : 'ALL';
					const deadlineLte: number = deadline ? Number(deadline) : 300001;
					const roomsCntForFilter: number[] = rooms ? rooms.split(',').map(Number) : [];
					const priceTotalMinLte: number = priceTo
						? Number(priceTo)
						: price
						? Number(price) * 1000000
						: 6000000;
					const priceTotalMinGte: number = priceFrom
						? Number(priceFrom)
						: Number((priceTotalMinLte * 0.7).toFixed());
					const areaIds: number[] = area ? area.split(',').map(Number) : [];
					const metroIds: number[] = metro ? metro.split(',').map(Number) : [];
					const wishesArray: string[] = wishes ? wishes.split(',') : [];

					dispatch({
						type: CHANGE_PROFILE,
						payload: {
							firstVisit: false,
							splashScreens: { ...splashScreens, mainShown: true },
						},
					});
					dispatch({
						type: SET_BASE_FILTER,
						payload: {
							realtyType,
							deadlineLte,
							roomsCntForFilter,
							priceTotalMinLte,
							priceTotalMinGte,
							areaIds,
							metroIds,
						},
					});
					if (monthlyPayment && initialFee) {
						dispatch({
							type: LIRA_SET_MORTGAGE_PARAMS,
							payload: {
								initialFee,
								monthlyPayment,
							},
						});
					}
					if (selection) {
						dispatch({
							type: SET_GLOBAL_SELECTION_MODE,
							payload: selection,
						});
					}
					if (wishesArray.length > 0) {
						const wishesArrayObject = wishesArray.reduce((a, b) => ({ ...a, [b]: 1 }), {});
						dispatch({ type: LIRA_UPDATE_WISHES, payload: wishesArrayObject });
					}
					dispatch({ type: CHANGE_FILTER_MAP, payload: true });
				}
			}
			return null;
		} catch {
			return null;
		}
	};

export const handleChangeLikesSold = (value) => ({ type: LIRA_SET_INCLUDE_SOLD, payload: value });

export const handleChangeLikesSorting = (page, value) => ({
	type: page === 'likes' ? LIRA_LIKES_SORTING : LIRA_DISLIKES_SORTING,
	payload: value,
});
