import { LOAD_CITIES, SUCCESS } from '../constants';

export default function city(state = [], action) {
	switch (action.type) {
		case `${LOAD_CITIES}${SUCCESS}`:
			return action.payload;

		default:
			return state;
	}
}
