/* @flow */
import React, { type Node, useEffect, useState } from 'react';
import {
	AppButtons,
	Burger,
	BurgerBox,
	BurgerInner,
	Container,
	LogoContainer,
	Menu,
	MenuOverflow,
	MenuWrapper,
	MobileBrand,
	NavButtonMobile,
	NavLinkMobile,
	NavMobileLink,
	NavSection,
	NavSectionTitle,
	Profile,
	ProfileIcon,
	ProfileNavLink,
} from './styles';
import type { TCity } from '../../../../types/city';
import { bell2021Icon, heart2023Icon, heart2023IconFill, Logo, profileUser } from '../../../Icons';
import { Count, NavIcon, Notice } from '../../styles';
import { plural } from '../../../../utils';
import { lockScroll, unlockScroll } from '../../../../DOMUtils';
import type { TLink } from '../../Header';
import Button from '../../../Button2.0';
import MobileAppBtn from '../../../MobileAppBtn';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import ContextMenu from '../ContextMenu';

type TProps = {
	city: TCity,
	favCount: number,
	compareCount: number,
	route: string,
	isAuthUser: boolean,
	isAnonUser: boolean,
	onNoticeClick: () => {},
	unreadEventsCount: number,
	onCityClick: () => {},
	onCallbackClick: () => {},
	links: TLink[],
	openAuthPopup: () => {},
	withContext: boolean,
	isApartResale: boolean,
	logout: () => {},
};

const MobileHeader = ({
	city,
	favCount,
	compareCount,
	route,
	isAuthUser,
	isAnonUser,
	onNoticeClick,
	unreadEventsCount,
	onCityClick,
	onCallbackClick,
	links,
	openAuthPopup,
	withContext,
	isApartResale,
	logout,
}: TProps): Node => {
	const [modal, setModal] = useState();

	const toggleModal = (type: 'burger' | 'context') => {
		if (modal === type) {
			setModal();
		} else {
			setModal(type);
		}
	};

	useEffect(() => {
		if (modal) {
			lockScroll();
		} else {
			unlockScroll();
		}
	}, [modal]);

	useEffect(() => {
		setModal();
		unlockScroll();
	}, [route]);

	return (
		<Container $withContext={withContext}>
			<Burger onClick={() => toggleModal('burger')}>
				<BurgerBox>
					<BurgerInner $isActive={modal === 'burger'} />
				</BurgerBox>
			</Burger>
			{withContext ? (
				<ContextMenu
					route={route}
					isOpen={modal === 'context'}
					setModal={setModal}
					toggleModal={toggleModal}
					isApartResale={isApartResale}
				/>
			) : (
				<>
					<MobileBrand
						to={!city || city.link === 'spb' ? '/' : `/${city.link}/`}
						id="headerLinkLogo"
						data-qa="header_logo"
					>
						<svg viewBox="0 0 315 68">{Logo}</svg>
					</MobileBrand>
					<NavLinkMobile
						to={`/${city.link}/selection/likes/`}
						title="Избранное"
						id="headerLinkFav"
						data-qa="header_favs"
						style={{ gridArea: 'fav' }}
						$isActive={route === 'likes'}
						$isProfile
					>
						<NavIcon>
							<svg viewBox="0 1 24 20">{route === 'likes' ? heart2023IconFill : heart2023Icon}</svg>
							{!!favCount && <Count>{favCount}</Count>}
						</NavIcon>
					</NavLinkMobile>
					<NavButtonMobile
						onClick={() => onNoticeClick(true)}
						title={
							unreadEventsCount
								? `${unreadEventsCount} ${plural(unreadEventsCount, [
										'непрочитанное уведомление',
										'непрочитанных уведомления',
										'непрочитанных уведомлений',
								  ])}`
								: 'У вас нет непрочитанных уведомлений'
						}
						id="headerLinkFeed"
						data-qa="header_notify"
						style={{ gridArea: 'notice' }}
					>
						<NavIcon>
							<svg>{bell2021Icon}</svg>
							{!!unreadEventsCount && <Notice />}
						</NavIcon>
					</NavButtonMobile>
					{isAuthUser ? (
						<ProfileNavLink
							key="profile_mobile"
							to="/profile"
							id="headerMobileLink_profile"
							aria-label="Перейти в профиль"
							$isAuth={isAuthUser}
							$isActive={route === 'profile-main'}
						>
							<ProfileIcon viewBox="0 0 19 22" $isAuth={isAuthUser} $isActive={route === 'profile-main'}>
								{profileUser}
							</ProfileIcon>
						</ProfileNavLink>
					) : (
						<Profile onClick={openAuthPopup}>
							<ProfileIcon viewBox="0 0 19 22" $isAuth={isAuthUser}>
								{profileUser}
							</ProfileIcon>
						</Profile>
					)}
				</>
			)}
			<MenuOverflow $isVisible={modal === 'burger'} onClick={() => setModal()}>
				<MenuWrapper $isVisible={modal === 'burger'}>
					<LogoContainer>
						<MobileBrand
							to={!city || city.link === 'spb' ? '/' : `/${city.link}/`}
							id="headerLinkLogo"
							data-qa="header_logo"
						>
							<svg viewBox="0 0 315 68">{Logo}</svg>
						</MobileBrand>
					</LogoContainer>
					<Menu>
						<NavSection>
							{links.map((link: TLink) => (
								<NavMobileLink to={link.link} key={link.title}>
									{link.title}
								</NavMobileLink>
							))}
							<NavMobileLink to={`/${city.link}/service/`}>Услуги</NavMobileLink>
						</NavSection>
						<NavSection>
							<ProfileMenu
								city={city}
								compareCount={compareCount}
								favCount={favCount}
								isAnonUser={isAnonUser}
								isAuthUser={isAuthUser}
								onCityClick={onCityClick}
								openAuthPopup={openAuthPopup}
								logout={logout}
							/>
						</NavSection>
						<NavSection style={{ padding: '10px' }}>
							<NavMobileLink as="a" href="tel:88007774091" style={{ padding: 0 }}>
								<b>8&nbsp;(800)&nbsp;777-40-91</b>
							</NavMobileLink>
							<Button onClick={onCallbackClick} size="xs" design="rounded">
								Перезвоните мне
							</Button>
						</NavSection>

						<NavSection style={{ padding: '10px' }}>
							<NavSectionTitle>Скачать приложение</NavSectionTitle>
							<AppButtons>
								<MobileAppBtn
									store="android"
									withoutText
									width={131}
									height={36}
									viewBox="0 0 131 36"
									padding="0"
									key={Math.random()}
								/>
								<MobileAppBtn
									store="ios"
									withoutText
									width={131}
									height={36}
									viewBox="0 0 131 36"
									padding="0"
									key={Math.random()}
								/>
							</AppButtons>
						</NavSection>
					</Menu>
				</MenuWrapper>
			</MenuOverflow>
		</Container>
	);
};

export default MobileHeader;
