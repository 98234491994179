/* @flow */
import React, { type Node } from 'react';
import history from '../../history';

type TProps = {
	to: string,
	children: Node,
	showPreloader?: boolean,
	onClick?: (Event) => any,
	style?: any,
	target?: string,
	dataQa?: string,
	className?: string,
	id?: string,
	role?: string,
	tabindex?: number,
	ariaLabel?: string,
};

function isLeftClickEvent(event) {
	return event.button === 0;
}

function isModifiedEvent(event) {
	return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const Link = (props: TProps): Node => {
	const handleClick = (event) => {
		const { onClick, to, showPreloader } = props;
		if (onClick) {
			onClick(event);
		}

		if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
			return;
		}

		if (event.defaultPrevented === true) {
			return;
		}

		if (event.currentTarget.target === '_blank') {
			return;
		}
		event.preventDefault();
		history.push(to, { prev: history.location, showPreloader });
	};

	const { to, children, id, className, dataQa, style, target, role, tabindex, ariaLabel } = props;
	return (
		<a
			href={to}
			id={id}
			className={className}
			data-qa={dataQa}
			onClick={handleClick}
			style={style}
			target={target}
			tabIndex={tabindex}
			role={role}
			aria-label={ariaLabel}
		>
			{children}
		</a>
	);
};

Link.defaultProps = {
	onClick: null,
	showPreloader: true,
	style: undefined,
	id: undefined,
	className: undefined,
	dataQa: undefined,
	target: '_self',
	role: 'link',
	tabindex: 0,
	ariaLabel: undefined,
};

export default Link;
