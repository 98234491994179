/* @flow */
import { keyframes } from 'styled-components';

const sweep = keyframes`
	0% {
		clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
	}

	50% {
		clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
	}

	100% {
		clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
	}
`;

export const accentFont = `"Druk Text Wide", Franklin Gothic, Arial Black, -apple-system, sans-serif`;
export const basicFont = `"IBM Plex Sans", Arial, -apple-system, 'HelveticaNeue', 'San Francisco', sans-serif`;
export const textFontSizeXXL = '28px';
export const textFontSizeXL = '24px';
export const textFontSizeL = '18px';
export const textFontSizeM = '16px';
export const textFontSizeS = '14px';
export const textFontSizeXS = '12px';
export const textLineHeightXL = '135%';
export const textLineHeightL = '125%';
export const textLineHeightM = '120%';
export const textLineHeightS = '105%';

export type TTheme = {
	name: string,
	primaryColor: string,
	primaryBlackColor: string,
	primaryLight: string,
	primaryHover: string,
	primaryActive: string,
	secondaryColor: string,
	secondaryHover: string,
	secondaryActive: string,
	dislikeHover: string,
	darkColor: string,
	disabledColor: string,
	backgroundGrayColor: string,
	accentColor: string,
	blueColor: string,
	blueColorTabs: string,
	yellowColor: string,
	yellowLighterColor: string,
	toxicLimeColor: string,
	grayColor: string,
	lightGrayColor: string,
	lightGrayHoverColor: string,
	middleGrayColor: string,
	darkGrayColor: string,
	darkestGray: string,
	baseGrayColor: string,
	baseGrayColorNew: string,
	newDarkGrayColor: string,
	greenColor: string,
	likeGreenColor: string,
	successColor: string,
	baseYellow: string,
	orangeColor: string,
	topPlaceColor: string,
	placeColor: string,
	liraColor: string,
	liraButtonColor: string,
	liraActiveColor: string,
	lightBlueLabelColor: string,
	lightBlueColor: string,
	lighterBlueColor: string,
	darkBlueColor: string,
	fullHeight: string,
	typography: {
		headlineXXL: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		headlineXXLmobile: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		headlineXL: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		headlineXLmobile: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		headlineL: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		headlineLmobile: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		headlineS: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
			textTransform: string,
		},
		headlineXS: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
			textTransform: string,
		},
		textAccentXXL: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textAccentXL: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textXL: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textBoldL: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textMediumL: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textL: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textAccentM: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
			textTransform: string,
		},
		textBoldM: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textMediumM: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textM: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textBoldS: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textSemiBoldS: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textMediumS: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textS: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		textAccentXS: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
			textTransform: string,
		},
		textXS: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		buttonL: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		buttonM: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		buttonS: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		buttonForm: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
		buttonFormS: {
			fontWeight: number,
			lineHeight: string,
			fontSize: string,
			fontFamily: string,
		},
	},
	sweep: string,
};

const theme: TTheme = {
	name: 'Living 2021',
	primaryColor: '#303CF6',
	primaryLight: '#4384F2',
	primaryHover: '#1D29DF',
	primaryActive: '#2632EA',
	secondaryColor: '#EEEFFD',
	secondaryHover: '#DADCFC',
	secondaryActive: '#E4E5FC',
	dislikeHover: '#FDEEEE',
	primaryBlackColor: '#0E233A',
	darkColor: '#081A2E',
	disabledColor: '#c5c5c5',
	backgroundGrayColor: '#F7F7FE',
	accentColor: '#E74C3C',
	blueColor: '#3498db',
	blueColorTabs: '#5862F7',
	yellowColor: '#F7C633',
	yellowLighterColor: '#ffee26',
	toxicLimeColor: '#DBFF00',
	grayColor: '#F6F6F9',
	lightGrayColor: '#F0F1F4',
	lightGrayHoverColor: '#ececec',
	middleGrayColor: '#D9DAE5',
	darkGrayColor: '#A0A3BD',
	darkestGray: '#5c5c5c',
	baseGrayColor: '#3A3D49',
	baseGrayColorNew: '#595E77',
	newDarkGrayColor: '#575A64',
	darkestGrayHover: '#707070',
	dropdownGrayColor: '#4A5368',
	greenColor: '#44CB01',
	likeGreenColor: '#1AC982',
	successColor: '#7cb342',
	warningColor: '#F19B92',
	baseYellow: '#fefce8',
	orangeColor: '#FB5A2E',
	topPlaceColor: '#19CB82',
	placeColor: '#7a7d93',
	liraColor: '#E6F2FA',
	liraButtonColor: '#CEE6F7',
	liraActiveColor: '#3498DB',
	lightBlueLabelColor: '#EEEFFD80',
	lightBlueColor: '#CEE6F7',
	lighterBlueColor: '#F4FAFF',
	lightGreenColor: '#EFF5E8',
	darkBlueColor: '#3B4C61',
	lightRedColor: '#F8E7E4',
	coralColor: '#FC8465',
	borderColor: '#e0e0e0',
	fullHeight: 'calc(var(--vh, 1vh) * 100)',
	typography: {
		headlineXXL: {
			fontWeight: 600,
			lineHeight: textLineHeightM,
			fontSize: '32px',
			fontFamily: basicFont,
		},
		headlineXXLmobile: {
			fontWeight: 700,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeXL,
			fontFamily: accentFont,
		},
		headlineXL: {
			fontWeight: 500,
			lineHeight: textLineHeightM,
			fontSize: '30px',
			fontFamily: accentFont,
		},
		headlineXLmobile: {
			fontWeight: 500,
			lineHeight: textLineHeightM,
			fontSize: textFontSizeL,
			fontFamily: accentFont,
		},
		headlineL: {
			fontWeight: 700,
			lineHeight: textLineHeightM,
			fontSize: textFontSizeXL,
			fontFamily: accentFont,
		},
		headlineLmobile: {
			fontWeight: 700,
			lineHeight: textLineHeightXL,
			fontSize: '20px',
			fontFamily: accentFont,
		},
		headlineS: {
			fontWeight: 500,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeL,
			fontFamily: accentFont,
			textTransform: 'uppercase',
		},
		headlineXS: {
			fontWeight: 500,
			lineHeight: textLineHeightM,
			fontSize: textFontSizeS,
			fontFamily: accentFont,
			textTransform: 'uppercase',
		},
		textAccentXXL: {
			fontWeight: 700,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeXXL,
			fontFamily: accentFont,
		},
		textAccentXL: {
			fontWeight: 700,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeXL,
			fontFamily: accentFont,
		},
		textXL: {
			fontWeight: 600,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeXL,
			fontFamily: basicFont,
		},
		textBoldL: {
			fontWeight: 600,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeL,
			fontFamily: basicFont,
		},
		textMediumL: {
			fontWeight: 500,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeL,
			fontFamily: basicFont,
		},
		textL: {
			fontWeight: 400,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeL,
			fontFamily: basicFont,
		},
		textAccentM: {
			fontWeight: 500,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeM,
			fontFamily: accentFont,
			textTransform: 'uppercase',
		},
		textBoldM: {
			fontWeight: 600,
			lineHeight: textLineHeightL,
			fontSize: textFontSizeM,
			fontFamily: basicFont,
		},
		textMediumM: {
			fontWeight: 500,
			lineHeight: textLineHeightL,
			fontSize: textFontSizeM,
			fontFamily: basicFont,
		},
		textM: {
			fontWeight: 400,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeM,
			fontFamily: basicFont,
		},
		textBoldS: {
			fontWeight: 700,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeS,
			fontFamily: basicFont,
		},
		textSemiBoldS: {
			fontWeight: 600,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeS,
			fontFamily: basicFont,
		},
		textMediumS: {
			fontWeight: 500,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeS,
			fontFamily: basicFont,
		},
		textS: {
			fontWeight: 400,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeS,
			fontFamily: basicFont,
		},
		textAccentXS: {
			fontWeight: 500,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeXS,
			fontFamily: accentFont,
			textTransform: 'uppercase',
		},
		textXS: {
			fontWeight: 500,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeXS,
			fontFamily: basicFont,
		},
		buttonL: {
			fontWeight: 700,
			lineHeight: textLineHeightL,
			fontSize: textFontSizeL,
			fontFamily: accentFont,
		},
		buttonM: {
			fontWeight: 700,
			lineHeight: textLineHeightL,
			fontSize: textFontSizeM,
			fontFamily: accentFont,
		},
		buttonS: {
			fontWeight: 700,
			lineHeight: '167%',
			fontSize: textFontSizeXS,
			fontFamily: accentFont,
		},
		buttonForm: {
			fontWeight: 400,
			lineHeight: textLineHeightL,
			fontSize: textFontSizeL,
			fontFamily: basicFont,
		},
		buttonFormS: {
			fontWeight: 500,
			lineHeight: textLineHeightXL,
			fontSize: textFontSizeM,
			fontFamily: basicFont,
		},
	},
	sweep,
};

export default theme;
