/* @flow */
import React, { useEffect, useState, useCallback } from 'react';
import type { Node } from 'react';
import NoUiStyle from '../../Layout/noui';
import { InputRoot, Input, InputRootWrapper, AfterElem, RangeSlider, PseudoRangeSlider, BeforeElem } from './styles';
import { FieldLabel } from '../FieldLabel/FieldLabel';
import debounce from '../../../utils/debounce';

export type TInputProps = {
	name: string,
	value: number,
};

type THandleValue = {
	x: number,
	isSlider?: boolean,
};

type THandleStep = {
	value: number,
};

type TProps = {
	label?: string,
	withSlider?: boolean,
	prefix?: any,
	suffix?: any,
	min?: number,
	max?: number,
	value: number,
	stepSlider?: number,
	name: string,
	debounceDelay?: number,
	styles?: {},
	inputStyle?: {},
	newStyle?: boolean,
	sliderMode?: 'from' | 'to',
	placeholder?: string,
	handleInputChange?: (TInputProps) => any,
	disabled?: boolean,
	handleValue?: (THandleValue) => number,
	handleStep?: (THandleStep) => number,
	handleFocus?: () => {},
	handleBlur?: () => {},
	dataQa?: string,
};
type InputProps = {
	name: string,
	value: number,
};
// Default Input Type Number!!!
const InputSlider = ({
	label = null,
	withSlider = false,
	value,
	name,
	prefix = null,
	suffix = null,
	styles,
	inputStyle = {},
	newStyle = false,
	placeholder,
	handleFocus = () => {},
	handleInputChange = () => {},
	handleBlur = () => {},
	handleStep = () => 1,
	handleValue = (x) => x,
	stepSlider,
	min = 0,
	max = 100000000,
	debounceDelay,
	dataQa,
	disabled,
	sliderMode,
}: TProps): Node => {
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('error');
	const [inputStateValue, setInputStateValue] = useState(value);
	const [sliderStateValue, setSliderStateValue] = useState(handleValue(value, true));
	const sliderMin = handleValue(min, true);
	const sliderMax = handleValue(max, true);
	const sliderStep = stepSlider || handleStep(sliderMin);

	/**
	 * Delay for debounce 500ms
	 * @type {number}
	 */
	const debounceCallback = useCallback(
		debounce((payload) => {
			handleInputChange(payload);
		}, debounceDelay),
		[],
	);

	/**
	 * Handler for input value
	 * @param e
	 */
	const handleInput = (e) => {
		const { value: inputValue, name: inputName }: InputProps = e.target;
		let resultValue: number;

		if (inputValue) {
			resultValue = Math.min(Math.max(Number(inputValue.replace(/\D/g, '')), 0), 2147483647);
		}

		setInputStateValue(resultValue);
		setSliderStateValue(handleValue(resultValue, true));

		const payload = { field: inputName, value: resultValue };
		debounceCallback(payload);
	};

	const handleSlider = (e) => {
		// e.target.valueAsNumber ломает фильтр, если у квартиры нет цены
		const { value: inputValue, name: inputName }: InputProps = e.target;
		let resultValue: number;

		if (inputValue) {
			resultValue = Number(inputValue);
		}
		setSliderStateValue(resultValue);
		setInputStateValue(handleValue(resultValue, false));

		const payload = { field: inputName, value: handleValue(resultValue, false) };
		debounceCallback(payload);
	};

	const checkSliderValue = () => {
		if (sliderStateValue) {
			if (sliderStateValue < sliderMin) {
				return sliderMin;
			}
			if (sliderStateValue > sliderMax) {
				return sliderMax;
			}
			return sliderStateValue;
		}
		return sliderMin;
	};

	useEffect(() => {
		if (value !== inputStateValue) {
			setInputStateValue(value);
			setSliderStateValue(Number(handleValue(value, true)));
		}
	}, [value]);

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.target.blur();
		}
	};

	return (
		<InputRootWrapper styles={styles}>
			{label && <FieldLabel htmlFor={name}>{label}</FieldLabel>}
			<InputRoot>
				{prefix && (
					<BeforeElem $newStyle={newStyle} $disabled={disabled}>
						{prefix}
					</BeforeElem>
				)}
				{suffix && (
					<AfterElem $newStyle={newStyle} $disabled={disabled}>
						{suffix}
					</AfterElem>
				)}

				<NoUiStyle />
				<Input
					name={name}
					id={name}
					value={inputStateValue}
					onFocus={handleFocus}
					onChange={handleInput}
					onBlur={handleBlur}
					placeholder={placeholder}
					inputMode="numeric"
					thousandSeparator=" "
					valueIsNumericString
					style={inputStyle}
					min={min}
					max={max}
					$withSlider={withSlider && !disabled}
					onKeyPress={handleKeyPress}
					disabled={disabled}
					data-qa={dataQa && `${dataQa}_input`}
					autoComplete={withSlider ? 'off' : 'on'}
					$hasPrefix={!!prefix}
					$hasSuffix={!!suffix}
					$hasError={error}
					$newStyle={newStyle}
				/>
				{withSlider && !disabled && (
					<>
						<PseudoRangeSlider
							value={checkSliderValue()}
							min={sliderMin}
							max={sliderMax}
							step={sliderStep}
							disabled={disabled}
							readOnly
							$newStyle={newStyle}
							$sliderMode={sliderMode}
							tabIndex={-1}
						/>

						<RangeSlider
							name={name}
							value={checkSliderValue()}
							min={sliderMin}
							max={sliderMax}
							onChange={handleSlider}
							step={sliderStep}
							dataQa={dataQa && `${dataQa}_slider`}
							disabled={disabled}
							$newStyle={newStyle}
							$sliderMode={sliderMode}
							tabIndex={-1}
						/>
					</>
				)}
			</InputRoot>
			{error && <FieldLabel error>{errorMessage}</FieldLabel>}
		</InputRootWrapper>
	);
};

InputSlider.defaultProps = {
	label: '',
	withSlider: false,
	prefix: undefined,
	suffix: undefined,
	type: 'number',
	mask: undefined,
	maskPlaceholder: undefined,
	min: undefined,
	max: undefined,
	stepSlider: undefined,
	placeholder: undefined,
	dataQa: null,
	disabled: false,
	debounceDelay: 500,
	styles: {},
	inputStyle: {},
	newStyle: false,
	sliderMode: 'to',
	handleInputChange: () => {},
	handleValue: (x) => x,
	handleStep: () => 1,
	handleFocus: () => {},
	handleBlur: () => {},
};

export default InputSlider;
