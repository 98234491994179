// @flow

type Result = { url: string, options: Request };

type Options = {
	baseUrl: string,
	lexsUrl: string,
	passUrl: string,
	cookie?: string,
};

export const interceptApiEndpoints =
	({ baseUrl, lexsUrl, passUrl, cookie }: Options) =>
	(url: string, options: Request): Result => {
		const defaults = {
			method: 'GET',
			headers: {
				...(cookie ? { Cookie: cookie } : null),
			},
			/*
		mode: baseUrl ? 'cors' : 'same-origin',
		credentials: baseUrl ? 'include' : 'same-origin',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...(cookie ? { Cookie: cookie } : null),
		},
		*/
		};

		if (url.startsWith('/api')) {
			return {
				url: `${baseUrl}${url.substring(4)}`,
				options: {
					...defaults,
					...options,
					headers: {
						...defaults.headers,
						...(options && options.headers),
					},
				},
			};
		}

		if (url.startsWith('/lexsApi')) {
			return {
				url: `${lexsUrl}${url.substring(8)}`,
				options: {
					...defaults,
					...options,
					headers: {
						...defaults.headers,
						...(options && options.headers),
						'Content-Type': 'application/json',
					},
				},
			};
		}

		if (url.startsWith('/passApi')) {
			return {
				url: `${passUrl}${url.substring(8)}`,
				options: {
					...defaults,
					...options,
					headers: {
						...defaults.headers,
						...(options && options.headers),
						'Content-Type': 'application/json',
					},
				},
			};
		}

		return { url, options };
	};
