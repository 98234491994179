/* @flow */
import {
	CHANGE_PAYMENT_POPUP_STEP,
	CLEAR_ACTION_CALLBACK_POPUP,
	CLOSE_AUTH_POPUP,
	CLOSE_CONFIRM_PHONE_POPUP,
	CLOSE_PAYMENT_POPUP,
	FETCH_ACTION_CALLBACK_POPUP,
	OPEN_AUTH_POPUP,
	OPEN_CONFIRM_PHONE_POPUP,
	OPEN_PAYMENT_POPUP,
	POPUP_IS_OPEN,
	SET_ACTION_CALLBACK_POPUP,
	TOGGLE_ACTION_CALLBACK_POPUP,
	TOGGLE_CALLBACK_POPUP,
} from '../constants/popup';

import {
	CLEAR_STORE,
	FAIL,
	FETCH_LIRA_CALLBACK_REQUEST,
	FETCH_LIRA_LOYALTY_REQUEST,
	POST_LIRA_PREVIEW_DATA,
	SET_LIRA_CALLBACK_FORM,
	SET_LIRA_LOYALTY_POPUP,
	SET_LIRA_PREVIEW_FORM,
	SET_LIRA_PREVIEW_STATUS,
	SET_LIRA_PREVIEW_STEP,
	START,
	SUCCESS,
} from '../constants';
import type { TPopup } from '../types/popup';

const initialState: TPopup = {
	popupOpen: false,
	authPopup: {
		isVisible: false,
		type: 'login',
	},
	paymentPopup: {
		isVisible: false,
		step: 1,
		phone: '',
		serviceId: null,
		paymentUrl: '',
	},
	confirmPhonePopup: {
		isVisible: false,
	},
	callbackPopup: {
		citySlug: null,
		context: null,
		isVisible: false,
		name: '',
	},
	actionCallbackPopup: {
		name: '',
		lastName: '',
		phone: '',
		email: '',
		agree: true,
		confirm: true,
		phoneError: false,
		isFetching: false,
		response: false,
		status: null,
		queue: null,
		responseIsSuccess: false,
		responseIsExist: false,
		day: '',
		time: '',
		isVisible: false,
		verified: null,
		received: false,
		success: false,
		inProgress: false,
	},
	apartPreview: {
		isLoading: false,
		receiveStatus: 'START',
		step: 'pre', // pre | intro | final | analogue
	},
	previewForm: {
		date: null,
		times: null,
		name: '',
		lastName: '',
		phone: '',
		confirm: true,
		verified: null,
		received: false,
		success: false,
		inProgress: false,
	},
	callbackForm: {
		name: '',
		lastName: '',
		phone: '',
		confirm: true,
		period: { from: '09:00', to: '21:00' },
		verified: null,
		received: false,
		success: false,
		inProgress: false,
	},
	loyaltyPopup: {
		name: '',
		lastName: '',
		phone: '',
		confirm: true,
		period: { from: '09:00', to: '21:00' },
		verified: null,
		received: false,
		success: false,
		inProgress: false,
	},
};

export default function popup(state: TPopup = initialState, action): TPopup {
	const { type, payload } = action;

	switch (type) {
		case OPEN_AUTH_POPUP:
			return {
				...state,
				authPopup: {
					isVisible: true,
					type: payload.type,
					email: payload.email || '',
					complexId: payload.complexId || state.authPopup.complexId,
				},
			};

		case CLOSE_AUTH_POPUP:
			return {
				...state,
				authPopup: {
					...state.authPopup,
					isVisible: false,
					email: '',
					complexId: null,
				},
			};

		case OPEN_PAYMENT_POPUP:
			return {
				...state,
				paymentPopup: {
					...state.paymentPopup,
					isVisible: true,
					step: payload.step,
					serviceId: payload.serviceId,
					phone: payload.phone,
					paymentUrl: payload.paymentUrl,
				},
			};

		case CLOSE_PAYMENT_POPUP:
			return {
				...state,
				paymentPopup: {
					...state.paymentPopup,
					...initialState.paymentPopup,
				},
			};

		case CHANGE_PAYMENT_POPUP_STEP:
			return {
				...state,
				paymentPopup: {
					...state.paymentPopup,
					...payload,
				},
			};

		case OPEN_CONFIRM_PHONE_POPUP:
			return {
				...state,
				confirmPhonePopup: {
					...state.confirmPhonePopup,
					isVisible: true,
				},
			};

		case CLOSE_CONFIRM_PHONE_POPUP:
			return {
				...state,
				confirmPhonePopup: {
					...state.confirmPhonePopup,
					isVisible: false,
				},
			};

		case TOGGLE_CALLBACK_POPUP:
			return {
				...state,
				callbackPopup: {
					...state.callbackPopup,
					isVisible: !state.callbackPopup.isVisible,
					context: state.callbackPopup.isVisible ? null : payload.context,
					citySlug: state.callbackPopup.isVisible ? null : payload.citySlug,
					name: state.callbackPopup.isVisible ? '' : payload.name,
					cityIds: state.callbackPopup.isVisible ? null : payload.cityIds,
				},
			};

		case TOGGLE_ACTION_CALLBACK_POPUP:
			return {
				...state,
				actionCallbackPopup: { ...state.actionCallbackPopup, isVisible: !state.actionCallbackPopup.isVisible },
			};

		case SET_ACTION_CALLBACK_POPUP:
			return { ...state, actionCallbackPopup: { ...state.actionCallbackPopup, ...action.payload } };

		case CLEAR_ACTION_CALLBACK_POPUP:
			return { ...state, actionCallbackPopup: { ...initialState.actionCallbackPopup } };

		case FETCH_ACTION_CALLBACK_POPUP + START:
			return {
				...state,
				actionCallbackPopup: {
					...state.actionCallbackPopup,
					isFetching: true,
					inProgress: true,
					received: false,
					success: false,
				},
			};

		case FETCH_ACTION_CALLBACK_POPUP + SUCCESS:
			return {
				...state,
				actionCallbackPopup: {
					...state.actionCallbackPopup,
					...action.payload,
					receiveStatus: true,
					inProgress: false,
					received: true,
					success: true,
				},
			};

		case FETCH_ACTION_CALLBACK_POPUP + FAIL:
			return {
				...state,
				actionCallbackPopup: {
					...state.actionCallbackPopup,
					...action.payload,
					receiveStatus: true,
					inProgress: false,
				},
			};

		case POPUP_IS_OPEN:
			return { ...state, popupOpen: action.payload };

		case SET_LIRA_CALLBACK_FORM:
			return { ...state, callbackForm: { ...state.callbackForm, ...action.payload } };

		case FETCH_LIRA_CALLBACK_REQUEST + START:
			return {
				...state,
				callbackForm: { ...state.callbackForm, inProgress: true, received: false, success: false },
			};

		case FETCH_LIRA_CALLBACK_REQUEST + SUCCESS:
			return {
				...state,
				callbackForm: { ...state.callbackForm, inProgress: false, received: true, success: true },
			};

		case SET_LIRA_PREVIEW_STATUS:
			return { ...state, apartPreview: { ...state.apartPreview, receiveStatus: action.payload } };

		case SET_LIRA_PREVIEW_STEP:
			return { ...state, apartPreview: { ...state.apartPreview, step: action.payload } };

		case FETCH_LIRA_CALLBACK_REQUEST:
			return { ...state, callbackForm: { ...state.callbackForm, inProgress: false } };

		case SET_LIRA_PREVIEW_FORM: {
			return { ...state, previewForm: { ...state.previewForm, ...action.payload } };
		}

		case POST_LIRA_PREVIEW_DATA + START:
			return {
				...state,
				apartPreview: { ...state.apartPreview, isLoading: true },
				previewForm: { ...state.previewForm, inProgress: true, received: false, success: false },
			};

		case POST_LIRA_PREVIEW_DATA + SUCCESS:
			return {
				...state,
				apartPreview: { ...state.apartPreview, isLoading: false },
				previewForm: {
					...state.previewForm,
					receiveStatus: true,
					inProgress: false,
					received: true,
					success: true,
				},
			};

		case POST_LIRA_PREVIEW_DATA + FAIL:
			return {
				...state,
				apartPreview: { ...state.apartPreview, isLoading: false },
				previewForm: { ...state.previewForm, receiveStatus: true, inProgress: false },
			};

		case SET_LIRA_LOYALTY_POPUP:
			return { ...state, loyaltyPopup: { ...state.loyaltyPopup, ...action.payload } };

		case FETCH_LIRA_LOYALTY_REQUEST + START:
			return {
				...state,
				loyaltyPopup: { ...state.loyaltyPopup, inProgress: true, received: false, success: false },
			};

		case FETCH_LIRA_LOYALTY_REQUEST + SUCCESS:
			return {
				...state,
				loyaltyPopup: { ...state.loyaltyPopup, inProgress: false, received: true, success: true },
			};

		case CLEAR_STORE:
			return initialState;

		default:
			return state;
	}
}
