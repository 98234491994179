/* @flow */
import React, { type Node } from 'react';
import { MessageText } from '../styles';

type TProps = {
	isInvalidCode: boolean,
	repeatChance: number,
	repeatBlocked: string,
};

const ErrorStage = ({ isInvalidCode, repeatChance, repeatBlocked }: TProps): Node => {
	const now = Date.now();
	let dateDiff;

	if (repeatBlocked) {
		dateDiff = new Date(3600000 - (now - repeatBlocked));
	}

	const getText = () => {
		if (repeatBlocked) {
			return 'Вы исчерпали количество попыток ввода кода';
		}
		if (isInvalidCode) {
			return 'Неверный код подверждения, попробуйте снова';
		}
		return 'Что-то пошло не так. Попробуйте повторить позже...';
	};

	return (
		<>
			<MessageText>{getText()}</MessageText>
			{/* repeatBlocked && (
				<MessageText gray>
					Можно повторить через {dateDiff.getMinutes()}{' '}
					{plural(dateDiff.getMinutes(), ['минуту', 'минуты', 'минут'])}
				</MessageText>
			) */}
			{/* isInvalidCode && !repeatBlocked && <MessageText gray>Осталось попыток: {repeatChance}</MessageText> */}
		</>
	);
};

export default ErrorStage;
