import { LOAD_FEED, SET_FEED_READ, CLEAR_STORE, SUCCESS, START, FAIL } from '../constants';

const initialState = {
	events: [],
	isLoading: false,
};

export default function feed(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case LOAD_FEED + START:
			return {
				...state,
				isLoading: true,
			};

		case LOAD_FEED + SUCCESS:
			return {
				...state,
				events: [...payload],
				isLoading: false,
			};

		case LOAD_FEED + FAIL:
			return {
				...state,
				isLoading: false,
			};

		case SET_FEED_READ + SUCCESS:
			return {
				...state,
				events: state.events.map(event => {
					if (payload.eventsIds.includes(event.id)) {
						event.isRead = true;
					}
					return event;
				}),
			};

		case CLEAR_STORE:
			return initialState;

		default:
			return state;
	}
}
export { initialState as feedInitialState };
