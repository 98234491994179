/* @flow */
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import type { TDispatch, TTokenData, TUser } from '../types/core';
import {
	AUTH_BLOCKED,
	AUTH_DECREMENT_CHANCE,
	AUTH_DECREMENT_COUNTER,
	AUTH_SET,
	AUTH_SET_COUNTER,
	AUTH_SET_REMAIN,
	SET_LIRA_FIRST_ENTRY_STATUS,
	SET_REFERRAL_ID,
	SET_TOKEN,
	SET_UNI_FILTER_COMPLEX_VIEW,
} from '../constants';
import { getCookie, getProfile, setCookie, setTokenCookies } from '../apiUtils';
import { AppContext } from '../components/AppContext';
import { addJavaHeaders, isEmpty } from '../utils';
import type { TFilterBase, TFilterExt, TFilterWishes } from '../types/filters';
import {
	baseFilterKeys,
	baseFilterKeysM,
	buildFilterQuery,
	encodeWishesSearchParam,
	extFilterKeys,
} from './searchParams';
import { setPopupOpen } from '../actions/popup';
import type { TToken } from '../types/account';
import { TTelegramAuth, TTelegramAuthEvent } from '../types/account';
import history from '../history';
import { checkCurrentToken } from '../clientUtils';
import type { TRootState } from '../types/rootState';
import { getUserLocation } from '../actions/user';
import { setMobile, setWindowSize } from '../actions/app';
import type { TAuthState } from '../reducers/auth';
import { authInitialState } from '../reducers/auth';
import { getUserProfileAnyway } from '../actions/lira';
import { getFeed } from '../actions/feed';
import type { TLiraSelectionsState } from '../reducers/liraSelections';
import type { TLiraFavorite } from '../types/lira3';
import { TLiraUser } from '../types/lira4';
import { checkLiraUserProfileExist } from '../actions/lira4';

const SECONDS_IN_DAY = 86400;

export const useReferralId = (): any => {
	const dispatch: TDispatch = useDispatch();
	const context = useContext(AppContext);

	const { hostname, query = {}, isBrowser = false } = context ?? {};

	const referralId: string = useSelector((state) => state.profile.referralId);
	const livingReferralId = isBrowser ? getCookie('living_referralId') : '';

	useEffect(() => {
		if (!isEmpty(query)) {
			const { ref: refId } = query;
			if (refId && !livingReferralId) {
				setCookie('living_referralId', referralId || refId, {
					path: '/',
					domain: hostname,
					expires: SECONDS_IN_DAY * 60,
				});
			}
			if (refId && !referralId) {
				dispatch({ type: SET_REFERRAL_ID, payload: refId });
			}
		}
	}, [query]);
};

export const useUserLocation = () => {
	const dispatch: TDispatch = useDispatch();
	const context = useContext(AppContext);

	const user: TUser = useSelector((state: TRootState) => state.user);

	const { isBrowser = false } = context ?? {};
	const {
		geoLocation: { isLoaded = false, isLoading = false },
	} = user;

	useEffect(() => {
		if (isBrowser && !isLoaded && !isLoading) {
			getUserLocation()(dispatch, context);
		}
	});
};

export const useDebounce = (value, delay) => {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);

			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay], // Only re-call effect if value or delay changes
	);

	return debouncedValue;
};

export const useModal = () => {
	const dispatch: TDispatch = useDispatch();
	const popupOpen: boolean = useSelector((state: TRootState) => state.popup.popupOpen);
	const [isShowing, setIsShowing]: [boolean, (boolean) => any] = useState(false);

	const onBackButtonEvent = (e) => {
		e.preventDefault();
		if (!popupOpen) {
			setIsShowing(false);
			dispatch(setPopupOpen(false));
		}
	};

	useEffect(() => {
		window.addEventListener('popstate', onBackButtonEvent);
		return () => {
			window.removeEventListener('popstate', onBackButtonEvent);
		};
	}, []);

	const closeModal = useCallback(() => {
		setIsShowing(false);
		dispatch(setPopupOpen(false));
	});

	const openModal = useCallback(() => {
		setIsShowing(true);
		dispatch(setPopupOpen(true));
	});

	const toggle = useCallback(() => {
		setIsShowing(!isShowing);
		dispatch(setPopupOpen(!popupOpen));
	});

	return {
		isShowing,
		toggle,
		closeModal,
		openModal,
	};
};

export const useComplexView = (enable: boolean = false): any => {
	const dispatch: TDispatch = useDispatch();
	const isComplexView: boolean = useSelector((state) => state.filterGlobal.isComplexView);

	useEffect(() => {
		if (enable !== isComplexView) {
			dispatch({ type: SET_UNI_FILTER_COMPLEX_VIEW, payload: enable });
		}
	}, []);
};

export const useFilterQuery = (mode: string): string => {
	const filterBase: TFilterBase = useSelector((state) => state.filterBase);
	const filterExt: TFilterExt = useSelector((state) => state.filterExt);
	const filterWishes: TFilterWishes = useSelector((state) => state.filterWishes);
	const selectionMode: string = useSelector((state) => state.filterGlobal.selectionMode);

	const selection: string = selectionMode === 'mortgage' ? 'sel=1' : '';
	const baseParams: string = buildFilterQuery(
		selectionMode === 'normal' ? baseFilterKeys : baseFilterKeysM,
		filterBase,
	);
	const extParams: string = buildFilterQuery(extFilterKeys, filterExt);
	const wishes: string = encodeWishesSearchParam(filterWishes);

	const base = baseParams ?? '';
	const regular = `${baseParams ?? ''}${!!baseParams && !!extParams ? '&' : ''}${extParams ?? ''}`;

	let out;
	switch (mode) {
		case 'base':
			out = base;
			break;

		case 'regular':
			out = regular;
			break;

		case 'all':
		default:
			out = `${regular}${!!regular && !!wishes ? '&' : ''}${wishes ?? ''}`;
	}

	return `${out}${out && selection ? '&' : ''}${selection}`;
};

export const useComponentDidMount = (): boolean => {
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	return isMounted;
};

export const useLocation = () => {
	const [location, setLocation] = useState(history.location);

	useEffect(() => {
		setLocation(history.location);
	}, [history.location]);

	return location;
};

export const useValidToken = () => {
	const context = useContext(AppContext);
	const { isBrowser = false } = context;

	const tokenObj: TToken = useSelector((state: TRootState) => state.token);
	const { token, tokenExpires, isAnonymous, isBot } = tokenObj;
	const location = useLocation();

	useEffect(() => {
		if (isBrowser && !isBot) {
			const isValid = new Date(tokenExpires).valueOf() > new Date().valueOf();
			if (!isValid) {
				checkCurrentToken(
					token,
					tokenExpires,
					isAnonymous,
				)(context).then((result) => {
					setTokenCookies(result?.token, result?.tokenExpires, null, result?.isAnonymous);
				});
			}
		}
	}, [location]);
};

export const useAuthTimer = (waitTime: number = 60): any => {
	const dispatch: TDispatch = useDispatch();

	const auth: TAuthState = useSelector((state: TRootState) => state.auth);

	const { timerInProgress, time, counter } = auth;

	const repeater = useRef(null);

	const decrementRemain = () => {
		dispatch({ type: AUTH_DECREMENT_COUNTER });
	};

	const startTimer = (): any => {
		// dispatch({ type: AUTH_SET_COUNTER, payload: time });
		repeater.current = setInterval(decrementRemain, 1000);
	};

	useEffect(() => {
		dispatch({ type: AUTH_SET_COUNTER, payload: time === waitTime ? time : waitTime });
		return () => {
			clearInterval(repeater.current);
		};
	}, []);

	useEffect(() => {
		if (timerInProgress && counter === time) {
			// setRemain(true);
			dispatch({ type: AUTH_SET_REMAIN, payload: true });
			startTimer();
		}
	}, [timerInProgress]);

	useEffect(() => {
		if (counter <= 0) {
			dispatch({
				type: AUTH_SET,
				payload: {
					timerInProgress: false,
					counter: time,
					remain: false,
				},
			});
			clearInterval(repeater.current);
		}
	}, [counter]);
};

const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
};

export const useWindowDimensions = () => {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
};

export const usePrevState = (value) => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

export const useAuthLocalStorage = () => {
	const dispatch: TDispatch = useDispatch();
	const { repeatChance, repeatBlocked } = authInitialState;
	let repeatChanceValue = repeatChance;
	let repeatBlockedValue = repeatBlocked;

	if (typeof localStorage !== 'undefined') {
		const authRepeatChance = localStorage.getItem('authRepeatChance');
		const authRepeatBlocked = localStorage.getItem('authRepeatBlocked');

		if (authRepeatChance) {
			repeatChanceValue = authRepeatChance;
		}

		if (authRepeatBlocked) {
			repeatBlockedValue = authRepeatBlocked;
		}
	}

	useEffect(() => {
		dispatch({ type: AUTH_DECREMENT_CHANCE, payload: repeatChanceValue });

		if (repeatBlockedValue) {
			dispatch({ type: AUTH_BLOCKED, payload: repeatBlockedValue });
		}
	}, []);
};

export const useLayoutSizes = () => {
	const dispatch: TDispatch = useDispatch();

	const addReal100vh = useCallback(() => {
		if (typeof window !== 'undefined' && typeof document !== 'undefined') {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
	}, []);

	const changeWindowSize = () => {
		const isClient = typeof window !== 'undefined';
		const clientWidth = isClient ? document?.body?.clientWidth : undefined;
		const clientHeight = isClient ? window.innerHeight : undefined;

		if (clientWidth && clientHeight) {
			dispatch(setWindowSize(clientWidth));
			dispatch(setMobile(clientHeight < 481 ? clientHeight : clientWidth));
		}

		addReal100vh();
	};

	useEffect(() => {
		changeWindowSize();

		window.addEventListener('resize', changeWindowSize);
		return () => {
			window.removeEventListener('resize', changeWindowSize);
		};
	}, []);
};

export const useTelegramConnection = (isLogin: boolean = false) => {
	const token: string = useSelector((state: TRootState) => state.token?.token);
	const user: TLiraUser = useSelector((state: TRootState) => state.lira?.user);
	const { firstEntry, portraitId, userWishes } = user;

	const [eventEmitterId, setEventEmitterId] = useState(null);
	const dispatch: TDispatch = useDispatch();
	const store = useStore();
	const { fetch } = useContext(AppContext);

	const handleConnect = async () => {
		try {
			const response = await fetch('/passApi/data/telegram', {
				method: 'POST',
				body: JSON.stringify({ type: isLogin ? 'login' : 'binding' }),
				...addJavaHeaders(token),
			});

			if (!response.ok) {
				throw new Error(`Ошибка сети: ${response.statusText}`);
			}

			const { data } = await response.json();

			if (!data || !data.eventEmitterId || !data.telegramBindingToken) {
				throw new Error('Неверный формат данных');
			}

			setEventEmitterId(data.eventEmitterId);
			const telegramLink = `${window.App.telegramBotName}?start=${data.telegramBindingToken}`;
			window.open(telegramLink, '_blank');
		} catch (err) {
			enqueueSnackbar('Не удалось подключиться к Telegram.', { variant: 'error' });
		}
	};

	const getAuthToken = async (newToken: string) => {
		try {
			const response = await fetch('/passApi/data/token', {
				method: 'POST',
				...addJavaHeaders(newToken),
			});

			if (response.ok) {
				const { data, status } = await response.json();

				if (!data) {
					throw new Error('Неверный формат данных');
				}

				if (status === 'ok') {
					const { token: updatedToken, expires: newExpires, isAnonymous: newIsAnonymous }: TTokenData = data;
					const expires = new Date(newExpires).toISOString();

					setTokenCookies(updatedToken, expires, null, newIsAnonymous);

					dispatch({
						type: SET_TOKEN,
						payload: { token: updatedToken, tokenExpires: expires, isAnonymous: newIsAnonymous },
					});

					return 'ok';
				}
			} else {
				throw new Error('Ошибка');
			}
		} catch (err) {
			enqueueSnackbar('Не удалось авторизоваться через Telegram.', { variant: 'error' });
			return 'error';
		}

		return null;
	};

	useEffect(() => {
		let eventSource = null;

		if (eventEmitterId) {
			eventSource = new EventSource(`${window.App.passUrl}/data/events/telegram/${eventEmitterId}`);

			eventSource.onmessage = (e: TTelegramAuthEvent) => {
				try {
					const data: TTelegramAuth = JSON.parse(e.data);

					if (e.type === 'error') {
						enqueueSnackbar(data.message, { variant: 'error' });
						eventSource.close();
					} else if (data.status === 'ok') {
						if (data.token) {
							getAuthToken(data.token).then(async () => {
								await Promise.all([
									getProfile(data.token, { store, fetch }),
									getUserProfileAnyway(data.token)(dispatch, store.getState, { fetch }),
									getFeed()(dispatch, store.getState),
									checkLiraUserProfileExist(data.token)(dispatch, store.getState, { fetch }),
								]);
							});
						} else {
							getProfile(token, { store, fetch }).then();
						}

						if (firstEntry && !!portraitId && userWishes?.length > 0) {
							dispatch({ type: SET_LIRA_FIRST_ENTRY_STATUS, payload: false });
						}

						enqueueSnackbar(`Успешно`, { variant: 'success' });

						eventSource.close();
					}
				} catch (err) {
					if (typeof e.data !== 'string') {
						enqueueSnackbar(`Неизвестное сообщение: ${e.data}`, { variant: 'error' });
					}
				}
			};

			eventSource.onerror = (e) => {
				const data: TTelegramAuth = e?.data ? JSON.parse(e.data) : undefined;

				if (data && data?.message === 'Incorrect Auth') {
					enqueueSnackbar(
						'Вы пытаетесь подключить Telegram-аккаунт, который был использован ранее. Используйте его для авторизации после выхода из текущего профиля.',
						{ variant: 'error' },
					);
				} else {
					enqueueSnackbar('Произошла ошибка соединения.', { variant: 'error' });
				}
				eventSource.close();
			};
		}

		return () => {
			if (eventSource) {
				eventSource.close();
			}
		};
	}, [eventEmitterId]);

	return { handleConnect };
};

export const useApartStatus = (apartId) => {
	const liraSelections: TLiraSelectionsState = useSelector((state: TRootState) => state.liraSelections);
	const { data, isLoaded, isLoading } = liraSelections;

	const [typeMark, setTypeMark] = useState(null);
	const [isOpenTypeMark, setIsOpenTypeMark] = useState(false);

	const getType = useCallback(() => {
		if (isLoaded && !isLoading) {
			if (data?.liked.some((item: TLiraFavorite) => item.id === apartId)) {
				setIsOpenTypeMark(true);
				setTypeMark('LIKE');
			} else if (data?.disliked.some((item: TLiraFavorite) => item.id === apartId)) {
				setIsOpenTypeMark(true);
				setTypeMark('DISLIKE');
			} else {
				setIsOpenTypeMark(false);
				setTypeMark(null);
			}
		}
	}, [apartId, data, isLoaded, isLoading]);

	useEffect(() => {
		getType();
	}, [apartId, data, getType]);

	return { typeMark, isOpenTypeMark };
};
