/* @flow */
import React, { type Node } from 'react';
import { Checkbox, Label, LabelCounter, LabelText, Root } from './styles';
import { dropdownIcon } from '../Icons';
import MetroIcon from '../MetroIcon/MetroIcon';
import type { TCity } from '../../types/city';

type TProps = {
	label: string,
	isActive: boolean,
	onClick: () => {},
	onLabelClick?: () => {},
	isGroup?: boolean,
	isOpen?: boolean,
	isShow?: boolean,
	isTopLevel?: boolean,
	groupText?: string,
	mode?: 'metro' | 'area',
	city?: TCity,
	color?: string,
	withColorPoint?: boolean,
	oneColumn?: boolean,
};

const CheckboxButton = ({
	city,
	label,
	isActive,
	onClick,
	onLabelClick,
	isGroup,
	isOpen,
	isShow,
	isTopLevel,
	groupText,
	color,
	mode,
	withColorPoint,
	oneColumn,
}: TProps): Node => (
	<Root $oneColumn={oneColumn} $isGroup={isGroup} $isShow={isShow}>
		<Label $isMetro={mode === 'metro'} $isGroup={isGroup} onClick={isGroup ? onLabelClick : onClick}>
			<LabelText $isGroup={isGroup} $isTopLevel={isTopLevel} $color={withColorPoint && color}>
				{mode === 'metro' && isGroup && city && <MetroIcon cityLink={city.link} color={color} />}
				{label}
			</LabelText>
			{groupText?.length > 0 && (
				<LabelCounter $isOpen={isOpen}>
					{groupText}
					{isGroup && <svg viewBox="0 0 10 6">{dropdownIcon}</svg>}
				</LabelCounter>
			)}
		</Label>
		<Checkbox onClick={onClick} $isActive={isActive} />
	</Root>
);

CheckboxButton.defaultProps = {
	onLabelClick: undefined,
	isGroup: false,
	isOpen: true,
	isShow: true,
	isTopLevel: false,
	groupText: '',
	mode: 'area',
	city: null,
	color: 'black',
	withColorPoint: false,
	oneColumn: false,
};

export default CheckboxButton;
