import rootTheme, { textFontSizeL, textFontSizeM } from './index';

export const theme = (outsideTheme) => ({
	...outsideTheme,
	borderRadius: 0,
	spacing: { baseUnit: 4, controlHeight: 40, menuGutter: 8 },
});

export const styles = {
	dropdownIndicator: (base, state) => {
		const { menuIsOpen } = state.selectProps;
		const transform = menuIsOpen ? 'rotate(180deg)' : 'transform: rotate(0)';
		return {
			...base,
			transition: 'transform 0.3s ease',
			transform,
		};
	},
	indicatorSeparator: () => ({
		display: 'none',
	}),
	valueContainer: (base, state) => {
		const { isMobile } = state.selectProps;
		const padding = isMobile ? '2px 0px 2px 6px' : '2px 8px';
		return {
			...base,
			padding,
		};
	},
	option: (provided, state) => {
		const backgroundColor = (optionState) => {
			if (optionState.isFocused && !optionState.isSelected) return '#f5f5f5';
			if (optionState.isSelected) return '#eaeaea';
			return '#fff';
		};

		return {
			...provided,
			backgroundColor: backgroundColor(state),
			':active': {
				backgroundColor: '#eaeaea',
			},
			color: '#333',
			cursor: 'pointer',
		};
	},
	control: () => ({
		cursor: 'pointer',
		display: 'flex',
		boxShadow: '1px 0px 8px rgba(0, 0, 0, 0.08)',
		borderRadius: '5px',
	}),
	singleValue: () => ({
		display: 'flex',
	}),
};

export const selectStyles2021 = {
	container: (containerStyle) => ({
		...containerStyle,
		zIndex: '10',
	}),
	valueContainer: (base, state) => {
		const { isMobile } = state.selectProps;
		const padding = isMobile ? '12px 0px 12px 16px' : '12px 16px';
		return {
			...base,
			padding,
			fontSize: textFontSizeM,
			display: 'flex',
		};
	},
	control: (base, state) => ({
		...base,
		cursor: 'pointer',
		display: 'flex',
		boxShadow: 'none',
		border: state.isFocused ? `1px solid ${rootTheme.primaryBlackColor}` : `1px solid ${rootTheme.lightGrayColor}`,
		borderRadius: '4px',
	}),
	option: (provided, state) => {
		const backgroundColor = (optionState) => {
			if (optionState.isFocused) return rootTheme.secondaryColor;
			return '#fff';
		};

		const color = () => {
			if (state.isFocused) return rootTheme.primaryColor;
			return rootTheme.primaryBlackColor;
		};

		return {
			...provided,
			backgroundColor: backgroundColor(state),
			':active': {
				color: rootTheme.primaryColor,
				backgroundColor: rootTheme.secondaryColor,
			},
			color: color(),
			fontSize: textFontSizeM,
			cursor: 'pointer',
			padding: '12px 16px',
			zIndex: 9000,
		};
	},
};

export const selectStyles2022 = {
	menu: (menuStyle) => ({
		...menuStyle,
		zIndex: 1000,
	}),
	dropdownIndicator: (base, state) => {
		const { menuIsOpen } = state.selectProps;
		const transform = menuIsOpen ? 'rotate(180deg)' : 'transform: rotate(0)';
		return {
			...base,
			transition: 'transform 0.3s ease',
			transform,
		};
	},
	valueContainer: (valueContainerStyle, state) => {
		const { isMobile } = state.selectProps;
		const padding = isMobile ? '12px 0px 12px 16px' : '16px';
		return {
			...valueContainerStyle,
			padding,
			fontSize: textFontSizeL,
			display: 'flex',
			flexWrap: 'nowrap',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		};
	},
	indicatorSeparator: () => ({
		display: 'none',
	}),
	control: (base, state) => ({
		...base,
		cursor: 'pointer',
		display: 'flex',
		boxShadow: 'none',
		border: state.isFocused ? `2px solid ${rootTheme.primaryColor}` : `2px solid transparent`,
		borderRadius: '4px',
		backgroundColor: rootTheme.grayColor,
		':hover': {
			backgroundColor: rootTheme.lightGrayColor,
			borderColor: 'transparent',
		},
		':focus': {
			borderColor: rootTheme.primaryColor,
		},
	}),
	groupHeading: (groupHeadingStyle, { data }) => {
		if (data.color)
			return {
				...groupHeadingStyle,
				'::before': {
					content: '"\u2022"',
					color: data.color,
					marginRight: '4px',
					fontSize: '18px',
				},
			};
		return groupHeadingStyle;
	},
	multiValue: (multiValueStyle, { data }) => ({
		...multiValueStyle,
		backgroundColor: data.color ?? rootTheme.primaryColor,
		color: '#fff',
	}),
	multiValueLabel: (multiValueLabelStyle) => ({
		...multiValueLabelStyle,
		color: '#fff',
	}),
	option: (optionStyle, state) => {
		const backgroundColor = (optionState) => {
			if (optionState.isFocused) return rootTheme.secondaryColor;
			return '#fff';
		};

		const color = () => {
			if (state.isFocused) return rootTheme.primaryColor;
			return rootTheme.primaryBlackColor;
		};

		return {
			...optionStyle,
			backgroundColor: backgroundColor(state),
			':active': {
				color: rootTheme.primaryColor,
				backgroundColor: rootTheme.secondaryColor,
			},
			color: color(),
			fontSize: textFontSizeM,
			cursor: 'pointer',
			padding: '12px 16px',
			zIndex: 9000,
		};
	},
};

export const selectStyles2022white = {
	container: (containerStyle) => ({
		...containerStyle,
		minWidth: '160px',
	}),
	menu: (menuStyle) => ({
		...menuStyle,
		zIndex: 1000,
	}),
	valueContainer: (valueContainerStyle) => {
		const padding = '11px 0px 11px 16px';
		return {
			...valueContainerStyle,
			padding,
			fontSize: textFontSizeL,
			display: 'flex',
		};
	},
	indicatorSeparator: () => ({
		display: 'none',
	}),
	control: (base, state) => ({
		...base,
		cursor: 'pointer',
		display: 'flex',
		boxShadow: 'none',
		border: state.isFocused ? `2px solid ${rootTheme.primaryBlackColor}` : `2px solid ${rootTheme.lightGrayColor}`,
		backgroundColor: '#fff',
		':hover': {
			border: `2px solid ${rootTheme.lightGrayHoverColor}`,
		},
	}),
	groupHeading: (groupHeadingStyle, { data }) => {
		if (data.color)
			return {
				...groupHeadingStyle,
				'::before': {
					content: '"\u2022"',
					color: data.color,
					marginRight: '4px',
					fontSize: '18px',
				},
			};
		return groupHeadingStyle;
	},
	multiValue: (multiValueStyle, { data }) => ({
		...multiValueStyle,
		backgroundColor: data.color ?? rootTheme.primaryColor,
		color: '#fff',
	}),
	multiValueLabel: (multiValueLabelStyle) => ({
		...multiValueLabelStyle,
		color: '#fff',
	}),
	option: (optionStyle, state) => {
		const backgroundColor = (optionState) => {
			if (optionState.isFocused) return rootTheme.secondaryColor;
			return '#fff';
		};

		const color = () => {
			if (state.isFocused) return rootTheme.primaryColor;
			return rootTheme.primaryBlackColor;
		};

		return {
			...optionStyle,
			backgroundColor: backgroundColor(state),
			':active': {
				color: rootTheme.primaryColor,
				backgroundColor: rootTheme.secondaryColor,
			},
			color: color(),
			fontSize: textFontSizeM,
			cursor: 'pointer',
			padding: '12px 16px',
			zIndex: 9000,
		};
	},
};

export const selectStyles2022whiteRounded = {
	container: (containerStyle) => ({
		...containerStyle,
		minWidth: '100%',
	}),
	menu: (menuStyle) => ({
		...menuStyle,
		zIndex: 1000,
	}),
	valueContainer: (valueContainerStyle) => {
		const padding = '11px 0px 11px 16px';
		return {
			...valueContainerStyle,
			padding,
			display: 'flex',
		};
	},
	singleValue: () => ({
		fontSize: textFontSizeM,
	}),

	indicatorSeparator: () => ({
		display: 'none',
	}),
	control: (base, state) => ({
		...base,
		cursor: 'pointer',
		display: 'flex',
		boxShadow: 'none',
		border: state.isFocused ? `2px solid ${rootTheme.primaryBlackColor}` : `2px solid ${rootTheme.lightGrayColor}`,
		borderRadius: '24px',
		backgroundColor: '#fff',
		minHeight: '52px',
		flexWrap: 'nowrap',
		':hover': {
			border: `2px solid ${rootTheme.lightGrayHoverColor}`,
		},
	}),
	groupHeading: (groupHeadingStyle, { data }) => {
		if (data.color)
			return {
				...groupHeadingStyle,
				'::before': {
					content: '"\u2022"',
					color: data.color,
					marginRight: '4px',
					fontSize: '18px',
				},
			};
		return groupHeadingStyle;
	},
	multiValue: (multiValueStyle, { data }) => ({
		...multiValueStyle,
		backgroundColor: data.color ?? rootTheme.primaryColor,
		color: '#fff',
	}),
	multiValueLabel: (multiValueLabelStyle) => ({
		...multiValueLabelStyle,
		color: '#fff',
	}),
	option: (optionStyle, state) => {
		const backgroundColor = (optionState) => {
			if (optionState.isFocused) return rootTheme.secondaryColor;
			return '#fff';
		};

		const color = () => {
			if (state.isFocused) return rootTheme.primaryColor;
			return rootTheme.primaryBlackColor;
		};

		return {
			...optionStyle,
			backgroundColor: backgroundColor(state),
			':active': {
				color: rootTheme.primaryColor,
				backgroundColor: rootTheme.secondaryColor,
			},
			color: color(),
			fontSize: textFontSizeM,
			cursor: 'pointer',
			padding: '12px 16px',
			zIndex: 9000,
		};
	},
};

export const selectStyles2023 = {
	menuPortal: (base) => ({ ...base, zIndex: 9999 }),
	menu: (menuStyle) => ({
		...menuStyle,
		zIndex: 1000,
		height: 'fit-content',
	}),

	menuList: (base, state) => {
		const { isPortrait } = state.selectProps;
		return {
			...base,
			maxHeight: isPortrait ? '100%' : '300px',
		};
	},

	dropdownIndicator: (base, state) => {
		const { menuIsOpen } = state.selectProps;
		const transform = menuIsOpen ? 'rotate(180deg)' : 'transform: rotate(0)';
		return {
			...base,
			transition: 'transform 0.3s ease',
			transform,
		};
	},
	valueContainer: (valueContainerStyle, state) => {
		const { isMobile } = state.selectProps;
		const padding = isMobile ? '12px 0px 12px 16px' : '10px 16px';
		return {
			...valueContainerStyle,
			padding,
			fontSize: textFontSizeM,
			fontWeight: 400,
			display: 'flex',
			flexWrap: 'nowrap',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		};
	},
	indicatorSeparator: () => ({
		display: 'none',
	}),
	control: (base, state) => ({
		...base,
		cursor: 'pointer',
		display: 'flex',
		boxShadow: 'none',
		border: state.isFocused ? `1px solid ${rootTheme.primaryColor}` : `1px solid ${rootTheme.middleGrayColor}`,
		borderRadius: '4px',
		backgroundColor: 'transparent',
		':hover': {
			backgroundColor: rootTheme.lightGrayColor,
		},
		':focus': {
			borderColor: rootTheme.primaryColor,
		},
	}),
	groupHeading: (groupHeadingStyle, { data }) => {
		if (data.color)
			return {
				...groupHeadingStyle,
				'::before': {
					content: '"\u2022"',
					color: data.color,
					marginRight: '4px',
					fontSize: '18px',
				},
			};
		return groupHeadingStyle;
	},
	multiValue: (multiValueStyle, { data }) => ({
		...multiValueStyle,
		backgroundColor: data.color ?? rootTheme.primaryColor,
		color: '#fff',
	}),
	multiValueLabel: (multiValueLabelStyle) => ({
		...multiValueLabelStyle,
		color: '#fff',
	}),
	option: (optionStyle, state) => {
		const backgroundColor = (optionState) => {
			if (optionState.isFocused) return rootTheme.secondaryColor;
			return '#fff';
		};

		const color = () => {
			if (state.isFocused) return rootTheme.primaryColor;
			return rootTheme.primaryBlackColor;
		};

		return {
			...optionStyle,
			backgroundColor: backgroundColor(state),
			':active': {
				color: rootTheme.primaryColor,
				backgroundColor: rootTheme.secondaryColor,
			},
			color: color(),
			fontSize: textFontSizeM,
			fontWeight: 400,
			cursor: 'pointer',
			padding: '12px 16px',
			zIndex: 9000,
		};
	},
};
