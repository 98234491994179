import styled from 'styled-components';

export const Root = styled.div`
	display: flex;
	flex: 1 1 100%;
	flex-direction: column;
	justify-content: center;
	background-color: ${({ theme }) => theme.lightGrayColor};
	border-bottom: ${({ theme }) => `1px solid ${theme.darkGrayColor}`};
	box-sizing: border-box;
`;

export const NotFoundError = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Picture = styled.picture`
	display: flex;
	width: 100%;

	img {
		margin: 0 auto 40px;
		max-width: 100%;

		@media (max-width: 600px) {
			margin: 0 auto 20px;
		}
	}
`;

export const Description = styled.p`
	margin: 0 auto 24px;
	text-align: center;
	${({ theme }) => theme.typography.headlineXXL};

	@media (max-width: 600px) {
		margin: 0 auto 12px;
		${({ theme }) => theme.typography.headlineXXLmobile};
	}
`;

export const SearchTitle = styled.div`
	margin: 0 auto 40px;
	text-align: center;
	${({ theme }) => theme.typography.textMediumL};

	@media (max-width: 600px) {
		margin: 0 auto 24px;
		${({ theme }) => theme.typography.textMediumM};
	}
`;

export const SearchButton = styled.button`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	width: 100%;
	max-width: 900px;
	height: 56px;
	margin: 0 auto;
	padding: 16px;
	background: #ffffff;
	border: ${({ theme }) => `1px solid ${theme.middleGrayColor}`};
	border-radius: 4px;
	color: ${({ theme }) => theme.darkGrayColor};
	box-sizing: border-box;
	cursor: text;
	transition: box-shadow 0.3s;
	${({ theme }) => theme.typography.textL};

	svg {
		transition: fill 0.3s;
		fill: currentColor;
	}

	&:hover {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

		svg {
			fill: ${({ theme }) => theme.primaryBlackColor};
		}
	}
`;
