import type { TOption } from './Filter';

export const DEAL_OPTIONS: TOption[] = [
	{
		value: 'mortgage',
		label: 'Ипотечный платеж',
	},
	{
		value: 'normal',
		label: 'Единовременная оплата',
	},
];

export const TYPE_OPTIONS: TOption[] = [
	{
		value: 'ALL',
		label: 'Любой',
	},
	{
		value: 'NEW',
		label: 'Новостройка',
	},
	{
		value: 'RESALE',
		label: 'Вторичка',
	},
];

export const APART_OPTIONS: TOption[] = [
	{
		value: null,
		label: 'Любой',
	},
	{
		value: false,
		label: 'Квартиры',
	},
	{
		value: true,
		label: 'Апартаменты',
	},
];

export const SEX_OPTIONS: TOption[] = [
	{
		value: 'MALE',
		label: 'Мужской',
	},
	{
		value: 'FEMALE',
		label: 'Женский',
	},
];

export const RoomsCount: string[] = ['Студия', '1 комната', '2 комнаты', '3 комнаты', '4 и более'];

export const EXTRA_LABEL_TOOLTIP =
	'Укажите, если у вас есть дети или вы работаете в it-компании - это повлияет на условия ипотеки';

export const AGE_LABEL_TOOLTIP = 'Укажите ваш возраст - это повлияет на условия ипотеки';

export const SEX_LABEL_TOOLTIP = 'Укажите ваш пол - это повлияет на условия ипотеки';
