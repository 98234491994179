/* @flow */

import React from 'react';
import { MetroIconSvg } from './styles';

import {
	SubwayIconSPB,
	SubwayIconMsk,
	SubwayIconEkb,
	SubwayIconSmr,
	SubwayIconKzn,
	SubwayIconNN,
	SubwayIconNsk,
	NewSubwayIconMsk,
} from '../Icons';
import type { TMetro } from '../../types/apart';

const Icons = {
	spb: SubwayIconSPB,
	msk: SubwayIconMsk,
	ekb: SubwayIconEkb,
	smr: SubwayIconSmr,
	kzn: SubwayIconKzn,
	nn: SubwayIconNN,
	nsk: SubwayIconNsk,
};

type TProps = {
	cityLink: 'spb' | 'msk' | 'ekb' | 'smr' | 'kzn' | 'nn' | 'nsk',
	station?: TMetro,
	className?: string | null,
	color?: string | null,
};

const MetroIcon = ({ cityLink, station, className, color }: TProps) => (
	<MetroIconSvg
		style={{ fill: color }}
		city={cityLink}
		line={station.line}
		className={className}
		preserveAspectRatio="xMaxYMax meet"
	>
		{Icons[cityLink] || NewSubwayIconMsk}
	</MetroIconSvg>
);

MetroIcon.defaultProps = {
	station: {},
	className: null,
	color: null,
};

export default MetroIcon;
