import { GET_MORTGAGE_PROGRAMS, SET_MORTGAGE_PARAMS } from '../constants/mortgage';
import {
	FAIL,
	LIRA_CHANGE_USER_PROFILE,
	LIRA_GET_USER_PROFILE,
	LIRA_SET_MORTGAGE_PARAMS,
	SET_APARTMENT,
	SET_GLOBAL_SELECTION_MODE,
	START,
	SUCCESS,
} from '../constants';
import type { TMortgage } from '../types/mortgage';
import { INITIAL_FEE_DEFAULT, PAYMENT_DEFAULT } from '../data/filter/price';

const initialState: TMortgage = {
	params: {
		monthlyPayment: PAYMENT_DEFAULT,
		initialFee: INITIAL_FEE_DEFAULT,
		loanerInfo: {
			sex: 'MALE',
			age: 35,
			worksInIT: false,
			hasChildren: false,
		},
	},
	suitablePrograms: [],
	unsuitablePrograms: [],
	isLoaded: false,
	isLoading: false,
};

const mortgage = (state: TMortgage = initialState, action): TMortgage => {
	const { type, payload } = action;

	switch (type) {
		case GET_MORTGAGE_PROGRAMS + START:
			return { ...state, isLoading: true, isLoaded: false };
		case GET_MORTGAGE_PROGRAMS + SUCCESS:
			return { ...state, ...payload, isLoaded: true, isLoading: false };
		case GET_MORTGAGE_PROGRAMS + FAIL:
			return { ...state, isLoading: false, isLoaded: false };
		case SET_MORTGAGE_PARAMS:
		case LIRA_SET_MORTGAGE_PARAMS:
			return { ...state, params: { ...state.params, ...payload } };
		case LIRA_CHANGE_USER_PROFILE + SUCCESS:
		case LIRA_GET_USER_PROFILE + SUCCESS: {
			const {
				separated: {
					base: { mortgageInfo = initialState.params },
				},
			} = payload;

			return { ...state, params: { ...mortgageInfo } };
		}
		case SET_APARTMENT:
			return { ...state, suitablePrograms: [], unsuitablePrograms: [], isLoaded: false, isLoading: false };

		case SET_GLOBAL_SELECTION_MODE:
			return initialState;
		default:
			return state;
	}
};

export { initialState as mortgageInitialState };
export default mortgage;
