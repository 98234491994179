import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Title = styled.h2`
	margin: 0 0 8px;
	color: #000;
	font-size: 24px;
	font-weight: 600;
	line-height: 1.25;
	text-align: center;
`;

export const StageRoot = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const MessageText = styled.div`
	text-align: center;
	margin: 0 0 10px 0;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.43;
	${(props) => props.gray && `color: #5c5c5c;`}
	${(props) => props.hiddenText && `color: transparent;`}

	b {
		font-size: 18px;
		font-weight: 700;
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 376px) {
		margin: 0 0 8px 0;
	}
`;

export const Label = styled.label`
	${({ theme }) => theme.typography.textAccentXS};
	align-self: flex-start;
	text-align: left;
	color: ${({ theme, $disabled }) => ($disabled ? theme.darkGrayColor : theme.primaryColor)};
	margin: 0 0 12px 0;

	@media (max-width: 376px) {
		margin: 0 0 10px 0;
	}
`;

export const StyledInputMaskFull = styled(InputMask)`
	width: 100%;
	height: 56px;
	padding: 16px;
	margin: 0 0 16px;
	border-radius: 4px;
	border: none;
	background-color: ${({ theme }) => theme.grayColor};
	color: ${({ theme }) => theme.primaryBlackColor};
	box-sizing: border-box;

	&::placeholder {
		color: ${({ theme }) => theme.darkGrayColor};
	}

	&:disabled {
		color: ${({ theme }) => theme.darkGrayColor};
	}

	@media (max-width: 376px) {
		margin: 0 0 13px;
	}
`;

export const FieldMarker = styled.sup`
	color: ${({ theme }) => theme.primaryColor};
`;

export const Remain = styled.div`
	margin-bottom: 15px;
	font-size: 12px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.4);
	text-align: center;

	@media (max-width: 376px) {
		margin-bottom: 12px;
	}
`;

export const RemainButton = styled.button`
	padding: 0;
	background-color: transparent;
	border: none;
	color: ${(props) => props.theme.primaryColor};
	text-decoration: underline;
	cursor: pointer;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 16px;

	@media (max-width: 376px) {
		margin-top: 3px;
	}
`;

export const PopUpImageBackground = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 180px;
	background: #edf3ff;

	@media (max-width: 376px) {
		height: 150px;
	}
`;

export const PopUpImage = styled.svg`
	width: 185px;
	padding-top: 20px;
`;
export const PopUpContent = styled.div`
	padding: 20px 35px;

	@media (max-width: 600px) {
		padding: 15px;
	}

	@media (max-width: 376px) {
		padding: 8px 10px 15px;
	}
`;

export const Tabs = styled.div`
	display: flex;
	width: 100%;
	margin: 12px 0 16px;
`;

export const Tab = styled.button`
	display: flex;
	flex: 1 1 100%;
	align-items: center;
	justify-content: center;
	height: 44px;
	padding: 0 16px;
	background: transparent;
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: ${({ $isActive, theme }) => ($isActive ? theme.primaryColor : theme.middleGrayColor)};
	box-shadow: none;
	box-sizing: border-box;
	cursor: pointer;
	color: ${({ $isActive, theme }) => ($isActive ? theme.primaryBlackColor : theme.darkGrayColor)};
	${({ theme }) => theme.typography.textMediumL}
	-webkit-appearance: none;
`;
