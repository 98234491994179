/* @flow */

import { CHANGE_USER_CITY, FAIL, GET_USER_LOCATION, START, SUCCESS } from '../constants';
import type { TDispatch } from '../types/core';

export function setUserCity({ citySlug, cityIds }: { citySlug: string, cityIds: Array<number> }) {
	return {
		type: CHANGE_USER_CITY,
		payload: {
			citySlug,
			cityIds,
		},
	};
}

export const getUserLocation =
	() =>
	async (dispatch: TDispatch, { fetch }) => {
		dispatch({ type: GET_USER_LOCATION + START });
		try {
			const data = await fetch('https://api.country.is/').then((res) => res.json());

			const { ip, country } = data;

			dispatch({
				type: GET_USER_LOCATION + SUCCESS,
				payload: { ip, country },
			});

			return data;
		} catch (error) {
			dispatch({ type: GET_USER_LOCATION + FAIL });
			return null;
		}
	};
