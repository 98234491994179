import styled, { createGlobalStyle, css } from 'styled-components';

import bg375 from '../../../public/images/home/bg/bg375.jpg';
import bg375x2 from '../../../public/images/home/bg/bg375@2x.jpg';
import bg1440 from '../../../public/images/home/bg/bg1440.jpg';
import bg1440x2 from '../../../public/images/home/bg/bg1440@2x.jpg';
import bg1920 from '../../../public/images/home/bg/bg1920.jpg';
import bg1920x2 from '../../../public/images/home/bg/bg1920@2x.jpg';

import bgPink from '../../../public/images/home/cards/bg-pink.png';
import bgPink2x from '../../../public/images/home/cards/bg-pink@2x.png';
import bgGreen from '../../../public/images/home/cards/bg-green.png';
import bgGreen2x from '../../../public/images/home/cards/bg-green@2x.png';
import bgBlue from '../../../public/images/home/cards/bg-blue.png';
import bgBlue2x from '../../../public/images/home/cards/bg-blue@2x.png';

export const Root = styled.div`
	display: grid;
	flex: 1 1 100%;
	grid-template-columns: 100%;
	grid-template-rows: 1fr auto;
	color: ${({ theme }) => theme.primaryBlackColor};
`;

export const Hero = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.lightGrayColor};
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(${bg375});
	min-height: 600px;

	@media (min-resolution: 2dppx) {
		background-image: url(${bg375x2});
	}

	@media (min-width: 601px) {
		background-image: url(${bg1440});

		@media (min-resolution: 2dppx) {
			background-image: url(${bg1440x2});
		}
	}

	@media (min-width: 768px) {
		min-height: unset;
	}

	@media (min-width: 1441px) {
		background-image: url(${bg1920});

		@media (min-resolution: 2dppx) {
			background-image: url(${bg1920x2});
		}
	}
`;

export const Footer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 0;
	min-height: 230px;
	background: #fff;

	@media (min-width: 601px) {
		padding: 48px 0;
	}

	@media (min-width: 1024px) {
		padding: 48px 16px;
	}
`;

export const CardsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 42px;
	flex: 1 1 100%;

	@media (min-width: 1024px) {
		grid-template-columns: repeat(4, 429px);
	}
`;

export const EmptyCard = styled.div`
	border-radius: 12px;
	opacity: 0.4;
	background: ${({ theme }) => theme.secondaryColor};
`;

const getCardColor = (color) => {
	switch (color) {
		case 'pink':
			return css`
				background-image: url(${bgPink});

				@media (min-resolution: 2dppx) {
					background-image: url(${bgPink2x});
				}
			`;
		case 'green':
			return css`
				background-image: url(${bgGreen});

				@media (min-resolution: 2dppx) {
					background-image: url(${bgGreen2x});
				}
			`;
		case 'blue':
			return css`
				background-image: url(${bgBlue});

				@media (min-resolution: 2dppx) {
					background-image: url(${bgBlue2x});
				}
			`;
		default:
			return '';
	}
};

export const Card = styled.div`
	position: relative;
	z-index: 1;
	display: flex !important;
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	flex-direction: column;
	gap: 20px;
	padding: 20px 24px;
	border-radius: 12px;
	background-repeat: no-repeat;
	background-position: bottom right;
	background-size: cover;
	overflow: hidden;
	${({ $color }) => getCardColor($color)};
`;

export const CardTitle = styled.h2`
	position: relative;
	z-index: 5;
	margin: 0;
	color: ${({ theme, $blue }) => ($blue ? theme.primaryColor : theme.baseGrayColor)};
	${({ theme }) => theme.typography.headlineXLmobile};
`;

export const CardSubTitle = styled.h2`
	position: relative;
	z-index: 5;
	margin: 0;
	color: ${({ theme }) => theme.baseGrayColor};
	${({ theme }) => theme.typography.textXL};
	font-weight: 700;
`;

export const CardList = styled.ul`
	position: relative;
	z-index: 5;
	margin: 0 0 0 24px;
	padding: 0;
`;

export const CardListItem = styled.li`
	color: ${({ theme }) => theme.baseGrayColorNew};
	${({ theme }) => theme.typography.textL};

	& + & {
		margin-top: 7px;
	}

	span {
		display: inline-flex;
		align-items: center;
	}

	b {
		color: ${({ theme }) => theme.primaryBlackColor};
	}

	&::marker {
		color: ${({ $color, theme }) => $color ?? theme.darkGrayColor};
	}
`;

export const CardMortgage = styled.div`
	position: relative;
	z-index: 3;
	display: flex !important;
	flex-direction: column;
	gap: 20px;
	padding: 0 24px 20px;
`;

export const MicroCardIcon = styled.svg`
	width: 25px;
	height: 16px;
	fill: #fff;
	margin: auto 8px auto 12px;
`;

export const CardImage = styled.figure`
	display: flex;
	padding: 0;
	margin: 0;
	position: absolute;
	bottom: -30px;
	right: -30px;
	width: 136px;
	z-index: 4;
	transform: rotate(4deg);

	img {
		width: 100%;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 950px;
	flex: 1 1 100%;
	padding: 0 15px;
	margin: 0 auto;
	box-sizing: border-box;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 100%;
	align-items: center;
	margin: auto;
	padding: 80px 0 20px;

	@media (min-width: 768px) {
		padding: 80px 0;
	}
`;

export const Title = styled.h1`
	margin: 0 0 10px;
	${({ theme }) => theme.typography.headlineXXLmobile};
	color: ${({ theme }) => theme.primaryColor};

	@media (min-width: 390px) {
		font-size: 28px;
	}

	@media (min-width: 601px) {
		margin: 0;
		font-size: 48px;
		text-align: center;
	}
`;

export const Subtitle = styled.h2`
	margin: 0 0 40px;
	${({ theme }) => theme.typography.textMediumL};
	color: ${({ theme }) => theme.darkGrayColor};
	font-size: 20px;

	@media (min-width: 601px) {
		margin: 0 0 80px;
		font-size: 32px;
		text-align: center;
	}
`;

export const Fields = styled.div`
	display: grid;
	width: 100%;
	margin-bottom: 20px;
	grid-gap: 10px;
	grid-template-columns: repeat(2, 1fr);
	grid-template-areas: 'city city' 'type type' 'rooms rooms' 'checkbox checkbox' 'priceFrom priceFrom' 'priceTo priceTo';
	box-sizing: border-box;

	@media (min-width: 360px) {
		grid-template-columns: repeat(6, 1fr);
		grid-template-areas: 'city city city city city city' 'type type type type rooms rooms' 'checkbox checkbox checkbox checkbox checkbox checkbox' 'priceFrom priceFrom priceFrom priceTo priceTo priceTo';
	}

	@media (min-width: 768px) {
		grid-template-columns: repeat(16, 1fr);
		grid-template-areas: 'city city city city city city type type type type rooms rooms rooms price price price';
		grid-gap: 0;
		border-radius: 8px;
		border: ${({ theme }) => `3px solid ${theme.secondaryHover}`};
		background: #fff;
		box-shadow: 0 4px 37.5px 0 rgba(25, 31, 87, 0.07);
	}

	@media (min-width: 900px) {
		grid-template-columns: repeat(15, 1fr);
		grid-template-areas: 'city city city city city city type type type rooms rooms rooms price price price';
	}
`;

export const FieldWrapper = styled.div`
	display: flex;
	height: 44px;
	border-radius: 4px;
	border: ${({ theme }) => `1px solid ${theme.middleGrayColor}`};
	background: #fff;
	box-sizing: border-box;

	@media (min-width: 768px) {
		border: none;
		height: 60px;

		& + & {
			border-left: ${({ theme }) => `3px solid ${theme.secondaryColor}`};
		}
	}
`;

export const CityButton = styled.button`
	display: flex;
	width: 100%;
	padding: 0 16px;
	margin: 0;
	background: transparent;
	box-shadow: none;
	border: none;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
	white-space: nowrap;
	cursor: pointer;
	color: ${({ theme }) => theme.primaryBlackColor};
	${({ theme }) => theme.typography.textBoldM};

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@media (max-width: 480px) {
		padding: 0 10px;
	}
`;

export const AreaWrapper = styled.span`
	display: flex;
	gap: 12px;
	align-items: center;
	flex-shrink: 0;
`;

export const AreaChip = styled.span`
	display: flex;
	align-items: center;
	margin: auto 0;
	padding: 0 12px;
	height: 24px;
	color: ${({ theme }) => theme.primaryColor};
	background: ${({ theme }) => theme.secondaryColor};
	border-radius: 4px;
	${({ theme }) => theme.typography.textMediumS};
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	gap: 16px;
	margin-top: auto;
	flex-wrap: nowrap;

	@media (max-width: 390px) {
		flex-wrap: wrap;
	}

	@media (max-width: 767px) {
		width: 100%;

		& > a {
			flex: 1 1 100%;
		}
	}

	@media (min-width: 768px) {
		margin: 0 3px auto auto;
		align-self: flex-end;
	}
`;

export const ButtonIcon = styled.svg`
	width: 24px;
	height: 24px;
	fill: currentColor;
	margin-right: 16px;
`;

export const LottieWrapper = styled.div`
	position: absolute;
	filter: drop-shadow(0 4px 15px rgba(110, 99, 177, 0.23));

	& > div,
	& > img {
		position: absolute;
		width: 100%;
		opacity: 1;
		transition: opacity 0.3s;

		&:first-of-type {
			z-index: 2;
		}

		&:last-of-type {
			z-index: 1;
			opacity: 0;
		}
	}

	&:hover {
		& > div,
		& > img {
			&:first-of-type {
				opacity: 0;
			}

			&:last-of-type {
				opacity: 1;
			}
		}
	}
`;

export const SlideStyle = createGlobalStyle`
	@keyframes homeDotsAnimation {
		from {
			background-position: 100%;
		}
		to {
			background-position: 0;
		}
	}

	.homeStatSlider {
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-areas: 'slider' 'dots';
		grid-gap: 8px;

		.slick {
			&-list {
				overflow: hidden;
				height: 100%;
				grid-area: slider;
			}

			&-dots {
				grid-area: dots;
				list-style: none;
				display: flex !important;
				margin: 0;
              	padding: 0;
				overflow: hidden;
				justify-content: center;

				& > li {
					display: flex;
					margin: 0 4px;

					& > button {
						padding: 0;
						font-size: 0;
						border: 0;
						box-shadow: none;
					  	border-radius: 2px;
						height: 4px;
                      	width: 24px;
						background-color: ${({ theme }) => theme.darkGrayColor};
						transition: background-color 0.2s, width 0.2s;
					}

					&.slick-active {
						& > button {
							background: ${({ theme }) =>
								`linear-gradient(90deg, ${theme.baseGrayColor} 0%, ${theme.baseGrayColor} 50%, ${theme.darkGrayColor} 50%, ${theme.darkGrayColor} 100%)`};
							background-size: 200%;
							animation: homeDotsAnimation 10s linear 1;
						}
					}
				}
			}

			&-track {
				display: flex;
				align-items: center;
				height: 100%;
				line-height: 0;
				font-size: 0;
			}

			&-slide {
				display: flex;
				width: 100%;
				height: 230px;
				padding: 0 8px;
				box-sizing: border-box;

				& > div {
					display: flex;
					width: 100%;
				}

				@media (min-width: 1600px) {
					padding: 0 26px;
				}
			}
		}
	}

	.mortgageStatSlider {
		flex: 1 1 100%;
		width: calc(100% + 48px);
		margin: 0 -24px -20px;

		.slick {
			&-list {
				height: 100%;
			}

			&-track {
				display: flex;
				align-items: center;
				height: 100%;
				line-height: 0;
				font-size: 0;
				gap: 0;
			}

			&-slide {
				display: flex;
				width: 100%;
				height: 100%;

				& > div {
					display: flex;
					width: 100%;
				}
			}
		}
	}
`;

export const InputLabel = styled.label`
	display: flex;
	flex-direction: column;
	gap: 4px;
	color: ${({ theme }) => theme.darkGrayColor};
	${({ theme }) => theme.typography.textMediumS};

	@media (min-width: 768px) {
		font-weight: normal;
		gap: 10px;
	}
`;

export const SwitcherLabel = styled.label`
	grid-column: span 2;
	width: 100%;
	display: flex;
	gap: 8px;
	justify-content: space-between;
	align-items: center;
`;
