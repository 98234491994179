/* @flow */
import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 0;
`;

const Spin = styled.div`
	box-sizing: border-box;
	width: 130px;
	height: 130px;
	position: relative;

	.spin-box {
		position: absolute;
		margin: auto;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 15px;
		height: 15px;
		border-radius: 100%;
		box-shadow: ${({ theme }) =>
			`15px 15px ${theme.primaryColor}, -15px 15px ${theme.primaryLight}, -15px -15px ${theme.primaryColor}, 15px -15px ${theme.primaryLight}`};
		animation: cssload-spin ease infinite 4.6s;
	}

	@keyframes cssload-spin {
		0%,
		100% {
			box-shadow: ${({ theme }) =>
				`15px 15px ${theme.primaryColor}, -15px 15px ${theme.primaryLight}, -15px -15px ${theme.primaryColor}, 15px -15px ${theme.primaryLight}`};
		}
		25% {
			box-shadow: ${({ theme }) =>
				`-15px 15px ${theme.primaryLight}, -15px -15px ${theme.primaryColor}, 15px -15px ${theme.primaryLight}, 15px 15px ${theme.primaryColor}`};
		}
		50% {
			box-shadow: ${({ theme }) =>
				`-15px -15px ${theme.primaryColor}, 15px -15px ${theme.primaryLight}, 15px 15px ${theme.primaryColor}, -15px 15px ${theme.primaryLight}`};
		}
		75% {
			box-shadow: ${({ theme }) =>
				`15px -15px ${theme.primaryLight}, 15px 15px ${theme.primaryColor}, -15px 15px ${theme.primaryLight}, -15px -15px ${theme.primaryColor}`};
		}
	}
`;

const Loader = () => {
	return (
		<Root>
			<Spin>
				<div className="spin-box" />
			</Spin>
		</Root>
	);
};

export default Loader;
