import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import theme from '../../theme';

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

const sweep = keyframes`
	0% {
		clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
	}

	50% {
		clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
	}

	100% {
		clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
	}
`;

const getBackgroundColor = ({
	disabled,
	inverted,
	isFilter,
	isActive,
	gray,
	grayBlack,
	blue,
	lightBlue,
	yellow,
	dashedText,
	dark,
	theme: { disabledColor, yellowColor, grayColor, blueColor, darkestGray },
}) => {
	if ((isFilter && isActive) || (grayBlack && isActive) || yellow) {
		return yellowColor;
	}
	if ((disabled && inverted) || inverted || isFilter || grayBlack) {
		return 'transparent';
	}
	if (disabled) {
		return disabledColor;
	}
	if (gray) {
		return grayColor;
	}
	if (blue) {
		return blueColor;
	}
	if (lightBlue) {
		return '#E6F2FA';
	}
	if (dashedText) {
		return 'transparent';
	}
	if (dark) {
		return darkestGray;
	}
	return theme.primaryColor;
};

const getHoverBackgroundColor = ({
	disabled,
	inverted,
	isFilter,
	gray,
	grayBlack,
	blue,
	lightBlue,
	yellow,
	noHover,
	dashedText,
	dark,
	theme: { accentColor, disabledColor, yellowColor, blueColor, grayColor, darkestGrayHover },
}) => {
	if (disabled && inverted) {
		return 'transparent';
	}
	if (disabled) {
		return disabledColor;
	}
	if (inverted && !grayBlack) {
		return theme.primaryColor;
	}
	if (gray && noHover) {
		return grayColor;
	}
	if (isFilter || gray || grayBlack || yellow || (grayBlack && inverted)) {
		return yellowColor;
	}
	if (blue) {
		return blueColor;
	}
	if (lightBlue) {
		return '#E6F2FA';
	}
	if (dark) {
		return darkestGrayHover;
	}
	if (dashedText) {
		return 'transparent';
	}
	return accentColor;
};

const getColor = ({
	disabled,
	inverted,
	isFilter,
	gray,
	grayBlack,
	lightBlue,
	yellow,
	dashedText,
	theme: { disabledColor, blueColor },
}) => {
	if (disabled && inverted) {
		return disabledColor;
	}
	if (inverted && !grayBlack) {
		return theme.primaryColor;
	}
	if (isFilter || gray || grayBlack || yellow || (grayBlack && inverted)) {
		return '#000';
	}
	if (lightBlue && !disabled) {
		return '#8DC5EB';
	}
	if (dashedText) {
		return blueColor;
	}
	return '#fff';
};

const getHoverColor = ({
	disabled,
	inverted,
	isFilter,
	gray,
	grayBlack,
	lightBlue,
	yellow,
	dashedText,
	theme: { disabledColor, blueColor },
}) => {
	if (disabled && inverted) {
		return disabledColor;
	}
	if (isFilter || gray || grayBlack || yellow) {
		return '#000';
	}
	if (dashedText) {
		return blueColor;
	}
	if (lightBlue) {
		return '#8DC5EB';
	}
	return '#fff';
};

const getBorderColor = ({
	disabled,
	isFilter,
	isActive,
	gray,
	grayBlack,
	blue,
	lightBlue,
	yellow,
	dashedText,
	dark,
	theme: { disabledColor, yellowColor, grayColor, blueColor, darkestGray },
}) => {
	if ((isFilter && isActive) || (grayBlack && isActive) || yellow) {
		return yellowColor;
	}
	if (disabled || isFilter) {
		return disabledColor;
	}
	if (gray) {
		return grayColor;
	}
	if (grayBlack) {
		return '#dcdcdc';
	}
	if (blue) {
		return blueColor;
	}
	if (lightBlue) {
		return '#E6F2FA';
	}
	if (dashedText) {
		return blueColor;
	}
	if (dark) {
		return darkestGray;
	}
	return theme.primaryColor;
};

const getHoverBorderColor = ({
	disabled,
	inverted,
	isFilter,
	gray,
	grayBlack,
	blue,
	lightBlue,
	yellow,
	noHover,
	dashedText,
	dark,
	theme: { accentColor, disabledColor, yellowColor, blueColor, grayColor, darkestGrayHover },
}) => {
	if (disabled && inverted) {
		return disabledColor;
	}
	if (disabled) {
		return disabledColor;
	}
	if (inverted && !grayBlack) {
		return theme.primaryColor;
	}
	if (gray && noHover) {
		return grayColor;
	}
	if (isFilter || gray || grayBlack || yellow || (grayBlack && inverted)) {
		return yellowColor;
	}
	if (blue) {
		return blueColor;
	}
	if (lightBlue) {
		return '#E6F2FA';
	}
	if (dark) {
		return darkestGrayHover;
	}
	if (dashedText) {
		return blueColor;
	}
	return accentColor;
};

const getPadding = ({ extraSmall, superSmall, isFilter, dashedText, big }) => {
	if (superSmall) {
		return '2px 12px';
	}
	if (extraSmall) {
		return '8px 12px';
	}
	if (isFilter) {
		return '9px 20px';
	}
	if (dashedText) {
		return '0';
	}
	if (big) {
		return '12px 52px';
	}

	return '11px 24px';
};

const getFontSize = ({ extraSmall, superSmall, small, isFilter, big }) => {
	if (extraSmall || superSmall) {
		return '12px';
	}

	if (small || isFilter) {
		return '14px';
	}

	if (big) {
		return '24px';
	}

	return '20px';
};

const Loader = css`
	position: relative;
	color: transparent !important;
	transition: color 0.2s;
	pointer-events: none;

	&:hover {
		color: transparent;
	}

	&::after {
		position: absolute;
		left: ${(props) => `calc(50% - ${getFontSize(props)} / 2)`};
		width: ${(props) => getFontSize(props)};
		height: ${(props) => getFontSize(props)};
		border-radius: 50%;
		border: 2px solid ${(props) => getColor(props)};
		animation: ${sweep} 1s linear alternate infinite, ${rotate} 0.8s linear infinite;
		content: '';
	}
`;

const Button = styled.button`
	padding: ${(props) => getPadding(props)};
	font-size: ${(props) => getFontSize(props)};
	font-weight: 500;
	line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.2')};
	color: ${(props) => getColor(props)};
	background-color: ${(props) => getBackgroundColor(props)};
	border-width: 1px;
	border-style: solid;
	border-color: ${(props) => getBorderColor(props)};
	border-radius: ${(props) => (props.rounded ? (props.big ? '27px' : '24px') : 0)};
	transition: all 0.2s ease;
	text-decoration: none;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	-moz-appearance: none !important;
	-webkit-appearance: none !important;
	${(props) => props.dashedText && 'border: 0; border-bottom-width: 1px; border-bottom-style: dashed;'}
	${(props) => props.isHide && 'display: none !important;'}
	${(props) => props.isLoading && Loader}

	&:focus {
		outline: none;
		border: 1px solid #000;
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover,
		&:active {
			background-color: ${(props) => getHoverBackgroundColor(props)};
			color: ${(props) => getHoverColor(props)};
			border-color: ${(props) => getHoverBorderColor(props)};
		}
	}

	@media (hover: none), (pointer: coarse) {
		&:hover,
		&:active {
			background-color: ${(props) => (props.isActive ? getHoverBackgroundColor(props) : getBackgroundColor(props))};
			color: ${(props) => (props.isActive ? getHoverColor(props) : getColor(props))};
			border-color: ${(props) => (props.isActive ? getHoverBorderColor(props) : getBorderColor(props))};
		}
	}
`;

Button.propTypes = {
	children: PropTypes.node.isRequired,
	type: PropTypes.string,
	disabled: PropTypes.bool,
	inverted: PropTypes.bool,
	rounded: PropTypes.bool,
	small: PropTypes.bool,
	extraSmall: PropTypes.bool,
	superSmall: PropTypes.bool,
	big: PropTypes.bool,
	gray: PropTypes.bool,
	grayBlack: PropTypes.bool,
	blue: PropTypes.bool,
	lightBlue: PropTypes.bool,
	yellow: PropTypes.bool,
	dark: PropTypes.bool,
	noHover: PropTypes.bool,
	isFilter: PropTypes.bool,
	isActive: PropTypes.bool,
	isHide: PropTypes.bool,
	onClick: PropTypes.func,
	dashedText: PropTypes.bool,
	isLoading: PropTypes.bool,
};

Button.defaultProps = {
	type: 'button',
	inverted: false,
	disabled: false,
	small: false,
	extraSmall: false,
	superSmall: false,
	big: false,
	rounded: false,
	gray: false,
	grayBlack: false,
	blue: false,
	lightBlue: false,
	yellow: false,
	dark: false,
	noHover: false,
	isFilter: false,
	isActive: false,
	isHide: false,
	dashedText: false,
	isLoading: false,
	onClick: () => {},
};

export default Button;
