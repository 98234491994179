export const SET_BASE_FILTER = 'SET_BASE_FILTER';
export const RESET_BASE_FILTER = 'RESET_BASE_FILTER';
export const SET_EXT_FILTER = 'SET_EXT_FILTER';
export const RESET_EXT_FILTER = 'RESET_EXT_FILTER';
export const SET_GLOBAL_FILTER = 'SET_GLOBAL_FILTER';
export const SET_GLOBAL_COMPLEX_VIEW = 'SET_GLOBAL_COMPLEX_VIEW';
export const SET_GLOBAL_SEARCH_MODE = 'SET_GLOBAL_SEARCH_MODE';
export const SET_GLOBAL_SELECTION_MODE = 'SET_GLOBAL_SELECTION_MODE';
export const SET_GLOBAL_DATA_MODE = 'SET_GLOBAL_DATA_MODE';
export const RESET_GLOBAL_FILTER = 'RESET_GLOBAL_FILTER';
export const SET_WISHES_FILTER = 'SET_WISHES_FILTER';
export const RESET_WISHES_FILTER = 'RESET_WISHES_FILTER';
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';
export const SET_COMPLEX_FILTER = 'SET_COMPLEX_FILTER';
export const RESET_COMPLEX_FILTER = 'RESET_COMPLEX_FILTER';
export const RESET_ALL_COMPLEX_FILTERS = 'RESET_ALL_COMPLEX_FILTERS';
