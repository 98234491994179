/* @flow */

import React from 'react';
import { arrowIconMenu, checkIconNew, CloseButtonIcon } from '../../../../components/Icons';
import { Button, CloseButton, List, ListHeader, ListItem, Overflow, Root } from './styles';
import type { TOption } from '../../../../components/Filter/Filter';

type TProps = {
	title: string,
	mode: string,
	mobileTitle: string,
	currentValue: string | string[],
	options: TOption[],
	onOptionClick: () => {},
	isOpen: boolean,
	setOpen: () => {},
	multiselect?: boolean,
	noWrap?: boolean,
};

const FakeSelect = ({
	title,
	mode,
	mobileTitle,
	currentValue,
	options,
	onOptionClick,
	isOpen,
	setOpen,
	multiselect = false,
	noWrap = false,
}: TProps) => (
	<Root $isOpen={isOpen}>
		<Button $isOpen={isOpen} onClick={setOpen(mode)}>
			{noWrap ? <span>{title}</span> : title}
			<svg viewBox="0 0 10 18">{arrowIconMenu}</svg>
		</Button>
		<List $isOpen={isOpen}>
			<ListHeader>
				{mobileTitle}
				<CloseButton onClick={setOpen(null)}>
					<svg viewBox="0 0 16 16">{CloseButtonIcon}</svg>
				</CloseButton>
			</ListHeader>
			{options.map((item: TOption) => {
				const isActive = multiselect ? currentValue.includes(item.value) : currentValue === item.value;

				return (
					<ListItem key={item.label} onClick={onOptionClick(item.value)}>
						{item.label}
						{isActive && <svg viewBox="0 0 20 20">{checkIconNew}</svg>}
					</ListItem>
				);
			})}
		</List>
		{isOpen && <Overflow $isOpen={isOpen} role="button" onClick={setOpen(null)} />}
	</Root>
);

FakeSelect.defaultProps = {
	multiselect: false,
	noWrap: false,
};

export default FakeSelect;
