/* @flow */
import type { TAction } from '../types/core';
import {
	FAIL,
	LIRA_GET_NEXT_APART,
	LIRA_SET_CALLBACK_POPUP_ADVERT_TEXTS,
	LIRA_SET_CALLBACK_POPUP_TEXTS,
	LIRA_SET_CANCEL_SIDEBAR,
	LIRA_SET_POPUP,
	LIRA_SET_POPUP_TEXTS,
	LIRA_SET_POPUP_TYPE,
	LIRA_SET_SAVE_SIDEBAR,
	LIRA_SET_SIDEBAR_SCREEN,
	LIRA_SET_TEMP_MORTGAGE_PARAMS,
	LIRA_SET_TEMP_PARAMS,
	LIRA_UPDATE_APARTMENT_OPINION,
	LIRA_UPDATE_WISHES,
	PREPARE_CHANGE_CITY,
	SET_CURRENT_CITY,
	SET_SIDEBAR_CLOSE,
	SUCCESS,
} from '../constants';
import { TFilterBase, TFilterPopupText } from '../types/filters';

export type TFilterPopupType = 'popupFilter' | 'popupWishes' | 'popupNoVariant' | '';

export type TLiraPopupsState = {
	popupType: string,
	tempAreaIds: number[],
	tempMetroIds: number[],
	tempParams: TFilterBase,
	areaForFilter: number,
	sidebarScreen: 'root' | 'area' | 'metro',
	saveSidebar: boolean,
	cancelSidebar: boolean,
	notices: string[] | null,
	apartNotices: { [id: string]: string[] | null },
	popupFilterTexts: {
		sortDescription: TFilterPopupText,
		titleText: TFilterPopupText,
	},
	popupCallbackTexts: {
		promoBlockList: TFilterPopupText,
		promoBlockText: TFilterPopupText,
	},
	popupCallbackAdvertTexts: {
		adText: TFilterPopupText,
		promoBlockList: TFilterPopupText,
		promoBlockText: TFilterPopupText,
	},
};

const initialState: TLiraPopupsState = {
	popupType: '',
	tempAreaIds: [],
	tempMetroIds: [],
	tempParams: {
		areaIds: [],
		metroIds: [],
	},
	areaForFilter: 2,
	sidebarScreen: 'root',
	saveSidebar: false,
	cancelSidebar: false,
	notices: [],
	apartNotices: {},
	popupFilterTexts: null,
	popupCallbackTexts: null,
	popupCallbackAdvertTexts: null,
};

function addNotices(state, action) {
	const { payload } = action;
	const { apartId, notices } = payload;

	return {
		...state,
		apartNotices: {
			...state.apartNotices,
			[apartId]: notices,
		},
	};
}

const liraPopups = (state: TLiraPopupsState = initialState, action: TAction): TLiraPopupsState => {
	switch (action.type) {
		case LIRA_SET_POPUP:
			return addNotices(state, action);

		case LIRA_SET_POPUP_TYPE:
			return { ...state, popupType: action.payload, sidebarScreen: initialState.sidebarScreen };

		case LIRA_GET_NEXT_APART + SUCCESS:
		case LIRA_GET_NEXT_APART + FAIL:
		case LIRA_UPDATE_APARTMENT_OPINION + SUCCESS:
		case LIRA_UPDATE_APARTMENT_OPINION + FAIL:
			return {
				...state,
				apartNotices: {
					...state.apartNotices,
					[action.payload]: [],
				},
			};

		case PREPARE_CHANGE_CITY:
			return {
				...state,
				popupType: initialState.popupType,
				sidebarScreen: initialState.sidebarScreen,
				tempAreaIds: [],
				tempMetroIds: [],
				tempParams: { ...state.tempParams, areaIds: [], metroIds: [] },
			};

		case LIRA_SET_SAVE_SIDEBAR:
			return { ...state, saveSidebar: action.payload };

		case LIRA_SET_CANCEL_SIDEBAR:
			return {
				...state,
				cancelSidebar: action.payload,
				popupType:
					(state.popupType === 'sidebarFilter' || state.popupType === 'sidebarWishes') && state.cancelSidebar
						? initialState.popupType
						: state.popupType,
				sidebarScreen:
					(state.popupType === 'sidebarFilter' || state.popupType === 'sidebarWishes') &&
					state.sidebarScreen !== initialState.sidebarScreen
						? initialState.sidebarScreen
						: state.sidebarScreen,
			};

		case LIRA_SET_SIDEBAR_SCREEN:
			return { ...state, sidebarScreen: action.payload };

		case LIRA_SET_POPUP_TEXTS:
			return { ...state, popupFilterTexts: action.payload };

		case LIRA_SET_CALLBACK_POPUP_TEXTS:
			return { ...state, popupCallbackTexts: action.payload };

		case LIRA_SET_CALLBACK_POPUP_ADVERT_TEXTS:
			return { ...state, popupCallbackAdvertTexts: action.payload };

		case LIRA_SET_TEMP_PARAMS:
			return { ...state, tempParams: { ...state.tempParams, ...action.payload } };

		case LIRA_SET_TEMP_MORTGAGE_PARAMS:
			return {
				...state,
				tempParams: {
					...state.tempParams,
					mortgageInfo: { ...state.tempParams.mortgageInfo, ...action.payload },
				},
			};

		// case SET_BASE_FILTER:
		case LIRA_UPDATE_WISHES:
		case SET_SIDEBAR_CLOSE:
			return {
				...state,
				tempParams: initialState.tempParams,
				saveSidebar:
					(state.popupType === 'sidebarFilter' || state.popupType === 'sidebarWishes') && state.saveSidebar
						? initialState.saveSidebar
						: state.saveSidebar,
				popupType:
					state.popupType === 'sidebarFilter' || state.popupType === 'sidebarWishes'
						? initialState.popupType
						: state.popupType,
				sidebarScreen:
					(state.popupType === 'sidebarFilter' || state.popupType === 'sidebarWishes') &&
					state.sidebarScreen !== initialState.sidebarScreen
						? initialState.sidebarScreen
						: state.sidebarScreen,
			};

		case SET_CURRENT_CITY:
			return {
				...state,
				tempParams: initialState.tempParams,
			};

		default:
			return state;
	}
};

export { initialState as liraPopupsInitialState };
export default liraPopups;
