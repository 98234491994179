/* @flow */
import React, { type Node } from 'react';
import styled from 'styled-components';
import { textFontSizeM, textFontSizeS } from '../../theme';

const Root = styled.div`
	position: relative;
	/* display: inline-block; */
	display: inline-flex;
	/* vertical-align: middle; */
	margin-bottom: ${({ withoutMargin }) => (withoutMargin ? 0 : '15px')};
	${({ toRight }) => toRight && 'flex-direction: row-reverse'};
	align-items: center;

	@media (max-width: 376px) {
		${({ withoutMargin }) => !withoutMargin && 'margin-bottom: 12px'};
	}
`;

const CheckWrapper = styled.div`
	position: relative;
	z-index: 1;
	width: ${({ $isBigger }) => ($isBigger ? '20px' : '15px')};
	height: ${({ $isBigger }) => ($isBigger ? '20px' : '15px')};
	display: inline-block;
	flex-shrink: 0;
	vertical-align: middle;
`;

const Box = styled.span`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 5;
	border: 1px solid #b3b3b3;
	border-radius: 4px;
	box-sizing: border-box;
	background-color: #fff;
`;

const Input = styled.input`
	position: absolute;
	border: none;
	padding: 0;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10;
	opacity: 0;
	cursor: pointer;

	&:checked ~ ${Box} {
		background-color: ${({ theme }) => theme.primaryColor};
		border-color: ${({ theme }) => theme.primaryColor};

		&:before {
			position: absolute;
			top: ${({ $isBigger }) => ($isBigger ? '3px' : '2px')};
			left: ${({ $isBigger }) => ($isBigger ? '3px' : '2px')};
			content: '';
			width: ${({ $isBigger }) => ($isBigger ? '10px' : '8px')};
			height: ${({ $isBigger }) => ($isBigger ? '5px' : '4px')};
			border-left: 2px solid #fff;
			border-bottom: 2px solid #fff;
			transform: rotate(-45deg);
		}
	}

	&:focus {
		& ~ ${Box} {
			border-color: ${({ theme }) => theme.primaryColor};
		}

		&:checked {
			& ~ ${Box} {
				background-color: ${({ theme }) => theme.primaryBlackColor};
				border-color: ${({ theme }) => theme.primaryBlackColor};
			}
		}
	}
`;

const Label = styled.label`
	vertical-align: middle;
	${({ $toRight }) => ($toRight ? 'margin-right: 7px' : 'margin-left: 7px')};
	cursor: pointer;
	transition: color 0.3s;
	color: ${({ $disabled, $isLight, theme }) =>
		$disabled ? 'rgba(0, 0, 0, 0.4)' : $isLight ? theme.darkGrayColor : theme.primaryBlackColor};
	${({ theme }) => theme.typography.textM};

	@media (max-width: 600px) {
		font-size: ${({ $isBigger }) => ($isBigger ? textFontSizeM : textFontSizeS)};
	}
`;

type TProps = {
	checked?: boolean,
	disabled?: boolean,
	personal?: boolean,
	withoutMargin?: boolean,
	toRight?: boolean,
	label?: string,
	name: string,
	id: string | number,
	isBigger?: boolean,
	isLight?: boolean,
	onChange: () => {},
	children?: Node,
	style?: any,
};

const Check = ({
	onChange,
	id,
	name,
	label,
	checked,
	disabled,
	personal,
	withoutMargin,
	toRight,
	isBigger,
	isLight,
	children,
	style,
}: TProps): Node => (
	<Root withoutMargin={withoutMargin} toRight={toRight} style={style}>
		<CheckWrapper $isBigger={isBigger}>
			<Input
				type="checkbox"
				onChange={onChange}
				id={id}
				name={name}
				checked={checked}
				disabled={disabled}
				$isBigger={isBigger}
			/>
			<Box />
		</CheckWrapper>

		{(label || children || personal) && (
			<Label htmlFor={id} $toRight={toRight} $disabled={disabled} $isLight={isLight} $isBigger={isBigger}>
				{label || children}
				{personal && (
					<>
						Даю согласие на передачу и обработку{' '}
						<a
							href="https://living.ru/soglasie-na-obrabotku-personalnyh-dannyh/"
							target="_blank"
							rel="noopener noreferrer"
						>
							персональных данных
						</a>
					</>
				)}
			</Label>
		)}
	</Root>
);

Check.defaultProps = {
	checked: false,
	disabled: false,
	personal: false,
	withoutMargin: false,
	toRight: false,
	label: null,
	isBigger: false,
	isLight: false,
	children: null,
	style: undefined,
};

export default Check;
