import Tippy from '@tippyjs/react';
import styled from 'styled-components';

export const StyledTippy = styled(Tippy)`
	transition-property: opacity;

	.tippy-backdrop {
		background-color: transparent;
	}

	.tippy-arrow:before {
		content: '';
		position: absolute;
		border-color: transparent;
		border-style: solid;
	}

	&[data-state='hidden'] {
		opacity: 0;
	}

	&[data-placement^='top'] > .tippy-arrow {
		bottom: 0;

		&:before {
			bottom: -7px;
			left: 0;
			border-width: 8px 8px 0;
			border-top-color: initial;
			transform-origin: center top;
		}
	}

	&[data-placement^='left'] > .tippy-arrow {
		right: 0;

		&:before {
			border-width: 8px 0 8px 8px;
			border-left-color: initial;
			right: -7px;
			transform-origin: center left;
		}
	}

	&[data-placement^='right'] > .tippy-arrow {
		left: 0;

		&:before {
			left: -7px;
			border-width: 8px 8px 8px 0;
			border-right-color: initial;
			transform-origin: center right;
		}
	}

	&[data-placement^='bottom'] > .tippy-arrow {
		top: 0;

		&:before {
			top: -7px;
			left: -8px;
			border-width: 0 8px 8px;
			border-bottom-color: initial;
			transform-origin: center bottom;
		}
	}

	&[data-theme~='clean'] {
		width: 100%;
		padding: 0;
		background-color: transparent;
	}

	&[data-theme~='white'] {
		padding: 12px;
		color: #a0a3bd;
		font-weight: 500;
		font-size: 12px;
		line-height: 135%;
		text-align: left;
		background-color: #fff;
		border: 1px solid #f0f1f4;
		border-radius: 8px;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);

		& p {
			margin: 0;
		}

		&.small {
			font-size: 12px;
		}

		&.discounts {
			padding: 16px;
		}

		&[data-placement^='top'] > .tippy-arrow::before {
			border-top-color: #fff;
		}

		&[data-placement^='bottom'] > .tippy-arrow::before {
			border-bottom-color: #fff;
		}

		&[data-placement^='left'] > .tippy-arrow::before {
			border-left-color: #fff;
		}

		&[data-placement^='right'] > .tippy-arrow::before {
			border-right-color: #fff;
		}

		&[data-placement^='bottom-start'] {
			margin-left: -20px;
			padding: 16px;
		}
	}

	&[data-theme~='blue'] {
		box-sizing: border-box;
		max-width: 100%;
		padding: 12px;
		overflow: visible;
		color: #000;
		font-weight: 500;
		font-size: 14px;
		text-align: left;
		background-color: #cee6f7;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);

		&.small {
			font-size: 12px;
		}

		& p {
			margin-top: 0;
			margin-bottom: 10px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&[data-placement^='top'] > .tippy-arrow::before {
			border-top-color: #cee6f7;
		}

		&[data-placement^='bottom'] > .tippy-arrow::before {
			border-bottom-color: #cee6f7;
		}

		&[data-placement^='left'] > .tippy-arrow::before {
			border-left-color: #cee6f7;
		}

		&[data-placement^='right'] > .tippy-arrow::before {
			border-right-color: #cee6f7;
		}
	}

	&[data-theme~='dark'] {
		box-sizing: border-box;
		max-width: 100%;
		padding: 12px;
		overflow: visible;
		color: #fff;
		font-weight: 500;
		font-size: 14px;
		text-align: left;
		background-color: #707070;
		border-radius: 4px;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);

		&.small {
			border-radius: 2px;
			font-size: 12px;
		}

		& p {
			margin-top: 0;
			margin-bottom: 10px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&[data-placement^='top'] > .tippy-arrow::before {
			border-top-color: #707070;
		}

		&[data-placement^='bottom'] > .tippy-arrow::before {
			border-bottom-color: #707070;
		}

		&[data-placement^='left'] > .tippy-arrow::before {
			border-left-color: #707070;
		}

		&[data-placement^='right'] > .tippy-arrow::before {
			border-right-color: #707070;
		}
	}

	&[data-theme~='rank'] {
		padding: 16px;
		color: #000;
		font-weight: 500;
		font-size: 12px;
		line-height: 135%;
		text-align: left;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);

		&[data-placement^='bottom'] > .tippy-arrow::before {
			border-bottom-color: #fff;
		}
	}

	&[data-theme~='priceDiff'] {
		color: #000;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
		margin-right: -20px;

		@media (max-width: 600px) {
			margin: 0;
		}

		&[data-placement^='bottom-end'] > .tippy-arrow {
			&:before {
				border-bottom-color: #fff;
				left: -18px;
				top: -12px;
				border-width: 0px 14px 14px;
				outline: none;
			}
		}

		&[data-placement^='top-start'] {
			margin-left: -20px;
			> .tippy-arrow {
				&:before {
					border-top-color: #fff;
					top: 0px;
					left: -20px;
					border-width: 14px 14px 0px;
					outline: none;
				}
			}
		}

		&[data-placement^='bottom-start'] {
			margin-left: -20px;
			> .tippy-arrow {
				&:before {
					border-bottom-color: #fff;
					left: -18px;
					top: -12px;
					border-width: 0px 14px 14px;
					outline: none;
				}
			}
		}
		&[data-placement^='top-end'] {
			> .tippy-arrow {
				&:before {
					border-top-color: #fff;
					top: 0px;
					left: -20px;
					border-width: 14px 14px 0px;
				}
			}
		}
	}

	&[data-theme~='selectionNotices'] {
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
		width: 100%;

		&[data-placement^='top'] {
			> .tippy-arrow {
				&:before {
					border-top-color: #fff;
					top: 0px;
					border-width: 14px 14px 0px;
				}
			}
		}
	}
`;
