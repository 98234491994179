/* @flow */

import type { TSelectionComplexItem, TSelectionMeta } from '../types/lexs';
import type { TAction } from '../types/core';
import {
	FAIL,
	FETCH_SELECTION_DATA,
	LIRA_CHANGE_USER_PROFILE,
	SELECTION_CHANGE_PAGE,
	SELECTION_CHANGE_SAMPLING,
	SELECTION_RELOAD_PAGE,
	SET_SELECTION_VIEW,
	SET_SHOW_SORTING_MODAL,
	START,
	SUCCESS,
} from '../constants';

export type TSelectionSampling = 'TOP10' | 'TOP30' | 'ALL' | 'LIKED' | 'DISLIKED';

export type TSelectionState = {
	items: TSelectionComplexItem[],
	meta: TSelectionMeta,
	isLoading: boolean,
	isLoaded: boolean,
	sampling: TSelectionSampling,
	page: number,
	count: number,
	needUpdate: boolean,
	reloadPage: boolean,
	showSortingModal: boolean,
};

const initialState: TSelectionState = {
	items: [],
	meta: {},
	isLoading: false,
	isLoaded: false,
	sampling: 'ALL',
	page: 0,
	count: 12,
	needUpdate: true,
	reloadPage: true,
	showSortingModal: false,
};

const reducer = (state: TSelectionState = initialState, action: TAction): TSelectionState => {
	const { type, payload } = action;
	switch (type) {
		case FETCH_SELECTION_DATA + START:
			return { ...state, isLoading: true, isLoaded: false };

		case FETCH_SELECTION_DATA + SUCCESS:
			return {
				...state,
				items: [...state.items, ...payload.items],
				meta: payload.meta,
				isLoading: false,
				isLoaded: true,
				needUpdate: false,
				reloadPage: false,
			};

		case FETCH_SELECTION_DATA + FAIL:
			return { ...state, isLoading: false, isLoaded: false };

		case SELECTION_CHANGE_SAMPLING:
			return {
				...state,
				items: initialState.items,
				meta: initialState.meta,
				sampling: payload,
				needUpdate: true,
				reloadPage: true,
				page: state.page > 0 ? 0 : state.page,
			};

		case SELECTION_CHANGE_PAGE:
			return { ...state, page: payload, needUpdate: true };

		case SELECTION_RELOAD_PAGE:
			return { ...state, reloadPage: true };

		case LIRA_CHANGE_USER_PROFILE + SUCCESS:
			return {
				...state,
				items: initialState.items,
				meta: initialState.meta,
				page: state.page > 0 ? 0 : state.page,
				needUpdate: true,
				reloadPage: true,
			};

		case SET_SELECTION_VIEW:
			return {
				...state,
				items: state.items.slice(0, state.count),
				meta: { ...state.meta, pageNumber: 0 },
				page: initialState.page,
			};

		case SET_SHOW_SORTING_MODAL:
			return { ...state, showSortingModal: payload };

		default:
			return state;
	}
};

export { initialState as selectionInitialState };

export default reducer;
