export default [
	{ id: 1, title: 'Цена', sort_field: 'pricePerMeterAverageAsc' },
	{ id: 2, title: 'Общий рейтинг', sort_field: 'ratingComplex' },
	{ id: 3, title: 'Безопасность', sort_field: 'ratingServiceSecurity' },
	{ id: 4, title: 'Транспорт', sort_field: 'ratingTransport' },
	{ id: 5, title: 'Квартира и дом', sort_field: 'ratingHouseApartment' },
	{ id: 6, title: 'Для детей', sort_field: 'ratingChildrenInfrastructure' },
	{ id: 7, title: 'Инфраструктура', sort_field: 'ratingInfrastructure' },
	{ id: 8, title: 'Район', sort_field: 'ratingArea' },
];
