import styled, { css } from 'styled-components';
import { NumericFormat } from 'react-number-format';

const inputHeight = 32;
const inputHeightCssValue = `${inputHeight}px`;

export const InputRootWrapper = styled.div`
	width: 100%;
	position: relative;
	z-index: 1;
	${(props) => props.styles};

	.noUi-horizontal {
		height: 2px;
		margin: 0;
		border: none;
		background: #d8d8d8;
	}

	.noUi-base {
		width: 100%;
		transform: translate3d(0, 0, 0);
	}

	.noUi-connect {
		background-color: #cc3b1f;
	}

	.noUi-handle {
		width: 15px !important;
		height: 15px;
		border: none;
		background: #cc3b1f;
		top: 0;
		left: -7px;
	}

	.noUi-origin {
		transform: none;
	}

	.noUi-tooltip {
		font-size: 14px;
		font-weight: 500;
		color: #cc3b1f;
		top: -180% !important;

		&::before,
		&::after {
			content: '';
		}
	}

	@media (max-width: 780px) {
		${(props) => props.styles};
		margin-left: 0 !important;
	}
	@media (max-width: 600px) {
		margin-left: 0 !important;
		width: 100%;
	}
`;

export const Input2022Style = css`
	padding-left: ${({ $hasPrefix }) => ($hasPrefix ? '42px' : '16px')};
	padding-right: ${({ $hasSuffix }) => ($hasSuffix ? '42px' : '16px')};
	height: 44px;
	background-color: transparent;
	border: 1px solid ${({ $hasError, theme }) => ($hasError ? theme.accentColor : theme.middleGrayColor)};
	border-radius: 4px;
	font-weight: normal;
	${({ theme }) => theme.typography.textL};
`;

export const InputLegacyStyle = css`
	padding-left: ${({ $hasPrefix }) => ($hasPrefix ? '32px' : '15px')};
	padding-right: ${({ $hasSuffix }) => ($hasSuffix ? '32px' : '15px')};
	height: ${() => inputHeightCssValue};
	background-color: white;
	border: 1px solid ${({ $hasError, theme }) => ($hasError ? theme.accentColor : theme.borderColor)};
	border-radius: ${({ $withSlider }) => ($withSlider ? '4px 4px 4px 0' : '5px')};
	${({ theme }) => theme.typography.textM};

	&:focus {
		border-color: ${({ theme }) => theme.primaryColor};
		box-shadow: 0px 0px 3.2px 0px rgba(48, 60, 246, 0.30);
	}
`;

export const Input = styled(NumericFormat)`
	position: relative;
	width: 100%;
	min-width: 100px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	color: ${({ theme }) => theme.primaryBlackColor};
	z-index: 1;
	${({ $newStyle }) => ($newStyle ? Input2022Style : InputLegacyStyle)};

	&:focus {
		/* border: 1px solid #000 !important; */
		outline: none !important;
	}
	&::placeholder {
		color: ${({ theme }) => theme.disabledColor};
		font-weight: 500;
	}

	&:disabled {
		background-color: ${(props) => props.theme.grayColor};
		color: ${({ theme }) => theme.disabledColor};
		border-color: ${(props) => props.theme.borderColor};
	}

	@media (max-width: 600px) {
		font-size: 16px;
	}
`;
export const InputRoot = styled.div`
	position: relative;
	${({ theme }) => theme.typography.textM};
	color: ${({ theme, $disabled }) => ($disabled ? theme.disabledColor : theme.primaryBlackColor)};
`;

export const BeforeElem = styled.div`
	position: absolute;
	left: ${({ $newStyle }) => ($newStyle ? '16px' : '10px')};
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
`;

export const AfterElem = styled.div`
	position: absolute;
	right: ${({ $newStyle }) => ($newStyle ? '16px' : '10px')};
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
`;

export const PseudoRangeSlider = styled.input.attrs((props) => ({ type: 'range' }))`
	margin: 0;
	width: ${({ $newStyle }) => ($newStyle ? 'calc(100% - 32px)' : '100%')};
	height: 10px;
	position: absolute;
	bottom: -5px;
	left: ${({ $newStyle }) => ($newStyle ? '16px' : 0)};
	z-index: 3;
	background: transparent;
	-webkit-appearance: none;

	&:focus {
		outline: none;
	}

	&::-webkit-slider-runnable-track {
		width: 10px;
		height: ${({ $newStyle }) => ($newStyle ? '4px' : '2px')};
		cursor: pointer;
		background: ${({ $sliderMode, theme }) => ($sliderMode === 'from' ? theme.primaryColor : theme.darkGrayColor)};
		overflow: hidden;
		-webkit-appearance: none;
		border-radius: ${({ $newStyle }) => ($newStyle ? '4px' : 0)};
	}

	&::-webkit-slider-thumb {
		margin-top: -4px;
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background: ${({ theme }) => theme.primaryColor};
		cursor: pointer;
		box-shadow: ${({ $sliderMode, theme }) =>
			$sliderMode === 'from'
				? `-1000px 0 0 1000px ${theme.darkGrayColor}`
				: `-1000px 0 0 1000px ${theme.primaryColor}`};
		-webkit-appearance: none;
	}

	&::-moz-range-track {
		width: 100%;
		height: ${({ $newStyle }) => ($newStyle ? '4px' : '2px')};
		box-shadow: none;
		border: none;
		background: ${({ $sliderMode, theme }) => ($sliderMode === 'from' ? theme.primaryColor : theme.darkGrayColor)};
		border-radius: ${({ $newStyle }) => ($newStyle ? '2px' : 0)};
	}

	&::-moz-range-thumb {
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background: ${({ theme }) => theme.primaryColor};
		border: none;
		cursor: pointer;
	}

	&::-moz-range-progress {
		height: ${({ $newStyle }) => ($newStyle ? '4px' : '2px')};
		border-radius: ${({ $newStyle }) => ($newStyle ? '2px' : 0)};
		background-color: ${({ $sliderMode, theme }) =>
			$sliderMode === 'from' ? theme.darkGrayColor : theme.primaryColor};
	}

	&::-ms-track {
		width: 100%;
		height: ${({ $newStyle }) => ($newStyle ? '4px' : '2px')};
		cursor: pointer;
		background: transparent;
		border-color: transparent;
		color: transparent;
		border-radius: ${({ $newStyle }) => ($newStyle ? '2px' : 0)};
	}

	&::-ms-fill-lower {
		background: ${({ $sliderMode, theme }) => ($sliderMode === 'from' ? theme.primaryColor : theme.darkGrayColor)};
		border: none;
		box-shadow: none;
	}

	&::-ms-fill-upper {
		background: ${({ $sliderMode, theme }) => ($sliderMode === 'from' ? theme.darkGrayColor : theme.primaryColor)};
		border: none;
		box-shadow: none;
	}

	&::-ms-thumb {
		box-shadow: none;
		border: none;
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background: ${({ theme }) => theme.primaryColor};
		cursor: pointer;
	}
`;

export const RangeSlider = styled.input.attrs((props) => ({ type: 'range', 'data-qa': props.dataQa }))`
	margin: 0;
	width: ${({ $newStyle }) => ($newStyle ? 'calc(100% - 32px)' : '100%')};
	height: 10px;
	position: absolute;
	bottom: -5px;
	left: ${({ $newStyle }) => ($newStyle ? '16px' : 0)};
	z-index: 4;
	background: transparent;
	-webkit-appearance: none;

	&::-webkit-slider-runnable-track {
		width: 10px;
		height: 2px;
		cursor: pointer;
		background: transparent;
		-webkit-appearance: none;
	}

	&::-webkit-slider-thumb {
		margin-top: 1px;
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background: ${({ theme }) => theme.primaryColor};
		cursor: pointer;
		transform: translateY(-50%);
		transition: height 0.2s, width 0.2s;
		-webkit-appearance: none;
	}

	&::-moz-range-track {
		width: 100%;
		height: 2px;
		box-shadow: none;
		border: none;
		background: transparent;
	}

	&::-moz-range-thumb {
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background: ${({ theme }) => theme.primaryColor};
		border: none;
		transition: height 0.2s, width 0.2s;
		cursor: pointer;
	}

	&::-moz-range-progress {
		background-color: transparent;
	}

	&::-ms-track {
		width: 100%;
		height: 2px;
		cursor: pointer;
		background: transparent;
		border-color: transparent;
		color: transparent;
	}

	&::-ms-fill-lower {
		background: ${({ theme }) => theme.primaryColor};
		border: none;
		box-shadow: none;
	}

	&::-ms-fill-upper {
		background: transparent;
		border: none;
		box-shadow: none;
	}

	&::-ms-thumb {
		box-shadow: none;
		border: none;
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background: ${({ theme }) => theme.primaryColor};
		cursor: pointer;
	}

	&:hover {
		&::-webkit-slider-thumb,
		&::-moz-range-thumb,
		&::-ms-thumb {
			height: 16px;
			width: 16px;
		}
	}

	&:focus {
		outline: none;
	}
`;
