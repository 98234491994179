import styled from 'styled-components';
import Link from '../Link';

export const Root = styled(Link)`
	background-color: ${({ theme, $color }) => theme[$color]};
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-decoration: none;

	@media (max-width: 340px) {
		padding: 9px 12px;
	}
`;
