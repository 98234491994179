// 1 - price_changed
// 2 - status_inactive
// 3 - status_available - квартира доступна после снятия с продажи
// 4 - status_reserved
// 5 - status_available - квартира доступна после резервирования
// TODO: Добавить описание типов 6 7 8
// 9 - Доступны новые квартиры в подборе (Lira 3). Еще нет макетов того куда ведет переход по ссылке
export const ALLOWED_EVENT_TYPES = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const FEED_WIDTH = 420;
