import type { TDispatch } from '../types/core';
import type { TLiraLocationItem } from '../types/lira3';
import { FAIL, GET_SELECTION_MAP_DATA, GET_SIDE_BAR_DATA, SET_BOUNDS, START, SUCCESS } from '../constants';
import { addJavaHeaders } from '../utils';
import { calcBounds, calcMinMax } from '../utils/mapUtils';
import type { TMapData } from '../types/selectionMap';
import type { TApartment } from '../reducers/apartment';

export const setMapData = (data, bounds) => ({
	type: GET_SELECTION_MAP_DATA + SUCCESS,
	payload: { data, bounds },
});

export const setSidebarData = (data) => ({
	type: GET_SIDE_BAR_DATA + SUCCESS,
	payload: { data },
});

export const errMapData = () => ({ type: GET_SELECTION_MAP_DATA + FAIL });

export const getBounds = (markers: TMapData[] = [], coords: number[]) => ({
	type: SET_BOUNDS,
	payload: calcBounds(calcMinMax(markers, coords), 0),
});

export const modifyAparts = (data) =>
	data.map((item: TMapData) => {
		const apartIds = item.apartments.map((a: TApartment) => a.id);
		return { ...item, apartIds };
	});

export const getApartData =
	(isLoading: boolean = true, blockScreen: boolean = false) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): TLiraLocationItem[] => {
		const {
			city: { cityIds = [], coords },
			token: { token = '' } = {},
			filterGlobal: { selectionType = 'MAIN', selectionParams = '' },
			selection: { sampling },
		} = getState();

		const fetchUrl = '/lexsApi/selection/swipe/variant/map/grouped';

		dispatch({ type: GET_SELECTION_MAP_DATA + START, payload: { isLoading, blockScreen } });

		const fetchOptions = {
			method: 'POST',
			...addJavaHeaders(token),
			body: JSON.stringify({
				selectionType,
				selectionParams,
				cityIds,
				sampling,
			}),
		};

		fetch(fetchUrl, fetchOptions)
			.then((data) => data.json())
			.then(({ data }) => {
				dispatch(setMapData(modifyAparts(data), calcBounds(calcMinMax(data, coords), 0)));
			})
			.catch(() => {
				dispatch(errMapData());
			});

		return null;
	};

export const getSideBarAparts =
	(apartIds: string[]) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): TLiraLocationItem[] => {
		const {
			token: { token = '' } = {},
			filterGlobal: { selectionType = 'MAIN', selectionParams = '' },
			sidebarAparts: { sidebarSort = 'RELEVANCE_DESC' },
		} = getState();

		const fetchUrl = '/lexsApi/selection/swipe/variant/map';

		const fetchOptions = {
			method: 'POST',
			...addJavaHeaders(token),
			body: JSON.stringify({
				selectionType,
				selectionParams,
				sort: sidebarSort,
				apartmentIds: apartIds,
			}),
		};

		if (apartIds?.length > 0) {
			dispatch({ type: GET_SIDE_BAR_DATA + START });

			fetch(fetchUrl, fetchOptions)
				.then((data) => data.json())
				.then(({ data }) => {
					const customData = Object.values(data).reduce((a, b) => [...a, ...b], []);
					dispatch(setSidebarData(customData));
				});
		}

		return null;
	};
