/* @flow */
import React, { type Node, useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { ContextGroup, DropdownIcon, DropdownLink } from '../../MobileHeader/styles';
import { compareIcon, dislikeOptionIcon, heart2023Icon, heart2023IconFill, share2023Icon } from '../../../../Icons';
import ShareBtn from '../../../../ShareBtn';
import Button from '../../../../Button2.0';
import { setApartCurrentModal, setDislikeMenuOpen } from '../../../../../actions/apartment';
import type { TRootState } from '../../../../../types/rootState';
import { changeApartOpinionCommon } from '../../../../../utils/changeApartOpinion';
import { AppContext } from '../../../../AppContext';
import type { TDispatch } from '../../../../../types/core';
import type { TComparisonState } from '../../../../../types/compare';
import { compareToggleAction } from '../../../../../actions/compare';
import type { TApartData } from '../../../../../types/apart';

type TProps = {
	isApartResale: boolean,
	currentApartData: TApartData,
	apartTitle: string,
	isLiked: boolean,
	isDisliked: boolean,
};

const Apart = ({ isApartResale, currentApartData, apartTitle, isDisliked, isLiked }: TProps): Node => {
	const store = useStore();
	const context = useContext(AppContext);
	const dispatch: TDispatch = useDispatch();
	const compare: TComparisonState = useSelector((state: TRootState) => state.compare);

	const activeCompareItem = compare?.items.find((item) => item?.apartment?.id === currentApartData?.id);

	const changeApartOpinion = async (opinion) => {
		await changeApartOpinionCommon(opinion, currentApartData?.id)(store, context, dispatch);

		if (opinion === ('LIKE' || 'RESET_REACTION')) {
			dispatch(setDislikeMenuOpen(true));
		}
	};

	const handleClick = async (ev) => {
		ev.preventDefault();
		await compareToggleAction(currentApartData?.id, activeCompareItem)(dispatch, store.getState);
	};

	const shareData = {
		title: apartTitle,
		text: 'Посмотри какая квартира на Living.ru!',
		url: 'http://localhost:3000/spb/apart/r:402083/',
		// url: getUrl(),
	};

	const customShareComponent = (handleSharing) => (
		<DropdownLink as="button" onClick={handleSharing}>
			Поделиться<DropdownIcon viewBox="0 0 24 24">{share2023Icon}</DropdownIcon>
		</DropdownLink>
	);

	return (
		<>
			<ContextGroup>
				<DropdownLink as="button" onClick={() => changeApartOpinion(isLiked ? 'RESET_REACTION' : 'LIKE')}>
					{isLiked ? 'В избранном' : 'В избранное'}
					<DropdownIcon viewBox="-2 0 24 20">{isLiked ? heart2023IconFill : heart2023Icon}</DropdownIcon>
				</DropdownLink>
				<DropdownLink as="button" onClick={() => changeApartOpinion(isDisliked ? 'RESET_REACTION' : 'DISLIKE')}>
					{isDisliked ? 'Не подошла' : 'Не подходит'}
					<DropdownIcon viewBox="4 4 36 36" isActive={isDisliked}>
						{dislikeOptionIcon}
					</DropdownIcon>
				</DropdownLink>
				<ShareBtn mobileContextMenuType renderComponent={customShareComponent} shareData={shareData} />
				<DropdownLink as="button" onClick={handleClick}>
					{activeCompareItem ? 'В сравнении' : 'Сравнить'}
					<DropdownIcon viewBox="0 3 22 17" isActive={activeCompareItem}>
						{compareIcon}
					</DropdownIcon>
				</DropdownLink>
			</ContextGroup>
			<Button
				design="rounded"
				size="xs"
				style={{ margin: '0 12px' }}
				onClick={() =>
					dispatch(setApartCurrentModal(isApartResale ? 'outerCallbackAdvert' : 'outerCallbackDeveloper'))
				}
			>
				Связаться с {isApartResale ? 'продавцом' : 'застройщиком'}
			</Button>
		</>
	);
};

export default Apart;
