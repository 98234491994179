/* @flow */
import type { Node } from 'react';
import React from 'react';
import { LoaderWrapper } from './styles';
import { ArrowLoader, CheckLoader, SearchLoader } from '../CircleLoaderWithIcon';

export const TYPE_COMPLEX_VIEW = 1;
export const TYPE_COMPLEX_MORTGAGE = 2;
export const TYPE_APARTMENT_VIEW = 3;
export const TYPE_APARTMENT_QUEUE = 4;
export const TYPE_APARTMENT_RESERVE = 5;
export const TYPE_APARTMENT_MORTGAGE = 6;
export const TYPE_APARTMENT_RESERVE_TRY = 7;

export const CHECK_APART_AVAILABILITY = 'Проверка наличия квартиры';
export const FORM_RESERVATION_AND_SEND = 'Формируем бронирование';
export const REQUEST_APART_AVAILABILITY =
	'Запрашиваем доступность квартиры в данный момент, потребуется немного времени';
export const SENDING_RESERVATION_DEVELOPER = 'Отправка бронирования застройщику';
export const SENDING_RESERVATION = 'Отправка бронирования';
export const TAKE_BOOKING_QUEUE = 'Занимаем очередь на бронирование';
export const SENDING_MORTGAGE = 'Отправка заявки на ипотеку';
export const SEND_MORTGAGE = 'Формируем и отправляем заявку на ипотеку';

export const times = [
	{ value: '09:00 - 12:00', label: 'с 9 до 12', startTime: 9, endTime: 12 },
	{ value: '12:00 - 17:00', label: 'с 12 до 17', startTime: 12, endTime: 17 },
	{ value: '17:00 - 20:00', label: 'с 17 до 20', startTime: 17, endTime: 20 },
];

/**
 * Получение новой даты со смещением относительно текущей
 * @param {number} offset - смещение в днях относительно текущей даты
 * @returns {Date}
 */
export const getNewDate = (offset: number = 0): Date => {
	const date = new Date();
	date.setDate(date.getDate() + offset);
	return date;
};

/**
 * Генераторо массива объектов дат для формы записи на просмотр/бронирование
 * @param {number} days - максимальное количество генерируемых объектов
 * @returns {Generator<{label: string, value: Date}, void, *>}
 */
export function* genDates(days: number = 7) {
	let curDay = 0;
	while (curDay < days) {
		if (curDay === 0) {
			yield { value: getNewDate(curDay), label: 'Сегодня' };
		} else if (curDay === 1) {
			yield { value: getNewDate(curDay), label: 'Завтра' };
		} else {
			yield {
				value: getNewDate(curDay),
				label: getNewDate(curDay, 'short').toLocaleString('ru', {
					weekday: 'long',
					month: 'long',
					day: 'numeric',
				}),
			};
		}
		curDay += 1;
	}
}

export const arrowLoader: Node = (
	<LoaderWrapper>
		<ArrowLoader progress />
	</LoaderWrapper>
);

export const searchLoader: Node = (
	<LoaderWrapper>
		<SearchLoader progress />
	</LoaderWrapper>
);

export const checkLoader: Node = (
	<LoaderWrapper>
		<CheckLoader />
	</LoaderWrapper>
);
