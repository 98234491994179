/* @flow */

import { TWishesTexts } from './lexs';
import type { TAdvertTag } from './lira3';

export type TBound = Array<number>;
export type TBounds = Array<TBound>;
export type TCoordinates = { latitude: number | null, longitude: number | null };

export type TApartment = {
	id: string,
	new: boolean,
	relevancePercent: number,
	type: string,
};

export type TMapData = {
	apartments: Array<TApartment>,
	apartIds: Array<string>,
	isNew: Array<boolean>,
	latitude: number,
	longitude: number,
	bestRankPlace: number,
	bestPriceTotalMin: number,
	bestMonthlyPayment: number,
	types: Array<string>,
};

export type TNotice = {
	key: string,
	title: string,
	priority: number,
};

export type TComplex = {
	addressText: string,
	area: {},
	cityId: number,
	developer: string | null,
	id: string,
	images: [],
	isComplex: boolean,
	isNew: boolean,
	metro: [],
	name: string | null,
	panorama: any,
	photo: any,
	ratingComplex: number,
	slug: any,
	url: string | null,
};

export type TApartCardType = 'NOT_VIEWED' | 'LIKED' | 'DISLIKED';

export type TApartSimilarItems = {
	id: string,
	floor: number,
};

export type TApartInfo = {
	id: string,
	isNew: boolean,
	isApartHotel: boolean,
	active: boolean,
	roomsCnt: number,
	number: string,
	floor: number,
	buildingNumber: number,
	areaTotal: number,
	areaKitchen: number,
	discountSum: number,
	priceDiscount: number,
	priceTotalMin: number,
	pricePerMeter: number,
	isReserved: boolean,
	status: number,
	deadline: number,
	rating: number,
	sectionStoreysCnt: number,
	layout: string,
	images: string[],
	finishing: string,
	bathroom: string,
	balcony: string,
	latitude: number,
	longitude: number,
	complex: TComplex,
	swipeTemestamp: number,
	relevancePercent: number,
	sources: string[],
	wishesTotal: number,
	wishesMatched: number,
	wishesTexts: TWishesTexts,
	availableNoticeList: {
		Квартира: Array<TNotice>,
		Расположение: Array<TNotice>,
	},
	type: TApartCardType,
	rankPlace: number,
	similarApartments: TApartSimilarItems[],
	assignment: boolean,
	mortgageInfo: {
		monthlyPayment: number,
		eligible: boolean,
	},
	description: string,
	tagList: TAdvertTag[],
	suggestionDate?: string,
	suggestionType?: 'MAIN' | 'PRICE' | 'LOCATION' | 'DEADLINE' | 'ROOMS_COUNT',
};
