export const GET_SERVICE_DATA = 'GET_SERVICE_DATA';
export const GET_SERVICE_DETAIL = 'GET_SERVICE_DETAIL';
export const GET_SERVICE_ORDERS = 'GET_SERVICE_ORDERS';
export const SET_SERVICE_ORDER_DETAILS = 'SET_SERVICE_ORDER_DETAILS';
export const SET_SERVICE_ORDER_FROM_HISTORY = 'SET_SERVICE_ORDER_FROM_HISTORY';
export const GET_SERVICE_ORDER_PAYMENT_STATUS = 'GET_SERVICE_ORDER_PAYMENT_STATUS';
export const ADD_SERVICE_ORDER = 'ADD_SERVICE_ORDER';
export const CHANGE_SERVICE_ORDER = 'CHANGE_SERVICE_ORDER';
export const ADD_SERVICE_ORDER_PAYMENT = 'ADD_SERVICE_ORDER_PAYMENT';
export const CHANGE_SERVICE_ORDER_PAYMENT = 'CHANGE_SERVICE_ORDER_PAYMENT';
export const CLEAR_SERVICE_ORDER = 'CLEAR_SERVICE_ORDER';
