/* @flow */

import type { TLiraStep } from '../types/lira4';
import {
	FAIL,
	FETCH_LIRA_SELECTION_DATA,
	FETCH_LIRA_SELECTION_MAP_DATA,
	GET_SIDE_BAR_DATA,
	LIRA_CHANGE_USER_STATUS,
	LIRA_CHANGE_USER_SUBSCRIBE,
	LIRA_FILTER_RESET,
	LIRA_GET_WISH_GROUP,
	LIRA_SET_USER_PORTRAIT,
	NEW_LIRA_CHANGE_USER_PROFILE,
	NEW_LIRA_CREATE_USER_PROFILE,
	NEW_LIRA_GET_USER_PROFILE,
	SET_LIRA_FIRST_ENTRY_STATUS,
	SET_LIRA_STEP,
	SET_LIRA_VIEW,
	START,
	SUCCESS,
} from '../constants';
import type { TDispatch } from '../types/core';
import { addJavaHeaders } from '../utils';
import { separateLiraFilters } from '../utils/filters';
import { calcBounds, calcMinMax } from '../utils/mapUtils';
import type { TLiraLocationItem } from '../types/lira3';
import { setSidebarData } from './selectionMap';

export const goToLiraStep = (step: TLiraStep) => ({
	type: SET_LIRA_STEP,
	payload: step,
});

export const setLiraUserPortrait = (id: number) => ({
	type: LIRA_SET_USER_PORTRAIT,
	payload: id,
});

export const handleLiraReset = () => ({
	type: LIRA_FILTER_RESET,
});

export const setLiraView = (desktop: 'list' | 'map', mobile: 'grid' | 'map') => async (dispatch: TDispatch) => {
	const payload = {};

	if (desktop && localStorage.getItem('liraViewDesktop') !== desktop) {
		localStorage.setItem('liraViewDesktop', desktop);
		payload.desktop = desktop;
	}
	if (mobile && localStorage.getItem('liraViewMobile') !== mobile) {
		localStorage.setItem('liraViewMobile', mobile);
		payload.mobile = mobile;
	}

	if (Object.keys(payload).length > 0) {
		dispatch({
			type: SET_LIRA_VIEW,
			payload,
		});
	}
};

export const handleStatisticsChange = (stats, { opinion, typeMark }) => {
	switch (opinion) {
		case 'LIKE':
			return {
				...stats,
				countNoFeedback: stats.countNoFeedback - 1,
				countLiked: stats.countLiked + 1,
			};
		case 'DISLIKE':
			return {
				...stats,
				countNoFeedback: stats.countNoFeedback - 1,
				countDisliked: stats.countDisliked + 1,
			};

		case 'RESET_REACTION':
			if (typeMark === 'LIKE') {
				return {
					...stats,
					countNoFeedback: stats.countNoFeedback + 1,
					countLiked: Math.max(stats.countLiked - 1, 0),
				};
			}
			if (typeMark === 'DISLIKE') {
				return {
					...stats,
					countNoFeedback: stats.countNoFeedback + 1,
					countDisliked: Math.max(stats.countDisliked - 1, 0),
				};
			}
			return stats;
		default:
			return stats;
	}
};

export const setMapData = (data, bounds) => ({
	type: FETCH_LIRA_SELECTION_MAP_DATA + SUCCESS,
	payload: { data, bounds },
});

export const getLiraSelectionWishes =
	() =>
	async (dispatch: TDispatch, getState: () => any, { fetch }) => {
		const { token: { token = '' } = {} } = getState();
		const url = '/lexsApi/selection/wish-group';

		dispatch({ type: LIRA_GET_WISH_GROUP + START });

		try {
			const { result, data, error } = await fetch(url, {
				method: 'GET',
				...addJavaHeaders(token),
			}).then((res) => res.json());
			if (result === 'ok') {
				dispatch({ type: LIRA_GET_WISH_GROUP + SUCCESS, payload: data });
				return data;
			}
			return null;
		} catch (error) {
			return null;
		}
	};

export const fetchTelegramData = async (token: string, fetch, dispatch) => {
	try {
		const url = '/passApi/data/telegram';

		const { result, data } = await fetch(url, {
			method: 'GET',
			...addJavaHeaders(token),
		}).then((res) => res.json());

		if (result === 'ok') {
			if (data && data.status === 'bound') {
				dispatch({
					type: LIRA_CHANGE_USER_SUBSCRIBE,
					payload: { telegram: true },
				});
			}

			return data;
		}
		return null;
	} catch (error) {
		return null;
	}
};

export const createLiraUserProfile =
	() =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const { token: { token = '' } = {}, city, lira } = getState();
		const { cityIds = [78, 47] } = city ?? {};
		const {
			filter,
			user: { portraitId, userWishes, firstEntry },
		} = lira;
		const url = '/lexsApi/selection/lira/profile';

		dispatch({ type: NEW_LIRA_CREATE_USER_PROFILE + START });

		try {
			const body = JSON.stringify({
				filters: { ...filter, cityIds },
				portraitId,
				wishGroups: userWishes,
			});

			const { result, data, error } = await fetch(url, {
				method: 'POST',
				...addJavaHeaders(token),
				body,
			}).then((res) => res.json());

			if (result === 'ok') {
				dispatch({ type: NEW_LIRA_CREATE_USER_PROFILE + SUCCESS, payload: separateLiraFilters(data) });

				if (firstEntry) {
					dispatch({ type: SET_LIRA_FIRST_ENTRY_STATUS, payload: false });
				}

				return data;
			}
			return null;
		} catch (error) {
			return null;
		}
	};

export const getLiraUserProfile =
	(userToken: string | null = null) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const { token: { token = '' } = {} } = getState();
		const url = '/lexsApi/selection/lira/profile';

		dispatch({ type: NEW_LIRA_GET_USER_PROFILE + START });

		try {
			const { result, data, error } = await fetch(url, {
				method: 'GET',
				...addJavaHeaders(userToken ?? token),
			}).then((res) => res.json());
			if (result === 'ok') {
				dispatch({
					type: NEW_LIRA_GET_USER_PROFILE + SUCCESS,
					payload: separateLiraFilters(data),
				});
				return data;
			}
			return null;
		} catch (error) {
			return null;
		}
	};

export const checkLiraUserProfileExist =
	(userToken: string | null = null) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		try {
			const profile = await getLiraUserProfile(userToken)(dispatch, getState, { fetch });
			if (profile) {
				dispatch({
					type: SET_LIRA_FIRST_ENTRY_STATUS,
					payload: false,
				});
				await fetchTelegramData(userToken, fetch, dispatch);
			}
			return null;
		} catch (error) {
			return null;
		}
	};

export const changeLiraUserProfile =
	(updatedFields) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): any => {
		const {
			token: { token = '' } = {},
			city: { cityIds = [] } = {},
			lira: { filter, user: { portraitId, userWishes, status, firstEntry } } = {},
		} = getState();
		const url = '/lexsApi/selection/lira/profile';

		const body = JSON.stringify({
			filters: { ...filter, cityIds },
			portraitId,
			wishGroups: userWishes,
			status,
			...updatedFields,
		});

		const fetchOptions = {
			method: 'PUT',
			...addJavaHeaders(token),
			body,
		};

		dispatch({
			type: NEW_LIRA_CHANGE_USER_PROFILE + START,
		});

		try {
			const { result, data } = await fetch(url, fetchOptions).then((res) => {
				if (res.ok) {
					return res.json();
				}
				throw new Error('Error occurred!');
			});

			if (result === 'ok') {
				dispatch({
					type: NEW_LIRA_CHANGE_USER_PROFILE + SUCCESS,
					payload: separateLiraFilters(data),
				});

				if (firstEntry) {
					dispatch({ type: SET_LIRA_FIRST_ENTRY_STATUS, payload: false });
				}

				return { ok: true, data };
			}

			dispatch({
				type: NEW_LIRA_CHANGE_USER_PROFILE + FAIL,
			});

			return { ok: false };
		} catch (e) {
			dispatch({
				type: NEW_LIRA_CHANGE_USER_PROFILE + FAIL,
			});
			return { ok: false, message: e };
		}
	};

export const getLiraSelectionData =
	(pageNumber = null, liraSortResults = 'SUGGESTION_DATE_ASC', pageSize = 12) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }) => {
		const {
			token: { token = '' } = {},
			lira: {
				selection: { sort, page, includeSold },
			},
		} = getState();

		const loadingSort = sort ?? liraSortResults;
		const loadedPage = pageNumber ?? page;

		const fetchUrl = `/lexsApi/selection/lira/variant?sort=${loadingSort}&includeSold=${includeSold}&page=${loadedPage}&size=${pageSize}`;

		dispatch({ type: FETCH_LIRA_SELECTION_DATA + START });

		const fetchOptions = {
			method: 'GET',
			...addJavaHeaders(token),
		};

		fetch(fetchUrl, fetchOptions)
			.then((data) => data.json())
			.then(({ data }) => {
				dispatch({ type: FETCH_LIRA_SELECTION_DATA + SUCCESS, payload: data });
				return data;
			})
			.catch((err) => {
				dispatch({ type: FETCH_LIRA_SELECTION_DATA + FAIL });
				return err;
			});
	};

export const getLiraSelectionMapData =
	(isLoading: boolean = true, blockScreen: boolean = false) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }) => {
		const {
			city: { coords },
			token: { token = '' } = {},
			lira: {
				selection: { includeSold },
			},
		} = getState();

		const fetchUrl = `/lexsApi/selection/lira/variant/map/grouped?includeSold=${includeSold}`;

		dispatch({ type: FETCH_LIRA_SELECTION_MAP_DATA + START, payload: { isLoading, blockScreen } });

		const fetchOptions = {
			method: 'GET',
			...addJavaHeaders(token),
		};

		fetch(fetchUrl, fetchOptions)
			.then((data) => data.json())
			.then(({ data }) => {
				if (data?.buildings) {
					dispatch(setMapData(data.buildings, calcBounds(calcMinMax(data.buildings, coords), 0)));
				}
				return data;
			})
			.catch((err) => {
				dispatch({ type: FETCH_LIRA_SELECTION_MAP_DATA + FAIL });
				return err;
			});
	};

export const getSideBarLiraAparts =
	(apartIds: string[]) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }): TLiraLocationItem[] => {
		const { token: { token = '' } = {} } = getState();

		const params = new URLSearchParams();

		const fetchOptions = {
			method: 'GET',
			...addJavaHeaders(token),
		};

		if (apartIds?.length > 0) {
			dispatch({ type: GET_SIDE_BAR_DATA + START });

			apartIds.forEach((item) => params.append('apartmentIds', item));
			const fetchUrl = `/lexsApi/selection/lira/variant/map?${params.toString()}`;

			fetch(fetchUrl, fetchOptions)
				.then((data) => data.json())
				.then(({ data }) => {
					dispatch(setSidebarData(data));
				});
		}

		return null;
	};

export const changeLiraSelectionStatus =
	(isActive: boolean) =>
	async (dispatch: TDispatch, getState: () => any, { fetch }) => {
		const newStatus = isActive ? 'STOP' : 'ACTIVE';

		const { token: { token = '' } = {} } = getState();
		const url = '/lexsApi/selection/lira/profile/status';

		const body = JSON.stringify({
			status: newStatus,
		});

		const fetchOptions = {
			method: 'PUT',
			...addJavaHeaders(token),
			body,
		};

		dispatch({
			type: LIRA_CHANGE_USER_STATUS + START,
		});

		try {
			const { result } = await fetch(url, fetchOptions).then((res) => {
				if (res.ok) {
					return res.json();
				}
				throw new Error('Error occurred!');
			});

			if (result === 'ok') {
				dispatch({
					type: LIRA_CHANGE_USER_STATUS + SUCCESS,
					payload: newStatus,
				});
			} else {
				dispatch({
					type: LIRA_CHANGE_USER_STATUS + FAIL,
				});
			}
		} catch (e) {
			dispatch({
				type: LIRA_CHANGE_USER_STATUS + FAIL,
			});
		}
	};
