/* @flow */
import {
	ADD_SERVICE_ORDER,
	ADD_SERVICE_ORDER_PAYMENT,
	AUTH_CLEAR_STORE,
	CHANGE_PROFILE,
	CHANGE_SERVICE_ORDER,
	CHANGE_SERVICE_ORDER_PAYMENT,
	FAIL,
	GET_LOYALTY_PROGRAM,
	GET_SERVICE_ORDERS,
	LIRA_CHANGE_FILTERS_FIELD,
	LIRA_CREATE_USER_PROFILE,
	LIRA_GET_USER_PROFILE,
	LOAD_PROFILE,
	PREPARE_CHANGE_CITY,
	SAVE_PROFILE,
	SET_FILTER_SCREEN_PASSED,
	SET_GLOBAL_SELECTION_MODE,
	SET_REFERRAL_ID,
	SET_SELECTION_VIEW,
	SET_START_SCREEN_PASSED,
	START,
	SUCCESS,
} from '../constants';
import type { TAction } from '../types/core';
import type { TProfile } from '../types/account';

let selectionViewDesktop = 'list';
let selectionViewMobile = 'grid';

if (typeof localStorage !== 'undefined') {
	if (localStorage.getItem('selectionViewDesktop')) {
		selectionViewDesktop = localStorage.getItem('selectionViewDesktop');
	}
	if (localStorage.getItem('selectionViewMobile')) {
		selectionViewMobile = localStorage.getItem('selectionViewMobile');
	}
}

const initialState: TProfile = {
	id: -1,
	cityId: null,
	city: {
		id: null,
		slug: null,
		name: null,
	},
	email: '',
	phone: '',
	phoneVerified: false,
	status: 30,
	name: '',
	registrationType: '',
	googleClientId: '',
	yandexClientId: '',
	hash: '',
	referralId: '',
	isAgree: true,
	isLoading: false,
	isLoaded: false,
	errors: null,
	firstVisit: false,
	splashScreens: {
		mainShown: false,
		apartShown: false,
	},
	forceSteps: false,
	selectionView: {
		desktop: selectionViewDesktop,
		mobile: selectionViewMobile,
	},
	selectionChanged: false,
	orders: {
		data: [],
		isLoading: false,
		isLoaded: false,
		needUpdate: false,
	},
	loyaltyProgram: {
		data: null,
		isLoading: false,
		isLoaded: false,
	},
	templateId: 1,
};

export default function profile(state: TProfile = initialState, action: TAction): TProfile {
	const { type, payload } = action;

	switch (type) {
		case CHANGE_PROFILE:
			return { ...state, ...payload, orders: { ...state.orders, needUpdate: true } };

		case LOAD_PROFILE + SUCCESS:
			return {
				...state,
				...payload,
				referralId: payload?.referralId ? payload.referralId : state.referralId,
				isLoaded: true,
			};

		case SAVE_PROFILE + START:
			return { ...state, isLoading: true, errors: null };

		case SAVE_PROFILE + SUCCESS:
			return { ...state, isLoading: false, errors: null };

		case SAVE_PROFILE + FAIL:
			return {
				...state,
				isLoading: false,
				errors: action?.payload ?? 'Что-то пошло не так...',
			};

		case SET_REFERRAL_ID:
			return { ...state, referralId: action.payload };

		case AUTH_CLEAR_STORE:
			return { ...initialState };

		case LIRA_CREATE_USER_PROFILE + SUCCESS: {
			const { splashScreens, templateId } = action.payload ?? {};
			return { ...state, splashScreens, templateId };
		}

		case LIRA_GET_USER_PROFILE + SUCCESS: {
			const { splashScreens, templateId } = action.payload?.data ?? {};
			return { ...state, splashScreens, templateId };
		}

		case LIRA_CHANGE_FILTERS_FIELD: {
			return { ...state, selectionChanged: action.payload };
		}

		case PREPARE_CHANGE_CITY: {
			return { ...state, forceSteps: true };
		}

		case GET_SERVICE_ORDERS + START: {
			return { ...state, orders: { ...state.orders, isLoaded: false, isLoading: true } };
		}

		case GET_SERVICE_ORDERS + SUCCESS: {
			return { ...state, orders: { data: action.payload, isLoaded: true, isLoading: false, needUpdate: false } };
		}

		case GET_SERVICE_ORDERS + FAIL: {
			return { ...state, orders: { ...state.orders, isLoaded: false, isLoading: false } };
		}

		case ADD_SERVICE_ORDER + SUCCESS:
		case ADD_SERVICE_ORDER_PAYMENT + SUCCESS:
		case CHANGE_SERVICE_ORDER + SUCCESS:
		case CHANGE_SERVICE_ORDER_PAYMENT + SUCCESS: {
			return { ...state, orders: { ...state.orders, needUpdate: true } };
		}

		case SET_START_SCREEN_PASSED: {
			return { ...state, splashScreens: { ...state.splashScreens, mainShown: true } };
		}

		case SET_FILTER_SCREEN_PASSED: {
			return { ...state, splashScreens: { ...state.splashScreens, mainShown: true, apartShown: true } };
		}

		case GET_LOYALTY_PROGRAM + START: {
			return { ...state, loyaltyProgram: { ...state.loyaltyProgram, isLoaded: false, isLoading: true } };
		}

		case GET_LOYALTY_PROGRAM + SUCCESS: {
			return { ...state, loyaltyProgram: { data: action.payload, isLoaded: true, isLoading: false } };
		}

		case GET_LOYALTY_PROGRAM + FAIL: {
			return { ...state, loyaltyProgram: { ...state.loyaltyProgram, isLoaded: false, isLoading: false } };
		}

		case SET_GLOBAL_SELECTION_MODE: {
			if (action.payload === 'normal') {
				return { ...state };
			}
			return state;
		}
		case SET_SELECTION_VIEW: {
			/* if (action.payload.isMobileView) {
				return { ...state, selectionView: { ...state.selectionView, mobile: action.payload.view } };
			} */
			return { ...state, selectionView: { ...state.selectionView, ...action.payload } };
		}

		default:
			return state;
	}
}

export { initialState as profileInitialState };
