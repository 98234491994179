/* @flow */
import React from 'react';
import { CountInline, LinkFlex, LinkIcon, LinkLoginIcon, NavMobileLink } from '../MobileHeader/styles';
import {
	compareIcon,
	dislikeOptionIcon,
	heart2023Icon,
	loginIcon,
	logoutIcon,
	pinMap2021Icon,
	profileUser,
} from '../../../Icons';
import type { TCity } from '../../../../types/city';
import { Divider } from '../../styles';

type TProps = {
	city: TCity,
	compareCount: number,
	favCount: number,
	isAnonUser: boolean,
	isAuthUser: boolean,
	isReverse?: boolean,
	withDivider?: boolean,
	onCityClick: () => {},
	openAuthPopup: () => {},
	logout: () => {},
};

const ProfileMenu = ({
	city,
	compareCount,
	favCount,
	isAnonUser,
	isAuthUser,
	isReverse,
	withDivider,
	onCityClick,
	openAuthPopup,
	logout,
}: TProps) => (
	<>
		{isAnonUser && (
			<NavMobileLink $isReverse={isReverse} as="button" onClick={openAuthPopup}>
				Войти
				<LinkLoginIcon viewBox="0 0 24 24">{loginIcon}</LinkLoginIcon>
			</NavMobileLink>
		)}
		{isAuthUser && (
			<NavMobileLink $isReverse={isReverse} to="/profile/">
				Профиль
				<LinkIcon viewBox="0 0 19 22">{profileUser}</LinkIcon>
			</NavMobileLink>
		)}
		<NavMobileLink $isReverse={isReverse} as="button" onClick={onCityClick}>
			{city.title}
			<LinkIcon viewBox="0 0 18 20">{pinMap2021Icon}</LinkIcon>
		</NavMobileLink>
		{withDivider && <Divider />}
		<NavMobileLink $isReverse={isReverse} to="/compare/">
			<LinkFlex>Сравнение{!!compareCount && <CountInline>{compareCount}</CountInline>}</LinkFlex>
			<LinkIcon viewBox="0 3 22 17">{compareIcon}</LinkIcon>
		</NavMobileLink>
		<NavMobileLink $isReverse={isReverse} to={`/${city.link}/selection/likes/`}>
			<LinkFlex>Избранное{!!favCount && <CountInline>{favCount}</CountInline>}</LinkFlex>
			<LinkIcon viewBox="-2 0 24 20">{heart2023Icon}</LinkIcon>
		</NavMobileLink>
		<NavMobileLink $isReverse={isReverse} to={`/${city.link}/selection/dislikes/`}>
			Не нравится
			<LinkIcon viewBox="4 4 36 36">{dislikeOptionIcon}</LinkIcon>
		</NavMobileLink>
		{isAuthUser && (
			<>
				{withDivider && <Divider />}
				<NavMobileLink $isReverse={isReverse} $isGray as="button" onClick={logout}>
					Выйти
					<LinkLoginIcon viewBox="0 0 24 24">{logoutIcon}</LinkLoginIcon>
				</NavMobileLink>
			</>
		)}
	</>
);

ProfileMenu.defaultProps = {
	isReverse: false,
	withDivider: false,
};

export default ProfileMenu;
