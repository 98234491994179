/* @flow */
import {
	AUTH_BLOCKED,
	AUTH_DECREMENT_CHANCE,
	AUTH_DECREMENT_COUNTER,
	AUTH_RESET,
	AUTH_SET,
	AUTH_SET_COUNTER,
	AUTH_SET_PROGRESS,
	AUTH_SET_REMAIN,
	AUTH_SET_STAGE,
	CHANGE_AUTH_TYPE,
	FAIL,
	LOGIN_FIRST_STAGE,
	LOGIN_SECOND_STAGE,
	LOGOUT,
	START,
	SUCCESS,
} from '../constants';
import type { TAction } from '../types/core';

export type TAuthState = {
	isLogout: boolean,
	stage: 'phone' | 'code' | 'telegram',
	fail: boolean,
	isLoading: boolean,
	time: number,
	counter: number,
	timerInProgress: boolean,
	remain: boolean,
	repeatChance: number,
	repeatBlocked: number | null,
};

const initialState: TAuthState = {
	isLogout: false,
	stage: 'telegram',
	fail: false,
	isLoading: false,
	time: 60,
	counter: 60,
	timerInProgress: false,
	remain: false,
	error: '',
	repeatChance: 3,
	repeatBlocked: null,
};

export default function auth(state: TAuthState = initialState, action: TAction): TAuthState {
	const { type } = action;

	switch (type) {
		case LOGOUT + START:
			return { ...state, isLogout: true };

		case LOGOUT + SUCCESS:
			return { ...state, isLogout: false, stage: initialState.stage };

		case LOGOUT + FAIL:
			return { ...state, isLogout: false };

		case LOGIN_FIRST_STAGE + START:
		case LOGIN_SECOND_STAGE + START:
			return { ...state, isLoading: true };

		case LOGIN_FIRST_STAGE + SUCCESS:
			return {
				...state,
				isLoading: false,
				fail: false,
				stage: 'code',
				timerInProgress: true,
			};

		case LOGIN_SECOND_STAGE + SUCCESS:
			return { ...initialState, isLoading: false, fail: false };

		case LOGIN_FIRST_STAGE + FAIL:
		case LOGIN_SECOND_STAGE + FAIL:
			return { ...state, fail: true, isLoading: false, error: action.error };

		case AUTH_SET:
			return { ...state, ...action.payload };

		case AUTH_SET_STAGE:
			return { ...state, stage: action.payload, ...(action?.act ? { fail: false } : {}) };

		case AUTH_SET_COUNTER:
			return { ...state, counter: action.payload };

		case AUTH_DECREMENT_COUNTER:
			return { ...state, counter: Math.max(state.counter - 1, 0) };

		case AUTH_SET_PROGRESS:
			return { ...state, timerInProgress: action.payload };

		case AUTH_SET_REMAIN:
			return { ...state, remain: action.payload };

		case AUTH_RESET: {
			const { counter, remain, timerInProgress } = state;
			return {
				...initialState,
				counter,
				remain,
				timerInProgress,
				stage: timerInProgress ? 'code' : 'phone',
			};
		}

		case AUTH_DECREMENT_CHANCE:
			return { ...state, repeatChance: action.payload };

		case AUTH_BLOCKED:
			return { ...state, repeatBlocked: action.payload };

		case CHANGE_AUTH_TYPE:
			return { ...state, stage: action.payload };

		default:
			return state;
	}
}

export { initialState as authInitialState };
