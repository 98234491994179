import { NEWS_FETCH_ARTICLES_DATA, NEWS_CLEAR, NEWS_SEARCH_ARTICLES_DATA } from '../constants/news';
import { FAIL, START, SUCCESS } from '../constants';

export type TArticle = {
	createdAt: string,
	id: number,
	previewDescription: string,
	previewPicture: ?string,
	slug: string,
	title: string,
	video: string,
};

export type TNews = {
	articles: TArticle[],
	currentPage: number,
	pageCount: number,
	isLoading: boolean,
	isLoaded: boolean,
};

const initialState: TNews = {
	articles: [],
	currentPage: 0,
	pageCount: 0,
	isLoading: false,
	isLoaded: false,
};

const reducer = (state: TNews = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case NEWS_FETCH_ARTICLES_DATA + START:
		case NEWS_SEARCH_ARTICLES_DATA + START:
			return { ...state, isLoading: true };

		case NEWS_FETCH_ARTICLES_DATA + SUCCESS: {
			const {
				items = [],
				meta: { pageCount, pageNumber },
			} = payload;
			return {
				...state,
				articles: [...state.articles, ...items],
				pageCount,
				currentPage: pageNumber,
				isLoading: false,
				isLoaded: true,
			};
		}

		case NEWS_SEARCH_ARTICLES_DATA + SUCCESS: {
			const {
				items = [],
				meta: { pageCount, pageNumber },
			} = payload;
			return {
				...state,
				articles: items,
				pageCount,
				currentPage: pageNumber,
				isLoading: false,
				isLoaded: true,
			};
		}

		case NEWS_FETCH_ARTICLES_DATA + FAIL:
		case NEWS_SEARCH_ARTICLES_DATA + FAIL:
			return { ...state, isLoading: false, isLoaded: false };

		case NEWS_CLEAR:
			return initialState;

		default:
			return state;
	}
};

export default reducer;
