// @flow
import fetch from 'unfetch';

type ResponseInterceptor = (response: Response) => Response;
type RequestInterceptor = (url: string, options: Request) => { url: string, options: Request };

export default class AppFetch {
	static #responseInterceptors: ResponseInterceptor[] = [];

	static #requestInterceptors: RequestInterceptor[] = [];

	static useRequestInterceptor(fn: RequestInterceptor) {
		AppFetch.#requestInterceptors.push(fn);
	}

	static useResponseInterceptor(fn: ResponseInterceptor) {
		AppFetch.#responseInterceptors.push(fn);
	}

	static async fetch(url: string, options: Request = {}): Promise<Response> {
		let currentUrl = String(url);
		let currentOptions: Request = {
			...options,
		};

		// Apply request interceptors
		AppFetch.#requestInterceptors.forEach((requestInterceptor) => {
			const { url: newUrl, options: newOptions } = requestInterceptor(currentUrl, currentOptions);
			currentUrl = newUrl;
			currentOptions = newOptions;
		});

		let currentResponse = await fetch(currentUrl, currentOptions);

		// Apply response interceptors
		AppFetch.#responseInterceptors.forEach((responseInterceptor) => {
			currentResponse = responseInterceptor(currentResponse);
		});

		return currentResponse;
	}
}
