/* @flow */
import { defaultSelectionWishesKeys } from './filters';
import type { TFilterWishes } from '../types/filters';

export const stringToValue = (str: string): any => {
	if (/^\d+$/.test(str)) return Number(str);
	if (/^true$/i.test(str)) return true;
	if (/^false$/i.test(str)) return false;
	if (/^null$/i.test(str)) return null;
	return str;
};

export const fromSearchParams = (search: string) =>
	Object.fromEntries(
		search
			.replace('?', '')
			.split('&')
			.map((item) => item.split('=').map((el, idx) => (idx > 0 ? stringToValue(el) : el))),
	);

export const reduceUndefined = (obj) =>
	Object.keys(obj).reduce((acc, curr) => {
		if (!obj[curr]) return acc;
		return { ...acc, [curr]: obj[curr] };
	}, {});

export const reduceToDefined = (obj: any, keys: string[]): any =>
	Object.keys(obj).reduce((acc, curr) => (keys.includes(curr) ? { ...acc, [curr]: obj[curr] } : acc), {});

export type TKeys = {
	key: string,
	type: 'array' | 'single',
};

export const baseFilterKeys: TKeys[] = {
	aparts: { key: 'roomsCntForFilter', type: 'array' },
	from: { key: 'priceTotalMinGte', type: 'single' },
	to: { key: 'priceTotalMinLte', type: 'single' },
	deadline: { key: 'deadlineLte', type: 'single' },
};

export const baseFilterKeysM: TKeys[] = {
	aparts: { key: 'roomsCntForFilter', type: 'array' },
	inf: { key: 'initialFee', type: 'single' },
	pay: { key: 'payment', type: 'single' },
	loan: { key: 'loanPeriod', type: 'single' },
	deadline: { key: 'deadlineLte', type: 'single' },
};

export const extFilterKeys: TKeys[] = {
	fin: { key: 'finishingType', type: 'single' },
	fg: { key: 'floorGte', type: 'single' },
	fl: { key: 'floorLte', type: 'single' },
	nff: { key: 'isNotFirstFloor', type: 'single' },
	nlf: { key: 'isNotLastFloor', type: 'single' },
	atg: { key: 'areaTotalGte', type: 'single' },
	atl: { key: 'areaTotalLte', type: 'single' },
	akg: { key: 'areaKitchenGte', type: 'single' },
	akl: { key: 'areaKitchenLte', type: 'single' },
	hab: { key: 'hasAnyBalcony', type: 'single' },
	hnb: { key: 'hasNoBalcony', type: 'single' },
	ah: { key: 'isApartHotel', type: 'single' },
};

export const encodeWishesSearchParam = (wishes: TFilterWishes, keys: string[] = defaultSelectionWishesKeys): string => {
	const encoded = keys.map((key: number) => (wishes[key] ? 1 : 0)).join('');
	return /1/g.test(encoded) ? `wishes=${encoded}` : '';
};

export const decodeWishesSearchParam = (wishes: string, keys: string[] = defaultSelectionWishesKeys): TFilterWishes => {
	if (wishes.length !== keys.length) return null;
	const values: number[] = wishes.split('').map((val) => Number(val));
	return keys.reduce((acc, key: string, idx: number) => ({ ...acc, [key]: values[idx] }), {}) |> reduceUndefined;
};

export const queryStringToArray = (str: string): number[] =>
	/^([0-4]_)+[0-4]$/g.test(str) ? str.split('_').map((item) => Number(item)) : [Number(str)];

export const arrayToQueryString = (arr: number[]): string => arr.join('_');

export const checkFilterQuery = (query, keys: TKeys[]): any =>
	Object.keys(keys).reduce((acc, curr: string) => {
		if (query[curr]) {
			return {
				...acc,
				[keys[curr].key]:
					keys[curr].type === 'single' ? stringToValue(query[curr]) : queryStringToArray(query[curr]),
			};
		}
		return acc;
	}, {});

export const buildFilterQuery = (keys: TKeys[], source): any =>
	Object.keys(keys)
		.reduce((acc: string[], curr: string) => {
			const srcKey = keys[curr].key;

			if (keys[curr].type === 'array') {
				if (Array.isArray(source[srcKey]) && source[srcKey].length) {
					return [...acc, `${curr}=${arrayToQueryString(source[srcKey])}`];
				}
				return acc;
			}
			if (curr === 'deadline' && Number.isInteger(source[srcKey]) && source[srcKey] < 300001) {
				return [...acc, `deadline=${source[srcKey]}`];
			}
			if (source[srcKey]) {
				return [...acc, `${curr}=${source[srcKey]}`];
			}
			return acc;
		}, [])
		.join('&');

export const reduceByMask = (query: number[], mask: number[]): number[] =>
	query.reduce((acc: number[], curr: number): number[] => (mask.includes(curr) ? [...acc, curr] : [...acc]), []);
