// TODO: move constants to separate files
export * from './app';
export * from './city';
export * from './complex';
export * from './comparison';
export * from './favourites';
export * from './filters';
export * from './lira';
export * from './lexs';
export * from './universalLexsFilterBase';
export * from './diagramData';
export * from './plannings';
export * from './dosier';
export * from './dosierDetail';
export * from './search';
export * from './selection';
export * from './selectionMap';
export * from './services';

export const START = '_START';
export const SUCCESS = '_SUCCESS';
export const FAIL = '_FAIL';
export const FINISH = '_FINISH';

export const SET_TOKEN = 'SET_TOKEN';
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const CHANGE_TOKEN = 'CHANGE_TOKEN';

export const LOAD_DASHBOARD = 'LOAD_DASHBOARD';

export const LOAD_CITIES = 'LOAD_CITIES';

export const SET_SELECTION_HISTORY = 'SET_SELECTION_HISTORY';

export const SET_REPORTS = 'SET_REPORTS';

export const CLEAR_STORE = 'CLEAR_STORE';

export const LOGOUT = 'LOGOUT';
export const LOGIN_FIRST_STAGE = 'LOGIN_FIRST_STAGE';
export const LOGIN_SECOND_STAGE = 'LOGIN_SECOND_STAGE';
export const AUTH_SET = 'AUTH_SET';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_SET_PERSONAL = 'AUTH_SET_PERSONAL';
export const AUTH_SET_PHONE_VALIDATION = 'AUTH_SET_PHONE_VALIDATION';
export const AUTH_SET_STAGE = 'AUTH_SET_STAGE';
export const AUTH_CLEAR_STORE = 'AUTH_CLEAR_STORE';
export const AUTH_SET_COUNTER = 'AUTH_SET_COUNTER';
export const AUTH_DECREMENT_COUNTER = 'AUTH_DECREMENT_COUNTER';
export const AUTH_SET_PROGRESS = 'AUTH_SET_PROGRESS';
export const AUTH_SET_REMAIN = 'AUTH_SET_REMAIN';
export const AUTH_DECREMENT_CHANCE = 'AUTH_DECREMENT_CHANCE';
export const AUTH_BLOCKED = 'AUTH_BLOCKED';

export const SAVE_PROFILE = 'SAVE_PROFILE';

export const LOAD_FEED = 'LOAD_FEED';
export const SET_FEED_READ = 'SET_FEED_READ';

export const SET_APARTMENT = 'SET_APARTMENT';
export const SET_APARTMENT_ON_MAP = 'SET_APARTMENT_ON_MAP';
export const SET_APARTMENT_INFRASTRUCTURE = 'SET_APARTMENT_INFRASTRUCTURE';
export const SET_NMARKET_URI = 'SET_NMARKET_URI';
export const SET_ADVERT = 'SET_ADVERT';
export const RESALE = '_RESALE';
export const SET_SIMILAR_APART_SORT = 'SET_SIMILAR_APART_SORT';
export const SET_APART_CURRENT_MODAL = 'SET_APART_CURRENT_MODAL';

export const SET_SCORING_QUESTIONS = 'SET_SCORING_QUESTIONS';
export const CLEAR_SCORING = 'CLEAR_SCORING';
export const HIDE_MILITARY_QUESTION = 'HIDE_MILITARY_QUESTION';
export const SHOW_MILITARY_QUESTION = 'SHOW_MILITARY_QUESTION';

// Disqus
export const DISQUS_SHORT_NAME = 'living-15';
// Questions
export const SET_QUESTIONS_RESULTS = 'SET_QUESTIONS_RESULTS';
export const SET_QUESTIONS_RESULTS_TEST = 'SET_QUESTIONS_RESULTS_TEST';

// receiveStatus
export const RECEIVE_START = 'START';
export const RECEIVE_SUCCESS = 'SUCCESS';
export const RECEIVE_FAIL = 'FAIL';

// User Location
export const GET_USER_LOCATION = 'GET_USER_LOCATION';

// Loyalty Program
export const GET_LOYALTY_PROGRAM = 'GET_LOYALTY_PROGRAM';
