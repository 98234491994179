import type { TOption } from '../../components/Filter/Filter';

export const ROOMS_OPTIONS: TOption[] = [
	{
		value: 0,
		label: 'Студия',
	},
	{
		value: 1,
		label: '1 комн.',
	},
	{
		value: 2,
		label: '2 комн.',
	},
	{
		value: 3,
		label: '3 комн.',
	},
	{
		value: 4,
		label: '4 и более комн.',
	},
];

export const SLIDER_SETTINGS = {
	autoplay: false,
	autoplaySpeed: 10000,
	className: 'homeStatSlider',
	centerPadding: 0,
	lazyLoad: true,
	arrows: false,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 3,
	accessibility: false,
	infinite: false,
	centerMode: false,
	dots: false,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				autoplay: false,
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: false,
				dots: false,
				centerMode: false,
			},
		},
		{
			breakpoint: 1024,
			settings: {
				autoplay: true,
				slidesToShow: 2,
				slidesToScroll: 1,
				centerMode: false,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				autoplay: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
				centerMode: true,
			},
		},
	],
};
