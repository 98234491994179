import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createStateSyncMiddleware, withReduxStateSync } from 'redux-state-sync';
import rootReducer from '../reducers';
import createHelpers from './createHelpers';
import { SET_TOKEN, LOAD_COMPARE, LOAD_PROFILE, CLEAR_STORE, SUCCESS } from '../constants';

export default function configureStore(initialState, helpersConfig) {
	const syncConfig = {
		channel: 'living_state_sync',
		// predicate: actionType => actionType.includes('TOKEN'),
		whitelist: [SET_TOKEN, LOAD_COMPARE + SUCCESS, LOAD_PROFILE + SUCCESS, CLEAR_STORE],
	};

	const helpers = createHelpers(helpersConfig);
	const middleware = process.env.BROWSER
		? [thunk.withExtraArgument(helpers), createStateSyncMiddleware(syncConfig)]
		: [thunk.withExtraArgument(helpers)];

	let enhancer;

	if (__DEV__) {
		// middleware.push(createLogger());

		// https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
		let devToolsExtension = (f) => f;
		// eslint-disable-next-line no-underscore-dangle
		if (process.env.BROWSER && window.__REDUX_DEVTOOLS_EXTENSION__) {
			// eslint-disable-next-line no-underscore-dangle
			devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__();
		}

		enhancer = compose(applyMiddleware(...middleware), devToolsExtension);
	} else {
		enhancer = applyMiddleware(...middleware);
	}

	// See https://github.com/rackt/redux/releases/tag/v3.1.0
	const store = process.env.BROWSER
		? createStore(withReduxStateSync(rootReducer), initialState, enhancer)
		: createStore(rootReducer, initialState, enhancer);

	// Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
	if (__DEV__ && module.hot) {
		module.hot.accept('../reducers', () =>
			// eslint-disable-next-line global-require
			store.replaceReducer(require('../reducers').default),
		);
	}

	return store;
}
