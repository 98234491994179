/* @flow */

import {
	INITIAL_FEE_DEFAULT,
	PAYMENT_DEFAULT,
	PRICE_TOTAL_MIN_GTE_DEFAULT,
	PRICE_TOTAL_MIN_LTE_DEFAULT,
} from '../data/filter/price';
import type { TAction } from '../types/core';
import { LIRA_SET_TEMP_MORTGAGE_PARAMS, LIRA_SET_TEMP_PARAMS } from '../constants';

export type TFilterTemp = {
	priceTotalMinGte: number,
	priceTotalMinLte: number,
	initialFee: number,
	monthlyPayment: number,
};

const initialState: TFilterTemp = {
	priceTotalMinGte: PRICE_TOTAL_MIN_GTE_DEFAULT,
	priceTotalMinLte: PRICE_TOTAL_MIN_LTE_DEFAULT,
	initialFee: INITIAL_FEE_DEFAULT,
	monthlyPayment: PAYMENT_DEFAULT,
};

const filterTemp = (state: TFilterTemp = initialState, action: TAction): TFilterTemp => {
	const { type, payload } = action;

	switch (type) {
		case LIRA_SET_TEMP_MORTGAGE_PARAMS:
		case LIRA_SET_TEMP_PARAMS: {
			const priceTotalMinGte =
				payload?.priceTotalMinGte && payload?.priceTotalMinGte !== null
					? payload.priceTotalMinGte
					: state.priceTotalMinGte;
			const priceTotalMinLte =
				payload?.priceTotalMinLte && payload?.priceTotalMinLte !== null
					? payload.priceTotalMinLte
					: state.priceTotalMinLte;
			const initialFee =
				payload?.initialFee && payload?.initialFee !== null ? payload.initialFee : state.initialFee;
			const monthlyPayment =
				payload?.monthlyPayment && payload?.monthlyPayment !== null
					? payload.monthlyPayment
					: state.monthlyPayment;

			return { priceTotalMinLte, priceTotalMinGte, initialFee, monthlyPayment };
		}
		default:
			return state;
	}
};

export default filterTemp;
