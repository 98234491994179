import { JOURNAL_FETCH_ARTICLES_DATA, JOURNAL_CLEAR } from '../constants/journal';
import { FAIL, START, SUCCESS } from '../constants';

const initialState = {
	articlesData: null,
	pageType: null,
	loading: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case JOURNAL_FETCH_ARTICLES_DATA + START:
			return { ...state, loading: true };

		case JOURNAL_FETCH_ARTICLES_DATA + SUCCESS:
			return { ...state, ...action.payload, loading: false };

		case JOURNAL_FETCH_ARTICLES_DATA + FAIL:
			return { ...state, articlesData: null, pageType: null, loading: false };

		case JOURNAL_CLEAR:
			return {
				...initialState,
			};

		default:
			return state;
	}
};

export default reducer;
