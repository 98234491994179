/* @flow */

import filterParams from '../data/general/lexsFilterParams';
import type { TFilterBase, TFilterExt, TFilterGlobal, TFilterWishes, TLexsFilter } from '../types/filters';
import { isEmpty } from '../utils';
import { genProgressiveDeadlines } from './genDeadlines';
// import { flatten } from '../utils';

/**
 * Получение массива сроков сдачи
 * @param {Date} inputDate - строка даты в ISO-формате (YYYY-MM-DD)
 * @returns {[]} - на выходе массив объектов { value: number, last?: boolean}
 */
export const getDeadlines = (inputDate: ?Date = null) => {
	const deadlines = [];
	const d: Date = inputDate === null ? new Date() : new Date(inputDate);
	const currentQuarter = Math.floor((d.getMonth() + 3) / 3);
	const currentYear = d.getFullYear();

	if (currentQuarter === 4) {
		// eslint-disable-next-line no-plusplus
		for (let year = currentYear + 1; year <= currentYear + 2; year++) {
			// eslint-disable-next-line no-plusplus
			for (let q = 1; q <= 4; q++) {
				const result = `${year}0${q}`;
				deadlines.push({
					value: Number(result),
				});
			}
		}
		deadlines.push({
			value: Number(`${currentYear + 3}04`),
			last: true,
		});
	} else {
		// eslint-disable-next-line no-plusplus
		for (let q = currentQuarter + 1; q <= 4; q++) {
			const result = `${currentYear}0${q}`;
			deadlines.push({
				value: Number(result),
			});
		}
		// eslint-disable-next-line no-plusplus
		for (let qNext = 1; qNext <= 4; qNext++) {
			const result = `${currentYear + 1}0${qNext}`;
			deadlines.push({
				value: Number(result),
			});
		}
		// eslint-disable-next-line no-plusplus
		for (let year = currentYear + 2; year <= currentYear + 3; year++) {
			deadlines.push({
				value: Number(`${year}04`),
				last: true,
			});
		}
	}

	return deadlines;
};

export const getNumberOfParams = (
	filter: TLexsFilter,
	relevance: { relevanceComplexParams: any, relevanceApartParams: any },
) => {
	const someComplexParams = ['numberOfStoreys', 'hasConcierge', 'hasSmartHouse'];
	const complexCountParams = [
		{ name: 'Способ оплаты', value: 'purchaseMethod' },
		{ name: 'На территории ЖК', value: 'territory' },
		{ name: 'Инфраструктура', value: 'infrastructure' },
		{ name: 'Для прогулок с питомцами', value: 'goodForPets' },
		{ name: 'Для автовладельцев', value: 'goodForCarOwners' },
		{ name: 'Безопасность', value: 'security' },
	];
	const apartmentParams = [
		{ value: 'technology', order: 1 },
		{ value: 'finishing', order: 3 },
		{ value: 'bathroom', order: 6 },
		{ value: 'roomsConnection', order: 7 },
		{ value: 'planningType', order: 2 },
		{ value: 'windowsDirections', order: 8 },
		...filterParams.layout.map((item) => ({ value: item.value, order: item.order || 100 })),
		...filterParams.apartAdditionals.map((item) => ({ value: item.value, order: item.order || 100 })),
		...filterParams.finishing.map((item) => ({ value: item.value, order: item.order || 100 })),
	];

	let matchedParams = 0;
	let complexParamsCount = 0;
	let apartmentParamsCount = 0;

	// Параметры ЖК
	if (relevance && relevance.relevanceComplexParams) {
		const { relevanceComplexParams } = relevance;
		const { isCloseToMetro, isCloseToCenter } = relevanceComplexParams;

		someComplexParams.forEach((param) => {
			if (relevanceComplexParams[param]) {
				complexParamsCount += 1;

				if (relevanceComplexParams[param].match) {
					matchedParams += 1;
				}
			}
		});

		[isCloseToMetro, isCloseToCenter].forEach((param) => {
			if (param) {
				complexParamsCount += 1;

				if (param.match) {
					matchedParams += 1;
				}
			}
		});

		complexCountParams.forEach((param) => {
			if (
				filterParams[param.value]
					.map((item) => item.value)
					.some((item) => Object.keys(relevanceComplexParams).includes(item))
			) {
				const subParamsKeys = Object.keys(relevanceComplexParams).filter((item) =>
					filterParams[param.value].map((filterParam) => filterParam.value).includes(item),
				);

				complexParamsCount += filterParams[param.value].filter((item) =>
					Object.keys(filter)
						.filter((key) => !!filter[key])
						.includes(item.value),
				).length;

				matchedParams += subParamsKeys.filter((sub) => relevanceComplexParams[sub].match).length;
			}
		});
	}

	// Параметры квартиры
	if (relevance && relevance.relevanceApartParams) {
		const { relevanceApartParams } = relevance;

		apartmentParams
			.map((item) => item.value)
			.map((param) => {
				if (relevanceApartParams[param]) {
					apartmentParamsCount += 1;

					if (relevanceApartParams[param].match) {
						matchedParams += 1;
					}
				}
				return null;
			});
	}

	return {
		allParams: apartmentParamsCount + complexParamsCount,
		matchedParams,
		complexParamsCount,
		apartmentParamsCount,
	};
};

export const defaultSelectionWishesKeys: string[] = [
	'hasChildrenPlayground',
	'hasOutdoorFitness',
	'hasStadium',
	'hasBikeParking',
	'hasSpecialImprovementObjects',
	'hasLandscapeDesign',
	'hasPlacesForAdults',
	'isParkArea',
	'hasAccessibleEntrance',
	'hasPantry',
	'isCloseToPark',
	'isCloseToSportFacility',
	'isCloseToPolyclinic',
	'isCloseToPharmacy',
	'isCloseToSupermarket',
	'isCloseToKindergarten',
	'isCloseToSchool',
	'isCloseToChildrenPolyclinic',
	'isCloseToShoppingMall',
	'isFullPaymentAvailable',
	'hasMortgage',
	'hasMaternityCapital',
	'hasMilitaryMortgage',
	'isCloseToMetro',
	'isCloseToCenter',
	'hasPawsWashingRoom',
	'hasGroundsForWalkingDogs',
	'hasEnoughParkingPlaces',
	'hasCoveredParking',
	'hasUndergroundParking',
	'hasPaidParking',
	'hasVideoSurveillanceSystem',
	'hasClosedYard',
	'hasAllDayProtectionPost',
	'hasTerritoryEnclosure',
	'isTechnologyBrick',
	'isTechnologyBrickMonolith',
	'isTechnologyPanel',
	'isTechnologyMonolith',
	'isLowRise',
	'isHighRise',
	'hasStrollers',
	'hasConcierge',
	'hasSmartHouse',
	'isVerticalPipeRouting',
	'isHorizontalPipeRouting',
	'isEuroPlanning',
	'isClassicPlanning',
	'isFreePlanning',
	'hasWindowsOnSingleDirection',
	'hasWindowsOnMultipleDirections',
	'hasWindowsOnOppositeDirections',
	'hasIsolatedRooms',
	'hasAdjacentRooms',
	'hasAdjacentBathroom',
	'hasSeparateBathroom',
	'hasMultipleBathroom',
	'hasBalcony',
	'hasLoggia',
	'hasTerrace',
	'hasBayWindow',
	'hasLoggiaKitchen',
	'hasLoggiaRoom',
	'hasNoAirShaft',
	'hasSauna',
	'hasLaundry',
	'hasRoomWithBathroom',
	'hasMultipleWindowKitchen',
	'hasMultipleWindowRoom',
	'hasPantryCorridor',
	'hasPantryRoom',
	'hasHighCeiling',
	'isReliableBuilder',
	'hasEastWindow',
	'hasWestWindow',
	'hasNorthWindow',
	'hasNorthEastWindow',
	'hasNorthWestWindow',
	'hasSouthWindow',
	'hasSouthEastWindow',
	'hasSouthWestWindow',
	'hasWindowsOnMultipleDirections',
];

export const defaultComplexWishesKeys: string[] = [
	'isEuroPlanning',
	'isClassicPlanning',
	'isFreePlanning',
	'hasWindowsOnSingleDirection',
	'hasWindowsOnMultipleDirections',
	'hasWindowsOnOppositeDirections',
	'hasIsolatedRooms',
	'hasAdjacentRooms',
	'hasAdjacentBathroom',
	'hasSeparateBathroom',
	'hasMultipleBathroom',
	'hasBalcony',
	'hasLoggia',
	'hasTerrace',
	'hasBayWindow',
	'hasLoggiaKitchen',
	'hasLoggiaRoom',
	'hasNoAirShaft',
	'hasSauna',
	'hasLaundry',
	'hasRoomWithBathroom',
	'hasMultipleWindowKitchen',
	'hasMultipleWindowRoom',
	'hasPantryCorridor',
	'hasPantryRoom',
	'isBigKitchen',
	'isBigFlat',
	'isSmallFlat',
	'hasHighCeiling',
	'hasEastWindow',
	'hasWestWindow',
	'hasNorthWindow',
	'hasNorthEastWindow',
	'hasNorthWestWindow',
	'hasSouthWindow',
	'hasSouthEastWindow',
	'hasSouthWestWindow',
	'hasWindowsOnMultipleDirections',
];

export const defaultExtFilterKeys = [
	'finishingType',
	'floorGte',
	'floorLte',
	'isNotFirstFloor',
	'isNotLastFloor',
	'areaTotalGte',
	'areaTotalLte',
	'areaKitchenGte',
	'areaKitchenLte',
];

export const defaultBaseFilterKeys = [
	'deadlineLte',
	'priceTotalMinGte',
	'priceTotalMinLte',
	'roomsCntForFilter',
	'polygonList',
	'page',
	'areaIds',
	'metroIds',
	'areaForFilter',
	'realtyType',
	'mortgageInfo',
	'isApartHotel',
];

export const defaultLiraFilterKeys = [
	'deadlineLte',
	'priceTotalMinGte',
	'priceTotalMinLte',
	'roomsCntForFilter',
	'areaIds',
	'metroIds',
	'realtyType',
	'isApartHotel',
	'mortgageInfo',
];

export const defaultGlobalFilterKeys = ['selectionMode'];

export const alwaysAvailableBaseKeys = ['deadlineLte', 'areaForFilter', 'realtyType', 'isApartHotel'];

export const baseFilterRemovableKeys = {
	mortgage: ['priceTotalMinGte', 'priceTotalMinLte'],
	normal: ['initialFee', 'payment', 'loanPeriod'],
};

export const globalFilterGarbageKeys = ['isComplexView', 'searchMode', 'dataMode'];

export const separateAdditionalParams = (data) =>
	Object.keys(data).reduce(
		(acc, key: string) =>
			defaultExtFilterKeys.includes(key)
				? { ext: { ...acc.ext, [key]: data[key] }, wishes: { ...acc.wishes } }
				: { ext: { ...acc.ext }, wishes: { ...acc.wishes, [key]: data[key] } },
		{ ext: {}, wishes: {} },
	);

/**
 * Удаляет лишние параметры из базового фильтра в зависимости от типа подбора
 * @param {TFilterBase} base - базовый фильтр
 * @param {String} mode - тип подбора
 * @returns {TFilterBase}
 */
export const getBaseFilters = (base: TFilterBase, mode: string = 'normal'): TFilterBase =>
	Object.keys(base).reduce(
		(acc, key: string) =>
			baseFilterRemovableKeys[mode].includes(key)
				? acc
				: {
						...acc,
						...(!isEmpty(base[key]) || alwaysAvailableBaseKeys.includes(key) ? { [key]: base[key] } : {}),
				  },
		{},
	);

/**
 * Возвращает значимые значения дополнительного фильтра
 * @param {TFilterExt} ext - дополнительный фильтр
 * @returns {TFilterExt}
 */
export const getExtFilter = (ext: TFilterExt): TFilterExt =>
	Object.keys(ext).reduce((acc, key) => (ext[key] === null ? acc : { ...acc, [key]: ext[key] }), {});

/**
 * Удаляет "лишние" параметры из глобального фильтра
 * @param {TFilterGlobal} globals
 * @returns {TFilterGlobal}
 */
export const getGlobalFilter = (globals: TFilterGlobal): TFilterGlobal =>
	Object.keys(globals).reduce(
		(acc, key) =>
			globalFilterGarbageKeys.includes(key)
				? acc
				: { ...acc, ...(isEmpty(globals[key]) ? {} : { [key]: globals[key] }) },
		{},
	);

/**
 * Возвращает только значимые параметры пожеланий по маске ключей
 * @param {TFilterWishes} wishes - фильтр пожеланий
 * @param {Array<string>} keys - маска ключей пожеланий
 * @returns {TFilterWishes}
 */
export const getSignedWishes = (wishes: TFilterWishes, keys: string[]): TFilterWishes =>
	keys.reduce((acc: TFilterWishes, key: string) => (wishes[key] ? { ...acc, [key]: wishes[key] } : acc), {});

/**
 * Возвращает объединённый объект фильтров для запроса
 * @param {TFilterBase} base - базовый фильтр
 * @param {TFilterExt} ext - расширенный фильтр
 * @param globals - глобальный фильтр
 * @param {TFilterWishes} wishes
 * @param {Array<string>} wishesKeys
 * @param {String} mode
 * @param {Boolean} modern - пожелания (wishes) как отдельный объект
 * @returns Object
 */
export const combineFilters = (
	base: TFilterBase,
	ext: TFilterExt,
	globals: TFilterGlobal,
	wishes: TFilterWishes,
	wishesKeys: string[],
	mode: string = 'normal',
	modern: boolean = false,
) => ({
	...getBaseFilters(base, mode),
	...getExtFilter(ext),
	...getGlobalFilter(globals),
	...(mode === 'normal' && !modern ? { ...getSignedWishes(wishes, wishesKeys) } : { wishes: { ...wishes } }),
});

/**
 * Возвращает сумму значащих пожеланий по маске ключей
 * @param {TFilterWishes} wishes - фильтр пожеланий
 * @param {Array<string>} keys - массив маски ключей
 * @returns {number}
 */
export const getWishesTotalCount = (wishes: TFilterWishes, keys: string[]): number =>
	keys.reduce((acc: number, key: string): number => (wishes[key] ? acc + 1 : acc), 0);

export const separateFilters = (filters) =>
	Object.keys(filters).reduce(
		(acc, curr: string) => {
			if (defaultBaseFilterKeys.includes(curr)) return { ...acc, base: { ...acc.base, [curr]: filters[curr] } };
			if (defaultExtFilterKeys.includes(curr)) return { ...acc, ext: { ...acc.ext, [curr]: filters[curr] } };
			if (defaultGlobalFilterKeys.includes(curr))
				return { ...acc, global: { ...acc.global, [curr]: filters[curr] } };
			if (curr === 'wishes') return { ...acc, wishes: { ...filters.wishes } };
			return acc;
		},
		{
			base: {},
			ext: {},
			global: {},
			wishes: {},
		},
	);

export const separateLiraFilters = (data) =>
	Object.keys(data).reduce(
		(acc, curr: string) => {
			if (curr === 'filters') {
				return Object.keys(data[curr]).reduce((a, b) => {
					if (defaultLiraFilterKeys.includes(b)) {
						return {
							filter: { ...a.filter, [b]: data[curr][b] },
						};
					}
					return a;
				});
			}
			if (curr === 'portraitId') return { ...acc, user: { ...acc.user, portraitId: data.portraitId } };
			if (curr === 'wishGroups') return { ...acc, user: { ...acc.user, userWishes: data.wishGroups } };
			if (curr === 'status') return { ...acc, user: { ...acc.user, status: data.status } };
			if (curr === 'connectedToTelegram')
				return { ...acc, subscribe: { ...acc.subscribe, telegram: data.connectedToTelegram } };

			return acc;
		},
		{
			filter: {},
		},
	);

export const handleValue = (value: number, isSlider: boolean): number => {
	if (value === 0) {
		return 0;
	}
	return isSlider ? Math.log10(value) : Math.ceil(Math.round(10 ** value) / 100000) * 100000;
};

export const handleValue1000 = (value: number, isSlider: boolean): number => {
	if (value === 0) {
		return 0;
	}
	return isSlider ? Math.log10(value) : Math.ceil(Math.round(10 ** value) / 1000) * 1000;
};

export const handleStep = (value): number => {
	if (Number(value) >= 0) {
		const isInteger = Number.isInteger(value);
		if (!isInteger) {
			const symbols = value.toString().split('.')[1].length;
			return 1 / 10 ** symbols;
		}
		return 1e-100;
	}
	return 1;
};

export const deadlineSelectorOptions = [
	{ value: 300001, label: 'Не важно' },
	{ value: 0, label: 'Уже сдано' },
	...genProgressiveDeadlines(null, { word: 'full' }),
];
