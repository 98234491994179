/* @flow */
import React, { type Node } from 'react';
import { useDispatch } from 'react-redux';
import { DropdownCheckbox, DropdownIcon, DropdownLink, DropdownSubmenu } from '../../MobileHeader/styles';
import Check from '../../../../Check';
import { handleChangeLikesSold } from '../../../../../actions/lira';
import { dropdownIconSmall } from '../../../../Icons';
import { LIRA_SORTING_LIKES as LIRA_SORTING } from '../../../../../constants';
import type { TLiraSelectionsState } from '../../../../../reducers/liraSelections';
import type { TDispatch } from '../../../../../types/core';

type TProps = {
	liraLikes: TLiraSelectionsState,
	changeLikesSorting: () => {},
	submenuToggle: () => {},
	openSubmenu: boolean,
};

const LiraDislikes = ({ liraLikes, changeLikesSorting, openSubmenu, submenuToggle }: TProps): Node => {
	const dispatch: TDispatch = useDispatch();

	return (
		<>
			<DropdownCheckbox>
				<Check
					name="likesSoldedFlats"
					id="likesSoldedFlats"
					label="Показывать проданные"
					checked={liraLikes.includeSold}
					withoutMargin
					toRight
					onChange={() => dispatch(handleChangeLikesSold(!liraLikes.includeSold))}
					style={{ justifyContent: 'space-between' }}
				/>
			</DropdownCheckbox>
			{liraLikes?.dislikedView === 'list' && (
				<>
					<DropdownLink as="button" onClick={submenuToggle}>
						Сортировка
						<DropdownIcon viewBox="0 0 16 16" $isOpen={openSubmenu}>
							{dropdownIconSmall}
						</DropdownIcon>
					</DropdownLink>
					<DropdownSubmenu $isOpen={openSubmenu}>
						{LIRA_SORTING.map((item) => (
							<DropdownLink
								as="button"
								$isActive={liraLikes.dislikedSort === item.value}
								key={Math.random()}
								onClick={changeLikesSorting('dislikes', item.value)}
							>
								{item.title}
							</DropdownLink>
						))}
					</DropdownSubmenu>
				</>
			)}
		</>
	);
};

export default LiraDislikes;
