/* @flow */

import React from 'react';
import styled from 'styled-components';
import { Button, List, Overflow, Root } from '../FakeSelect/styles';
import InputSlider from '../../../../components/UniversalFilter/InputSlider';
import SwitcherCheckbox from '../../../../components/SwitcherCheckbox';
import { InputLabel, SwitcherLabel } from '../../styles';

type TProps = {
	title: string,
	valueMin: number,
	valueMax: number,
	initialFee: number,
	monthlyPayment: number,
	isMortgage: boolean,
	onChange: () => {},
	isOpen: boolean,
	setOpen: () => {},
	handleSelectionModeToggle: () => {},
};

const InputsWrapper = styled.div`
	width: 400px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	margin: 20px 13px;
	grid-column-gap: 12px;
	grid-row-gap: 16px;
`;

const PriceSelect = ({
	title,
	valueMin,
	valueMax,
	initialFee,
	monthlyPayment,
	isMortgage,
	onChange,
	isOpen,
	setOpen,
	handleSelectionModeToggle,
}: TProps) => (
	<Root>
		<Button $isOpen={isOpen} onClick={setOpen('price')}>
			<span>{title}</span>
		</Button>
		<List $isOpen={isOpen}>
			<InputsWrapper>
				<SwitcherLabel>
					По ежемесячному платежу
					<SwitcherCheckbox checked={isMortgage} onChange={handleSelectionModeToggle} />
				</SwitcherLabel>
				{isMortgage ? (
					<>
						<InputLabel>
							Ежемесячный платеж
							<InputSlider
								value={monthlyPayment}
								name="monthlyPayment"
								prefix="до"
								suffix={`\u20BD`}
								handleInputChange={onChange}
							/>
						</InputLabel>
						<InputLabel>
							Первоначальный взнос
							<InputSlider
								value={initialFee}
								name="initialFee"
								suffix={`\u20BD`}
								handleInputChange={onChange}
							/>
						</InputLabel>
					</>
				) : (
					<>
						<InputSlider
							value={valueMin}
							name="priceTotalMinGte"
							prefix="от"
							suffix={`\u20BD`}
							handleInputChange={onChange}
						/>
						<InputSlider
							value={valueMax}
							name="priceTotalMinLte"
							prefix="до"
							suffix={`\u20BD`}
							handleInputChange={onChange}
						/>
					</>
				)}
			</InputsWrapper>
		</List>
		{isOpen && <Overflow $isOpen={isOpen} role="button" onClick={setOpen(null)} />}
	</Root>
);

export default PriceSelect;
