/* @flow */
import { TMediaItem } from '../types/media';

import type { TCity } from '../types/city';
import type { TArticlePreview } from '../types/articles';
import type { TVideos } from '../types/apart';

export const checkKey = (prop: any): any => (prop === undefined || prop === null ? true : prop);

export const logger = (...args) => {
	if (typeof window !== 'undefined') {
		console.info(...args);
	} else {
		console.info(...args, { depth: 5, colors: true });
	}
};

/**
 * Получение форматированного объекта с изображениями для слайдера
 * */
export const getMediasForSlider = (
	images: string[],
	layout: string,
	floorPlan: string,
	house: string[],
	videos: TVideos,
) => {
	const medias: TMediaItem[] = [];

	if (layout !== null && layout !== '' && layout !== 'https://fcdn.living.ru/images/broken-1024.png') {
		medias.push({
			link: layout,
			type: 'image',
			content: 'layout',
		});
	}

	if (floorPlan && floorPlan !== null) {
		medias.push({
			link: floorPlan,
			type: 'image',
			content: 'floorPlan',
		});
	}

	if (images && Array.isArray(images)) {
		images.forEach((image) => {
			if (image) {
				medias.push({
					link: image,
					type: 'image',
					content: 'photo',
				});
			}
		});
	}

	if (house && Array.isArray(house)) {
		house.forEach((image) => {
			if (image) {
				medias.push({
					link: image,
					type: 'image',
					content: 'house',
				});
			}
		});
	}

	if (videos) {
		Object.entries(videos).forEach((element) => {
			if (element[1] !== null) {
				medias.push({
					link: `https://img.youtube.com/vi/${element[1]}/0.jpg`,
					type: 'youtube',
					code: element[1],
					label: element[0],
					content: 'video',
				});
			}
		});
	}

	return medias;
};

/**
 * Проверка загруженного списка статей на соответствие выбранному городу
 *
 * @param {TArticlePreview[]} articlesArray - массив списка статей
 * @param {TCity} city - стандартный объект Город
 * @returns {boolean} - получаем true/false
 *
 */
export const checkArticlesCity = (articlesArray: TArticlePreview[], city: TCity) => {
	if (articlesArray?.length > 0 && city) {
		return city?.cityIds.includes(articlesArray[articlesArray.length - 1].cityId);
	}

	return false;
};
