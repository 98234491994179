import { EXPERTISE_REPORT_FETCH_REPORT_DATA, CLEAR_EXPERTISE_REPORT_DATA } from '../constants/expertiseReport';
import { FAIL, START, SUCCESS } from '../constants';

const initialState = {
	reportData: null,
	loading: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case EXPERTISE_REPORT_FETCH_REPORT_DATA + START:
			return { ...state, loading: true };

		case EXPERTISE_REPORT_FETCH_REPORT_DATA + SUCCESS:
			return { ...state, reportData: action.payload, loading: false };

		case EXPERTISE_REPORT_FETCH_REPORT_DATA + FAIL:
			return { ...state, reportData: null, loading: false };

		case CLEAR_EXPERTISE_REPORT_DATA:
			return {
				...initialState,
			};

		default:
			return state;
	}
};

export default reducer;
