/* @flow */
import React, { type Node } from 'react';
import { useSelector } from 'react-redux';
import { MessageText, Remain, RemainButton } from '../styles';
import { plural } from '../../../utils';

type TProps = {
	onClick: () => any,
};

const RepeatButton = ({ onClick }: TProps): Node => {
	const counter: number = useSelector((state) => state.auth.counter);
	const remain: boolean = useSelector((state) => state.auth.remain);

	return (
		<Remain>
			{remain && counter ? (
				<MessageText>
					Отправить ещё раз через {`${counter} ${plural(counter, ['секунду', 'секунды', 'секунд'])}`}
				</MessageText>
			) : (
				<RemainButton type="button" onClick={onClick}>
					Отправить ещё раз
				</RemainButton>
			)}
		</Remain>
	);
};

export default RepeatButton;
