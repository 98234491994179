/* @flow */
import {
	CHANGE_PAYMENT_POPUP_STEP,
	CLOSE_AUTH_POPUP,
	CLOSE_CONFIRM_PHONE_POPUP,
	CLOSE_PAYMENT_POPUP,
	FETCH_ACTION_CALLBACK_POPUP,
	OPEN_AUTH_POPUP,
	OPEN_CONFIRM_PHONE_POPUP,
	OPEN_PAYMENT_POPUP,
	POPUP_IS_OPEN,
	TOGGLE_CALLBACK_POPUP,
} from '../constants/popup';
import {
	TYPE_APARTMENT_MORTGAGE,
	TYPE_APARTMENT_QUEUE,
	TYPE_APARTMENT_RESERVE,
	TYPE_APARTMENT_VIEW,
	TYPE_COMPLEX_MORTGAGE,
	TYPE_COMPLEX_VIEW,
} from '../components/CallbackPopupR';
import type { TDispatch } from '../types/core';
import type { TCallbackPopupData } from '../decorators/withCallbackPopup';
import {
	FAIL,
	FETCH_LIRA_CALLBACK_REQUEST,
	FETCH_LIRA_LOYALTY_REQUEST,
	POST_LIRA_PREVIEW_DATA,
	START,
	SUCCESS,
} from '../constants';
import { sendGTMAnalytics } from '../utils/tagManager';

/**
 * Открытие попапа авторизации/регистрации/восстановления пароля
 * @param {'login' | 'report'} type - Тип попапа
 * @param {number} complexId - complexId для покупки отчета
 */
export function openAuthPopup(type = 'login', complexId = null) {
	return {
		type: OPEN_AUTH_POPUP,
		payload: {
			type,
			complexId,
		},
	};
}

/**
 * Закрытие попапа авторизации/регистрации/восстановления пароля
 */
export function closeAuthPopup() {
	return {
		type: CLOSE_AUTH_POPUP,
	};
}

export function openPaymentPopup(step = 1, { serviceId, phone, paymentUrl }) {
	return {
		type: OPEN_PAYMENT_POPUP,
		payload: {
			step,
			serviceId,
			phone,
			paymentUrl,
		},
	};
}

export function closePaymentPopup() {
	return {
		type: CLOSE_PAYMENT_POPUP,
	};
}

export function changePaymentPopupStep(step, options) {
	return {
		type: CHANGE_PAYMENT_POPUP_STEP,
		payload: {
			step,
			...options,
		},
	};
}

/**
 * Открытие попапа подтверждение номера телефона
 */
export function openConfirmPhonePopup() {
	return {
		type: OPEN_CONFIRM_PHONE_POPUP,
	};
}

/**
 * Закрытие попапа подтверждение номера телефона
 */
export function closeConfirmPhonePopup() {
	return {
		type: CLOSE_CONFIRM_PHONE_POPUP,
	};
}

/**
 * Открытие/закрытие попапа обратного звонка
 */
export function toggleCallbackPopup(options = {}) {
	return (dispatch, getState) => {
		const { context = null } = options;
		const {
			user,
			profile: { name = '' },
			city: { cityIds = [] },
		} = getState();

		dispatch({
			type: TOGGLE_CALLBACK_POPUP,
			payload: {
				context,
				citySlug: user.citySlug,
				name,
				cityIds,
			},
		});
	};
}

export const sendActionCallbackRequest =
	(props: TCallbackPopupData) =>
	async (dispatch: TDispatch, getState, { fetch }): boolean => {
		const {
			apartData,
			callbackType,
			complexId,
			context,
			isReserved,
			mortgageData,
			location,
			apartId = null,
		} = props;
		const {
			city: { title } = {},
			popup: {
				actionCallbackPopup: {
					name,
					lastName,
					phone,
					email,
					agree: isAgree,
					confirm,
					phoneError,
					queue,
					day,
					time,
				} = {},
			} = {},
		} = getState();

		if (!phoneError) {
			let message;
			let type;
			let eventText;
			let periodFrom;
			let periodTo;
			const date = new Date(day);

			switch (callbackType) {
				case 'mortgage':
					message = 'Заявка на ипотеку';
					type = apartData.id ? TYPE_APARTMENT_MORTGAGE : TYPE_COMPLEX_MORTGAGE; // 'mortgage';
					eventText = 'ipoteka2';
					break;
				case 'view':
					message = `Записаться на просмотр. Выбранная дата: ${date.toLocaleString('ru', {
						weekday: 'long',
						month: 'long',
						day: 'numeric',
					})}, время: ${time}`;
					type = apartData.id ? TYPE_APARTMENT_VIEW : TYPE_COMPLEX_VIEW; // 'view';
					eventText = 'zapis';
					[periodFrom = '', periodTo = ''] = time.split(' - ');
					break;
				case isReserved:
					message = 'Уточнить наличие';
					type = TYPE_APARTMENT_QUEUE; // 'queue';
					eventText = 'nalichie';
					break;
				default: // 'reservation';
					message = 'Забронировать';
					type = TYPE_APARTMENT_RESERVE;
					eventText = 'bron';
			}

			const url = 'https://api.dashamail.com/';
			const body = JSON.stringify({
				method: 'transactional.send',
				api_key: '42774ad5702973bf4f72f0c752b7f7c3',
				to: 'help@living.ru',
				message: `<p><b>Тип:</b> ${
					message ?? 'Обратный звонок'
				}</p><p><b>Имя:</b> ${name}</p><p><b>Фамилия:</b> ${lastName}</p><p><b>Город:</b> ${title}</p><p><b>Телефон:</b> ${phone}</p><p><b>Страница:</b> ${
					location?.pathname
				}</p>${apartId ? `<p><b>ID квартиры</b>: ${apartId}</p>` : ''}`,
				from_email: 'notice@living-team.ru',
				subject: 'Новая заявка',
			});

			const options = {
				method: 'POST',
				body,
			};

			dispatch({ type: FETCH_ACTION_CALLBACK_POPUP + START });

			try {
				const { response } = await fetch(url, options).then((res) => res.json());
				let payload = {};
				if (response?.msg?.text === 'OK') {
					payload = {
						response: true,
						responseIsSuccess: true,
					};
				} else {
					payload = {
						response: false,
						responseIsExist: false,
						responseIsSuccess: false,
						isFetching: false,
						status: null,
					};
				}

				dispatch({ type: FETCH_ACTION_CALLBACK_POPUP + SUCCESS, payload });

				return true;
			} catch (e) {
				dispatch({
					type: FETCH_ACTION_CALLBACK_POPUP + FAIL,
					payload: {
						response: false,
						responseIsExist: false,
						responseIsSuccess: false,
						isFetching: false,
						status: null,
					},
				});

				return false;
			}
		}

		return false;
	};

export const setPopupOpen = (state: boolean) => ({
	type: POPUP_IS_OPEN,
	payload: state,
});

/**
 * Отправка запроса на просмотр квартиры из формы ЛИРЫ
 * @param location - useLocation() hook
 * @returns {function(...[*]=)}
 */
export const fetchLiraPreviewRequest =
	(location: string) =>
	(dispatch: TDispatch, getState, { fetch }): any => {
		dispatch({ type: POST_LIRA_PREVIEW_DATA + START });

		const {
			city: { title },
			popup: { previewForm: { name, lastName, phone } = {} },
		} = getState();

		const url = 'https://api.dashamail.com/';
		const body = JSON.stringify({
			method: 'transactional.send',
			api_key: '42774ad5702973bf4f72f0c752b7f7c3',
			to: 'help@living.ru',
			message: `<p><b>Тип:</b> Просмотр квартиры</p><p><b>Имя:</b> ${name}</p><p><b>Фамилия:</b> ${lastName}</p><p><b>Город:</b> ${title}</p><p><b>Телефон:</b> ${phone}</p><p><b>Страница:</b> ${location?.pathname}</p>`,
			from_email: 'notice@living-team.ru',
			subject: 'Просмотр квартиры',
		});

		fetch(url, {
			method: 'POST',
			body,
		})
			.then((data) => data.json())
			.then(({ response }) => {
				if (response?.msg?.text === 'OK') {
					dispatch({ type: POST_LIRA_PREVIEW_DATA + SUCCESS });

					sendGTMAnalytics({
						event: 'customTrackEvent',
						eventCategory: 'zapis_na_prosmotr',
						eventAction: 'send_form_succefully',
					});
				} else {
					dispatch({ type: POST_LIRA_PREVIEW_DATA + FAIL, error: 'WTF' });
				}
			})
			.catch((error) => {
				dispatch({ type: POST_LIRA_PREVIEW_DATA + FAIL, error });
			});
	};

export const fetchLiraCallbackRequest =
	(location: string, customType: string = '', apartId: string = null) =>
	(dispatch: TDispatch, getState, { fetch }): any => {
		dispatch({ type: FETCH_LIRA_CALLBACK_REQUEST + START });

		const {
			city: { title },
			popup: { callbackForm: { phone: formattedPhone, name, lastName } = {} },
		} = getState();
		const phone = formattedPhone.replace(/[() ]/g, '');

		const url = 'https://api.dashamail.com/';
		const body = JSON.stringify({
			method: 'transactional.send',
			api_key: '42774ad5702973bf4f72f0c752b7f7c3',
			to: 'help@living.ru',
			message: `<p><b>Тип:</b> ${
				customType ?? 'Обратный звонок'
			}</p><p><b>Имя:</b> ${name}</p><p><b>Фамилия:</b> ${lastName}</p><p><b>Город:</b> ${title}</p><p><b>Телефон:</b> ${phone}</p><p><b>Страница:</b> ${
				location?.pathname
			}</p>${apartId ? `<p><b>ID квартиры</b>: ${apartId}</p>` : ''}`,
			from_email: 'notice@living-team.ru',
			subject: 'Новая заявка',
		});

		fetch(url, {
			method: 'POST',
			body,
		})
			.then((data) => data.json())
			.then(({ response }) => {
				if (response?.msg?.text === 'OK') {
					dispatch({ type: FETCH_LIRA_CALLBACK_REQUEST + SUCCESS });

					sendGTMAnalytics({
						event: 'customTrackEvent',
						eventCategory: 'zayzvka_na_zvonok_cta',
						eventAction: 'send_form_succefully',
					});
				} else {
					dispatch({ type: FETCH_LIRA_CALLBACK_REQUEST + FAIL });
				}
			})
			.catch((error) => {
				dispatch({ type: FETCH_LIRA_CALLBACK_REQUEST + FAIL, error });
			});
	};

export const fetchLiraLoyaltyRequest =
	(location: string, customType: string = '', userId: number = null) =>
	(dispatch: TDispatch, getState, { fetch }): any => {
		dispatch({ type: FETCH_LIRA_LOYALTY_REQUEST + START });

		const {
			city: { title },
			popup: { loyaltyPopup: { phone: formattedPhone, name, lastName } = {} },
		} = getState();
		const phone = formattedPhone.replace(/[() ]/g, '');

		const url = 'https://api.dashamail.com/';
		const body = JSON.stringify({
			method: 'transactional.send',
			api_key: '42774ad5702973bf4f72f0c752b7f7c3',
			to: 'help@living.ru',
			message: `<p><b>Тип:</b> ${
				customType ?? 'Регистрация в программе лояльности'
			}</p><p><b>Имя:</b> ${name}</p><p><b>Фамилия:</b> ${lastName}</p><p><b>Город:</b> ${title}</p><p><b>Телефон:</b> ${phone}</p><p><b>Страница:</b> ${
				location?.pathname
			}</p>${userId ? `<p><b>ID пользователя</b>: ${userId}</p>` : ''}`,
			from_email: 'notice@living-team.ru',
			subject: 'Новая заявка',
		});

		fetch(url, {
			method: 'POST',
			body,
		})
			.then((data) => data.json())
			.then(({ response }) => {
				if (response?.msg?.text === 'OK') {
					dispatch({ type: FETCH_LIRA_LOYALTY_REQUEST + SUCCESS });

					sendGTMAnalytics({
						event: 'customTrackEvent',
						eventCategory: 'programma_loyalnosti_cta',
						eventAction: 'send_form_succefully',
					});
				} else {
					dispatch({ type: FETCH_LIRA_LOYALTY_REQUEST + FAIL });
				}
			})
			.catch((error) => {
				dispatch({ type: FETCH_LIRA_LOYALTY_REQUEST + FAIL, error });
			});
	};
