/* @flow */
import React, { type Node, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SmartCaptcha } from '@yandex/smart-captcha';
import type { TDispatch } from '../../types/core';
import { AppContext } from '../AppContext';
import { ButtonWrapper, PopUpContent, PopUpImage, PopUpImageBackground, StageRoot, Tab, Tabs, Title } from './styles';
import Button from '../Button2.0';
import ErrorStage from './Components/ErrorStage';
import PhoneStage from './Components/PhoneStage';
import { loginStageOne, loginStageTwo } from '../../actions/profile';
import CodeStage from './Components/CodeStage';
import history from '../../history';
import { AuthDialogImg } from './Components/Image';
import { AUTH_SET, CHANGE_AUTH_TYPE, CHANGE_TOKEN } from '../../constants';
import type { TRootState } from '../../types/rootState';
import type { TProfile, TToken } from '../../types/account';
import type { TAuthState } from '../../reducers/auth';
import TelegramStage from './Components/TelegramStage';
import { useTelegramConnection } from '../../utils/hooks';

type TProps = {
	backUrl?: string,
	onClose?: () => {},
};

const ModalAuthDialog = ({ backUrl = '', onClose }: TProps): Node => {
	const dispatch: TDispatch = useDispatch();
	const store = useStore();
	const context = useContext(AppContext);

	const profile: TProfile = useSelector((state: TRootState) => state.profile);
	const auth: TAuthState = useSelector((state: TRootState) => state.auth);
	const token: TToken = useSelector((state: TRootState) => state.token);

	const { phone, phoneVerified, isAgree } = profile;
	const { stage, fail, isLoading, timerInProgress, error, repeatChance, repeatBlocked } = auth;
	const { baseToken, code } = token;

	const [isDisabled, setIsDisabled]: [boolean, (boolean) => any] = useState(true);
	const [hiddenText, setHiddenText]: [string, (string) => any] = useState('');

	const [tokenCaptcha, setTokenCaptcha] = useState(null);

	const isLocalhost = document?.location?.hostname === 'localhost';
	const isInvalidCode = error === 'Invalid activation code';

	const handleAuthTab = (type) => {
		dispatch({ type: CHANGE_AUTH_TYPE, payload: type });
	};

	useEffect(() => {
		if (isLocalhost) {
			setTokenCaptcha('localhost');
		}
	}, []);

	const handleCaptchaSuccess = useCallback((captchaToken: string) => {
		setTokenCaptcha(captchaToken);
	});

	const { executeRecaptcha } = useGoogleReCaptcha();
	const { handleConnect } = useTelegramConnection(true);

	const getButtonText = (): string => {
		if (fail) return isInvalidCode && !repeatBlocked ? 'Повторить' : 'Закрыть';
		if (stage === 'phone') return 'Получить пароль';
		if (stage === 'telegram') return 'Открыть Telegram';
		return 'Войти в систему';
	};

	const goBack = (): any => {
		if (backUrl) {
			history.push(backUrl, {
				showPreloader: true,
				prev: history.location,
				y: window.scrollY,
			});
		}
	};

	const doFinish = () => {
		if (backUrl) {
			goBack();
		}
		if (onClose) {
			onClose();
		}
	};

	const handleRecaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			// eslint-disable-next-line no-console
			console.error('Execute recaptcha not yet available');
		} else {
			const recaptchaToken = await executeRecaptcha('login');
			dispatch({
				type: CHANGE_TOKEN,
				payload: {
					reCaptchaToken: recaptchaToken,
					reCaptchaTime: new Date(),
				},
			});
		}
	}, [executeRecaptcha]);

	const onRepeat = () => {
		dispatch({ type: AUTH_SET, payload: { fail: false, error: null } });
		// setAuthRepeatCode()(dispatch, store.getState);
	};

	const onButtonClick =
		(force: boolean = false) =>
		(): any => {
			setIsDisabled(true);

			if (fail && !force) {
				if (isInvalidCode && !repeatBlocked) {
					onRepeat();
				} else {
					doFinish();
				}
			} else if (tokenCaptcha && stage === 'telegram') {
				handleConnect(true).then();
			} else if (tokenCaptcha && (stage === 'phone' || force)) {
				handleRecaptchaVerify().then(() => {
					loginStageOne(phone)(dispatch, store.getState, context);
				});
			} else if (stage === 'code') {
				loginStageTwo(doFinish)(dispatch, store, context);
			} else {
				doFinish();
			}
		};

	useEffect(() => {
		handleRecaptchaVerify().then();
	}, [handleRecaptchaVerify]);

	useEffect(() => {
		if (hiddenText) {
			setIsDisabled(true);
		} else if (fail) {
			if (isDisabled) {
				setIsDisabled(false);
			}
		} else if (stage === 'phone') {
			if (timerInProgress) {
				setIsDisabled(true);
			} else if (isDisabled !== !(phoneVerified && isAgree && tokenCaptcha)) {
				setIsDisabled(!(phoneVerified && isAgree && tokenCaptcha));
			}
		} else if (stage === 'telegram' && isAgree) {
			setIsDisabled(false);
		} else {
			const codeVerified = /^\d{4}$/.test(code);
			if (isDisabled !== (!codeVerified || !baseToken)) {
				setIsDisabled(!codeVerified || !baseToken);
			}
		}
	}, [stage, phoneVerified, code, fail, isAgree, hiddenText, timerInProgress, tokenCaptcha]);

	useEffect(() => {
		if (stage === 'telegram' && !token.isAnonymous) {
			doFinish();
		}
	}, [token, stage]);

	const getCurrentScreen = () => {
		if (fail || repeatBlocked) {
			return (
				<ErrorStage isInvalidCode={isInvalidCode} repeatChance={repeatChance} repeatBlocked={repeatBlocked} />
			);
		}
		if (stage === 'phone') {
			return (
				<>
					<PhoneStage
						handleKeyPress={onButtonClick()}
						changeHidden={setHiddenText}
						tokenCaptcha={tokenCaptcha}
					/>
					{!isLocalhost && (
						<SmartCaptcha
							sitekey="ysc1_5yNkKaJxQYqyT9wncOxS8ln8dAXeGGZ8scJuQA7ac8f5135e"
							onSuccess={handleCaptchaSuccess}
						/>
					)}
				</>
			);
		}
		if (stage === 'telegram') {
			return (
				<>
					<TelegramStage />
					{!isLocalhost && (
						<SmartCaptcha
							sitekey="ysc1_5yNkKaJxQYqyT9wncOxS8ln8dAXeGGZ8scJuQA7ac8f5135e"
							onSuccess={handleCaptchaSuccess}
						/>
					)}
				</>
			);
		}
		return <CodeStage onRepeatButtonClick={onButtonClick(true)} handleKeyPress={onButtonClick()} />;
	};

	return (
		<>
			<PopUpImageBackground>
				<PopUpImage id="popUpImage" viewBox="0 0 185 166">
					{AuthDialogImg}
				</PopUpImage>
			</PopUpImageBackground>
			<PopUpContent>
				<Title>Авторизация</Title>
				{stage !== 'code' && (
					<Tabs>
						<Tab onClick={() => handleAuthTab('telegram')} $isActive={stage === 'telegram'}>
							Через Telegram
						</Tab>
						<Tab onClick={() => handleAuthTab('phone')} $isActive={stage === 'phone'}>
							По телефону
						</Tab>
					</Tabs>
				)}
				<StageRoot>{getCurrentScreen()}</StageRoot>
				<ButtonWrapper>
					<Button
						size="m"
						disabled={isDisabled}
						isLoading={isLoading}
						onClick={onButtonClick()}
						style={{ padding: '0 40px' }}
					>
						{getButtonText()}
					</Button>
				</ButtonWrapper>
			</PopUpContent>
		</>
	);
};

ModalAuthDialog.defaultProps = {
	backUrl: '',
	onClose: undefined,
};

export default ModalAuthDialog;
