/* @flow */

import React, { memo } from 'react';
import Slider from 'react-slick';
import mortgageGos from '../../../../../public/images/home/cards/mortgage-gos.png';
import mortgageGos2x from '../../../../../public/images/home/cards/mortgage-gos@2x.png';
import mortgageFamily from '../../../../../public/images/home/cards/mortgage-family.png';
import mortgageFamily2x from '../../../../../public/images/home/cards/mortgage-family@2x.png';
import mortgageStandart from '../../../../../public/images/home/cards/mortgage-standart.png';
import mortgageStandart2x from '../../../../../public/images/home/cards/mortgage-standart@2x.png';
import mortgageIt from '../../../../../public/images/home/cards/mortgage-it.png';
import mortgageIt2x from '../../../../../public/images/home/cards/mortgage-it@2x.png';
import type { TMortgageProgramStat } from '../../../../reducers/app';
import { Card, CardImage, CardList, CardListItem, CardMortgage, CardTitle } from '../../styles';
import { plural } from '../../../../utils';
import theme from '../../../../theme';

type TProps = {
	index: number,
	data: TMortgageProgramStat[],
};

const SLIDER_SETTINGS = {
	autoplay: true,
	autoplaySpeed: 2500,
	className: 'mortgageStatSlider',
	centerMode: true,
	centerPadding: 0,
	fade: true,
	dots: false,
	infinite: true,
	lazyLoad: true,
	arrows: false,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	accessibility: false,
};

const ICONS = [
	{
		icon: mortgageGos,
		icon2x: mortgageGos2x,
	},
	{
		icon: mortgageFamily,
		icon2x: mortgageFamily2x,
	},
	{
		icon: mortgageStandart,
		icon2x: mortgageStandart2x,
	},
	{
		icon: mortgageIt,
		icon2x: mortgageIt2x,
	},
];

export const MortgageCard = memo(({ index, data }: TProps) => (
	<Card $color="blue" index={index}>
		<CardTitle $blue>Ипотека</CardTitle>
		<Slider {...SLIDER_SETTINGS}>
			{data.map((item: TMortgageProgramStat, idx: number) => (
				<CardMortgage key={item.id}>
					<CardList>
						<CardListItem $color={theme.baseGrayColor}>
							<b>{item.title}</b>
						</CardListItem>
						<CardListItem $color={theme.baseGrayColor}>
							<b>
								{item.bankProgramCount}&nbsp;
								{plural(item.bankProgramCount, ['программа', 'программы', 'программ'])}
							</b>
						</CardListItem>
						<CardListItem $color={theme.baseGrayColor}>
							<b>
								От {item.minRate}%&ensp;Срок до {item.maxPeriod / 12}&nbsp;
								{plural(item.maxPeriod / 12, ['год', 'года', 'лет'])}
							</b>
						</CardListItem>
					</CardList>
					<CardImage>
						<img src={ICONS[idx].icon} srcSet={`${ICONS[idx].icon}, ${ICONS[idx].icon2x} 2x`} alt="" />
					</CardImage>
				</CardMortgage>
			))}
		</Slider>
	</Card>
));
