/* @flow */
import type { TLiraSelections } from '../types/lira3';
import type { TAction } from '../types/core';
import {
	FAIL,
	LIRA_DISLIKES_SORTING,
	LIRA_DISLIKES_VIEW,
	LIRA_GET_APARTS_LIST,
	LIRA_GET_LIKED_APARTS_LIST_FULL,
	LIRA_LIKES_NEED_UPDATE,
	LIRA_LIKES_SORTING,
	LIRA_LIKES_VIEW,
	LIRA_SET_INCLUDE_SOLD,
	LIRA_UPDATE_APARTMENT_OPINION,
	LOAD_PROFILE,
	LOGOUT,
	PREPARE_CHANGE_CITY,
	START,
	SUCCESS,
} from '../constants';

export type TLiraLikesView = 'list' | 'map';

export type TLiraSelectionsState = {
	data: TLiraSelections | null,
	likedSort: 'PRICE_DESC' | 'PRICE_ASC' | 'RELEVANCE_DESC' | 'RELEVANCE_ASC' | null,
	likedView: TLiraLikesView,
	dislikedSort: 'PRICE_DESC' | 'PRICE_ASC' | 'RELEVANCE_DESC' | 'RELEVANCE_ASC' | null,
	dislikedView: TLiraLikesView,
	includeSold: boolean,
	isLoading: boolean,
	isLoaded: boolean,
	needUpdate: boolean,
};

const initialState: TLiraSelectionsState = {
	data: {
		liked: [],
		disliked: [],
	},
	likedSort: null,
	likedView: 'list',
	dislikedSort: null,
	dislikedView: 'list',
	includeSold: false,
	isLoading: false,
	isLoaded: false,
	needUpdate: true,
};

const liraSelections = (state: TLiraSelectionsState = initialState, action: TAction): TLiraSelectionsState => {
	switch (action.type) {
		case LIRA_GET_APARTS_LIST + SUCCESS:
			return { ...state, data: { ...state.data, ...action.payload } };

		case LIRA_GET_APARTS_LIST + FAIL:
		case LIRA_GET_LIKED_APARTS_LIST_FULL + FAIL:
			return { ...state, isLoading: false, isLoaded: false };

		case LIRA_GET_LIKED_APARTS_LIST_FULL + START:
			return { ...state, isLoading: true, isLoaded: false };

		case LIRA_GET_LIKED_APARTS_LIST_FULL + SUCCESS:
			return { ...state, needUpdate: false, isLoading: false, isLoaded: true };

		case PREPARE_CHANGE_CITY:
		case LIRA_UPDATE_APARTMENT_OPINION + SUCCESS:
		case LOAD_PROFILE + SUCCESS:
		case LOGOUT + SUCCESS:
			return { ...state, needUpdate: true };

		case LIRA_LIKES_SORTING:
			return { ...state, likedSort: action.payload };

		case LIRA_DISLIKES_SORTING:
			return { ...state, dislikedSort: action.payload };

		case LIRA_LIKES_VIEW:
			return { ...state, likedView: action.payload };

		case LIRA_DISLIKES_VIEW:
			return { ...state, dislikedView: action.payload };

		case LIRA_SET_INCLUDE_SOLD:
			return { ...state, includeSold: action.payload };

		case LIRA_LIKES_NEED_UPDATE:
			return { ...state, needUpdate: true };

		default:
			return state;
	}
};

export { initialState as likesInitialState };

export default liraSelections;
