/* @flow */
import type { TAction } from '../types/core';
import { REPLACE_PLANNINGS_SORT, RESET_PLANNINGS_FILTER, SET_PLANNINGS_FILTER } from '../constants';
import type { TPlanningsSort } from '../types/filters';

export type TFilterPlanningsState = {
	planningIds: number[],
	sortedBy: TPlanningsSort,
	includeSold: boolean,
	page: number,
	count: number,
};

const initialState: TFilterPlanningsState = {
	planningIds: [],
	sortedBy: { price: 'asc' },
	includeSold: false,
	page: 0,
	count: 10,
};

const filterPlannings = (state: TFilterPlanningsState = initialState, action: TAction): TFilterPlanningsState => {
	switch (action.type) {
		case SET_PLANNINGS_FILTER:
			return { ...state, ...action.payload };

		// Для сортировки в мобиле
		case REPLACE_PLANNINGS_SORT:
			return { ...state, sortedBy: { ...action.payload } };

		case RESET_PLANNINGS_FILTER:
			return { ...initialState };

		default:
			return state;
	}
};

export default filterPlannings;
