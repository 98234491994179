/* @flow */
import React, { type Node, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessageText, StyledInputMaskFull } from '../styles';
import type { TDispatch } from '../../../types/core';
import { CHANGE_TOKEN } from '../../../constants';
import RepeatButton from './RepeatButton';
import type { TRootState } from '../../../types/rootState';
import type { TAuthState } from '../../../reducers/auth';
import type { TToken } from '../../../types/account';

type TProps = {
	onRepeatButtonClick?: () => any,
	handleKeyPress?: () => any,
};

const CodeStage = ({ onRepeatButtonClick, handleKeyPress }: TProps): Node => {
	const dispatch: TDispatch = useDispatch();

	const auth: TAuthState = useSelector((state: TRootState) => state.auth);
	const token: TToken = useSelector((state: TRootState) => state.token);

	const { timerInProgress } = auth;
	const { code } = token;

	const inputRef = useRef(null);

	const codeVerified = (inputCode) => /^\d{4}$/.test(inputCode);

	const handleKeyPressSubmit = useCallback((e) => {
		if (e.key === 'Enter' && codeVerified(code)) {
			handleKeyPress();
		}
	}, []);

	const onChange = useCallback((event: Event): any => {
		const { value = '' } = event.target;
		dispatch({ type: CHANGE_TOKEN, payload: { code: value } });

		if (value.length === 4 && codeVerified(value)) {
			handleKeyPress();
		}
	}, []);

	useEffect(() => {
		if (!inputRef?.current) {
			inputRef.current.focus();
		}
	}, [inputRef.current]);

	return (
		<>
			<MessageText>Введите одноразовый пароль из SMS</MessageText>
			<MessageText />
			<StyledInputMaskFull
				id="user-code"
				mask="9999"
				maskChar={null}
				placeholder="0000"
				ref={inputRef}
				value={code}
				onChange={onChange}
				onKeyPress={handleKeyPressSubmit}
				type="tel"
				autoComplete="off"
				autoFocus
			/>
			{!timerInProgress && <RepeatButton time={60} onClick={onRepeatButtonClick} />}
		</>
	);
};

CodeStage.defaultProps = {
	onRepeatButtonClick: undefined,
	handleKeyPress: undefined,
};

export default CodeStage;
