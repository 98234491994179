/* @flow */
import React, { type Node, useState } from 'react';
import { ButtonClear, ButtonSearch, Icon, Input, Root } from './styles';
import { search2021Icon } from '../Icons';

type TSearchProps = {
	data?: {
		type: string,
		placeholder: string,
	},
	value?: string,
	handleChangeSearch?: () => {},
	style?: Node,
};

const Search = ({ data, value, handleChangeSearch, style }: TSearchProps): Node => {
	const [stateValue, setStateValue] = useState('');
	const { type, placeholder } = data;

	const handleChange = (e) => {
		if (handleChangeSearch) {
			handleChangeSearch(e.currentTarget.value);
		} else setStateValue(e.target.value);
	};

	const handleClear = () => {
		if (handleChangeSearch) {
			handleChangeSearch('');
		} else setStateValue('');
	};

	return (
		<Root style={style}>
			<Input type={type} placeholder={placeholder} value={value || stateValue} onChange={handleChange} />
			<ButtonClear type="button" $isVisible={value || stateValue} onClick={handleClear} />
			<ButtonSearch type="button">
				<Icon viewBox="0 0 20 21">{search2021Icon}</Icon>
			</ButtonSearch>
		</Root>
	);
};

Search.defaultProps = {
	data: {
		type: 'text',
		placeholder: 'Введите данные',
	},
	value: null,
	handleChangeSearch: () => {},
	style: undefined,
};

export default Search;
